import { Stack, Typography, Box } from "@mui/material";
import { Contact } from "../../orderEntry/types/Contact";
import Iconify from "../../../minimals/components/iconify";

export function ContactOption({ option, props }: { option: Contact; props: any }) {
  const grey = "#212B36";
  return (
    <Stack
      direction="row"
      justifyContent="space-between !important"
      padding={2}
      alignItems="normal"
      {...props}
      key={option.lookupCode}
    >
      <Box>
        <Stack direction="row" alignItems="center">
          <Box>
            <Iconify
              icon={option.isIndividual ? "mdi:account" : "heroicons-outline:office-building"}
              fontSize={24}
              style={{ marginRight: "16px" }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2" fontWeight={600}>
              {`${option?.name || ""} `}
              <Typography variant="caption">{!option.isIndividual ? `(Company)` : `(Individual)`}</Typography>
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color={grey}>
              {option?.email || ""}
            </Typography>
            <Typography variant="subtitle2" fontWeight={300} color={grey}>
              {option?.phone || ""}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box textAlign="right">
        <Typography variant="subtitle2" fontWeight={300} color={grey}>
          {option.isIndividual && option.role ? `${option.role} ${option.company ? "at" : ""}` : ""}
        </Typography>
        {option.company && option.isIndividual && (
          <Typography color={grey}>
            <Iconify icon="heroicons-outline:office-building" style={{ marginRight: 8 }} fontSize={24} />
            {option.company}
          </Typography>
        )}
        <Typography variant="subtitle2" fontWeight={300} color={grey}>
          {option?.address1 || ""}
        </Typography>
      </Box>
    </Stack>
  );
}

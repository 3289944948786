import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link as MUILink,
  Checkbox
} from "@mui/material";
import { CompanySearchResults, Phone, SearchData } from "../types";
import { CustomAvatar } from "../../components/custom-avatar";
import { Link } from "react-router-dom";
import { getLogo } from "../../utils/getLogo";
import Highlighter from "react-highlight-words";
import LoadingSkeleton from "./LoadingSkeleton";
import { formatPhone } from "../shared/phones/formatPhone";
import { logEvent } from "../../utils/analyticsLogger";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  companiesSearchResults: CompanySearchResults | undefined;
  searchData: SearchData;
  handlePageNumberChange: (number: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchWords: string[];
  isLoading: boolean;
  companiesToMerge: Set<number>;
  setCompaniesToMerge: Dispatch<SetStateAction<Set<number>>>;
  maxCompaniesToMerge: number;
}

const highlightStyles = { backgroundColor: "#F8FF00" };

export default function Companies(props: IProps) {
  const {
    companiesSearchResults,
    searchData,
    handlePageNumberChange,
    handleChangeRowsPerPage,
    searchWords,
    isLoading,
    companiesToMerge,
    setCompaniesToMerge,
    maxCompaniesToMerge
  } = props;

  const handleCheckboxChange = (companyId: number, isChecked: boolean) => {
    if (isChecked) {
      addCompany(companyId);
      return;
    }
    removeCompany(companyId);
  };

  const addCompany = (companyId: number) => {
    setCompaniesToMerge(new Set(companiesToMerge).add(companyId));
  };

  const removeCompany = (companyId: number) => {
    const updatedCompanies = new Set(companiesToMerge);
    updatedCompanies.delete(companyId);
    setCompaniesToMerge(updatedCompanies);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Website</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Address of HQ</TableCell>
            <TableCell>Account Rep</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            companiesSearchResults?.data.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Checkbox
                    disabled={companiesToMerge.size >= maxCompaniesToMerge && !companiesToMerge.has(c.id)}
                    checked={companiesToMerge.has(c.id)}
                    onChange={(_, isChecked) => handleCheckboxChange(c.id, isChecked)}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    to={`../phonebook/company/profile?id=${c.id}`}
                    style={{
                      textDecoration: "none",
                      color: "#212B36"
                    }}
                    onClick={() => {
                      logEvent("Phonebook", "Clicked to view a company profile");
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CustomAvatar alt={c.name} name={c.name.trim()} src={getLogo(c?.website)} />
                      <Box>
                        <Typography variant="subtitle2">
                          <Highlighter
                            autoEscape
                            textToHighlight={c.name || ""}
                            highlightStyle={highlightStyles}
                            searchWords={searchWords}
                          />
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "italic"
                          }}
                        >
                          {c.companyType?.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    <MUILink color="inherit" href={`https://${c.website}`} target="_blank">
                      <Highlighter
                        autoEscape
                        textToHighlight={c.website || ""}
                        highlightStyle={highlightStyles}
                        searchWords={searchWords}
                      />
                    </MUILink>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap>
                    <MUILink color="initial" href={`tel:+${c.phoneCountryCode}${c.phone}`}>
                      <Highlighter
                        autoEscape
                        textToHighlight={
                          c.phone
                            ? formatPhone({
                                number: c.phone,
                                countryCode: c.phoneCountryCode
                              } as Phone)
                            : ""
                        }
                        highlightStyle={highlightStyles}
                        searchWords={searchWords}
                      />
                    </MUILink>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    <Highlighter
                      autoEscape
                      textToHighlight={`${c.branches[0]?.address1 || ""} ${c.branches[0]?.address2 || ""}`}
                      highlightStyle={highlightStyles}
                      searchWords={searchWords}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <Highlighter
                      autoEscape
                      textToHighlight={`${c.branches[0]?.city || ""}${(c.branches[0]?.city || "") && ","} ${
                        c.branches[0]?.state || ""
                      } ${c.branches[0]?.zip || ""}`}
                      highlightStyle={highlightStyles}
                      searchWords={searchWords}
                    />
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isLoading && <LoadingSkeleton />}
      {companiesSearchResults?.data.length === 0 && (
        <Box textAlign="center" padding={3}>
          <Typography>Your search for "{searchData.searchTerm}" didn't return any results</Typography>
          <Typography variant="caption">Check for spelling mistakes</Typography>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={companiesSearchResults?.count || 0}
        rowsPerPage={searchData.pageSize}
        page={searchData.pageNumber}
        onPageChange={(e, number) => handlePageNumberChange(number)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
      />
    </>
  );
}

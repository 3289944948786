import { Grid, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import Iconify from "../../../minimals/components/iconify";
import { Contact } from "../types/Contact";

type Prop = {
  principal: Contact;
};

export default function MatchedPrincipal({ principal }: Prop) {
  const { orderPrincipal, setOrderPrincipal, additionalContacts, setAdditionalContacts, getContact } =
    useContext(OrderContext);

  let phonebookContact: Contact;

  const setMatchedPrincipal = async (phonebookContact: Contact) => {
    if ((principal as Contact).isMarketingSource) {
      setOrderPrincipal(phonebookContact);
      const relatedOrdersElement = document.getElementById("principal");
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    } else {
      const relatedOrdersElement = document.getElementById("addcontact");
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      saveAdditionalContact();
    }

    setIsHovered(false);
  };

  const handleMatchePrincipalContact = async () => {
    phonebookContact = await getContact(principal?.lookupCode || "");
    setMatchedPrincipal(phonebookContact);
  };
  const saveAdditionalContact = () => {
    phonebookContact.contactType = {
      //isOther is set to true even though its a phonebook contact, as a hack to make it work. This needs to be changed eventually.
      isOther: true,
      name: "Principal",
      hasLookupAPI: true,
      isPhonebookContact: true
    };
    setAdditionalContacts([...additionalContacts, phonebookContact]);
  };
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const isImported =
    principal.name === orderPrincipal?.name || additionalContacts.find((n) => n.name === principal.name);

  return (
    <Grid container marginTop={3}>
      <Grid item xs={2}>
        {!isImported && (
          <IconButton
            color={isHovered ? "primary" : "primary"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMatchePrincipalContact()}
          >
            {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
            {!isHovered && <Iconify icon={"eva:person-outline"} />}
          </IconButton>
        )}
        {isImported && (
          <IconButton color="success">
            <Iconify icon={"eva:checkmark-fill"} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">Principal</Typography>
            <Typography>{principal.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

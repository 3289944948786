import axios from "axios";
import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import { IFramePageViewTypes } from "./types/types";
import PhonebookIFrame from "../phonebook/phonebookAutocomplete/PhonebookIFrame";
// ----------------------------------------------------------------------

interface OpenPhonebookIFrameParams {
  defaultName: string;
  onSave?: Function | null;
  selectedLookupCode: string;
  pageView: IFramePageViewTypes;
}

type IFrameContextProps = {
  isDisplayPhonebookIFrame: boolean;
  setIsDisplayPhonebookIFrame: Dispatch<SetStateAction<boolean>>;
  defaultPhonebookName: string;
  setDefaultPhonebookName: Dispatch<SetStateAction<string>>;
  openPhonebookIFrame: (params: OpenPhonebookIFrameParams) => void;
  closePhonebookIFrame: () => void;
  iFramePageView: IFramePageViewTypes;
  setIFramePageView: Dispatch<SetStateAction<IFramePageViewTypes>>;
  selectedLookupCode: string;
  setSelectedLookupCode: Dispatch<SetStateAction<string>>;
};

const IFrameContext = createContext({} as IFrameContextProps);
type IFrameProviderProps = {
  children: ReactNode;
};

function IFrameProvider({ children }: IFrameProviderProps) {
  const [isDisplayPhonebookIFrame, setIsDisplayPhonebookIFrame] = useState(false);
  const [defaultPhonebookName, setDefaultPhonebookName] = useState("");
  const [iFramePageView, setIFramePageView] = useState<IFramePageViewTypes>("view-contact");
  const [selectedLookupCode, setSelectedLookupCode] = useState("");
  const [selectedContactFunctions, setSelectedContactFunctions] = useState<{
    save: Function | null;
  } | null>(null);

  const closePhonebookIFrame = () => {
    setDefaultPhonebookName("");
    setIsDisplayPhonebookIFrame(false);
    setIFramePageView("view-contact");
    setSelectedLookupCode("");
  };

  const openPhonebookIFrame = ({ defaultName, onSave, selectedLookupCode, pageView }: OpenPhonebookIFrameParams) => {
    setDefaultPhonebookName(defaultName);
    setIsDisplayPhonebookIFrame(true);
    setIFramePageView(pageView);
    setSelectedLookupCode(selectedLookupCode);

    if (onSave) {
      setSelectedContactFunctions({ save: onSave });
    }
  };

  //FOR IFRAME
  useEffect(() => {
    if (window.self !== window.top) {
      //// We only want to add the listener if its not the app in the IFrame Version
      return;
    }
    const PARENT_DOMAIN = window?.parent.origin;
    // const domain = 'titleq.mcres.com';
    const handleIFrameEvent = async (event: any) => {
      if (event.origin !== PARENT_DOMAIN) return;

      const { eventType, phonebookId } = event.data ?? {};

      if (window.self === window.top && eventType === "cancel") {
        closePhonebookIFrame();
        return;
      }

      const isContactEvent = eventType === "addContact" || eventType === "editContact";
      const isCompanyEvent = eventType === "editCompany" || eventType === "addCompany";
      if (phonebookId && (isContactEvent || isCompanyEvent)) {
        const endpoint = isContactEvent
          ? `/api/clientphonebook/contacts/getOrderEntryContactByPhonebookId?id=${phonebookId}`
          : `/api/clientphonebook/companies/getOrderEntryCompanyByPhonebookId?id=${phonebookId}`;

        const { data } = await axios.get(endpoint);

        if (selectedContactFunctions?.save) {
          selectedContactFunctions.save(data);
        }
        closePhonebookIFrame();
      }
    };
    // Add event listener for message events
    window.addEventListener("message", handleIFrameEvent);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("message", handleIFrameEvent);
    };
  }, [selectedContactFunctions]);

  return (
    <IFrameContext.Provider
      value={{
        isDisplayPhonebookIFrame,
        setIsDisplayPhonebookIFrame,
        defaultPhonebookName,
        setDefaultPhonebookName,
        openPhonebookIFrame,
        closePhonebookIFrame,
        iFramePageView,
        setIFramePageView,
        selectedLookupCode,
        setSelectedLookupCode
      }}
    >
      {isDisplayPhonebookIFrame && <PhonebookIFrame />}
      {children}
    </IFrameContext.Provider>
  );
}

export { IFrameProvider, IFrameContext };

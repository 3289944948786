import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { CustomAvatar } from "../../components/custom-avatar";
import { CloseIcon } from "../../../minimals/theme/overrides/CustomIcons";
import axios from "axios";
import { BasicTask } from "../types";
import { getNotes } from "./getNotes";

export default function TaskNotesModal({
  selectedTask,
  setSelectedTask,
  updateTasks
}: {
  selectedTask: BasicTask | null;
  setSelectedTask: (value: any) => void;
  updateTasks: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [text, setText] = useState<string>("");

  const saveNote = async () => {
    if (!selectedTask || !text) return;
    const payload = {
      basicTaskId: selectedTask?.id,
      text: text
    };
    //revise route to be dynamic
    await axios.post("/api/taskmanagement/tasks/addNote", payload);
  };

  const updateSelectedTaskItem = async () => {
    if (!selectedTask) return;
    const notes = await getNotes(selectedTask);
    const updatedTaskItem = {
      ...selectedTask,
      notes
    } as BasicTask;
    setSelectedTask(updatedTaskItem);
    updateTasks();
    setText("");
  };

  const save = async () => {
    if (text && selectedTask) {
      setSubmitting(true);
      await saveNote();
      getNotes(selectedTask);
      updateSelectedTaskItem();
      updateTasks();
      setSubmitting(false);
    }
  };

  const closeModal = () => {
    setSelectedTask(null);
  };

  const open = !!selectedTask;

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3}>
          {selectedTask?.description}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={3}
            label="Note"
            sx={{ marginBottom: 2 }}
          />
          <Stack direction="row" justifyContent="right">
            <Button variant="contained" size="small" onClick={save} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Box>
        {selectedTask?.notes && selectedTask?.notes?.length > 0 && (
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1">History</Typography>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.4
                }
              }}
            >
              {selectedTask?.notes?.map((n) => {
                return (
                  <TimelineItem key={n.id}>
                    <TimelineOppositeContent color="textSecondary" width={300}>
                      <Stack direction="column">
                        <Typography variant="caption">{n.createdByUser}</Typography>
                        <Typography variant="caption">
                          {new Date(n.dateCreated).toLocaleDateString()}{" "}
                          {new Date(n.dateCreated).toLocaleTimeString(undefined, {
                            hour: "numeric",
                            minute: "numeric"
                          })}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined">
                        <CustomAvatar
                          alt={n.createdByUser}
                          name={n.createdByUser}
                          sx={{ width: 22, height: 22, fontSize: 12 }}
                        />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                        {n.text}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

import { Helmet } from "react-helmet-async";
import { useContext, useState } from "react";
// @mui
import { Tab, Card, Tabs, Container, Box, Typography, Link, Stack, Button, IconButton } from "@mui/material";
import { useSettingsContext } from "../../contexts/SettingsContext";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { ProfileCover } from "../shared/profile";
import { useNavigate, useParams } from "react-router-dom";
import { TABS } from "./TABS";
import Iconify from "../../../minimals/components/iconify";
import { ContactContext } from "./ContactContext";
import Label from "../../../minimals/components/label/Label";
import { closeIFrame } from "../../utils/iframeUtils";

export default function Contacts() {
  const { themeStretch, isAppInIFrame } = useSettingsContext();
  const { contact, isFetching } = useContext(ContactContext);
  const navigate = useNavigate();

  const { section } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const idFromUrl = searchParams.get("id");

  const selectedValue = () => {
    return TABS.find((tab) => tab.values.some((value) => value === section))?.values[0];
  };

  if (isFetching) {
    return <></>;
  }

  ////If this gets use more frequently, it can be styled much better
  if (!contact) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 144px)"
        }}
      >
        <div style={{ textAlign: "center", fontSize: "50px", fontWeight: "" }}>Contact Not Found In Phonebook</div>
      </div>
    );
  }

  return (
    <>
      {isAppInIFrame && (
        <Stack direction="row" justifyContent="flex-end" paddingRight={1.5} marginBottom={1}>
          <IconButton
            size="large"
            onClick={closeIFrame}
            sx={{ position: "absolute", top: 16, right: 16, padding: 0.3 }}
          >
            <Iconify icon="eva:close-outline" width={32} height={32} />
          </IconButton>

        </Stack>
      )}
      <Helmet>
        <title> Individual Profile | TitleQ</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        {contact && !contact.isActive && (
          <Label variant="soft" color="error">
            {" "}
            Inactive
          </Label>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <CustomBreadcrumbs
            heading={`${isAppInIFrame ? "Phonebook - " : ""}Individual Profile`}
            links={[
              {
                name: "Individuals",
                href: "../phonebook/contacts/individuals"
              },
              {
                name: contact?.contactRoles[0]?.branch?.company?.name || "",
                href: contact?.contactRoles[0]?.branch?.company?.name
                  ? `../phonebook/company/profile?id=${contact?.contactRoles[0]?.branch?.company?.id}`
                  : ""
              },
              {
                name: contact?.firstName
                  ? (contact?.firstName || "") + " " + (contact?.middleName || "") + " " + (contact?.lastName || "")
                  : ""
              }
            ]}
          />
          <Button
            variant="contained"
            disabled={!contact.isActive}
            startIcon={<Iconify icon="eva:edit-fill" />}
            onClick={() => {
              navigate(`../phonebook/edit-contact?id=${contact?.id || idFromUrl}`);
            }}
          >
            Edit
          </Button>
        </Stack>
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: "relative"
          }}
        >
          <ProfileCover
            name={`${contact?.firstName || ""} ${contact?.middleName || ""} ${contact?.lastName || ""}`}
            suffix={contact?.suffix}
            logo={
              contact?.contactRoles[0]?.businessEmail?.split("@")[1]
                ? `https://logo.clearbit.com/${contact?.contactRoles[0]?.businessEmail?.split("@")[1]}`
                : undefined
            }
            role={contact?.contactRoles[0]?.role?.name || ""}
            company={contact?.contactRoles[0]?.branch?.company}
          />

          <Tabs
            value={selectedValue()}
            onChange={(event, newValue) => {
              navigate(`../phonebook/contact/${newValue}?id=${contact?.id || idFromUrl}`);
            }}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: "absolute",
              bgcolor: "background.paper",
              "& .MuiTabs-flexContainer": {
                pr: { md: 3 },
                justifyContent: {
                  sm: "center",
                  md: "flex-end"
                }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.values[0]} value={tab.values[0]} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>
        {TABS.map((tab) => tab.values.some((v) => v === section) && <Box key={tab.values[0]}> {tab.component}</Box>)}
      </Container>
    </>
  );
}

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { ConfirmationContext } from "../contexts/ConfirmationContext";
import EmailBodyForm from "./EmailBodyForm";
import IncludedAttachments from "./IncludedAttachments";
import SendViaEmailButton from "./SendViaEmailButton";
// ----------------------------------------------------------------------

export default function EmailOptions() {
  const { emailContacts, sentEmail, setEmailContacts, submitting } = useContext(ConfirmationContext);
  return (
    <Card
      sx={{
        marginTop: 2,
        marginBottom: 4,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 1,
        paddingLeft: 1
      }}
    >
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell width={250}>Email Options</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell width={300}>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailContacts.map((c) => (
              <TableRow
                sx={{
                  verticalAlign: "top",
                  "&:last-child td, &:last-child th": { border: 0 }
                }}
              >
                <TableCell component="th" scope="row">
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!c.email}
                          checked={c.to}
                          onClick={() =>
                            setEmailContacts(
                              emailContacts.map((contact) =>
                                contact.email === c.email ? { ...contact, to: !contact.to } : contact
                              )
                            )
                          }
                        />
                      }
                      label="To"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!c.email}
                          checked={c.cc}
                          onClick={() =>
                            setEmailContacts(
                              emailContacts.map((contact) =>
                                contact.email === c.email ? { ...contact, cc: !contact.cc } : contact
                              )
                            )
                          }
                        />
                      }
                      label="CC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!c.email}
                          checked={c.bcc}
                          onClick={() =>
                            setEmailContacts(
                              emailContacts.map((contact) =>
                                contact.email === c.email ? { ...contact, bcc: !contact.bcc } : contact
                              )
                            )
                          }
                        />
                      }
                      label="BCC"
                    />
                  </FormGroup>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{c.name}</Typography>
                </TableCell>
                <TableCell>
                  {c.email?.split(";").map((e) => (
                    <Typography>{e}</Typography>
                  ))}
                </TableCell>
                <TableCell>{c.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmailBodyForm />
      <IncludedAttachments />
      <Stack alignItems="flex-end" paddingRight={1}>
        <SendViaEmailButton label={sentEmail ? "Re-send Via Email" : "Send via Email"} />
      </Stack>
    </Card>
  );
}

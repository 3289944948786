import { Card, Typography, CardHeader, Stack, Box } from "@mui/material";
import { useContext } from "react";
import { ContactContext } from "../../contact/ContactContext";

// ----------------------------------------------------------------------

export default function SaleReps() {
  const { contact } = useContext(ContactContext);

  const salesReps = contact?.contactReps.filter((r) => r.contactRepTypeId === 1).map((r) => r.internalUser);

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Sales Reps</Typography>} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {contact?.contactReps?.length === 0 && (
          <Stack direction="row" spacing={1}>
            <Typography>No Information</Typography>
          </Stack>
        )}
        {salesReps?.map((r) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={700}>
              Title
            </Typography>
            <Box>
              <Typography variant="body2">
                {r?.firstName} {r?.lastName}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    color: "#1817a8",
    "&.Mui-checked": {
      color: "#1817a8"
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1817a8"
    }
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#1817a8"
  }
}));

import { Button, Stack } from "@mui/material";
import Iconify from "../../minimals/components/iconify";
import OrderAddress from "../shared/orderInfo/OrderAndAddress";

interface IProps {
  handleOpenNewRecordable: () => void;
}

export default function RecordableHeader({ handleOpenNewRecordable }: IProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <OrderAddress />
      <Button
        color="primary"
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={handleOpenNewRecordable}
      >
        New Recordable
      </Button>
    </Stack>
  );
}

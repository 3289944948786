import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
}

export default function TasksFilters({ assignedToMe, setAssignedToMe }: IProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <FormControlLabel
        control={<Checkbox checked={assignedToMe} onChange={(_, value) => setAssignedToMe(value)} />}
        label="Assigned to me"
      />
    </Stack>
  );
}

import { Contact } from "../shared/orderInfo/types";
import { BasicTask } from "./types";

export const isBuyerOrBorrower = (task: BasicTask, contact: Contact) => {
  const represents = contact?.represents;
  return !!(
    task.contactCode?.charAt(0) === "B" ||
    (represents && ["Buyers", "Borrowers"].includes(represents)) ||
    (task.contactCode?.charAt(0) === "O" && contact?.otherType && contact.otherType.includes("Buyer"))
  );
};

export const isSeller = (task: BasicTask, contact: Contact) => {
  const represents = contact?.represents;
  return !!(
    (task.contactCode?.charAt(0) === "S" && task.contactCode !== "SU") ||
    (represents && ["Sellers"].includes(represents)) ||
    (task.contactCode?.charAt(0) === "O" && contact?.otherType && contact.otherType.includes("Seller"))
  );
};

export const isLender = (task: BasicTask, contact: Contact) => {
  const represents = contact?.represents;
  return !!(
    (["L", "M"].includes(task.contactCode?.charAt(0)) && !task.contactCode.includes("LB")) ||
    (task.contactCode?.includes("AT") && represents && ["Lender", "Mortgage"].includes(represents))
  );
};

export const isMTA = (task: BasicTask) => {
  return !["B", "S", "L", "M", "O"].includes(task.contactCode?.charAt(0)) && !task.contactCode?.includes("AT");
};

import { ContactRole } from "../../phonebook/types";
import { InternalUser } from "../../types/app";
import { ContactType } from "./ContactType";
import { SelectUser } from "./SelectUser";

export type Contact = {
  id: string | null;
  contactType?: ContactType | null | undefined;
  lookupCode?: string | null;
  title?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  name?: string | null;
  email?: string | null;
  emailsSplit?: string[] | null;
  code?: string | null;
  phone?: string | null;
  ext?: string | null;
  fax?: string | null;
  cell?: string | null;
  address1?: string | null;
  address2?: string | null;
  type?: string | null;
  represents?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  company?: string | null;
  companyId?: number | null;
  branchId?: number | null;
  status?: string | null;
  role?: string | null;
  marketingRep?: SelectUser | null;
  marketingRepShortName?: string | null;
  internalDriver?: string | null;
  internalDriverName?: string | null;
  nationalInternalDriver?: string | null;
  notes?: string[];
  isButton?: boolean;
  licenseNumber?: string | null;
  payeeName?: string | null;
  isDynamicsContact?: boolean;
  otherContactType?: string | null;
  contactRoles?: ContactRole[];
  isIndividual?: boolean;
  isOther?: boolean;
  suffix: string | null;
  salesRep: InternalUser[] | null;
  nyAccountRep: InternalUser | null;
  njAccountRep: InternalUser | null;
  nationalAccountRep: InternalUser | null;
  internalDriverEmail: string | null;
  internalDriverPhone: string | null;
  clientInstructions: string | null;
  whoHasTheFile: string | null;
  isMarketingSource?: boolean;
  emailPolicyTo?: string | null;
};

export const GetDefaultContact: () => Contact = () => {
  return {
    id: null,
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    name: null,
    email: null,
    phone: null,
    fax: null,
    cell: null,
    address1: null,
    city: null,
    state: null,
    company: null,
    role: "Principal",
    lookupCode: null,
    suffix: null,
    address2: null,
    zip: null,
    status: null,
    salesRep: null,
    nyAccountRep: null,
    njAccountRep: null,
    nationalAccountRep: null,
    marketingRepShortName: null,
    internalDriver: null,
    nationalInternalDriver: null,
    notes: [],
    clientInstructions: null,
    internalDriverEmail: null,
    internalDriverName: null,
    internalDriverPhone: null,
    whoHasTheFile: null,
    isIndividual: true,
    emailPolicyTo: null
  } as Contact;
};

import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Typography, Card } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Iconify from "../../minimals/components/iconify";
import DebouncedOrderSearch from "../components/DebouncedOrderSearch";
import axios from "axios";

const NewTransferRequest = () => {
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [requestNote, setRequestNote] = useState<string>("");
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

  const submitRequest = async () => {
    const request = {
      orderNumber,
      requestNote,
      amount: Number(amount)
    };

    setIsSubmittingRequest(true);
    const response = await axios.post("/api/depositrefunds/addRequest", request);
    if (response.status == 200) {
      setIsSubmittingRequest(false);
      navigate(`/deposit-refunds/view`);
      return;
    }
    setIsSubmittingRequest(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        pt: 8
      }}
    >
      <Box sx={{ position: "absolute", top: 100, right: 300 }}>
        <Button
          component={RouterLink}
          to={"/deposit-refunds/view"}
          variant="contained"
          startIcon={<Iconify icon="mdi:eye" />}
        >
          View Requests
        </Button>
      </Box>
      <Card sx={{ p: 4, maxWidth: 600, width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          New Deposit Refund Transfer Request
        </Typography>
        <Stack spacing={3}>
          <DebouncedOrderSearch
            value={orderNumber}
            textboxMargin="dense"
            onSelect={async (e: React.SyntheticEvent<Element, Event>, option: string | null, reason: string) => {
              setOrderNumber(option || "");
            }}
          />
          <TextField
            value={amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const num = Number(e.target.value);
              ///Using type of number has annoying scroll when feature/bug so using a type of text and ensuring number is entered
              if (!amount && e.target.value == ".") {
                setAmount("0.");
              }

              if (!isNaN(num)) {
                if (num < 0) {
                  setAmount("0");
                  return;
                }
                setAmount(e.target.value);
              }
            }}
            fullWidth
            label="Amount"
            placeholder="Amount"
          />

          <TextField
            value={requestNote}
            onChange={(e) => setRequestNote(e.target.value)}
            fullWidth
            multiline
            rows={3}
            label="Request Note"
            sx={{ marginBottom: 2 }}
          />

          <LoadingButton
            disabled={!orderNumber || !amount}
            fullWidth
            loading={isSubmittingRequest}
            size="large"
            type="submit"
            variant="contained"
            onClick={submitRequest}
          >
            Submit Request
          </LoadingButton>
        </Stack>
      </Card>
    </Box>
  );
};

export default NewTransferRequest;

import styled from "@emotion/styled";
// @mui
import {
  Stack,
  Button,
  Tooltip,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Collapse,
  ButtonGroup,
  Switch,
  FormControlLabel,
  FormGroup
} from "@mui/material";
// utils
import { fDateMonthYear, fDateMonthDayYear } from "../utils/formatTime";
import { ICalendarViewOption, IViewType } from "../types/calendar";
import CalendarFilters from "./CalendarFilters";
import { useCalendar } from "../contexts/CalendarContext";
import { logEvent } from "../utils/analyticsLogger";
import useResponsive from "../../minimals/hooks/useResponsive";
import useLocalStorage from "../../minimals/hooks/useLocalStorage";
import Iconify from "../../minimals/components/iconify";

// ----------------------------------------------------------------------
const VIEW_OPTIONS = [
  {
    id: "dayGridMonth",
    value: "dayGridMonth",
    label: "Month",
    icon: "ic:round-view-module",
    isExpanded: false
  },
  {
    id: "dayGridMonthExpanded",
    value: "dayGridMonthExpanded",
    label: "Expanded",
    icon: "ic:round-view-week",
    isExpanded: true
  },
  {
    id: "listWeekOneDay",
    value: "listWeekOneDay",
    label: "Day",
    icon: "ic:round-view-day",
    isExpanded: false
  },
  {
    id: "listWeek",
    value: "listWeek",
    label: "Agenda",
    icon: "ic:round-view-agenda",
    isExpanded: false
  }
] as ICalendarViewOption[];
// ----------------------------------------------------------------------

type Props = {
  date: Date;
  view: string;
  onToday: VoidFunction;
  onNextDate: VoidFunction;
  onPrevDate: VoidFunction;
  onGoToDate: (date: string) => void;
  onChangeView: (newView: ICalendarViewOption) => void;
  isDisplayWeekends: boolean;
  handleToggleDisplayWeekends: (isChecked: boolean) => void;
};

export default function CalendarToolbar({
  date,
  view,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
  onGoToDate,
  isDisplayWeekends,
  handleToggleDisplayWeekends
}: Props) {
  const { viewMode, setViewMode } = useCalendar();

  const isDesktop = useResponsive("up", "sm");
  const [isOpenFilters, setIsOpenFilters] = useLocalStorage("isOpenFilters", true);

  const setViewModeAndLog = (mode: IViewType) => {
    logEvent("Closing Calendar", "Filter 2", mode);
    setViewMode(mode);
  };
  return (
    <ToolbarWrap>
      <Collapse in={isOpenFilters} timeout="auto" unmountOnExit>
        <CalendarFilters onGoToDate={onGoToDate} />
      </Collapse>
      <Stack alignItems="center" justifyContent="space-between" direction={{ xs: "column", sm: "row" }} sx={{ p: 2.5 }}>
        {isDesktop && (
          <Stack direction="row" spacing={1}>
            <ToggleButtonGroup value={view} exclusive aria-label="calendar-view">
              {VIEW_OPTIONS.map((viewOption) => (
                <Tooltip key={viewOption.id} title={viewOption.label}>
                  <ToggleButton
                    size="small"
                    value={viewOption.id}
                    selected={viewOption.id === view}
                    onChange={() => onChangeView(viewOption)}
                  >
                    <Iconify icon={viewOption.icon} />
                  </ToggleButton>
                </Tooltip>
              ))}
            </ToggleButtonGroup>
          </Stack>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isDisplayWeekends}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleToggleDisplayWeekends(event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Show Weekends"
          />
        </FormGroup>

        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={onPrevDate}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Typography variant="h5">
            {view === "dayGridMonth" || view === "dayGridMonthExpanded"
              ? fDateMonthYear(date)
              : fDateMonthDayYear(date)}
          </Typography>
          {/* <Typography variant="h5">{fDateMonthYear(date)}</Typography> */}

          <IconButton onClick={onNextDate}>
            <Iconify icon="eva:arrow-ios-forward-fill" />
          </IconButton>
        </Stack>

        {/* After this was done I found MUI has this built in anyone wants to change it. https://mui.com/material-ui/react-toggle-button/  */}
        <ButtonGroup size="small">
          <Button onClick={() => setViewModeAndLog("client")} variant={viewMode == "client" ? "contained" : "outlined"}>
            Client
          </Button>
          <Button onClick={() => setViewModeAndLog("rep")} variant={viewMode == "rep" ? "contained" : "outlined"}>
            Assigned To
          </Button>
          <Button
            onClick={() => setViewModeAndLog("titlecloser")}
            variant={viewMode == "titlecloser" ? "contained" : "outlined"}
          >
            Title Closer
          </Button>
        </ButtonGroup>

        <Stack direction="row" alignItems="center" spacing={1}>
          {isDesktop && (
            <Button size="small" color="error" variant="contained" onClick={onToday}>
              Today
            </Button>
          )}

          <IconButton onClick={() => setIsOpenFilters(!isOpenFilters)}>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Stack>
      </Stack>
    </ToolbarWrap>
  );
}

const ToolbarWrap = styled.div`
  background-color: #fff;
`;

import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { MRT_ColumnDef } from 'material-react-table';
import { LedgerType } from './types';
import { fCurrency } from '../../utils/formatNumber';
import {
  fMonthDayYearSlashNotation,
  fMonthDayYearTimeSlashNotation,
} from '../../utils/formatTime';
import Iconify from '../../../minimals/components/iconify/Iconify';

export const statusColorMap: {
  [key: string]:
    | 'default'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
} = {
  Pending: 'warning',
  Posted: 'success',
  Voided: 'default',
  Deleted: 'error'
};


export const columns = (handleHistoryModal: (row: string) => void): MRT_ColumnDef<LedgerType>[] => [
  {
    header: 'Status',
    accessorKey: 'status',
    size: 20,
    maxSize: 20,
    Cell: ({ renderedCellValue, row }) => {
      var color = statusColorMap[row.original.status];
      return (
        <Chip
          label={row.original.status}
          color={color}
          variant='soft'
          size='small'
          sx={{ borderRadius: '4px' }}
          onClick={() => handleHistoryModal(row.original.id)}
        />
      );
    },
    filterVariant: 'select',
    filterSelectOptions: ['Pending', 'Posted', 'Voided', 'Deleted'],
    filterFn: (row, columnId, filterValue) => {
        return filterValue.includes(row.getValue(columnId));
      },
  },
  {
    header: 'Transaction Type',
    accessorKey: 'typeDescription',
  },
  {
    header: 'In/Out',
    accessorKey: 'isDebit',
    Cell: ({ renderedCellValue, row }) => (
      <Typography variant='body2'>
        {row.original.isDebit ? 'Out' : 'In'}
      </Typography>
    ),
    size: 20,
    maxSize: 20,
    filterVariant: 'select',
    filterSelectOptions: ['In', 'Out'],
    filterFn: (row, id, filterValue) => {
      var isDebit = filterValue === 'Out' && row.original.isDebit;
      var notDebit = filterValue === 'In' && !row.original.isDebit;
      return isDebit ? isDebit : notDebit;
    },
  },
  {
    header: 'Tx Date',
    accessorKey: 'transactionDate',
    Cell: ({ renderedCellValue, row }) => (
      <Typography variant='body2'>
        {fMonthDayYearSlashNotation(row.original.transactionDate)}
      </Typography>
    ),
    filterVariant: 'date-range',
    filterFn: (row, id, filterValue) => {
      var startDate = new Date(filterValue[0]).getTime();
      var endDate = new Date(filterValue[1]).getTime();
      var transactionDate = new Date(row.original.transactionDate).getTime();
      var passes = transactionDate >= startDate && transactionDate <= endDate;
      return passes;
    },
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    size: 20,
    maxSize: 20,
    Cell: ({ renderedCellValue, row }) => (
      <Typography variant='body2'>{fCurrency(row.original.amount)}</Typography>
    ),
  },
  {
    header: 'Ref #',
    accessorKey: 'referenceNumber',
    size: 20,
    maxSize: 20,
  },
  {
    header: 'Payor/Payee',
    accessorKey: 'payorPayee',
  },
  {
    header: 'Memo',
    accessorKey: 'memo',
    Cell: ({ renderedCellValue, row }) => (
        <Typography sx={{whiteSpace: 'normal', width: 250}} variant='body2'>{row.original.memo}</Typography>
      ),
  },
  {
    header: 'Cleared/Voided',
    accessorKey: 'clearedOn',
    size: 20,
    maxSize: 20,
    Cell: ({ renderedCellValue, row }) => (
      <Stack direction='row' spacing={2} alignItems="center">
      <div style={{width:70}}>
        {row.original.clearedOn && (
          <Typography variant='body2'>
            {fMonthDayYearSlashNotation(row.original.clearedOn)}{' '}
          </Typography>
        )}
        {row.original.voidedOn && (
          <Typography variant='body2'>
            {fMonthDayYearSlashNotation(row.original.voidedOn)}{' '}
          </Typography>
        )}
        </div>
         <IconButton onClick={() => handleHistoryModal(row.original.id)}>
        <Iconify icon='bi:three-dots-vertical' />
      </IconButton>
      </Stack>
    ),
  },
];

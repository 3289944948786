import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IFrameContext } from "../../contexts/IFrameContext";
import Spinner from "../../components/Spinner";
import { IFramePageViewTypes } from "../../contexts/types/types";

const PhonebookIFrame = () => {
  const {
    isDisplayPhonebookIFrame: isOpen,
    defaultPhonebookName,
    closePhonebookIFrame,
    iFramePageView,
    selectedLookupCode
  } = useContext(IFrameContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = (event: object, reason: string) => {
    if (reason == "backdropClick") return;
    closePhonebookIFrame();
  };

  const getIFrameSource = ({
    page = "view-contact",
    lookupCode = "",
    defaultName = "",
    displayRole = true
  }: {
    page: IFramePageViewTypes;
    lookupCode: string;
    defaultName: string;
    displayRole: boolean;
  }) => {
    if (page == "view-contact" && lookupCode) {
      return `/phonebook/contact/profile?lookupcode=${lookupCode}`;
    }
    if (page == "view-company" && lookupCode) {
      return `/phonebook/company/profile?lookupcode=${lookupCode}`;
    }
    if (page == "create-company") {
      return `/phonebook/company/create-company?defaultName=${defaultName}&&displayRole=${displayRole}`;
    }
    return `/phonebook/create-contact?defaultname=${defaultName}`;
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          ".MuiPaper-root": {
            width: "1200px",
            maxWidth: "1200px",
            height: "1000px"
          }
        }}
      >
        <DialogContent sx={{ position: "relative", padding: 0 }}>
          <Spinner local={true} display={true} />
          <iframe
            title="Client Phonebook"
            src={getIFrameSource({
              page: iFramePageView,
              lookupCode: selectedLookupCode,
              defaultName: defaultPhonebookName,
              displayRole: true
            })}
            style={{
              aspectRatio: "12 / 16",
              height: "100%",
              width: "100%",
              border: "none",
              position: "relative",
              zIndex: 50000
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhonebookIFrame;

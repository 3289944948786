import { Checkbox, Chip, Link, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { MRT_ColumnDef } from 'material-react-table';
import { TransactionHistory } from './types';
import {
  fMonthDayYearSlashNotation,
  fMonthDayYearTimeSlashNotation,
} from '../../utils/formatTime';


export const historyColumns: MRT_ColumnDef<TransactionHistory>[] = [
  {
    header: 'Adjustment Type',
    accessorKey: 'adjustmentType',
    size: 20,
    maxSize: 20,
  },
  {
    header: 'Reason',
    accessorKey: 'reason',
    size: 20,
    maxSize: 20,
  },
  {
    header: 'Last Modified By',
    accessorKey: 'lastModifiedBy',
    size: 20,
    maxSize: 20,
  },
  {
    header: 'Date',
    accessorKey: 'lastModifiedOn',
    size: 20,
    maxSize: 20,
    Cell: ({ renderedCellValue, row }) => (
      <Typography variant='body2'>
            {fMonthDayYearSlashNotation(row.original.lastModifiedOn)}
      </Typography>
    ),
    
  },
];

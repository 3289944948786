import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Order } from "../orderEntry/types/Order";
import { ConfirmationData, EmailContact, WordingItem } from "../confirmationForms/ConfirmationTypes";
import { convertToFileDownload } from "../utils/convertToFileDownload";
import { FileData } from "./types/types";

// ----------------------------------------------------------------------

const ConfirmationContext = createContext<ConfirmationData>({} as ConfirmationData);

function ConfirmationContextProvider({ children }: { children: JSX.Element }) {
  const { orderNumber } = useParams();
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [confirmationPdf, setConfirmationPdf] = useState<string>();
  const [order, setOrder] = useState<Order>();
  const [emailContacts, setEmailContacts] = useState<EmailContact[]>([]);
  const [body, setBody] = useState<string>();
  const [emailWordingList, setEmailWordingList] = useState<WordingItem[]>([]);
  const [includeWireInstructions, setIncludeWireInstructions] = useState<boolean>(false);
  const [includeEscrowAgreement, setIncludeEscrowAgreement] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  type UploadFilesProp = {
    orderNumber: string | undefined;
    confirmationFiles: FileData[];
  };

  const uploadFiles = (uploadFilesData: UploadFilesProp) => {
    axios.post("/api/file/uploadfiles", uploadFilesData);
  };

  const surveyFlowStates = ["ny", "nj"];
  let hasSurveyFlow = false;

  const getandSetOrderInfo = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/GetOrderInfo?orderNumber=${orderNumber}`
    });
    setOrder({
      orderNumber: data.number,
      identifier: data.identifier,
      titleCode: data.titleCode,
      propertyUse: data.propertyUse,
      state: data.properties[0].state,
      productType: data.productType,
      contacts: data.contacts,
      properties: data.properties
    });
    hasSurveyFlow =
      surveyFlowStates.includes(data.properties[0].state.toLowerCase()) && data.propertyUse === "Residential";
  };

  const getandSetConfirmationPdf = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/documentgenerator/generate?orderNumber=${orderNumber}&documentType=Confirmation of Title`
    });
    setConfirmationPdf(data.base64Document);
  };

  const generateEmailContact = (contact: any) => {
    return {
      ...contact,
      to: contact.type.includes("Point of Contact"),
      cc: contact.type === "Sales Rep" || contact.type === "Account Rep" || contact.type === "Principal",
      bcc: false
    };
  };

  const getAndSetConfirmationItems = async (state: string) => {
    const { data } = await axios({
      method: "get",
      url: `/api/intel/getConfirmationItems?state=${state}`
    });
    setEmailWordingList(data);
  };

  const getandSetEmailContacts = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/getprimarycontacts?ordernumber=${orderNumber}`
    });
    let contacts = data.map((d: any) => generateEmailContact(d));
    if (hasSurveyFlow) {
      const surveyFlow = {
        name: "Mrs. Schenkoleski",
        email: "sschenkolewski@madisontitle.com",
        type: "Survey Flow",
        to: false,
        cc: false,
        bcc: true
      };
      contacts = [...contacts, surveyFlow];
    }
    setEmailContacts(contacts);
  };

  useEffect(() => {
    getandSetConfirmationPdf();
    getandSetEmailContacts();
    getandSetOrderInfo();
  }, [orderNumber]);

  useEffect(() => {
    order?.state && getAndSetConfirmationItems(order.state);
  }, [order]);

  const onSendViaEmailClick = async () => {
    setSubmitting(true);
    const subject = "Confirmation of Title";
    const { data } = await axios.post("/api/Confirmation/generateemail", {
      Base64Document: confirmationPdf,
      toRecipients: emailContacts.filter((c) => c.to).map((c) => ({ email: c.email, name: c.name })),
      ccRecipients: emailContacts.filter((c) => c.cc).map((c) => ({ email: c.email, name: c.name })),
      bccRecipients: emailContacts.filter((c) => c.bcc).map((c) => ({ email: c.email, name: c.name })),
      clientName: emailContacts.find((c) => c.type?.includes("Point of Contact"))?.name,
      subject,
      orderNumber,
      body,
      includeWireInstructions,
      includeEscrowAgreement
    });
    convertToFileDownload(data.base64Email, "confirmation-of-title.msg");
    const name = `${subject}.pdf`;
    uploadFiles({
      orderNumber,
      confirmationFiles: [{ name, base64Contents: confirmationPdf }]
    });
    setSentEmail(data.base64Email);
    setSubmitting(false);
  };

  return (
    <ConfirmationContext.Provider
      value={{
        order,
        emailContacts,
        setEmailContacts,
        confirmationPdf,
        onSendViaEmailClick,
        sentEmail,
        emailWordingList,
        body,
        setBody,
        includeEscrowAgreement,
        includeWireInstructions,
        setIncludeWireInstructions,
        setIncludeEscrowAgreement,
        submitting,
        setSubmitting
      }}
    >
      {children}
    </ConfirmationContext.Provider>
  );
}

export { ConfirmationContextProvider, ConfirmationContext };

import { MRT_ColumnDef } from "material-react-table";
import { ThreeWayMatchedOutgoingWires, UnmatchedItem } from "./types";
import { Link, Typography } from "@mui/material";

export const threeWayMatchedOutgoingWiresColumns: MRT_ColumnDef<ThreeWayMatchedOutgoingWires>[] = [
  {
    id: "wireConfirmationDescription",
    header: "Wire Confirmation",
    accessorFn: (row) => {
      if (!row.wireConfirmationUrl || !row.wireConfirmationDescription) {
        return (
          <Typography variant="body2" color={"error"}>
            Not Found
          </Typography>
        );
      }
      return (
        <Link target="_blank" href={row.wireConfirmationUrl} sx={{ display: "block", textDecoration: "underline" }}>
          {row.wireConfirmationDescription}
        </Link>
      );
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Matching Register Transaction",
    accessorKey: "matchingRegisterTransaction",
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "matchingInvoice",
    header: "Matching Invoices",
    accessorFn: (row) => {
      if (!row.matchingInvoiceDescription || !row.matchingInvoiceUrl) {
        return <></>;
      }
      return (
        <Link target="_blank" href={row.matchingInvoiceUrl} sx={{ display: "block", textDecoration: "underline" }}>
          {row.matchingInvoiceDescription}
        </Link>
      );
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

export const unmatchedItemWithUrl: MRT_ColumnDef<UnmatchedItem>[] = [
  {
    id: "url",
    header: "Unmatched",
    accessorFn: (row) => {
      if (!row.url) {
        return <Typography variant="body2">{row.description}</Typography>;
      }
      return (
        <Link target="_blank" href={row.url} sx={{ display: "block", textDecoration: "underline" }}>
          {row.description}
        </Link>
      );
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

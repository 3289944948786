import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link as MUILink,
  Tab,
  Checkbox
} from "@mui/material";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Contact, ContactSearchResults, FullContact, Phone, SearchData } from "../types";
import { CustomAvatar } from "../../components/custom-avatar";
import { Link } from "react-router-dom";
import { getLogo } from "../../utils/getLogo";
import Highlighter from "react-highlight-words";
import LoadingSkeleton from "./LoadingSkeleton";
import { formatPhone } from "../shared/phones/formatPhone";
import { logEvent } from "../../utils/analyticsLogger";
import produce from "immer";
import { AddEditContactContext } from "../contact/addEditContact/AddEditContactContext";

interface IProps {
  contactsSearchResults: ContactSearchResults | undefined;
  searchData: SearchData;
  handlePageNumberChange: (number: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchWords: string[];
  isLoading: boolean;
  selectedContactIds: number[];
  setSelectedContactIds: Dispatch<SetStateAction<number[]>>;
}

export default function Contacts(props: IProps) {
  const {
    contactsSearchResults,
    searchData,
    handlePageNumberChange,
    handleChangeRowsPerPage,
    searchWords,
    isLoading,
    selectedContactIds,
    setSelectedContactIds
  } = props;

  const highlightStyles = { backgroundColor: "#F8FF00" };

  const handleCheck = (contact: FullContact, checked: boolean) => {
    if (checked) {
      setSelectedContactIds([...selectedContactIds, contact?.id]);
      return;
    }
    setSelectedContactIds(selectedContactIds.filter((id) => id !== contact?.id));
  };

  const formatPhoneNumber = (phoneNumber: Phone) => {
    const phone = formatPhone(phoneNumber);
    if (!phone) {
      return "";
    }
    if (phone && phoneNumber.countryCode && phoneNumber.countryCode !== "1") {
      return `+${phoneNumber.countryCode} ${phone}`;
    } else return phone;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Sales Rep</TableCell>
            <TableCell>Account Rep</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            contactsSearchResults?.data.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedContactIds?.includes(c.id)}
                    onChange={(_, value) => {
                      handleCheck(c, value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    color="initial"
                    to={`../phonebook/contact/profile?id=${c.id}`}
                    style={{
                      textDecoration: "none",
                      color: "#212B36"
                    }}
                    onClick={() => {
                      logEvent("Phonebook", "Clicked to view an individual profile");
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CustomAvatar
                        alt={`${c.firstName || ""} ${c.middleName || ""} ${c.lastName || ""}`}
                        name={`${c.firstName || ""} ${c.middleName || ""} ${c.lastName || ""}`}
                        src={getLogo(c.contactRoles[0]?.businessEmail?.split("@")[1])}
                      />
                      <Box>
                        <Typography variant="subtitle2">
                          <Highlighter
                            autoEscape
                            textToHighlight={`${c.firstName || ""} ${c.middleName || ""} ${c.lastName || ""} `}
                            highlightStyle={highlightStyles}
                            searchWords={searchWords}
                          />
                          <Typography variant="caption" display="inline-block">
                            {c.suffix}
                          </Typography>
                        </Typography>
                        {!c.contactRoles[0]?.branch?.company?.name && c.contactRoles[0]?.role?.name}
                        {c.contactRoles[0]?.branch?.company && (
                          <Typography variant="body2">
                            <Box
                              sx={{
                                fontStyle: "italic",
                                display: "inline-block"
                              }}
                            >
                              <Highlighter
                                autoEscape
                                textToHighlight={c.contactRoles[0]?.role?.name || ""}
                                highlightStyle={highlightStyles}
                                searchWords={searchWords}
                              />
                            </Box>{" "}
                            {c.contactRoles[0]?.role?.name && "at "}
                            <Link
                              color="initial"
                              to={`../phonebook/contact/profile?id=${c.id}`}
                              style={{
                                textDecoration: "none",
                                color: "#212B36"
                              }}
                            >
                              <Highlighter
                                autoEscape
                                textToHighlight={c.contactRoles[0]?.branch?.company?.name}
                                highlightStyle={highlightStyles}
                                searchWords={searchWords}
                              />
                            </Link>
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    <MUILink color="initial" href={`mailto:${c.contactRoles[0]?.businessEmail}`}>
                      <Highlighter
                        autoEscape
                        textToHighlight={c.contactRoles[0]?.businessEmail || ""}
                        highlightStyle={highlightStyles}
                        searchWords={searchWords}
                      />
                    </MUILink>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap>
                    <MUILink
                      color="initial"
                      href={`tel:+${c.contactRoles[0]?.phoneNumbers[0]?.countryCode}${
                        c.contactRoles[0]?.phoneNumbers && c.contactRoles[0]?.phoneNumbers[0]?.number
                      }`}
                    >
                      {c.contactRoles[0]?.phoneNumbers && c.contactRoles[0]?.phoneNumbers[0] && (
                        <Highlighter
                          autoEscape
                          textToHighlight={
                            c.contactRoles[0]?.phoneNumbers &&
                            c.contactRoles[0]?.phoneNumbers[0].number &&
                            formatPhoneNumber(c.contactRoles[0]?.phoneNumbers[0])
                          }
                          highlightStyle={highlightStyles}
                          searchWords={searchWords}
                        />
                      )}
                    </MUILink>
                  </Typography>
                </TableCell>
                <TableCell>
                  {c.contactReps
                    .filter((r) => r.contactRepTypeId === 1)
                    .map((r) => (
                      <Typography variant="body2">
                        {r.internalUser?.firstName} {r.internalUser?.middleName} {r.internalUser?.lastName}
                      </Typography>
                    ))}
                </TableCell>
                <TableCell>
                  {c.contactReps
                    .filter((r) => r.contactRepTypeId === 2)
                    .map((r) => (
                      <Typography variant="body2" noWrap>
                        <Typography display="inline-block" variant="subtitle2">
                          NY
                        </Typography>{" "}
                        {r.internalUser?.firstName} {r.internalUser?.middleName}
                        {r.internalUser?.lastName}
                      </Typography>
                    ))}

                  {c.contactReps
                    .filter((r) => r.contactRepTypeId === 4)
                    .map((r) => (
                      <Typography variant="body2" noWrap>
                        <Typography display="inline-block" variant="subtitle2">
                          National
                        </Typography>{" "}
                        {r.internalUser?.firstName} {r.internalUser?.middleName}
                        {r.internalUser?.lastName}
                      </Typography>
                    ))}

                  {c.contactReps
                    .filter((r) => r.contactRepTypeId === 3)
                    .map((r) => (
                      <Typography variant="body2" noWrap>
                        <Typography display="inline-block" variant="subtitle2">
                          NJ
                        </Typography>{" "}
                        {r.internalUser?.firstName} {r.internalUser?.middleName}
                        {r.internalUser?.lastName}
                      </Typography>
                    ))}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isLoading && <LoadingSkeleton />}
      {contactsSearchResults?.data.length === 0 && (
        <Box textAlign="center" padding={3}>
          <Typography>Your search for "{searchData.searchTerm}" didn't return any results</Typography>
          <Typography variant="caption">Check for spelling mistakes</Typography>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={contactsSearchResults?.count || 0}
        rowsPerPage={searchData.pageSize}
        page={searchData.pageNumber}
        onPageChange={(e, number) => handlePageNumberChange(number)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
      />
    </>
  );
}

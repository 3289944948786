import { MRT_ColumnDef, MRT_RowData } from "material-react-table";

export const orderColumnsByList = <T extends MRT_RowData>(columns: MRT_ColumnDef<T>[], sortList: string[]) => {
  const copiedColumns = [...columns];
  if (!sortList.length) return copiedColumns;

  return copiedColumns.sort((a, b) => {
    const indexA = a.id ? sortList.indexOf(a.id) : -1;
    const indexB = b.id ? sortList.indexOf(b.id) : -1;

    if (indexA === -1 && indexB === -1) {
      return 0;
    } else if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });
};

import Label from "../../../../../minimals/components/label";

export const generateLabel = (status: string) => {
  switch (status) {
    case "Complete":
      return (
        <Label variant="soft" color="success">
          Complete
        </Label>
      );
    case "Closed":
      return (
        <Label variant="soft" color="success">
          Closed
        </Label>
      );
    case "In Progress":
      return (
        <Label variant="soft" color="warning">
          In Progress
        </Label>
      );
    case "Cancelled":
      return (
        <Label variant="soft" color="error">
          Cancelled
        </Label>
      );
    default:
      return (
        <Label variant="soft" color="warning">
          {status}
        </Label>
      );
  }
};

import { countAndMapAssignedTasks } from "./commonUtils";
import { MemberTaskCount } from "../../types";
import axios from "axios";
import { EscrowLedger } from "../../escrowLedgers/types";
import dayjs from "dayjs";

const today = dayjs();
const endDate = today.add(6, "day");

export const searchEscrowOverdueOrders = async (): Promise<EscrowLedger[]> => {
  const { data } = await axios.get<EscrowLedger[]>(`/api/ledgers/getescrowledgers`);
  return data.filter((d) => {
    const dueDate = dayjs(d.dueDate);
    return (d.amount && d.dueDate && dueDate.isBefore(endDate, "day")) || (d.amount && !d.dueDate);
  });
};

export const getEscrowOverdueOrdersUniqueAssignedToList = (escrowOverdueOrders: EscrowLedger[]): MemberTaskCount[] =>
  countAndMapAssignedTasks(escrowOverdueOrders, "Escrow Overdue Orders");

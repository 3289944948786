import { Dispatch, SetStateAction, createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { TaskGroupEntityWithStatusInfo } from "../taskManagement/types";
import { Navigate } from "react-router-dom";

export type FlipsDashboardContextData = {
  flips: TaskGroupEntityWithStatusInfo[];
  loading: boolean;
  fetchFlips: (useLoader: boolean) => Promise<void>;
  isUpdatingInSelect: boolean;
  setIsUpdatingInSelect: Dispatch<SetStateAction<boolean>>;
};

const FlipsDashboardContext = createContext({} as FlipsDashboardContextData);
export const useFlips = () => useContext(FlipsDashboardContext);

export const FlipsDashboardContextProvider = ({ children }: { children: JSX.Element }) => {
  const [flips, setFlips] = useState<TaskGroupEntityWithStatusInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [isUpdatingInSelect, setIsUpdatingInSelect] = useState(false);
  const [isManager, setIsManager] = useState(true);

  const fetchFlips = async (useLoader: boolean) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<TaskGroupEntityWithStatusInfo[]>("/api/taskmanagement/flips/getForFlipsDashboard");
    setFlips(data);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkIfManager = async () => {
      const { data } = await axios.get("/api/taskmanagement/flips/IsFlipsManager");
      setIsManager(data.isManager);
    };
    checkIfManager();
  }, []);

  if (!isManager) {
    return <Navigate to="/orders" />;
  }

  return (
    <FlipsDashboardContext.Provider value={{ flips, loading, fetchFlips, isUpdatingInSelect, setIsUpdatingInSelect }}>
      {children}
    </FlipsDashboardContext.Provider>
  );
};

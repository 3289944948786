import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../minimals/components/iconify";

type PrefilledIndicatorProps = {
  color?: "inherit" | "primary" | "default" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
  title: string;
  sx?: object;
};

const PrefilledIndicator = ({ color = "primary", title, sx }: PrefilledIndicatorProps) => (
  <Tooltip title={title} sx={{ position: "absolute", right: 6, top: 32, ...sx }}>
    <IconButton color={color}>
      <Iconify icon={"eva:alert-circle-fill"} />
    </IconButton>
  </Tooltip>
);

export default PrefilledIndicator;

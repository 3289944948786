// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Card, Typography, CardHeader, Stack, Box, Divider, Button, Link } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import Label from "../../../../minimals/components/label";
import { getPhoneIcon } from "../../../utils/getPhoneIcon";
import { Company } from "../../types";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { highlightStyles } from "../../../utils/highlightStyles";

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

// ----------------------------------------------------------------------

interface IProps {
  company: Company | undefined;
  duplicateMode?: boolean;
  searchWords?: string[];
}

export default function About({ company, duplicateMode, searchWords = [] }: IProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const headquarterBranches = company?.branches?.filter((b) => b?.isHeadquarters && b?.address1);
  const nonHeadquarterBranches = company?.branches?.filter((b) => !b?.isHeadquarters && b?.address1);
  const branches = [...(headquarterBranches || []), ...(nonHeadquarterBranches || [])];

  return (
    <Card sx={duplicateMode ? { backgroundColor: theme.palette.warning.lighter } : null}>
      <CardHeader
        title={
          <Typography variant="h6">
            {duplicateMode ? (
              <Highlighter
                textToHighlight={company?.name || ""}
                highlightStyle={highlightStyles}
                searchWords={searchWords}
              />
            ) : (
              company?.name
            )}
          </Typography>
        }
      />
      <Stack spacing={2} sx={{ p: 3 }}>
        {company?.website && (
          <Stack direction="row">
            <StyledIcon icon="eva:globe-3-fill" color="#0000EE" />

            <Typography variant="body2">
              <Link color="#0000EE" target="blank" href={`https://${company?.website}`}>
                {duplicateMode ? (
                  <Highlighter
                    textToHighlight={company?.website || ""}
                    highlightStyle={highlightStyles}
                    searchWords={searchWords}
                  />
                ) : (
                  company?.website
                )}
              </Link>
            </Typography>
          </Stack>
        )}
        {company?.email && (
          <Stack direction="row">
            <StyledIcon icon="eva:email-fill" />
            <Typography variant="body2">
              <Link href={`mailto:${company.email}`}>
                {duplicateMode ? (
                  <Highlighter
                    textToHighlight={company.email || ""}
                    highlightStyle={highlightStyles}
                    searchWords={searchWords}
                  />
                ) : (
                  company.email
                )}
              </Link>
            </Typography>
          </Stack>
        )}

        {Boolean(company?.name || company?.email || company?.website) && (
          <Stack>
            <Box marginTop={1} marginBottom={1}>
              <Divider />
            </Box>
          </Stack>
        )}

        {branches.map((b, index) => (
          <>
            <Stack direction="row">
              <StyledIcon icon="eva:pin-fill" />
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Box>
                  <Typography variant="subtitle2">
                    {b.city} {b.isHeadquarters ? "Headquarters" : "Branch"}
                  </Typography>
                  <Typography variant="body2">{b.address1}</Typography>
                  {b.address2 && <Typography variant="body2">{b.address2}</Typography>}
                  <Typography variant="body2">
                    {b.city}
                    {b.city && b.state && ","} {b.state} {b.zip}
                  </Typography>
                </Box>
                {b.isHeadquarters && <Label color="warning">HQ</Label>}
              </Stack>
            </Stack>
            {b.email && (
              <Stack direction="row">
                <StyledIcon icon="eva:email-fill" />
                <Typography variant="body2">
                  <Link href={`mailto:${b.email}`}>
                    {duplicateMode ? (
                      <Highlighter
                        textToHighlight={b.email || ""}
                        highlightStyle={highlightStyles}
                        searchWords={searchWords}
                      />
                    ) : (
                      b.email
                    )}
                  </Link>
                </Typography>
              </Stack>
            )}

            {b.phone && (
              <Stack direction="row">
                <StyledIcon icon={getPhoneIcon("Business")} />

                <Typography variant="body2">
                  <Link href={`tel:${b.phone}`}>
                    {duplicateMode ? (
                      <Highlighter
                        textToHighlight={b?.phone?.replace("+", "") || ""}
                        highlightStyle={highlightStyles}
                        searchWords={searchWords}
                      />
                    ) : (
                      b.phone
                    )}
                  </Link>
                </Typography>
              </Stack>
            )}
            {index < branches.length - 1 && (
              <Stack>
                <Box marginTop={1} marginBottom={1}>
                  <Divider />
                </Box>
              </Stack>
            )}
          </>
        ))}
        {duplicateMode && (
          <Button
            variant="outlined"
            component={RouterLink}
            to={`../phonebook/company/profile?id=${company?.id}`}
            target="_blank"
            fullWidth
          >
            View
          </Button>
        )}
      </Stack>
    </Card>
  );
}

import SvgColor from "../../../../../minimals/components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  company: icon("ic_company")
};

const navConfig = [
  {
    subheader: "Workspace",
    items: [
      {
        title: "Orders",
        path: "/orders",
        icon: ICONS.menuItem
      },
      {
        title: "Closing Calendar",
        path: "/calendar",
        icon: ICONS.calendar
      }
    ]
  },
  {
    subheader: "express order placement",
    items: [
      {
        title: "Order Entry",
        path: "/order-entry",
        icon: ICONS.banking
      },
      {
        title: "Order Searches",
        path: "/searches",
        icon: ICONS.analytics
      },
      {
        title: "Confirmation",
        path: "/confirmation",
        icon: ICONS.mail
      },
      {
        title: "Notes",
        path: "/notes",
        icon: ICONS.file
      }
    ]
  },
  {
    subheader: "phonebook",
    items: [
      {
        title: "Phonebook",
        path: "/phonebook",
        icon: ICONS.user
      }
    ]
  }
];

export default navConfig;

import axios from "axios";
import { IUpdateClosingDatePayload, IUpdateClosingPayload } from "../types/calendar";

export const updateClosingInfo = async (payload: IUpdateClosingPayload) => {
  //// !Imporant. Not Passing along a settlementDate will clear out the settlement date on the order
  //// For this reason it was made required in the payload and if the intended result is to clear the date it should be passed in as null
  return await axios.post(`proxy/api/orders/UpdateClosingInformation`, payload);
};

export const updateClosingDate = async (payload: IUpdateClosingDatePayload) => {
  return await axios.post(`proxy/api/orders/UpdateClosingDate`, payload);
};

export const getClosedOrder = async (orderNumber: string) => {
  return await axios.get(`/api/calendar/getclosedorder?orderNumber=${orderNumber}`);
};
export const getClosedOrderAdditionalInfo = async (orderNumber: string) => {
  return await axios.get(`/api/calendar/GetClosedOrderAdditionalInfo?ordernumber=${orderNumber}`);
};
export const getClosingCalendarFunderAndCloser = async (orderNumber: string) => {
  return await axios.get(`proxy/api/orders/GetClosingCalendarFundAndCloser?ordernumber=${orderNumber}`);
};

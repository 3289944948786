import { Alert, Autocomplete, Button, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useContacts } from "../../../contexts/ContactsContext";
import useDebounce from "../../../../hooks/useDebounce";
import { MarketingRep } from "../../../orderEntry/types/MarketingRep";
import ClientLoaderButton from "./ClientLoaderButton";
import DuplicateClientCard from "./DuplicateClientCard";
import { Contact } from "../../../orderEntry/types/Contact";
import produce from "immer";
import validateEmail from "../../../utils/validateEmail";
import { MuiTelInput } from "mui-tel-input";
import styled from "@emotion/styled";
const titles = ["", "Mr.", "Ms.", "Mrs.", "Dr.", "Prof."];

export default function Profile({
  onNextClick,
  duplicateButtonText,
  onAfterSubmitFunction,
  name
}: {
  onNextClick: () => void;
  duplicateButtonText?: string;
  onAfterSubmitFunction?: (contact: any) => void;
  name?: string;
}) {
  const {
    currentContact,
    editContact,
    isEditMode,
    roles,
    newContact,
    submittedStatus,
    setSubmittedStatus,
    setNewContact
  } = useContacts();
  const [marketingRepList, setMarketingRepList] = useState<MarketingRep[]>([]);
  const [searchSalesRepText, setSearchSalesRepText] = useState("");
  const debouncedSearchSaleRepText = useDebounce(searchSalesRepText, 300);
  const [duplicateClientList, setDuplicateClientList] = useState<Contact[]>([]);
  const [isValid, setIsValid] = useState(true);
  let defaultValues = isEditMode ? currentContact : newContact;

  // const populateDefaultValues = (contact: Contact | undefined) => {
  //   defaultValues = useMemo(
  //     () => ({
  //       lookupCode: contact?.lookupCode || undefined,
  //       title: contact?.title || '',
  //       firstName: contact?.firstName || '',
  //       middleName: contact?.middleName || undefined,
  //       lastName: contact?.lastName || '',
  //       email: contact?.email || '',
  //       phone: contact?.phone || '',
  //       cell: contact?.cell || '',
  //       fax: contact?.fax || '',
  //       address: contact?.address1 || '',
  //       state: contact?.state || '',
  //       city: contact?.city || '',
  //       zipCode: contact?.zip || '',
  //       status: contact?.status,
  //       company: contact?.company || '',
  //       role: contact?.role || '',
  //       marketingRep: contact?.marketingRep || undefined,
  //       id: contact?.id || ''
  //     }),
  //     [contact],
  //   );
  // }

  useEffect(() => {
    setSubmittedStatus("unsubmitted");
  }, []);

  useEffect(() => {
    if (name) {
      const nameParts = name.split(" ");
      const contact = produce(newContact, (draft) => {
        draft.firstName = nameParts[0];
        draft.middleName = nameParts.length > 2 ? nameParts[1] : draft.middleName;
        draft.lastName =
          nameParts.length > 3 ? nameParts.filter((n, i) => i > 1).join(" ") : nameParts[nameParts.length > 2 ? 2 : 1];
      });
      setNewContact(contact);
    }
  }, []);

  const searchClientDuplicates = async () => {
    const { data } = await axios.get(
      `/proxy/api/clients/findmatching?firstName=${defaultValues.firstName}&lastName=${defaultValues.lastName}&email=${defaultValues.email}` +
        `&phone=${defaultValues.phone}&cell=${defaultValues.cell}&fax=${defaultValues.fax}`
    );
    let { data: dynamicsMatches } = await axios.get(
      `/proxy/api/dynamics/contacts/SearchAsSelectContact?firstName=${defaultValues.firstName}&lastName=${defaultValues.lastName}&email=${defaultValues.email}` +
        `&phone=${defaultValues.phone}&cell=${defaultValues.cell}&fax=${defaultValues.fax}`
    );
    dynamicsMatches = dynamicsMatches?.filter(
      (dynamicsContact: Contact) =>
        !data.some((phonebookContact: Contact) => dynamicsContact.lookupCode === phonebookContact.lookupCode)
    );
    dynamicsMatches.forEach((c: Contact) => (c.isDynamicsContact = true));
    setDuplicateClientList(data.concat(dynamicsMatches));
  };
  useEffect(() => {
    if (debouncedSearchSaleRepText) {
      axios({
        method: "get",
        url: "/proxy/api/salesreps/search?searchText=" + debouncedSearchSaleRepText
      }).then(function (response) {
        setMarketingRepList(response.data.sort((d: { name: string }) => d.name));
      });
    } else {
      setMarketingRepList([]);
    }
  }, [debouncedSearchSaleRepText]);

  const getShortName = (displayName: string) => {
    const nameParts = displayName.split(" ");
    const result = displayName[0] + nameParts[nameParts.length - 1];
    return result;
  };

  return (
    <>
      <Grid container spacing={3}>
        {submittedStatus != "unsubmitted" && (
          <Grid marginBottom={2} item xs={8}>
            {submittedStatus === "success" && (
              <Alert style={{ maxWidth: 712, fontWeight: "bold" }}>Your contact was saved successfully</Alert>
            )}
            {submittedStatus === "error" && (
              <Alert severity="error" style={{ maxWidth: 712, fontWeight: "bold" }}>
                There is an error with your submission
              </Alert>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        {duplicateClientList.length > 0 && (
          <Grid item xs={12}>
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  sx={{ top: 14 }}
                  onClick={() => setDuplicateClientList([])}
                >
                  Dismiss
                </Button>
              }
            >
              <Typography variant="subtitle1">Duplicates Found!</Typography>
              <Typography variant="body2">
                Similar individuals already exist. Please confirm that a duplicate entry is not being created.
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={8}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2.5}>
                <Autocomplete
                  options={titles.map((title) => title)}
                  renderInput={(params) => <TextField {...params} label="Title" />}
                  onChange={(e, value) => editContact("title", value)}
                  value={defaultValues.title}
                />
              </Grid>
              <Grid item xs={12} md={3.5}>
                <TextField
                  fullWidth
                  value={defaultValues.firstName}
                  name="firstName"
                  label="First Name"
                  onChange={(e) => editContact("firstName", e.target.value)}
                  onBlur={() => {
                    defaultValues.firstName && defaultValues.lastName && searchClientDuplicates();
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  name="middleName"
                  value={defaultValues.middleName}
                  label="Middle"
                  onChange={(e) => editContact("middleName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  name="lastName"
                  value={defaultValues.lastName}
                  label="Last Name"
                  onChange={(e) => editContact("lastName", e.target.value)}
                  onBlur={() => {
                    defaultValues.firstName && defaultValues.lastName && searchClientDuplicates();
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "0px",
                ".MuiFormControl-root": { width: "100%" }
              }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  value={defaultValues.email}
                  label="Email"
                  error={!isValid}
                  helperText={!isValid && "Invalid Email"}
                  onFocus={() => setIsValid(true)}
                  onChange={(e) => {
                    setIsValid(true);
                    editContact("email", e.target.value);
                  }}
                  onBlur={(e) => {
                    searchClientDuplicates();
                    setIsValid(validateEmail(e.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <IconStyle>
                  <MuiTelInput
                    preferredCountries={["US", "IL"]}
                    defaultCountry="US"
                    forceCallingCode
                    inputProps={{ maxLength: 12 }}
                    label="Phone"
                    value={defaultValues.phone || undefined}
                    name="phone"
                    onChange={(e) => {
                      editContact("phone", e);
                    }}
                    onBlur={() => {
                      searchClientDuplicates();
                    }}
                  />
                </IconStyle>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  name="ext"
                  // defaultValue={defaultValues.phone}
                  label="Ext."
                  // onChange={(e) => setValue('phone', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <IconStyle>
                  <MuiTelInput
                    preferredCountries={["US", "IL"]}
                    defaultCountry="US"
                    forceCallingCode
                    inputProps={{ maxLength: 12 }}
                    name="cell"
                    value={defaultValues.cell || undefined}
                    label="Mobile Phone"
                    onChange={(e) => editContact("cell", e)}
                    onBlur={() => searchClientDuplicates()}
                  />
                </IconStyle>
              </Grid>
              <Grid item xs={12} md={6}>
                <IconStyle>
                  <MuiTelInput
                    preferredCountries={["US", "IL"]}
                    defaultCountry="US"
                    forceCallingCode
                    inputProps={{ maxLength: 12 }}
                    name="fax"
                    value={defaultValues.fax || undefined}
                    label="Fax"
                    onChange={(e) => editContact("fax", e)}
                    onBlur={() => searchClientDuplicates()}
                  />
                </IconStyle>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={roles.map((role) => role)}
                  renderInput={(params) => <TextField {...params} label="Role" />}
                  onChange={(e, value) => editContact("role", value)}
                  value={defaultValues.role}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={marketingRepList}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.displayName}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option.displayName || ""}
                  onChange={(e, value) =>
                    editContact("marketingRep", {
                      ...value,
                      name: value?.displayName ? getShortName(value?.displayName) : ""
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Sales Rep" onChange={(e) => setSearchSalesRepText(e.target.value)} />
                  )}
                  value={defaultValues.marketingRep || null}
                />
              </Grid>
            </Grid>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <ClientLoaderButton onNextClick={onNextClick} />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4} spacing={2}>
          {duplicateClientList.length > 0 && (
            <Card
              sx={{
                p: 2,
                mb: 3,
                position: "sticky",
                top: 62,
                maxHeight: "100vh",
                overflowY: "scroll"
              }}
            >
              {duplicateClientList.map((dcl) => (
                <Grid>
                  <DuplicateClientCard
                    duplicateClient={dcl}
                    duplicateButtonText={duplicateButtonText}
                    onAfterSubmitFunction={onAfterSubmitFunction}
                    setDuplicateClientList={setDuplicateClientList}
                    newClient={{
                      firstName: defaultValues.firstName,
                      lastName: defaultValues.lastName,
                      email: defaultValues.email,
                      phone: defaultValues.phone,
                      cell: defaultValues.cell,
                      fax: defaultValues.fax
                    }}
                  />
                </Grid>
              ))}
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
const IconStyle = styled.div`
  .MuiTelInput-IconButton {
    img {
      display: none;
    }
  }
`;

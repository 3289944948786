import { Card, CardContent, Stack } from "@mui/material";
import MembersTable from "./MembersTable";
import SearchSection from "./SearchSection";
import { useState } from "react";

export default function Members() {
  const [searchKeywords, setSearchKeywords] = useState<string>();
  return (
    <Card>
      <CardContent>
        <Stack spacing={2.5}>
          <SearchSection setSearchKeywords={setSearchKeywords} />
          <MembersTable searchKeywords={searchKeywords} />
        </Stack>
      </CardContent>
    </Card>
  );
}

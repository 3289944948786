import { Box, Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Iconify from "../../../minimals/components/iconify";
import { fCurrency } from "../../utils/formatNumber";

interface IProps {
  color: string;
  backgroundColor: string;
  title: string;
  to: string;
  apiUrl: string;
  icon: string;
  isWire?: boolean;
}

export default function DashboardCard({ color, backgroundColor, title, to, apiUrl, icon, isWire }: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number | null>(null);

  const getData = async () => {
    const { data } = await axios.get(apiUrl);
    setData(data);
    setCount(data.length);
  };

  const amount = [...data].reduce((accumulator, currentValue) => accumulator + currentValue.Amount, 0);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          backgroundColor,
          color,
          py: 3,
          textAlign: "center",
          minHeight: 192
        }}
      >
        <CardContent>
          <Iconify icon={icon} sx={{ position: "absolute", top: 16, right: 16 }} width={32} />
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box>
              <Typography variant="h3" height="50px">
                {count ? count : <CircularProgress color="inherit" />}
              </Typography>
              <Typography variant="body2">{title}</Typography>
              {isWire && <Typography>Total: {fCurrency(amount)}</Typography>}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
}

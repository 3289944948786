import { InternalUser } from "../../types/app";
import { IContact } from "../../types/calendar";
import { Contact } from "../types/Contact";

/* Not sure why we have a type of Contact and a seperate type for IContact, but I don't have time to start changing this now */
export const convertInternalUserToContact = (user: InternalUser): Contact | IContact => {
  const { id, username, type, lookupCode, title, email, firstName, lastName, middleName, phone } = user;
  return {
    id: String(id),
    lookupCode,
    name: `${firstName} ${lastName}`.trim(),
    firstName,
    middleName,
    lastName,
    title,
    phone,
    email,
    emailsSplit: email ? email.split(";") : []
    // contactType?: ContactType | null | undefined;
  } as Contact | IContact;
};

export const getContactFullName = (contact: Contact) => {
  if (!contact) return "";
  if (contact.name) return contact.name;
  const { firstName = "", middleName = "", lastName = "" } = contact;
  const fullName = [firstName, middleName, lastName].filter(Boolean).join(" ");
  return fullName.trim();
};

export function removeMiddleInitial(name: string) {
  // Step 1: Remove non-alphabetic characters except for spaces
  const cleanedName = name.replace(/[^a-zA-Z\s]/g, "");
  // Step 2: Split the string into an array of words
  const words = cleanedName.split(" ");
  // Step 3: Filter out any single-character words
  const filteredWords = words.filter((word) => word.length > 1);
  // Step 4: Join the remaining words back into a single string
  const result = filteredWords.join(" ");
  return result.trim();
}

import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useContext } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider, DateRangePicker, DateRange, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import Count from "./Count";
import { DashboardReportType } from "../../checks/types";
import ReassignButtonAndModal from "../ReassignButtonAndModal";
import { PoliciesNotIssuedContext } from "./PoliciesNotIssuedContext";

interface IProps {
  dateRange: DateRange<dayjs.Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  count: number;
  hideAssignedToMeOptions: boolean;
  table: any;
  onRowsDeselected: () => void;
}

export default function Actions({
  dateRange,
  setDateRange,
  assignedToMe,
  setAssignedToMe,
  count,
  hideAssignedToMeOptions,
  table,
  onRowsDeselected
}: IProps) {
  const { fetchPoliciesNotIssued } = useContext(PoliciesNotIssuedContext);
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={dateRange}
          localeText={{
            start: "Start-Date",
            end: "End-Date"
          }}
          onAccept={(newValue) => {
            setDateRange(newValue);
          }}
          calendars={3}
          slotProps={{ textField: { size: "small" } }}
          slots={{ field: SingleInputDateRangeField }}
          format="MM/DD/YY"
          sx={{ width: 210 }}
        />
      </LocalizationProvider>
      <Count count={count} />
      {!hideAssignedToMeOptions && (
        <FormControlLabel
          control={<Checkbox checked={assignedToMe} onChange={(_, value) => setAssignedToMe(value)} />}
          label="Assigned to me"
        />
      )}
      <ReassignButtonAndModal
        table={table}
        fetchTableData={() => fetchPoliciesNotIssued(true)}
        reportType={DashboardReportType.PoliciesNotIssued}
        onRowsDeselected={onRowsDeselected}
      />
    </Stack>
  );
}

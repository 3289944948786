import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { IToastProps } from "../@types/app";

const ToastMessage = ({
  open = false,
  message = "",
  autoHideDuration = null,
  onClose = () => {},
  severity = "error",
  anchorOrigin = { vertical: "top", horizontal: "center" }
}: IToastProps) => {
  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;

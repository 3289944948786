import { MRT_ColumnDef } from "material-react-table";
import { IconButton, Link, Typography } from "@mui/material";
import { ContactWithSuspectedMatch } from "../../phonebook/types";
import NavigationIconButton from "./NavigateIcon";
import Iconify from "../../../minimals/components/iconify";
import axios from "axios";

const ignoreSuspectedMatch = async (
  contactId1: number,
  contactId2: number,
  setSuspectedMatch: React.Dispatch<React.SetStateAction<ContactWithSuspectedMatch[]>>
) => {
  try {
    await axios.post("/api/clientphonebook/contacts/ignoreSuspectedMatch", { contactId1, contactId2 });
    setSuspectedMatch((prevData) =>
      prevData
        .map((contact) => {
          if (contact.id === contactId1) {
            return {
              ...contact,
              suspectedMatches: contact.suspectedMatches.filter((s) => s.contact.id !== contactId2)
            };
          }
          return contact;
        })
        .filter((contact) => contact.suspectedMatches.length > 0)
    );
  } catch (error) {
    console.error("Error ignoring suspected match:", error);
  }
};

export const suspectedMatchesColums = ({
  setSuspectedMatch,
  suspectedMatch
}: {
  setSuspectedMatch: React.Dispatch<React.SetStateAction<ContactWithSuspectedMatch[]>>;
  suspectedMatch: ContactWithSuspectedMatch[];
}): MRT_ColumnDef<ContactWithSuspectedMatch>[] => [
  {
    header: "Date/Time Created",
    accessorKey: "createdDate",
    accessorFn: (row) => {
      return new Date(row.createdDate).toLocaleDateString();
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    }
  },
  {
    header: "Contact",
    accessorFn: (row) => {
      return (
        <Link target="_blank" rel="noreferrer" href={`/phonebook/contact/profile?id=${row.id}`}>
          <Typography variant="body2">{row.name || ""}</Typography>
        </Link>
      );
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    }
  },
  {
    id: "suspectedMatches",
    header: "Suspected Duplicates",
    accessorFn: (row) => {
      return row?.suspectedMatches?.map((s) => (
        <Link target="_blank" rel="noreferrer" href={`/phonebook/contact/profile?id=${s.contact?.id}`}>
          <Typography variant="body2" color="error">
            {s.contact?.firstName || ""} {s.contact?.lastName}
          </Typography>
        </Link>
      ));
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    }
  },
  {
    id: "matchingField",
    header: "Matching Field",
    accessorFn: (row) => {
      return row?.suspectedMatches?.map((s) => (
        <Typography variant="body2" color="error">
          {s.matchedOnBusinessEmail && "Matched on Email"}
          {s.matchedOnName && "Matched on Name"}
          {s.matchedOnPhoneNumber && "Matched on Phone"}

          <NavigationIconButton
            to={`../phonebook/contactsMerge?ids=${row.id}&ids=${s.contact.id}`}
            openInNewTab={true}
            aria-label="Merge Contacts"
          />

          <IconButton onClick={() => ignoreSuspectedMatch(row.id, s.contact.id, setSuspectedMatch)}>
            <Iconify icon="tabler:equal-not" />
          </IconButton>
        </Typography>
      ));
    }
  }
];

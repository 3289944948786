import { Button, DialogActions } from "@mui/material";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  mergedOrderResult: ContactOrder[];
  indexToProcess: number;
  setIndexToProcess: Dispatch<SetStateAction<number>>;
  isAllOrdersSuccessful: boolean;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  navigateToProfile: string;
}
export default function MergedOrdersActions({
  mergedOrderResult,
  indexToProcess,
  setIndexToProcess,
  isAllOrdersSuccessful,
  step,
  setStep,
  navigateToProfile
}: IProps) {
  const navigate = useNavigate();
  const { setShowOrdersModal, contact } = useContext(AddEditContactContext);

  return (
    <>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        {step === 1 && !isAllOrdersSuccessful && (
          <Button
            onClick={() => {
              setStep(2);
            }}
            variant="contained"
            disabled={indexToProcess < mergedOrderResult.length}
          >
            {indexToProcess >= mergedOrderResult.length ? "Overview" : "Merging..."}
          </Button>
        )}
        {step === 2 && !isAllOrdersSuccessful && (
          <>
            <Button
              onClick={() => {
                setStep(3);
                setIndexToProcess(0);
              }}
              variant="outlined"
            >
              Try Again
            </Button>
            <Button
              onClick={() => {
                setStep(3);
                setIndexToProcess(0);
              }}
              variant="contained"
            >
              Verify I did it
            </Button>
          </>
        )}
        {isAllOrdersSuccessful && (
          <>
            <Button
              onClick={() => {
                navigate(navigateToProfile);
                setShowOrdersModal(false);
              }}
              variant="outlined"
            >
              Close
            </Button>
          </>
        )}
        {step === 3 && !isAllOrdersSuccessful && (
          <>
            <Button
              onClick={() => {
                setStep(3);
                setIndexToProcess(0);
              }}
              variant="outlined"
            >
              Try Again
            </Button>
            <Button
              onClick={() => {
                setShowOrdersModal(false);
                navigate(navigateToProfile);
              }}
              variant="outlined"
            >
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
}

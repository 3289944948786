// @mui
import { Stack, Typography, Box } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../../../contexts/UserContext";
// locales
import { useLocales } from "../../../../../minimals/locales";

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useContext(UserContext);

  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: "block",
        textAlign: "center"
      }}
    >
      <div>
        <Typography gutterBottom variant="subtitle1">
          {`${translate("docs.hi")}, ${user?.name}`}
        </Typography>
      </div>
    </Stack>
  );
}

import { Stack, Typography, Skeleton, Divider, IconButton, Box, Link } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";
import OpenInHomePageButton from "../../searches/OpenInHomePageButton";
import OpenInSelectButton from "../../searches/OpenInSelectButton";
import GooglePin from "../../shared/orderInfo/GooglePin";
import { useOrderInfo } from "../../shared/orderInfo/OrderInfoContext";
import { useState } from "react";

const Properties = () => {
  const orderInfo = useOrderInfo();
  const [propertyIndex, setPropertyIndex] = useState<number>(0);

  const nextProperty = () => {
    setPropertyIndex((prev) => prev + 1);
  };

  const previousProperty = () => {
    setPropertyIndex((prev) => prev - 1);
  };

  return (
    <Box maxWidth={650}>
      <Stack direction="row" spacing={3}>
        <Box>
          <Typography variant="subtitle1">
            {orderInfo?.properties[propertyIndex].address1}
            {!orderInfo && <Skeleton />}
          </Typography>
          <Typography variant="subtitle1">
            {orderInfo?.properties[propertyIndex].city}
            {orderInfo?.properties[propertyIndex].city && ","} {orderInfo?.properties[propertyIndex].state}{" "}
            {orderInfo?.properties[propertyIndex].zip}
            {!orderInfo && <Skeleton />}
          </Typography>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            {orderInfo?.properties[propertyIndex].state !== "NJ" &&
              orderInfo?.properties[propertyIndex].state !== "NY" && (
                <Box>
                  <Typography variant="caption">Parcel Id</Typography>
                  <Typography variant="subtitle1">
                    {orderInfo?.properties[propertyIndex].parcelIds.join(", ")}
                    {!orderInfo && <Skeleton />}
                  </Typography>
                </Box>
              )}
            {(orderInfo?.properties[propertyIndex].state === "NJ" ||
              orderInfo?.properties[propertyIndex].state === "NY") && (
              <Box>
                <Typography variant="caption">Section</Typography>
                <Typography variant="subtitle1">
                  {orderInfo?.properties[propertyIndex].section}
                  {!orderInfo && <Skeleton />}
                </Typography>
              </Box>
            )}
            {(orderInfo?.properties[propertyIndex].state === "NJ" ||
              orderInfo?.properties[propertyIndex].state === "NY") && (
              <Box>
                <Typography variant="caption">Block</Typography>
                <Typography variant="subtitle1">
                  {orderInfo?.properties[propertyIndex].block}
                  {!orderInfo && <Skeleton />}
                </Typography>
              </Box>
            )}
            {(orderInfo?.properties[propertyIndex].state === "NJ" ||
              orderInfo?.properties[propertyIndex].state === "NY") && (
              <Box>
                <Typography variant="caption">Lot</Typography>
                <Typography variant="subtitle1">
                  {" "}
                  {orderInfo?.properties[propertyIndex].lots.join(", ")}
                  {!orderInfo && <Skeleton />}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography variant="caption">County</Typography>
              <Typography variant="subtitle1">
                {orderInfo?.properties[propertyIndex].county}
                {!orderInfo && <Skeleton />}
              </Typography>
            </Box>
          </Stack>
          <Divider sx={{ marginTop: 2, marginBottom: 0.5 }} />
          <Box sx={{ left: -8, position: "relative" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton size="small" onClick={previousProperty} disabled={propertyIndex === 0}>
                <Iconify icon="eva:arrow-ios-back-outline" />
              </IconButton>
              <Typography variant="subtitle2">
                Property {propertyIndex + 1} of {orderInfo?.properties.length}
              </Typography>
              <IconButton
                size="small"
                onClick={nextProperty}
                disabled={orderInfo?.properties && propertyIndex === orderInfo?.properties?.length - 1}
              >
                <Iconify icon="eva:arrow-ios-forward-fill" />
              </IconButton>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box sx={{ position: "relative", top: 4 }}>
                  <OpenInSelectButton identifier={orderInfo?.identifier || ""} />
                </Box>
                <OpenInHomePageButton orderNumber={orderInfo?.number || ""} />
                <Link
                  href={`https://maps.google.com/?q=${orderInfo?.properties[propertyIndex].address1},${orderInfo?.properties[propertyIndex].city},${orderInfo?.properties[propertyIndex].state}`}
                  target="_blank"
                >
                  <IconButton>
                    <GooglePin />
                  </IconButton>
                </Link>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Properties;

import { useRef, useState } from "react";
import { createFilterOptions, Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { IOption } from "../@types/app";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  options: IOption[];
  sx?: Object;
  selectedOptions: any[];
  setSelectedOptions: (value: React.SetStateAction<any>) => void;
  label: string;
  id: string;
  limitTags?: number;
  optionsLimit?: number;
  getMenuItemStyles?: Function;
  onlyShowOptionsOnTyping?: boolean;
  placeholder?: string;
  style?: Object;
  // onChange: Function,
  handleSelect: ({
    selectedOption,
    selectedOptions,
    setSelectedOptions
  }: {
    selectedOption: IOption;
    selectedOptions: IOption[];
    setSelectedOptions: (value: React.SetStateAction<any>) => void;
    label?: string;
  }) => void;
  defaultOptions?: Object[];
  multiple?: boolean;
}

const CustomAutocomplete = ({
  options = [],
  sx = {},
  selectedOptions,
  setSelectedOptions = () => {},
  label = "",
  id,
  limitTags = 2,
  optionsLimit = 10,
  getMenuItemStyles = () => {},
  onlyShowOptionsOnTyping = false,
  placeholder = "",
  style = {},
  handleSelect = () => {},
  defaultOptions = [],
  multiple = false
}: Props) => {
  const OPTIONS_LIMIT = useRef(optionsLimit);
  const defaultFilterOptions = createFilterOptions();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  ////We only want to add these props when user needs to type to see results
  const onlyShowOptionsOnTypingProps = {
    open: open,
    onOpen: () => {
      // only open when in focus and inputValue is not empty
      if (inputValue) {
        setOpen(true);
      }
    },
    onClose: () => setOpen(false),
    inputValue: inputValue,
    onInputChange: (e: React.SyntheticEvent<Element, Event>, value: string) => {
      setInputValue(value);
      if (value && !open) {
        setOpen(true);
      }
      // only open when inputValue is not empty after the user typed something
      if (!value) {
        setOpen(false);
      }
    }
  };

  return (
    <Autocomplete
      value={selectedOptions}
      {...(onlyShowOptionsOnTyping ? onlyShowOptionsOnTypingProps : {})}
      onChange={(event, newValue) => setSelectedOptions(newValue)}
      filterOptions={(options, state) => {
        const results = defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT.current);
        if (defaultOptions.length) {
          results.unshift(...defaultOptions);
        }
        return results;
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      size="small"
      multiple={multiple}
      limitTags={limitTags}
      id={id}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.value}
            style={getMenuItemStyles(option.value)}
            onClick={() =>
              handleSelect({
                selectedOption: option,
                setSelectedOptions,
                selectedOptions,
                label
              })
            }
          >
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.label}
          </li>
        );
      }}
      style={style}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          placeholder={placeholder || (onlyShowOptionsOnTyping ? "Start typing..." : "")}
          sx={sx}
        />
      )}
    />
  );
};
export default CustomAutocomplete;

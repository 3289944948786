import {
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ContactHistory, CompanyHistory, HistoryType } from "../../types";
import axios from "axios";
import { ContactContext } from "../../contact/ContactContext";
import { CompanyContext } from "../../company/CompanyContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

interface IProps {
  type?: HistoryType;
}

export default function History({ type = HistoryType.CONTACT }: IProps) {
  const { contact } = useContext(ContactContext);
  const { company } = useContext(CompanyContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [history, setHistory] = useState<ContactHistory[] | CompanyHistory[]>([]);

  const getAndSetHistory = async () => {
    const url =
      type == HistoryType.CONTACT
        ? `/api/clientphonebook/contactHistory/getByContactId/${contact?.id}`
        : `/api/clientphonebook/companyHistory/getByCompanyId/${company?.id}`;
    const { data } = await axios.get(url);
    setHistory(data);
    setLoading(false);
  };

  useEffect(() => {
    if ((contact?.id && type == HistoryType.CONTACT) || (company?.id && type == HistoryType.COMPANY)) {
      getAndSetHistory();
    }
  }, [contact?.id, company?.id]);

  const generateMailLink = (username: string) => {
    if (type == HistoryType.CONTACT) {
      return `mailto:${username}?subject=${contact?.firstName} ${contact?.lastName}&body=Regarding https://titleq.mcres.com/phonebook/contact/history?id=${contact?.id}`;
    }
    return `mailto:${username}?subject=${company?.name}&body=Regarding https://titleq.mcres.com/phonebook/company/history?id=${company?.id}`;
  };

  return (
    <Card>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Field Name</TableCell>
            <TableCell>Before</TableCell>
            <TableCell>After</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((h) => (
            <StyledTableRow key={h.id}>
              <StyledTableCell sx={{ verticalAlign: "top" }}>
                <Typography variant="subtitle2">{new Date(h.date).toLocaleDateString()}</Typography>
                <Typography variant="body2">
                  {new Date(h.date).toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit"
                  })}
                </Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ verticalAlign: "top" }}>
                <Typography variant="body2">{h.eventType}</Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ verticalAlign: "top" }}>
                <Typography variant="body2">
                  <Link href={generateMailLink(h.username)}>{h.username?.split("@")[0]}</Link>
                </Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ verticalAlign: "top", paddingRight: 0 }}>
                {h.changes?.map((c, i) => (
                  <Typography variant="subtitle2" key={i}>
                    {c.friendlyFieldName}
                  </Typography>
                ))}
              </StyledTableCell>
              <StyledTableCell sx={{ verticalAlign: "top" }}>
                {h.changes?.map((c, i) => (
                  <Typography variant="body2" key={i}>
                    {c.oldValue || "--"}
                  </Typography>
                ))}
              </StyledTableCell>
              <StyledTableCell sx={{ verticalAlign: "top" }}>
                {h.changes?.map((c, i) => (
                  <Typography variant="body2" key={i}>
                    {c.newValue || "--"}
                  </Typography>
                ))}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {!loading && history.length === 0 && (
        <Typography padding={3} textAlign="center">
          No Information
        </Typography>
      )}
    </Card>
  );
}

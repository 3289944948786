import { Autocomplete, Paper, TableCell, TableRow, TextField, styled } from "@mui/material";
import { EventType, StatusCategory } from "./types";

interface TaskFiltersProps {
  chosenStatusCategories: StatusCategory[];
  setChosenStatusCategories: (filters: StatusCategory[]) => void;
  statusCategories: StatusCategory[],
  activityFilters: EventType[];
  setActivityFilters: (filters: EventType[]) => void;
  assignedToFilters: any[];
  setAssignedToFilters: (filters: any[]) => void;
  associationFilters: any[];
  setAssociationFilters: (filters: any[]) => void;
  taskFilter: string;
  setTaskFilter: (filter: string) => void;
  taskQuickNoteFilter: string;
  setTaskQuickNoteFilter: (filter: string) => void;
  associationFilterOptions: any[];
  assignedToFilterOptions: any[];
  activityFilterOptions: any[];
  filtersVisible: boolean;
  setFiltersVisible: (visible: boolean) => void;
  columns: any[];
  eventTypes: EventType[];
  applicationId: number;
}

const CustomPaper = styled(Paper)({
  width: "300px"
});

const TaskFilters = ({
  chosenStatusCategories,
  setChosenStatusCategories,
  activityFilters,
  setActivityFilters,
  assignedToFilters,
  setAssignedToFilters,
  associationFilters,
  setAssociationFilters,
  taskFilter,
  setTaskFilter,
  taskQuickNoteFilter,
  setTaskQuickNoteFilter,
  associationFilterOptions,
  assignedToFilterOptions,
  activityFilterOptions,
  filtersVisible,
  columns,
  statusCategories,
  applicationId
}: TaskFiltersProps) => {
  const isFlips = applicationId == 1;
  return (
    <>
      <TableRow
        sx={{
          display: filtersVisible ? "table-row" : "none",
          th: {
            backgroundColor: "#fafafa"
          }
        }}
      >
        {!isFlips && <TableCell />}
        {columns.map((column) => (
          <TableCell style={{ paddingBottom: 12, paddingTop: 2 }} key={column.key}>
            {column.key === "description" && (
              <TextField
                fullWidth
                label="Task"
                value={taskFilter}
                onChange={(e) => setTaskFilter(e.target.value)}
                size="small"
                variant="standard"
              />
            )}
            {column.key === "quickNote" && (
              <TextField
                fullWidth
                label="Quick Note"
                value={taskQuickNoteFilter}
                onChange={(e) => setTaskQuickNoteFilter(e.target.value)}
                size="small"
                variant="standard"
              />
            )}
            {column.key === "status" && (
              <Autocomplete
                multiple
                value={chosenStatusCategories}
                options={statusCategories}
                onChange={(_, value) => setChosenStatusCategories(value)}
                getOptionLabel={(option) => option.description}
                filterSelectedOptions
                size="small"
                PaperComponent={(props) => <CustomPaper {...props} />}
                renderInput={(params) => <TextField label="Status" {...params} variant="standard" />}
              />
            )}
            {column.key === "association" && (
              <Autocomplete
                multiple
                value={associationFilters}
                options={associationFilterOptions}
                onChange={(_, value) => setAssociationFilters(value)}
                getOptionLabel={(option) => {
                  if (option.represents) {
                    return `${option.represents} ${option.type} - ${option.name}`;
                  }
                  return `${option.code} - ${option.name}`;
                }}
                filterSelectedOptions
                size="small"
                PaperComponent={(props) => <CustomPaper {...props} />}
                renderInput={(params) => <TextField {...params} label="Responsible Party" variant="standard" />}
              />
            )}
            {column.key === "activityDate" && (
              <Autocomplete
                multiple
                id="activity"
                value={activityFilters}
                options={activityFilterOptions}
                onChange={(_, value) => setActivityFilters(value)}
                getOptionLabel={(option) => option.description}
                filterSelectedOptions
                size="small"
                PaperComponent={(props) => <CustomPaper {...props} />}
                renderInput={(params) => <TextField {...params} label="Activity" variant="standard" />}
              />
            )}
            {column.key === "assignedTo" && (
              <Autocomplete
                multiple
                id="assignedTo"
                value={assignedToFilters}
                options={assignedToFilterOptions}
                onChange={(_, value) => setAssignedToFilters(value)}
                filterSelectedOptions
                size="small"
                PaperComponent={(props) => <CustomPaper {...props} />}
                renderInput={(params) => <TextField {...params} label="Assigned To" variant="standard" />}
              />
            )}
          </TableCell>
        ))}
        <TableCell />
        <TableCell />
      </TableRow>
    </>
  );
};

export default TaskFilters;

import { Grid, IconButton, Typography } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import { MouseEventHandler } from "react";

interface IProps {
  title: string;
  handleEditClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}

export default function HeaderTitle({ title, handleEditClick, isDisabled = false }: IProps) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h6" display="inline-block">
          {title}
        </Typography>
      </Grid>
      {handleEditClick && (
        <Grid item xs={1} textAlign="right">
          <IconButton color="primary" onClick={handleEditClick} disabled={isDisabled}>
            <Iconify icon="eva:edit-fill" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

import { countAndMapAssignedTasks } from "./commonUtils";
import { Check } from "../../../checks/types";
import { MemberTaskCount } from "../../types";
import { DateRange } from "@mui/lab";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";

export const getChecksUniqueAssignedToList = (checks: Check[]): MemberTaskCount[] =>
  countAndMapAssignedTasks(checks, "Outstanding Checks");

export const defaultStartDate: Dayjs = dayjs("7/4/2023");

export const searchChecks = async (dateRange?: DateRange<Dayjs>): Promise<Check[]> => {
  const url = dateRange
    ? `/api/OutstandingChecks/GetChecks?from=${dateRange[0]}&to=${dateRange[1]}`
    : `/api/OutstandingChecks/GetChecks`;

  const { data } = await axios.get<Check[]>(url);
  return data;
};

import { Box, Grid, Typography } from "@mui/material";
import { Order } from "./types/Order";
import OpenInHomePageButton from "../searches/OpenInHomePageButton";
import OpenInSelectButton from "../searches/OpenInSelectButton";
// ----------------------------------------------------------------------

type OrderProp = {
  order: Order | undefined;
};

export default function OrderHeader(headerProp: OrderProp) {
  const { order } = headerProp;

  return (
    <Grid container sx={{ marginTop: 4, marginBottom: 4 }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4} textAlign="center">
        <Typography variant="subtitle1" color="GrayText">
          REFERENCE:{" "}
          <Typography sx={{ display: "inline-block" }} fontWeight={700}>
            {order?.titleCode ? order?.titleCode + "-" : ""}
            {order?.orderNumber}
          </Typography>
          <Box
            sx={{
              display: "inline-block",
              marginLeft: 2,
              position: "relative",
              top: 6,
              left: -4
            }}
          >
            <OpenInSelectButton identifier={order?.identifier} />
          </Box>
          <Box
            sx={{
              display: "inline-block",
              position: "relative",
              top: -2,
              left: -4
            }}
          >
            <OpenInHomePageButton orderNumber={order?.orderNumber} />
          </Box>
        </Typography>
        <Typography variant="subtitle2" color="GrayText">
          {order?.propertyUse?.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
}

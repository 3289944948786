import React from "react";
import { Link, Container, Typography, Box } from "@mui/material";

const pages = [
  { name: "AddEditCompanyForm", url: "/phonebook/edit-company" },
  { name: "AddEditContactForm", url: "/phonebook/create-contact" },
  { name: "BlackListNameSearch", url: "/blacklistSearch" },
  { name: "CalendarPage", url: "/calendar" },
  { name: "Checks", url: "/checks" },
  { name: "ClosingsPage", url: "/closings" },
  { name: "CommitmentsOverdue", url: "/commitments-overdue" },
  { name: "CompaniesPage", url: "/phonebook/company/profile?id=3035" },
  { name: "Contacts", url: "/phonebook/contacts/individuals" },
  { name: "ContactsPage", url: "/phonebook/contact/profile?id=30163" },
  { name: "CorpDocsManageTasksPage", url: "/corpdocs/manage-tasks" },
  { name: "CorpDocsPage", url: "/corpdocs" },
  { name: "CorpDocsTasksPage", url: "/corpdocs/tasks" },
  { name: "Dashboard", url: "/dashboard" },
  { name: "EditContactHistory", url: "/phonebook/edit-history" },
  { name: "EscrowOverdueOrders", url: "/escrow-overdue-orders" },
  { name: "FlipsDashboard", url: "/flips-dashboard" },
  { name: "FundsTransfer", url: "https://funds-transfer.mcres.com" },
  { name: "InsuranceAudit", url: "/insurance-audit" },
  { name: "ManagersDashboard", url: "/dashboard2" },
  { name: "ManageTasksPage", url: "/recordables/manage-tasks" },
  { name: "MergeCompaniesPage", url: "/phonebook/companiesMerge?ids=3035&ids=21585" },
  { name: "MergeContacts", url: "/phonebook/contactsMerge?ids=30163&ids=40103" },
  { name: "NonZeroLedgerBalance", url: "/non-zero-ledger-balances" },
  { name: "OperatingTransfers", url: "https://operatingtransfers.mcres.com/request/new" },
  { name: "Orders", url: "/orders" },
  { name: "OutstandingSearches", url: "/outstanding-searches" },
  { name: "PageConfirmation", url: "/confirmation" },
  { name: "PageLoginSucess", url: "/loginsuccess" },
  { name: "PageNotes", url: "/notes" },
  { name: "PageOrderEntry", url: "/order-entry/order-information" },
  { name: "PoliciesNotIssued", url: "/policies-not-issued" },
  { name: "RecordablesPage", url: "/recordables" },
  { name: "SearchesNotOrdered", url: "/searches-not-ordered" },
  { name: "SuspectedMatches", url: "/phonebook/suspectedMatches" },
  { name: "Tasks", url: "/task-management/:taskType" },
  { name: "TasksPage", url: "/recordables/tasks" },
  { name: "TMEOrders", url: "/orders" }
];

const Directory = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" marginBottom={2}>
        Description
      </Typography>
      <Box>
        {pages.map((page, index) => (
          <Box key={index} marginBottom={1}>
            <Link href={page.url} variant="body1" underline="hover" target="_blank">
              {page.name}
            </Link>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Directory;

import { Autocomplete, TextField } from "@mui/material";
import { usStateList } from "../../utils/usStateList";

interface IProps {
  usMode: boolean;
  state: string | null;
  handleChange: any;
  onFocus: any;
  onBlur: any;
}

export default function StateAutocomplete({ usMode, state, handleChange, onFocus, onBlur }: IProps) {
  let stateListOpened = !!state && !usStateList.includes(state);
  return (
    <Autocomplete
      options={usMode ? usStateList : []}
      open={stateListOpened}
      onBlur={() => {
        stateListOpened = false;
        onBlur && onFocus();
      }}
      onFocus={() => {
        stateListOpened = true;
        onFocus && onFocus();
      }}
      value={state || ""}
      freeSolo
      onChange={(e, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password"
          }}
          fullWidth
          label="State"
          onChange={(e) => handleChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    />
  );
}

import { ContactsOutlined, ExpandCircleDown } from "@mui/icons-material";
import {
  Typography,
  Card,
  Stack,
  styled,
  Accordion,
  Button,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid
} from "@mui/material";
import { useContext, useState } from "react";
import EditProfilePreviewCard from "./EditProfilePreviewCard";
import { display } from "@mui/system";
import Iconify from "../../minimals/components/iconify";
import { IFrameContext } from "../contexts/IFrameContext";
import { CurriedFunction1 } from "lodash";
import { Contact } from "./types/Contact";

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

interface iProps {
  contact: Contact | null;
  index: number;
  open: boolean;
  onAccordianClick: any;
  onClick?: () => void;
  updateCurrentContact: CurriedFunction1<Contact, void>;
}

export default function ProfilePreviewCard(props: iProps) {
  const { contact, open, onAccordianClick, updateCurrentContact } = props;
  const [editProfileModal, setEditProfileModal] = useState(false);
  const { openPhonebookIFrame } = useContext(IFrameContext);

  return (
    <>
      {editProfileModal && (
        <EditProfilePreviewCard
          onClose={() => {
            setEditProfileModal(false);
          }}
          editProfileModal={editProfileModal}
          contact={contact}
        />
      )}
      <Accordion
        expanded={open}
        onChange={onAccordianClick}
        sx={{
          "&:before": {
            backgroundColor: "transparent"
          }
        }}
      >
        <AccordionSummary expandIcon={<Iconify icon={`eva:arrow-ios-downward-fill`} />}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={9}>
              <Typography variant="h6" sx={{ position: "relative" }}>
                {contact?.name}
                {!open && contact?.company && (
                  <Typography variant="body2">
                    <span style={{ fontWeight: 600 }}>@ {contact.company}</span>
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {open && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (contact && contact.id) {
                      openPhonebookIFrame({
                        defaultName: "",
                        onSave: (contact: Contact) => updateCurrentContact(contact),
                        selectedLookupCode: contact.lookupCode || "",
                        pageView: contact.isIndividual ? "view-contact" : "view-company"
                      });
                      // window.open(`../phonebook/contact/profile?id=${contact.id}`, "_blank")?.focus();
                    }
                  }}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {
              <>
                {contact?.role && (
                  <Typography variant="body2">
                    {contact.role} {contact?.company && "at"}{" "}
                    <span style={{ fontWeight: 600 }}> {contact?.company}</span>
                  </Typography>
                )}
                {contact?.email && (
                  <Stack direction="row">
                    <IconStyle icon={"eva:email-fill"} />
                    <Typography variant="body2">{contact.email}</Typography>
                  </Stack>
                )}

                {contact?.phone && (
                  <Stack direction="row">
                    <IconStyle icon={"carbon:phone-filled"} />
                    <Typography variant="body2">{contact.phone}</Typography>
                  </Stack>
                )}

                {contact?.cell && (
                  <Stack direction="row">
                    <IconStyle icon={"carbon:phone-filled"} />
                    <Typography variant="body2">{contact.cell}</Typography>
                  </Stack>
                )}
                <Stack direction="row">
                  {(contact?.address1 || contact?.address2) && (
                    <>
                      <IconStyle icon={"eva:pin-fill"} />
                      <Typography sx={{ fontWeight: 400 }}>
                        {contact?.company && <Typography variant="subtitle2">{contact.company}</Typography>}
                        {contact?.address1 && <Typography variant="subtitle2">{contact.address1}</Typography>}
                        {contact?.address2 && <Typography variant="subtitle2">{contact.address2}</Typography>}
                        <Typography variant="subtitle2">
                          {contact.city} {contact.state} {contact.zip}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </Stack>
                {contact?.clientInstructions && (
                  <Stack direction="row">
                    <IconStyle icon={"material-symbols:note-outline"} />
                    <Typography variant="body2" color="error">
                      {contact?.clientInstructions}
                    </Typography>
                  </Stack>
                )}
                {typeof contact?.notes === "string" && (
                  <Stack direction="row">
                    <IconStyle icon={"material-symbols:note-outline"} />
                    <Typography variant="body2" color="error">
                      {contact?.notes}
                    </Typography>
                  </Stack>
                )}
                {typeof contact?.notes !== "string" &&
                  contact?.notes?.map((n, i) => (
                    <Stack direction="row" key={i}>
                      <IconStyle icon={"material-symbols:note-outline"} />
                      <Typography variant="body2" color="error">
                        {n}
                      </Typography>
                    </Stack>
                  ))}
              </>
            }
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Iconify from "../../../minimals/components/iconify";
import { TABS } from "./TABS";
import { useNavigate, useParams } from "react-router-dom";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import Contacts from "./Contacts";
import { useImmer } from "use-immer";
import {
  ActiveStatusFilter,
  CompanySearchResults,
  CompanyType,
  ContactSearchResults,
  Role,
  SearchData
} from "../types";
import { debounce, values } from "lodash";
import axios, { CancelToken, CancelTokenSource } from "axios";
import Companies from "./Companies";
import ComingSoon from "../shared/ComingSoon";
import { logEvent } from "../../utils/analyticsLogger";
import { AddEditContactContext } from "../contact/addEditContact/AddEditContactContext";
import { useQueryParam, StringParam } from "use-query-params";

export default function ContactList() {
  const { section } = useParams();

  const Section = {
    INDIVIDUALS: "individuals",
    DEACTIVATED_INDIVIDUALS: "deactivatedindividuals",
    DEACTIVATED_COMPANIES: "deactivatedcompanies",
    COMPANIES: "companies",
    BLACKLISTED: "blacklisted"
  };

  const [isLoading, setIsLoading] = useState(true);
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const [contactsSearchResults, setContactsSearchResults] = useState<ContactSearchResults>();
  const [deactivatedContactsSearchResults, setDeactivatedContactsSearchResults] = useState<ContactSearchResults>();
  const [companiesSearchResults, setCompaniesSearchResults] = useState<CompanySearchResults>();
  const [deactivatedCompaniesSearchResults, setDeactivatedCompaniesSearchResults] = useState<CompanySearchResults>();
  const [query, setQuery] = useQueryParam("query", StringParam);
  const contactCompaniesOnlyOption = { id: -1, name: "All Non Vendors" };
  const defaultOption = { id: 0, name: "All" };
  const defaultSelectedRole = (
    localStorage.getItem("selectedRole")
      ? JSON.parse(localStorage.getItem("selectedRole") || "")
      : null || defaultOption
  ) as Role;

  const defaultSelectedCompanyType = (
    localStorage.getItem("selectedCompanyType")
      ? JSON.parse(localStorage.getItem("selectedCompanyType") || "")
      : null || contactCompaniesOnlyOption
  ) as CompanyType;
  const defaultSearchData = {
    searchTerm: "",
    pageSize: 100,
    pageNumber: 0,
    roleId: -1,
    companyTypeId: -1,
    isContactOnlySearch: defaultSelectedRole?.id === -1,
    isContactCompanyOnlySearch: defaultSelectedCompanyType?.id === -1,
    activeStatus: ActiveStatusFilter.ActiveOnly
  } as SearchData;
  const [searchData, setSearchData] = useImmer<SearchData>({
    ...defaultSearchData,
    searchTerm: query || "",
    roleId: defaultSelectedRole?.id || null,
    companyTypeId: defaultSelectedCompanyType?.id || null
  });

  const contactOnlyOption = { id: -1, name: "All Clients" };
  const [roleList, setRoleList] = useState<Role[]>([contactOnlyOption, defaultOption]);
  const [companyTypeList, setCompanyTypeList] = useState<CompanyType[]>([contactCompaniesOnlyOption, defaultOption]);
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>([]);
  const [companiesToMerge, setCompaniesToMerge] = useState<Set<number>>(new Set());
  const MAX_COMPANIES_TO_MERGE = 3;

  const getAndSetRoles = async () => {
    const { data } = await axios.get(`/api/clientphonebook/roles/search`);
    setRoleList((prev) => [...prev, ...data]);
  };

  const getAndSetCompanyTypes = async () => {
    const { data } = await axios.get(`/api/clientphonebook/companies/getAllTypes`);
    setCompanyTypeList((prev) => [...prev, ...data]);
  };

  let cancelTokenSource: CancelTokenSource | undefined;

  const performSearch = async (
    apiEndpoint: string,
    searchData: SearchData,
    searchTerm: string,
    setSearchResults: Function,
    cancelToken: CancelToken
  ) => {
    let loadingTimeout: string | number | NodeJS.Timeout | undefined;

    const startLoadingTimer = () => {
      loadingTimeout = setTimeout(() => {
        setIsLoading(true);
      }, 1000); // Adjust the timeout value to your desired duration
    };

    const clearLoadingTimer = () => {
      clearTimeout(loadingTimeout);
    };

    try {
      setIsLoading(false);
      startLoadingTimer();
      const cleanedSearchTerm = searchTerm.replace(",", "");
      const { data } = await axios.post(
        apiEndpoint,
        {
          ...searchData,
          roleId: searchData.roleId === 0 ? null : searchData.roleId,
          companyTypeId: searchData.companyTypeId === 0 ? null : searchData.companyTypeId,
          searchTerm: cleanedSearchTerm
        },
        {
          cancelToken // Pass the cancellation token to the request
        }
      );

      clearLoadingTimer();

      setSearchResults(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Search canceled:", error.message);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const searchContactsCommon = async (
    searchTerm: string,
    setData: (data: any) => void,
    additionalData: object = {}
  ) => {
    if (cancelTokenSource) {
      // Cancel the previous search request if it exists
      cancelTokenSource.cancel("Operation canceled by the user.");
    }

    // Create a new cancellation token
    cancelTokenSource = axios.CancelToken.source();

    await performSearch(
      "/api/clientphonebook/contacts/getPaginated",
      { ...searchData, ...additionalData },
      searchTerm,
      setData,
      cancelTokenSource.token
    );
  };

  const searchContacts = async (searchTerm: string) => {
    await searchContactsCommon(searchTerm, setContactsSearchResults);
  };

  const searchDeactivatedContacts = async (searchTerm: string) => {
    await searchContactsCommon(searchTerm, setDeactivatedContactsSearchResults, {
      activeStatus: ActiveStatusFilter.InactiveOnly
    });
  };

  const searchCompaniesCommon = async (
    searchTerm: string,
    setData: (data: any) => void,
    additionalData: object = {}
  ) => {
    if (cancelTokenSource) {
      // Cancel the previous search request if it exists
      cancelTokenSource.cancel("Operation canceled by the user.");
    }

    // Create a new cancellation token
    cancelTokenSource = axios.CancelToken.source();

    await performSearch(
      "/api/clientphonebook/companies/getPaginated",
      { ...searchData, ...additionalData },
      searchTerm,
      setData,
      cancelTokenSource.token
    );
  };

  const searchCompanies = async (searchTerm: string) => {
    await searchCompaniesCommon(searchTerm, setCompaniesSearchResults);
  };

  const searchDeactivatedCompanies = async (searchTerm: string) => {
    await searchCompaniesCommon(searchTerm, setDeactivatedCompaniesSearchResults, {
      activeStatus: ActiveStatusFilter.InactiveOnly
    });
  };

  const debouncedSearchContacts = useCallback(
    debounce((val) => {
      searchContacts(val);
      setQuery(val, "replaceIn");
      logEvent("Phonebook", "Searched", `Searched ${val}`);
    }, 250),
    []
  );

  const handleContactTermChange = (searchTerm: string) => {
    setSearchData((draft) => {
      draft.searchTerm = searchTerm;
    });
    debouncedSearchContacts(searchTerm);
  };

  const debouncedSearchDeactivatedContacts = useCallback(
    debounce((val) => {
      searchDeactivatedContacts(val);
      setQuery(val, "replaceIn");
    }, 250),
    []
  );
  const handleDeactivatedContactTermChange = (searchTerm: string) => {
    setSearchData((draft) => {
      draft.searchTerm = searchTerm;
    });
    debouncedSearchDeactivatedContacts(searchTerm);
  };

  const debouncedSearchCompanies = useCallback(
    debounce((val) => {
      searchCompanies(val);
      setQuery(val, "replaceIn");
    }, 250),
    []
  );

  const debouncedSearchDeactivatedCompanies = useCallback(
    debounce((val) => {
      searchDeactivatedCompanies(val);
      setQuery(val, "replaceIn");
    }, 250),
    []
  );
  const handleCompanyTermChange = (searchTerm: string) => {
    setSearchData((draft) => {
      draft.searchTerm = searchTerm;
    });
    debouncedSearchCompanies(searchTerm);
  };

  const handleDeactivatedCompanyTermChange = (searchTerm: string) => {
    setSearchData((draft) => {
      draft.searchTerm = searchTerm;
    });
    debouncedSearchDeactivatedCompanies(searchTerm);
  };

  const handlePageNumberChange = (number: number) => {
    setSearchData((draft) => {
      draft.pageNumber = number;
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchData((draft) => {
      draft.pageSize = parseInt(event.target.value, 10);
      draft.pageNumber = 0;
    });
  };

  const handleRoleChange = (selectedRole: Role) => {
    localStorage.setItem("selectedRole", JSON.stringify(selectedRole));

    setSearchData((draft) => {
      draft.roleId = selectedRole.id;
      draft.isContactOnlySearch = selectedRole.id == -1;
    });
  };

  const handleCompanyTypeChange = (selectedCompanyType: CompanyType) => {
    localStorage.setItem("selectedCompanyType", JSON.stringify(selectedCompanyType));
    setSearchData((draft) => {
      draft.companyTypeId = selectedCompanyType.id;
      draft.isContactCompanyOnlySearch = selectedCompanyType.id == -1;
    });
  };

  const renderMergeButton = () => {
    if (section == Section.INDIVIDUALS) {
      return (
        <IconButton
          disabled={selectedContactIds.length !== 2}
          onClick={() => {
            const queryParams = selectedContactIds.map((id) => `ids=${id}`).join("&");
            navigate(`../phonebook/contactsMerge?${queryParams}`);
          }}
        >
          <Iconify icon="lucide:merge" />
        </IconButton>
      );
    }

    if (section == Section.COMPANIES) {
      const baseURL = "/phonebook/companiesMerge";

      const queryParams = Array.from(companiesToMerge)
        .map((id) => `ids=${id}`)
        .join("&");

      return (
        <IconButton
          disabled={companiesToMerge.size <= 1 || companiesToMerge.size > MAX_COMPANIES_TO_MERGE}
          onClick={() => {
            navigate(`${baseURL}?${queryParams}`);
          }}
        >
          <Iconify icon="lucide:merge" />
        </IconButton>
      );
    }
  };

  useEffect(() => {
    if (section === Section.INDIVIDUALS) {
      searchContacts(searchData.searchTerm);
    } else if (section === Section.DEACTIVATED_INDIVIDUALS) {
      searchDeactivatedContacts(searchData.searchTerm);
    } else if (section === Section.DEACTIVATED_COMPANIES) {
      searchDeactivatedCompanies(searchData.searchTerm);
    } else {
      searchCompanies(searchData.searchTerm);
    }
  }, [searchData.pageNumber, searchData.pageSize, searchData.roleId, searchData.companyTypeId, section]);

  useEffect(() => {
    setSearchData((draft) => {
      draft.pageNumber = 0;
    });
  }, [section, searchData.searchTerm, searchData.roleId, searchData.companyTypeId]);

  useEffect(() => {
    getAndSetRoles();
    getAndSetCompanyTypes();
  }, []);

  const searchWords = useMemo(() => searchData.searchTerm.split(" "), [searchData.searchTerm]);
  return (
    <>
      <Helmet>
        <title> Contacts | TitleQ</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
          <Typography variant="h4">Contacts</Typography>
          <Button
            size="medium"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate("../phonebook/create-contact")}
          >
            New
          </Button>
        </Stack>
        <Card>
          <CardContent>
            <Grid container marginBottom={3}>
              <Grid item md={5} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" />
                      </InputAdornment>
                    )
                  }}
                  value={searchData.searchTerm}
                  placeholder="Search"
                  onChange={(e) => {
                    if (section === Section.INDIVIDUALS) {
                      handleContactTermChange(e.target.value);
                    } else if (section === Section.COMPANIES) {
                      handleCompanyTermChange(e.target.value);
                    } else if (section === Section.DEACTIVATED_INDIVIDUALS) {
                      handleDeactivatedContactTermChange(e.target.value);
                    } else if (section === Section.DEACTIVATED_COMPANIES) {
                      handleDeactivatedCompanyTermChange(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
              <Grid item md={3} xs={12}>
                {section === Section.INDIVIDUALS && (
                  <Autocomplete
                    defaultValue={defaultSelectedRole}
                    fullWidth
                    renderInput={(params) => <TextField {...params} placeholder="Search" label="Roles/Professions" />}
                    onChange={(_, value) => {
                      if (!value) return;
                      handleRoleChange(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={roleList}
                  />
                )}
                {section === Section.COMPANIES && (
                  <Autocomplete
                    defaultValue={defaultSelectedCompanyType}
                    fullWidth
                    renderInput={(params) => <TextField {...params} placeholder="Search" label="Company Types" />}
                    onChange={(_, value) => {
                      if (!value) return;
                      handleCompanyTypeChange(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={companyTypeList}
                  />
                )}
              </Grid>
            </Grid>
            <Box
              paddingLeft={3}
              paddingRight={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: theme.palette.background.neutral
              }}
            >
              <Tabs
                value={section}
                onChange={(e, value) => {
                  let queryString = "";
                  if (query) {
                    queryString = `?query=${query}`;
                  }
                  navigate(`../phonebook/contacts/${value}${queryString}`);
                }}
              >
                {TABS.filter((tab) => tab.visible).map((t) => (
                  <Tab label={t.label} value={t.value} key={t.value} />
                ))}
              </Tabs>
              <Stack direction="row">
                {renderMergeButton()}
                <IconButton>
                  <Iconify icon="material-symbols:download" />
                </IconButton>
                <IconButton onClick={() => null}>
                  <Iconify icon="eva:trash-2-outline" />
                </IconButton>
              </Stack>
            </Box>
            <Divider />
            {section === Section.INDIVIDUALS && (
              <Contacts
                selectedContactIds={selectedContactIds}
                setSelectedContactIds={setSelectedContactIds}
                contactsSearchResults={contactsSearchResults}
                searchData={searchData}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handlePageNumberChange={handlePageNumberChange}
                searchWords={searchWords}
                isLoading={isLoading}
              />
            )}
            {section === Section.COMPANIES && (
              <Companies
                companiesSearchResults={companiesSearchResults}
                searchData={searchData}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handlePageNumberChange={handlePageNumberChange}
                searchWords={searchWords}
                isLoading={isLoading}
                companiesToMerge={companiesToMerge}
                setCompaniesToMerge={setCompaniesToMerge}
                maxCompaniesToMerge={MAX_COMPANIES_TO_MERGE}
              />
            )}
            {section === Section.DEACTIVATED_INDIVIDUALS && (
              <Contacts
                selectedContactIds={selectedContactIds}
                setSelectedContactIds={setSelectedContactIds}
                contactsSearchResults={deactivatedContactsSearchResults}
                searchData={searchData}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handlePageNumberChange={handlePageNumberChange}
                searchWords={searchWords}
                isLoading={isLoading}
              />
            )}
            {section === Section.DEACTIVATED_COMPANIES && (
              <Companies
                companiesSearchResults={deactivatedCompaniesSearchResults}
                searchData={searchData}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handlePageNumberChange={handlePageNumberChange}
                searchWords={searchWords}
                isLoading={isLoading}
                companiesToMerge={companiesToMerge}
                setCompaniesToMerge={setCompaniesToMerge}
                maxCompaniesToMerge={MAX_COMPANIES_TO_MERGE}
              />
            )}
            {section === Section.BLACKLISTED && <ComingSoon />}
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

import { Stack, TextField, Card, Autocomplete, Grid, Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Iconify from "../../minimals/components/iconify";
import BulletinUpload from "./BulletinUpload";
import BulletinUploadModal from "./BulletinUploadModal";
import { BulletinInformation, UploadInfo } from "./types";

interface Props {
  bulletinInfo: BulletinInformation;
  setBulletinInfo: (e: any, value: any) => void;
  addUpload: (value: UploadInfo) => void;
  uploads: UploadInfo[];
  handleRemoveUpload: (index: number) => void;
}

export default function BulletinInfo(props: Props) {
  const [openNewUploadModal, setOpenNewUploadModal] = useState(false);
  const [states, setStates] = useState<any[]>([]);
  const [counties, setCounties] = useState<any[]>([]);


  const getStates = async () => {
    const { data } = await axios.get("/api/blacklist/getstates");
    setStates(data);
  };

  const handleStateChange = (selectedState: any) => {
    if (selectedState) {
      setCounties(selectedState.counties || []); 
      props.setBulletinInfo("state", selectedState);
    } else {
      setCounties([]);
      props.setBulletinInfo("state", null);
      props.setBulletinInfo("county", null);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Card sx={{ p: 3, maxWidth: 780, marginTop: 4 }}>
      <Stack spacing={2} direction="row" justifyContent={"space-between"} marginTop="40px" marginBottom={8}>
        <Autocomplete
          options={states || []}
          renderInput={(params) => <TextField {...params} label="State" fullWidth />}
          getOptionLabel={(option) => option.name || ""}
          onChange={(e, value) => handleStateChange(value)}
          value={props.bulletinInfo?.state || null}
          fullWidth
        />

        <Autocomplete
          options={counties || []}
          renderInput={(params) => <TextField {...params} label="County" fullWidth />}
          getOptionLabel={(option) => option.name || ""}
          onChange={(e, val) => props.setBulletinInfo("county", val)}
          value={props.bulletinInfo?.county || null}
          fullWidth
        />
      </Stack>
      <Stack direction={"row"} spacing={2} alignItems="center" justifyContent="space-between" gap={2}>
        <Stack>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="eva:upload-fill" />}
            onClick={() => setOpenNewUploadModal(true)}
          >
            Bulletin
          </Button>
        </Stack>
        <Stack direction={"column"}>
          {props.uploads?.map((u, i) => {
            return (
              <Stack marginTop={2}>
                <BulletinUpload
                  index={i}
                  upload={u}
                  onSave={props.addUpload}
                  handleRemoveUpload={props.handleRemoveUpload}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Grid container spacing={2} marginBottom={3} marginTop={3}>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            rows={4}
            placeholder="Notes"
            onChange={(e) => props.setBulletinInfo("note", e.target.value)}
            value={props.bulletinInfo?.note}
            sx={{
              "& fieldset": {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[200]} !important`
              }
            }}
          />
        </Grid>
      </Grid>
      {openNewUploadModal && (
        <BulletinUploadModal
          open={openNewUploadModal}
          onClose={() => setOpenNewUploadModal(false)}
          onSave={props.addUpload}
        />
      )}
    </Card>
  );
}

import { useContext, useEffect, useState } from "react";
// @mui
import {
  Grid,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Stack,
  Autocomplete,
  TextField
} from "@mui/material";
// components
import { OrderContext } from "../contexts/OrderDataContext";
import axios from "axios";

// ----------------------------------------------------------------------

export default function OrderType() {
  const {
    isRush,
    setIsRush,
    isConfidential,
    setIsConfidential,
    transactionType,
    setTransactionType,
    productTypeList,
    selectedProductType,
    setSelectedProductType,
    projectName,
    setProjectName,
    orderType,
    setOrderType
  } = useContext(OrderContext);

  const [orderTypeList, setOrderTypeList] = useState<string[]>([]);

  const getOrderType = async () => {
    const { data } = await axios.get("/proxy/api/orders/getOrderTypes");
    setOrderTypeList(data);
  };

  useEffect(() => {
    getOrderType();
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} xs={12}>
          <ToggleButtonGroup size="small" value={transactionType} color="primary">
            <ToggleButton value="Purchase" onClick={() => setTransactionType("Purchase")}>
              Purchase
            </ToggleButton>
            <ToggleButton value="Refinance" onClick={() => setTransactionType("Refinance")}>
              Refinance
            </ToggleButton>
            <ToggleButton value="LastOwnerSearch" onClick={() => setTransactionType("LastOwnerSearch")}>
              Search Only
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack alignItems="flex-end">
            <FormControlLabel
              control={<Switch checked={isRush} onChange={() => setIsRush(!isRush)} />}
              label="Rush Order"
            />
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Stack alignItems="flex-end">
            <FormControlLabel
              control={<Switch checked={isConfidential} onChange={() => setIsConfidential(!isConfidential)} />}
              label="Confidential"
            />
          </Stack>
        </Grid>
        <Grid item md={6} xs={12} position="relative" marginBottom={2} marginTop={1}>
          <TextField
            fullWidth
            label="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Start typing..."
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item md={6} xs={12} position="relative" marginBottom={2} marginTop={1}>
          <Autocomplete
            value={orderType}
            options={orderTypeList}
            onChange={(e, value) => setOrderType(value || "")}
            renderInput={(params) => <TextField {...params} label="Order Type" placeholder="Select a Order Type" />}
          />
        </Grid>
        {transactionType === "LastOwnerSearch" && (
          <Grid item md={6} xs={12} position="relative" marginBottom={2} marginTop={1}>
            <Autocomplete
              value={selectedProductType}
              options={productTypeList}
              getOptionLabel={(option) => option}
              onChange={(e, value) => setSelectedProductType(value)}
              renderInput={(params) => <TextField {...params} label="Product Type" placeholder="Start typing..." />}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

import { NotesContextProvider } from "../contexts/NotesContext";
import Notes from "../notes/Notes";

// ----------------------------------------------------------------------

export default function PageNotes() {
  return (
    <NotesContextProvider>
      <Notes />
    </NotesContextProvider>
  );
}

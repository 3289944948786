import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router";
import { Company } from "../../types";

interface IProps {
  companyToKeep: Company;
}

const NoOrdersToMergeDialog = ({ companyToKeep }: IProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>Companies Merged Successfully!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">No Orders To Merge</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate(`../phonebook/company/profile?id=${companyToKeep.id}`);
            }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoOrdersToMergeDialog;

import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Stack,
  Autocomplete
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useOrderInfo } from "../shared/orderInfo/OrderInfoContext";
import { Contact } from "../shared/orderInfo/types";
import { BasicTask } from "./types";

const AddTaskModal = ({
  open,
  closeModal,
  applicationId,
  orderNumber,
  refreshTasks,
  tasks
}: {
  open: boolean;
  closeModal: () => void;
  applicationId: number;
  orderNumber: string | null;
  refreshTasks: () => Promise<void>;
  tasks: BasicTask[];
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedParentTaskId, setSelectedParentId] = useState<number | null>(null);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const orderInfo = useOrderInfo();

  const excludedContactTypes = [
    "Abstractor",
    "Appraiser",
    "GeneralContractor",
    "Government",
    "HOA",
    "HomeInspector",
    "Surveyor",
    "Underwriter",
    "SettlementAgent"
  ];

  const contacts = orderInfo?.contacts.filter((c) => c.name && !excludedContactTypes.includes(c.type)) || [];

  const save = async () => {
    setSubmitting(true);  
    await axios.post("/api/taskmanagement/tasks/add", {
      applicationId,
      orderNumber,
      description,
      parentTaskId: selectedParentTaskId,
      contactCode: selectedContact?.code
    }); 
    refreshTasks();
    closeModal();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3}>
          New Task
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <Stack direction="column" spacing={2}>
            <Autocomplete
              options={tasks.filter((t) => t.parentTaskId === null)}
              renderInput={(params) => <TextField {...params} label="Parent Task (optional)" />}
              onChange={(_, val) => setSelectedParentId(val?.id || null)}
              getOptionLabel={(option) => option.description}
            />
            <TextField
              fullWidth
              label="Task Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Autocomplete
                 renderInput={(params) => (
                  <TextField {...params} placeholder="Responsible Party" size="small" variant="outlined" />
                )}
                getOptionLabel={(o) => {
                  if (o.type === "Attorney" && o.represents) {
                    let { represents } = o;
                    if (represents.charAt(represents.length - 1) !== "s") {
                      represents += "s";
                    }
                    return `${represents} Attorney - ${o.name}`;
                  }
                  return `${o.code} - ${o.name || ""}`;
                }}
                options={contacts}
                value={selectedContact}
                onChange={(_, value) => setSelectedContact(value)}
              />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} color="inherit">
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={save}>
          {submitting ? "Saving...." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTaskModal;

import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { InternalUser } from "../../../types/app";
import { ContactContext } from "../../contact/ContactContext";
import ClientCultivatorsAutocomplete from "./ClientCultivatorsAutocomplete";
import { refreshContact } from "./helpers/refreshContact";

interface IProps {
  setEditMode: () => void;
}

export default function EditClientCultivator({ setEditMode }: IProps) {
  const { contact, setContact } = useContext(ContactContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [clientCultivator, setClientCultivator] = useState<InternalUser | null>(contact?.clientCultivator || null);

  const submit = async () => {
    setSubmitting(true);
    await axios.post("/api/clientphonebook/contacts/updateClientCultivator", {
      contactId: contact?.id,
      ClientCultivatorId: clientCultivator?.id
    });
    await refreshContact({ contact, setContact });
    setEditMode();
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Client Cultivator</Typography>}></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <ClientCultivatorsAutocomplete
            clientCultivator={clientCultivator}
            setClientCultivator={setClientCultivator}
            label="Client Cultivator"
          />
          <Stack direction="row" justifyContent="right" spacing={1.5}>
            <Button variant="outlined" onClick={setEditMode}>
              Cancel
            </Button>
            <Button variant="contained" onClick={submit} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

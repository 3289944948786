import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ContactContext } from "../../contact/ContactContext";
import OrdersFilter from "./orders/OrdersFilter";
import OrdersTable from "./orders/OrdersTable";
import InvoicesFilter from "./invoices/InvoicesFilter";
import InvoicesTable from "./invoices/InvoicesTable";
import { ContactOrder } from "./types";
import axios from "axios";

export default function OrdersAndInvoices({ type }: { type: "contact" | "company" }) {
  const { contact } = useContext(ContactContext);
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const section = searchParams.get("section");
  const navigate = useNavigate();

  const displayOrders = !section || section === "orders";
  const displayInvoices = !displayOrders;

  const [orders, setOrders] = useState<ContactOrder[]>([]);
  const [orderFilterStatus, setOrderFilterStatus] = useState("All");

  useEffect(() => {
    const getOrders = async () => {
      const url = type === "contact" ? "contacts/getcontactorders?contactid=" : "companies/getorders?companyid=";
      const { data } = await axios.get(`/api/clientphonebook/${url}${id}`);
      setOrders(data);
    };
    getOrders();
  }, []);

  let ordersToDisplay = orders;
  if (orderFilterStatus !== "All") {
    ordersToDisplay = orders.filter((o) => o.orderStatus === orderFilterStatus);
  }

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 3.5
          }}
        >
          <Tab label={`${orders.length} Orders`} value="orders" />
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            {displayOrders && (
              <OrdersFilter
                statuses={["All", ...new Set(orders.map((o) => o.orderStatus!))]}
                selectedStatus={orderFilterStatus}
                onStatusChange={setOrderFilterStatus}
              />
            )}
            {displayInvoices && <InvoicesFilter />}
          </Box>
        </Box>
        {displayOrders && <OrdersTable orders={ordersToDisplay} />}
        {displayInvoices && <InvoicesTable />}
      </CardContent>
    </Card>
  );
}

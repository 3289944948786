import Papa from "papaparse";
import { BasicTask } from "./taskManagement/types";
import { MRT_TableInstance } from "material-react-table";

interface RowData {
  [key: string]: string;
}

export const exportToCsv = (data: RowData[], fileName: string) => {
  const csvString = Papa.unparse(data);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportTableToCsv = <T extends object>(table: MRT_TableInstance<T>, fileName: string) => {
  const columnDefs = table
    .getAllColumns()
    .map((c) => c.columnDef)
    .filter((c) => c.header && c.header !== "Select" && c.header != "Actions");

  const tableData = table.getPrePaginationRowModel().rows.map((row) => {
    const rowDataForDownload: RowData = {};
    columnDefs.forEach((c) => {
      rowDataForDownload[c.header] = c.id ? row.getValue(c.id) || "" : "";
    });
    return rowDataForDownload;
  });

  exportToCsv(tableData, fileName);
};

export const copyTasksToClipboard = async (tasks: BasicTask[]): Promise<boolean> => {
  try {
    const generateTaskHtml = (task: BasicTask): string => {
      const parentTaskDescription = task.parentTask?.description ? `${task.parentTask.description} - ` : "";
      const taskHtml = `<li>${parentTaskDescription}${task.description}</li>`;
      return taskHtml;
    };

    const taskBullets = tasks.map((task) => generateTaskHtml(task));
    const htmlContent = `<ul>${taskBullets.join("")}</ul>`;
    const htmlBlob = new Blob([`<html><body>${htmlContent}</body></html>`], {
      type: "text/html"
    });
    const data = [new ClipboardItem({ "text/html": htmlBlob })];
    await navigator.clipboard.write(data);
    return true;
  } catch (error) {
    console.error("Error copying to clipboard:", error);
    return false;
  }
};

export const predefinedDomains = [
  "gmail.com",
  "gmail.co",
  "yahoo.com",
  "aol.com",
  "outlook.com",
  "hotmail.com",
  "icloud.com",
  "protonmail.com",
  "zoho.com",
  "earthlink.net",
  "mail.com",
  "mac.com",
  "optonline.com",
  "optonline.net",
  "msn.com",
  "sbcglobal.net",
  "verizon.net",
  "comcast.net",
  "airmail.net",
  "att.net",
  "ymail.com",
  "optimum.net",
  "prodigy.net",
  "juno.com",
  "live.com",
  "me.com",
  "spectrum.net",
  "twcny.rr.com",
  "si.rr.com",
  "hvc.rr.com",
  "bellsouth.net",
  "cs.com",
  "embarqmail.com",
  "frontier.com",
  "frontiernet.net",
  "ptd.net",
  "roadrunner.com",
  "windstream.net"
];

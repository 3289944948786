import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Checkbox, CircularProgress, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ActiveStatusLabel from "../shared/ActiveStatusLabel";
import InternalUserAutocomplete from "../shared/InternalUserAutocomplete";
import {
  InternalUser,
  InternalUserDepartment,
  InternalUserRole,
  InternalUserRoleType,
  InternalUserTeam
} from "../types/app";
import RoleOptionsDropdown from "./RoleOptionsDropdown";

const EditInternalUserRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getEmptyRole = () => ({
    internalUserId: internalUser.id,
    internalUserDepartmentId: 0,
    internalUserRoleTypeId: 0,
    internalUserTeamId: 0,
    isPrimaryRole: false,
    internalUserRoleType: null,
    internalUserTeam: null,
    internalUserDepartment: null,
    internalUser: null,
    errors: {}
  });

  const [internalUser, setInternalUser] = useState<InternalUser>({} as InternalUser);
  const [roles, setRoles] = useState<(InternalUserRole & { errors?: { [key: string]: boolean } })[]>([getEmptyRole()]);

  const [pairedWiths, setPairedWiths] = useState<InternalUser[]>([]);
  const [assignedTME, setAssignedTME] = useState<InternalUser | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [teamOptions, setTeamOptions] = useState<InternalUserTeam[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<InternalUserDepartment[]>([]);
  const [roleTypeOptions, setRoleTypeOptions] = useState<InternalUserRoleType[]>([]);
  const [error, setError] = useState<string>("");

  const resetForm = () => {
    setInternalUser({} as InternalUser);
    setRoles([getEmptyRole()]);
    setPairedWiths([]);
    setAssignedTME(null);
  };

  const showErrorToast = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 3500,
      anchorOrigin: { vertical: "bottom", horizontal: "center" }
    });
  };

  const handleRoleChange = (index: number, key: keyof InternalUserRole, value: number) => {
    const updatedRoles = roles.map((role, i) => {
      if (i === index) {
        const newRole = { ...role, [key]: value };
        const errors = { ...role.errors };

        if (key === "internalUserDepartmentId" && value !== 0) {
          errors.internalUserDepartmentId = false;
        }
        if (key === "internalUserTeamId" && value !== 0) {
          errors.internalUserTeamId = false;
        }
        if (key === "internalUserRoleTypeId" && value !== 0) {
          errors.internalUserRoleTypeId = false;
        }
        return { ...newRole, errors };
      }
      return role;
    });

    setRoles(updatedRoles);
    setError("");
  };

  const handleIsPrimaryRoleChange = (index: number, value: boolean) => {
    const newRoles = roles.map((role, i) => {
      /* There can only be one primary role so if its true set all others to false*/
      return { ...role, isPrimaryRole: i === index && value };
    });
    setRoles(newRoles);
  };

  const addRole = () => {
    setRoles([...roles, getEmptyRole()]);
  };

  const deleteRole = (index: number) => {
    setRoles(roles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (id) {
      const getInternalUserWithRoles = async (id: string) => {
        const { data } = await axios.get<InternalUser>(`/api/clientphonebook/internalusers/getById/${id}`);
        setInternalUser(data);
        if (data?.internalUserRoles?.length) {
          setRoles(data.internalUserRoles.map((role) => ({ ...role, errors: {} })));
        }
        if (data?.internalUserPairedWiths?.length) {
          const pairedWithUsers = data.internalUserPairedWiths
            .filter((d) => d.pairedWithInternalUser)
            .map((d) => d.pairedWithInternalUser as InternalUser);
          setPairedWiths(pairedWithUsers);
        }
        if (data?.internalUserTme?.assignedToTme) {
          setAssignedTME(data.internalUserTme.assignedToTme);
        }
      };
      getInternalUserWithRoles(id);
    }
  }, [id]);

  const handleSave = async () => {
    let isValid = true;
    const updatedRoles = roles.map((role) => {
      const errors = {
        internalUserDepartmentId: role.internalUserDepartmentId === 0,
        internalUserTeamId: role.internalUserTeamId === 0,
        internalUserRoleTypeId: role.internalUserRoleTypeId === 0
      };

      const hasErrors = Object.values(errors).some((error) => error);

      if (hasErrors) {
        isValid = false;
      }
      return { ...role, errors };
    });

    const primaryRolesCount = updatedRoles.filter((role) => role.isPrimaryRole).length;
    if (primaryRolesCount > 1) {
      setError("There can only be one primary role.");
      isValid = false;
    } else {
      setError("");
    }

    const seenRoles = new Set();
    const hasDuplicates = updatedRoles.some((role) => {
      const roleString = `${role.internalUserDepartmentId}-${role.internalUserTeamId}-${role.internalUserRoleTypeId}`;
      if (seenRoles.has(roleString)) {
        return true;
      }
      seenRoles.add(roleString);
      return false;
    });

    if (hasDuplicates) {
      setError("Duplicate roles are not allowed.");
      isValid = false;
    }

    setRoles(updatedRoles);

    if (!isValid) {
      return;
    }

    try {
      const { data: success } = await axios.post<boolean>(
        `/api/clientphonebook/internalusers/UpdateInternalUserRoles`,
        {
          internalUserId: internalUser.id,
          roles: updatedRoles,
          pairedWiths,
          assignedTME
        }
      );

      enqueueSnackbar("Roles sucessfully saved.", {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });

      resetForm();
      navigate(`../internal-user-roles?searchText=${internalUser.fullName}`);
    } catch (error) {
      showErrorToast("An error occurred while saving roles.");
    }
  };

  useEffect(() => {
    const getTeamsDeptsAndRoles = async () => {
      const { data: roleOpts } = await axios.get<InternalUserRoleType[]>(
        `/api/clientphonebook/internalusers/getInternalUserRoleTypeOptions`
      );
      setRoleTypeOptions(roleOpts);
      const { data: teamOpts } = await axios.get<InternalUserTeam[]>(
        `/api/clientphonebook/internalusers/getInternalUserTeamOptions`
      );
      setTeamOptions(teamOpts);
      const { data: deptOptions } = await axios.get<InternalUserDepartment[]>(
        `/api/clientphonebook/internalusers/getInternalUserDepartmentOptions`
      );
      setDepartmentOptions(deptOptions);
    };
    getTeamsDeptsAndRoles();
  }, []);

  if (!internalUser.id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3} sx={{ backgroundColor: "white", borderRadius: 2, boxShadow: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          Assign a Role, Team, Department to {internalUser.fullName || ""} {internalUser.email || ""}
        </Typography>
        <ActiveStatusLabel isActive={internalUser.isActiveSelectUser} />
      </Box>
      <Stack direction="row" spacing={2} marginTop={2}>
        <InternalUserAutocomplete
          sx={{ minWidth: 200, marginRight: 2 }}
          label="Assigned TME"
          value={assignedTME}
          onChange={(e) => setAssignedTME(e as InternalUser | null)}
          multiple={false}
          size="medium"
        />
        <InternalUserAutocomplete
          sx={{ width: 500 }}
          label="Paired With"
          value={pairedWiths}
          onChange={(e) => setPairedWiths(e as InternalUser[])}
          multiple
          size="medium"
          limitTags={-1}
        />
      </Stack>
      <Box my={2}>
        <Typography variant="subtitle1">Roles:</Typography>
        {roles?.map((role, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <Checkbox
              checked={role.isPrimaryRole}
              onChange={(e) => handleIsPrimaryRoleChange(index, e.target.checked)}
            />
            <Typography variant="body2" sx={{ mr: 2 }}>
              Primary Role
            </Typography>
            <RoleOptionsDropdown
              sx={{ minWidth: 200, mr: 2 }}
              label="Department"
              value={role.internalUserDepartmentId}
              options={departmentOptions}
              onChange={(e) => handleRoleChange(index, "internalUserDepartmentId", e.target.value as number)}
              error={role.errors?.internalUserDepartmentId}
            />
            <RoleOptionsDropdown
              sx={{ minWidth: 200, mr: 2 }}
              label="Team"
              value={role.internalUserTeamId}
              options={teamOptions}
              onChange={(e) => handleRoleChange(index, "internalUserTeamId", e.target.value as number)}
              error={role.errors?.internalUserTeamId}
            />
            <RoleOptionsDropdown
              sx={{ minWidth: 200, mr: 2 }}
              label="Role"
              value={role.internalUserRoleTypeId}
              options={roleTypeOptions}
              onChange={(e) => handleRoleChange(index, "internalUserRoleTypeId", e.target.value as number)}
              error={role.errors?.internalUserRoleTypeId}
            />
            <Button onClick={() => deleteRole(index)}>
              <DeleteIcon />
            </Button>
          </Box>
        ))}
        <Button startIcon={<AddIcon />} onClick={addRole}>
          Add another Role
        </Button>
      </Box>
      {error && (
        <Box mb={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button variant="outlined" sx={{ mr: 1 }} onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditInternalUserRole;

import { Box, Button, Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { FullContact, SuspectedMatch } from "../../types";
import Highlighter from "react-highlight-words";
import Iconify from "../../../../minimals/components/iconify";
import { Link, useNavigate } from "react-router-dom";
import { AddEditContactContext } from "../../contact/addEditContact/AddEditContactContext";
import { useContext, useState } from "react";
import { highlightStyles } from "../../../utils/highlightStyles";

export default function DuplicateContact({ duplicateClient }: { duplicateClient: SuspectedMatch }) {
  const { contact, setContact } = useContext(AddEditContactContext);
  const [importButtonClicked, setImportButtonClicked] = useState(false);
  const theme = useTheme();

  const firstRole =
    duplicateClient.contact.contactRoles && duplicateClient.contact.contactRoles.length > 0
      ? duplicateClient?.contact.contactRoles[0]?.role
      : null;
  const firstCompanyName =
    duplicateClient?.contact.contactRoles && duplicateClient?.contact.contactRoles.length > 0
      ? duplicateClient?.contact.contactRoles[0]?.branch?.company?.name
      : null;

  const handleDynamicsContact = () => {
    setContact(duplicateClient?.contact);
    setImportButtonClicked(true);
  };
  console.log("duplicateClient?.contact", duplicateClient?.contact);

  return (
    <Card sx={{ backgroundColor: theme.palette.warning.lighter }}>
      <CardContent>
        <Typography variant="h6" marginBottom={3}>
          <Highlighter
            textToHighlight={`${duplicateClient?.contact.firstName} ${duplicateClient?.contact.lastName}` || ""}
            highlightStyle={highlightStyles}
            searchWords={[contact.firstName || "", contact.lastName || ""]}
          />
        </Typography>
        {firstRole?.name && (
          <Typography variant="body2" fontSize={13}>
            {firstRole?.name} {firstCompanyName && "at"} <span style={{ fontWeight: 600 }}> {firstCompanyName}</span>
          </Typography>
        )}
        <Stack rowGap={3} marginTop={3}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            {(duplicateClient?.contact.address1 || duplicateClient?.contact.address2) && (
              <>
                <Iconify icon={"eva:pin-fill"} />
                <Typography sx={{ fontWeight: 400 }}>
                  {firstCompanyName && <Typography variant="subtitle2">{firstCompanyName}</Typography>}
                  {duplicateClient?.contact?.address1 && (
                    <Typography variant="subtitle2">{duplicateClient?.contact.address1}</Typography>
                  )}
                  {duplicateClient?.contact?.address2 && (
                    <Typography variant="subtitle2">{duplicateClient?.contact.address2}</Typography>
                  )}
                  <Typography variant="subtitle2">
                    {duplicateClient?.contact.city} {duplicateClient?.contact.state} {duplicateClient?.contact.zip}
                  </Typography>
                </Typography>
              </>
            )}
          </Stack>
          {duplicateClient?.contact.contactRoles && duplicateClient?.contact.contactRoles[0]?.businessEmail && (
            <Stack direction="row" alignItems="center" columnGap={2}>
              <Iconify icon="eva:email-fill" />
              <Box>
                <Typography variant="body2" fontSize={13}>
                  <Highlighter
                    textToHighlight={duplicateClient?.contact.contactRoles[0].businessEmail || ""}
                    highlightStyle={highlightStyles}
                    searchWords={[contact.contactRoles[0]?.businessEmail || ""]}
                  />
                </Typography>
              </Box>
            </Stack>
          )}
          {duplicateClient?.contact.contactRoles[0]?.phoneNumbers &&
            duplicateClient?.contact.contactRoles[0]?.phoneNumbers
              .filter((p) => p.number)
              .map((p) => (
                <Stack direction="row" alignItems="center" columnGap={2}>
                  <Iconify icon="eva:phone-fill" />
                  <Box>
                    <Typography variant="body2" fontSize={13}>
                      {p.number} ({p.type}) {p.extension && `Ext. ${p.extension}`}
                    </Typography>
                  </Box>
                </Stack>
              ))}
          {duplicateClient?.contact.id && !duplicateClient?.contact.isDynamicsContact && (
            <Button
              variant="outlined"
              fullWidth
              component={Link}
              to={`../phonebook/contact/profile?id=${duplicateClient?.contact.id}`}
              target="_blank"
              rel="noreferrer"
            >
              View
            </Button>
          )}
          {duplicateClient?.contact.isDynamicsContact && (
            <Button variant="outlined" fullWidth onClick={handleDynamicsContact}>
              {!importButtonClicked ? "Import from Dynamics" : "Imported from Dynamics"}
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

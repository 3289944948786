import { Checkbox, Link, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { MRT_ColumnDef } from 'material-react-table';
import { fCurrency } from '../utils/formatNumber';
import { Commission } from './types';

const setIsPaid = async (orderNumber: string, paid: boolean) => {
  await axios.post('/api/commissionTool/SetCommissionPaid', { orderNumber, paid });
};

export const columns: MRT_ColumnDef<Commission>[] = [
  {
    header: 'Order Number',
    accessorKey: 'orderNumber',
    Cell: ({ renderedCellValue, row }) => (
      <Link
        href={`https://internal.mcres.com/new-home/${row.original.orderNumber}`}
        target='_blank'
      >
        <Typography variant='body2'>{row.original.orderNumber}</Typography>
      </Link>
    ),
  },
  {
    header: 'Sales Person',
    accessorKey: 'salesPerson',
  },
  {
    header: 'Client',
    accessorKey: 'client',
  },
  {
    header: 'Transaction',
    accessorKey: 'purchasePrice',
    accessorFn: (row) => `${fCurrency(row.purchasePrice || "")}-${fCurrency(row.purchasePrice || "")}`,
    Cell: ({ renderedCellValue, row }) => (
      <>
        {row.original.transactionType === "Purchase" && (
          <Typography variant="subtitle2">
            {row.original.purchasePrice !== null ? `P ${fCurrency(row.original.purchasePrice || "")}` : null}
          </Typography>
        )}
        <Typography variant="body2" color="grey">
          {row.original.loanAmount === 0 && "L ---"}
          {!!row.original.loanAmount && row.original.loanAmount > 0 && `L ${fCurrency(row.original.loanAmount || "")}`}
        </Typography>
      </>
    ),
  },
  {
    header: 'Purchase/Refi',
    accessorKey: 'transactionType',
  },
  {
    header: 'Net Premium',
    accessorKey: 'netPremium',
    Cell: ({ renderedCellValue, row }) => (
      <Typography variant='body2'>
        {fCurrency(row.original.netPremium)}
      </Typography>
    ),
  },
  {
    header: 'Commission Paid',
    accessorKey: 'commissionPaid',
    Cell: ({ renderedCellValue, row }) => (
      <Checkbox
        color='success'
        onChange={(e, value) =>
          setIsPaid(row.original.orderNumber, value)
        }
        defaultChecked={row.original.commissionPaid}
      />
    ),
  },
];

import { useState, useContext } from "react";
import { MatchedOrder } from "./MatchedOrder";
import { OrderContext } from "../../contexts/OrderDataContext";
import { CityData, Property as PropertyType } from "../types/Property";
import { Alert, Button, Grid, IconButton, Snackbar } from "@mui/material";
import Address from "../../shared/Address";
import Iconify from "../../../minimals/components/iconify";
import { LegalDescription } from "../../shared/types/Property";

type OrderProp = {
  order: MatchedOrder;
};

type PropertyProp = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  propertyUse: "Commercial" | "Residential" | "";
  section: string;
  block: string;
  lots: string[];
  parcelIds: string[];
  legalDescription: LegalDescription;
};

export function Property(property: PropertyProp) {
  const { setProperties, properties, propertyUse, setPropertyUse } = useContext(OrderContext);
  const [propertyUseAlertMessage, setPropertyUseAlertMessage] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const addMatchedProperty = () => {
    const importedProperty = {
      propertyInfo: {
        address: property.address1,
        formattedAddress: property.address1,
        aptNo: property.address2,
        city: property.city,
        state: property.state,
        zipCode: property.zip,
        county: property.county,
        section: property.section,
        block: property.block,
        lots: property.lots,
        parcelIds: property.parcelIds,
        propertyType: null,
        legalDescription: property.legalDescription
      },
      cityData: {
        city: property.city || "",
        county: property.county || "",
        state: property.state || "",
        zipCode: property.zip || ""
      } as CityData,
      zipData: {
        city: property.city || "",
        county: property.county || "",
        state: property.state || "",
        zipCode: property.zip || ""
      } as CityData
    } as PropertyType;

    if (
      !properties[0].propertyInfo.formattedAddress &&
      !properties[0].propertyInfo.block &&
      !properties[0].propertyInfo.lots[0] &&
      !properties[0].propertyInfo.parcelIds[0]
    ) {
      setProperties([importedProperty]);
    } else {
      setProperties([...properties, importedProperty]);
    }
    const relatedOrdersElement = document.getElementById(`property${properties.length - 1}`);
    relatedOrdersElement?.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    const message =
      propertyUse === "Residential" && property.propertyUse === "Commercial"
        ? "Order switched to commercial because commercial property was imported"
        : propertyUse === "Commercial" && property.propertyUse === "Residential"
        ? "Order was not switched to residential because order is already marked commercial"
        : null;
    setPropertyUseAlertMessage(message);
    setPropertyUse(property.propertyUse);
    setIsHovered(false);

    (propertyUse === "Residential" && property.propertyUse === "Commercial") ||
      (propertyUse === "Commercial" && property.propertyUse === "Residential" && setPropertyUse("Commercial"));
  };

  const isImported = properties.some((p) => {
    p.propertyInfo.formattedAddress === property.address1 &&
      p.cityData.city === property.city &&
      p.propertyInfo.county === property.county &&
      p.propertyInfo.state === property.state &&
      p.propertyInfo.block === property.block;
  });
  return (
    <>
      <Snackbar
        open={isImported && propertyUseAlertMessage != null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          action={
            <Button onClick={() => setPropertyUseAlertMessage(null)} color="inherit">
              Dismiss
            </Button>
          }
          severity="warning"
          sx={{ width: "100%" }}
        >
          {propertyUseAlertMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid container marginTop={3}>
          <Grid item xs={2}>
            {!isImported && (
              <IconButton
                color={isHovered ? "primary" : "primary"}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={addMatchedProperty}
              >
                {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
                {!isHovered && <Iconify icon={"eva:pin-outline"} />}
              </IconButton>
            )}
            {isImported && (
              <IconButton color="success">
                <Iconify icon={"eva:checkmark-fill"} />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={10}>
            <Address
              address1={property.address1}
              address2={property.address2}
              city={property.city}
              state={property.state}
              zip={property.zip}
              county={property.county}
              propertyUse={property.propertyUse}
              section={property.section}
              block={property.block}
              lots={property.lots}
              parcelIds={property.parcelIds}
              legalDescription={property.legalDescription}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function MatchedOrderAddress({ order }: OrderProp) {
  return (
    <>
      {order.properties.map((property) => (
        <Property
          address1={property.address1}
          address2={property.address2}
          city={property.city}
          state={property.state}
          zip={property.zip}
          county={property.county}
          propertyUse={order.propertyUse}
          section={property.section}
          block={property.block}
          lots={property.lots}
          parcelIds={property.parcelIds}
          legalDescription={property.legalDescription}
        />
      ))}
    </>
  );
}

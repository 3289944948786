import axios from "axios";
import { fMonthDayYear } from "../../../utils/formatTime";
import { IClosedOrder } from "../../../types/calendar";

export const fetchClosedOrders = async (start: Date, end: Date) => {
  const startDateFormatted = fMonthDayYear(start);
  const endDateFormatted = fMonthDayYear(end);
  const { data }: { data: IClosedOrder[] } = await axios.get(
    `/api/calendar/getclosedorders?settlementDateFrom=${startDateFormatted}&settlementDateTo=${endDateFormatted}`
  );
  return data;
};

export const getClosedOrdersByDateRange = async ({ start, end }: { start: Date | null; end: Date | null }) => {
  if (!start || !end) {
    return [];
  }
  return await fetchClosedOrders(start, end);
};

import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { WritableDraft } from "immer/dist/internal";
import { debounce } from "lodash";
import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useContext, useState } from "react";
import { OrderContext } from "../../../contexts/OrderDataContext";
import { getProperyInfoOptionFromSmartyResponse, isNJorNY } from "../../../utils/property";
import { Property, PropertyInfo, SmartyAddressResponse } from "../../types/Property";

interface IProps {
  property: Property;
  i: number;
  setPropertyProp: (index: number, cb: (property: WritableDraft<Property>) => void) => void;
  focusUnitInput: () => void | undefined;
  isDisabled: boolean;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
  searchFullAddressDetails: (propertyInfo: PropertyInfo, index: number) => Promise<void>;
  setProperty: (propertyInfo: PropertyInfo, index: number) => void;
}

export default function Address(props: IProps) {
  const { errorMode } = useContext(OrderContext);
  const {
    property,
    i,
    setPropertyProp,
    focusUnitInput,
    isDisabled,
    setIsDisabled,
    searchFullAddressDetails,
    setProperty
  } = props;
  const { propertyInfo } = property;
  const [loading, setLoading] = useState(false);
  const [isAddressOptionsOpen, setIsAddressOptionsOpen] = useState(false);
  const [options, setOptions] = useState<PropertyInfo[]>([]);
  const [isInputEdited, setIsInputEdited] = useState(false);

  const searchSelectedAddress = async (text: string, selected: string) => {
    if (!text) return [];
    const { data } = await axios.get(
      `/api/property/searchSelectedAddress?searchText=${encodeURIComponent(text)}&selected=${encodeURIComponent(
        selected
      )}`
    );
    return data.suggestions.map((d: SmartyAddressResponse) => getProperyInfoOptionFromSmartyResponse(d));
  };

  const onPropertySearchTextChanged = async (text: string, propertyInfo: PropertyInfo) => {
    setLoading(true);
    if (!text) {
      setOptions([]);
      setLoading(false);
      return;
    }

    const { data } = await axios.get(`/api/property/search?searchText=${encodeURIComponent(text)}`);
    const suggestions = data.suggestions.map((d: SmartyAddressResponse) => getProperyInfoOptionFromSmartyResponse(d));
    const labelForFreeformTextOption = suggestions.length
      ? `Results below don't match. I want to use "${text}" instead`
      : `No results found for "${text}", press “TAB” key to use typed entry.`;

    const freeTypedOption = {
      ...propertyInfo,
      isFreeFormEntry: true,
      optionLabel: labelForFreeformTextOption,
      entries: 0,
      address: text,
      formattedAddress: text
    } as PropertyInfo;

    setOptions([freeTypedOption].concat(suggestions));
    setLoading(false);
    setIsAddressOptionsOpen(true);
  };

  const debouncedOnPropertySearchTextChanged = useCallback(debounce(onPropertySearchTextChanged, 500), []);

  const handleInputChange = async (event: any, newInputValue: string, reason: any) => {
    setIsInputEdited(true);
    if (newInputValue.trim() == "") {
      setPropertyProp(i, (property) => (property.propertyInfo.formattedAddress = ""));
      setOptions([]);
      setIsAddressOptionsOpen(false);

      ///This line is needed even though it seems not to make sense, otherwise there will be a bug if backspacing all the text in input
      await debouncedOnPropertySearchTextChanged("", propertyInfo);
      return;
    }

    setOptions([]);
    setPropertyProp(
      i,
      (property) => (
        (property.propertyInfo.formattedAddress = newInputValue), (property.propertyInfo.isFreeFormEntry = true)
      )
    );
    ///We only want to search the options if the user entered the option and not selected a the autocomplete of a partially typed option
    if (reason == "input") await debouncedOnPropertySearchTextChanged(newInputValue, propertyInfo);
  };

  const handleChange = async (e: SyntheticEvent<Element, Event>, value: string | PropertyInfo | null) => {
    setOptions([]);
    if (value == null || typeof value == "string") {
      setIsAddressOptionsOpen(false);
      setOptions([]);
      return;
    }
    const { formattedAddress = "", isFreeFormEntry, entries, address, aptNo, city, state, zipCode } = value;
    setProperty({ ...propertyInfo, ...value }, i);
    if (Number(entries) > 1) {
      const unitOptions = await searchSelectedAddress(
        formattedAddress || "",
        `${address} ${aptNo} (${entries}) ${city} ${state} ${zipCode}`
      );
      setOptions(unitOptions);
      setIsAddressOptionsOpen(true);
      return;
    }

    // We only want to run this when the user selected and option. If he used a free form type address, we will rely on the onblur
    if (!isFreeFormEntry) {
      setIsDisabled(true);
      await searchFullAddressDetails(value, i);
    }
    setIsAddressOptionsOpen(false);
    focusUnitInput();
    setIsDisabled(false);
  };

  return (
    <div>
      <Autocomplete
        disabled={isDisabled}
        value={propertyInfo}
        onChange={handleChange}
        inputValue={propertyInfo.formattedAddress || ""}
        onInputChange={handleInputChange}
        options={options}
        open={isAddressOptionsOpen}
        onFocus={() => {
          if (propertyInfo.formattedAddress && !!options.length) {
            setIsAddressOptionsOpen(true);
          }
        }}
        onBlur={async () => {
          if (!isInputEdited || !propertyInfo.isFreeFormEntry) return;
          setIsDisabled(true);
          await searchFullAddressDetails(propertyInfo, i);
          setIsDisabled(false);
          setIsInputEdited(false);
        }}
        onClose={(event, reason) => {
          if (reason !== "selectOption") setIsAddressOptionsOpen(false);
        }}
        autoHighlight
        freeSolo
        id="propery-quick-entry"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Property Quick Entry"
            placeholder="Start typing an address..."
            error={
              errorMode &&
              !propertyInfo.formattedAddress &&
              !propertyInfo.parcelIds[0] &&
              !(propertyInfo.state && isNJorNY(propertyInfo.state) && propertyInfo.block && propertyInfo.lots[0])
            }
          />
        )}
        ////Need to do this 👇🏻 because of freeSolo mode
        getOptionLabel={(option) => (typeof option === "string" ? option : option?.formattedAddress || "")}
        loadingText="Loading..."
        loading={loading}
        renderOption={(props, option) => {
          const { optionLabel = "", isFreeFormEntry } = option || {};
          return (
            <li {...props} key={optionLabel}>
              <div style={{ width: "100%" }}>
                <div>
                  <Typography sx={{ display: "inline-block" }} variant={!isFreeFormEntry ? "subtitle2" : "body1"}>
                    {optionLabel}
                  </Typography>
                </div>
              </div>
            </li>
          );
        }}
      />
    </div>
  );
}

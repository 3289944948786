import { DialogContent, DialogTitle } from "@mui/material";
import { MergeCompanyStatus } from "../types";
import DotLoader from "../../../components/DotLoader";

const getDialogHeaderText = (mergeStatus: MergeCompanyStatus, failedAttemptCount: number) => {
  switch (mergeStatus) {
    case MergeCompanyStatus.MergingOrders:
      return "Merging Orders";
    case MergeCompanyStatus.DeactivatingCompanies:
      return "Deactivating Companies";
    case MergeCompanyStatus.MergeOrdersAndCompanySuccessful:
      return "Merge Successful!";
    case MergeCompanyStatus.MergingOrdersFailed:
      return failedAttemptCount > 1 ? "Orders Still Failed to Update" : "Orders Failed to Update";
    case MergeCompanyStatus.MergingCompanyFailed:
      return "Companies failed to merge";
    default:
      return "Unknown status";
  }
};

const getStatusColor = (mergeStatus: MergeCompanyStatus) => {
  switch (mergeStatus) {
    case MergeCompanyStatus.MergingOrders:
    case MergeCompanyStatus.DeactivatingCompanies:
      return "#FFC107";
    case MergeCompanyStatus.MergeOrdersAndCompanySuccessful:
      return "green";
    case MergeCompanyStatus.MergingOrdersFailed:
    case MergeCompanyStatus.MergingCompanyFailed:
      return "red";
    default:
      return "black";
  }
};

interface IProps {
  mergeStatus: MergeCompanyStatus;
  lookupCodeToKeep: string;
  failedAttemptCount: number;
}

const MergeOrdersProgressModalHeader = ({ mergeStatus, lookupCodeToKeep, failedAttemptCount }: IProps) => {
  return (
    <>
      <DialogTitle>
        <span>Status: </span>
        <span style={{ color: getStatusColor(mergeStatus) }}>
          {getDialogHeaderText(mergeStatus, failedAttemptCount)}
          {(mergeStatus === MergeCompanyStatus.MergingOrders ||
            mergeStatus === MergeCompanyStatus.DeactivatingCompanies) && <DotLoader />}
        </span>
      </DialogTitle>
      {mergeStatus == MergeCompanyStatus.MergingOrdersFailed && (
        <DialogContent>
          These orders failed to update. Please try again or update the contact on the above orders manually in Select with the
          merged lookup code of "{lookupCodeToKeep}"
        </DialogContent>
      )}
    </>
  );
};

export default MergeOrdersProgressModalHeader;

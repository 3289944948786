import { Check } from "./types";

export function checksSort(a: Check, b: Check) {
  const priorityOrder = ["Urgent", "Important", "To Review"];

  // Compare priority first
  const priorityA = priorityOrder.indexOf(a.priority);
  const priorityB = priorityOrder.indexOf(b.priority);

  if (priorityA !== priorityB) {
    return priorityA - priorityB;
  }

  // If priority is the same, compare by transactionDate (oldest first)
  const dateA = new Date(a.transactionDate || "").getTime(); // Convert to timestamp
  const dateB = new Date(b.transactionDate || "").getTime(); // Convert to timestamp

  return dateA - dateB;
}

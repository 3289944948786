import { SettingsValueProps } from "./types";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

const defaultToMiniNav =
  window.location.pathname === "/checks" ||
  window.location.pathname === "/tasks" ||
  window.location.pathname === "/phonebook/suspectedMatches" ||
  window.location.pathname === "/phonebook/edit-history" ||
  /^\/ledger\/[^/]+$/.test(window.location.pathname);;

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: defaultToMiniNav ? "mini" : "vertical",
  themeColorPresets: "default",
  themeStretch: false
};

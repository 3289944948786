import React, { useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { useCalendar } from "../contexts/CalendarContext";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const FiltersPresetModal = ({ isOpen, handleClose }: IProps) => {
  const { addCurrentFiltersToSavedFilterList, showToastMessage } = useCalendar();
  const textFieldRef = useRef<HTMLInputElement>(null);
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add New Filter Preset</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const value = textFieldRef.current?.value;
          if (value) {
            addCurrentFiltersToSavedFilterList(value);
            handleClose();
            showToastMessage({
              message: "Filter Preset Saved",
              severity: "success"
            });
          }
        }}
      >
        <DialogContent>
          <DialogContentText>
            This will save the filters you currently have set so you can quickly go back to them later. Please enter a
            unique name for your filter preset
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Preset Name"
            variant="standard"
            fullWidth
            type="text"
            inputRef={textFieldRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FiltersPresetModal;

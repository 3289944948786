import { Card } from "@mui/material";
import { useContext } from "react";
import PatriotSearchResultAlert from "../phonebook/shared/PatriotSearchResultAlert";
import { OrderContext } from "../contexts/OrderDataContext";
import BlackListSearchAlert from "../phonebook/shared/BlackListSearchAlert";

export default function OrderWarningsBanner() {
  const { patriotSearchResult, blackListSearchResults } = useContext(OrderContext);

  if (!patriotSearchResult?.length && !blackListSearchResults?.length) {
    return <></>;
  }
  return (
    <Card sx={{ marginBottom: 4, padding: 4 }}>
      {patriotSearchResult.length > 0 && <PatriotSearchResultAlert patriotSearchResult={patriotSearchResult} />}
      {blackListSearchResults.length > 0 && <BlackListSearchAlert blackListSearchResults={blackListSearchResults} />}
    </Card>
  );
}

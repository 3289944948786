import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import { Dispatch, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AddEditContactContext } from "../../contact/addEditContact/AddEditContactContext";
import { CompanyContext } from "../CompanyContext";

interface IProps {
  setSearchKeywords: Dispatch<SetStateAction<string | undefined>>;
}

export default function SearchSection(props: IProps) {
  const { setSearchKeywords } = props;
  const { company } = useContext(CompanyContext);
  const { setSelectedCompany } = useContext(AddEditContactContext);
  const navigate = useNavigate();

  const handleAddMember = () => {
    setSelectedCompany(company || null);
    navigate(`../phonebook/create-contact?companyId=${company?.id || ""}`);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" />
            </InputAdornment>
          )
        }}
        placeholder="Search..."
        sx={{ width: 368, marginRight: 2 }}
        onChange={(e) => setSearchKeywords(e.target.value)}
      />
      <Button
        size="medium"
        startIcon={<Iconify icon="eva:plus-fill" />}
        variant="contained"
        onClick={handleAddMember}
        disabled={!company?.isActive}
      >
        Add
      </Button>
    </Stack>
  );
}

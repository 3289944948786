import { Button, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MergeCompanyStatus } from "../types";

interface IProps {
  resetMergeProcess: () => void;
  navigateToProfile: string;
  mergeStatus: MergeCompanyStatus;
}

export default function MergedOrdersActions({ resetMergeProcess, navigateToProfile, mergeStatus }: IProps) {
  const navigate = useNavigate();

  const isProcessingStatus = [MergeCompanyStatus.MergingOrders, MergeCompanyStatus.DeactivatingCompanies].includes(
    mergeStatus
  );

  const isFailedStatus = [MergeCompanyStatus.MergingOrdersFailed, MergeCompanyStatus.MergingCompanyFailed].includes(
    mergeStatus
  );

  return (
    <DialogActions style={{ justifyContent: "flex-end" }}>
      {isProcessingStatus && (
        <Button variant="contained" disabled>
          {mergeStatus === MergeCompanyStatus.MergingOrders ? "Merging Orders..." : "Merging Companies..."}
        </Button>
      )}

      {isFailedStatus && (
        <Button onClick={resetMergeProcess} variant="outlined">
          Try Again
        </Button>
      )}

      {mergeStatus === MergeCompanyStatus.MergeOrdersAndCompanySuccessful && (
        <Button onClick={() => navigate(navigateToProfile)} variant="outlined">
          Close
        </Button>
      )}
    </DialogActions>
  );
}

export enum DepositRefundApprovalStatus {
  NEW = 1,
  CLOSED = 2
}

export interface DepositRefundRequest {
  id: number;
  requestedDate: Date;
  orderNumber: string;
  amount: number;
  address: string;
  clientName: string;
  salesRep: string;
  dealAmount?: number;
  depositAmount?: number;
  requestedByEmail: string;
  requestedByName: string;
  reviewedBy: string;
  reviewedByName: string;
  note: string;
  approvalStatus: DepositRefundApprovalStatus;
  approvalStatusDescription: string;
  requestNote: string;
}

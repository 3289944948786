import { Box, Button, Container, IconButton, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CenterLoader from "../../components/CenterLoader";
import { useFlips } from "../../contexts/FlipsDashboardContext";
import { exportTableToCsv } from "../../taskUtils";
import { getColumns } from "./columns";
import Iconify from "../../../minimals/components/iconify";

export default function FlipsDashboard() {
  const { flips, loading, fetchFlips, isUpdatingInSelect } = useFlips();

  useEffect(() => {
    fetchFlips(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Flips Dashboard | TitleQ</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Flips Dashboard
        </Typography>
        <MaterialReactTable
          columns={getColumns()}
          data={flips}
          enableGlobalFilter
          enableFacetedValues
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 }
          }}
          state={{ showSkeletons: loading }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4", height: 60 } : { height: 60 }
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Tooltip title="Export to csv">
                    <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                      <Iconify icon="eva:download-fill" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            );
          }}
        />
        <CenterLoader isOpen={isUpdatingInSelect} displayText="Updating in select..." />
      </Container>
    </>
  );
}

// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Skeleton, Typography } from "@mui/material";
import { CustomAvatar } from "../../../components/custom-avatar";
import Image from "../../../components/Image";
import { bgBlur } from "../../../../minimals/utils/cssStyles";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Company } from "../../types";

// ----------------------------------------------------------------------

const StyledInfo = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(3)
  }
}));

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  suffix?: string;
  role?: string;
  logo: string | undefined;
  companyType?: string;
  company?: Company;
}

function ProfileCover({ name, role, logo, companyType, company, suffix }: IProps) {
  const StyledRoot = styled("div")(({ theme }) => ({
    "&:before": logo
      ? {
          ...bgBlur({
            color: "#1E2458"
          }),
          top: 0,
          zIndex: 9,
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute"
        }
      : {
          backgroundColor: "#1E2458",
          top: 0,
          zIndex: 9,
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute"
        }
  }));

  name = !name.includes("undefined") ? name : "";
  return (
    <StyledRoot>
      <StyledInfo>
        {name ? (
          <CustomAvatar
            src={logo}
            alt={name}
            name={name}
            sx={{
              mx: "auto",
              fontSize: 62,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "common.white",
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 }
            }}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="circular"
            sx={{ width: { xs: 80, md: 128 }, height: { xs: 80, md: 128 } }}
          />
        )}
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" }
          }}
        >
          <Typography variant="h4">
            {name} {suffix && <Typography variant="caption">{suffix}</Typography>}
          </Typography>
          {!company && role && (
            <Typography fontStyle="italic" display="inline-block">
              {role}
            </Typography>
          )}
          {!companyType && company && (
            <Typography sx={{ opacity: 0.72 }}>
              <Typography fontStyle="italic" display="inline-block">
                {role}
              </Typography>{" "}
              {role && "at "}
              <RouterLink
                to={`../phonebook/company/profile?id=${company?.id}`}
                style={{
                  textDecoration: "none",
                  color: "#fff"
                }}
              >
                {company?.name}
              </RouterLink>
            </Typography>
          )}
          {companyType && <Typography sx={{ opacity: 0.72, color: "#fff" }}>{companyType}</Typography>}
        </Box>
      </StyledInfo>

      <Image
        alt={name}
        src={logo}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute"
        }}
      />
    </StyledRoot>
  );
}

export default React.memo(ProfileCover);

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Container,
  Divider,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SearchesContext } from "../contexts/SearchesContext";
import { SearchItem } from "../contexts/types/types";
// ----------------------------------------------------------------------

export default function SearchItems({ index }: { index: number }) {
  const { searchSections, notes, setNotes, searchItemList, order } = useContext(SearchesContext);
  const searches = searchSections[index].searchRequests;
  const [selectedProductType, setSelectedProductType] = useState<SearchItem | null>();

  useEffect(() => {
    if (order?.productType) {
      const product = searchItemList.find((i) => i.type.toLowerCase() === order.productType?.toLowerCase());
      setSelectedProductType(product);
    }
  }, [order]);

  useEffect(() => {
    setNotes(selectedProductType?.wording);
  }, [selectedProductType]);

  return (
    <Box marginTop={4}>
      <Grid container justifyContent="space-between" padding={2}>
        <Grid item justifyContent="flex-start">
          <Grid container justifyContent="flex-start">
            <Typography variant="subtitle2" marginRight={3}>
              #
            </Typography>
            <Typography variant="subtitle2">Search Requests</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Actions</Typography>
        </Grid>
      </Grid>
      {searches.map((s, searchIndex) => (
        <Accordion expanded={false} key={s.searchType}>
          <AccordionSummary
          // expandIcon={s.items.length > 0 && <ExpandMoreIcon />}
          >
            <Grid container justifyContent="space-between">
              <Grid item justifyContent="flex-start">
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Typography variant="body2" marginRight={3}>
                    {index + 1}
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: 450 }}
                    value={selectedProductType}
                    onChange={(e, value) => setSelectedProductType(value)}
                    size="small"
                    options={searchItemList}
                    getOptionLabel={(option) => option.type || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Search item" placeholder="Start typing..." />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Container sx={{ paddingBottom: 2 }}></Container>
          </AccordionDetails>
        </Accordion>
      ))}
      <>
        <Divider sx={{ marginBottom: 4 }} />
        <TextField
          value={notes || ""}
          onChange={(e) => setNotes(e.target.value)}
          label="Additional Notes & Special instructions"
          multiline
          minRows={4}
          sx={{ width: 499 }}
        />
      </>
    </Box>
  );
}

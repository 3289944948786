import axios from "axios";
import { formatISO, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/edit";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  TextFieldProps
} from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import { useCalendar } from "../contexts/CalendarContext";
import useDebounce from "../../hooks/useDebounce";
import OpenInHomePageButton from "../searches/OpenInHomePageButton";
import OpenInSelectButton from "../searches/OpenInSelectButton";
import { fCurrency } from "../utils/formatNumber";
import { fIsValidDate } from "../utils/formatTime";
import { getStatusColor, getStatusTitle, statusOptions } from "./CalendarUtils";
import PropertyAddress from "./PropertyAddress";
import { logEvent } from "../utils/analyticsLogger";
import {
  getClosedOrder,
  getClosedOrderAdditionalInfo,
  getClosingCalendarFunderAndCloser,
  updateClosingInfo
} from "./CalendarActions";
import { IContact } from "../types/calendar";
import { IOption, IGenericOption, InternalUser } from "../types/app";
import DotLoader from "../components/DotLoader";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { convertInternalUserToContact } from "../orderEntry/utils/helpers";
import { useInternalUsers } from "../contexts/InternalUserContext";
import { useQueryParam, StringParam } from "use-query-params";

interface IContactOption extends IGenericOption<IContact> {}

interface IUpdateForm {
  orderStatus: IOption;
  accountRep: IOption;
  settlementDate: string | null;
  funder: { value: IContact | null; label: string } | null;
  titleCloser: { value: IContact | null; label: string } | null;
}

interface Props {
  getOrdersWithLoader: () => void;
}

export default function ViewClosingModal({ getOrdersWithLoader }: Props) {
  const { modalOrder, setModalOrder, viewOrderMode: mode, setViewOrderMode: setMode } = useCalendar();
  const { accountRepOptions } = useInternalUsers();

  if (!modalOrder) {
    return <></>;
  }

  const {
    salesPrice = "",
    orderStatus = "",
    accountRep = "",
    accountRepId = "",
    funder = "",
    clientName = "",
    titleCloser = "",
    propertyType = "",
    loanAmount = "",
    premium = "",
    salesRep = "",
    principal = "",
    buyerAttorney = "",
    sellerAttorney = "",
    fullOrderNumber = "",
    settlementDate,
    projectName = "",
    transactionType = "",
    identifier = ""
  } = modalOrder;

  const orderNumber = modalOrder.orderNumber || "";

  const [isOpenSettlementDatePicker, setIsOpenSettlementDatePicker] = useState(false);
  const [isOpenSettlementTimePicker, setIsOpenSettlementTimePicker] = useState(false);
  const [isLoadingAdditonalInfo, setIsLoadingAdditonalInfo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lendersAttorney, setLendersAttorney] = useState("");
  const [properties, setProperties] = useState([]);
  const [funderOptions, setFunderOptions] = useState<IContactOption[]>([]);
  const [titleCloserOptions, setTitleCloserOptions] = useState<IContactOption[]>([]);
  const [orderNumberUrlParam, setOrderNumberUrlParam] = useQueryParam("ordernumber", StringParam);

  const [funderSearchInputValue, setFunderSearchInputValue] = useState(funder || "");
  const debouncedFunderSearchInput = useDebounce(funderSearchInputValue, 500);

  const [titleCloserSearchInputValue, setTitleCloserSearchInputValue] = useState(titleCloser || "");
  const debouncedTitleCloserSearchInput = useDebounce(titleCloserSearchInputValue, 500);

  useEffect(() => {
    if (!debouncedFunderSearchInput) {
      return setFunderOptions([]);
    }

    axios
      .get<InternalUser[]>(`/api/clientphonebook/internalUsers/searchFunders?searchText=${debouncedFunderSearchInput}`)
      .then(({ data }) => {
        const options = data.map((d) => {
          const funderAsContact = convertInternalUserToContact(d);
          return {
            value: { ...funderAsContact, lookupCode: "" } as IContact,
            label: funderAsContact.name || ""
          };
        });
        setFunderOptions(options);
      });
  }, [debouncedFunderSearchInput]);

  useEffect(() => {
    axios
      .get<IContact[]>(`/proxy/api/clients/getbyrole?role=Title Closer&searchterm=${debouncedTitleCloserSearchInput}`)
      .then(({ data }) => {
        setTitleCloserOptions(data.map((d) => ({ value: d, label: d.name })));
      });
  }, [debouncedTitleCloserSearchInput]);

  const [updatedFormValues, setUpdatedFormValues] = useState<IUpdateForm>({
    orderStatus: { value: orderStatus || "", label: orderStatus || "" },
    accountRep: { value: accountRepId || "", label: accountRep || "" },
    settlementDate: settlementDate || "",
    funder: { value: null, label: "" },
    titleCloser: { value: null, label: "" }
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    logEvent("Closing Calendar", "Edit Closing", "");
    const { orderStatus, settlementDate, accountRep, funder, titleCloser } = updatedFormValues;
    if (!orderNumber || !orderStatus?.value || (settlementDate && !fIsValidDate(settlementDate))) {
      setIsSubmitting(false);
      return false;
    }

    const updatePayload = {
      orderNumber,
      settlementDate: settlementDate || null,
      status: orderStatus.value || "",
      accountRepId: accountRep?.value || null,
      funder: funder?.value || null,
      titleCloser: titleCloser?.value || null
    };

    try {
      await updateClosingInfo(updatePayload);
      await refreshOrderData(orderNumber);

      setMode("view");
      getOrdersWithLoader();
      setIsSubmitting(false);
    } catch (e) {
      console.error("Error in order update submit", e);
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setModalOrder(null);
    setOrderNumberUrlParam(null);
    // setIsEditModeParam(null);
  };

  const onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (!isSubmitting && reason == "backdropClick") {
      handleClose();
    }
  };

  const refreshOrderData = async (orderNumber: string) => {
    const { data: orderData } = await getClosedOrder(orderNumber);
    setModalOrder(orderData);
    const orderStatus = orderData?.orderStatus || "";

    // getAndSetAdditionalData(orderNumber)
    const { data } = await getClosingCalendarFunderAndCloser(orderNumber);
    const funderName = data.funder?.name || "";
    const titleCloserName = data.titleCloser?.name || "";
    setFunderSearchInputValue(funderName);
    setTitleCloserSearchInputValue(titleCloserName);

    const { data: additionalInfo } = await getClosedOrderAdditionalInfo(orderNumber);
    setLendersAttorney(additionalInfo?.lendersAttorney || "");
    setProperties(additionalInfo?.properties || "");

    setUpdatedFormValues({
      orderStatus: { value: orderStatus, label: orderStatus },
      accountRep: {
        value: orderData.accountRepId || "",
        label: orderData.accountRep || ""
      },
      settlementDate: orderData.settlementDate || "",
      funder: { value: data.funder, label: funderName },
      titleCloser: { value: data.titleCloser, label: titleCloserName }
    });
  };

  const getAndSetAdditionalData = async (orderNumber: string) => {
    setIsLoadingAdditonalInfo(true);
    try {
      const { data } = await getClosingCalendarFunderAndCloser(orderNumber);
      const funderName = data.funder?.name || "";
      const titleCloserName = data.titleCloser?.name || "";
      setUpdatedFormValues((formVals) => ({
        ...formVals,
        funder: { value: data.funder, label: funderName },
        titleCloser: { value: data.titleCloser, label: titleCloserName }
      }));

      setFunderSearchInputValue(funderName);
      setTitleCloserSearchInputValue(titleCloserName);

      const { data: additionalInfo } = await getClosedOrderAdditionalInfo(orderNumber);
      setLendersAttorney(additionalInfo?.lendersAttorney || "");
      setProperties(additionalInfo?.properties || "");
      setIsLoadingAdditonalInfo(false);
    } catch (e) {
      console.error("Error in getting additonal order info update submit", e);
      alert("There was an issue getting all the info for this order. Changes cannot be submitted at this time");
    }
  };

  useEffect(() => {
    getAndSetAdditionalData(orderNumber);
    // refreshData(orderNumber);
    if (!orderNumberUrlParam) {
      setOrderNumberUrlParam(orderNumber);
    }
    return () => setModalOrder(null);
  }, []);

  const handleChange = (val: IOption | null, field: string) => {
    setUpdatedFormValues({
      ...updatedFormValues,
      [field]: val || { value: null, label: "" }
    });
  };

  const handleSettlementDateChange = (val: string | null | undefined | Date) => {
    if (!val)
      return setUpdatedFormValues({
        ...updatedFormValues,
        settlementDate: null
      });

    setUpdatedFormValues({
      ...updatedFormValues,
      settlementDate: val ? formatISO(new Date(val)) : ""
    });
  };

  const handleDateChange = (val: Date | null) => {
    if (!updatedFormValues.settlementDate && val) {
      const formatted = formatISO(startOfDay(val));
      handleSettlementDateChange(formatted);
      return;
    }
    handleSettlementDateChange(val?.toDateString());
  };

  const handleDateClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    handleSettlementDateChange(null);
    setIsOpenSettlementDatePicker(false);
  };

  const handleTimeClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (updatedFormValues.settlementDate) {
      const formatted = formatISO(startOfDay(new Date(updatedFormValues.settlementDate)));
      handleSettlementDateChange(formatted);
      setIsOpenSettlementTimePicker(false);
      return;
    }
    handleSettlementDateChange("");
    setIsOpenSettlementTimePicker(false);
  };

  return (
    <Dialog open={Boolean(modalOrder)} onClose={onClose}>
      <Stack spacing={4} paddingBottom={4}>
        <DialogTitle>
          {fullOrderNumber}
          {identifier && (
            <Box
              sx={{
                display: "inline-block",
                marginLeft: 2,
                position: "relative",
                top: 7,
                left: -4
              }}
            >
              <OpenInSelectButton identifier={identifier} />
            </Box>
          )}
          {orderNumber && <OpenInHomePageButton orderNumber={orderNumber || undefined} />}
          {projectName && <div>{projectName}</div>}

          <div style={{ color: getStatusColor(orderStatus)?.color }}>
            {getStatusTitle({ status: orderStatus, date: settlementDate })}
          </div>

          {mode == "view" && (
            <IconButton
              onClick={() => {
                setMode("edit");
              }}
              aria-label="close"
              sx={{ position: "absolute", right: 20, top: 20 }}
            >
              <EditIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Stack>
              <Typography variant="subtitle2">
                {propertyType} {transactionType}
              </Typography>
              {isLoadingAdditonalInfo ? <DotLoader /> : <PropertyAddress properties={properties} />}
            </Stack>

            <Grid container columnSpacing={1} rowSpacing={3}>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Sales Price</Typography>
                  <DialogContentText variant="body2">{salesPrice ? fCurrency(salesPrice) : ""}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Loan Amount</Typography>
                  <DialogContentText variant="body2">{loanAmount ? fCurrency(loanAmount) : ""}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Premium</Typography>
                  <DialogContentText variant="body2">{premium ? fCurrency(premium) : ""}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Client</Typography>
                  <DialogContentText variant="body2">{clientName}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Principal</Typography>
                  <DialogContentText variant="body2">{principal}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Sales Rep</Typography>
                  <DialogContentText variant="body2">{salesRep}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Buyers Attorney</Typography>
                  <DialogContentText variant="body2">{buyerAttorney}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Sellers Attorney</Typography>
                  <DialogContentText variant="body2">{sellerAttorney}</DialogContentText>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">Lenders Attorney</Typography>
                  {isLoadingAdditonalInfo ? (
                    <DotLoader />
                  ) : (
                    <DialogContentText variant="body2">{lendersAttorney}</DialogContentText>
                  )}
                </Stack>
              </Grid>

              {mode === "view" && (
                <>
                  <Grid item xs={4}>
                    <Stack>
                      <Typography variant="subtitle2">Account Rep</Typography>
                      <DialogContentText variant="body2">{accountRep}</DialogContentText>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack>
                      <Typography variant="subtitle2">Funder</Typography>
                      <DialogContentText variant="body2">{funder}</DialogContentText>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack>
                      <Typography variant="subtitle2">Title Closer</Typography>
                      <DialogContentText variant="body2">{titleCloser}</DialogContentText>
                    </Stack>
                  </Grid>
                </>
              )}

              {mode == "edit" && (
                <Stack direction="row" spacing={3} sx={{ paddingTop: "35px" }}>
                  <Stack spacing={3} width="200px">
                    <Autocomplete
                      disableClearable
                      id="modal-status"
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      options={statusOptions}
                      value={updatedFormValues.orderStatus}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          sx={{
                            input: {
                              ...getStatusColor(updatedFormValues.orderStatus.label)
                            }
                          }}
                        />
                      )}
                      onChange={(e, val) => handleChange(val, "orderStatus")}
                      disabled={isSubmitting}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value} style={{ ...getStatusColor(option.label) }}>
                          {option.label}
                        </li>
                      )}
                    />{" "}
                    <Autocomplete
                      id="modal-account-rep"
                      filterOptions={createFilterOptions({ limit: 10 })}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      options={accountRepOptions}
                      value={updatedFormValues.accountRep}
                      renderInput={(params) => <TextField {...params} label="Account Rep" />}
                      onChange={(e, val) => handleChange(val, "accountRep")}
                      disabled={isSubmitting}
                    />
                    <Autocomplete
                      id="modal-title-closer"
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      options={titleCloserOptions}
                      value={updatedFormValues.titleCloser}
                      onInputChange={(e, val) => setTitleCloserSearchInputValue(val)}
                      renderInput={(params) => <TextField {...params} label="Title Closer" />}
                      onChange={(e, val) => setUpdatedFormValues({ ...updatedFormValues, titleCloser: val })}
                      disabled={isSubmitting}
                    />
                  </Stack>

                  <Stack spacing={3} width="200px">
                    <DesktopDatePicker
                      open={isOpenSettlementDatePicker}
                      onOpen={() => {
                        if (!isSubmitting) {
                          setIsOpenSettlementDatePicker(true);
                        }
                      }}
                      onClose={() => setIsOpenSettlementDatePicker(false)}
                      label="Settlement Date"
                      value={new Date(updatedFormValues.settlementDate || "")}
                      onChange={handleDateChange}
                      disabled={isSubmitting}
                      slotProps={{
                        textField: {
                          disabled: isSubmitting,
                          sx: { " .MuiOutlinedInput-input": { cursor: "pointer" } },
                          inputProps: { readOnly: true },
                          onClick: () => {
                            if (!isSubmitting) {
                              setIsOpenSettlementDatePicker(true);
                            }
                          },
                          error:
                            Boolean(updatedFormValues.settlementDate) &&
                            !fIsValidDate(updatedFormValues.settlementDate),
                          fullWidth: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleDateClear} disabled={isSubmitting}>
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        }
                      }}
                    />
                    <TimePicker
                      open={isOpenSettlementTimePicker}
                      onOpen={() => {
                        if (!isSubmitting) {
                          setIsOpenSettlementTimePicker(true);
                        }
                      }}
                      onClose={() => setIsOpenSettlementTimePicker(false)}
                      label="Settlement Time"
                      value={new Date(updatedFormValues.settlementDate || "")}
                      onChange={handleSettlementDateChange}
                      disabled={isSubmitting}
                      slotProps={{
                        textField: {
                          disabled: isSubmitting,
                          sx: {
                            " .MuiOutlinedInput-input": { cursor: "pointer" }
                          },
                          inputProps: {
                            readOnly: true
                          },
                          onClick: () => {
                            if (!isSubmitting) {
                              setIsOpenSettlementTimePicker(true);
                            }
                          },
                          fullWidth: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleTimeClear} disabled={isSubmitting}>
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        }
                      }}
                    />
                    <Autocomplete
                      disabled={isSubmitting}
                      id="modal-funder"
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      options={funderOptions}
                      value={updatedFormValues.funder}
                      onInputChange={(e, val) => setFunderSearchInputValue(val)}
                      renderInput={(params) => <TextField {...params} label="Funder" />}
                      onChange={(e, val) => setUpdatedFormValues({ ...updatedFormValues, funder: val })}
                    />
                  </Stack>
                </Stack>
              )}
            </Grid>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button variant="outlined" disabled={isSubmitting} onClick={() => handleClose()}>
                {mode == "view" ? "Close" : "Cancel"}
              </Button>
              {mode == "edit" && (
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting || isLoadingAdditonalInfo}
                >
                  Save
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}

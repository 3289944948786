import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import CorpDocs from "./CorpDocs";
import { useSettingsContext } from "../contexts/SettingsContext";

// ----------------------------------------------------------------------

export default function CorpDocsPage() {
  const settings = useSettingsContext();
  return (
    <>
      <Helmet>
        <title>TitleQ Corp Docs</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <CorpDocs />
      </Container>
    </>
  );
}

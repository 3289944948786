import axios from "axios";
import { TaskItem, Note } from "../types";

export const getNotes = async (task: TaskItem) => {
  if (task?.id) {
    let { data } = await axios.get(`/api/corpdocstracking/taskItems/getnotes?taskItemId=${task?.id}`);
    data = data.sort(
      (a: Note, b: Note) => new Date(b.dateCreated || "").getTime() - new Date(a.dateCreated || "").getTime()
    );
    return data;
  }
};

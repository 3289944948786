export function getDomainFromEmail(email: string) {
  // Regular expression to match the domain part of an email address
  const regex = /@([a-zA-Z0-9.-]+)$/;
  const match = email.match(regex);

  if (match) {
    return match[1]; // The domain is in the first capturing group
  } else {
    return null; // Invalid email format or no domain found
  }
}

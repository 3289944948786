import { Divider, Grid, IconButton, Link, Typography } from "@mui/material";
import axios from "axios";
import { random } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import DebouncedAutoComplete from "../components/DebouncedAutoComplete";
import Iconify from "../../minimals/components/iconify";
import { Attachment } from "./types/Attachment";

export default function OrderAttachments({
  selectedAttachments,
  setSelectedAttachments,
  orderNumber
}: {
  selectedAttachments: Attachment[];
  setSelectedAttachments: Dispatch<SetStateAction<Attachment[]>>;
  orderNumber: string | undefined;
}) {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  //this is a hack to clear the attachments autocomplete allowing it to be uncontrolled :)
  const [autocompleteKey, setAutocompleteKey] = useState<string>(random(1, 10, true).toString());

  const handleTextChange = async (text: string) => {
    const { data } = await axios.get(`/api/orderdocuments/search?ordernumber=${orderNumber}&searchtext=${text}`);
    setAttachments(data);
  };

  const handleOptionChange = (
    e: React.SyntheticEvent<Element, Event>,
    option: string | Attachment | null | undefined
  ) => {
    if (option && typeof option === "object") {
      setSelectedAttachments([...selectedAttachments, option]);
      //this is a hack to clear the attachments autocomplete allowing it to be uncontrolled :)
      setAutocompleteKey(random(1, 10, true).toString());
    }
  };

  const handleDeleteAttachment = (id: number) => {
    setSelectedAttachments(selectedAttachments.filter((a) => a.id !== id));
  };

  return (
    <>
      <DebouncedAutoComplete
        sx={{ marginBottom: 3 }}
        //this is a hack to clear the attachments autocomplete allowing it to be uncontrolled :)
        key={autocompleteKey}
        options={attachments}
        getOptionsLabel={(v) => v?.description || ""}
        onSelectedOptionChanged={handleOptionChange}
        textboxLabel="Order Documents"
        textboxPlaceholder="Type to search"
        onDebouncedTextChanged={handleTextChange}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id}>
            <div style={{ width: "100%" }}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2" color="primary">
                      {option?.description}
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                      {option?.category}/{option?.subcategory}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton color="primary" onClick={() => handleDeleteAttachment(option.id)}>
                      <Iconify icon="eva:plus-square-fill" />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </div>
          </li>
        )}
      />
      {selectedAttachments.length > 0 && <Divider sx={{ marginTop: 3, marginBottom: 3 }} />}
      {selectedAttachments.map((a) => (
        <Grid container>
          <Grid item xs={1}>
            <Iconify icon="eva:attach-outline" marginTop={0.5} />
          </Grid>
          <Grid item xs={10}>
            <Link
              sx={{ marginBottom: 1, display: "block" }}
              href={`https://internal.mcres.com/opendoc?id=${a.id}`}
              target="_blank"
            >
              {a.description}
            </Link>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" color="error" onClick={() => handleDeleteAttachment(a.id)}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import AddressAutocomplete from "../../contact/addEditContact/AddressAutocomplete";
import { Company, CompanyBranch, SmartyAddress } from "../../types";
import { AddEditCompanyContext } from "./AddEditCompanyContext";
import { useContext, useState } from "react";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { usStateList } from "../../../utils/usStateList";
import { countryList } from "../../../utils/countryList";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import isNumber from "../../../utils/isNumber";
import { WritableDraft } from "immer/dist/internal";
import validateEmail from "../../../utils/validateEmail";

export default function BranchInformation({
  branch,
  number
}: {
  company: Company;
  branch: CompanyBranch;
  number: number;
}) {
  const { company, setCompany, setDeletedBranches, members } = useContext(AddEditCompanyContext);
  const [isTouched, setIsTouched] = useState(false);

  const handleBranchChange = (key: keyof CompanyBranch, value: any) => {
    setCompany((draft) => {
      var branchToUpdate = draft.branches.find((b) => b.id === branch.id) as WritableDraft<CompanyBranch>;
      (branchToUpdate[key] as any) = value;
    });
  };

  const handleOptionChange = (option: SmartyAddress | null) => {
    if (option) {
      setCompany((draft) => {
        var branchToUpdate = draft.branches.find((b) => b.id === branch.id) as WritableDraft<CompanyBranch>;
        branchToUpdate.address1 = option?.formattedStreet_line;
        branchToUpdate.address2 = option.secondary;
        branchToUpdate.city = option.city;
        branchToUpdate.state = option.state;
        branchToUpdate.zip = option.zipcode;
      });
    }
  };

  const handleHeadquartersChange = (checked: boolean) => {
    setCompany((draft) => {
      var branchToUpdate = draft.branches.find((b) => b.id === branch.id) as WritableDraft<CompanyBranch>;
      draft.branches.forEach((b) => {
        b.isHeadquarters = false;
      });
      branchToUpdate.isHeadquarters = checked;
    });
  };

  const handlePhoneFields = (type: "phone" | "fax", info: MuiTelInputInfo) => {
    handleBranchChange(type, info.nationalNumber || "");
    handleBranchChange(`${type}CountryCode`, info.countryCallingCode || "");
  };

  const removeBranch = () => {
    if (branch.id) {
      setDeletedBranches((draft) => {
        draft.push(company.branches.find((b) => b.id === branch.id) as CompanyBranch);
      });
    }
    setCompany((draft) => {
      if (branch?.isHeadquarters && draft.branches.length > 1) {
        const firstAvailableBranchAfterDelete = number === 0 ? 1 : 0;
        draft.branches[firstAvailableBranchAfterDelete].isHeadquarters = true;
      }
      draft.branches = draft.branches.filter((b) => b.id !== branch.id);
    });
  };

  return (
    <Card sx={{ backgroundColor: "hsla(240, 76%, 37%, 0.08)" }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
          {branch.isHeadquarters && <Typography variant="overline">Headquarters</Typography>}
          {!branch.isHeadquarters && <Typography variant="overline">Branch #{number}</Typography>}
          <FormControlLabel
            control={
              <Checkbox onChange={(_, checked) => handleHeadquartersChange(checked)} checked={branch.isHeadquarters} />
            }
            label="Headquarters"
          />
        </Stack>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={8}>
            <AddressAutocomplete
              setAddressOption={handleOptionChange}
              setAddress1={(value) => handleBranchChange("address1", value)}
              inputValue={branch.address1 || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={branch.address2 || ""}
              label="Address 2"
              fullWidth
              onChange={(e) => handleBranchChange("address2", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={branch.city || ""}
              label="City"
              fullWidth
              onChange={(e) => handleBranchChange("city", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={
                branch.country === "United States" || branch.country === "US" || !branch.country ? usStateList : []
              }
              value={branch.state || ""}
              freeSolo
              onChange={(_, value) => handleBranchChange("state", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autocomplete: "new-password"
                  }}
                  fullWidth
                  label="State"
                  onChange={(e) => handleBranchChange("state", e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={branch.zip || ""}
              label="Zip"
              fullWidth
              onChange={(e) => handleBranchChange("zip", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={branch?.country || ""}
              options={countryList}
              onChange={(_, value) => handleBranchChange("country", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autocomplete: "new-password"
                  }}
                  fullWidth
                  label="Country"
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <MuiTelInput
              value={`+${isNumber(branch.phoneCountryCode) ? branch.phoneCountryCode : 1} ${branch.phone}`}
              onChange={(value, info) => handlePhoneFields("phone", info)}
              preferredCountries={["US", "IL"]}
              defaultCountry={"US"}
              forceCallingCode
              label="Company Phone"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <MuiTelInput
              value={`+${isNumber(branch.faxCountryCode) ? branch.faxCountryCode : 1} ${branch.fax}`}
              onChange={(value, info) => handlePhoneFields("fax", info)}
              preferredCountries={["US", "IL"]}
              defaultCountry={"US"}
              forceCallingCode
              label="Company Fax"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={branch.email || ""}
              label="Email"
              fullWidth
              onChange={(e) => handleBranchChange("email", e.target.value?.trim())}
              error={isTouched && branch.email !== null && !branch.isValidEmail}
              onBlur={(e) => {
                setIsTouched(true);
                handleBranchChange("isValidEmail", validateEmail(e.target.value));
              }}
              helperText={isTouched && !branch.isValidEmail && branch.email !== "" ? "Invalid email address" : ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={branch.website || ""}
              label="Website"
              fullWidth
              onChange={(e) => handleBranchChange("website", e.target.value)}
            />
          </Grid>
        </Grid>
        {company.branches.length > 1 && (
          <Button
            onClick={removeBranch}
            sx={{ marginTop: 2 }}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            color="error"
          >
            Delete
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { PoliciesNotIssuedData, PolicyNotIssued } from "./types";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { MRT_TableInstance } from "material-react-table";

// ----------------------------------------------------------------------

const PoliciesNotIssuedContext = createContext<PoliciesNotIssuedData>({} as PoliciesNotIssuedData);

function PoliciesNotIssuedContextProvider({ children }: { children: JSX.Element }) {
  const today = dayjs();
  const defaultEndDate = today;
  const defaultStartDate = today.subtract(90, "days");
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [loading, setLoading] = useState(true);
  const [policiesNotIssued, setPoliciesNotIssued] = useState<PolicyNotIssued[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<PolicyNotIssued> | null>(null);
  const fetchPoliciesNotIssued = async (useLoader: boolean = true) => {
    if (useLoader) {
      setLoading(true);
    }

    const [startDate, endDate] = dateRange;
    const { data } = await axios.get<PolicyNotIssued[]>(
      `/api/outstandingchecks/policiesNotIssued?from=${startDate || defaultStartDate}&to=${endDate || defaultEndDate}`
    );
    setPoliciesNotIssued(data);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPoliciesNotIssued(true);
  }, [dateRange]);

  return (
    <PoliciesNotIssuedContext.Provider
      value={{
        setDateRange,
        dateRange,
        loading,
        policiesNotIssued,
        setPoliciesNotIssued,
        assignedToMe,
        setAssignedToMe,
        fetchPoliciesNotIssued,
        tableRef
      }}
    >
      {children}
    </PoliciesNotIssuedContext.Provider>
  );
}

export { PoliciesNotIssuedContextProvider, PoliciesNotIssuedContext };

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { UploadMultiFile } from "../components/upload";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toBase64 } from "../utils/helpers";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";
import { BulletinInformation, UploadInfo } from "./types";
import produce from "immer";
import { DatePicker } from "@mui/x-date-pickers";
import MultiFilePreview from "../components/upload/MultiFilePreview";

interface IProps {
  open: boolean;
  onClose: () => void;
  onSave: (upload: UploadInfo, index: number | null) => void;
  index?: number;
  editUpload?: UploadInfo;
}

export default function BulletinUploadModal({ open, onClose, onSave, index, editUpload }: IProps) {
  const baseUpload = {
    underwriter: { value: null, label: "" },
    date: new Date(),
    number: "",
    file: {} as File
  };
  const [file, setFile] = useState<File | null>(editUpload?.file || null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [attemptedSave, setAttemptedSave] = useState<boolean>(false);
  const [underwriters, setUnderwriters] = useState<any[]>([]);
  const [upload, setUpload] = useState<UploadInfo>(editUpload || baseUpload);
  const [submittingUnderwriterError, setSubmittingUnderwriterError] = useState(false);
  const [submittingDateError, setSubmittingDateError] = useState(false);
  const [pdfErrorMessage, setPdfErrorMessage] = useState(false);

  const getUnderwriters = async () => {
    const { data } = await axios.get("/api/blacklist/getunderwriters");
    setUnderwriters(data);
  };
  const changeUploadInfo = (label: keyof UploadInfo, value: any) => {
    const newUploadInfo = produce(upload, (draft: UploadInfo) => {
      draft[label] = value;
    });
    setUpload(newUploadInfo);
  };

  const handleFileDrop = useCallback(
    (acceptedFile: any) => {
      const file = Object.assign(acceptedFile[0], {
        preview: URL.createObjectURL(acceptedFile[0])
      });
      const extension = acceptedFile[0].name?.substring(acceptedFile[0].name?.lastIndexOf('.'));
      setPdfErrorMessage(false)
      if (extension?.toLowerCase() !== '.pdf') {
        setPdfErrorMessage(true);
      }
      setFile(file);
    },
    [setFile]
  );

  const save = async () => {
    setAttemptedSave(true);
    if (!upload.underwriter) {
      setSubmittingUnderwriterError(true);
      return;
    }
    if (!upload.date) {
      setSubmittingDateError(true);
      return;
    }
    if (!file || pdfErrorMessage) {
      return;
    }

    setSubmittingUnderwriterError(false);
    setSubmittingDateError(false);
    setSubmitting(true);
    onSave({ ...upload, file }, index || null);
    setSubmitting(false);
    onClose();
  };
  useEffect(() => {
    getUnderwriters();
  }, []);

  return (
    <Dialog open={open} fullWidth onClose={onClose} maxWidth="md">
      <DialogContent>
        <DialogTitle>Add Bulletin</DialogTitle>
        <Stack justifyContent="space-between" marginTop="40px">
          <Stack spacing={2} direction="row">
            <Autocomplete
              options={underwriters || []}
              renderInput={(params) => <TextField {...params} label="Underwriter" error={submittingUnderwriterError} />}
              onChange={(e, val) => changeUploadInfo("underwriter", val)}
              value={upload?.underwriter || null}
              getOptionLabel={(option) => option.name || ""}
              fullWidth
            />
            <DatePicker
              label="Bulletin Date"
              format="MM/dd/yyyy"
              value={upload?.date}
              onChange={(date) => {
                changeUploadInfo("date", date);
              }}
              slotProps={{
                textField: { error: submittingDateError, sx: { width: 850, marginRight: "24px" } }
              }}
            />
          </Stack>
        </Stack>
        <Stack marginTop="24px" direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
          {editUpload ? (
            <MultiFilePreview
              files={upload.file ? [upload.file] : []}
              showPreview={false}
              onRemove={() => {
                setFile(null);
                setPdfErrorMessage(false)
              }}
              onRemoveAll={() => {
                setFile(null);
                setPdfErrorMessage(false)
              }}
            />
          ) : (
            <UploadMultiFile
              error={attemptedSave && !file}
              files={file ? [file] : []}
              onDrop={handleFileDrop}
              onRemove={() => {
                setFile(null);
                setPdfErrorMessage(false)
              }}
              onRemoveAll={() => {
                setFile(null);
                setPdfErrorMessage(false)
              }}
              showPreview={false}
              showSelectedFiles={true}
              multiple={false}
            />
          )}
          <TextField
            label="Bulletin #"
            onChange={(e) => changeUploadInfo("number", e.target.value)}
            value={upload?.number || ""}
            fullWidth
          />
        </Stack>
        {pdfErrorMessage && (
          <div style={{ color: '#ff5252', fontSize: 12, marginTop:-20 }}>
            Document must be in PDF format
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="soft" disabled={submitting} onClick={save}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useMemo } from "react";
import { BasicTask, SortConfig } from "../types";

export const useSortedTasks = (tasks: BasicTask[], sortConfig: SortConfig | null/*, statusTypes: StatusType[]*/) => {
  const standardColumns = [
    { key: "description", label: "Task", accessor: (task: BasicTask) => task.description },
    { key: "quickNote", label: "Quick Note", accessor: (task: BasicTask) => task.quickNote },
    { key: "association", label: "Responsible Party", accessor: (task: BasicTask) => task.contactCode },
    { key: "status", label: "Status", accessor: (task: BasicTask) => task.taskItemStatus?.description }
  ];

  const flipsColumns = [
    { key: "description", label: "Task", accessor: (task: BasicTask) => task.description },
    { key: "quickNote", label: "Quick Note", accessor: (task: BasicTask) => task.quickNote },
    { key: "activityDate", label: "Activity", accessor: getActivityDate },
    { key: "status", label: "Status", accessor: (task: BasicTask) => task.taskItemStatus?.description }
  ];

  const sortedTasks = useMemo(() => {
    if (!sortConfig) return tasks;

    return [...tasks].sort((a, b) => {
      const itemA = sortConfig.accessor(a);
      const itemB = sortConfig.accessor(b);
      return compareValues(itemA, itemB, sortConfig.direction);
    });
  }, [tasks, sortConfig]);

  const isFlips = tasks[0].applicationId === 1;
  const columns = isFlips ? flipsColumns : standardColumns;

  return { sortedTasks, columns };
};

const compareValues = (
  valueA: string | Date | undefined,
  valueB: string | Date | undefined,
  direction: "asc" | "desc"
): number => {
  if (valueA === null || valueA === undefined) return direction === "asc" ? 1 : -1;
  if (valueB === null || valueB === undefined) return direction === "asc" ? -1 : 1;

  // Helper function to extract a numeric prefix from a string and to determine if it is numeric
  const extractAndClassify = (value: string): { value: number | string; isNumeric: boolean } => {
    const match = value.match(/^(\d+(\.\d+)?)/);
    return match ? { value: parseFloat(match[1]), isNumeric: true } : { value, isNumeric: false };
  };

  if (typeof valueA === "string" && typeof valueB === "string") {
    const { value: numA, isNumeric: isNumA } = extractAndClassify(valueA);
    const { value: numB, isNumeric: isNumB } = extractAndClassify(valueB);

    if (isNumA && !isNumB) return direction === "asc" ? -1 : 1;
    if (!isNumA && isNumB) return direction === "asc" ? 1 : -1;

    if (typeof numA === "number" && typeof numB === "number") {
      return (numA - numB) * (direction === "asc" ? 1 : -1);
    } else if (typeof numA === "string" && typeof numB === "string") {
      return numA.localeCompare(numB) * (direction === "asc" ? 1 : -1);
    }
  } else if (valueA instanceof Date && valueB instanceof Date) {
    return (valueA.getTime() - valueB.getTime()) * (direction === "asc" ? 1 : -1);
  }

  return 0;
};

const getActivityDate = (task: BasicTask): Date | undefined => {
  if (task.statusUpdateHistory.length === 0) {
    return undefined;
  }
  const latestEvent = task.statusUpdateHistory.reduce((latest, current) =>
    new Date(latest.dateTime) > new Date(current.dateTime) ? latest : current
  );
  return new Date(latestEvent.dateTime);
};

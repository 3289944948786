import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { CountyTask, GeneralTask, ModalType, CorpDocType, StateTask, TaskType } from "../types";
import axios from "axios";

export default function AddEditTaskModal({
  task,
  onClose,
  modalType,
  taskType,
  corpDocTypes
}: {
  task: GeneralTask | StateTask | CountyTask;
  onClose: () => void;
  modalType: ModalType;
  taskType: TaskType;
  corpDocTypes: CorpDocType[];
}) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [modalTask, setModalTask] = useState(task);

  const submit = async () => {
    setSubmitting(true);
    switch (taskType) {
      case TaskType.General:
        if (modalType === ModalType.Add) {
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/addgeneraltask", copy);
          onClose();
        } else {
          if (!modalTask) {
            setSubmitting(false);
            return;
          }
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/updategeneraltask", copy);
          onClose();
        }
        break;
      case TaskType.County:
        if (modalType === ModalType.Add) {
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/addcountytask", copy);
          onClose();
        } else {
          if (!modalTask) {
            setSubmitting(false);
            return;
          }
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/updatecountytask", copy);
          onClose();
        }
        break;
      case TaskType.State:
        if (modalType === ModalType.Add) {
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/addstatetask", copy);
          onClose();
        } else {
          if (!modalTask) {
            setSubmitting(false);
            return;
          }
          const copy = { ...modalTask } as any;
          copy.corpDocType = copy.corpDocType.id;
          await axios.post("/api/corpdocstracking/managetasks/updatestatetask", copy);
          onClose();
        }
        break;
    }
  };

  return (
    <Dialog open={true} fullWidth>
      <DialogContent sx={{ padding: 4 }}>
        <Typography variant="h6" color="primary" marginBottom={3}>
          {`${modalType === ModalType.Add ? "Add" : "Edit"}`} task:
        </Typography>
        <Stack spacing={2}>
          {modalType === ModalType.Add && (
            <Autocomplete
              size="small"
              sx={{ width: 300 }}
              options={corpDocTypes}
              renderInput={(params) => <TextField {...params} label="Document Type" />}
              getOptionLabel={(option) => option.name}
              onChange={(e, val) => setModalTask({ ...modalTask, corpDocType: val! })}
              value={modalTask.corpDocType}
            />
          )}
          <TextField
            value={modalTask.description}
            onChange={(e) => setModalTask({ ...modalTask, description: e.target.value })}
            placeholder="Task Description"
            rows={2}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={submit}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

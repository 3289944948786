import { Badge, Box, IconButton, Link, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useState } from "react";
import { fCurrency } from "../utils/formatNumber";
import { fDateAbbreviatedMonth, formatTimeElapsed, fTime, isTimeExceeded } from "../utils/formatTime";
import ApprovalStatusLabel from "./ApprovalStatusLabel";
import ActionButtons from "./StatusActionButtons";
import { DepositRefundRequest } from "./types";
import NoteModal from "./NoteModal";
import Iconify from "../../minimals/components/iconify";

export const CreateColumns = (refreshData: () => void): MRT_ColumnDef<DepositRefundRequest>[] => {
  return [
    {
      id: "requestedNote",
      header: "",
      accessorKey: "requestedNote",
      size: 25,
      Cell: ({ row }) => {
        const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
        const allNotesCount = [row.original.note, row.original.requestNote].filter(Boolean).length;
        return (
          <>
            <IconButton onClick={() => setIsNotesModalOpen(true)}>
              <Badge color="info" badgeContent={allNotesCount}>
                <Iconify icon="ic:baseline-note-add" />
              </Badge>
            </IconButton>

            {isNotesModalOpen && (
              <NoteModal
                row={row}
                isOpen={isNotesModalOpen}
                setIsOpen={setIsNotesModalOpen}
                refreshData={refreshData}
              />
            )}
          </>
        );
      },
      enableColumnFilter: false,
      enableSorting: false,
      enableColumnDragging: false,
      enableHiding: false,
      enableColumnActions: false
    },
    {
      id: "requestedDate",
      header: "Date/Time",
      accessorKey: "requestedDate",
      size: 25,
      Cell: ({ row }) => {
        const requestedDate = row.original.requestedDate;
        if (!requestedDate) return;
        return (
          <>
            <Typography variant="subtitle2" noWrap>
              {fDateAbbreviatedMonth(requestedDate)}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: "text.disabled", cursor: "pointer" }} noWrap>
              {fTime(requestedDate)}
            </Typography>
          </>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "orderNumber",
      header: "Order #",
      accessorKey: "orderNumber",
      Cell: ({ row }) => (
        <Link variant="body2" href={`https://internal.mcres.com/new-home/${row.original.orderNumber}`} target="_blank">
          {row.original.orderNumber}
        </Link>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "amount",
      header: "Amount",
      accessorKey: "amount",
      size: 15,
      Cell: ({ row }) => (
        <>
          <Typography variant="subtitle2">
            {row.original.amount !== null && `${fCurrency(row.original.amount || "")}`}
          </Typography>
        </>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "timeElapsed",
      header: "Time Elapsed",
      size: 15,
      Cell: ({ row }) => {
        //Refresh the data every minute
        const [_, setForceRender] = useState(0);
        useEffect(() => {
          const interval = setInterval(() => {
            setForceRender((prev) => prev + 1);
          }, 60000);

          return () => clearInterval(interval);
        }, []);
        const maxHoursUntilLate = 4;
        const requestedDate = new Date(row.original.requestedDate);
        const timeElapsedFormatted = formatTimeElapsed(requestedDate);
        const isExceeded = isTimeExceeded(requestedDate, maxHoursUntilLate);

        return (
          <Typography variant="subtitle2" sx={{ color: isExceeded ? "red" : "inherit" }}>
            {timeElapsedFormatted}
          </Typography>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "address",
      header: "Address",
      accessorKey: "address",
      Cell: ({ renderedCellValue, row }) => (
        <Box width={200} position="relative" whiteSpace="pre-wrap">
          <Typography variant="body2" color="grey">
            {row.original.address}
          </Typography>
        </Box>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "requestedName",
      header: "Requested By",
      accessorKey: "requestedName",
      size: 25,
      Cell: ({ renderedCellValue, row }) => (
        <Box width={100} position="relative" whiteSpace="pre-wrap">
          <Typography variant="body2" color="grey">
            {row.original.requestedByName}
          </Typography>
        </Box>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "clientName",
      header: "Client",
      accessorKey: "clientName",
      size: 25,
      Cell: ({ renderedCellValue, row }) => <Typography variant="subtitle2">{row.original.clientName}</Typography>,
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "dealAmount",
      header: "Deal Amount",
      accessorKey: "dealAmount",
      size: 25,
      Cell: ({ renderedCellValue, row }) => (
        <Typography variant="subtitle2">
          {row.original.dealAmount !== null && `${fCurrency(row.original.dealAmount || "")}`}
        </Typography>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    // {
    //   id: "approvalStatusDescription",
    //   header: "Status",
    //   accessorKey: "approvalStatusDescription",
    //   Cell: ({ row }) => (
    //     <ApprovalStatusLabel status={row.original.approvalStatus} text={row.original.approvalStatusDescription} />
    //   ),
    //   muiFilterTextFieldProps: {
    //     variant: "outlined",
    //     size: "small"
    //   },
    //   filterVariant: "multi-select",
    //   filterSelectOptions: [
    //     { text: "New", value: "New" },
    //     { text: "Closed", value: "Closed" }
    //   ]
    // },
    {
      id: "reviewedByName",
      header: "Reviewed By",
      accessorKey: "reviewedByName",
      size: 25,
      Cell: ({ row }) => <Typography variant="subtitle2"> {row.original.reviewedByName}</Typography>,
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      id: "actions",
      header: "Actions",
      size: 25,
      Cell: ({ renderedCellValue, row }) => (
        <ActionButtons row={row} approvalStatus={row.original.approvalStatus} refreshData={refreshData} />
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    }
  ];
};

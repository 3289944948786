import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Check, ChecksData, DashboardNote } from "./types";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { checksSort } from "./checksSort";
import { MRT_TableInstance } from "material-react-table";

// ----------------------------------------------------------------------

const ChecksContext = createContext<ChecksData>({} as ChecksData);

function ChecksContextProvider({ children }: { children: JSX.Element }) {
  const today = dayjs();
  const defaultEndDate = today.subtract(10, "days");
  const defaultStartDate = dayjs("7/4/2023");
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [loading, setLoading] = useState(true);
  const [checks, setChecks] = useState<Check[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<Check> | null>(null);
  const resetPaginationFunc = () => {
    if (tableRef.current) {
      tableRef.current.resetPageIndex();
    }
  };

  const fetchChecks = async ({ useLoader = true, resetPagination = true } = {}): Promise<void> => {
    if (useLoader) {
      setLoading(true);
    }
    const [startDate, endDate] = dateRange;
    const { data } = await axios.get<Check[]>(
      `/api/OutstandingChecks/GetChecks?from=${startDate || defaultStartDate}&to=${endDate || defaultEndDate}`
    );
    data.sort(checksSort);
    setChecks(data);
    if (useLoader) {
      setLoading(false);
    }
    if (resetPagination) {
      resetPaginationFunc();
    }
  };

  useEffect(() => {
    fetchChecks({ useLoader: true, resetPagination: true });
  }, [dateRange]);

  return (
    <ChecksContext.Provider
      value={{
        setDateRange,
        dateRange,
        loading,
        checks,
        setChecks,
        assignedToMe,
        setAssignedToMe,
        fetchChecks,
        tableRef
      }}
    >
      {children}
    </ChecksContext.Provider>
  );
}

export { ChecksContextProvider, ChecksContext };

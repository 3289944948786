import { Box, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { CorpDoc, TaskItem } from "./types";
import Iconify from "../../minimals/components/iconify";
import FileIcon from "./components/FileIcon";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import { getType } from "./getType";
import { useNavigate, useSearchParams } from "react-router-dom";
import VersionCount from "./VersionCount";
import OpenFileButton from "./OpenFileButton";
import UploadButton from "./UploadButton";

interface IProps {
  corpDoc: CorpDoc;
  tasks: TaskItem[];
  setOpenVersionUpload: (open: boolean) => void;
  showTaskFilters: boolean;
  toggleShowTaskFilters: () => void;
}

export default function OrderInfo({ corpDoc, setOpenVersionUpload, showTaskFilters, toggleShowTaskFilters }: IProps) {
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");
  const navigate = useNavigate();

  const navigateToCorpDocsPage = () => {
    navigate(`/corpdocs/?order=${order}`);
  };

  const totalTasksCount = corpDoc.taskItems.filter((t) => t.status !== 3).length;
  const completedTasksCount = corpDoc.taskItems.filter((t) => t.status === 2).length;

  return (
    <>
      <Stack direction="row" spacing={18} padding={3} alignItems="center">
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <IconButton onClick={navigateToCorpDocsPage}>
                <Iconify icon="eva:arrow-back-fill" />
              </IconButton>
            </Box>
            <Stack direction="row" spacing={2}>
              <FileIcon />
              <Box>
                <Typography variant="subtitle2">
                  {getType(corpDoc?.type)} {corpDoc.description && `- ${corpDoc.description}`}
                </Typography>
                <Tooltip disableFocusListener title="Add">
                  <VersionCount
                    versionCount={corpDoc.documents.length}
                    documents={corpDoc.documents}
                    openVersionUpload={() => setOpenVersionUpload(true)}
                  />
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </Box>
        <LinearProgressWithLabel totalTasks={totalTasksCount} completedTasks={completedTasksCount} />
        {completedTasksCount < totalTasksCount && <Chip label="Needs Review" color="warning" />}
        {completedTasksCount === totalTasksCount && <Chip label="Ready for Recording" color="success" />}
        <Stack direction="row" spacing={2}>
          <UploadButton setOpenVersionUpload={() => setOpenVersionUpload(true)} />
          <OpenFileButton href={`https://internal.mcres.com/opendoc?id=${corpDoc.documents[0].momentumDocumentId}`} />
          <IconButton onClick={toggleShowTaskFilters}>
            <Iconify icon={showTaskFilters ? "ic:outline-filter-list" : "ic:outline-filter-list-off"} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
}

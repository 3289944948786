import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import Iconify from "../../../minimals/components/iconify";
import { Check } from "../../checks/types";
import { CustomAvatar } from "../../components/custom-avatar";
import { UserContext } from "../../contexts/UserContext";
import { NonZeroLedgerBalanceInfo } from "../nonZeroLedgerBalance/types";
import { PolicyNotIssued } from "../policiesNotIssued/types";
import { MemberTaskCount } from "../types";
import DashboardBadge from "./DashboardBadge";
import LoadingSkeletonTable from "./LoadingSkeletonTable";
import { getChecksUniqueAssignedToList, searchChecks } from "./utils/checkUtils";
import {
  getEscrowOverdueOrdersUniqueAssignedToList,
  searchEscrowOverdueOrders
} from "./utils/escrowOverdueOrdersUtils";
import {
  getNonZeroLedgerBalancesUniqueAssignedToList,
  searchNonZeroLedgerBalances
} from "./utils/nonZeroLedgerBalancesUtils";
import { getPoliciesNotIssuedUniqueAssignedToList, searchPoliciesNotIssued } from "./utils/policiesNotIssuedUtils";
import { InternalUser } from "../../types/app";
import { EscrowLedger } from "../escrowLedgers/types";

export default function ManagersDashboard() {
  const { user, teamMembers, isFetchingTeamMembers } = useContext(UserContext);

  const teamMemberIds = useMemo(() => teamMembers.map((m) => m.id), [teamMembers]);
  const departments = useMemo(() => user?.departments.map((m) => m.toLowerCase()) || [], [user?.departments]);

  const [loading, setLoading] = useState(true);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);
  const [uniqueChecksAssignedToList, setUniqueChecksAssignedToList] = useState<MemberTaskCount[]>([]);
  const [uniquePoliciesNotIssuedAssignedToList, setUniquePoliciesNotIssuedAssignedToList] = useState<MemberTaskCount[]>(
    []
  );
  const [uniqueEscrowOverdueOrdersAssignedToList, setUniqueEscrowOverdueOrdersAssignedToList] = useState<
    MemberTaskCount[]
  >([]);
  const [uniqueNonZeroLedgerBalancesAssignedToList, setUniqueNonZeroLedgerBalancesAssignedToList] = useState<
    MemberTaskCount[]
  >([]);
  const [allChecks, setAllChecks] = useState<Check[]>([]);
  const [filteredChecks, setFilteredChecks] = useState<Check[]>([]);
  const [allPoliciesNotIssued, setAllPoliciesNotIssued] = useState<PolicyNotIssued[]>([]);
  const [filteredPoliciesNotIssued, setFilteredPoliciesNotIssued] = useState<PolicyNotIssued[]>([]);
  const [allEscrowOverdueOrders, setAllEscrowOverdueOrders] = useState<EscrowLedger[]>([]);
  const [filteredEscrowOverdueOrders, setFilteredEscrowOverdueOrders] = useState<EscrowLedger[]>([]);
  const [allNonZeroLedgerBalances, setAllNonZeroLedgerBalances] = useState<NonZeroLedgerBalanceInfo[]>([]);
  const [filteredNonZeroLedgerBalances, setFilteredNonZeroLedgerBalances] = useState<NonZeroLedgerBalanceInfo[]>([]);

  const filterToMyTeam = ({
    assignedToUser,
    department
  }: {
    assignedToUser: InternalUser | null;
    department?: string;
  }) => {
    const isUnassignedInDepartment =
      !assignedToUser?.id && department && departments.includes(department.toLowerCase());
    const isAssignedToUserOrTeam =
      assignedToUser?.id && (assignedToUser.id === user?.id || teamMemberIds.includes(assignedToUser.id));
    return isUnassignedInDepartment || isAssignedToUserOrTeam;
  };

  const fetchChecks = async () => {
    const allChecks = await searchChecks();
    setAllChecks(allChecks);
    const filteredChecks = allChecks.filter(filterToMyTeam);

    setFilteredChecks(filteredChecks);
  };

  const fetchPoliciesNotIssued = async () => {
    const allPoliciesNotIssued = await searchPoliciesNotIssued();
    setAllPoliciesNotIssued(allPoliciesNotIssued);
    const filteredPoliciesNotIssued = allPoliciesNotIssued.filter(filterToMyTeam);
    setFilteredPoliciesNotIssued(filteredPoliciesNotIssued);
  };

  const fetchEscrowOverdueOrders = async () => {
    const allEscrowOverdueOrders = await searchEscrowOverdueOrders();
    setAllEscrowOverdueOrders(allEscrowOverdueOrders);
    const filteredEscrowOverdueOrders = allEscrowOverdueOrders.filter(filterToMyTeam);
    setFilteredEscrowOverdueOrders(filteredEscrowOverdueOrders);
  };

  const fetchNonZeroLedgerBalances = async () => {
    const allNonZeroLedgerBalances = await searchNonZeroLedgerBalances();
    setAllNonZeroLedgerBalances(allNonZeroLedgerBalances);
    const filteredNonZeroLedgerBalances = allNonZeroLedgerBalances.filter(filterToMyTeam);
    setFilteredNonZeroLedgerBalances(filteredNonZeroLedgerBalances);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user || isFetchingTeamMembers) {
        return;
      }
      setLoading(true);
      await Promise.all([
        fetchChecks(),
        fetchPoliciesNotIssued(),
        fetchEscrowOverdueOrders(),
        fetchNonZeroLedgerBalances()
      ]);
      setLoading(false);
    };

    fetchData();
  }, [isFetchingTeamMembers]);

  useEffect(() => {
    const checks = assignedToMe ? filteredChecks : allChecks;
    const policiesNotIssued = assignedToMe ? filteredPoliciesNotIssued : allPoliciesNotIssued;
    const escrowOverdueOrders = assignedToMe ? filteredEscrowOverdueOrders : allEscrowOverdueOrders;
    const nonZeroLedgerBalances = assignedToMe ? filteredNonZeroLedgerBalances : allNonZeroLedgerBalances;
    const uniqueChecksList = getChecksUniqueAssignedToList(checks);
    setUniqueChecksAssignedToList(uniqueChecksList);
    const uniquePoliciesList = getPoliciesNotIssuedUniqueAssignedToList(policiesNotIssued);
    setUniquePoliciesNotIssuedAssignedToList(uniquePoliciesList);
    const uniqueEscrowOverdueOrderList = getEscrowOverdueOrdersUniqueAssignedToList(escrowOverdueOrders);
    setUniqueEscrowOverdueOrdersAssignedToList(uniqueEscrowOverdueOrderList);
    const uniqueNonZeroLedgerBalancesList = getNonZeroLedgerBalancesUniqueAssignedToList(nonZeroLedgerBalances);
    setUniqueNonZeroLedgerBalancesAssignedToList(uniqueNonZeroLedgerBalancesList);
  }, [
    assignedToMe,
    filteredChecks,
    allChecks,
    filteredPoliciesNotIssued,
    allPoliciesNotIssued,
    filteredEscrowOverdueOrders,
    allEscrowOverdueOrders,
    filteredNonZeroLedgerBalances,
    allNonZeroLedgerBalances
  ]);

  const combinedUniqueList = useMemo(() => {
    const combinedMap = new Map();
    [
      uniqueChecksAssignedToList,
      uniquePoliciesNotIssuedAssignedToList,
      uniqueEscrowOverdueOrdersAssignedToList,
      uniqueNonZeroLedgerBalancesAssignedToList
    ].forEach((list, index) => {
      list.forEach((item) => {
        const assignedToId = item.assignedToId;

        if (!combinedMap.has(assignedToId)) {
          combinedMap.set(assignedToId, {
            assignedToId,
            assignedToName: item.assignedToName,
            assignedToEmail: item.assignedToEmail,
            checks: index === 0 ? item : null,
            policies: index === 1 ? item : null,
            escrowOverdueOrders: index === 2 ? item : null,
            nonZeroLedgerBalances: index === 3 ? item : null
          });
        } else {
          const entry = combinedMap.get(assignedToId);
          if (index === 0) entry.checks = item;
          if (index === 1) entry.policies = item;
          if (index === 2) entry.escrowOverdueOrders = item;
          if (index === 3) entry.nonZeroLedgerBalances = item;
        }
      });
    });

    // Convert the values of the map to an array to get the final combined list
    const combinedList = Array.from(combinedMap.values());

    // Sort the combinedList by the last name, with 'unassigned' always at the start
    combinedList.sort((a, b) => {
      const isAUnassigned = a.assignedToName.includes("Unassigned");
      const isBUnassigned = b.assignedToName.includes("Unassigned");

      if (isAUnassigned && !isBUnassigned) {
        return -1;
      } else if (!isAUnassigned && isBUnassigned) {
        return 1;
      } else if (isAUnassigned && isBUnassigned) {
        return 0;
      } else {
        // Neither are unassigned, sort by last name
        const lastNameA = a.assignedToName.split(" ")[1];
        const lastNameB = b.assignedToName.split(" ")[1];
        return lastNameA.localeCompare(lastNameB);
      }
    });

    return combinedList;
  }, [
    uniqueChecksAssignedToList,
    uniquePoliciesNotIssuedAssignedToList,
    uniqueEscrowOverdueOrdersAssignedToList,
    uniqueNonZeroLedgerBalancesAssignedToList
  ]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" marginLeft={2} marginBottom={2}>
        Managers Dashboard
      </Typography>
      <FormControlLabel
        sx={{ marginLeft: 1, marginBottom: 2, userSelect: "none" }}
        control={<Checkbox checked={assignedToMe} onChange={(e, checked) => setAssignedToMe(checked)} />}
        label={"Assigned to anyone on my team"}
      />
      {loading && <LoadingSkeletonTable />}
      {!loading && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {combinedUniqueList.map((m, i) => (
                <TableRow key={i}>
                  <TableCell width={200}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CustomAvatar name={m.assignedToName} />
                      <Box>
                        <Typography variant="subtitle2">{m.assignedToName}</Typography>
                        <Typography variant="body2">{m.assignedToEmail}</Typography>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <DashboardBadge
                        badgeContent={m.checks?.count}
                        label="Outstanding Checks"
                        color="#00a8a8"
                        hoverBackgroundColor="#00a8a814"
                        hoverColor="#007d7d"
                        disabled={!m.checks}
                        href={`/checks?assignedtoid=${m.assignedToId}`}
                      />
                      <DashboardBadge
                        badgeContent={m.policies?.count}
                        label="Policies Not Issued"
                        color="#e74c3c"
                        hoverBackgroundColor="#e74c3c14"
                        hoverColor="#c0392b"
                        disabled={!m.policies}
                        href={`/policies-not-issued?assignedtoid=${m.assignedToId}`}
                      />
                      <DashboardBadge
                        badgeContent={m.escrowOverdueOrders?.count}
                        label="Escrow Overdue"
                        color="#f39c12"
                        hoverBackgroundColor="#f39c1214"
                        hoverColor="#d68910"
                        disabled={!m.escrowOverdueOrders}
                        href={`/escrow-ledgers?assignedtoid=${m.assignedToId}`}
                      />
                      <DashboardBadge
                        badgeContent={m.nonZeroLedgerBalances?.count}
                        label="Non Zero Ledger Balances"
                        color="#3498db"
                        hoverBackgroundColor="#3498db14"
                        hoverColor="#2980b9"
                        disabled={!m.nonZeroLedgerBalances}
                        href={`/non-zero-ledger-balances?assignedtoid=${m.assignedToId}`}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && combinedUniqueList.length === 0 && (
        <Card>
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify color="success.main" icon="ic:baseline-check-circle-outline" />
              <Typography variant="h6">
                {assignedToMe ? "Your team has successfully completed all assigned tasks." : "No outstanding tasks"}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

// @mui
import { Grid, Stack } from "@mui/material";
import ProfileAbout from "./About";
import NoteCard from "../../shared/profile/NoteCard";
import ProfilePostInput from "../../shared/profile/NoteInput";
import AccountReps from "../../shared/profile/AccountReps";
import axios from "axios";
import { useContext } from "react";
import { ContactContext } from "../ContactContext";
import { Note } from "../../types";
import SaleReps from "../../shared/profile/SaleReps";
import PersonalInfo from "./PersonalInfo";
import { CompanyContext } from "../../company/CompanyContext";

// ----------------------------------------------------------------------

export default function Profile() {
  const { contact, setContact } = useContext(ContactContext);
  const { company, setCompany } = useContext(CompanyContext);

  const updateNotes = (note: Note) => {
    setContact((draft) => {
      if (draft) {
        draft.notes.unshift(note);
      }
    });
  };

  const saveNote = async (note: string) => {
    const { data } = await axios.post("/api/clientphonebook/contactNotes/add", {
      text: note,
      contactId: contact?.id
    });
    updateNotes(data);
  };

  const hasPersonalInfo =
    contact?.address1 ||
    contact?.personalEmail ||
    contact?.birthday ||
    contact?.phoneNumbers.some((p) => p.type === "Home");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <ProfileAbout />
          {hasPersonalInfo && <PersonalInfo />}
          <SaleReps />
          <AccountReps />
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <ProfilePostInput showClientInstructionsHeader={false} saveNote={saveNote} updateNotes={updateNotes} />
          {/* --------------- Regular notes (non client instruction) --------------- */}
          {contact?.notes
            .filter((n) => !n.isClientInstructions)
            .map((note) => (
              <NoteCard
                showClientInstructionsHeader={false}
                key={note.id}
                note={note}
                companyId={company?.id}
                contactId={contact?.id}
              />
            ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

import { MRT_ColumnDef } from "material-react-table";
import { Audit } from "./types";
import { Link, Typography } from "@mui/material";
import { fMonthDayYearSlashNotation } from "../utils/formatTime";

const getDaysColor = (daysCount: number) => {
  if (daysCount > 0) {
    return "inherit";
  } else if (daysCount === -1 || daysCount === 0) {
    return "success.main";
  } else {
    return "error.main";
  }
};

export const insuranceAuditResultsColumns: MRT_ColumnDef<Audit>[] = [
  {
    header: "Audit Item",
    accessorKey: "description",
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "location",
    header: "Location of item",
    accessorFn: (row) =>
      row.items?.map((i) =>
        i.url ? (
          <Link target="_blank" href={i.url} sx={{ display: "block", textDecoration: "underline" }}>
            {i.location}
          </Link>
        ) : (
          <Typography variant="body2" color={i.location === "Not Found" ? "error" : "inherit"}>
            {i.location}
          </Typography>
        )
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "daysBeforeClosing",
    header: "Days before closing",
    accessorFn: (row) =>
      row.items?.map((i) =>
        i.daysBeforeClosing !== null ? (
          <Typography variant="body2" color={getDaysColor(+i.daysBeforeClosing)}>
            {i.daysBeforeClosing}
          </Typography>
        ) : null
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "date",
    header: "Date",
    accessorFn: (row) =>
      row.items?.map((i) =>
        i.date ? (
          <Typography variant="body2" color={i.highlightDateInRed ? "error.main" : "inherit"}>
            {fMonthDayYearSlashNotation(i.date)}
          </Typography>
        ) : null
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

import { Box, IconButton } from "@mui/material";
import Iconify from "../../minimals/components/iconify";

export default function UploadButton({ setOpenVersionUpload }: { setOpenVersionUpload: () => void }) {
  return (
    <Box>
      <IconButton onClick={setOpenVersionUpload}>
        <Iconify icon="eva:upload-fill" />
      </IconButton>
    </Box>
  );
}

export const OtherNameSearchTypes = [
  "Fee Owner",
  "Name Search",
  "Rundown Name Search",
  "Name on Application",
  "Name Requested",
  "Guarantor",
  "Leasehold Owner",
  "Prior Owner"
];

import React from "react";
import { NumericFormat } from "react-number-format";

type CustomProps = any;

export const NumberFormatCustom = React.forwardRef<typeof NumericFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        isnumericstring="true"
        prefix="$"
      />
    );
  }
);

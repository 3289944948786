import { Button, Card, Grid, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { SearchesContext } from "../../contexts/SearchesContext";
import Iconify from "../../../minimals/components/iconify";
import AdditionalNameModel from "./AdditionalNameModal";

export default function Names() {
  const { order, setOrder } = useContext(SearchesContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5" marginBottom={2}>
          Names
        </Typography>
        {order?.contacts.map((c) => (
          <Grid container>
            <Grid item xs={11}>
              <Grid container>
                <Grid item paddingRight={2} xs={1}>
                  <Iconify icon="eva:person-fill" sx={{ top: 3, position: "relative" }} />
                </Grid>
                <Grid item xs={11} paddingRight={4}>
                  <Typography variant="subtitle1">{c.type === "Other" ? c.otherType : c.type}</Typography>
                  <Typography marginBottom={2}>{c.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                color="error"
                onClick={() =>
                  setOrder({
                    ...order,
                    contacts: order.contacts.filter(
                      (contact) => (contact.name || "") + contact.type != (c.name || "") + c.type
                    )
                  })
                }
              >
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          onClick={() => setModalOpen(true)}
          size="small"
          sx={{ marginBottom: 1 }}
          startIcon={<Iconify icon={"eva:plus-fill"} />}
        >
          Add additional name
        </Button>
      </Card>
      <AdditionalNameModel open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

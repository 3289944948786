import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { Contact } from "../types/Contact";
import { OrderContext } from "../../contexts/OrderDataContext";
import { ContactType } from "../types/ContactType";
import { TextField, Autocomplete, Button, Typography, Grid, IconButton } from "@mui/material";
import DebouncedAutoComplete from "../../components/DebouncedAutoComplete";
import ContactCardNew from "./ContactCardNew";
import Iconify from "../../../minimals/components/iconify";
import { AdditionalEmailsWrap } from "./PhonebookContactStyles";
import { IFrameContext } from "../../contexts/IFrameContext";

interface IProps {
  isAttorney: boolean | undefined;
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  setContactType: React.Dispatch<React.SetStateAction<ContactType | null>>;
  contactType: ContactType;
  contactList: Contact[];
  setContactList: React.Dispatch<React.SetStateAction<Contact[]>>;
  searchedContact: string | null;
  setSearchedContact: Dispatch<SetStateAction<string | null>>;
}

export default function PhonebookContact({
  isAttorney,
  contact,
  setContactList,
  setContact,
  setContactType,
  contactType,
  contactList,
  searchedContact,
  setSearchedContact
}: IProps) {
  const { contactTypeList, buyers, sellers, onContactSearchTextChanged } = useContext(OrderContext);
  const { openPhonebookIFrame } = useContext(IFrameContext);
  const [representsContact, setRepresentsContact] = useState<any>();

  const emailsSplit = contact?.emailsSplit || [];

  const representsList = useMemo(() => {
    return [
      { id: "", name: "All Buyers", code: "ba", type: null } as Contact,
      { id: "", name: "All Sellers", code: "sa", type: null } as Contact,
      ...buyers.map((b) => ({ id: "", name: `${b.firstName1 || ""}  ${b.lastName1 || ""}`, code: "", type: "Buyer" })),
      ...sellers.map((s) => ({ id: "", name: `${s.firstName1 || ""} ${s.lastName1 || ""}`, code: "", type: "Seller" }))
    ];
  }, [buyers, sellers]);

  const setRepresentsField = async (contactType: ContactType) => {
    const attorneyType = contactTypeList.find((t) => t.name === "Attorney");
    const isBuyersAttorney = contactType?.name === "Buyer's Attorney";
    if (contactType?.name === "Buyer's Attorney" || contactType?.name === "Seller's Attorney") {
      setContact({ ...contact, represents: isBuyersAttorney ? "ba" : "sa", type: contactType?.name });
      setContactType(attorneyType!);
    }
    if (contactType?.name === "Lender's Attorney") {
      const firstLender = representsList.find((c) => c.type?.toLowerCase().includes("lender"));
      if (firstLender) {
        setContact({ ...contact, represents: firstLender?.name, type: contactType?.name, contactType: attorneyType });
      }
      setContactType(attorneyType!);
    }
  };

  useEffect(() => {
    if (isAttorney) {
      setRepresentsField(contactType);
    }
  }, [contactType.name]);

  useEffect(() => {
    const represents = representsList.find((r) => r.code === contact.represents || r.name === contact.represents);
    setRepresentsContact(represents);
  }, [contactType.name, contact.represents]);

  const contactSearchMode = !contact.name;

  const updateContact = (updateContactObj: Contact) => {
    setContact((currentContact) => ({
      ...updateContactObj,
      contactType: currentContact.contactType,
      type: currentContact?.type,
      represents: currentContact?.represents
    }));
  };

  return (
    <>
      {isAttorney && (
        <Grid item xs={6}>
          <Autocomplete
            key={representsContact?.code}
            value={representsContact}
            options={representsList}
            getOptionLabel={(option) => {
              if (typeof option === "object") {
                if (option?.name.trim()) {
                  return `${option?.type ? option?.type + " - " : ""} ${option?.name ? option?.name?.trim() : ""}`;
                }
              }
              return "";
            }}
            size="small"
            onChange={(e, value: any) => {
              setContact({ ...contact, represents: value.code });
            }}
            renderInput={(params) => <TextField {...params} label="Represents" placeholder="Start typing..." />}
          />
        </Grid>
      )}
      {contactType?.isPhonebookContact && (
        <>
          <Grid item xs={5}>
            <ContactCardNew contact={contact} updateContactInPhonebook={updateContact} />
          </Grid>
          <Grid item xs={7}>
            <DebouncedAutoComplete
              size="small"
              value={contact}
              fullWidth={true}
              options={contactList}
              getOptionsLabel={(option) => option?.name || ""}
              onSelectedOptionChanged={(_e, c) => {
                if (!c) {
                  setSearchedContact(null);
                }
                if (typeof c === "object") {
                  updateContact(c!);
                }
              }}
              noOptionsText={
                searchedContact && contactList ? (
                  <Button
                    sx={{ textTransform: "lowercase" }}
                    fullWidth
                    onClick={() =>
                      openPhonebookIFrame({
                        defaultName: searchedContact,
                        onSave: updateContact,
                        selectedLookupCode: "",
                        pageView: "create-contact"
                      })
                    }
                  >
                    Add "{searchedContact}" to Phonebook
                  </Button>
                ) : (
                  ""
                )
              }
              renderOption={(props, option) => (
                <li {...props} key={option?.lookupCode}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    {/* Icon addition */}
                    <Iconify
                      icon={option.isIndividual ? "mdi:account" : "heroicons-outline:office-building"}
                      width={24}
                      height={24}
                      style={{ marginRight: "8px" }}
                    />
                    {/* End of Icon addition */}
                    <div style={{ flex: 1 }}>
                      <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                        {`${option?.name || ""} (${option.isIndividual ? `Individual` : `Company`})`}
                      </Typography>
                      {option?.email && (
                        <Typography sx={{ display: "inline-block" }} variant="caption">
                          {`${option?.email || ""}`}
                        </Typography>
                      )}
                    </div>
                  </div>
                </li>
              )}
              textboxLabel={`Search for ${contactType?.name}...`}
              textboxPlaceholder="Search the phonebook/Lookup..."
              onDebouncedTextChanged={(text) => onContactSearchTextChanged(text, setContactList)}
              additionalOnChange={(text) => setSearchedContact(text)}
              isSearch={contactSearchMode}
            />
            {Boolean(emailsSplit.length) && (
              <AdditionalEmailsWrap>
                <div className="email-header">
                  <span>Emails</span>
                </div>
                <div className="new-emails">
                  {emailsSplit.map((email, index) => (
                    <div key={index} className="g1 input_wrap">
                      <TextField
                        type="text"
                        size="small"
                        label={index === 0 ? "Email" : `CC ${index}`}
                        disabled={index === 0}
                        value={email}
                        fullWidth
                        onChange={(e) => {
                          const emailsSplitCopy = [...emailsSplit];
                          emailsSplitCopy[index] = e.target.value;
                          setContact({ ...contact, emailsSplit: emailsSplitCopy });
                        }}
                      />
                      <IconButton
                        style={index === 0 ? { visibility: "hidden" } : {}}
                        aria-label="delete"
                        onClick={() => {
                          setContact({ ...contact, emailsSplit: emailsSplit.filter((_, i) => i != index) });
                        }}
                      >
                        <Iconify icon={"eva:trash-2-outline"} />
                      </IconButton>
                    </div>
                  ))}
                  <div
                    className="add-button"
                    onClick={() => setContact({ ...contact, emailsSplit: [...emailsSplit, ""] })}
                  >
                    <Iconify icon={"eva:plus-square-fill"} />
                    <div>Add a CC</div>
                  </div>
                </div>
              </AdditionalEmailsWrap>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

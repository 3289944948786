import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { PropertyInfo, SmartyAddressResponse } from "../orderEntry/types/Property";
import { getProperyInfoOptionFromSmartyResponse } from "../utils/property";

interface Props {
  propertyInfo: PropertyInfo;
  setPropertyInfo: (propertyInfo: any) => void;
  states: any[];
  setCounties: (counties: any[]) => void;
  counties: any[];
}

export default function AddressSearch(props: Props) {
  const [options, setOptions] = useState([] as PropertyInfo[]);
  const [isAddressOptionsOpen, setIsAddressOptionsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchSelectedAddress = async (text: string, selected: string) => {
    if (!text) return [];
    const { data } = await axios.get(
      `/api/property/searchSelectedAddress?searchText=${encodeURIComponent(text)}&selected=${encodeURIComponent(
        selected
      )}`
    );
    return data.suggestions.map((d: SmartyAddressResponse) => getProperyInfoOptionFromSmartyResponse(d));
  };
  const getState = (stateName: string | null, stateArray: any[]) => {
    return stateArray.find((state:any) => state.abbreviation.toLowerCase() === stateName?.toLowerCase());
  };
  const handleChange = async (e: SyntheticEvent<Element, Event>, value: string | PropertyInfo | null) => {
    setOptions([]);
    if (value == null || typeof value == "string") {
      setOptions([]);
      return;
    }

    const { formattedAddress = "", isFreeFormEntry, entries, address, aptNo, city, state, zipCode } = value;
    const stateObj = getState(state, props.states)
    
    props.setPropertyInfo((propertyInfo: any) => ({ ...propertyInfo, ...value, state: stateObj }));
    props.setCounties(stateObj.counties);
    if (Number(entries) > 1) {
      const unitOptions = await searchSelectedAddress(
        formattedAddress || "",
        `${address} ${aptNo} (${entries}) ${city} ${state} ${zipCode}`
      );
      setOptions(unitOptions);
      setIsAddressOptionsOpen(true);
      return;
    }
  };
  const onPropertySearchTextChanged = async (text: string, propertyInfo: PropertyInfo) => {
    setLoading(true);
    if (!text) {
      setOptions([]);
      setLoading(false);
      return;
    }

    const { data } = await axios.get(`/api/property/search?searchText=${encodeURIComponent(text)}`);
    const suggestions = data.suggestions.map((d: SmartyAddressResponse) => getProperyInfoOptionFromSmartyResponse(d));
    const labelForFreeformTextOption = suggestions.length
      ? `Results below don't match. I want to use "${text}" instead`
      : `No results found for "${text}", press “TAB” key to use typed entry.`;

    const freeTypedOption = {
      ...propertyInfo,
      isFreeFormEntry: true,
      optionLabel: labelForFreeformTextOption,
      entries: 0,
      address: text,
      formattedAddress: text
    } as PropertyInfo;

    setOptions([freeTypedOption].concat(suggestions));
    setLoading(false);
    setIsAddressOptionsOpen(true);
  };
  const debouncedOnPropertySearchTextChanged = useCallback(debounce(onPropertySearchTextChanged, 500), []);
  const handleInputChange = async (event: any, newInputValue: string, reason: any) => {
    props.setPropertyInfo((propertyInfo: any) => ({ ...propertyInfo, formattedAddress: newInputValue }));
    if (newInputValue.trim() == "") {
      setOptions([]);

      await debouncedOnPropertySearchTextChanged("", props.propertyInfo);
      return;
    }

    setOptions([]);

    if (reason == "input") await debouncedOnPropertySearchTextChanged(newInputValue, props.propertyInfo);
  };

  return (
    <Autocomplete
      value={props.propertyInfo}
      onChange={handleChange}
      inputValue={props.propertyInfo?.formattedAddress || ""}
      onInputChange={handleInputChange}
      options={options}
      open={isAddressOptionsOpen}
      freeSolo
      id='propery-quick-entry'
      renderInput={(params) => (
        <TextField {...params} label='Property Quick Entry' placeholder='Start typing an address...' />
      )}
      getOptionLabel={(option) => (typeof option === "string" ? option : option?.formattedAddress || "")}
      loadingText='Loading...'
      loading={loading}
      renderOption={(props, option) => {
        const { optionLabel = "", isFreeFormEntry } = option || {};
        return (
          <li {...props} key={optionLabel}>
            <div style={{ width: "100%" }}>
              <div>
                <Typography sx={{ display: "inline-block" }} variant={!isFreeFormEntry ? "subtitle2" : "body1"}>
                  {optionLabel}
                </Typography>
              </div>
            </div>
          </li>
        );
      }}
    />
  );
}

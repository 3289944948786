export const TABS = [
  {
    value: "All",
    count: 24
  },
  {
    value: "Paid",
    count: 4
  },
  {
    value: "Unpaid",
    count: 8
  },
  {
    value: "Overdue",
    count: 8
  },
  {
    value: "Draft",
    count: 4
  }
];

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SearchesContext } from "../../contexts/SearchesContext";
import { Contact } from "../../orderEntry/types/Contact";
import { Order } from "../../orderEntry/types/Order";
import { OtherNameSearchTypes } from "./OtherNameSearchTypes";

export default function AdditionalNameModel(prop: { open: boolean; onClose: () => void }) {
  const { order, setOrder } = useContext(SearchesContext);
  const { open, onClose } = prop;
  const [type, setType] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const addContactToOrder = async () => {
    await axios({
      method: "post",
      url: "/proxy/api/orders/addOrderContact",
      data: {
        orderNumber: order?.orderNumber,
        contact: {
          name,
          contactType: type,
          isOther: true
        }
      }
    });
  };

  const addContact = async () => {
    setSubmitting(true);
    await addContactToOrder();
    setOrder({
      ...order,
      contacts: order?.contacts ? [...order?.contacts, { name, otherType: type, type: "Other" }] : []
    } as Order);
    onClose();
    setSubmitting(false);
  };

  const resetState = () => {
    setType(null);
    setName(null);
  };

  useEffect(() => {
    resetState();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{"Additonal Name"}</DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          sx={{ marginBottom: 2, marginTop: 2 }}
          value={type}
          options={OtherNameSearchTypes}
          onChange={(e, value) => {
            setType(value);
          }}
          renderInput={(params) => <TextField {...params} label="Type" placeholder="Start typing..." />}
        />
        <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={addContact} disabled={submitting}>
          {submitting ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

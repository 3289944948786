import axios from "axios";
import { ParsedName } from "../orderEntry/PropertiesNew/types";
import { IEstatedResponse, PropertyInfo, SmartyAddressResponse } from "../orderEntry/types/Property";

export const toTitleCase = (str: string) => {
  return str
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace("Llc", "LLC");
};

export const isNJorNY = (state: string | undefined) => {
  if (!state) {
    return false;
  }
  const stateLowerCase = state.toLowerCase();
  return ["nj", "ny", "new jersey", "new york"].includes(stateLowerCase);
};

export const isinFiveBoroughs = (state: string, county: string) => {
  return ["ny", "new york"].includes(state) && ["new york", "kings", "bronx", "queens", "richmond"].includes(county);
};

export const getBlock = (state: string, county: string, apn: string) => {
  if (!isinFiveBoroughs(state?.toLowerCase(), county?.toLowerCase()) && !isNJorNY(state)) {
    return null;
  }
  return parseBlock(apn);
};

export const getLot = (state: string, county: string, apn: string) => {
  if (!isinFiveBoroughs(state?.toLowerCase(), county?.toLowerCase()) && !isNJorNY(state)) {
    return null;
  }
  return parseLot(apn);
};

export const getSection = (state: string, county: string, apn: string) => {
  if (!isinFiveBoroughs(state?.toLowerCase(), county?.toLowerCase()) && !isNJorNY(state)) {
    return null;
  }
  return parseSection(apn);
};

export const parseBlock = (parcelId: string) => {
  var parcelIdParts = parcelId
    .replace(/[^0-9.]/g, "-")
    .replace(/-{2,}/g, "-")
    .split("-");
  const startIndex = parcelIdParts.length >= 3 ? 1 : 0;
  const endIndex = parcelIdParts.length - 1 - (parcelIdParts.length === 5 ? 2 : 1);
  const blockParts = parcelIdParts.filter((p, index) => index >= startIndex && index <= endIndex);
  if (blockParts.length > 0) {
    return parseFloat(blockParts.join(".")).toString();
  }

  return "";
};

const parseLot = (parcelId: string) => {
  var parcelIdParts = parcelId
    .replace(/[^0-9.]/g, "-")
    .replace(/-{2,}/g, "-")
    .split("-");
  const fromLength = parcelIdParts.length === 5 ? 2 : 1;
  const lots = parcelIdParts.filter((p, index) => index > parcelIdParts.length - 1 - fromLength);
  if (lots.length > 0) {
    return parseFloat(lots.join(".")).toString();
  }
  return null;
};

const parseSection = (parcelId: string) => {
  var parcelIdParts = parcelId
    .replace(/[^0-9.]/g, "-")
    .replace(/-{2,}/g, "-")
    .split("-");
  if (parcelIdParts.length > 2) {
    return parseFloat(parcelIdParts[0]).toString();
  }
  return null;
};

export const getProperyInfoOptionFromSmartyResponse = (data: SmartyAddressResponse) => {
  const {
    state,
    city,
    entries,
    zipcode: zipCode,
    formattedStreet_line: formattedAddress,
    secondary: aptNo,
    street_line: address
  } = data;

  const isMultipleUnits = Number(entries) > 1;
  const aptText = aptNo ? ` ${aptNo}` : "";
  const optionLabel = isMultipleUnits
    ? `${formattedAddress} ${aptText} (${entries} more entries) ${city}, ${state} ${zipCode}`
    : `${formattedAddress}${aptText}, ${city}, ${state} ${zipCode}`;

  return {
    optionLabel,
    isFreeFormEntry: false,
    entries,
    aptNo,
    address,
    formattedAddress,
    city,
    state,
    zipCode,
    parcelIds: [""],
    lots: [""]
  } as PropertyInfo;
};

export const searchEstated = async (propertyInfo: PropertyInfo) => {
  const { formattedAddress, aptNo, city, state, zipCode } = propertyInfo;
  if (!formattedAddress || !city || !state) return null;
  try {
    const { data } = await axios.get<IEstatedResponse>(
      `/api/property/searchEstated?street=${formattedAddress} ${
        aptNo?.replace(/\W/g, "") || ""
      }&city=${city}&state=${state}&zip_code=${zipCode}`
    );
    return data.data;
  } catch (e) {
    return null;
  }
};

export const getDefaultPropertyInfoObj = () =>
  ({
    lots: [""],
    parcelIds: [""],
    isFreeFormEntry: true,
    optionLabel: "",
    id: null,
    entries: null,
    owner: null,
    formattedAddress: "",
    address: "",
    addressHigh: "",
    addressLow: "",
    city: "",
    state: "",
    zipCode: "",
    block: "",
    section: "",
    borough: "",
    aptNo: "",
    condoNumber: "",
    currentTaxClass: "",
    buildingClass: "",
    county: "",
    propertyUse: "",
    propertyType: "",
    legalDescription: {
      description: "",
      lookupCode: ""
    }
  } as PropertyInfo);

export const getParsedName: (name: string) => Promise<ParsedName | null> = async (name: string) => {
  if (!name) return null;
  const { data } = await axios.get(`/api/name/getParsedName?name=${name.replace(/[^a-zA-Z ]/g, "")}`);
  if (!data) {
    const nameParts = name.replace(",", "").split(" ");
    return { gender: "Male", firstName: nameParts[0], lastName: nameParts[1] || "" };
  }
  const gender = data.data[0].name.firstname?.gender === "f" ? "Female" : "Male";
  const firstName = data.data[0].name.firstname?.name;
  const lastName = data.data[0].name.lastname?.name;
  return { gender, firstName, lastName };
};

export const searchCountyByCityAndZip = async (city: string, zipCode: string) => {
  try {
    const { data } = await axios.get(`/api/property/searchCountyByCityAndZip?city=${city}&zipCode=${zipCode}`);
    return data;
  } catch (e) {
    return null;
  }
};

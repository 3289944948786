import { Alert, Box, Button, Stack, Typography } from "@mui/material";

export default function DuplicateAlert({ handleDismiss }: { handleDismiss: () => void }) {
  return (
    <Alert severity="warning" sx={{ position: "relative" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="subtitle2">Duplicates Found!</Typography>
          <Typography variant="body2">
            Similar entries already exist. Please confirm that a duplicate entry is not being created.
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          sx={{ position: "absolute", right: 16 }}
          onClick={handleDismiss}
        >
          Dismiss
        </Button>
      </Stack>
    </Alert>
  );
}

import NoteBadge from "../checks/checkNotes/NoteBadge";
import { IAssignable } from "../checks/types";
import getRegularNotesCount from "../utils/getRegularNotesCount";

const NoteCell = ({
  row,
  onNoteClick,
  color = "secondary"
}: {
  row: IAssignable;
  onNoteClick: Function;
  color: "error" | "secondary" | "warning";
}) => {
  const regularNotesCount = getRegularNotesCount(row.notes.map((n) => n.text));
  return <NoteBadge onClick={async () => onNoteClick(row)} color={color} length={regularNotesCount} />;
};

export default NoteCell;

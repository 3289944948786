import { Check } from "./types";

export const getOrderNumberDisplay = (check: Check) => {
  if (!check?.ledgerName) return "";

  const startsWithNumber = /\d/.test(check.ledgerName![0]);
  if (!startsWithNumber || !check.titleCompanyPrefix) {
    return check.ledgerName;
  }

  return `${check.titleCompanyPrefix.toUpperCase()}-${check.ledgerName}`;
};

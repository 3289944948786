import { useContext } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { ConfirmationContext } from "../contexts/ConfirmationContext";

export default function EmailBodyForm() {
  const { emailWordingList, body, setBody } = useContext(ConfirmationContext);
  return (
    <Box padding={3} width={750}>
      <Autocomplete
        sx={{ marginBottom: 2 }}
        fullWidth
        onChange={(e, value) => setBody(value?.wording)}
        options={emailWordingList}
        getOptionLabel={(option) => option.type || ""}
        renderInput={(params) => <TextField {...params} label="Email Wording Template" placeholder="Start typing..." />}
      />
      <TextField
        fullWidth
        value={body || ""}
        onChange={(e) => setBody(e.target.value)}
        label="Email Body"
        multiline
        minRows={4}
      />
    </Box>
  );
}

import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import ManageTasks from "./ManageTasks";
import { useSettingsContext } from "../../contexts/SettingsContext";

// ----------------------------------------------------------------------

export default function ManageTasksPage() {
  const settings = useSettingsContext();
  return (
    <>
      <Helmet>
        <title>TitleQ Manage Tasks</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <ManageTasks />
      </Container>
    </>
  );
}

import { Link, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { fCurrency } from "../../utils/formatNumber";
import { NonZeroLedgerBalanceInfo } from "./types";
import { getOrderNumberDisplay, orderNumberFilterFn } from "../ReportsUtils";
import { OrderNumberCell } from "../OrderNumberCell";

export const columns: MRT_ColumnDef<NonZeroLedgerBalanceInfo>[] = [
  {
    id: "notes",
    header: "",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,
    size: 50,
    enableHiding: false
  },
  {
    header: "Order Number",
    accessorKey: "orderNumber",
    id: "orderNumber",
    accessorFn: getOrderNumberDisplay,
    filterFn: orderNumberFilterFn,
    Cell: OrderNumberCell,
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Department",
    accessorKey: "department",
    id: "department",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Ledger Balance",
    accessorKey: "endingBalance",
    id: "endingBalance",
    Cell: ({ renderedCellValue = "" }) => (
      <Typography variant="subtitle2">{fCurrency(String(renderedCellValue || ""))}</Typography>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Closer",
    id: "closerUser",
    accessorFn: (row) => row.closerUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.closerUser?.email) {
        return <Link href={`mailto:${row.original.closerUser.email}`}>{renderedCellValue || ""}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Funder",
    id: "funderUser",
    accessorFn: (row) => row.funderUser?.fullName || "",
    filterVariant: "select",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.funderUser?.email) {
        <Link href={`mailto:${row.original.funderUser.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Post Closer",
    id: "postCloser",
    accessorFn: (row) => row.postCloserUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.postCloserUser?.email) {
        return <Link href={`mailto:${row.original.postCloserUser?.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Assigned To",
    id: "assignedTo",
    accessorFn: (row) => row.assignedToUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.assignedToUser?.email) {
        return <Link href={`mailto:${row.original.assignedToUser.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

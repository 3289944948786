import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Card, Button, Stack, InputBase, Box, Typography } from "@mui/material";
import { Note } from "../../types";

// ----------------------------------------------------------------------

interface IProps {
  saveNote: (note: string) => Promise<any>;
  updateNotes: (note: Note) => void;
  showClientInstructionsHeader: boolean;
}

export default function NoteInput(props: IProps) {
  const { saveNote } = props;
  const [note, setNote] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSaveNote = async () => {
    setSubmitting(true);
    await saveNote(note);
    setSubmitting(false);
    setNote("");
  };

  return (
    <Card sx={{ p: 3 }}>
      {props.showClientInstructionsHeader && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Client Instructions</Typography>
        </Box>
      )}
      <InputBase
        value={note}
        multiline
        fullWidth
        rows={4}
        placeholder="Add a note..."
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`
        }}
        onChange={(e) => setNote(e.target.value)}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: "text.secondary" }}></Stack>
        <Button variant="contained" onClick={handleSaveNote} disabled={submitting}>
          {submitting ? "Submitting" : "Save"}
        </Button>
      </Stack>
    </Card>
  );
}

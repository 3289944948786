import { Alert, Stack, Typography } from "@mui/material";
import { PatriotResult } from "../../components/BlacklistNameSearch/types";

export default function PatriotResultAlert({ patriotSearchResult }: { patriotSearchResult: PatriotResult[] }) {
  if (patriotSearchResult.length == 0) return <></>;
  return (
    <Alert severity="error">
      <Stack direction="column" spacing={2}>
        <Typography variant="body1" sx={{ display: "inline" }}>
          Potential patriot search matches found!!
        </Typography>
        <ol style={{ paddingLeft: "20px" }}>
          {patriotSearchResult.map((r, index) => (
            <li key={index}>
              <Typography variant="body2">
                <b>{r.searchedName}</b> may match {r.results.join(",")}
              </Typography>
            </li>
          ))}
        </ol>
        <Typography variant="body2" sx={{ fontWeight: "bold", color: "red" }}>
          DO NOT CONTINUE transaction until confirmed to not be a match.
        </Typography>
      </Stack>
    </Alert>
  );
}

import { Autocomplete, TextField } from "@mui/material";

interface StatusCountsFilterProps {
  column: any;
  placeholder: string;
}

const StatusCountsFilter = ({ column, placeholder }: StatusCountsFilterProps) => {
  const filterValue = column.getFilterValue() || [];
  const setFilterValue = column.setFilterValue;

  const handleFilterChange = (_: any, newValue: any) => {
    setFilterValue(newValue);
  };

  return (
    <Autocomplete
      sx={{ width: 250 }}
      fullWidth
      multiple
      options={column.columnDef.filterSelectOptions.map((option: any) => option.text)}
      value={filterValue}
      onChange={handleFilterChange}
      getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} variant="outlined" />}
      limitTags={-1}
      size="small"
    />
  );
};

export default StatusCountsFilter;

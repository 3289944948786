import { Card } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { NotesContext } from "../contexts/NotesContext";
import ProgressLoader from "../components/ProgressLoader";
// ----------------------------------------------------------------------

export default function NotesPreview() {
  const { notesPdf } = useContext(NotesContext);
  return (
    <Card>
      <Box height={1000}>
        {notesPdf && (
          <iframe
            allowFullScreen={true}
            title="notes"
            src={`data:application/pdf;base64,${notesPdf}#toolbar=0`}
            height="100%"
            width="100%"
            allowTransparency={true}
            style={{
              border: "none",
              backgroundColor: "#fff",
              padding: 0,
              position: "relative",
              left: -5,
              top: -5
            }}
            frameBorder="0"
            scrolling="no"
          />
        )}
        {!notesPdf && <ProgressLoader size={80} />}
      </Box>
    </Card>
  );
}

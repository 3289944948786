import { useState, useEffect } from "react";
import axios from "axios";
import { StatusCategory } from "../types";

const useStatusCategories = () => {
  const [statusCategories, setStatusCategories] = useState<StatusCategory[]>([]);

  const getStatusCategories = async () => {
    const { data } = await axios.get(`/api/taskmanagement/tasks/GetStatusCategories`);
    setStatusCategories(data);
  };

  useEffect(() => {
    getStatusCategories();
  }, []);

  return { statusCategories };
};

export default useStatusCategories;

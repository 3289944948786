import {
  Dialog,
  DialogTitle,
  Stack,
  Box,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Typography
} from "@mui/material";
import { CloseIcon } from "../../minimals/theme/overrides/CustomIcons";
import { fCurrency } from "../utils/formatNumber";
import { useState } from "react";
import { MRT_Row } from "material-react-table";
import { DepositRefundApprovalStatus, DepositRefundRequest } from "./types";
import axios from "axios";
import { useSnackbar } from "notistack";

interface IProps {
  row: MRT_Row<DepositRefundRequest>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: () => void;
  isClosing?: boolean;
}

const NoteModal = ({ row, isOpen, setIsOpen, refreshData, isClosing = false }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState(row.original.note);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSaveNote = async (rowData: DepositRefundRequest, note: string) => {
    setIsSubmitting(true);
    const { data } = await axios.post("/api/depositrefunds/updateDepositRequest", {
      ...rowData,
      note,
      approvalStatus: isClosing ? DepositRefundApprovalStatus.CLOSED : rowData.approvalStatus
    });
    await refreshData();
    setIsSubmitting(false);
    if (isClosing) {
      enqueueSnackbar(`Order ${row.original.orderNumber} was marked as reviewed`);
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems="center" marginBottom={3}>
          <Box>
            Order Number {row.original.orderNumber}
            {row.original.amount ? ` - ${fCurrency(row.original.amount)}` : ""}
          </Box>
        </Stack>
        {isClosing && (
          <Stack direction="row" spacing={2} alignItems="center" marginBottom={3}>
            <Box>
              <Typography variant="body2" sx={{ color: "grey.600", opacity: 0.8 }}>
                Please add an optional note and confirm close
              </Typography>
            </Box>
          </Stack>
        )}
      </DialogTitle>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <TextField
            value={row.original.requestNote}
            fullWidth
            multiline
            disabled
            label="Requester's note"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            multiline
            rows={3}
            label="Note"
            sx={{ marginBottom: 2 }}
          />
          <Stack direction="row" justifyContent="right">
            <Button
              variant="contained"
              size="small"
              onClick={() => handleSaveNote(row.original, note)}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : isClosing ? "Confirm Close" : "Save"}
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NoteModal;

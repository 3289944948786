import { useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";

const useAxiosCancel = () => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const latestRequest = useRef(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (url: string, params?: any) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation canceled by the user.");
    }
    cancelTokenSource.current = axios.CancelToken.source();
    const requestId = ++latestRequest.current;
    setIsLoading(true);

    try {
      const response = await axios.get(url, { params, cancelToken: cancelTokenSource.current.token });
      if (requestId === latestRequest.current) {
        setIsLoading(false);
      }
      return response.data;
    } catch (error) {
      if (requestId === latestRequest.current) {
        setIsLoading(false);
      }
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("An error occurred:", error);
      }
      throw error;
    }
  };

  return { fetchData, isLoading };
};

export default useAxiosCancel;

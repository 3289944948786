import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "../../../minimals/components/iconify";
import AddEditTaskModal from "./AddEditTaskModal";
import { County, CountyTask, GeneralTask, ModalType, RecordableType, State, StateTask, TaskType } from "../types";
import AddCountyDialog from "./AddCountyDialog";
import axios from "axios";

export default function ManageTasks() {
  const [modalTask, setModalTask] = useState<GeneralTask | StateTask | CountyTask | null>(null);
  const [recordableTypes, setRecordableTypes] = useState<RecordableType[]>([]);
  const [selectedRecordableType, setSelectedRecordableType] = useState<RecordableType>({
    id: -1,
    description: "Loading...."
  });
  const [generalTasks, setGeneralTasks] = useState<GeneralTask[]>([]);
  const [stateTasks, setStateTasks] = useState<StateTask[]>([]);
  const [countyTasks, setCountyTasks] = useState<CountyTask[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddCountyModal, setShowAddCountyModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(TaskType.General);
  const [modalType, setModalType] = useState(ModalType.Add);
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State | null>(null);

  const [counties, setCounties] = useState<County[]>([]);
  const [selectedCounty, setSelectedCounty] = useState<County | null>(null);

  const currentlyDisplayedGeneralTasks = generalTasks.filter((t) => t.recordableType.id === selectedRecordableType?.id);
  const currentlyDisplayedStateTasks = stateTasks.filter((s) => s.recordableType.id === selectedRecordableType?.id);
  const currentlyDisplayedCountyTasks = countyTasks.filter((s) => s.recordableType.id === selectedRecordableType?.id);

  let currentDisplayTasks: GeneralTask[] = [];
  switch (currentTab) {
    case TaskType.General:
      currentDisplayTasks = currentlyDisplayedGeneralTasks;
      break;
    case TaskType.State:
      currentDisplayTasks = currentlyDisplayedStateTasks;
      break;
    case TaskType.County:
      currentDisplayTasks = currentlyDisplayedCountyTasks;
      break;
  }

  const closeModal = async () => {
    setShowModal(false);
    if (currentTab === TaskType.General) {
      await fetchGeneralTasks(recordableTypes);
    } else if (currentTab === TaskType.State) {
      await fetchStateTasks(selectedState!);
    } else {
      await fetchCountyTasks(selectedCounty!);
    }
  };

  const onEditClick = (task: GeneralTask) => {
    setShowModal(true);
    setModalType(ModalType.Edit);
    setModalTask(task);
  };

  const onAddClick = () => {
    setModalType(ModalType.Add);
    setModalTask({
      id: 0,
      recordableType: selectedRecordableType,
      description: "",
      stateId: selectedState?.id,
      countyId: selectedCounty?.id
    });
    setShowModal(true);
  };

  const onDeleteClick = async (taskId: number) => {
    if (!window.confirm("Are you sure you want to delete the current task?")) {
      return;
    }
    switch (currentTab) {
      case TaskType.General:
        await axios.delete(`/api/recordablestracking/managetasks/DeleteGeneralTask/${taskId}`);
        await fetchGeneralTasks(recordableTypes);
        break;
      case TaskType.State:
        await axios.delete(`/api/recordablestracking/managetasks/DeleteStateTask/${taskId}`);
        await fetchStateTasks(selectedState!);
        break;
      case TaskType.County:
        await axios.delete(`/api/recordablestracking/managetasks/DeleteCountyTask/${taskId}`);
        await fetchCountyTasks(selectedCounty!);
        break;
    }
  };

  const fetchGeneralTasks = async (recordableTypes: RecordableType[]) => {
    const { data: generalTasks } = await axios.get("/api/recordablestracking/managetasks/getgeneraltasks");
    generalTasks.forEach((g: any) => (g.recordableType = recordableTypes.find((r: any) => r.id === g.recordableType)));
    setGeneralTasks(generalTasks);
  };

  const fetchStateTasks = async (state: State) => {
    const { data: stateTasks } = await axios.get(`/api/recordablestracking/managetasks/getstatetasks/${state.id}`);
    stateTasks.forEach((s: any) => (s.recordableType = recordableTypes.find((r: any) => r.id === s.recordableType)));
    setStateTasks(stateTasks);
  };

  const fetchCountyTasks = async (county: County) => {
    if (!county) {
      setCountyTasks([]);
      return;
    }
    const { data: countyTasks } = await axios.get(`/api/recordablestracking/managetasks/getcountytasks/${county.id}`);
    countyTasks.forEach((s: any) => (s.recordableType = recordableTypes.find((r: any) => r.id === s.recordableType)));
    setCountyTasks(countyTasks);
  };

  const onStateChanged = async (state: State | null) => {
    if (!state) {
      setStateTasks([]);
      return;
    }

    setSelectedState(state);
    await fetchStateTasks(state);
    await fetchCounties(state.id);
  };

  const fetchCounties = async (stateId: number) => {
    const { data } = await axios.get<County[]>(`/api/recordablestracking/managetasks/getcounties/${stateId}`);
    setCounties(data);
    setSelectedCounty(data[0]);
    await fetchCountyTasks(data[0]);
  };

  const onCountyChanged = async (county: County | null) => {
    if (!county) {
      return;
    }

    setSelectedCounty(county);
    await fetchCountyTasks(county);
  };

  const onTabChanged = async (taskType: TaskType) => {
    setCurrentTab(taskType);
    if (taskType === TaskType.General || !selectedState) {
      return;
    }
    if (taskType === TaskType.State) {
      await fetchStateTasks(selectedState);
    }

    await fetchCounties(selectedState.id);
  };

  const onAddCountyModalClose = async () => {
    setShowAddCountyModal(false);
    await fetchCounties(selectedState!.id);
  };

  useEffect(() => {
    (async () => {
      const { data: recordableTypes } = await axios.get("/api/recordablestracking/recordables/getTypes");
      setSelectedRecordableType(recordableTypes[0]);
      setRecordableTypes(recordableTypes);
      await fetchGeneralTasks(recordableTypes);
      const { data: states } = await axios.get("/api/recordablestracking/managetasks/getstates");
      setStates(states);
      setSelectedState(states.find((s: any) => s.abbreviation === "NJ"));
    })();
  }, []);

  return (
    <>
      <Stack direction="column" spacing={2}>
        {/* ************** Tabs ************** */}
        <Tabs
          value={currentTab}
          onChange={(_, value) => onTabChanged(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value={TaskType.General} label="General Tasks" />
          <Tab value={TaskType.State} label="State Tasks" />
          <Tab value={TaskType.County} label="County Tasks" />
        </Tabs>
        <Divider sx={{ marginBottom: 4 }} />
        {/* ************** New Task Form ************** */}
        <Stack direction="row" spacing={2}>
          <Autocomplete
            size="small"
            sx={{ width: 300 }}
            options={recordableTypes}
            renderInput={(params) => <TextField {...params} label="Document Type" />}
            getOptionLabel={(option) => option.description}
            onChange={(e, val) => setSelectedRecordableType(val!)}
            value={selectedRecordableType}
          />
          {currentTab !== TaskType.General && (
            <>
              <Autocomplete
                size="small"
                sx={{ width: 200 }}
                options={states}
                renderInput={(params) => <TextField {...params} label="State" />}
                getOptionLabel={(option) => option.abbreviation}
                onChange={(e, val) => onStateChanged(val)}
                value={selectedState}
              />

              {currentTab === TaskType.County && (
                <>
                  <Autocomplete
                    size="small"
                    sx={{ width: 300 }}
                    options={counties}
                    renderInput={(params) => <TextField {...params} label="County" />}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, val) => onCountyChanged(val)}
                    value={selectedCounty}
                  />
                </>
              )}
            </>
          )}
          <Button variant="outlined" sx={{ width: 150 }} onClick={onAddClick}>
            Add Task
          </Button>
          {currentTab === TaskType.County && (
            <Button variant="outlined" sx={{ width: 150 }} onClick={() => setShowAddCountyModal(true)}>
              Add County
            </Button>
          )}
        </Stack>
        {/* ************** Task List Table ************** */}
        <Stack direction="row">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Task</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentDisplayTasks.map((r) => (
                  <TableRow>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        <IconButton color="default" onClick={() => onEditClick(r)}>
                          <Iconify icon="eva:edit-outline" />
                        </IconButton>
                        <IconButton color="error">
                          <Iconify icon="eva:trash-2-outline" onClick={() => onDeleteClick(r.id)} />
                        </IconButton>
                        <Typography variant="subtitle2" marginLeft={2}>
                          {r.description}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      {/* ************** Edit Task Modal ************** */}
      {showModal && (
        <AddEditTaskModal
          task={modalTask!}
          onClose={closeModal}
          modalType={modalType}
          taskType={currentTab}
          recordableTypes={recordableTypes}
        />
      )}

      {showAddCountyModal && (
        <AddCountyDialog states={states} defaultState={selectedState!} onClose={onAddCountyModalClose} />
      )}
    </>
  );
}

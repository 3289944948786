import { Box, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { CustomAvatar } from "../../../components/custom-avatar";
import { getLogo } from "../../../utils/getLogo";
import { EditCompanyData, EditCompanyDataCompany, MemberData } from "../../types";
import { CompanyContext } from "../CompanyContext";

interface IProps {
  searchKeywords?: string;
}

export default function MembersTable(props: IProps) {
  const { searchKeywords } = props;
  const { company } = useContext(CompanyContext);
  const searchKeywordParts = searchKeywords?.trim().split(" ");
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [members, setMembers] = useState<MemberData[]>([]);

  const getAndSetMembers = async () => {
    if (!id) return;
    const { data } = await axios.get(`/api/clientphonebook/companies/getContacts?id=${id}&onlyShowActive=true`);
    setMembers(data);
  };

  useEffect(() => {
    getAndSetMembers();
  }, []);

  const updatePrimaryContact = async (primaryContactId: number | null) => {
    await axios.put(`/api/clientphonebook/companies/updatePrimaryContact`, {
      companyId: company?.id,
      primaryContactId
    });
    getAndSetMembers();
  };

  const filteredData = members.filter((m) =>
    searchKeywordParts && searchKeywordParts.length > 0 ? searchKeywordParts?.some((p) => m.name.includes(p)) : m
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Email</TableCell>
          <TableCell align="right">Primary Contact</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredData.map((m) => (
          <TableRow key={m.contactId}>
            <TableCell>
              <Typography variant="subtitle2">
                <Stack direction="row" spacing={1} alignItems="center">
                  <CustomAvatar alt={`${m.name}`} name={`${m.name}`} src={getLogo(m.role && m.email?.split("@")[1])} />
                  <Box>
                    <Typography variant="subtitle2">
                      <Link
                        to={`../phonebook/contact/profile?id=${m.contactId}`}
                        style={{
                          textDecoration: "none",
                          color: "#212B36"
                        }}
                      >
                        {m.name}
                      </Link>
                    </Typography>
                  </Box>
                </Stack>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{m?.role}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {m.location.address1} {m.location.address2}
              </Typography>
              <Typography variant="body2">
                {m.location.city || ""}
                {m.location.city && m.location.state && ","} {m.location.state || ""} {m.location.zip || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{m?.phones?.find((p) => p.type === "Business")?.number}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{m?.email}</Typography>
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="right">
                <Switch
                  color="success"
                  checked={m.isPrimaryContact}
                  onChange={(e, v) => updatePrimaryContact(v ? m.contactId : null)}
                  disabled={!company?.isActive}
                />
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

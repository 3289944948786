import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TableContainer,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Stack,
  Chip,
  Link
} from "@mui/material";
import { EventType, CorpDocDocument, Status, TaskItem } from "./types";
import { fMonthDayYearDotNotation, fTime } from "../utils/formatTime";
import getStatusColor from "./getStatusColor";

interface IProps {
  task: TaskItem;
  corpDocType: string;
  corpDocDescription: string | undefined;
  statuses: Status[];
  eventTypes: EventType[];
  onClose: () => void;
  documents: CorpDocDocument[];
}

export default function TaskHistory({
  task,
  corpDocType,
  corpDocDescription,
  statuses,
  eventTypes,
  onClose,
  documents
}: IProps) {
  const generateDocumentHref = (documentIdToFind: number) => {
    return `https://internal.mcres.com/opendoc?id=${
      documents.find((d) => d.id === documentIdToFind)?.momentumDocumentId
    }`;
  };

  const generateDocumentLabel = (documentIdToFind: number) => {
    let reversedDocuments = documents.slice();
    const index = reversedDocuments.reverse().findIndex((d) => d.id === documentIdToFind);
    return `Version ${index + 1}`;
  };

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1">{task.description}</Typography>
            <Typography variant="subtitle1" color="#637381">
              Task History
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="subtitle1">
              {corpDocType} {corpDocDescription && `- ${corpDocDescription}`}
            </Typography>
            <Typography variant="subtitle1" color="#637381">
              Milestone
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Document</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {task.statusUpdateHistory.map((h) => (
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell>
                    <Typography variant="subtitle2">{fMonthDayYearDotNotation(h.dateTime)}</Typography>
                    <Typography variant="subtitle2" color="#637381" fontWeight={400}>
                      {fTime(h.dateTime)}
                    </Typography>
                  </TableCell>
                  <TableCell>{eventTypes.find((e) => e.id === h.eventType)?.description}</TableCell>
                  <TableCell sx={{ wordWrap: "normal" }}>
                    <Chip
                      sx={{ minWidth: 139, borderRadius: "8px" }}
                      variant="soft"
                      color={getStatusColor(h.status, true) as "error" | "success" | "warning"}
                      label={statuses.find((s) => s.id === h.status)?.description}
                    />
                  </TableCell>
                  <TableCell>
                    <Link href={generateDocumentHref(h.documentId)} underline="none" target="_blank">
                      <Button>{generateDocumentLabel(h.documentId)}</Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">{h.username?.toLowerCase()}</Typography>
                  </TableCell>
                  <TableCell width={300}>{h.note}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {task.statusUpdateHistory.length === 0 && <Typography padding={2}>No history available</Typography>}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

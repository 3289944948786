import { Autocomplete, TextField } from "@mui/material";
import { Dispatch, useContext } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import { Contact } from "../types/Contact";
import { ContactType } from "../types/ContactType";
export default function ContactTypeDropdown({
  setContactType,
  contactType,
  setContact,
  contact,
  errorMode,
  resetSearchedContact
}: {
  setContactType: Dispatch<React.SetStateAction<ContactType | null>>;
  contactType: ContactType | null;
  setContact: Dispatch<React.SetStateAction<Contact>>;
  contact: Contact | null;
  errorMode?: boolean;
  resetSearchedContact: () => void;
}) {
  const { contactTypeList } = useContext(OrderContext);

  const getContactTypeLabel = (option: any) => {
    if (option.isOther && !option.name) {
      return "Other";
    } else {
      return option.name || option;
    }
  };

  return (
    <Autocomplete
      fullWidth
      value={(contactType?.name || null || undefined || "") as any}
      options={contactTypeList}
      getOptionLabel={(option) => getContactTypeLabel(option)}
      freeSolo={true}
      size="small"
      onChange={(e, value: any) => {
        setContact({ type: value?.name, contactType: value } as Contact);
        setContactType(value);
        resetSearchedContact();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Contact Type"
          placeholder="Start typing..."
          error={errorMode && !contactType?.name}
        />
      )}
    />
  );
}

// @mui
import { Box, Typography, Stack } from "@mui/material";
import { BlockContentProps } from ".";
import Iconify from "../../../minimals/components/iconify";
// assets
// import { UploadIllustration } from "../../assets";

// ----------------------------------------------------------------------

export default function BlockContent({ label }: BlockContentProps) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{
        width: 1,
        textAlign: { xs: "center", md: "left" }
      }}
    >
      {/* upload illustration */}
      {/* <UploadIllustration sx={{ width: 220 }} /> */}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom fontWeight={600} fontSize={14}>
          {label ? label : "Upload Document"}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Drop document here or &nbsp;
          <Typography variant="body2" component="span" sx={{ color: "primary.main", textDecoration: "underline" }}>
            browse
          </Typography>
          &nbsp;to&nbsp;upload
        </Typography>
      </Box>
      <Iconify icon="eva:file-add-fill" width={48} sx={{ opacity: 0.65 }} />
    </Stack>
  );
}

export default function getStatusColor(statusId: number, variantMode?: boolean) {
  switch (statusId) {
    case 0:
      return variantMode ? "warning" : "warning.main";
    case 1:
      return variantMode ? "error" : "error.main";
    case 2:
      return variantMode ? "success" : "success.main";
  }
}

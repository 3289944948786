import { Link, Tooltip, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { fCurrency } from "../utils/formatNumber";
import { fShortMonthDayYearSlashNotation } from "../utils/formatTime";
import { PriorityChip, getLabelColor } from "./PriorityStatus";
import { getOrderNumberDisplay } from "./getOrderNumberDisplay";
import { Check } from "./types";
import SingleDateRangeFilter from "./SingleDateRangeFilter";

export const columns: MRT_ColumnDef<Check>[] = [
  {
    id: "notes",
    header: "",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,
    size: 50,
    enableHiding: false
  },
  {
    header: "Date",
    accessorKey: "transactionDate",
    sortingFn: "datetime",
    filterFn: (row, id, filterValue) => {
      if (filterValue && filterValue[0] && filterValue[1] && !row.original.transactionDate) {
        return false;
      } else if (filterValue && filterValue[0] && filterValue[1] && row.original.transactionDate) {
        const transactionDate = new Date(row.original.transactionDate).getTime();
        const startDate = new Date(filterValue[0]).getTime();
        const endDate = new Date(filterValue[1]).getTime();

        return transactionDate >= startDate && transactionDate <= endDate;
      } else {
        return true;
      }
    },
    Filter: SingleDateRangeFilter,
    accessorFn: (row) => (row.transactionDate ? new Date(row.transactionDate) : null),
    Cell: ({ row }) =>
      row.original.transactionDate ? (
        <Typography color={getLabelColor(row.original.priority)}>
          {fShortMonthDayYearSlashNotation(row.original.transactionDate)}
        </Typography>
      ) : (
        ""
      )
  },
  {
    header: "Ledger Name",
    accessorFn: (row) => getOrderNumberDisplay(row),
    filterFn: (row, _, filterValue) => {
      const { titleCompanyPrefix, ledgerName } = row.original;
      const ledgerWithPrefix = titleCompanyPrefix ? `${titleCompanyPrefix}-${ledgerName}` : ledgerName;
      return (ledgerWithPrefix || "").toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ renderedCellValue, row }) => (
      <Link href={`https://internal.mcres.com/new-home/${row.original.ledgerName}`} target="_blank">
        {renderedCellValue}
      </Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Department",
    accessorKey: "department",
    id: "department",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Amount",
    accessorKey: "amount",
    id: "amount",
    filterFn: "between",
    Cell: ({ row }) => (row.original.amount ? fCurrency(row.original.amount) : ""),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Payee",
    accessorKey: "payorPayee",
    Cell: ({ renderedCellValue, row }) =>
      row.original.payeeEmail ? (
        <Link href={`mailto:${row.original.payeeEmail}`}>{renderedCellValue}</Link>
      ) : (
        <Tooltip title={renderedCellValue}>
          <Typography noWrap maxWidth={200} variant="body2" sx={{ cursor: "pointer" }}>
            {renderedCellValue}
          </Typography>
        </Tooltip>
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Trust Account",
    accessorKey: "trustAccount",
    Cell: ({ renderedCellValue }) => (
      <Tooltip title={renderedCellValue}>
        <Typography noWrap maxWidth={200} variant="body2" sx={{ cursor: "pointer" }}>
          {renderedCellValue}
        </Typography>
      </Tooltip>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Memo",
    accessorKey: "memo",
    Cell: ({ renderedCellValue }) => (
      <Tooltip title={renderedCellValue}>
        <Typography noWrap maxWidth={200} variant="body2" sx={{ cursor: "pointer" }}>
          {renderedCellValue}
        </Typography>
      </Tooltip>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Extended Memo",
    accessorKey: "extendedMemo",
    Cell: ({ renderedCellValue }) => (
      <Tooltip title={renderedCellValue}>
        <Typography noWrap maxWidth={200} variant="body2" sx={{ cursor: "pointer" }}>
          {renderedCellValue}
        </Typography>
      </Tooltip>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Assigned to",
    accessorKey: "assignedToName",
    filterVariant: "select",
    Cell: ({ renderedCellValue, row }) =>
      row.original.assignedToUser ? (
        <Link href={`mailto:${row.original.assignedToUser.email}`}>{row.original.assignedToUser.fullName}</Link>
      ) : (
        renderedCellValue
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Printed By",
    accessorKey: "printedBy",
    filterVariant: "select",
    Cell: ({ renderedCellValue, row }) =>
      row.original.printedByUser ? (
        <Link href={`mailto:${row.original.printedByUser.email}`}>{row.original.printedByUser.fullName}</Link>
      ) : (
        renderedCellValue
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Funder",
    accessorKey: "funder",
    filterVariant: "select",
    Cell: ({ renderedCellValue, row }) =>
      row.original.funderUser ? (
        <Link href={`mailto:${row.original.funderUser.email}`}>{row.original.funderUser.fullName}</Link>
      ) : (
        renderedCellValue
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Post Closer",
    accessorKey: "postCloser",
    Cell: ({ renderedCellValue, row }) =>
      row.original.postCloserUser ? (
        <Link href={`mailto:${row.original.postCloserUser.email}`}>{row.original.postCloserUser.fullName}</Link>
      ) : (
        renderedCellValue
      ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Transaction Type",
    accessorKey: "transactionType",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Check #",
    accessorKey: "referenceNumber",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Voided On",
    accessorKey: "voidedOn",
    accessorFn: (row) => (row.voidedOn ? new Date(row.voidedOn).toLocaleDateString() : ""),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Priority",
    filterVariant: "select",
    accessorKey: "priority",
    Cell: ({ renderedCellValue, row }) => <PriorityChip priority={row.original.priority} />,
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

import { Badge, IconButton } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";

interface IProps {
  onClick: () => Promise<void>;
  length: number;
  color: "error" | "warning" | "secondary";
}

export default function NoteBadge({ onClick, length, color }: IProps) {
  return (
    <IconButton onClick={onClick}>
      <Badge badgeContent={length} color={color}>
        <Iconify icon="ic:baseline-note-add" />
      </Badge>
    </IconButton>
  );
}

import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";

const today = dayjs();

export const columns: MRT_ColumnDef<any>[] = [
  {
    header: "Order #",
    accessorKey: "orderNumber",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Order Placed By",
    accessorKey: "orderPlacedBy",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Title Commitment",
    accessorKey: "titleCommitment",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Notes",
    accessorKey: "cT6Notes",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

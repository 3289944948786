import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";

const today = dayjs();

export const columns: MRT_ColumnDef<any>[] = [
  {
    header: "Order #",
    accessorKey: "orderNumber",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Reach out to",
    accessorKey: "abstractor",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Days Elapsed",
    accessorKey: "daysElapsed",
    accessorFn: (row) => (row.requestedDate ? today.diff(row.requestedDate, "day") : ""),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Due Date",
    accessorKey: "requestedDate",
    accessorFn: (row) => (row.requestedDate ? new Date(row.requestedDate).toLocaleDateString() : ""),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Assigned To",
    accessorKey: "requestedBy",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Notes",
    accessorKey: "notes",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

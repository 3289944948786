import { Alert, Button, Grid, IconButton, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Iconify from "../../../../minimals/components/iconify";
import { OrderContext } from "../../../contexts/OrderDataContext";
import { MatchedOrderRequest } from "../../matchedOrders/MatchedOrder";
import { Property, PropertyInfo } from "../../types/Property";

interface IProps {
  id: string;
  parcelIdsIndex: number;
  property: Property;
  propertyIndex: number;
  setProperty: (newProperty: PropertyInfo, index: number) => void;
  isDisabled: boolean;
}

export default function ParcelId(props: IProps) {
  const { getAndSetMatchedOrders } = useContext(OrderContext);
  const { id, parcelIdsIndex, property, propertyIndex, setProperty, isDisabled } = props;
  const { propertyInfo } = property;
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);

  useEffect(() => {
    if (id) setErrorMessageOpen(id.length > 60);
  }, [id]);

  return (
    <Grid container alignItems="center" marginBottom={1} style={{ position: "relative" }}>
      {errorMessageOpen && (
        <Alert
          severity="error"
          style={{
            maxWidth: 712,
            fontWeight: "bold",
            position: "absolute",
            top: 60,
            left: -16,
            zIndex: 500
          }}
        >
          If you're trying to add multiple APNs, please click the +APN to add additional APNs.
        </Alert>
      )}
      <Grid item xs={11}>
        <TextField
          disabled={isDisabled}
          InputProps={{
            endAdornment: propertyInfo.parcelIds.length > 1 && (
              <IconButton
                size="small"
                color="inherit"
                onClick={() => {
                  setProperty(
                    {
                      ...propertyInfo,
                      formattedAddress: propertyInfo.formattedAddress,
                      parcelIds: propertyInfo.parcelIds.filter((value, index) => index != parcelIdsIndex)
                    },
                    propertyIndex
                  );
                }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
              </IconButton>
            )
          }}
          label="Parcel ID"
          error={errorMessageOpen}
          value={id || ""}
          onChange={(e) =>
            setProperty(
              {
                ...propertyInfo,
                formattedAddress: propertyInfo.formattedAddress,
                parcelIds: propertyInfo.parcelIds.map((p, index) => (index === parcelIdsIndex ? e.target.value : p))
              },
              propertyIndex
            )
          }
          fullWidth
          onBlur={() => {
            if (propertyInfo.state) {
              getAndSetMatchedOrders({
                address: propertyInfo.formattedAddress,
                state: propertyInfo.state,
                block: propertyInfo.block,
                lot: propertyInfo.lots[0],
                section: propertyInfo.section,
                parcelId: propertyInfo.parcelIds[0]
              } as MatchedOrderRequest);
            }
          }}
        />
      </Grid>
      {parcelIdsIndex === propertyInfo.parcelIds.length - 1 && (
        <Grid item xs={1}>
          <Button
            sx={{ marginLeft: 1 }}
            size="small"
            startIcon={<Iconify icon={"eva:plus-fill"} />}
            onClick={() => setProperty({ ...propertyInfo, parcelIds: [...propertyInfo.parcelIds, ""] }, propertyIndex)}
          >
            APN
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

import { Skeleton, Stack } from "@mui/material";

export default function () {
  return (
    <Stack spacing={2} marginTop={2}>
      {[...Array(10)]?.map(() => (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="circular" width={80} height={75} />
          <Skeleton width="100%" />
        </Stack>
      ))}
    </Stack>
  );
}

import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button, Card, Container, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Iconify from "../../../minimals/components/iconify";
import Label from "../../../minimals/components/label/Label";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { getLogo } from "../../utils/getLogo";
import { ProfileCover } from "../shared/profile";
import { CompanyContext } from "./CompanyContext";
import { TABS } from "./TABS";
import { closeIFrame } from "../../utils/iframeUtils";

export default function Companies() {
  const { themeStretch, isAppInIFrame } = useSettingsContext();
  const navigate = useNavigate();
  const { section } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const idFromUrl = searchParams.get("id");
  const { company, isFetching } = useContext(CompanyContext);

  const selectedValue = () => {
    return TABS.find((tab) => tab.values.some((value) => value === section))?.values[0];
  };

  if (isFetching) {
    return <></>;
  }

  ////If this gets use more frequently, it can be styled much better
  if (!company) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 144px)"
        }}
      >
        <div style={{ textAlign: "center", fontSize: "50px", fontWeight: "" }}>Company Not Found In Phonebook</div>
      </div>
    );
  }

  return (
    <>
      {isAppInIFrame && (
        <Stack direction="row" justifyContent="flex-end" paddingRight={1.5} marginBottom={1}>
          <IconButton
            size="large"
            onClick={closeIFrame}
            sx={{ position: "absolute", top: 16, right: 16, padding: 0.3 }}
          >
            <Iconify icon="eva:close-outline" width={32} height={32} />
          </IconButton>
        </Stack>
      )}
      <Helmet>
        <title> Company Profile | TitleQ</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        {company && !company.isActive && (
          <Label variant="soft" color="error">
            Inactive
          </Label>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <CustomBreadcrumbs
            heading={`${isAppInIFrame ? "Phonebook - " : ""}Company Profile`}
            links={[{ name: "Companies", href: "../phonebook/contacts/companies" }, { name: company?.name }]}
          />
          <Button
            disabled={!company.isActive}
            variant="contained"
            startIcon={<Iconify icon="eva:edit-fill" />}
            onClick={() => navigate(`../phonebook/edit-company?id=${company?.id || idFromUrl}`)}
          >
            Edit
          </Button>
        </Stack>
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: "relative"
          }}
        >
          <ProfileCover
            name={company?.name || ""}
            logo={getLogo(company?.website)}
            companyType={company?.companyType?.name}
          />

          <Tabs
            value={selectedValue()}
            onChange={(event, newValue) => {
              navigate(`../phonebook/company/${newValue}?id=${company?.id || idFromUrl}`);
            }}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: "absolute",
              bgcolor: "background.paper",
              "& .MuiTabs-flexContainer": {
                pr: { md: 3 },
                justifyContent: {
                  sm: "center",
                  md: "flex-end"
                }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.values[0]} value={tab.values[0]} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {TABS.map((tab) => tab.values.some((v) => v === section) && <Box key={tab.values[0]}> {tab.component}</Box>)}
      </Container>
    </>
  );
}

import { Grid, Stack } from "@mui/material";
import { useContext, useState } from "react";
import LookupCodeCard from "./LookupCodeCard";
import useDebugShortcut from "../../../utils/useDebugShortcut";
import { CompanyContext } from "../../company/CompanyContext";
import DebugMenu from "../../company/profile/DebugMenu";
import EmailPolicyToCard from "./EmailPolicyToCard";

export default function CompanyPreferences() {
  const { company } = useContext(CompanyContext);
  const [isInDebugMode, setIsInDebugMode] = useState(false);

  useDebugShortcut(() => {
    setIsInDebugMode(true);
  });

  if (!company) return <></>;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <LookupCodeCard lookupCode={company.lookupCode || ""} />
          <EmailPolicyToCard companyId={company.id} emailPolicyTo={company?.emailPolicyTo} />
          {isInDebugMode && company && (
            <Stack spacing={3}>
              <DebugMenu companyId={company.id} />
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  Popper,
  Stack,
  Switch,
  TextField,
  TextFieldProps
} from "@mui/material";
import axios from "axios";
import { ReactNode, useContext, useEffect, useState } from "react";
import { SearchesContext } from "../contexts/SearchesContext";
import { Contact } from "../orderEntry/types/Contact";
import SearchItems from "./SearchItems";
import { Preview } from "./types/Preview";
import { produce } from "immer";
import SentSearchEmailAlert from "./SentSearchEmailAlert";
import Names from "./names/Names";
import { OtherNameSearchTypes } from "./names/OtherNameSearchTypes";
import Properties from "./properties/Properties";
import Attachments from "./attachments/SearchAttachments";
import { logEvent } from "../utils/analyticsLogger";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PhonebookContactAutocomplete from "../phonebook/phonebookAutocomplete/PhonebookContactAutocomplete";
import { ContactOption } from "../phonebook/phonebookAutocomplete/ContactOption";

type SearchProp = {
  index: number;
};

export default function SearchCard({ index }: SearchProp) {
  const { orderNumber, setVendor, searchSections, setSearchSections, setPreview, sentEmail, notes, order } =
    useContext(SearchesContext);
  const [intelStateVendors, setIntelStateVendors] = useState<Contact[]>([]);
  const [onlyIntelStateVendors, setOnlyIntelStateVendors] = useState<boolean>(true);
  const searches = searchSections[index].searchRequests;
  const vendor = searchSections[index].vendor;

  const convertToSelectContact = (contact: Contact | null) => {
    if (!contact) return null;
    const selectContact = {
      lookupCode: contact.lookupCode,
      title: contact.title,
      firstName: contact.firstName,
      middleName: contact.middleName,
      lastName: contact.lastName,
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      extension: contact.ext,
      fax: contact.fax,
      cell: contact.cell,
      company: contact.company,
      payeeName: contact.payeeName,
      isMarketingSource: false,
      address1: contact.address1,
      address2: contact.address2,
      city: contact.city,
      state: contact.state,
      zip: contact.zip
    };
    return selectContact;
  };

  const preview = async () => {
    setPreview({ previewMode: true, index } as Preview);
    const payload = {
      orderNumber,
      vendor: convertToSelectContact(vendor),
      searchTypes: searches.map((s) => s.searchType),
      notes,
      dueDate: searchSections[index].dueDate,
      sellers: order?.contacts.filter((c) => c.type === "Seller").map((c) => c.name),
      buyers: order?.contacts.filter((c) => c.type === "Buyer/Borrower").map((c) => c.name),
      nameSearches: order?.contacts
        .filter((c) => OtherNameSearchTypes.some((t) => c.otherType === t))
        .map((c) => c.name),
      includedPropertyIds: order?.properties.map((p) => p.id)
    };
    logEvent("Order Searches", "Preview", JSON.stringify(payload, null, 2));
    const { data } = await axios({
      method: "post",
      url: `/proxy/api/ordersearches/preview`,
      data: payload
    });
    setPreview({
      previewMode: true,
      index,
      base64Document: data.base64Document
    });
  };

  const getAndSetIntelStateVendors = async () => {
    const state = order?.state;
    const { data } = await axios.get(`/api/orderSearches/getSearcherLookupCodesByState?state=${state}`);
    setIntelStateVendors(data);
  };

  useEffect(() => {
    if (!order?.state) return;
    getAndSetIntelStateVendors();
  }, [order?.state]);

  const previewable = searchSections[index].vendor && searchSections[index].dueDate;

  return (
    <Card sx={{ marginTop: 4, marginBottom: 4, padding: 2 }}>
      <Container sx={{ marginTop: 4, marginBottom: 4 }}>
        {!sentEmail && (
          <>
            <Grid container justifyContent="space-between">
              <Grid item xs={8}>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                  <Box width="100%">
                    {onlyIntelStateVendors && (
                      <Autocomplete
                        value={vendor}
                        renderInput={(params) => <TextField {...params} label="Vendor" placeholder="Search Vendor" />}
                        options={intelStateVendors}
                        getOptionLabel={(option) => (option.isIndividual ? option.name : option.company) || ""}
                        onChange={(e, value, reason) => {
                          setVendor(index, value || ({} as Contact));
                        }}
                        renderOption={(props, option) => <ContactOption option={option} props={props} />}
                        PopperComponent={(props) => (
                          <Popper {...props} style={{ width: 650 }} placement="bottom-start" />
                        )}
                      />
                    )}
                    {!onlyIntelStateVendors && (
                      <PhonebookContactAutocomplete
                        contact={vendor}
                        setContact={(value: any) => {
                          setVendor(index, value || ({} as Contact));
                        }}
                        apiOptions={{ isOnlySearchContacts: true }}
                        textboxLabel="Vendor"
                        textboxPlaceholder="Search Vendor"
                      />
                    )}
                  </Box>
                  <FormControlLabel
                    sx={{ width: 705 }}
                    control={
                      <Switch
                        defaultChecked={onlyIntelStateVendors}
                        onChange={() => setOnlyIntelStateVendors(!onlyIntelStateVendors)}
                      />
                    }
                    label="Filter to searchers in Intel for this state"
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <DesktopDatePicker
                  label="Due Date"
                  format="MM/dd/yyyy"
                  value={searchSections[index].dueDate}
                  onChange={(value: Date | null) => {
                    const nextState = produce(searchSections, (draftState) => {
                      draftState[index].dueDate = value;
                    });
                    setSearchSections(nextState);
                  }}
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                />
              </Grid>
            </Grid>
            <Grid container marginTop={4} spacing={2}>
              {order?.properties && order?.properties.length > 0 && (
                <Grid item xs={4}>
                  <Properties />
                </Grid>
              )}
              {order?.contacts && order?.contacts.length > 0 && (
                <Grid item xs={4}>
                  <Names />
                </Grid>
              )}
              <Grid item xs={4}>
                <Attachments />
              </Grid>
            </Grid>
            <SearchItems index={index} />
            <Grid container marginTop={2}>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  sx={{ marginRight: 1 }}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={preview}
                  disabled={!previewable}
                >
                  Preview
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {sentEmail && <SentSearchEmailAlert />}
      </Container>
    </Card>
  );
}

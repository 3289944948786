import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface IProps {
  open: boolean;
  handleNo: () => void;
  handleYes: () => void;
}

export default function NoCompanyAlert({ open, handleNo, handleYes }: IProps) {
  return (
    <Dialog open={open} onClose={handleNo} PaperProps={{ style: { backgroundColor: "#FFF7CD" } }}>
      <DialogTitle color="error">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please try to find company information. If company information is really not available choose Yes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleYes}>
          Yes
        </Button>
        <Button variant="contained" onClick={handleNo} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import DepositRefunds from "./DepositRefunds";
import { useSettingsContext } from "../contexts/SettingsContext";

// ----------------------------------------------------------------------

export default function DepositRefundsPage() {
  return (
    <>
      <Helmet>
        <title>TitleQ Deposit Refunds</title>
      </Helmet>
      <DepositRefunds />
    </>
  );
}

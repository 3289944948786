import {
  Checkbox,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Iconify from "../../../../../minimals/components/iconify";
import { generateLabel } from "./generateLabel";
import InvoicesFilterTabs from "./InvoicesFilterTabs";
import { orders } from "./orders";

export default function InvoicesTable() {
  return (
    <>
      <InvoicesFilterTabs />
      <Divider />
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell width="1%" sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <Checkbox />
            </TableCell>
            <TableCell width="20%">Created</TableCell>
            <TableCell width="24%">Due</TableCell>
            <TableCell width="24%">Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((o) => (
            <TableRow key={o.id}>
              <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Checkbox />
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">{o.created}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{o.due}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{o.amount}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{generateLabel(o.status)}</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton>
                  <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

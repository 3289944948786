import CreateOrder from "./CreateOrder";
import { OrderContextProvider } from "../contexts/OrderDataContext";

// ----------------------------------------------------------------------

export default function PageOne() {
  return (
    <OrderContextProvider>
      <CreateOrder />
    </OrderContextProvider>
  );
}

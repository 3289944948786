import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "../../minimals/components/iconify";
import { CreateColumns } from "./CreateColumns";
import { DepositRefundRequest } from "./types";
import Label from "../../minimals/components/label/Label";

const DepositRefunds = () => {
  // const [depositRefundRequests, setDepositRefundRequests] = useState<DepositRefundRequest[]>([]);
  const [newDepositRefundRequests, setNewDepositRefundRequests] = useState<DepositRefundRequest[]>([]);
  const [closedDepositRefundRequests, setClosedDepositRefundRequests] = useState<DepositRefundRequest[]>([]);
  const [tabValue, setTabValue] = useState(0);

  const filteredRequests = tabValue == 0 ? newDepositRefundRequests : closedDepositRefundRequests;

  const getAllDepositRefunds = async () => {
    const { data } = await axios.get<DepositRefundRequest[]>("/api/depositrefunds/getAllRequests");
    // setDepositRefundRequests(data);
    setNewDepositRefundRequests(data.filter((request) => request.approvalStatusDescription === "New"));
    setClosedDepositRefundRequests(data.filter((request) => request.approvalStatusDescription === "Closed"));
  };

  useEffect(() => {
    getAllDepositRefunds();
  }, []);

  const columns = CreateColumns(getAllDepositRefunds);

  return (
    <>
      <Typography variant="h4" marginLeft={0} marginBottom={-4}>
        Deposit Refund Requests
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          pt: 8
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            aria-label="Deposit Refund Request Tabs"
            sx={{ mb: 2 }}
            centered
          >
            <Tab
              label={
                <Label variant="soft" color="success">
                  New Requests
                </Label>
              }
            />
            <Tab
              label={
                <Label variant="soft" color="warning">
                  Closed Requests
                </Label>
              }
            />
          </Tabs>

          <Box sx={{ position: "absolute", top: 100, right: 30 }}>
            <Button
              component={RouterLink}
              to={"/deposit-refunds/new"}
              variant="contained"
              startIcon={<Iconify icon="mdi:plus" />}
            >
              New Request
            </Button>
          </Box>

          <MaterialReactTable
            columns={columns}
            data={filteredRequests}
            enableGlobalFilter
            enableFacetedValues
            enableMultiSort
            autoResetPageIndex={false}
            initialState={{
              density: "compact"
            }}
            muiTableProps={{ sx: { paddingLeft: 4 } }}
            muiTableBodyProps={{
              sx: {
                boxShadow: "none",
                "& .MuiTableRow-root:hover .MuiTableCell-root": {
                  backgroundColor: "inherit !important"
                }
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default DepositRefunds;

import { Card, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import { SearchesContext } from "../../contexts/SearchesContext";
import Iconify from "../../../minimals/components/iconify";
import Address from "../../shared/Address";

export default function Properties() {
  const { order, setOrder } = useContext(SearchesContext);
  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5" marginBottom={2}>
          Properties
        </Typography>
        {order?.properties.map((property, index) => (
          <>
            <Grid container>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item paddingRight={2} xs={1}>
                    <Iconify icon={"eva:pin-outline"} sx={{ top: 3, position: "relative" }} />
                  </Grid>
                  <Grid item xs={11} paddingRight={4}>
                    <Address
                      address1={property.address1}
                      address2={property.address2}
                      city={property.city}
                      state={property.state}
                      zip={property.zip}
                      county={property.county}
                      propertyUse={property.propertyUse}
                      section={property.section}
                      block={property.block}
                      lots={property.lots}
                      parcelIds={property.parcelIds}
                      legalDescription={property.legalDescription}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() =>
                    setOrder({
                      ...order,
                      properties: order.properties.filter((p) => property.id != p.id)
                    })
                  }
                >
                  <Iconify icon="eva:trash-2-outline" />
                </IconButton>
              </Grid>
            </Grid>
            {index != order.properties.length - 1 && <Divider sx={{ marginBottom: 3, marginTop: 3 }} />}
          </>
        ))}
      </Card>
    </>
  );
}

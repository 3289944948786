import { m } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
import { MotionContainer, varBounce } from "../minimals/components/animate";
import Page from "../minimals/components/page";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function PageLoginSucess() {
  return (
    <Page title="Login Success" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h4" paragraph>
                You have successfully logged in!
              </Typography>
            </m.div>
            <Typography sx={{ color: "text.secondary" }}>You can now close this page</Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

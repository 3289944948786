// @mui
import { Grid, Stack } from "@mui/material";
import ProfilePostCard from "../../shared/profile/NoteCard";
import About from "./About";
import Branches from "./Branches";
import DirectColleagues from "./DirectColleagues";
import { useContext, useEffect, useState } from "react";
import { Company as CompanyType } from "../../types";
import axios from "axios";
import { ContactContext } from "../ContactContext";
import NoCompanyAssociated from "./NoCompanyAssociated";

// ----------------------------------------------------------------------

export default function Company() {
  const { contact } = useContext(ContactContext);
  const [company, setCompany] = useState<CompanyType>();
  const companyId = contact?.contactRoles[0]?.branch?.companyId;

  const getAndSetCompany = async () => {
    if (companyId) {
      const { data } = await axios.get(`/api/clientphonebook/companies/get/${companyId}`);
      setCompany(data);
    }
  };

  useEffect(() => {
    if (contact?.id) {
      getAndSetCompany();
    }
  }, [contact?.id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {companyId && (
          <Stack spacing={3}>
            <About />
            <Branches
              company={company}
              activeBranchId={contact?.contactRoles[0].companyBranchId}
              amountOfBranchesToDisplay={2}
              isDisplayMoreLocations
            />
          </Stack>
        )}
        {!companyId && <NoCompanyAssociated />}
      </Grid>

      <Grid item xs={12} md={8}>
        {companyId && (
          <Stack spacing={3}>
            <DirectColleagues />
            {company?.notes.map((note) => (
              <ProfilePostCard
                showClientInstructionsHeader={false}
                key={note.id}
                note={note}
                companyId={company?.id}
                contactId={contact?.id}
              />
            ))}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

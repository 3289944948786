import { useContext } from "react";
import { OrderContext } from "../contexts/OrderDataContext";
import Attachments from "../shared/Attachments";

// ----------------------------------------------------------------------

export default function ProofOfOrderPlacement() {
  const { orderFiles, setOrderFiles, errorMode, priorPolicyFiles, setPriorPolicyFiles } = useContext(OrderContext);

  return (
    <Attachments
      orderFiles={orderFiles}
      setOrderFiles={setOrderFiles}
      errorMode={errorMode}
      priorPolicyFiles={priorPolicyFiles}
      setPriorPolicyFiles={setPriorPolicyFiles}
    />
  );
}

import { Button } from "@mui/material";
import { useState } from "react";
import { DashboardReportType } from "../checks/types";
import AssignToModal from "./AssignToModal";

interface IProps {
  table: any;
  fetchTableData: Function;
  reportType: DashboardReportType;
  onRowsDeselected?: () => void;
}

export default function ReassignButtonAndModal({ table, reportType, fetchTableData, onRowsDeselected }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const selectedRows = table.getSelectedRowModel().rows.map((r: any) => r.original);
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        disabled={table.getSelectedRowModel().rows.length < 1}
        onClick={() => setIsOpen(true)}
      >
        Reassign
      </Button>
      {isOpen && (
        <AssignToModal
          selectedRows={selectedRows}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          reportType={reportType}
          fetchTableData={fetchTableData}
          onRowsDeselected={onRowsDeselected}
        />
      )}
    </>
  );
}

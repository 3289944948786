import { Container, Grid, Stepper, Step, StepLabel } from "@mui/material";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import HeaderBreadcrumbs from "../components/HeaderBreadcrumbs";
// ----------------------------------------------------------------------

const STEPS = ["Order Information", "Order Searches", "Confirmation"];

type Prop = {
  title: string;
  activeStep: number;
  children: JSX.Element;
};

export default function OrderPageContainer(pageProps: Prop) {
  const { themeStretch } = useSettingsContext();

  const { title, activeStep, children } = pageProps;

  return (
    <Page title={title} width="100%">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Express Order Placement"
          links={[{ name: "Orders", href: "" }, { name: "Express Order Placement", href: "" }, { name: "New Order" }]}
        />

        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stepper alternativeLabel activeStep={activeStep} sx={{ marginBottom: 3 }}>
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        typography: "subtitle2",
                        color: "text.disabled"
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item md={4} />
        </Grid>
        {children}
      </Container>
    </Page>
  );
}

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { CommitmentTask, TaskData } from "../types";

// ----------------------------------------------------------------------

const CommitmentsOverdueContext = createContext<TaskData>({} as TaskData);

function CommitmentsOverdueContextProvider({ children }: { children: JSX.Element }) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [commitmentsOverdue, setCommitmentsOverdue] = useState<CommitmentTask[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  let userName: string | undefined = useMemo(() => {
    let name = user?.name.toLowerCase();
    if (name) {
      return name
        .split(",")
        .map((p: string) => p.trim())
        .reverse()
        .join(" ");
    }
  }, [user]);

  const searchCommitmentsOverdue = async () => {
    setLoading(true);
    const { data } = await axios.get<CommitmentTask[]>(
      `/api/outstandingchecks/commitmentOverdue?username=${assignedToMe ? userName : ""}`
    );
    setCommitmentsOverdue(data);
    setLoading(false);
  };

  useEffect(() => {
    if (user?.name) {
      searchCommitmentsOverdue();
    }
  }, [user, assignedToMe]);

  return (
    <CommitmentsOverdueContext.Provider
      value={{
        loading,
        tasks: commitmentsOverdue,
        setTasks: setCommitmentsOverdue,
        assignedToMe,
        setAssignedToMe
      }}
    >
      {children}
    </CommitmentsOverdueContext.Provider>
  );
}

export { CommitmentsOverdueContextProvider, CommitmentsOverdueContext };

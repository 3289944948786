import { Container, Grid } from "@mui/material";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import AddEditContactForm from "../addEditContact/AddEditContactForm";

export default function Merge() {
  const { themeStretch } = useSettingsContext();
  return (
    <Container maxWidth={themeStretch ? false : "lg"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AddEditContactForm />
        </Grid>
      </Grid>
    </Container>
  );
}

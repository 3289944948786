import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Badge, Avatar } from "@mui/material";
//
import { CustomAvatarProps } from "./types";

// ----------------------------------------------------------------------

const getCharAtName = (name: string) => name && name.replace(/[0-9]/g, "").trim().charAt(0).toUpperCase();

function getColorByName(name: string): {
  backgroundColor: string;
  textColor: string;
} {
  const colors = [
    "#24508F",
    "#2874A6",
    "#2E86C1",
    "#3498DB",
    "#5DADE2",
    "#85C1E9",
    "#ABB2B9",
    "#D7DBDD",
    "#F2F3F4",
    "#E5E8E8",
    "#D0D3D4",
    "#BDC3C7",
    "#A6ACAF",
    "#909497",
    "#717D7E",
    "#34495E"
  ];

  // concatenate first and last name, remove any spaces and make lowercase
  const initials = name?.replace(/\s/g, "").toLowerCase();

  // get a numeric hash of the initials
  let hash = 0;
  for (let i = 0; i < initials?.length; i++) {
    hash = (hash << 5) - hash + initials?.charCodeAt(i);
    hash |= 0; // convert to 32-bit integer
  }

  // use the hash to select a color from the palette
  const index = Math.abs(hash) % colors.length;
  const backgroundColor = colors[index];

  // calculate the contrasting text color
  const textColor = getContrastColor(backgroundColor);

  // return the background and text colors
  return { backgroundColor, textColor };
}

function getContrastColor(hexColor: string): string {
  // convert hex color to RGB values
  const [r, g, b] = hexToRgb(hexColor);

  // calculate the contrast ratio using the W3C formula
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const contrastRatio = (luma + 0.05) / 0.05;

  // return white or black depending on the contrast ratio
  return contrastRatio >= 3 ? "#ffffff" : "#000000";
}

function hexToRgb(hexColor: string): [number, number, number] {
  const hex = hexColor.slice(1);
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return [r, g, b];
}

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ color, name = "", BadgeProps, children, sx, ...other }, ref) => {
    const theme = useTheme();

    const charAtName = getCharAtName(name);

    const colorByName = getColorByName(name);

    const colr = color || colorByName;

    const renderContent =
      colr === "default" ? (
        <Avatar ref={ref} sx={sx} {...other}>
          {name && charAtName}
          {children}
        </Avatar>
      ) : (
        <Avatar
          ref={ref}
          sx={{
            color: colorByName.textColor,
            backgroundColor: colorByName.backgroundColor,
            fontWeight: theme.typography.fontWeightMedium,
            ...sx
          }}
          {...other}
        >
          {name && charAtName}
          {children}
        </Avatar>
      );

    return BadgeProps ? (
      <Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} {...BadgeProps}>
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);

export default CustomAvatar;

import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Stack,
  Autocomplete,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { TransactionHistory } from './types';
import { historyColumns } from './HistoryColumns';
import { CloseIcon } from '../../../minimals/theme/overrides/CustomIcons';

const TransactionHistoryModal = ({
  open,
  closeModal,
  transactionId,
}: {
  open: boolean;
  closeModal: () => void;
  transactionId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState<
    TransactionHistory[]
  >([]);

  const getTransactionHistory = async () => {
    const { data } = await axios.get(
      `/proxy/api/ledgers/GetTransactionHistory?transactionId=${transactionId}`
    );
    
    setTransactionHistory(data);
  };
  useEffect(() => {
    getTransactionHistory();
  }, []);
  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Typography variant='h4' textAlign="center">
          Transaction History
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
        <Box paddingTop={1}>
          <MaterialReactTable
            columns={historyColumns}
            data={transactionHistory}
            initialState={{
              density: 'compact',
              pagination: { pageIndex: 0, pageSize: 30 },
            }}
            muiTableBodyProps={{
              sx: {
                '& .MuiTableRow-root:hover .MuiTableCell-root': {
                  backgroundColor: 'inherit !important',
                },
              },
            }}
            state={{ showSkeletons: loading }}
            enableSorting={false}
            enableGlobalFilter={false}
            enableFilters={false}
            enablePagination={false}
            enableColumnResizing={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionHistoryModal;

import { Recordable, TaskItem } from "../types";

export const getStatusColor = (statusId: number, variantMode?: boolean) => {
  switch (statusId) {
    case 0:
      return variantMode ? "warning" : "warning.main";
    case 1:
      return variantMode ? "error" : "error.main";
    case 2:
      return variantMode ? "success" : "success.main";
  }
};

export const getLatestEvent = (task: TaskItem) => {
  const sortedEventByDate = task.statusUpdateHistory.sort((a, b) => {
    const dateA: Date = new Date(a.dateTime);
    const dateB: Date = new Date(b.dateTime);
    return dateB.getTime() - dateA.getTime();
  });

  return sortedEventByDate[0];
};

export const getRecordableDescriptionAndTitle = (recordable: Recordable) => {
  return `${recordable.typeDescription} ${recordable.description ? `- ${recordable.description}` : ""}`.trim();
};

export const copyRecordableInfoToClipboard = async (recordables: Recordable[]): Promise<boolean> => {
  try {
    const htmlContent = recordables
      .map((recordable) => {
        const descriptionAndTitle = getRecordableDescriptionAndTitle(recordable);
        const tasksToBeCopied = recordable.taskItems
          .filter((task) => task.statusText === "Missing/Incorrect" || task.statusText === "Needs Confirmation")
          .map((task) => {
            const { description, statusText, notes } = task;
            const nonAssignedToNotes = notes
              .filter((n) => !n.text.toLowerCase().includes("assigned to"))
              .sort((a, b) => {
                const dateA: Date = new Date(a.dateCreated);
                const dateB: Date = new Date(b.dateCreated);
                return dateB.getTime() - dateA.getTime();
              });

            const note = nonAssignedToNotes[0]?.text || "";
            return `<b>${description}</b>: <i>${statusText}</i> ${note ? `- ${note}` : ""}`;
          })
          .join("<br>");

        return tasksToBeCopied
          ? `${descriptionAndTitle.toUpperCase() || "No Description"}<br><br>${tasksToBeCopied}`
          : "";
      })
      .filter((entry) => entry.trim() !== "")
      .join("<br><br>");

    const htmlBlob = new Blob([`<html><body>${htmlContent}</body></html>`], { type: "text/html" });
    const data = [new ClipboardItem({ "text/html": htmlBlob })];
    await navigator.clipboard.write(data);
    return true;
  } catch (error) {
    console.error("Error copying to clipboard:", error);
    return false;
  }
};

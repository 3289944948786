import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import { Backdrop, Button, CircularProgress, Fade, Typography } from "@mui/material";
import { useSettingsContext } from "./app/contexts/SettingsContext";
import DynamicLink from "./app/shared/DynamicLink";

const AppLoadingSpinner = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  return (
    <Fade
      in={!isAuthenticated}
      style={{
        transitionDelay: !isAuthenticated ? "800ms" : "0ms"
      }}
      unmountOnExit
    >
      <Backdrop sx={{ backgroundColor: "#fff" }} open={!isAuthenticated}>
        <CircularProgress color="primary" size={72} />
        <Typography marginLeft={3}>Loading...</Typography>
      </Backdrop>
    </Fade>
  );
};

const RequestLoginPage = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  return (
    <Backdrop sx={{ backgroundColor: "#fff", flexDirection: "column" }} open={!isAuthenticated}>
      <Typography textAlign="center" marginLeft={3} sx={{ maxWidth: 500 }}>
        In order to access the phonebook you need to be logged into the TitleQ app. For security reasons you cannot
        login here.
      </Typography>
      <Typography sx={{ marginTop: 4 }}>
        1. Please click{" "}
        {/* If you are scratching your head, loginsuccess is the path to redirect to once succesfully logged in */}
        <DynamicLink path="/loginsuccess" target="_blank">
          here to login
        </DynamicLink>
      </Typography>
      <Typography textAlign="center" marginLeft={3} marginTop={2} sx={{ maxWidth: 500 }}>
        Note: If you click on you username that is logged in and it doesn't work, choose "Use a different account" and
        then login again with your regular login.
      </Typography>
      <Typography sx={{ marginTop: 4 }}>
        2. After you successfully login, please click the button below to reload
      </Typography>
      <Button
        onClick={() => {
          location.reload();
        }}
      >
        Reload
      </Button>
      <Typography sx={{ marginTop: 4 }}></Typography>
    </Backdrop>
  );
};

type User = { name: string; email: string; username: string };

const isAppInIFrame = window.self !== window.top;

const EnsureAuthenticated = ({ children }: { children: ReactElement }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setupBeamer = (user: User) => {
    const beamerConfigScript = document.createElement("script");
    beamerConfigScript.text = ` 
        var beamer_config = {
          product_id : 'WdqQStfQ41060',
          user_email: '${user.email}',
          user_lastname: '${user.name}',
          user_id: '${user.email}',
          filter_by_url: true,
          file_number: 'TitleQ'
        };`;
    document.head.appendChild(beamerConfigScript);

    const beamerSrcScript = document.createElement("script");
    beamerSrcScript.type = "text/javascript";
    beamerSrcScript.src = "https://app.getbeamer.com/js/beamer-embed.js";

    document.head.appendChild(beamerSrcScript);
  };

  const setupPendo = (user: User) => {
    // @ts-ignore
    const localPendo = window.pendo as any;
    localPendo.initialize({
      visitor: {
        id: user.username,
        email: user.email,
        full_name: user.name
      }
    });
  };

  useEffect(() => {
    const setupBeamerAndPendo = async () => {
      const { data } = await axios.get("/api/login/GetUserPersonalInfo");
      setupBeamer(data as User);
      setupPendo(data as User);
    };

    if (!isAppInIFrame) {
      setupBeamerAndPendo();
    }
  }, []);

  useEffect(() => {
    const doLogin = async () => {
      const { data } = await axios.get("/api/login/isAuthenticated", {
        headers: { Pragma: "no-cache" }
      });
      setIsAuthenticated(data.isAuthenticated);
      setIsLoading(false);
      if (!data.isAuthenticated && !isAppInIFrame) {
        const { pathname, search } = window.location;
        window.location.href = `/api/login/?returnUrl=${pathname}${search}`;
      }
    };

    doLogin();
  }, []);

  if (isAuthenticated) {
    return children;
  }

  if (!isAuthenticated && !isLoading && isAppInIFrame) {
    return <RequestLoginPage isAuthenticated={isAuthenticated} />;
  }
  return <AppLoadingSpinner isAuthenticated={isAuthenticated} />;
};

export default EnsureAuthenticated;

import { useDropzone } from "react-dropzone";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";
// type
import { UploadMultiFileProps } from "./type";
//
import BlockContent from "./BlockContent";
import RejectionFiles from "./RejectionFiles";
import MultiFilePreview from "./MultiFilePreview";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[200]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" }
}));

// ----------------------------------------------------------------------

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  label,
  showSelectedFiles,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Paper variant="outlined" sx={{ borderStyle: "dashed" }}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            padding: 0,
            paddingRight: 1,
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter"
            })
          }}
        >
          <input {...getInputProps()} />

          <BlockContent label={label} />
        </DropZoneStyle>
      </Paper>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {showSelectedFiles && (
        <MultiFilePreview
          files={files}
          showPreview={showPreview}
          onRemove={onRemove}
          onRemoveAll={onRemoveAll}
          label={null}
        />
      )}

      {helperText && helperText}
    </Box>
  );
}

import Iconify from "../../../minimals/components/iconify";
import Profile from "./profile/Profile";
import { _userAbout, _userFeeds } from "../../../minimals/_mock/arrays";
import Members from "./members/Members";
import Associates from "./associates/Associates";
import Orders from "../shared/ordersAndInvoices/OrdersAndInvoices";
import History from "../shared/history/History";
import CompanyPreferences from "../shared/preferences/CompanyPreferences";
import { Alert, Card, CardContent, Typography } from "@mui/material";
import ComingSoon from "../shared/ComingSoon";
import { HistoryType } from "../types";

// ----------------------------------------------------------------------
export const TABS = [
  {
    values: ["profile"],
    label: "Profile",
    icon: <Iconify icon="ic:round-account-box" />,
    component: <Profile />
  },
  {
    values: ["members"],
    label: "Members",
    icon: <Iconify icon="eva:people-fill" />,
    component: <Members />
  },
  // {
  //   values: ["connections-clients", "connections-represented-by"],
  //   label: "Connections",
  //   icon: <Iconify icon="eva:people-fill" />,
  //   component: <ComingSoon />
  //   // component: <Associates />
  // },
  {
    values: ["orders", "invoices"],
    label: "Orders",
    icon: <Iconify icon="eva:folder-fill" />,
    component: <Orders type={"company"} />
  },
  {
    values: ["history"],
    label: "History",
    icon: <Iconify icon="ic:baseline-history" />,
    component: <History type={HistoryType.COMPANY} />
  },
  {
    values: ["preferences"],
    label: "Preferences",
    icon: <Iconify icon="eva:settings-2-outline" />,
    // component: <ComingSoon />
    component: <CompanyPreferences />
  }
];

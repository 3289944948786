import { Stack } from "@mui/material";
import { LocalizationProvider, DateRangePicker, DateRange } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  dateRange: DateRange<dayjs.Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
  table: any;
}
export default function SuspectedMatchesActions({ dateRange, setDateRange }: IProps) {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={dateRange}
          localeText={{
            start: "Edit-Start-Date",
            end: "Edit-End-Date"
          }}
          onAccept={(newValue) => {
            setDateRange(newValue);
          }}
          calendars={3}
          slotProps={{ textField: { size: "small" } }}
        />
      </LocalizationProvider>
    </Stack>
  );
}

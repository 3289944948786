export const TABS = [
  {
    value: "individuals",
    label: "Individuals",
    visible: true
  },
  {
    value: "companies",
    label: "Companies",
    visible: true
  },
  {
    value: "deactivatedindividuals",
    label: "Deactivated Individuals",
    visible: false
  }
  // {
  //   value: "blacklisted",
  //   label: "Blacklisted"
  // }
];

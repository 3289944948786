import { Button, Stack } from "@mui/material";
import Iconify from "../../minimals/components/iconify";
import OrderAddress from "./OrderAndAddress";

interface IProps {
  handleOpenNewCorpDoc: () => void;
}

export default function CorpDocHeader({ handleOpenNewCorpDoc }: IProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <OrderAddress />
      <Button
        color="primary"
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={handleOpenNewCorpDoc}
      >
        New Corp Doc
      </Button>
    </Stack>
  );
}

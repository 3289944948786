import { IconButton } from "@mui/material";
import {
  ClearIcon,
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { MRT_Column } from "material-react-table";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { TMEOrderDetails } from "./types";

const SingleDateRangeFilter = ({ column }: { column: MRT_Column<TMEOrderDetails, unknown> }) => {
  const defaultDateValues = (column.getFilterValue() || []) as Date[];
  const startDate = defaultDateValues[0] ? dayjs(defaultDateValues[0]) : null;
  const endDate = defaultDateValues[1] ? dayjs(defaultDateValues[1]) : null;
  const [value, setValue] = useState<DateRange<Dayjs>>([startDate, endDate]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={value}
        format="MM/DD/YY"
        onChange={(newValue) => {
          setValue(newValue);
          column.setFilterValue(newValue);
        }}
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
            InputProps: {
              endAdornment: (
                <IconButton sx={{ padding: 0.3 }}>
                  <ClearIcon
                    onClick={() => {
                      setValue([null, null]);
                      column.setFilterValue([null, null]);
                    }}
                    sx={{ width: 20, height: 20, cursor: "pointer", color: "rgba(145, 158, 171, 1)" }}
                  />
                </IconButton>
              )
            }
          }
        }}
        slots={{ field: SingleInputDateRangeField }}
      />
    </LocalizationProvider>
  );
};

export default SingleDateRangeFilter;

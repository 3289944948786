import { Alert, Stack, Typography } from "@mui/material";
import { BlacklistResultsWithSearch } from "../../components/BlacklistNameSearch/types";

export default function BlackListSearchAlert({
  blackListSearchResults
}: {
  blackListSearchResults: BlacklistResultsWithSearch[];
}) {
  if (blackListSearchResults.length == 0) return <></>;
  return (
    <Alert severity="error">
      <Stack direction="column" spacing={2}>
        <Typography variant="body1" sx={{ display: "inline" }}>
          DO NOT PROCEED! Potential blacklist matches found!!
        </Typography>
        <ol style={{ paddingLeft: "20px" }}>
          {blackListSearchResults.map((r, index) => (
            <li key={index}>
              <Typography variant="body2">
                <b>{r.searchedName}</b> may match {r.results.map((inner) => inner.information).join(", ")}
              </Typography>
            </li>
          ))}
        </ol>
        <Typography variant="body2" sx={{ fontWeight: "bold", color: "red" }}>
          DO NOT CONTINUE transaction until confirmed in writing with your manager!
        </Typography>
      </Stack>
    </Alert>
  );
}

// @mui
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Checkbox
} from "@mui/material";
import { Party } from "../types/Party";
import { Delete } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import PartyAddress from "./PartyAddress";
import { PropertyInfo } from "../types/Property";
import PrefilledIndicator from "../../components/PrefilledIndicator";

const PartyRootStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[200]
}));

const PartyRootWhiteStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.background.default
}));

export default function JointParty(prop: {
  party: Party;
  parties: Party[];
  setType: (type: "Individual" | "Organization", index: number) => void;
  setIsJoint: (isJoint: boolean, index: number) => void;
  setFirstName1: (firstName1: string, index: number) => void;
  setMiddleName1: (middleName1: string, index: number) => void;
  setLastName1: (lastName1: string, index: number) => void;
  setGender1: (gender1: "Male" | "Female", index: number) => void;
  setFirstName2: (firstName2: string, index: number) => void;
  setMiddleName2: (middleName2: string, index: number) => void;
  setLastName2: (lastName2: string, index: number) => void;
  setGender2: (gender2: "Male" | "Female", index: number) => void;
  setRelationshipType: (relationshipType: string | null, index: number) => void;
  sameAsPropertyAddress: boolean;
  index: number;
  multiple: boolean;
  removeParty: (index: number) => void;
  isSeller: boolean;
  setContactInfo: (property: string, value: string | PropertyInfo[], index: number) => void;
  setFullContactInfo: (value: PropertyInfo, index: number) => void;
  setAddressSameAsProperty: (sameAsPropertyAddress: boolean, index: number) => void;
}) {
  const {
    party,
    parties,
    setType,
    setIsJoint,
    setFirstName1,
    setMiddleName1,
    setLastName1,
    setGender1,
    setFirstName2,
    setMiddleName2,
    setLastName2,
    setGender2,
    setRelationshipType,
    index,
    multiple,
    removeParty,
    isSeller,
    setContactInfo,
    setFullContactInfo,
    setAddressSameAsProperty,
    sameAsPropertyAddress
  } = prop;

  const {
    relationshipTypeList,
    transactionType,
    autoFilled,
    setAutoFilled,
    getFullNameForBlacklistAndPatriotSearching,
    searchForPatriotsAndBlackList
  } = useContext(OrderContext);

  const [firstName1Touched, setFirstName1Touched] = useState(false);
  const [firstName2Touched, setFirstName2Touched] = useState(false);

  useEffect(() => {
    if (firstName1Touched && party.firstName1 !== "Certify as Found") {
      setFirstName1("", index);
    }
    if (firstName2Touched && party.firstName1 !== "Certify as Found") {
      setFirstName2("", index);
    }
  }, [firstName1Touched, firstName2Touched]);

  return (
    <Grid item xs={12} key={index} id={isSeller ? `seller${index}` : `buyer${index}`}>
      <Box component={multiple ? PartyRootStyle : Box}>
        <Grid container spacing={3} alignItems="center">
          {multiple && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {isSeller
                  ? "Seller"
                  : transactionType === "Refinance" || transactionType === "LastOwnerSearch"
                  ? "Borrower"
                  : "Buyer"}{" "}
                #{index + 1}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8}>
            <ToggleButtonGroup size="small" value={party.type} color="primary">
              <ToggleButton value="Individual" onClick={() => setType("Individual", index)}>
                Individual
              </ToggleButton>
              <ToggleButton value="Organization" onClick={() => setType("Organization", index)}>
                Organization
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={4}>
            <Stack alignItems="flex-end">
              <FormControlLabel
                control={<Switch checked={party.isJoint} onChange={() => setIsJoint(!party.isJoint, index)} />}
                label="Is Joint"
              />
            </Stack>
          </Grid>

          <Grid item md={3} xs={0}></Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={party.relationshipType}
              options={relationshipTypeList}
              onChange={(e, value) => setRelationshipType(value, index)}
              renderInput={(params) => (
                <TextField {...params} label="Joint Relationship" placeholder="Start typing..." />
              )}
            />
          </Grid>
          <Grid item md={3} xs={0}></Grid>
          <Grid item md={6} xs={12}>
            <Box component={multiple ? PartyRootWhiteStyle : PartyRootStyle}>
              <Typography variant="subtitle2" marginBottom={2}>
                Joint #1
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} position="relative">
                  <TextField
                    label="First Name"
                    value={party.firstName1 || ""}
                    onChange={(e) => setFirstName1(e.target.value, index)}
                    fullWidth
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    onFocus={() => {
                      if (party.firstName1 === "Certify as Found" || party.firstName1 === "TBD") {
                        setFirstName1Touched(true);
                      }
                      if (index === 0) {
                        setAutoFilled(
                          isSeller
                            ? {
                                ...autoFilled,
                                seller: {
                                  ...autoFilled.seller,
                                  firstName1: {
                                    ...autoFilled.seller.firstName1,
                                    autoFilled: false
                                  }
                                }
                              }
                            : {
                                ...autoFilled,
                                buyer: {
                                  ...autoFilled.buyer,
                                  firstName1: {
                                    ...autoFilled.buyer.firstName1,
                                    autoFilled: false
                                  }
                                }
                              }
                        );
                      }
                    }}
                  />
                  {index === 0 && isSeller && autoFilled.seller.firstName1.autoFilled && (
                    <PrefilledIndicator title={autoFilled.seller.firstName1.reason} sx={{ top: 24 }} />
                  )}
                  {index === 0 && !isSeller && autoFilled.buyer.firstName1.autoFilled && (
                    <PrefilledIndicator title={autoFilled.buyer.firstName1.reason} sx={{ top: 24 }} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Middle Name"
                    value={party.middleName1 || ""}
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    onChange={(e) => setMiddleName1(e.target.value, index)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} position="relative">
                  <TextField
                    label="Last Name"
                    value={party.lastName1 || ""}
                    onChange={(e) => setLastName1(e.target.value, index)}
                    fullWidth
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    onFocus={() => {
                      if (index === 0) {
                        setAutoFilled(
                          isSeller
                            ? {
                                ...autoFilled,
                                seller: {
                                  ...autoFilled.seller,
                                  lastName1: {
                                    ...autoFilled.seller.lastName1,
                                    autoFilled: false
                                  }
                                }
                              }
                            : {
                                ...autoFilled,
                                buyer: {
                                  ...autoFilled.buyer,
                                  lastName1: {
                                    ...autoFilled.buyer.lastName1,
                                    autoFilled: false
                                  }
                                }
                              }
                        );
                      }
                    }}
                  />
                  {index === 0 && isSeller && autoFilled.seller.lastName1.autoFilled && (
                    <PrefilledIndicator title={autoFilled.seller.lastName1.reason} sx={{ top: 24 }} />
                  )}
                  {index === 0 && !isSeller && autoFilled.buyer.lastName1.autoFilled && (
                    <PrefilledIndicator title={autoFilled.buyer.lastName1.reason} sx={{ top: 24 }} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup size="small" value={party.gender1} color="primary">
                    <ToggleButton value="Male" onClick={() => setGender1("Male", index)}>
                      Male
                    </ToggleButton>
                    <ToggleButton value="Female" onClick={() => setGender1("Female", index)}>
                      Female
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box component={multiple ? PartyRootWhiteStyle : PartyRootStyle}>
              <Typography variant="subtitle2" marginBottom={2}>
                Joint #2
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} position="relative">
                  <TextField
                    label="First Name"
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    value={party.firstName2 || ""}
                    onChange={(e) => setFirstName2(e.target.value, index)}
                    fullWidth
                    onFocus={() => {
                      setFirstName2Touched(true);
                      if (index === 0) {
                        setAutoFilled(
                          isSeller
                            ? {
                                ...autoFilled,
                                seller: {
                                  ...autoFilled.seller,
                                  firstName2: {
                                    ...autoFilled.seller.firstName2,
                                    autoFilled: false
                                  }
                                }
                              }
                            : {
                                ...autoFilled,
                                buyer: {
                                  ...autoFilled.buyer,
                                  firstName2: {
                                    ...autoFilled.buyer.firstName2,
                                    autoFilled: false
                                  }
                                }
                              }
                        );
                      }
                    }}
                  />
                  {index === 0 && isSeller && autoFilled.seller.firstName2.autoFilled && (
                    <PrefilledIndicator title={autoFilled.seller.firstName2.reason} sx={{ top: 24 }} />
                  )}
                  {index === 0 && !isSeller && autoFilled.buyer.firstName2.autoFilled && (
                    <PrefilledIndicator title={autoFilled.buyer.firstName2.reason} sx={{ top: 24 }} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Middle Name"
                    value={party.middleName2 || ""}
                    onChange={(e) => setMiddleName2(e.target.value, index)}
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} position="relative">
                  <TextField
                    label="Last Name"
                    value={party.lastName2 || ""}
                    onBlur={async () => {
                      const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                        firstName: party.firstName1,
                        middleName: party.middleName1,
                        lastName: party.lastName1
                      });
                      await searchForPatriotsAndBlackList([{ name: nameToUse, isCompany: false }], nameToUse);
                    }}
                    onChange={(e) => {
                      setLastName2(e.target.value, index);
                      setAutoFilled(
                        isSeller
                          ? {
                              ...autoFilled,
                              seller: {
                                ...autoFilled.seller,
                                lastName2: {
                                  ...autoFilled.seller.lastName2,
                                  autoFilled: false
                                }
                              }
                            }
                          : {
                              ...autoFilled,
                              buyer: {
                                ...autoFilled.buyer,
                                lastName2: {
                                  ...autoFilled.buyer.lastName2,
                                  autoFilled: false
                                }
                              }
                            }
                      );
                    }}
                    fullWidth
                  />
                  {index === 0 && isSeller && autoFilled.seller.lastName2.autoFilled && (
                    <PrefilledIndicator title={autoFilled.seller.lastName2.reason} sx={{ top: 24 }} />
                  )}
                  {index === 0 && !isSeller && autoFilled.buyer.lastName2.autoFilled && (
                    <PrefilledIndicator title={autoFilled.buyer.lastName2.reason} sx={{ top: 24 }} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup size="small" value={party.gender2} color="primary">
                    <ToggleButton value="Male" onClick={() => setGender2("Male", index)}>
                      Male
                    </ToggleButton>
                    <ToggleButton value="Female" onClick={() => setGender2("Female", index)}>
                      Female
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={party.sameAsPropertyAddress}
                  onClick={() => {
                    setAddressSameAsProperty(!party.sameAsPropertyAddress, index);
                  }}
                />
              }
              label="Address same as property"
            />
          </Grid>
          <PartyAddress
            party={party}
            sameAsPropertyAddress={sameAsPropertyAddress}
            setContactInfo={setContactInfo}
            setFullContactInfo={setFullContactInfo}
            index={index}
          />

          {(index > 0 || (index === 0 && parties.length > 1)) && (
            <Grid item xs={12}>
              <Button color="error" onClick={() => removeParty(index)}>
                <Delete fontSize="small" />
                Remove
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

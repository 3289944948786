import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { fMonthDayYearDotNotation, fTime } from "../utils/formatTime";
import { Recordable, RecordableStatusColorMap } from "./types";

interface IProps {
  recordable: Recordable;
  onClose: () => void;
}

export default function RecordableHistoryDialog({ recordable, onClose }: IProps) {
  const generateDocumentHref = (documentIdToFind: number | null) => {
    if (!documentIdToFind) return;
    return `https://internal.mcres.com/opendoc?id=${
      recordable?.documents.find((d) => d.id === documentIdToFind)?.momentumDocumentId
    }`;
  };

  const generateDocumentLabel = (documentIdToFind: number | null) => {
    if (!documentIdToFind) return;
    let reversedDocuments = recordable?.documents.slice().reverse();
    const index = reversedDocuments.findIndex((d) => d.id === documentIdToFind);
    return `Version ${index + 1}`;
  };

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1">{recordable.description}</Typography>
            <Typography variant="subtitle1" color="#637381">
              Recordable History
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="subtitle1">
              {recordable?.typeDescription || ""} {recordable?.description && `- ${recordable?.description}`}
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Document Status</TableCell>
                <TableCell>Package Status</TableCell>
                <TableCell>Document</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordable.history.map((h) => {
                return (
                  <TableRow sx={{ verticalAlign: "top" }}>
                    <TableCell>
                      <Typography variant="subtitle2">{fMonthDayYearDotNotation(h.dateTime)}</Typography>
                      <Typography variant="subtitle2" color="#637381" fontWeight={400}>
                        {fTime(h.dateTime)}
                      </Typography>
                    </TableCell>
                    <TableCell>{h.eventTypeDescription}</TableCell>
                    <TableCell sx={{ wordWrap: "normal" }}>
                      <Chip
                        sx={{ minWidth: 139, borderRadius: "8px" }}
                        variant="soft"
                        color={RecordableStatusColorMap[h.documentStatusDescription]}
                        label={h.documentStatusDescription}
                      />
                    </TableCell>
                    <TableCell sx={{ wordWrap: "normal" }}>
                      {h.packageStatusDescription && (
                        <Chip
                          sx={{ minWidth: 139, borderRadius: "8px" }}
                          variant="soft"
                          color={RecordableStatusColorMap[h.packageStatusDescription]}
                          label={h.packageStatusDescription}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Link href={generateDocumentHref(h.documentId)} underline="none" target="_blank">
                        <Button>{generateDocumentLabel(h.documentId)}</Button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{h.username?.toLowerCase()}</Typography>
                    </TableCell>
                    <TableCell width={300}>{h.note}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {recordable.history.length === 0 && <Typography padding={2}>No history available</Typography>}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LinearProgressWithLabel from "../shared/LinearProgressWithLabel";
import { fMonthDayYearDotNotation } from "../utils/formatTime";
import DownloadDocumentButton from "./DownloadFileButton";
import OpenFileButton from "./OpenFileButton";
import RecordableActions from "./RecordableActions";
import RecordableHistoryDialog from "./RecordableHistoryDialog";
import UploadButton from "./UploadButton";
import VersionCount from "./VersionCount";
import FileIcon from "./components/FileIcon";
import { Recordable, RecordableStatusColorHexMap, Status } from "./types";
import OpenSimplifileDocument from "./OpenSimplifileDocument";
import axios from "axios";

export default function RecordableTable({
  isDeleted,
  data,
  setSelectedRecordable,
  setOpenVersionUpload,
  getAndSetRecordables
}: {
  isDeleted?: boolean;
  data: Recordable[];
  setSelectedRecordable: Dispatch<SetStateAction<Recordable | null>>;
  setOpenVersionUpload: Dispatch<SetStateAction<boolean>>;
  getAndSetRecordables: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [statusTypes, setStatusTypes] = useState<Status[]>([]);
  const order = searchParams.get("order");
  const navigateToTasks = (id: number) => {
    navigate(`./tasks?order=${order}&recordable-id=${id}`);
  };

  const getAndSetStatuses = async () => {
    const { data } = await axios.get(`/api/recordablestracking/recordables/getStatusTypes`);
    setStatusTypes(data);
  };

  const handleSelectedRecordableAndOpenUpload = (r: Recordable) => {
    setSelectedRecordable(r);
    setOpenVersionUpload(true);
  };

  const handleOpenVersionOpload = (recordable: Recordable) => {
    setSelectedRecordable(recordable);
    setOpenVersionUpload(true);
  };

  const [selectedRecordableHistory, setSelectedRecordableHistory] = useState<Recordable>();

  useEffect(() => {
    getAndSetStatuses();
  }, []);

  return (
    <>
      <Table
        sx={{
          cursor: "pointer",
          "& .MuiTableRow-root:hover": {
            backgroundColor: "background.neutral"
          }
        }}
      >
        {data.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell>{isDeleted ? "Deleted Documents" : "Recordable Document"}</TableCell>
              <TableCell>Task Count</TableCell>
              <TableCell>Latest Activity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((r) => {
            const latestEvent = r.history[0];
            return (
              <TableRow>
                <TableCell onClick={() => navigateToTasks(r.id)} width={"27%"}>
                  <Stack direction="row" spacing={2}>
                    <FileIcon />
                    <Box>
                      <Typography variant="subtitle2">
                        {r.typeDescription} {r.description && `- ${r.description}`}
                      </Typography>
                      <Tooltip disableFocusListener title="Add">
                        <VersionCount
                          versionCount={r.documents.length}
                          documents={r.documents}
                          openVersionUpload={() => handleOpenVersionOpload(r)}
                        />
                      </Tooltip>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell onClick={() => navigateToTasks(r.id)}>
                  <LinearProgressWithLabel
                    totalTasks={r.taskItems.filter((t) => t.status !== 3).length}
                    completedTasks={r.taskItems.filter((t) => t.status === 2).length}
                    isDeleted={isDeleted}
                  />
                </TableCell>
                <TableCell width={500}>
                  {latestEvent && (
                    <>
                      <Typography variant="body2" color="GrayText" noWrap>
                        <Typography
                          variant="body2"
                          display="inline-block"
                          color="info.main"
                          onClick={() => setSelectedRecordableHistory(r)}
                          sx={{ cursor: "pointer" }}
                        >
                          {latestEvent.eventTypeDescription}
                        </Typography>{" "}
                        on {latestEvent?.dateTime && fMonthDayYearDotNotation(latestEvent?.dateTime)}
                      </Typography>
                      <Typography variant="body2" color="GrayText">
                        by&nbsp;{latestEvent?.username}
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color={RecordableStatusColorHexMap[r.statusDescription]} noWrap>
                    {r.statusDescription}
                  </Typography>
                </TableCell>
                <TableCell width={"60%"}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <RecordableActions
                      recordableId={r.id}
                      navigateToTasks={() => navigateToTasks(r.id)}
                      momentumDocumentId={r.documents[0].momentumDocumentId}
                      setOpenVersionUpload={() => handleSelectedRecordableAndOpenUpload(r)}
                      getAndSetRecordables={getAndSetRecordables}
                      isDeleted={isDeleted}
                      statusTypes={statusTypes}
                    />
                    <UploadButton setOpenVersionUpload={() => handleSelectedRecordableAndOpenUpload(r)} />
                    <DownloadDocumentButton fileId={r.documents[0].momentumDocumentId} />
                    <OpenFileButton
                      href={`https://internal.mcres.com/opendoc?id=${r.documents[0].momentumDocumentId}`}
                    />
                    <OpenSimplifileDocument href={r.viewPackageUrl || ""} />
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {selectedRecordableHistory && (
        <RecordableHistoryDialog
          recordable={selectedRecordableHistory}
          onClose={() => setSelectedRecordableHistory(undefined)}
        />
      )}
    </>
  );
}

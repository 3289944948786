import { Box, Card, CardHeader, Link, Stack, Tooltip, Typography } from "@mui/material";
import { StyledIcon } from "../../../components/StyledIcon";
import { ContactContext } from "../ContactContext";
import { useContext } from "react";
import Phone from "../../shared/phones/Phone";

export default function PersonalInfo() {
  const { contact } = useContext(ContactContext);
  const homeNumber = contact?.phoneNumbers.find((p) => p.type === "Home");
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Personal Information</Typography>} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {contact?.address1 && (
          <Stack direction="row">
            <Tooltip title="Home Address" placement="top-start">
              <StyledIcon icon="eva:home-fill" />
            </Tooltip>
            <Box>
              <Typography variant="body2">{contact?.address1}</Typography>
              {contact?.address2 && <Typography variant="body2">{contact?.address2}</Typography>}
              <Typography variant="body2">
                {contact?.city} {contact?.state} {contact?.zip}
              </Typography>
            </Box>
          </Stack>
        )}
        {contact?.personalEmail && (
          <Stack direction="row">
            <Tooltip title="Email" placement="top-start">
              <StyledIcon icon="eva:email-fill" />
            </Tooltip>
            <Typography variant="body2">
              <Link color="initial" href={`mailto:${contact?.personalEmail}`}>
                {contact?.personalEmail}
              </Link>
            </Typography>
          </Stack>
        )}
        {homeNumber && <Phone phone={homeNumber} />}
        {contact?.birthday && (
          <Stack direction="row">
            <Tooltip title="Birthday" placement="top-start">
              <StyledIcon icon="ic:baseline-cake" />
            </Tooltip>
            <Typography variant="body2">{new Date(contact?.birthday).toLocaleDateString()}</Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}

import { Stack } from "@mui/material";
import AccountRepAutocomplete from "../../shared/editPreferences/AccountRepAutocomplete";
import { useContext } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import { InternalUser } from "../../../types/app";

export default function AccountRepForm() {
  const { contact, handleAccountRepChange } = useContext(AddEditContactContext);

  const nyRep = contact?.contactReps.find((r) => r.contactRepTypeId === 2)?.internalUser;
  const njRep = contact?.contactReps.find((r) => r.contactRepTypeId === 3)?.internalUser;
  const nationalRep = contact?.contactReps.find((r) => r.contactRepTypeId === 4)?.internalUser;

  return (
    <Stack direction="column" spacing={2}>
      <AccountRepAutocomplete
        editedRep={nyRep}
        setEditedRep={(rep: InternalUser | undefined) => handleAccountRepChange(rep, 2)}
        label="NY Account Rep"
      />
      <AccountRepAutocomplete
        editedRep={njRep}
        setEditedRep={(rep: InternalUser | undefined) => handleAccountRepChange(rep, 3)}
        label="NJ Account Rep"
      />
      <AccountRepAutocomplete
        editedRep={nationalRep}
        setEditedRep={(rep: InternalUser | undefined) => handleAccountRepChange(rep, 4)}
        label="National Account Rep"
      />
    </Stack>
  );
}

import { Box, IconButton, Link, SvgIcon, SvgIconProps, Tooltip } from "@mui/material";

export default function OpenSimplifileDocument({ href }: { href: string }) {
  return (
    <Box>
      {href ? (
        <Link href={href} underline="none" target="_blank">
          <Tooltip title="View in Simplifile">
            <IconButton>
              <SimplifileIcon sx={{ height: "16px", width: "16px" }} />
            </IconButton>
          </Tooltip>
        </Link>
      ) : (
        <IconButton disabled>
          <SimplifileIcon sx={{ height: "16px", width: "16px", visibility: "hidden" }} />
        </IconButton>
      )}
    </Box>
  );
}
export function SimplifileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.40152V23.6742H23.2727V0.40152H0ZM20.1236 20.5252L3.14909 3.55061H20.1255V20.5252H20.1236Z"
        fill="#000"
      />
    </SvgIcon>
  );
}

import { Backdrop, CircularProgress, Typography } from "@mui/material";

const CenterLoader = ({ isOpen, displayText }: { isOpen: boolean; displayText?: string }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
      open={isOpen}
    >
      <CircularProgress color="inherit" />
      {displayText && (
        <Typography variant="h6" sx={{ mt: 2 }}>
          {displayText}
        </Typography>
      )}
    </Backdrop>
  );
};

export default CenterLoader;

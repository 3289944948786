import { Alert, Box, Button, Card, Container, Grid, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import DuplicateAlert from "../../shared/create/DuplicateAlert";
import IndividualInformation from "./OfficeInformation";
import { AddEditContactContext } from "./AddEditContactContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import CompanyInformation from "./CompanyInformation";
import { useNavigate } from "react-router-dom";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { Helmet } from "react-helmet-async";
import GoBackButton from "../../shared/create/GoBackButton";
import AddNewBranchToCompany from "./AddNewBranchToCompany";
import { Company, ContactRole, DynamicsContact, FullContact, Phone, SuspectedMatch } from "../../types";
import axios from "axios";
import DuplicateContact from "../../shared/create/DuplicateContact";
import AddEditCompanyToPhoneBook from "./AddEditCompanyToPhoneBook";
import About from "../../company/profile/About";
import RoleAlert from "./RoleAlert";
import MissingCompanyBranchAlert from "./MissingCompanyBranchAlert";
import PersonalInformation from "./PersonalInformation";
import { closeIFrame } from "../../../utils/iframeUtils";
import { logEvent } from "../../../utils/analyticsLogger";
import Discrepancies from "../merge/Discrepancies";
import { UserContext } from "../../../contexts/UserContext";
import MergedOrdersModal from "./MergedOrdersModal";
import Iconify from "../../../../minimals/components/iconify";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";

export default function AddEditContactForm() {
  const {
    submit,
    submitting,
    newCompany,
    editMode,
    newBranch,
    contact,
    reset,
    showInvalidAlert,
    setShowInvalidAlert,
    noCompanyMode,
    isBranchWithoutCompany,
    company,
    showRoleAlert,
    setShowRoleAlert,
    setAllowNoRole,
    showMissingCompanyBranchAlert,
    setShowMissingCompanyBranchAlert,
    setUseDefaultCompanyBranch,
    mergingContacts,
    setMergingContacts,
    handleMergeAndDelete,
    showOrdersModal,
    setShowOrdersModal,
    merging,
    getAllContactInfo,
    mergedOrderResult,
    getOrdersForLookupCode,
    getOrdersToMerge,
    deactivateContact
  } = useContext(AddEditContactContext);

  const { user } = useContext(UserContext);
  const [theInput, setTheInput] = useState("");
  const ref = useRef(null);
  const { themeStretch, isAppInIFrame } = useSettingsContext();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const contactsIdsToMerge = useMemo(() => searchParams.getAll("ids").map(Number), [location.search]);
  const [duplicateClientList, setDuplicateClientList] = useState<SuspectedMatch[]>([]);

  const [duplicateCompanyList, setDuplicateCompanyList] = useState<Company[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const setUpMergingContacts = async (firstContactId: number, secondContactId: number) => {
    const contactsPromises = [getAllContactInfo(firstContactId), getAllContactInfo(secondContactId)];
    const [contact1, contact2] = await Promise.all(contactsPromises);

    const getOrderCount = async (lookupCode: string) => {
      const { data } = await axios.get(
        `/api/clientphonebook/contacts/getcontactordersbylookupcode?lookupCode=${lookupCode}`
      );

      return data;
    };

    const contact1OrderCount = (await getOrderCount(contact1.lookupCode)).length;
    const contact2OrderCount = (await getOrderCount(contact2.lookupCode)).length;

    const arr = contact1OrderCount > contact2OrderCount ? [contact1, contact2] : [contact2, contact1];
    setMergingContacts(arr);
  };
  const searchClientDuplicates = async () => {
    const contactPhones = contact.phoneNumbers?.map((p) => p.number || "") || [];
    const contactRolePhones = contact.contactRoles.flatMap((cr) => cr.phoneNumbers.map((p) => p.number || ""));
    const phoneNumbers = contactPhones.concat(contactRolePhones).filter((n) => n.length > 3);

    if ((contact.firstName && contact.lastName) || contact.contactRoles[0]?.businessEmail || phoneNumbers.length) {
      const { data: clientphonebookContacts } = await axios.post("/api/clientphonebook/contacts/findMatching", {
        id: contact.id || null,
        firstName: (contact.firstName || "").trim(),
        lastName: (contact.lastName || "").trim(),
        businessEmail: (contact.contactRoles[0]?.businessEmail || "").trim(),
        phoneNumbers
      });
      const clientPhonebookContacts = clientphonebookContacts.map((d: FullContact) => ({
        ...d,
        isDynamicContact: false
      }));

      const queryParams = new URLSearchParams({
        firstName: (contact.firstName || "").trim(),
        lastName: (contact.lastName || "").trim(),
        email: (contact.contactRoles[0]?.businessEmail || "").trim()
      });

      const removeContactsWithMatchingLookupCodes = (
        dynamicsContacts: DynamicsContact[],
        phonebookContacts: FullContact[]
      ) => {
        const removedMatchingContacts = dynamicsContacts.filter((dc) =>
          phonebookContacts.some((pc) => dc.lookupCode !== pc.lookupCode)
        );
        return removedMatchingContacts;
      };
      const { data } = await axios.get(`/proxy/api/dynamics/contacts/Searchv2?${queryParams}`);

      let dynamics = data.map((d: DynamicsContact) => ({
        dynamicsGuid: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        address1: d.address,
        city: d.city,
        state: d.state,
        zip: d.zip,
        contactRoles: [
          {
            phoneNumbers: [
              {
                type: "Business",
                number: d.phone
              }
            ],
            businessEmail: d.emailAddress
          }
        ],
        isDynamicsContact: true
      }));
      dynamics = removeContactsWithMatchingLookupCodes(dynamics, clientPhonebookContacts);
      if (contact?.id != null) {
        dynamics = dynamics.filter((d: any) => d?.dynamicsGuid !== contact?.dynamicsGuid);
      }

      const combinedContacts = clientPhonebookContacts.concat(dynamics);
      setDuplicateClientList(combinedContacts);
      return;
    }

    setDuplicateClientList([]);
  };

  const searchCompanyDuplicates = async () => {
    const { data } = await axios.post("/api/clientphonebook/companies/findMatching", {
      id: company.id,
      name: company.name || "",
      website: company.website || "",
      phone: company.branches[0]?.phone || "",
      email: company.branches[0]?.email || "",
      fax: company.branches[0]?.fax || "",
      addresses: [
        {
          address1: company.branches[0]?.address1 || "",
          state: company.branches[0]?.state || ""
        }
      ]
    });
    const duplicateCompanies =
      data?.map(
        (d: any) =>
          ({
            ...d.company,
            branches: [d]
          } as Company)
      ) || [];
    setDuplicateCompanyList(duplicateCompanies);
  };

  const handleDismiss = () => {
    setDuplicateClientList([]);
    setDuplicateCompanyList([]);
  };

  const searchWords = [
    company.name,
    company.website,
    company.branches[0]?.phone?.replace("+", ""),
    company.branches[0]?.email,
    company.branches[0]?.address1
  ].filter((w) => w) as string[];

  const hasExistingBranch = !!contact.contactRoles[0]?.companyBranchId;

  const handleRoleAlertNoClick = () => {
    setAllowNoRole(true);
    setShowRoleAlert(false);
  };

  const handleRoleAlertClose = () => setShowRoleAlert(false);

  const handleMissingCompanyBranchAlertNoClick = () => {
    setUseDefaultCompanyBranch(true);
    setShowMissingCompanyBranchAlert(false);
  };

  const handleMissingCompanyBranchAlertClose = () => setShowMissingCompanyBranchAlert(false);

  const saveButton = () => {
    if (mergingContacts.length > 0) {
      return "Complete Merge";
    } else {
      return submitting ? "Saving..." : !isAppInIFrame ? "Save" : "Save & Add to Order";
    }
  };

  const mergeContactOrder = async (orderToMerge: ContactOrder) => {
    const { data } = await axios.post("/api/clientphonebook/contacts/updateorderlookup", {
      contactOrder: orderToMerge,
      lookupCodeToDelete: mergingContacts[1].lookupCode,
      contactToKeep: mergingContacts[0]
    });
    return data;
  };

  useEffect(() => {
    if (contactsIdsToMerge.length < 2) {
      return;
    }
    setUpMergingContacts(contactsIdsToMerge[0], contactsIdsToMerge[1]);
  }, [contactsIdsToMerge]);

  useEffect(() => {
    const savedRef: any = ref.current;
    if (savedRef) {
      savedRef.focus();
      savedRef.style.border = "#1817A8";
      savedRef.style.background = "#E6E6FF";
      // savedRef.scrollIntoView({
      //   behavior: "smooth"
      // })
    }
    return () => {
      if (savedRef) {
        savedRef.style.background = "white";
      }
    };
  }, [theInput]);
  return (
    <>
      {isAppInIFrame && (
        <Stack direction="row" justifyContent="flex-end" paddingRight={1.5} marginBottom={1}>
          <IconButton
            size="large"
            onClick={closeIFrame}
            sx={{ position: "absolute", top: 16, right: 16, padding: 0.3 }}
          >
            <Iconify icon="eva:close-outline" width={32} height={32} />
          </IconButton>
        </Stack>
      )}
      <Helmet>
        <title>
          {editMode ? "Edit Contact" : mergingContacts.length > 0 ? "Merge Contacts" : "Create a Contact"} | TitleQ
        </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={4}>
          <Typography variant="h4">
            {editMode ? "Edit Contact" : mergingContacts.length > 0 ? "Merge Contacts" : "Create a Contact"}
          </Typography>
          <GoBackButton />
        </Stack>
        <Grid container marginTop={5} columnSpacing={3}>
          {(duplicateClientList?.length > 0 || duplicateCompanyList?.length > 0) && mergingContacts.length < 2 && (
            <Grid item xs={12} marginBottom={5}>
              <DuplicateAlert handleDismiss={handleDismiss} />
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <IndividualInformation
                refProp={ref}
                theInput={theInput}
                setTheInput={setTheInput}
                searchClientDuplicates={searchClientDuplicates}
              />
              {!noCompanyMode && !newCompany && !newBranch && (
                <CompanyInformation
                  refProp={ref}
                  theInput={theInput}
                  setDuplicateCompanyList={setDuplicateCompanyList}
                />
              )}
              {(newCompany || noCompanyMode) && (
                <AddEditCompanyToPhoneBook searchCompanyDuplicates={searchCompanyDuplicates} />
              )}
              {newBranch && <AddNewBranchToCompany />}
              <PersonalInformation refProp={ref} theInput={theInput} />
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  variant="soft"
                  color="error"
                  onClick={() => {
                    reset();
                    if (isAppInIFrame) {
                      closeIFrame();
                    }
                    navigate(
                      editMode ? `../phonebook/contact/profile?id=${contact.id}` : "../phonebook/contacts/individuals"
                    );
                  }}
                  disabled={newCompany || (isBranchWithoutCompany && editMode && !noCompanyMode && !hasExistingBranch)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    mergingContacts.length > 0
                      ? handleMergeAndDelete(mergingContacts[1].lookupCode, mergingContacts[1].id)
                      : submit();
                    logEvent("Phonebook", `${editMode ? "Edit new individual" : "Add new individual"}`);
                  }}
                  disabled={
                    (newCompany && !noCompanyMode) ||
                    (isBranchWithoutCompany && editMode && !noCompanyMode && !hasExistingBranch) ||
                    submitting
                  }
                >
                  {saveButton()}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {mergingContacts.length > 0 && <Discrepancies setTheInput={setTheInput} />}
            {(duplicateClientList?.length > 0 || duplicateCompanyList?.length > 0) && (
              <Card
                sx={{
                  p: 2,
                  mb: 3,
                  position: "sticky",
                  top: 62,
                  maxHeight: "100vh",
                  overflowY: "scroll"
                }}
              >
                <Stack spacing={2}>
                  {duplicateClientList.length > 0 &&
                    mergingContacts.length < 2 &&
                    duplicateClientList.map((dcl) => (
                      <Grid key={dcl.contact.id}>
                        <DuplicateContact duplicateClient={dcl} />
                      </Grid>
                    ))}
                  {duplicateCompanyList.length > 0 &&
                    duplicateCompanyList.map((dcl) => (
                      <Grid key={dcl.id}>
                        <About company={dcl} duplicateMode searchWords={searchWords} />
                      </Grid>
                    ))}
                </Stack>
              </Card>
            )}
          </Grid>
        </Grid>
        {showInvalidAlert && (
          <Snackbar
            open={showInvalidAlert}
            onClose={() => setShowInvalidAlert(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setShowInvalidAlert(false)} severity="error" variant="filled" sx={{ width: "100%" }}>
              A{" "}
              <Typography variant="body2" fontWeight={900} display="inline-block">
                Last Name
              </Typography>{" "}
              and{" "}
              <Typography variant="body2" fontWeight={900} display="inline-block">
                ONE
              </Typography>
              {"…. "}
              of the following is required:
              <Typography variant="body2">• Email</Typography>
              <Typography variant="body2">• A Phone #</Typography>
              <Typography variant="body2">• A Company</Typography>
              <Typography variant="body2">• An Address</Typography>
            </Alert>
          </Snackbar>
        )}
      </Container>
      {showOrdersModal && (
        <MergedOrdersModal
          mergedOrderResult={mergedOrderResult}
          getOrdersForLookupCode={getOrdersForLookupCode}
          updateOrders={getOrdersToMerge}
          mergeOrder={mergeContactOrder}
          deleteingLookupCodes={[mergingContacts[1].lookupCode]}
          deactivateLookupCode={deactivateContact}
          navigateToProfile={`../phonebook/contact/profile?id=${contact.id}`}
        />
      )}
      {showRoleAlert && !showInvalidAlert && (
        <RoleAlert handleClose={handleRoleAlertClose} handleNo={handleRoleAlertNoClick} />
      )}
      {showMissingCompanyBranchAlert && (
        <MissingCompanyBranchAlert
          handleClose={handleMissingCompanyBranchAlertClose}
          handleNo={handleMissingCompanyBranchAlertNoClick}
        />
      )}
    </>
  );
}

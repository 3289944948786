import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { capitalCase } from "change-case";
import Associates from "./Associates";
import Company from "./Company";
import PersonalInfo from "./PersonalInfo";
import Preferences from "./Preferences";
import Profile from "./Profile";
import { useContacts } from "../../../contexts/ContactsContext";
import { Contact } from "../../../orderEntry/types/Contact";
import Page from "../../../../minimals/components/page";
import Iconify from "../../../../minimals/components/iconify";
import useTabs from "../../../../hooks/useTabs";

interface NewContactProps {
  constPrefilledData?: Contact;
  afterSaveFunction?: (contact?: any) => void;
  submitButtonText?: string;
  duplicateButtonText?: string;
  onClose?: () => void;
  name?: string;
}

export default function UserNewEditForm(props: NewContactProps) {
  const { currentTab, onChangeTab, setCurrentTab } = useTabs("profile");
  const { isEditMode, submitSaveChanges } = useContacts();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const onNextClick = async () => {
    if (isEditMode || currentTabIndex + 1 === ACCOUNT_TABS.length) {
      const contactCreated = await submitSaveChanges();
      props.afterSaveFunction &&
        props.afterSaveFunction({
          ...contactCreated,
          name: contactCreated?.name || `${contactCreated?.firstName || ""} ${contactCreated?.lastName || ""}`
        });
    } else {
      const nextTab = ACCOUNT_TABS[currentTabIndex + 1];
      setCurrentTab(nextTab.value);
      setCurrentTabIndex(currentTabIndex + 1);
    }
  };
  const ACCOUNT_TABS = [
    {
      value: "profile",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <Profile
          onNextClick={onNextClick}
          duplicateButtonText={props.duplicateButtonText}
          onAfterSubmitFunction={props.afterSaveFunction}
          name={props.name}
        />
      )
    },
    {
      value: "Company",
      icon: <Iconify icon={"ant-design:bank-filled"} width={20} height={20} />,
      component: <Company onNextClick={onNextClick} />
    },
    {
      value: "personal_info",
      icon: <Iconify icon={"clarity:avatar-line"} width={20} height={20} />,
      component: <PersonalInfo onNextClick={onNextClick} />
    },
    {
      // value: 'social_links',
      value: "preferences",
      icon: <Iconify icon={"ant-design:setting-outlined"} width={20} height={20} />,
      component: <Preferences onNextClick={onNextClick} submitButtonText={props.submitButtonText} />
    }
    // {
    //   // value: 'change_password',
    //   value: 'associates',
    //   icon: <Iconify icon={'el:group'} width={20} height={20} />,
    //   component: <Associates onNextClick={onNextClick} />,
    // },
  ];
  return (
    <Page title="New Contact" sx={{ position: "relative" }}>
      {props.onClose && (
        <Iconify
          onClick={props.onClose}
          icon={"ant-design:close-circle-outlined"}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
            zIndex: 1000
          }}
          width={30}
          height={30}
        />
      )}
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={(e, newValue) => {
          setCurrentTabIndex(ACCOUNT_TABS.findIndex((tab) => tab.value === newValue));
          onChangeTab(e, newValue);
        }}
      >
        {ACCOUNT_TABS.map((tab) => (
          <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      <Box sx={{ mb: 5 }} />

      {ACCOUNT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Page>
  );
}

import { Dispatch, MutableRefObject, SetStateAction, useContext } from "react";

import { Autocomplete, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { AddEditContactContext } from "./AddEditContactContext";
import { FullContact, Role } from "../../types";

interface IProps {
  refProp?: MutableRefObject<null | HTMLInputElement>;
  theInput?: string;
}
const roleLabel = (contact: FullContact) => {
  return (
    <Typography sx={{ display: "inline-block" }}>
      <Typography fontStyle="italic" sx={{ display: "inline-block" }}>{`${contact.firstName || ""}${
        contact.firstName && contact.lastName ? " " : ""
      }${contact.lastName || ""}${contact.firstName || contact.lastName ? "'s" : ""}`}</Typography>{" "}
      {`Role`}
    </Typography>
  );
};

export default function RoleAndProfession({ refProp, theInput }: IProps) {
  const {
    handleProfessionTypeChange,
    handleRoleTypeChange,
    isRoleSameAsProfession,
    handleIsRoleSameAsProfession,
    contact,
    roleList
  } = useContext(AddEditContactContext);

  const getRole = () => {
    const role = {
      ...roleList.find((r) => r.id === contact.contactRoles[0]?.roleId)
    } as Role;
    return role.id ? role : null;
  };

  const getProfession = () => {
    const profession = {
      ...roleList.find((r) => r.id === contact.professionId)
    } as Role;
    return profession.id ? profession : null;
  };

  return (
    <Grid container rowGap={3} columnSpacing={2} marginBottom={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isRoleSameAsProfession}
                onChange={(_, value) => handleIsRoleSameAsProfession(value)}
              />
            }
            label="Company Role is same as profession"
          />
        </Stack>
      </Grid>
      <Grid item xs={6} marginBottom={2}>
        <Autocomplete
          value={getRole()}
          onChange={(_, value) => handleRoleTypeChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password"
              }}
              fullWidth
              label={roleLabel(contact)}
              placeholder="Start typing..."
              inputRef={theInput === "Role" ? refProp : null}
            />
          )}
          getOptionLabel={(option) => option.name}
          options={roleList}
        />
      </Grid>
      {!isRoleSameAsProfession && (
        <Grid item xs={6}>
          <Autocomplete
            value={getProfession()}
            onChange={(_, value) => handleProfessionTypeChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password"
                }}
                fullWidth
                label="Profession"
                placeholder="Start typing..."
                inputRef={theInput === "Profession" ? refProp : null}
              />
            )}
            getOptionLabel={(option) => option.name}
            options={roleList}
          />
        </Grid>
      )}
    </Grid>
  );
}

import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Preview } from "../searches/types/Preview";
import { SearchSection } from "../searches/types/SearchSection";
import { SearchRequest } from "../searches/types/SearchRequest";
import { produce } from "immer";
import { Contact } from "../orderEntry/types/Contact";
import axios from "axios";
import { convertToFileDownload } from "../utils/convertToFileDownload";
import { Order, Contact as SearchContact } from "../orderEntry/types/Order";
import { FileData, SearchesData, SearchItem, UpdateSearchTaskProp, UploadFilesProp } from "./types/types";
import { OtherNameSearchTypes } from "../searches/names/OtherNameSearchTypes";
import { FileWithType } from "../shared/types/FileWithType";
import { Attachment } from "../searches/types/Attachment";
import { logEvent } from "../utils/analyticsLogger";
import { UserContext } from "./UserContext";

// ----------------------------------------------------------------------
const SearchesContext = createContext<SearchesData>({} as SearchesData);

const getTomorrowsDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

function SearchContextProvider({ children }: { children: JSX.Element }) {
  const { orderNumber } = useParams();
  const { user } = useContext(UserContext);

  const [order, setOrder] = useState<Order>();
  const [orderFiles, setOrderFiles] = useState<FileWithType[]>([]);
  const [searchItemList, setSearchItemList] = useState<SearchItem[]>([]);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const dueDateDefault = getTomorrowsDate();
  const [notes, setNotes] = useState<string>();
  const [selectedAttachments, setSelectedAttachments] = useState<Attachment[]>([]);
  const [searchSections, setSearchSections] = useState<SearchSection[]>([
    {
      vendor: null,
      dueDate: dueDateDefault,
      searchRequests: [
        {
          searchType: "Abstract Search Request"
        }
      ]
    }
  ]);

  const setSearches = (sectionIndex: number, searches: SearchRequest[]) => {
    const nextState = produce(searchSections, (drafState) => {
      drafState[sectionIndex].searchRequests = searches;
    });

    setSearchSections(nextState);
  };

  const setVendor = (sectionIndex: number, vendor: Contact) => {
    const nextState = produce(searchSections, (drafState) => {
      drafState[sectionIndex].vendor = vendor;
    });

    setSearchSections(nextState);
  };

  const updateSearchTask = async (updateSearchData: UpdateSearchTaskProp) => {
    await axios({
      method: "post",
      url: `/proxy/api/ordersearches/updateSearchTask`,
      data: updateSearchData
    });
  };

  const uploadFiles = (uploadFilesData: UploadFilesProp) => {
    axios.post("/api/file/uploadfiles", uploadFilesData);
  };

  const onPreivewSendViaEmailClick = async () => {
    const { base64Document, index } = preview!;
    const vendor = searchSections[index].vendor;
    const firstSearch = searchSections[index].searchRequests[0].searchType;
    const { data: orderLockStatus } = await axios.get(
      `/proxy/api/orders/getorderlockstatus?ordernumber=${orderNumber}`
    );
    if (orderLockStatus.isLocked) {
      alert(
        `File number ${orderNumber} is currently locked in Select by ${orderLockStatus.lockedByUser}. Please close the order and try again.`
      );
      return;
    }
    const payload = {
      base64Document,
      toEmail: vendor?.email,
      toName: vendor?.name,
      subject: firstSearch,
      orderNumber,
      body: notes,
      attachmentIds: selectedAttachments.map((a) => a.id)
    };
    const payloadWithoutBase64 = { ...payload } as any;
    delete payloadWithoutBase64.base64Document;
    logEvent("Order Searches", "Sent Via Email", JSON.stringify(payloadWithoutBase64, null, 2));
    const { data } = await axios.post("/api/orderSearches/generateemail", payload);
    convertToFileDownload(data.base64Email, "search-request.msg");
    updateSearchTask({
      orderNumber,
      vendor: !vendor ? null : ({ ...vendor, notes: vendor.notes?.join("\n") } as Contact),
      searchTypes: searchSections[index].searchRequests.map((s) => s.searchType),
      notes,
      dueDate: searchSections[index].dueDate,
      requestedByUsername: user?.username
    });
    const searchFiles = [
      {
        name: `${firstSearch}.pdf`,
        base64Contents: base64Document
      } as FileData
    ];
    uploadFiles({ orderNumber, searchFiles });
    setSentEmail(data.base64Email);
    closePreview();
  };

  const closePreview = () => {
    setPreview({ ...preview, previewMode: false } as Preview);
  };

  const [preview, setPreview] = useState<Preview>();

  const filterSearchContacts = (contacts: SearchContact[], transactionType: string) => {
    return contacts
      .filter(
        (c) =>
          c.type === "Buyer" ||
          (c.type === "Seller" && transactionType === "Purchase") ||
          OtherNameSearchTypes.some((t) => t.toLowerCase() === c.otherType?.toLowerCase())
      )
      .map((c) => {
        if (c.type === "Buyer") {
          return { ...c, type: "Buyer/Borrower" };
        } else if (c.otherType === "Name on application") {
          return { ...c, otherType: "Name on Application" };
        } else {
          return c;
        }
      });
  };

  const getandSetOrderInfo = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/GetOrderInfo?orderNumber=${orderNumber}`
    });
    setOrder({
      orderNumber: data.number,
      identifier: data.identifier,
      titleCode: data.titleCode,
      propertyUse: data.propertyUse,
      state: data.properties[0].state,
      productType: data.productType,
      contacts: filterSearchContacts(data.contacts, data.transactionType),
      properties: data.properties
    });
  };

  const getAndSetSearchItems = async (state: string) => {
    const { data } = await axios({
      method: "get",
      url: `/api/intel/getSearchItems?state=${state}`
    });
    setSearchItemList(data);
  };

  useEffect(() => {
    getandSetOrderInfo();
  }, [orderNumber]);

  useEffect(() => {
    order?.state && getAndSetSearchItems(order.state);
  }, [order]);

  return (
    <SearchesContext.Provider
      value={{
        orderNumber,
        order,
        orderFiles,
        notes,
        searchItemList,
        selectedAttachments,
        setOrder,
        setOrderFiles,
        setNotes,
        setSearchSections,
        searchSections,
        setSearches,
        setSelectedAttachments,
        setVendor,
        preview,
        setPreview,
        closePreview,
        onPreivewSendViaEmailClick,
        sentEmail
      }}
    >
      {children}
    </SearchesContext.Provider>
  );
}

export { SearchContextProvider, SearchesContext };

import { Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { AddEditCompanyContext } from "./AddEditCompanyContext";
import BranchInformation from "./BranchInformation";
import { useNavigate } from "react-router-dom";
import { CompanyBranch } from "../../types";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { logEvent } from "../../../utils/analyticsLogger";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import MainCompanyForm from "./MainCompanyForm";
import { KVP } from "./types";

const isExistingBranch = (id: number) => id.toString().length < 13;

export default function CompanyInformation() {
  const { company, setCompany, submit, submitting, editMode, setBranchIdentifiers, branchIdentifiers } =
    useContext(AddEditCompanyContext);
  const { isAppInIFrame } = useSettingsContext();
  const navigate = useNavigate();

  const [hasBranchesLoaded, setHasBranchesLoaded] = useState(false);

  const addBranch = () => {
    const min = 10000;
    const max = 1000000;
    const newBranchId = (Math.floor(Math.random() * (max - min + 1)) + min) * -1; //HACK ALERT: because we need to retain branch # on the UI when users delete branches, every branch needs an id so we can map it into the original order of all the branches in the branchIdentifiers 
    setCompany((draft) => {
      if (draft?.branches) {
        draft.branches.push({
          id: newBranchId,
          companyId: company.id,
          isHeadquarters: false,
          address1: ""
        } as CompanyBranch);
      }
    });
    setBranchIdentifiers((prevIdentifiers) => {
      const nextNumber = Object.keys(prevIdentifiers).length;
      return {
        ...prevIdentifiers,
        [newBranchId]: nextNumber
      };
    });
  };

  const sortBranches = useMemo(() => {
    if (!company?.branches) return [];

    const sortedBranches = [...company.branches].sort((a, b) => (a.address1 || "").localeCompare(b.address1 || ""));
    const headquartersIndex = sortedBranches.findIndex((branch) => branch.isHeadquarters);

    if (headquartersIndex > -1) {
      const headquarterBranch = sortedBranches.splice(headquartersIndex, 1)[0];
      const branchesBeforeHQ = sortedBranches.filter(
        (branch) =>
          isExistingBranch(branch.id) && (branch.address1 || "").localeCompare(headquarterBranch.address1 || "") < 0
      );
      const branchesAfterHQ = sortedBranches.filter(
        (branch) =>
          isExistingBranch(branch.id) && (branch.address1 || "").localeCompare(headquarterBranch.address1 || "") >= 0
      );
      const newBranches = company.branches.filter((branch) => !isExistingBranch(branch.id));

      return [headquarterBranch, ...branchesAfterHQ, ...branchesBeforeHQ, ...newBranches];
    }

    return sortedBranches;
  }, [company?.branches]);

  useEffect(() => {
    if (!company?.branches || hasBranchesLoaded) return;

    setCompany((draft) => {
      draft.branches = sortBranches;
    });

    const identifiers = sortBranches.reduce((acc, branch, index) => {
      acc[branch.id] = index;
      return acc;
    }, {} as KVP);

    setBranchIdentifiers(identifiers);

    setHasBranchesLoaded(true);
  }, [company?.branches, hasBranchesLoaded]);

  return (
    <Card>
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <MainCompanyForm company={company} setCompany={setCompany} />
        </Grid>
        <Stack spacing={3} marginTop={3}>
          {company.branches?.map((b) => (
            <BranchInformation company={company} branch={b} number={branchIdentifiers[b.id]} key={b.id} />
          ))}
        </Stack>
        <Button startIcon={<Iconify icon="eva:plus-fill" />} sx={{ marginTop: 2 }} onClick={addBranch}>
          Add Additional Location
        </Button>
        <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={3}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => {
              navigate(editMode ? `../phonebook/company/profile?id=${company.id}` : "../phonebook/contacts/companies");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              submit(true);
              logEvent("Phonebook", `${editMode ? "Edit company" : "Add company"}`);
            }}
            disabled={submitting}
          >
            {submitting ? "Saving..." : !isAppInIFrame ? "Save" : "Save & Add to Order"}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

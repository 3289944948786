import Label from "../../minimals/components/label";

const ActiveStatusLabel = ({ isActive }: { isActive: boolean }) => {
  return (
    <Label variant="soft" color={isActive ? "success" : "error"}>
      {isActive ? "Active" : "Inactive"}
    </Label>
  );
};

export default ActiveStatusLabel;

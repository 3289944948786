import { useContext } from "react";
// @mui
import { Autocomplete, Grid, Popper, TextField, TextFieldProps, Typography } from "@mui/material";
// components
import { OrderContext } from "../contexts/OrderDataContext";
import axios from "axios";
import { LensTwoTone } from "@mui/icons-material";
import { SettlementAgent } from "./types/SettlementAgent";
import React from "react";
import DebouncedAutoComplete from "../components/DebouncedAutoComplete";
import PrefilledIndicator from "../components/PrefilledIndicator";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { fullAddress } from "../../minimals/_mock/assets";

// ----------------------------------------------------------------------

export default function TitleAgentAndUnderwriter() {
  const {
    selectedUnderwriter,
    setSelectedUnderwriter,
    underwriterList,
    titleCompanyList,
    selectedTitleCompany,
    settlementDate,
    selectedSettlementAgent,
    settlementAgentList,
    setSelectedSettlementAgent,
    setSettlementAgentList,
    setSelectedTitleCompany,
    setSettlementDate,
    errorMode,
    errors,
    autoFilled,
    setAutoFilled,
    transactionType
  } = useContext(OrderContext);

  const searchSettlementAgents = async (searchQuery: string) => {
    let { data } = await axios({
      method: "get",
      url: `/proxy/api/clients/searchSettlementAgents?searchQuery=${searchQuery}`
    });
    data = data.filter((d: SettlementAgent) => d.lookupCode && d.lookupCode != "-");
    setSettlementAgentList(data);
  };
  const PopperMy = React.useCallback((props: any) => {
    return <Popper {...props} style={{ width: "700px" }} placement="bottom-start" />;
  }, []);

  return (
    <Grid container spacing={3} alignItems="top" marginBottom={3}>
      <Grid item md={6} xs={12} position="relative">
        <Autocomplete
          value={selectedTitleCompany}
          PopperComponent={PopperMy}
          disabled={titleCompanyList.length < 1}
          options={titleCompanyList}
          getOptionLabel={(option) => option.lookupCode || ""}
          onChange={(e, value) => {
            setSelectedTitleCompany(value);
            setSelectedUnderwriter(null);
            setAutoFilled({
              ...autoFilled,
              titleCompany: { ...autoFilled.titleCompany, autoFilled: false }
            });
          }}
          renderInput={(params) => (
            <TextField
              required
              error={errorMode && errors.titleCompany}
              {...params}
              label="Title Agent"
              placeholder="Start typing..."
            />
          )}
        />
        {autoFilled.titleCompany.autoFilled && <PrefilledIndicator title={autoFilled.titleCompany.reason} />}
      </Grid>
      {transactionType != "LastOwnerSearch" && (
        <Grid item md={6} xs={12} position="relative">
          <Autocomplete
            value={selectedUnderwriter}
            disabled={underwriterList.length < 1}
            options={underwriterList}
            getOptionLabel={(option) => option.payeeName || ""}
            onChange={(e, value) => {
              setSelectedUnderwriter(value);
              setAutoFilled({
                ...autoFilled,
                underwriter: { ...autoFilled.underwriter, autoFilled: false }
              });
            }}
            renderInput={(params) => (
              <TextField
                required
                error={errorMode && errors.underwriter}
                {...params}
                label="Underwriter"
                placeholder="Start typing..."
              />
            )}
          />
          {autoFilled.underwriter.autoFilled && <PrefilledIndicator title={autoFilled.underwriter.reason} />}
        </Grid>
      )}
      <Grid item md={6} xs={12} position="relative">
        <DebouncedAutoComplete
          value={selectedSettlementAgent}
          options={settlementAgentList}
          getOptionsLabel={(option) => option?.name || ""}
          onSelectedOptionChanged={(e, value) => {
            if (typeof value !== "object") return;
            setSelectedSettlementAgent(value);
            setAutoFilled({
              ...autoFilled,
              settlementAgent: {
                ...autoFilled.settlementAgent,
                autoFilled: false
              }
            });
          }}
          renderOption={(props, option) => (
            <li {...props} key={option?.lookupCode}>
              <div style={{ width: "100%" }}>
                <div>
                  <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                    {`${option?.name || ""}`}
                  </Typography>

                  {option?.company && (
                    <Typography
                      sx={{
                        display: "inline-block",
                        color: "#b1b6bb",
                        fontSize: "13px"
                      }}
                      variant="body1"
                    >
                      {option?.company && <span> &nbsp; @{option.company}</span>}
                    </Typography>
                  )}
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ display: "inline-block" }} variant="caption">
                    {`${option?.phone || ""}`}
                  </Typography>
                  <Typography sx={{ display: "inline-block" }} variant="caption">
                    {`${option?.address1 || ""}`}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ display: "inline-block" }} variant="caption">
                    {`${option?.email || ""}`}
                  </Typography>
                  <Typography sx={{ display: "inline-block" }} variant="caption">
                    {`${option?.address2 || ""}`}
                  </Typography>
                </div>
              </div>
            </li>
          )}
          textboxPlaceholder="Start typing..."
          textboxLabel="Settlement Agent"
          onDebouncedTextChanged={(text) => searchSettlementAgents(text)}
        />
        {autoFilled.settlementAgent.autoFilled && <PrefilledIndicator title={autoFilled.settlementAgent.reason} />}
      </Grid>
      <Grid item md={6} xs={12}>
        <DesktopDatePicker
          label="Settlement Date"
          format="MM/dd/yyyy"
          value={settlementDate}
          onChange={(d) => {
            if (!d) {
              return;
            }
            //TODO: It seems that by default this datepicker is setting the time to UTC
            //I haven't figured out a way to disable that, so for now I'm converting the UTC
            //time to local time. We should really figure out how to change the datepicker
            //to not use UTC
            const date = new Date(d!);
            const localOffset = date.getTimezoneOffset() * 60000;
            const localTime = date.getTime();
            const newDate = localTime - localOffset;
            setSettlementDate(new Date(newDate));
          }}
          slotProps={{ textField: { fullWidth: true } }}
        />
      </Grid>
    </Grid>
  );
}

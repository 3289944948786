import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Contact, FullContact } from "../types";
import { Updater, useImmer } from "use-immer";

// ----------------------------------------------------------------------

interface IContactContext {
  contact: FullContact | undefined;
  setContact: Updater<FullContact | undefined>;
  isFetching: boolean;
}

const ContactContext = createContext<IContactContext>({} as IContactContext);

type ContactProviderProps = {
  children: ReactNode;
};

function ContactProvider({ children }: ContactProviderProps) {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const lookupcode = searchParams.get("lookupcode");
  const [contact, setContact] = useImmer<FullContact | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(true);

  const getAndSetContact = async () => {
    setIsFetching(true);
    const { data } = await axios.get(`/api/clientphonebook/contacts/get?id=${id || ""}&lookupcode=${lookupcode || ""}`);
    setContact((draft) => (draft = data));
    setIsFetching(false);
  };

  useEffect(() => {
    if (id || lookupcode) {
      getAndSetContact();
    }
  }, [id, lookupcode]);

  return <ContactContext.Provider value={{ contact, setContact, isFetching }}>{children}</ContactContext.Provider>;
}

export { ContactProvider, ContactContext };

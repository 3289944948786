import { Container, Typography, Box } from "@mui/material";

const Unauthorized = () => {
  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="60vh">
        <Typography variant="h4" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1">
          You are not authorized to access this page. Please contact your administrator for assistance.
        </Typography>
      </Box>
    </Container>
  );
};

export default Unauthorized;

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { NumberFormatCustom } from "../components/NumberFormat";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";
import { useQueryParam } from "use-query-params";
import Iconify from "../../minimals/components/iconify";
import { prependUserToSelectNote } from "../utils/prependUserToSelectNote";

type LedgerTransaction = {
  id: string;
  status: string;
  amount: number;
  name: string;
  payorPayee: string;
  type: string;
  typeDescription: string;
  isDebit: boolean;
  referenceNumber: string;
  memo: string | null;
  systemPostedOn: string | null;
  userPostedOn: string | null;
  voidedOn: string | null;
  clearedOn: string | null;
  transactionDate: string | null;
};

export default function ClosingChecklistEscrow({ onClose, refresh }: { onClose: () => void; refresh: () => void }) {
  const { user } = useContext(UserContext);
  const [existingOrderNumber] = useQueryParam<string | undefined>("order");
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [memo, setMemo] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [orderNumber, setOrderNumber] = useState<string | null>(existingOrderNumber || null);
  const [notes, setNotes] = useState<string | null>(null); // State for notes
  const [assignToEscrowTeam, setAssignToEscrowTeam] = useState(true);
  const [ledgerBalance, setLedgerBalance] = useState(0);
  const [orderNumbers, setOrderNumbers] = useState<string[]>([]);
  const [loadingOrderNumbers, setLoadingOrderNumbers] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<LedgerTransaction[]>([]);
  const [existingTransactionId, setExistingTransactionId] = useState("");
  const [touched, setTouched] = useState(false);

  const submitTransfer = async () => {
    setTouched(true);
    if (!amount || !memo || !dueDate) return;
    setSubmitting(true);
    await axios.post("/proxy/api/ledgers/CreateEscrowLedger", {
      orderNumber: orderNumber,
      amount,
      for: memo,
      dueDate,
      userName: user?.username,
      notes: notes ? prependUserToSelectNote(notes, user?.name as string) : null,
      assignToEscrowTeam,
      transactionId: existingTransactionId
    });
    refresh();
    onClose();
  };

  const getLedgerBalance = async (orderNumber: string | null) => {
    if (!orderNumber) {
      setLedgerBalance(0);
      return;
    }
    const { data } = await axios.get(`/proxy/api/ledgers/GetOrderAndLedgerBalance?ordernumber=${orderNumber}`);
    setLedgerBalance(data.ledgerBalance);
  };

  const loadTransactions = async (orderNumber: string) => {
    const response = await axios.get<LedgerTransaction[]>(
      `/proxy/api/ledgers/gettransactions?orderNumber=${orderNumber}`
    );
    const filteredTransactions = response.data.filter((transaction) => {
      return transaction.typeDescription.startsWith("Ledger Transfer") && transaction.status === "Pending";
    });
    setTransactions(filteredTransactions);
  };

  const debouncedOrderSearchTextChange = debounce(async (text: string | null) => {
    if (!text) {
      setOrderNumbers([]);
      return;
    }
    setLoadingOrderNumbers(true);
    const { data } = await axios.get(`/proxy/api/orders/FindRelatedOrdersByNumber?searchText=${text}`);
    setOrderNumbers(data);
    setLoadingOrderNumbers(false);
  }, 500);

  useEffect(() => {
    getLedgerBalance(orderNumber);
  }, [orderNumber]);

  useEffect(() => {
    if (orderNumber) {
      loadTransactions(orderNumber);
    }
  }, []);

  const isValidTransactionAmount = amount! <= ledgerBalance;

  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Transfer Funds to Escrow</DialogTitle>
      <DialogContent sx={{ paddingBottom: 4 }}>
        <Stack direction="column" marginTop={1} spacing={2}>
          {!existingOrderNumber && (
            <Autocomplete
              options={orderNumbers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Order Number"
                  onChange={(e) => debouncedOrderSearchTextChange(e.target.value)}
                />
              )}
              onChange={async (e, val) => {
                setOrderNumber(val);
                if (val) {
                  await loadTransactions(val!);
                }
              }}
              loading={loadingOrderNumbers}
            />
          )}
          <TextField
            select
            fullWidth
            disabled={!orderNumber}
            label="Choose Pending Transaction"
            value={existingTransactionId}
            onChange={(e) => {
              const transaction = transactions.find((t) => t.id === e.target.value);
              if (transaction) {
                setAmount(transaction?.amount);
                setExistingTransactionId(transaction.id);
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: existingTransactionId && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setExistingTransactionId("");
                      setAmount(null);
                    }}
                    size="small"
                  >
                    <Iconify icon="material-symbols:close-small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          >
            {transactions.map((transaction) => (
              <MenuItem key={transaction!.id} value={transaction!.id}>
                {transaction!.name} - ${transaction!.amount.toFixed(2)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            error={touched && !amount}
            fullWidth
            label="Amount"
            value={amount}
            variant="outlined"
            onChange={(e) => setAmount(Number(e.target.value))}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
            disabled={!orderNumber || Boolean(existingTransactionId)}
          />
          <TextField
            required
            error={touched && !memo}
            fullWidth
            label="For"
            variant="outlined"
            onChange={(e) => setMemo(e.target.value)}
            disabled={!orderNumber}
            inputProps={{ maxLength: 50 }}
            helperText={memo?.length === 50 ? "Only 50 characters allowed, use notes for additional info." : null}
          />
          <DatePicker
            label="Due Date"
            onChange={setDueDate}
            disabled={!orderNumber}
            slotProps={{
              textField: {
                required: true,
                error: touched && !dueDate
              }
            }}
          />
          <TextField
            fullWidth
            label="Notes"
            variant="outlined"
            multiline
            rows={4}
            onChange={(e) => setNotes(e.target.value)}
            disabled={!orderNumber}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={assignToEscrowTeam}
                onChange={(e) => setAssignToEscrowTeam(e.target.checked)}
                disabled={!orderNumber}
              />
            }
            label="Assign to Escrow Team"
          />

          {!isValidTransactionAmount && (
            <Typography marginTop={3} marginBottom={3} sx={{ color: "error.main" }}>
              There are insufficient funds for this transaction.
            </Typography>
          )}
        </Stack>

        <Stack direction="row" spacing={1} marginTop={4} justifyContent="flex-end">
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitTransfer} disabled={!isValidTransactionAmount || !orderNumber}>
            {submitting ? (
              <>
                Transferring <CircularProgress size={16} color="inherit" sx={{ marginLeft: 1 }} />
              </>
            ) : (
              "Transfer"
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

import { Box, Card, Grid, TableContainer, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NewRecordable from "./NewRecordable";
import OrderSidebar from "./OrderSidebar";
import RecordableBreadcrumbs from "./RecordableBreadcrumbs";
import RecordableFilters from "./RecordableFilters";
import RecordableHeader from "./RecordableHeader";
import RecordableTable from "./RecordableTable";
import VersionUpload from "./VersionUpload";
import { Recordable, RecordableType, Status } from "./types";
import { copyRecordableInfoToClipboard } from "./utils/recordableUtils";
import { useSnackbar } from "notistack";

export default function Recordables() {
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");

  const { enqueueSnackbar } = useSnackbar();

  const [newRecordableOpen, setNewRecordableOpen] = useState<boolean>(false);
  const handleCloseNewRecordable = () => setNewRecordableOpen(false);
  const handleOpenNewRecordable = () => setNewRecordableOpen(true);
  const [openVersionUpload, setOpenVersionUpload] = useState<boolean>(false);

  const [recordableTypes, setRecordableTypes] = useState<RecordableType[]>([]);
  const [recordables, setRecordables] = useState<Recordable[]>([]);
  const [selectedRecordableType, setSelectedRecordableType] = useState<RecordableType | null>(null);
  const [selectedRecordable, setSelectedRecordable] = useState<Recordable | null>(null);

  const getAndSetRecordableTypes = async () => {
    const { data } = await axios.get(`/api/recordablestracking/recordables/getTypes`);
    setRecordableTypes(data);
  };

  const getAndSetRecordables = async () => {
    const { data } = await axios.get(`/api/recordablestracking/recordables/getByOrderNumber?orderNumber=${order}`);
    setRecordables(data);
  };

  useEffect(() => {
    getAndSetRecordableTypes();
    getAndSetRecordables();
  }, []);

  const filteredRecordables = recordables.filter(
    (r) => (!selectedRecordableType || r.type === selectedRecordableType.id) && !r.isDeleted
  );

  const filteredDeletedDocuments = selectedRecordableType
    ? recordables.filter((r) => r.type === selectedRecordableType.id && r.isDeleted)
    : recordables.filter((r) => r.isDeleted);

  const handleCopy = async (recordables: Recordable[]) => {
    const success = await copyRecordableInfoToClipboard(recordables);

    if (success) {
      enqueueSnackbar("Summary of incomplete items was copied to clipboard. You can now paste it to an email.", {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    } else {
      enqueueSnackbar("There was a problem copying the data. Please try again", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    }
  };

  return (
    <>
      <RecordableHeader handleOpenNewRecordable={handleOpenNewRecordable} />
      <RecordableBreadcrumbs />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TableContainer component={Card}>
            <RecordableFilters
              options={recordableTypes}
              setSelectedRecordableType={setSelectedRecordableType}
              copyToClipboard={() => handleCopy(filteredRecordables)}
            />
            <RecordableTable
              isDeleted={false}
              data={filteredRecordables}
              setSelectedRecordable={setSelectedRecordable}
              setOpenVersionUpload={setOpenVersionUpload}
              getAndSetRecordables={getAndSetRecordables}
            />
            <Box mt={4}>
              <RecordableTable
                isDeleted
                data={filteredDeletedDocuments}
                setSelectedRecordable={setSelectedRecordable}
                setOpenVersionUpload={setOpenVersionUpload}
                getAndSetRecordables={getAndSetRecordables}
              />
            </Box>
            {recordables.length === 0 && <Typography padding={2}>No recordables available</Typography>}
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <OrderSidebar />
        </Grid>
      </Grid>
      {newRecordableOpen && (
        <NewRecordable
          open={newRecordableOpen}
          onClose={handleCloseNewRecordable}
          updateRecordableData={getAndSetRecordables}
        />
      )}
      {openVersionUpload && (
        <VersionUpload
          open={openVersionUpload}
          onClose={() => setOpenVersionUpload(false)}
          updateData={getAndSetRecordables}
          recordableId={selectedRecordable?.id}
          documentType={selectedRecordable?.typeDescription}
          additionalDescription={selectedRecordable?.description}
          newVersionCount={selectedRecordable?.documents?.length ? selectedRecordable?.documents?.length + 1 : 0}
        />
      )}
    </>
  );
}

import Companies from "./Companies";
import { CompanyProvider } from "./CompanyContext";

export default function CompaniesPage() {
  return (
    <CompanyProvider>
      <Companies />
    </CompanyProvider>
  );
}

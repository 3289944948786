import axios from "axios";
import { ReactNode, createContext, useState, useEffect } from "react";
import { InternalUser, InternalUserRole } from "../types/app";

// ----------------------------------------------------------------------
const initialState: UserContextProps = {
  user: null,
  teamMembers: [],
  isFetchingTeamMembers: true
};

declare global {
  interface Window {
    clarity: any;
  }
}

const UserContext = createContext(initialState);

type UserProviderProps = {
  children: ReactNode;
};

export type User = {
  name: string;
  initials: string;
  email: string;
  username: string;
  id: number | null;
  isManager: boolean;
  roles: InternalUserRole[];
  departments: string[];
};

type UserContextProps = {
  user: User | null;
  teamMembers: InternalUser[];
  isFetchingTeamMembers: boolean;
};

const getInitials = (name: string) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let regexArray = [...name.matchAll(rgx)] || [];
  const initials = ((regexArray.shift()?.[1] || "") + (regexArray.pop()?.[1] || "")).toUpperCase();

  return initials;
};

function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [teamMembers, setTeamMembers] = useState<InternalUser[]>([]);
  const [isFetchingTeamMembers, setIsFetchingTeamMembers] = useState(true);

  const getAndSetTeamMembers = async (id: number) => {
    if (!id) return;

    setIsFetchingTeamMembers(true);
    const { data } = await axios.get<InternalUser[]>(
      `/api/clientphonebook/internalusers/getInternalUserAndTeamMembers?id=${id}`
    );

    if (data) {
      setTeamMembers(data);
    }

    setIsFetchingTeamMembers(false);
  };

  const getAndSetUser = async () => {
    const { data } = await axios.post("/api/login/GetUserPersonalInfo");
    const lastName = data.name.split(", ").slice(0, -1).join(" ");
    const firstName = data.name.split(", ").slice(-1).join(" ");
    const name = `${firstName} ${lastName}`;
    const email = data.email.toLowerCase();
    const initials = getInitials(name);
    setUser({
      name,
      initials,
      email,
      username: data.username,
      id: data.id,
      isManager: data.isManager,
      roles: data.roles || [],
      departments: data.departments
    });
    window.clarity("set", "user", `${name}`);

    await getAndSetTeamMembers(data.id);
  };

  useEffect(() => {
    getAndSetUser();
  }, []);

  return <UserContext.Provider value={{ user, teamMembers, isFetchingTeamMembers }}>{children}</UserContext.Provider>;
}


export { UserProvider, UserContext };

import axios from "axios";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { Contact, GetDefaultContact } from "../orderEntry/types/Contact";
import { PropertyInfo } from "../orderEntry/types/Property";
import { SelectUser } from "../orderEntry/types/SelectUser";
import { UserContext } from "./UserContext";
import { logEvent } from "../utils/analyticsLogger";

const roles = ["Attorney", "Title Closer", "Principal", "Funder", "Mortgage Broker"];

interface ContactsContextType {
  contacts: Contact[];
  setContacts: React.Dispatch<React.SetStateAction<Contact[]>>;
  getContacts: (searchText: string) => void;
  selectedContacts: Contact[];
  selectUsers: SelectUser[];
  currentContact: Contact;
  newContact: Contact;
  setCurrentContact: (contact: Contact) => void;
  handleContactCheckbox: (checked: boolean, contact: Contact) => void;
  uncheckAllContacts: () => void;
  editContact: (property: string, value: any) => void;
  isLoading: boolean;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  submitSaveChanges: () => Promise<Contact | undefined>;
  submittedStatus: "success" | "error" | "unsubmitted";
  setSubmittedStatus: Dispatch<SetStateAction<"success" | "error" | "unsubmitted">>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  roles: string[];
  roleFilter: string | null;
  setRoleFilter: (role: string | null) => void;
  setNewContact: React.Dispatch<React.SetStateAction<Contact>>;
  setAllAddressFields: (property: PropertyInfo) => void;
}

const ContactsContext = React.createContext<ContactsContextType>({
  contacts: [],
  setContacts: () => [],
  getContacts: () => null,
  selectedContacts: [],
  selectUsers: [],
  currentContact: GetDefaultContact(),
  newContact: GetDefaultContact(),
  setCurrentContact: (contact) => {},
  handleContactCheckbox: () => {},
  uncheckAllContacts: () => {},
  editContact: () => {},
  isLoading: false,
  isEditMode: false,
  setIsEditMode: () => {},
  submitSaveChanges: () => Promise.resolve(GetDefaultContact()),
  submittedStatus: "unsubmitted",
  setSubmittedStatus: () => {},
  searchText: "",
  setSearchText: () => {},
  roles: [],
  roleFilter: null,
  setRoleFilter: (role) => {},
  setNewContact: () => {},
  setAllAddressFields: () => {}
});

interface ContactsProviderProps {
  children: React.ReactNode;
}

function ContactsProvider({ children }: ContactsProviderProps) {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentContact, setCurrentContact] = useState<Contact>(GetDefaultContact());
  const [contactBefore, setContactBefore] = useState<Contact>();
  const [newContact, setNewContact] = useState<Contact>(GetDefaultContact());
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [selectUsers, setSelectUsers] = useState<SelectUser[]>([]);
  const [roleFilter, setRoleFilter] = useState<string | null>(null);
  const [submittedStatus, setSubmittedStatus] = useState<"success" | "error" | "unsubmitted">("unsubmitted");
  const [searchText, setSearchText] = useState("");
  const { user } = useContext(UserContext);
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    getSelectUsers();
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    uncheckAllContacts();
  }, [contacts]);

  useEffect(() => {
    if (window.location.pathname.includes("/edit")) {
      setIsEditMode(true);
      setContactBefore(currentContact);
    } else {
      setIsEditMode(false);
      setCurrentContact(GetDefaultContact());
    }
  }, [window.location.pathname]);

  async function getContacts(searchText: string) {
    setIsLoading(true);
    if (searchText) {
      const { data } = await axios.get<Contact[]>(`/proxy/api/clients/search?searchQuery=${searchText}`);
      setContacts(data);
    } else {
      setContacts([]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getContacts(debouncedSearchText);
  }, [debouncedSearchText]);

  const getSelectUsers = async () => {
    const { data } = await axios.get<SelectUser[]>(`/proxy/api/clientphonebook/contacts/getwindowsusers`);
    setSelectUsers(data);
  };

  function handleContactCheckbox(checked: boolean, contact: Contact) {
    const newArray = checked ? [...selectedContacts, contact] : selectedContacts.filter((c) => c !== contact);
    setSelectedContacts(newArray);
  }

  const uncheckAllContacts = () => {
    setSelectedContacts([]);
  };

  const editContact = (property: string, value: any) => {
    isEditMode
      ? setCurrentContact({
          ...currentContact,
          id: currentContact?.id || "",
          [property]: value
        })
      : setNewContact({
          ...newContact,
          id: newContact?.id || "",
          [property]: value
        });
  };
  const setAllAddressFields = (property: PropertyInfo) => {
    isEditMode
      ? setCurrentContact({
          ...currentContact,
          address1: property.address,
          address2: property.aptNo,
          city: property.city,
          state: property.state,
          zip: property.zipCode
        })
      : setNewContact({
          ...newContact,
          address1: property.address,
          address2: property.aptNo,
          city: property.city,
          state: property.state,
          zip: property.zipCode
        });
  };

  const submitSaveChanges: () => Promise<Contact | undefined> = async () => {
    setIsLoading(true);
    const contact = isEditMode ? currentContact : newContact;
    Object.keys(contact).map(
      (c) =>
        //@ts-ignore
        (contact[c] =
          //@ts-ignore
          typeof contact[c] == "string" ? contact[c].trim() : contact[c])
    );
    let addedContact = contact;
    try {
      const { data } = await axios.post(`/proxy/api/clientphonebook/contacts/${isEditMode ? "edit" : "add"}`, {
        contact,
        username: user?.username
      });
      if (data) {
        addedContact = data;
      }
      setSubmittedStatus("success");
      logEvent(
        "Client Phonebook",
        isEditMode ? "EditContact" : "AddContact",
        JSON.stringify(isEditMode ? { before: contactBefore, updated: contact } : contact, null, 2)
      );
    } catch {
      setSubmittedStatus("error");
    } finally {
      setIsLoading(false);
      return addedContact;
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        setContacts,
        getContacts,
        selectedContacts,
        selectUsers,
        currentContact,
        newContact,
        setCurrentContact,
        handleContactCheckbox,
        uncheckAllContacts,
        editContact,
        isLoading,
        isEditMode,
        setIsEditMode,
        submitSaveChanges,
        submittedStatus,
        setSubmittedStatus,
        searchText,
        setSearchText,
        roles,
        roleFilter,
        setRoleFilter,
        setNewContact,
        setAllAddressFields
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}

function useContacts() {
  return useContext(ContactsContext);
}

export { ContactsProvider, useContacts };

import { Global, css } from "@emotion/react";
import { useEffect, useState, useRef, useCallback } from "react";
import { Button, Container, Skeleton, Stack } from "@mui/material";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import StyledCalendar from "./StyledCalendar";
import CalendarToolbar from "./CalendarToolbar";
import useDebounce from "../../hooks/useDebounce";
import NewClosingModal from "./NewClosingModal";
import { useCalendar } from "../contexts/CalendarContext";
import CalenderEventContent from "./CalendarEventContent";
import ViewClosingModal from "./ViewClosingModal";
import ConfirmDateDragModal from "./ConfirmDateDragModal";
import { logEvent } from "../utils/analyticsLogger";
import { useSettingsContext } from "../../minimals/components/settings";
import useLocalStorage from "../../minimals/hooks/useLocalStorage";
import Iconify from "../../minimals/components/iconify";
import Page from "../../minimals/components/page";
import { DatesSetArg, EventContentArg, EventDropArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import DotLoader from "../components/DotLoader";
import HeaderBreadcrumbs from "../components/HeaderBreadcrumbs";
import ToastMessage from "../components/ToastMessage";
import { useQueryParam, StringParam, BooleanParam } from "use-query-params";
import { IClosedOrder, ICalendarEvent } from "../types/calendar";
import { getClosedOrder } from "./CalendarActions";

export default function Calendar() {
  const { themeStretch } = useSettingsContext();
  const [orderNumberUrlParam] = useQueryParam("ordernumber", StringParam);
  const [modalModeParam] = useQueryParam("mode", StringParam);
  // const isDesktop = useResponsive('up', 'sm');
  const [isNewClosingModalOpen, setIsNewClosingModalOpen] = useState(false);
  const [isDisplayConfirmDragModal, setIsDisplayConfirmDragModal] = useState(false);
  const [isExpandedView, setIsExpandedView] = useState(false);
  const [isDisplayWeekends, setIsDisplayWeekends] = useLocalStorage<boolean>("isDisplayWeekends", false);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useLocalStorage<string>("calendar-view", "dayGridMonth"); // useState(isDesktop ? 'dayGridMonth' : 'listWeek');
  const [isLoading, setIsLoading] = useState(false);
  const {
    calendarEvents,
    modalOrder,
    getAndSetClosedOrders,
    dateRange,
    setDateRange,
    toastProps,
    openViewOrderModal,
    showToastMessage
  } = useCalendar();
  const debouncedDateRange = useDebounce(`${dateRange.start}-${dateRange.end}`, 500);
  const calendarDragEventData = useRef<any>({});
  const calendarRef = useRef<FullCalendar>(null);
  const startDateRef = useRef("");
  const endDateRef = useRef("");

  const handleDateClick = useCallback((info: any) => {
    if (!info.jsEvent.target.classList.contains("fc-daygrid-day-number")) return;
    gotToDateInListView(info.date);
  }, []);

  const scrollToToday = () => {
    const todayEl = document.querySelector(".fc-day-today");
    //This is not ideal, but I could not find anywhere in the docs that allowed for scrolling to to today
    todayEl?.scrollIntoView(false); ///To have scroll view on top of page set param to true or remove alogether
  };

  const getOrdersWithLoader = () => {
    setIsLoading(true);
    ///Doing this for bug when turning off show weekends on day view when currently selecting day view
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      setDate(calendarApi.getDate());
    }

    getAndSetClosedOrders()
      .then(() => setIsLoading(false))
      .catch((e) => {
        console.log("Error", e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOrdersWithLoader();
  }, [debouncedDateRange]);

  useEffect(() => {
    setTimeout(scrollToToday, 0);

    const handleOrderNumberQueryParam = async () => {
      if (orderNumberUrlParam) {
        const { data }: { data: IClosedOrder } = await getClosedOrder(orderNumberUrlParam);
        if (!data) {
          showToastMessage({
            message: `There is an issue with editing order ${orderNumberUrlParam}`,
            severity: "error"
          });
          return;
        }
        openViewOrderModal({ order: data as ICalendarEvent, mode: modalModeParam == "edit" ? "edit" : "view" });
      }
    };

    handleOrderNumberQueryParam();
  }, []);

  const onEventDrop = async (info: EventDropArg) => {
    calendarDragEventData.current = info;
    setIsDisplayConfirmDragModal(true);
  };

  const handleDateSet = async (args: DatesSetArg) => {
    const { startStr, endStr, start, end } = args;
    if (startStr === startDateRef.current && endStr === endDateRef.current) {
      return;
    }

    startDateRef.current = startStr;
    endDateRef.current = endStr;
    setIsLoading(true);
    setDateRange({ start, end });
  };

  const handleChangeView = (selectedViewOption: {
    id: any;
    value: any;
    label?: string;
    icon?: string;
    isExpanded: any;
  }) => {
    const newView = selectedViewOption.value;
    setIsExpandedView(selectedViewOption.isExpanded || false);
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(selectedViewOption.id);
    }
  };

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleGoToDate = (date: string | Date) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.gotoDate(date);
      calendarApi.select({ start: date });
      setDate(calendarApi.getDate());
    }

    const selectedEl = document.querySelector(".fc-highlight"); //This is not ideal, but I could not find anywhere in the docs that allowed for scrolling to to today
    selectedEl?.scrollIntoView(); ///To have scroll view on top of page set param to true or remove alogether
  };

  const gotToDateInListView = (date: Date) => {
    handleChangeView({
      id: "listWeekOneDay",
      value: "listWeekOneDay",
      label: "Day",
      icon: "ic:round-view-day",
      isExpanded: false
    });
    handleGoToDate(date);
  };

  const handleToggleDisplayWeekends = (isChecked: boolean) => {
    logEvent("Closing Calendar", "Filter 2", `Show weekends ${isChecked}`);
    setIsDisplayWeekends(isChecked);
    // const calendarEl = calendarRef.current;
    // if (calendarEl) {
    // const calendarApi = calendarEl.getApi();
    // setTimeout(() => setDate(calendarApi.getDate()), 50)
    // }
  };

  const renderDayCellContent = (e: any) => {
    if (!isLoading) {
      return <>{e.dayNumberText}</>;
    }

    return (
      <div>
        <Stack spacing={0.5}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={215} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={215} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={215} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={215} />
        </Stack>
      </div>
    );
  };

  return (
    <Page title="Calendar">
      <Global
        styles={css`
          header {
            background-color: transparent !important;
            backdrop-filter: none !important;
          }
          main {
            padding-top: 24px !important;
            padding-bottom: 40px !important;
          }
        `}
      />
      <Container
        maxWidth={themeStretch ? false : "xl"}
        sx={{
          zIndex: 1300,
          paddingLeft: "0px !important",
          paddingRight: "64px !important",
          top: 24,
          position: "relative"
        }}
      >
        <HeaderBreadcrumbs
          sx={{ height: 66 }}
          heading="Calendar"
          subheading={calendarEvents.length ? `${calendarEvents.length} Closings` : ""}
          links={[{ name: "Dashboard", href: "" }, { name: "User", href: "" }, { name: "List" }]}
          action={
            <Button
              variant="contained"
              onClick={() => setIsNewClosingModalOpen(true)}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
            >
              New Closing
            </Button>
          }
        />
      </Container>
      <StyledCalendar sx={{ position: "relative" }}>
        <CalendarToolbar
          date={date}
          view={view}
          onNextDate={handleClickDateNext}
          onPrevDate={handleClickDatePrev}
          onToday={handleClickToday}
          onChangeView={handleChangeView}
          onGoToDate={handleGoToDate}
          isDisplayWeekends={isDisplayWeekends}
          handleToggleDisplayWeekends={handleToggleDisplayWeekends}
        />
        <FullCalendar
          dayCellContent={renderDayCellContent}
          datesSet={handleDateSet}
          showNonCurrentDates={true}
          weekends={isDisplayWeekends}
          editable
          droppable
          selectable
          allDayMaintainDuration
          eventResizableFromStart
          fixedWeekCount={false}
          ref={calendarRef}
          initialDate={date}
          initialView={view}
          dayMaxEventRows={isExpandedView || view !== "dayGridMonth" ? Infinity : 5}
          eventDisplay="list-item"
          headerToolbar={false}
          events={calendarEvents as any}
          eventContent={(eventInfo: EventContentArg) => (
            <CalenderEventContent isLoading={isLoading} eventInfo={eventInfo} view={view} />
          )}
          contentHeight={3000}
          eventMinHeight={100}
          eventDurationEditable={false}
          eventDrop={onEventDrop}
          views={{
            ///For custom views
            dayGridMonthExpanded: {
              type: "dayGridMonth",
              buttonText: "Day Grid Expanded"
            },
            listWeekOneDay: {
              type: "listWeek",
              duration: { days: 1 },
              buttonText: "1 day"
            }
          }}
          noEventsContent={
            isLoading ? (
              <>
                Loading
                <DotLoader />
              </>
            ) : undefined
          }
          dateClick={(info) => handleDateClick(info)}
          // navLinkDayClick
          // dayClick={info => gotToDateInListView(info.date)}
          height={"auto"}
          plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
        />
      </StyledCalendar>
      {Boolean(modalOrder) && <ViewClosingModal getOrdersWithLoader={getOrdersWithLoader} />}
      {isNewClosingModalOpen && <NewClosingModal isOpen={isNewClosingModalOpen} setIsOpen={setIsNewClosingModalOpen} />}
      {isDisplayConfirmDragModal && (
        <ConfirmDateDragModal
          info={calendarDragEventData.current}
          isOpen={isDisplayConfirmDragModal}
          setIsOpen={setIsDisplayConfirmDragModal}
          resetDragEventData={() => (calendarDragEventData.current = {})}
        />
      )}
      {toastProps?.open && <ToastMessage {...toastProps} />}
    </Page>
  );
}

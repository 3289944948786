import { Box, Card, CardContent, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import HeaderTitle from "./HeaderTitle";
import { ContactContext } from "../../contact/ContactContext";
import { useContext } from "react";

interface IProps {
  setEditMode: () => void;
}

export default function AccountReps({ setEditMode }: IProps) {
  const theme = useTheme();

  const { contact } = useContext(ContactContext);
  const nyReps = contact?.contactReps.filter((r) => r?.contactRepTypeId === 2);
  const njReps = contact?.contactReps.filter((r) => r?.contactRepTypeId === 3);
  const nationalReps = contact?.contactReps.filter((r) => r?.contactRepTypeId === 4);

  return (
    <Card>
      <CardHeader
        title={<HeaderTitle title="Account Reps" handleEditClick={setEditMode} isDisabled={!contact?.isActive} />}
      />
      <CardContent>
        <Stack spacing={1.5}>
          {nyReps && nyReps.length > 0 ? (
            nyReps?.map((r) => (
              <Box>
                <Typography variant="body2" display="inline-block" marginRight={1.5}>
                  NY:
                </Typography>
                <Typography display="inline-block" variant="body2">
                  {r?.internalUser?.firstName} {r?.internalUser?.middleName}
                  {r?.internalUser?.lastName}
                </Typography>
              </Box>
            ))
          ) : (
            <Box>
              <Typography variant="body2" display="inline-block" marginRight={1.5}>
                NY:
              </Typography>
              <Typography
                variant="body2"
                display="inline-block"
                color={theme.palette.text.disabled}
                sx={{ fontStyle: "italic" }}
              >
                no account rep selected
              </Typography>
            </Box>
          )}
          {nationalReps && nationalReps.length > 0 ? (
            nationalReps?.map((r) => (
              <Box>
                <Typography variant="body2" display="inline-block" marginRight={1.5}>
                  National:
                </Typography>
                <Typography display="inline-block" variant="body2">
                  {r?.internalUser?.firstName} {r?.internalUser?.middleName}
                  {r?.internalUser?.lastName}
                </Typography>
              </Box>
            ))
          ) : (
            <Box>
              <Typography variant="body2" display="inline-block" marginRight={1.5}>
                National:
              </Typography>
              <Typography
                variant="body2"
                display="inline-block"
                color={theme.palette.text.disabled}
                sx={{ fontStyle: "italic" }}
              >
                no account rep selected
              </Typography>
            </Box>
          )}
          {njReps && njReps.length > 0 ? (
            njReps?.map((r) => (
              <Box>
                <Typography variant="body2" display="inline-block" marginRight={1.5}>
                  NJ:
                </Typography>
                <Typography display="inline-block" variant="body2">
                  {r?.internalUser?.firstName} {r?.internalUser?.middleName}
                  {r?.internalUser?.lastName}
                </Typography>
              </Box>
            ))
          ) : (
            <Box>
              <Typography variant="body2" display="inline-block" marginRight={1.5}>
                NJ:
              </Typography>
              <Typography
                variant="body2"
                display="inline-block"
                color={theme.palette.text.disabled}
                sx={{ fontStyle: "italic" }}
              >
                no account rep selected
              </Typography>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

import { useContext } from "react";
import { SearchesContext } from "../contexts/SearchesContext";
import OrderHeader from "../orderEntry/OrderHeader";
import SearchCard from "./SearchCard";
import PreviewModal from "./Preview";
import OrderPageContainer from "../orderEntry/OrderPageContainer";
// ----------------------------------------------------------------------

export default function OrderSearches() {
  const { searchSections, preview, order } = useContext(SearchesContext);

  return (
    <OrderPageContainer title="Order Searches" activeStep={1}>
      <>
        <OrderHeader order={order} />
        {searchSections.map((s, i) => (
          <SearchCard index={i} />
        ))}
        {preview && preview.previewMode && <PreviewModal />}
      </>
    </OrderPageContainer>
  );
}

import { InternalUserRole } from "../types/app";

export const CONFIGURATIONS = {
  "Post-Closer": {
    sortingOptions: [
      { id: "statusCounts", desc: false },
      { id: "settlementDate", desc: false }
    ],
    filterOptions: [
      { id: "status", value: ["In process", "Closed", "Completed"] },
      { id: "statusCounts", value: ["None Sent/Recorded", "Rejected", "Some Sent/Recorded"] },
      {
        id: "settlementDate",
        value: [new Date(1998, 0, 1, 0, 1, 0), new Date(new Date().setHours(23, 59, 59, 999))]
      }
    ],
    columnOrderOptions: [
      "settlementDate",
      "number",
      "salesPrice",
      "propertyAddress",
      "status",
      "postCloserFullName",
      "statusCounts",
      "heldByFullName",
      "funder"
    ]
  },
  default: {
    sortingOptions: [{ id: "number", desc: false }],
    filterOptions: [{ id: "status", value: ["In process", "Transferred"] }],
    columnOrderOptions: []
  }
};

type ConfigurationKeys = keyof typeof CONFIGURATIONS | "Manager";

export const getConfiguration = (roles?: InternalUserRole[]) => {
  const defaultConfiguration = CONFIGURATIONS["default"];
  if (!roles) return defaultConfiguration;

  const primaryRole = roles.find((r) => r.isPrimaryRole);

  if (!primaryRole) {
    return defaultConfiguration;
  }

  const roleName = primaryRole.internalUserRoleType?.name as ConfigurationKeys;
  const isManager = roleName == "Manager";

  if (!isManager) {
    return CONFIGURATIONS[roleName] || defaultConfiguration;
  }

  const teamName = primaryRole.internalUserTeam?.name;
  if (teamName == "Post Closing") {
    return CONFIGURATIONS["Post-Closer"];
  }

  return defaultConfiguration;
};

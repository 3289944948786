import {useParams} from "react-router-dom";
import {useEffect, useState} from 'react';
import axios from 'axios';
import './index.css';
import {Typography} from "@mui/material";

type MarkedUpCommitmentRequirement = {
  guid: string;
  number: string;
  code: string;
  text: string;
  quickNote: string | null;
  selectInternalId: number;
  status: string;
  subRequirements: MarkedUpCommitmentRequirement[];
};

const statusClassMap: any = {
  'NotYetDone': 'status-not-done-yet',
  'Pending': 'status-pending',
  'Error': 'status-error',
  'Completed': 'status-completed',
  'NA': 'status-na'
}

const Requirement = ({number, code, text, quickNote, subRequirements, status}: MarkedUpCommitmentRequirement) => {
  return <div className="requirement">
    <p>
      <strong>{number} {code}</strong> {text}
    </p>
    <Typography variant="body2" className={statusClassMap[status]} style={{marginTop: '5px'}}>
      <span style={{fontWeight: 'bold'}}>{status === 'NA' ? 'N/A' : status}</span>{' '}
      {quickNote && <em style={{color: 'blue'}}>Quick Note: {quickNote}</em>}
    </Typography>

    {subRequirements && subRequirements.length > 0 && (
        <div className="sub-requirements">
          {subRequirements.map((subReq: MarkedUpCommitmentRequirement) => (
              <Requirement key={subReq.guid} {...subReq} />
          ))}
        </div>
    )}
  </div>
};

const RequirementsList = ({requirements}: { requirements: MarkedUpCommitmentRequirement[] }) => (
    <div id="printable-content" className="requirements-list">
      <div className="header">
        <h1>SCHEDULE B, PART I</h1>
        <h2>REQUIREMENTS</h2>
      </div>
      {requirements.map((requirement: MarkedUpCommitmentRequirement) => (
          <Requirement key={requirement.guid} {...requirement} />
      ))}
    </div>
);

const MarkedUpCommitment = () => {
  const {orderNumber} = useParams();
  const [requirements, setRequirements] = useState<MarkedUpCommitmentRequirement[]>([]);

  useEffect(() => {
    (async () => {
      const {data} = await axios.get(`/api/taskmanagement/closingchecklist/GetMarkedupCommitmentRequirements?ordernumber=${orderNumber}`);
      setRequirements(data);
    })();
  }, []);

  return (
      <div id="printable-content">
        <RequirementsList requirements={requirements}/>
      </div>
  );
}

export default MarkedUpCommitment;
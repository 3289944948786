import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router";
import { useState } from "react";

type Props = {
  identifier: string | undefined;
  orderNumber: string | undefined;
  orderType: string | undefined;
  didFileUploadSucceed: boolean;
};

export default function OrderSuccess({ orderNumber, orderType, didFileUploadSucceed }: Props) {
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);
  const navigate = useNavigate();
  return (
    <Card sx={{ marginBottom: 4, padding: 4 }}>
      <Grid container marginTop={4}>
        <Grid item xs={0.8}>
          <CheckCircleIcon color="success" />
        </Grid>
        <Grid item xs={11.2}>
          <Typography variant="body1" color="GrayText" sx={{ position: "relative" }}>
            Your order has been submitted as{" "}
            <Tooltip title={copiedToClipBoard ? "Copied" : "Click to copy"}>
              <Typography
                sx={{ display: "inline-block", cursor: "pointer" }}
                variant="subtitle1"
                fontWeight={700}
                onClick={() => {
                  navigator.clipboard.writeText(orderNumber || "");
                  setCopiedToClipBoard(true);
                }}
              >
                {orderNumber}
              </Typography>
            </Tooltip>
            , click Next, to order search.
          </Typography>
          {!didFileUploadSucceed && (
            <Typography variant="subtitle1" color="error.main" sx={{ position: "relative", marginTop: 1 }}>
              Please note that while your order was created successfuly, there was an error saving the documents you
              uploaded to the Home Page.
            </Typography>
          )}
          <Typography variant="subtitle2" color="GrayText">
            {orderType}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button variant="contained" color="primary" onClick={() => navigate(`/searches/${orderNumber}`)}>
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { State } from "../types";
import { useState } from "react";
import axios from "axios";

const AddCountyDialog = ({
  states,
  defaultState,
  onClose
}: {
  states: State[];
  defaultState: State;
  onClose: () => void;
}) => {
  const [selectedState, setSelectedState] = useState<State>(defaultState);
  const [countyName, setCountyName] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submit = async () => {
    setSubmitting(true);
    await axios.post("/api/recordablestracking/managetasks/addcounty", { name: countyName, stateId: selectedState.id });
    onClose();
  };

  return (
    <Dialog open={true} fullWidth>
      <DialogContent sx={{ padding: 4 }}>
        <Typography variant="h6" color="primary" marginBottom={3}>
          Add County
        </Typography>
        <Stack spacing={2}>
          <Autocomplete
            size="small"
            sx={{ width: 300 }}
            options={states}
            renderInput={(params) => <TextField {...params} label="State" />}
            getOptionLabel={(option) => option.abbreviation}
            onChange={(e, val) => setSelectedState(val!)}
            value={selectedState}
          />
          <TextField
            value={countyName}
            onChange={(e) => setCountyName(e.target.value)}
            placeholder="County Name"
            rows={2}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={submit}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCountyDialog;

import { useContext } from "react";
import axios from "axios";
import { prependUserToSelectNote } from "../../utils/prependUserToSelectNote";

export const saveEscrowLedgerNote = async (ledgerName: string, note: string, user: string) => {
  const noteWithPrependedUser = prependUserToSelectNote(note, user);

  await axios.post("/proxy/api/ledgers/addLedgerNote", {
    ledgerName,
    note: noteWithPrependedUser
  });
};

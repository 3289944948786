import { Box, IconButton, Link } from "@mui/material";
import Iconify from "../../minimals/components/iconify";

export default function DownloadFileButton({ fileId }: { fileId: number }) {
  return (
    <Box>
      <Link href={`https://internal.mcres.com/opendoc/download?id=${fileId}`} underline="none">
        <IconButton>
          <Iconify icon="eva:download-fill" />
        </IconButton>
      </Link>
    </Box>
  );
}

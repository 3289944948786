
import { Grid, ToggleButton, ToggleButtonGroup, Card } from "@mui/material";
import { useState } from "react";
import EntityContactInfo from "./EntityContactInfo";
import IndividualContactInfo from "./IndividualContactInfo";
import { BlacklistNameEntity, BlacklistNameIndividual } from "./types";

interface Props {
  individualInfo: BlacklistNameIndividual;
  setIndividualInfo: (e: any, value: any) => void;
  entityInfo: BlacklistNameEntity;
  changeEntityInfo: (e: any, value: any) => void;
  isIndividual: boolean;
  setIsIndividual:(value: boolean) => void;
}
export default function ContactInfo(props: Props) {

  return (
    <Card sx={{ p: 3, maxWidth: 760, marginTop: 4 }}>
      <Grid alignItems="center">
        <ToggleButtonGroup size="small" color="primary">
          <ToggleButton
            value="Individual"
            onClick={() => {
              props.setIsIndividual(true);
            }}
            selected={props.isIndividual}
          >
            Individual
          </ToggleButton>
          <ToggleButton
            value="Entity"
            onClick={() => {
              props.setIsIndividual(false);
            }}
            selected={!props.isIndividual}
          >
            Entity
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {!props.isIndividual ? (
        <EntityContactInfo entityInfo={props.entityInfo} changeEntityInfo={props.changeEntityInfo} />
      ) : (
        <IndividualContactInfo individualInfo={props.individualInfo} setIndividualInfo={props.setIndividualInfo} />
      )}
    </Card>
  );
}

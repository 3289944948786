import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import { useContext } from "react";
import { ConfirmationContext } from "../contexts/ConfirmationContext";

export default function IncludedAttachments() {
  const { includeWireInstructions, includeEscrowAgreement, setIncludeWireInstructions, setIncludeEscrowAgreement } =
    useContext(ConfirmationContext);
  return (
    <Box paddingLeft={3}>
      <FormLabel component="legend">Included attachments</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={includeWireInstructions}
              onChange={() => setIncludeWireInstructions(!includeWireInstructions)}
            />
          }
          label="Wire instructions"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={includeEscrowAgreement}
              onChange={() => setIncludeEscrowAgreement(!includeEscrowAgreement)}
            />
          }
          label="Escrow Agreement - Deposit"
        />
      </FormGroup>
    </Box>
  );
}

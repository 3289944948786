import { Box, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Recordable, TaskItem, RecordableStatusColorMap } from "./types";
import Iconify from "../../minimals/components/iconify";
import FileIcon from "./components/FileIcon";
import LinearProgressWithLabel from "../shared/LinearProgressWithLabel";
import { useNavigate, useSearchParams } from "react-router-dom";
import VersionCount from "./VersionCount";
import OpenFileButton from "./OpenFileButton";
import UploadButton from "./UploadButton";
import { useSnackbar } from "../../minimals/components/snackbar";
import { copyRecordableInfoToClipboard, getRecordableDescriptionAndTitle } from "./utils/recordableUtils";
import DownloadFileButton from "./DownloadFileButton";

interface IProps {
  recordable: Recordable;
  tasks: TaskItem[];
  setOpenVersionUpload: (open: boolean) => void;
  showTaskFilters: boolean;
  toggleShowTaskFilters: () => void;
}

export default function OrderInfo({
  recordable,
  setOpenVersionUpload,
  showTaskFilters,
  toggleShowTaskFilters
}: IProps) {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const order = searchParams.get("order");
  const navigate = useNavigate();

  const navigateToRecordablesPage = () => {
    navigate(`/recordables/?order=${order}`);
  };

  const totalTasksCount = recordable.taskItems.filter((t) => t.status !== 3).length;
  const completedTasksCount = recordable.taskItems.filter((t) => t.status === 2).length;
  const descriptionAndTitle = getRecordableDescriptionAndTitle(recordable);

  const handleCopy = async (recordables: Recordable[]) => {
    const success = await copyRecordableInfoToClipboard(recordables);

    if (success) {
      enqueueSnackbar("Summary of incomplete items was copied to clipboard. You can now paste it to an email.", {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    } else {
      enqueueSnackbar("There was a problem copying the data. Please try again", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    }
  };

  return (
    <>
      <Stack direction="row" spacing={18} padding={3} alignItems="center">
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <IconButton onClick={navigateToRecordablesPage}>
                <Iconify icon="eva:arrow-back-fill" />
              </IconButton>
            </Box>
            <Stack direction="row" spacing={2}>
              <FileIcon />
              <Box>
                <Typography variant="subtitle2">{descriptionAndTitle}</Typography>
                <Tooltip disableFocusListener title="Add">
                  <VersionCount
                    versionCount={recordable.documents.length}
                    documents={recordable.documents}
                    openVersionUpload={() => setOpenVersionUpload(true)}
                  />
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </Box>
        <LinearProgressWithLabel totalTasks={totalTasksCount} completedTasks={completedTasksCount} />
        {recordable.statusDescription && (
          <Chip
            variant="soft"
            label={recordable.statusDescription}
            color={RecordableStatusColorMap[recordable.statusDescription]}
          />
        )}
        <Stack direction="row" spacing={2}>
          <UploadButton setOpenVersionUpload={() => setOpenVersionUpload(true)} />
          <DownloadFileButton fileId={recordable.documents[0].momentumDocumentId} />

          <OpenFileButton
            href={`https://internal.mcres.com/opendoc?id=${recordable.documents[0].momentumDocumentId}`}
          />
          <IconButton onClick={toggleShowTaskFilters}>
            <Iconify icon={showTaskFilters ? "ic:outline-filter-list" : "ic:outline-filter-list-off"} />
          </IconButton>
          <IconButton onClick={() => handleCopy([recordable])}>
            <Iconify icon="codicon:combine" />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Alert, Link, Dialog, DialogActions, DialogContent, DialogContentText, Stack, Typography } from "@mui/material";
import axios from "axios";
import { RecordableStatusColorHexMap, RecordabledocumentStatusDescription, Status } from "./types";

export default function RecordableActions({
  navigateToTasks,
  momentumDocumentId,
  setOpenVersionUpload,
  getAndSetRecordables,
  recordableId,
  isDeleted,
  statusTypes
}: {
  navigateToTasks: () => void;
  momentumDocumentId: number;
  setOpenVersionUpload: () => void;
  getAndSetRecordables: () => Promise<void>;
  recordableId: number;
  isDeleted: boolean | undefined;
  statusTypes: Status[];
}) {
  const [deleteDocumentId, setDeleteDocumentId] = React.useState<number>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteDocument = async (recordableId: number, isDeleted: boolean) => {
    await axios.post(`/api/recordablestracking/recordables/UpdateDeletedDocumentStatus`, { recordableId, isDeleted });
    getAndSetRecordables();
    setDeleteDocumentId(undefined);
  };

  const handleStatusChange = async (status: string) => {
    await axios.post(`/api/recordablestracking/recordables/updateRecordableStatus`, { recordableId, status });
    handleClose();
    getAndSetRecordables();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="inherit"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {statusTypes.map((t) => (
          <MenuItem disableRipple onClick={() => handleStatusChange(t.description)}>
            Mark as{" "}
            <Typography
              sx={{
                color: RecordableStatusColorHexMap[t.description as RecordabledocumentStatusDescription],
                marginLeft: 1
              }}
            >
              "{t.description}"
            </Typography>
          </MenuItem>
        ))}
        <MenuItem onClick={navigateToTasks} disableRipple>
          Review Tasks
        </MenuItem>
        <Link
          underline="none"
          color="inherit"
          href={`https://internal.mcres.com/opendoc?id=${momentumDocumentId}`}
          target="_blank"
        >
          <MenuItem onClick={handleClose} disableRipple>
            View Document
          </MenuItem>
        </Link>
        <MenuItem onClick={() => setDeleteDocumentId(recordableId)} disableRipple>
          {isDeleted ? "Undelete Document" : "Delete Document"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenVersionUpload();
          }}
          disableRipple
        >
          Upload New Version
        </MenuItem>
      </Menu>

      <Dialog open={!!(deleteDocumentId && deleteDocumentId > 0)} onClose={() => setDeleteDocumentId(undefined)}>
        <DialogContent sx={{ padding: "24px" }}>
          <DialogContentText sx={{ textAlign: "center" }}>
            {`Are you sure you want to ${!isDeleted ? "delete" : " undelete"} this document?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setDeleteDocumentId(undefined);
              setAnchorEl(null);
            }}
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              handleDeleteDocument(recordableId, isDeleted ? false : true);
              setAnchorEl(null);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface IProps {
  handleClose: () => void;
  handleNo: () => void;
}

export default function RoleAlert({ handleClose, handleNo }: IProps) {
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>A role is very important</DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={2}>
          Please try to find the individual on the company website, LinkedIn&nbsp;or&nbsp;Google.
        </DialogContentText>
        <DialogContentText>Were you able to find a role by trying the above?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleNo}>
          No
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { includes } from "lodash";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "../../minimals/hooks/useLocalStorage";
import { IOption } from "../@types/app";
import { mapClosedOrdersToCalenderEvents, propertyTypeOptions, stateOptions } from "../calendar/CalendarUtils";
import { getClosedOrdersByDateRange } from "../calendar/closings/actions/closings";
import { IToastProps } from "../types/app";
import { IAssignedToOption, ICalendarEvent, IClosedOrder, IViewType } from "../types/calendar";
import { checkIsPassAssignedToFilter } from "../utils/validation";
// import { getClosedOrdersByDateRange } from '../actions/closings';

type TViewOrderMode = "view" | "edit";
interface CalendarContextType {
  closedOrders: IClosedOrder[];
  setClosedOrders: React.Dispatch<React.SetStateAction<IClosedOrder[]>>;
  calendarEvents: ICalendarEvent[];
  setCalendarEvents: React.Dispatch<React.SetStateAction<ICalendarEvent[]>>;
  propertyTypeOptions: IOption[];
  statusOptions: IOption[];
  stateOptions: IOption[];
  selectedPropertyType: string;
  setSelectedPropertyType: React.Dispatch<React.SetStateAction<string>>;
  selectedStatuses: IOption[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<IOption[]>>;
  selectedStates: IOption[];
  setSelectedStates: React.Dispatch<React.SetStateAction<IOption[]>>;
  selectedAccountReps: IAssignedToOption[];
  setSelectedAccountReps: React.Dispatch<React.SetStateAction<IAssignedToOption[]>>;
  selectedSalesReps: IOption[];
  setSelectedSalesReps: React.Dispatch<React.SetStateAction<IOption[]>>;
  updateFilteredCalendarEvents: (closedOrders: IClosedOrder[]) => void;
  viewMode: IViewType;
  setViewMode: React.Dispatch<React.SetStateAction<IViewType>>;
  openViewOrderModal: ({
    order,
    mode
  }: {
    order: React.SetStateAction<ICalendarEvent | null>;
    mode?: TViewOrderMode | undefined;
  }) => void;
  modalOrder: ICalendarEvent | null;
  setModalOrder: React.Dispatch<React.SetStateAction<ICalendarEvent | null>>;
  viewOrderMode: TViewOrderMode;
  setViewOrderMode: React.Dispatch<React.SetStateAction<TViewOrderMode>>;
  getAndSetClosedOrders: () => Promise<void>;
  getClosedOrdersByDateRange: ({ start, end }: { start: Date | null; end: Date | null }) => Promise<IClosedOrder[]>;
  dateRange: { start: Date | null; end: Date | null };
  setDateRange: React.Dispatch<React.SetStateAction<{ start: Date | null; end: Date | null }>>;
  toastProps: IToastProps;
  showToastMessage: (data: IToastProps) => void;
  addCurrentFiltersToSavedFilterList: (name: string) => void;
  removeFromSavedFilterList: (name: string) => void;
  restoreSavedFilters: (name: string) => void;
  savedFilterList: { [id: string]: ISavedFiltersList };
  selectedSavedFilterPreset: string;
  setSelectedSavedFilterPreset: React.Dispatch<React.SetStateAction<string>>;
  clearSelectedPresetFilter: () => void;
}

interface ISavedFiltersList {
  selectedPropertyType: string;
  selectedAccountReps: IAssignedToOption[];
  selectedSalesReps: IOption[];
  selectedStates: IOption[];
  selectedStatuses: IOption[];
}

const CalendarContext = createContext<CalendarContextType>({} as CalendarContextType);
function CalendarProvider({ children }: { children: ReactNode }) {
  const [closedOrders, setClosedOrders] = useState<IClosedOrder[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: null, end: null });

  //// Selected Filters
  const [selectedPropertyType, setSelectedPropertyType] = useLocalStorage<string>("storedPropertyType", "");
  const [selectedStatuses, setSelectedStatuses] = useLocalStorage<IOption[]>("storedStatuses", []);
  const [selectedStates, setSelectedStates] = useLocalStorage<IOption[]>("storedStates", []);
  const [selectedAccountReps, setSelectedAccountReps] = useLocalStorage<IAssignedToOption[]>("storedAccountReps", []);
  const [selectedSalesReps, setSelectedSalesReps] = useLocalStorage<string[]>("storedSalesReps", []);
  const [savedFilterList, setSavedFilterList] = useLocalStorage<{ [id: string]: ISavedFiltersList }>(
    "savedFilters",
    {}
  );
  const [selectedSavedFilterPreset, setSelectedSavedFilterPreset] = useState<string>("");
  const clearSelectedPresetFilter = () => setSelectedSavedFilterPreset("");

  const addCurrentFiltersToSavedFilterList = (name: string) => {
    setSavedFilterList({
      ...savedFilterList,
      [name]: {
        selectedPropertyType,
        selectedAccountReps,
        selectedSalesReps,
        selectedStates,
        selectedStatuses
      }
    });
  };

  const removeFromSavedFilterList = (name: string) => {
    let { [name]: omit, ...rest } = savedFilterList;
    return setSavedFilterList({ ...rest });
  };

  const restoreSavedFilters = (name: string) => {
    const savedFilters = savedFilterList[name] || {};
    const {
      selectedPropertyType = "",
      selectedAccountReps = [],
      selectedSalesReps = [],
      selectedStates = [],
      selectedStatuses = []
    } = savedFilters;
    setSelectedPropertyType(selectedPropertyType);
    setSelectedAccountReps(selectedAccountReps);
    setSelectedSalesReps(selectedSalesReps);
    setSelectedStates(selectedStates);
    setSelectedStatuses(selectedStatuses);
  };

  const [viewMode, setViewMode] = useLocalStorage<IViewType>("storedViewMode", "rep");
  const [modalOrder, setModalOrder] = useState<ICalendarEvent | null>(null);
  const [viewOrderMode, setViewOrderMode] = useState<TViewOrderMode>("view");

  const initialToastState: IToastProps = {
    open: false,
    onClose: () => setToastProps(initialToastState),
    message: "",
    autoHideDuration: 5000,
    severity: "warning",
    anchorOrigin: { vertical: "top", horizontal: "center" }
  };
  const [toastProps, setToastProps] = useState<IToastProps>(initialToastState);

  useEffect(() => {
    updateFilteredCalendarEvents(closedOrders);
  }, [selectedStates, selectedPropertyType, selectedStatuses, selectedAccountReps, selectedSalesReps]);

  useEffect(() => {
    if (!modalOrder) {
      setViewOrderMode("view");
    }
  }, [modalOrder]);

  const openViewOrderModal = ({
    order,
    mode = "view"
  }: {
    order: React.SetStateAction<ICalendarEvent | null>;
    mode?: TViewOrderMode;
  }) => {
    setModalOrder(order);
    setViewOrderMode(mode);
  };

  const updateFilteredCalendarEvents = (closedOrders: IClosedOrder[]) => {
    const selectedStatusValues = selectedStatuses.map((status: { value: any }) => status.value);
    const selectedStateValues = selectedStates.map((state: { value: any }) => state.value);
    ////The value of reps is lookup code to ensure unique keys but the closed order only has names so we need to filter by sring names which is the label
    const selectedAccountRepsEmails = selectedAccountReps
      .map((rep: { email: any }) => rep.email || "")
      .filter((rep: any) => rep);
    const isAnyFundersSelected =
      selectedAccountReps.findIndex((option: { value: string }) => option.value == "anyfunders") !== -1;
    const isNoCloserSelected =
      selectedAccountReps.findIndex((option: { value: string }) => option.value == "noclosersassigned") !== -1;
    const isNoFunderSelected =
      selectedAccountReps.findIndex((option: { value: string }) => option.value == "nofundersassigned") !== -1;

    const filteredClosedOrders = closedOrders.filter((order) => {
      const containsNationalFilter = includes(selectedStateValues, "NATIONAL");
      const isPassStateFilter =
        selectedStates.length == 0 ||
        (containsNationalFilter && order.state !== "NY") ||
        includes(selectedStateValues, order.state);
      const isPassTypeFilter = !selectedPropertyType || selectedPropertyType == order.propertyType;
      const isPassStatusFilter = selectedStatuses.length == 0 || includes(selectedStatusValues, order.orderStatus);
      const isPassAccountRepFilter = checkIsPassAssignedToFilter({
        order,
        selectedAccountReps,
        selectedAccountRepsEmails,
        isAnyFundersSelected,
        isNoCloserSelected,
        isNoFunderSelected
      });
      const isPassSalesRepFilter =
        selectedSalesReps.length == 0 || selectedSalesReps.some((r: IOption) => r.label === order.salesRep);
      return (
        isPassTypeFilter && isPassStatusFilter && isPassStateFilter && isPassAccountRepFilter && isPassSalesRepFilter
      );
    });
    setCalendarEvents(mapClosedOrdersToCalenderEvents(filteredClosedOrders));
  };

  const getAndSetClosedOrders = async () => {
    const { start, end } = dateRange;
    const data = await getClosedOrdersByDateRange({ start, end });
    setClosedOrders(data);
    updateFilteredCalendarEvents(data);
  };

  const showToastMessage = ({ message = "", severity, autoHideDuration = 5000 }: IToastProps) => {
    setToastProps((toastProps) => ({
      ...toastProps,
      open: true,
      message,
      severity,
      autoHideDuration
    }));
  };

  return (
    <CalendarContext.Provider
      value={{
        propertyTypeOptions,
        statusOptions: [],
        stateOptions,
        selectedPropertyType,
        setSelectedPropertyType,
        setSelectedAccountReps,
        setSelectedSalesReps,
        selectedStatuses,
        setSelectedStatuses,
        selectedStates,
        setSelectedStates,
        selectedAccountReps,
        selectedSalesReps,
        closedOrders,
        setClosedOrders,
        calendarEvents,
        setCalendarEvents,
        updateFilteredCalendarEvents,
        viewMode,
        setViewMode,
        modalOrder,
        setModalOrder,
        getClosedOrdersByDateRange,
        getAndSetClosedOrders,
        dateRange,
        setDateRange,
        viewOrderMode,
        setViewOrderMode,
        openViewOrderModal,
        toastProps,
        showToastMessage,
        addCurrentFiltersToSavedFilterList,
        removeFromSavedFilterList,
        restoreSavedFilters,
        savedFilterList,
        selectedSavedFilterPreset,
        setSelectedSavedFilterPreset,
        clearSelectedPresetFilter
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

const useCalendar = () => useContext(CalendarContext);

export { CalendarProvider, useCalendar };

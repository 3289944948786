import { Button } from "@mui/material";
import { ReactChild, ReactFragment, ReactPortal, useContext } from "react";
import { ConfirmationContext } from "../contexts/ConfirmationContext";

type Prop = {
  label: string;
};

export default function SendViaEmailButton(prop: Prop) {
  const { label } = prop;
  const { onSendViaEmailClick, confirmationPdf, submitting } = useContext(ConfirmationContext);
  return (
    <Button variant="outlined" disabled={!confirmationPdf || submitting} onClick={onSendViaEmailClick}>
      {submitting ? "Submitting..." : label}
    </Button>
  );
}

import { useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography
} from "@mui/material";
import MatchedOrderAddress, { Property } from "./MatchedOrderAddress";
import { OrderContext } from "../../contexts/OrderDataContext";
import MatchedAttorney from "./MatchedAttorney";
import MatchedParty from "./MatchedParty";
import { Buyer, MatchedOrder } from "./MatchedOrder";
import { MatchedOldOrder } from "./MatchedOldOrder";
import MatchedPriorPolicy from "./MatchedPriorPolicy";
import MatchedPrincipal from "./MatchedPrincipal";
import Iconify from "../../../minimals/components/iconify";

type OrderAccordianProp = {
  order?: MatchedOrder;
  matchedOldOrder?: MatchedOldOrder;
  imported: boolean;
  relatedOrdersElement: HTMLElement | null;
  isOldOrder: boolean;
  expanded: boolean;
};
export function OrderAccordian(orderProps: OrderAccordianProp) {
  const { relatedOrders, setRelatedOrders } = useContext(OrderContext);

  const { order, matchedOldOrder, imported, relatedOrdersElement, expanded } = orderProps;
  if (order) {
    return (
      <Accordion
        defaultExpanded={expanded}
        key={order.identifier}
        sx={{
          "&:before": {
            backgroundColor: "transparent"
          }
        }}
      >
        <AccordionSummary
          expandIcon={<Iconify icon={`eva:arrow-ios-downward-fill`} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <Box sx={{ fontWeight: 600, display: "inline-block" }}>
                  {order.titleCode && `${order.titleCode.toUpperCase()}-`}
                  {order.number}
                </Box>
              </Typography>
              <Typography variant="caption">{order.transactionType}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box paddingBottom={2}>
            <Grid container marginBottom={2}>
              <Grid item xs={11}>
                <Typography color="primary.main">
                  {imported && <Iconify fontSize={16} color="success.main" icon={"eva:checkmark-fill"} />}
                  <Link
                    onClick={() => {
                      !imported &&
                        setRelatedOrders(
                          relatedOrders[0].fileNumber
                            ? [
                                ...relatedOrders,
                                {
                                  fileNumber: order.number,
                                  fileNumberList: [],
                                  notes: null
                                }
                              ]
                            : [
                                {
                                  fileNumber: order.number,
                                  fileNumberList: [],
                                  notes: null
                                }
                              ]
                        );
                      relatedOrdersElement?.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                      });
                    }}
                    sx={{ cursor: "pointer" }}
                    underline={imported ? "none" : "always"}
                  >
                    {imported ? "Imported" : "Import"}
                  </Link>{" "}
                  {imported ? "as related order" : 'into "Related"'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    onClick={() =>
                      window.open(`https://internal.mcres.com/new-home/${order.number}`, "_blank")?.focus()
                    }
                    size="small"
                  >
                    <Iconify icon={"eva:external-link-fill"} />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <MatchedOrderAddress order={order} />
            {order.principal?.name && <MatchedPrincipal principal={order.principal} />}
            {order.buyers?.attorney?.name && <MatchedAttorney contact={order.buyers.attorney} />}
            {order.buyers?.buyers.map((buyer) => (
              <MatchedParty party={buyer} />
            ))}
            {order.priorPolicy && <MatchedPriorPolicy priorPolicy={order.priorPolicy} orderNumber={order.number} />}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  } else if (matchedOldOrder) {
    return (
      <Accordion
        key={matchedOldOrder.orderNumber + matchedOldOrder.orderDate}
        defaultExpanded={expanded}
        sx={{
          "&:before": {
            backgroundColor: "transparent"
          }
        }}
      >
        <AccordionSummary
          expandIcon={<Iconify icon={`eva:arrow-ios-downward-fill`} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <Box sx={{ fontWeight: 600, display: "inline-block" }}>{matchedOldOrder.orderNumber.toUpperCase()}</Box>
              </Typography>
              <Typography variant="caption">Old Order</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box paddingBottom={2}>
            <Grid container marginBottom={2}>
              <Grid item xs={11}>
                <Typography color="primary.main">
                  {imported && <Iconify fontSize={16} color="success.main" icon={"eva:checkmark-fill"} />}
                  <Link
                    onClick={() => {
                      !imported &&
                        setRelatedOrders(
                          relatedOrders[0].fileNumber
                            ? [
                                ...relatedOrders,
                                {
                                  fileNumber: matchedOldOrder.orderNumber,
                                  fileNumberList: [],
                                  notes: null
                                }
                              ]
                            : [
                                {
                                  fileNumber: matchedOldOrder.orderNumber,
                                  fileNumberList: [],
                                  notes: null
                                }
                              ]
                        );
                      relatedOrdersElement?.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                      });
                    }}
                    sx={{ cursor: "pointer" }}
                    underline={imported ? "none" : "always"}
                  >
                    {imported ? "Imported" : "Import"}
                  </Link>{" "}
                  {imported ? "as related order" : 'into "Related"'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    onClick={() =>
                      window
                        .open(`https://internal.mcres.com/new-home/${matchedOldOrder.orderNumber}`, "_blank")
                        ?.focus()
                    }
                    size="small"
                  >
                    <Iconify icon={"eva:external-link-fill"} />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <Property
              address1={matchedOldOrder.address}
              address2={""}
              city={matchedOldOrder.city}
              state={matchedOldOrder.state}
              zip={matchedOldOrder.zip}
              county={""}
              propertyUse={""}
              section={""}
              block={""}
              lots={[]}
              parcelIds={[]}
              legalDescription={matchedOldOrder.legalDescription}
            />
            {matchedOldOrder.buyer && (
              <MatchedParty
                isOldOrder
                party={
                  {
                    individual: {
                      firstName: matchedOldOrder.buyer
                    }
                  } as Buyer
                }
              />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return <></>;
  }
}

import { Container } from "@mui/material";
import { CalendarProvider } from "../contexts/CalendarContext";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import Main from "./index";

export default function CalendarPage() {
  const { themeStretch } = useSettingsContext();
  return (
    <CalendarProvider>
      <Page title="Closing Calendar">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Main />
        </Container>
      </Page>
    </CalendarProvider>
  );
}

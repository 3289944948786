import { Breadcrumbs, Link } from "@mui/material";

interface IProps {
  recordableType: string;
}

export default function TaskBreadcrumbs({ recordableType }: IProps) {
  return (
    <Breadcrumbs separator="•" aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
      <Link color="inherit">Orders</Link>
      <Link color="inherit">Recordables</Link>
      <Link color="text.primary">{recordableType}</Link>
    </Breadcrumbs>
  );
}

import Iconify from "../../../minimals/components/iconify";
import Profile from "./profile/Profile";
import Company from "./company/Company";
import Associates from "./associates/Associates";
import Orders from "../shared/ordersAndInvoices/OrdersAndInvoices";
import History from "../shared/history/History";
import Preferences from "../shared/preferences/Preferences";
import ComingSoon from "../shared/ComingSoon";
import { HistoryType } from "../types";

// ----------------------------------------------------------------------
export const TABS = [
  {
    values: ["profile"],
    label: "Profile",
    icon: <Iconify icon="ic:round-account-box" />,
    component: <Profile />
  },
  {
    values: ["colleagues"],
    label: "Colleagues",
    icon: <Iconify icon="ic:baseline-account-balance" />,
    component: <Company />
  },
  // {
  //   values: ["connections-represents", "connections-represented-by"],
  //   label: "Connections",
  //   icon: <Iconify icon="eva:people-fill" />,
  //   component: <ComingSoon />
  //   // component: <Associates />
  // },
  {
    values: ["orders", "invoices"],
    label: "Orders",
    icon: <Iconify icon="eva:folder-fill" />,
    // component: <ComingSoon />
    component: <Orders type={"contact"} />
  },
  {
    values: ["history"],
    label: "History",
    icon: <Iconify icon="ic:baseline-history" />,
    component: <History type={HistoryType.CONTACT} />
  },
  {
    values: ["preferences"],
    label: "Preferences",
    icon: <Iconify icon="eva:settings-2-outline" />,
    component: <Preferences />
  }
];

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "@mui/material";

export default function CorpDocActions({
  navigateToTasks,
  momentumDocumentId,
  setOpenVersionUpload
}: {
  navigateToTasks: () => void;
  momentumDocumentId: number;
  setOpenVersionUpload: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="inherit"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={navigateToTasks} disableRipple>
          Review Tasks
        </MenuItem>
        <Link
          underline="none"
          color="inherit"
          href={`https://internal.mcres.com/opendoc?id=${momentumDocumentId}`}
          target="_blank"
        >
          <MenuItem onClick={handleClose} disableRipple>
            View Document
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenVersionUpload();
          }}
          disableRipple
        >
          Upload New Version
        </MenuItem>
      </Menu>
    </div>
  );
}

import { TableCell, Select, MenuItem } from "@mui/material";
import { fCurrency } from "../utils/formatNumber";
import { fMonthDayYear, fTime } from "../utils/formatTime";

export const customColumns = [
  {
    name: "fullOrderNumber",
    label: "Order Number",
    options: { filter: true, sort: true }
  },
  {
    name: "orderStatus",
    label: "Status",
    options: { filter: true, sort: true }
  },
  {
    name: "clientName",
    label: "Client Name",
    options: { filter: true, sort: true }
  },
  {
    name: "settlementDate",
    label: "Settlement Date",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => (value ? fMonthDayYear(value) : "")
    }
  },
  {
    name: "settlementDate",
    label: "Settlement Time",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => (value ? fTime(value) : "")
    }
  },
  {
    name: "propertyType",
    label: "Property Type",
    options: {
      filter: true,
      sort: true
      // customHeadRender: ({ index, ...column }: any) => {
      //     return (
      //         <TableCell key={index}>
      //             {column.label}
      //             <div>
      //                 <Select>
      //                     <MenuItem>-All-</MenuItem>
      //                     <MenuItem>Commercial</MenuItem>
      //                     <MenuItem>Residential</MenuItem>
      //                 </Select>
      //             </div>
      //         </TableCell>

      //     )
      // }
    }
  },
  {
    name: "titleCloser",
    label: "Title Closer",
    options: { filter: true, sort: true }
  },
  {
    name: "principal",
    label: "Principal",
    options: { filter: true, sort: true }
  },
  {
    name: "buyerAttorney",
    label: "Buyer Attorney",
    options: { filter: true, sort: true }
  },
  {
    name: "sellerAttorney",
    label: "Seller Attorney",
    options: { filter: true, sort: true }
  },
  {
    name: "salesRep",
    label: "Sales Rep",
    options: { filter: true, sort: true }
  },
  {
    name: "premium",
    label: "Premium",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => (value ? fCurrency(value) : "")
    }
  },
  {
    name: "salesPrice",
    label: "Sales Price",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => (value ? fCurrency(value) : "")
    }
  },
  {
    name: "loanAmount",
    label: "Loan",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value: any) => (value ? fCurrency(value) : "")
    }
  },
  {
    name: "accountRep",
    label: "Account Rep",
    options: { filter: true, sort: true }
  },
  { name: "funder", label: "Funder", options: { filter: true, sort: true } }
];

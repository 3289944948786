import { MRT_ColumnDef } from "material-react-table";
import { EscrowLedger } from "./types";
import { Chip, IconButton, Link, Stack, Box, Typography } from "@mui/material";
import { fMonthDayYearDotNotation } from "../../utils/formatTime";
import { OrderNumberCell } from "../OrderNumberCell";
import { getOrderNumberDisplay, orderNumberFilterFn } from "../ReportsUtils";
import { fCurrency } from "../../utils/formatNumber";
import { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import Iconify from "../../../minimals/components/iconify";
import { DatePicker } from "@mui/x-date-pickers";
import SingleDateRangeFilter from "./SingleDateRangeFilter";

const DueDateColumn = ({ order }: { order: EscrowLedger }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editDueDate, setEditDueDate] = useState(dayjs(order.dueDate).format("YYYY-MM-DD"));
  const [isUpdating, setIsUpdating] = useState(false);

  const updateDueDate = async () => {
    var newDueDate = dayjs(editDueDate).toDate();
    setIsUpdating(true);
    await axios.post("/proxy/api/ledgers/editLedgerDueDate", {
      orderNumber: order.name,
      dueDate: newDueDate
    });
    //Hack alert! This is not the correct React way to do this, but we need to update the UI
    //and since we're buried so deep here, it would be really difficult to pull this off. This
    //works, but it's a hack!
    order.dueDate = newDueDate;
    setIsUpdating(false);
    setIsEditMode(false);
  };

  const getUI = () => {
    if (!isEditMode) {
      return (
        <>
          {order.dueDate && <Chip label={fMonthDayYearDotNotation(order.dueDate)} />}
          <IconButton sx={{ marginLeft: 1 }} onClick={() => setIsEditMode(true)}>
            <Iconify icon="eva:edit-outline" />
          </IconButton>
        </>
      );
    }
    if (isUpdating) {
      return <h4>Updating....</h4>;
    }
    return (
      <Stack direction="row" alignItems="center">
        <DatePicker
          label="Due Date"
          onChange={(newValue) => setEditDueDate(dayjs(newValue!).format("YYYY-MM-DD"))}
          value={dayjs(editDueDate).toDate()}
          slotProps={{ textField: { sx: { width: 160, marginRight: 2 }, size: "small" } }}
        />
        <Box>
          <IconButton onClick={updateDueDate} color="success">
            <Iconify icon="eva:checkmark-fill" />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => setIsEditMode(false)} color="error">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Box>
      </Stack>
    );
  };

  return getUI();
};

export const columns: MRT_ColumnDef<EscrowLedger>[] = [
  {
    id: "notes",
    header: "",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,
    size: 50,
    enableHiding: false
  },
  {
    header: "Order Number",
    id: "orderNumber",
    accessorFn: getOrderNumberDisplay,
    filterFn: orderNumberFilterFn,
    Cell: OrderNumberCell,
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    size: 50
  },
  {
    id: "dueDate",
    header: "Due Date",
    accessorFn: (row) => (row.dueDate ? new Date(row.dueDate) : null),
    filterVariant: "date",
    sortingFn: "datetime",
    Cell: ({ row }) => <DueDateColumn order={row.original} />,
    filterFn: (row, id, filterValue) => {
      if (filterValue[0] || filterValue[1]) {
        const dueDate = new Date(row.original.dueDate).getTime();
        const startDate = filterValue[0] ? new Date(filterValue[0]).getTime() : null;
        const endDate = filterValue[1] ? new Date(filterValue[1]).getTime() : null;

        return (startDate ? dueDate >= startDate : true) && (endDate ? dueDate <= endDate : true);
      } else {
        return true;
      }
    },
    Filter: SingleDateRangeFilter,
    size: 230
  },
  {
    header: "Ledger",
    accessorKey: "friendlyName",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    Cell: ({ row, renderedCellValue }) => (
      <Link href={`/ledger/${row.original.name}`} target="_blank">
        {renderedCellValue}
      </Link>
    ),
    size: 100
  },
  {
    header: "For",
    accessorKey: "ledgerComment",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    Cell: ({ row }) => (
      <Typography variant="body2" paddingRight={3}>
        {row.original.ledgerComment}
      </Typography>
    )
  },
  {
    header: "Amount",
    accessorKey: "amount",
    Cell: ({ row }) => (row.original.amount ? fCurrency(row.original.amount) : <></>),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    size: 100
  },
  {
    header: "Assigned To",
    accessorKey: "assignedTo",
    Cell: ({ renderedCellValue = "", row }) => (
      <Link href={`mailto:${row.original?.assignedToEmail}`}>{renderedCellValue}</Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Closer",
    accessorKey: "orderInfo.closer",
    Cell: ({ renderedCellValue = "", row }) => (
      <Link href={`mailto:${row.original.orderInfo?.closerEmail}`}>{renderedCellValue}</Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Funder",
    accessorKey: "orderInfo.funder",
    Cell: ({ renderedCellValue = "", row }) => (
      <Link href={`mailto:${row.original.orderInfo?.funderEmail}`}>{renderedCellValue}</Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Post Closer",
    accessorKey: "orderInfo.postCloserFullName",
    Cell: ({ renderedCellValue = "", row }) => (
      <Link href={`mailto:${row.original.orderInfo?.postCloserEmail}`}>{renderedCellValue}</Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

import { MRT_ColumnDef } from "material-react-table";
import { Link, Stack, Typography } from "@mui/material";
import { HistoryWithRedFlags } from "../../phonebook/types";
import { formatPhoneNumber } from "./FormatPhoneNumberUtils";

export const contactColumns: MRT_ColumnDef<HistoryWithRedFlags>[] = [
  {
    header: "Date/Time",
    accessorKey: "date",
    size: 50,
    accessorFn: (row) => {
      return new Date(row.date).toLocaleDateString();
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Contact/Company",
    size: 50,
    accessorKey: "contactCompany",
    accessorFn: (row) => {
      const isContact = row?.contact;
      return (
        <Link
          maxWidth={35}
          target="_blank"
          rel="noreferrer"
          href={`/phonebook/${isContact ? "contact" : "company"}/profile?id=${
            isContact ? row?.contact?.id : row?.company?.id
          }`}
        >
          {isContact
            ? `${row?.contact?.firstName || ""} ${row?.contact?.lastName || ""}`
            : `${row?.company?.name || ""}`}
        </Link>
      );
    },
    filterFn: (row, id, filterValue) => {
      const isContact = row.original.contact;
      const name = isContact
        ? `${row.original.contact.firstName || ""} ${row.original.contact.lastName || ""}`
        : row.original.company?.name || "";
      return name.toLowerCase().includes(filterValue.toLowerCase());
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "User",
    accessorKey: "username",
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Event",
    size: 100,
    accessorKey: "eventType",
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "before",
    header: "Before",
    accessorFn: (row) => {
      return (
        row.changes &&
        row.changes?.map((ov) => {
          const isNumberField =
            ov.friendlyFieldName.includes("Phone") ||
            ov.friendlyFieldName.includes("Number") ||
            ov.friendlyFieldName.includes("Fax");
          return (
            <Typography variant="body2">{isNumberField ? formatPhoneNumber(ov.oldValue) : ov.oldValue}</Typography>
          );
        })
      );
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "friendlyFieldName",
    size: 100,
    header: "Field Name",
    filterFn: (row, id, filterValue) => {
      var fieldNames = row.original?.changes?.map((c) => c.friendlyFieldName);
      return filterValue.some((v: string) => fieldNames.some((fn) => fn === v));
    },
    filterVariant: "multi-select",
    filterSelectOptions: [
      "Mobile Phone",
      "Business Phone",
      "First Name",
      "Last Name",
      "Business Email",
      "Business Address 1",
      "Business Address 2",
      "Business City",
      "Business State",
      "Sales Rep",
      "Role Name",
      "Company Name",
      "Profession"
    ],
    accessorFn: (row) => {
      return (
        row.changes && row.changes?.map((fn) => <Typography variant="subtitle2">{fn.friendlyFieldName}</Typography>)
      );
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "after",
    size: 100,
    header: "After",
    accessorFn: (row) => {
      return (
        row.changes &&
        row.changes?.map((nv) => {
          const isNumberField =
            nv.friendlyFieldName.includes("Phone") ||
            nv.friendlyFieldName.includes("Number") ||
            nv.friendlyFieldName.includes("Fax");

          return (
            <Typography variant="body2" maxWidth={250}>
              {isNumberField ? formatPhoneNumber(nv.newValue) : nv.newValue}
            </Typography>
          );
        })
      );
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "redFlags",
    header: "Red Flags",
    size: 100,
    accessorFn: (row) => {
      return row.redFlags?.map((f) => (
        <Typography variant="body2" color="error">
          {f}
        </Typography>
      ));
    },
    filterFn: (row, id, filterValue) => {
      return (
        row.original.redFlags.some((f) => filterValue.includes(f)) ||
        (row.original.redFlags.length === 0 && filterValue.includes("No Red Flags")) ||
        filterValue.length === 0
      );
    },
    filterVariant: "multi-select",
    filterSelectOptions: [
      "Missing Role",
      "Missing Company",
      "Missing Company Type",
      "No Members",
      "No Red Flags",
      "Missing Email"
    ],
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Phonebook Type",
    filterFn: (row, id, filterValue) => {
      return row.original.contact && filterValue === "Individual";
    },
    filterVariant: "select",
    filterSelectOptions: ["Individual", "Company"],
    accessorFn: (row) => {
      const isContact = row.contact;
      return <Typography variant="body2">{isContact ? "Individual" : "Company"}</Typography>;
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

import React from "react";

interface DynamicLinkProps {
  path: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  children: React.ReactNode;
}

const DynamicLink: React.FC<DynamicLinkProps> = ({ path, target, children }) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const fullUrl = `${protocol}//${host}${path}`;
  return (
    <a href={fullUrl} target={target}>
      {children}
    </a>
  );
};

export default DynamicLink;

import { Autocomplete, AutocompleteChangeReason, InputAdornment, TextField, Typography, debounce } from "@mui/material";
import { SmartyAddress, SmartyResponse } from "../../types";
import { SyntheticEvent, useState } from "react";
import axios from "axios";
import Iconify from "../../../../minimals/components/iconify";

interface IProps {
  setAddressOption: (option: SmartyAddress | null) => void;
  setAddress1: (value: string) => void;
  inputValue: string | undefined;
  onFocus?: () => void;
  error?: boolean;
  onBlur?: () => void;
}

export default function AddressAutocomplete({
  setAddressOption,
  setAddress1,
  inputValue,
  onFocus,
  error,
  onBlur
}: IProps) {
  const [addressList, setAddressList] = useState<SmartyAddress[]>([]);

  const searchAddress = debounce(async (searchText: string) => {
    if (searchText) {
      let { data } = await axios<SmartyResponse>({
        method: "get",
        url: `/api/property/search?searchText=${encodeURIComponent(searchText)}`
      });
      setAddressList(data.suggestions);
    } else {
      setAddressList([]);
    }
  }, 200);

  const handleAddressOptionChange = (
    e: SyntheticEvent<Element, Event>,
    option: SmartyAddress | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "selectOption") {
      setAddressOption(option);
    } else if (reason === "clear") {
      clearAddress1AndOptions();
    }
  };

  const handleAddress1Change = async (searchText: string) => {
    searchAddress(searchText);
    setAddress1(searchText);
  };

  const clearAddress1AndOptions = () => {
    setAddress1("");
    setAddressList([]);
  };

  return (
    <Autocomplete
      inputValue={inputValue}
      onChange={(e, option, reason) => handleAddressOptionChange(e, option, reason)}
      renderInput={(params) => (
        <TextField
          error={error}
          {...params}
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password"
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" />
              </InputAdornment>
            )
          }}
          fullWidth
          label="Address"
          placeholder="Address Quick Entry"
          onChange={(e) => handleAddress1Change(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      getOptionLabel={(option) => option.formattedStreet_line}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.formattedStreet_line + option.zipcode}>
            <div style={{ width: "100%" }}>
              <div>
                <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                  {option.formattedStreet_line} {option.secondary} {option.city} {option.state} {option.zipcode}
                </Typography>
              </div>
            </div>
          </li>
        );
      }}
      options={addressList}
    />
  );
}

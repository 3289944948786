import LoadingButton from "@mui/lab/LoadingButton";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect } from "react";
import { getStatusColor } from "../../../calendar/CalendarUtils";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";

interface IProps {
  order: ContactOrder;
  indexToProcess: number;
  nextIndex: () => void;
  index: number;
  mergeOrder: (order: ContactOrder) => Promise<any>;
  setOrdersSuccessStatus: (index: number, isSuccess: boolean) => void;
}
const MergeOrdersProgressModalRow = ({
  order,
  indexToProcess,
  nextIndex,
  index,
  mergeOrder,
  setOrdersSuccessStatus
}: IProps) => {
  const mergeOrderAndSetIndex = async () => {
    if (!order.isSuccessful) {
      const data = await mergeOrder(order);
      setOrdersSuccessStatus(index, data);
    }

    nextIndex();
  };

  const formatDate = (dateString: any) => format(new Date(dateString), "MM/dd/yyyy p");

  useEffect(() => {
    if (index === indexToProcess) {
      mergeOrderAndSetIndex();
    }
  }, [indexToProcess]);

  return (
    <>
      <TableRow
        key={order.orderNumber}
        sx={{
          backgroundColor: order.isSuccessful ? "#E9FCD4" : indexToProcess <= index ? "#ffffff" : "#FFE7D9"
        }}
      >
        <TableCell>
          <Typography variant="subtitle1">{order.orderNumber || ""}</Typography>
        </TableCell>
        <TableCell>{order.roleInOrder || ""}</TableCell>
        <TableCell>{formatDate(order.closingDate) || ""}</TableCell>
        <TableCell>
          <Chip
            variant="outlined"
            label={order.orderStatus}
            sx={{
              height: "24px",
              width: "100px",
              borderRadius: "6px",
              fontWeight: 600,
              borderColor: getStatusColor(order.orderStatus || "").color,
              color: getStatusColor(order.orderStatus || "").color
            }}
          />
        </TableCell>
        <TableCell>
          <LoadingButton loading={indexToProcess === index} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default MergeOrdersProgressModalRow;

import { useState } from "react";
import { Box, Typography, Link, Tooltip, ClickAwayListener } from "@mui/material";
import { BasicTask } from "./types";

const RequirementInfo = ({ task }: { task: BasicTask }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box minWidth={180}>
      <Typography variant="body2" display="inline-block">
        created by{" "}
      </Typography>{" "}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={
            <Typography variant="body2" padding={0.5}>
              {task.originalSelectRequirementText}
            </Typography>
          }
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
        >
          <Link sx={{ cursor: "pointer" }} display="inline-block" onClick={handleTooltipOpen}>
            Req: #{task.selectRequirementNumber} {task.selectRequirementCode}
          </Link>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default RequirementInfo;

import { DialogContent, DialogTitle } from "@mui/material";
import { useContext } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import { LoadingButton } from "@mui/lab";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";

interface IProps {
  isAllOrdersSuccessful: boolean;
  step: number;
  indexToProcess: number;
  mergedOrderResult: ContactOrder[];
  deleteingLookupCodes: string[];
}
export default function MergedOrdersHeaderText({
  isAllOrdersSuccessful,
  step,
  indexToProcess,
  mergedOrderResult,
  deleteingLookupCodes
}: IProps) {
  const { mergingContacts } = useContext(AddEditContactContext);

  const getDialogHeaderText = (step: number, isAllOrderSuccessful: boolean) => {
    if (isAllOrderSuccessful) {
      return "Merge Successful!";
    }
    if (step === 1 || indexToProcess < mergedOrderResult.length) {
      return "Updating Orders to Reflect Merge";
    }
    switch (step) {
      case 2:
        return "Orders failed to update";
      case 3:
        return "Orders still failed to update";
    }
  };

  return (
    <>
      <DialogTitle>{getDialogHeaderText(step, isAllOrdersSuccessful)}</DialogTitle>
      {step === 2 && !isAllOrdersSuccessful && (
        <DialogContent>
          These orders failed to update. Please update the contact on the above orders manually in Select with the
          Merged lookup code of "{deleteingLookupCodes}"
        </DialogContent>
      )}
    </>
  );
}

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import { CloseIcon } from "../../../minimals/theme/overrides/CustomIcons";
import InternalUserAutocomplete from "../../shared/InternalUserAutocomplete";
import { InternalUser } from "../../types/app";
import { fCurrency } from "../../utils/formatNumber";
import { PriorityChip } from "../PriorityStatus";
import { DashboardNote, DashboardReportType, IAssignable } from "../types";
import NotesTimeline from "./NotesTimeline";
import { saveAssignToInternalUser, getNotes, saveNote } from "./NotesUtils";
import { UserContext } from "../../contexts/UserContext";

interface IProps {
  entity: IAssignable;
  setEntity: Function;
  setEntityTable?: Function;
  isOpen: boolean;
  orderNumberDisplay?: string;
  amount?: string;
  priority?: string;
  nameBeforeUpdate: string;
  emailBeforeUpdate: string;
  refetchData?: Function;
  reportType: DashboardReportType;
  saveEscrowLedgerAssignTo?: (userName: string) => Promise<void>;
  saveEscrowLedgerNote?: (note: string) => Promise<void>;
}

export default function NotesModal({
  entity,
  setEntity,
  setEntityTable,
  orderNumberDisplay,
  amount,
  priority,
  isOpen,
  nameBeforeUpdate = "",
  emailBeforeUpdate = "",
  refetchData,
  reportType,
  saveEscrowLedgerAssignTo,
  saveEscrowLedgerNote
}: IProps) {
  const [submitting, setSubmitting] = useState(false);
  const { user } = useContext(UserContext);
  const [text, setText] = useState<string>("");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<InternalUser | null>({
    fullName: nameBeforeUpdate || "",
    email: emailBeforeUpdate || ""
  } as InternalUser);

  const saveAssignTo = async (user: InternalUser | null) => {
    if (!user || user.email?.toLowerCase() == emailBeforeUpdate?.toLowerCase()) return;
    if (reportType === DashboardReportType.EscrowOverdue && saveEscrowLedgerAssignTo) {
      await saveEscrowLedgerAssignTo(user.username);
    } else {
      await saveAssignToInternalUser({ selectId: entity.selectId, orderNumber: entity.orderNumber, user, reportType });
    }
    await updateSelectedEntity("assignedTo");
  };

  const handleSaveNote = async () => {
    if (!text) return;
    if (reportType === DashboardReportType.EscrowOverdue && saveEscrowLedgerNote) {
      await saveEscrowLedgerNote(text);
    } else {
      await saveNote({ selectId: entity.selectId, orderNumber: entity.orderNumber, text, reportType });
    }
    await updateSelectedEntity("note");
  };

  const updateSelectedEntity = async (type: "note" | "assignedTo") => {
    if (!entity) return;
    let notes = entity.notes;
    if (type === "note" && reportType === DashboardReportType.EscrowOverdue) {
      notes =
        type === "note"
          ? [
              ...entity.notes,
              {
                id: 0,
                selectId: entity.selectId,
                orderNumber: entity.orderNumber,
                createdByUser: user?.name,
                text,
                dateCreated: new Date(),
                createdBy: user?.name
              } as DashboardNote
            ]
          : entity.notes;
    } else if (reportType !== DashboardReportType.EscrowOverdue) {
      notes = await getNotes({ orderNumber: entity.orderNumber, selectId: entity.selectId, reportType });
    }
    const updatedEntity = {
      ...entity,
      notes,
      assignedToUser: selectedAssignedTo,
      assignedToEmail: selectedAssignedTo?.email,
      assignedToName: selectedAssignedTo?.fullName,
      assignedTo: selectedAssignedTo?.fullName
    };
    setEntity(updatedEntity);
    if (setEntityTable) {
      setEntityTable((tableData: IAssignable[]) => {
        return reportType === DashboardReportType.EscrowOverdue
          ? tableData.map((row) => (row.name == entity.name ? updatedEntity : row))
          : tableData.map((row) => (row.selectId == entity.selectId ? updatedEntity : row));
      });
    }
    setText("");
  };

  const save = async () => {
    if (!(selectedAssignedTo?.email || text) || !entity) return;

    setSubmitting(true);
    await saveAssignTo(selectedAssignedTo);
    await handleSaveNote();
    setSubmitting(false);
    if (refetchData) {
      refetchData();
    }
  };

  useEffect(() => {
    const fetchAssignedToUser = async () => {
      if (selectedAssignedTo?.email) {
        const { data } = await axios.get(
          `/api/clientphonebook/internalusers/getInternalUserByEmail?email=${selectedAssignedTo?.email}`
        );
        if (data) {
          setSelectedAssignedTo(data);
        }
      }
    };
    fetchAssignedToUser();
  }, []);

  const onClose = () => setEntity(null);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" spacing={2} alignItems="center" marginBottom={3}>
          <Box>
            Order Number {orderNumberDisplay || entity.orderNumber}
            {amount ? ` - ${fCurrency(amount)}` : ""}
          </Box>
          {priority && (
            <Box>
              <PriorityChip priority={priority} />
            </Box>
          )}
        </Stack>
        <InternalUserAutocomplete
          sx={{ width: 220 }}
          value={selectedAssignedTo}
          onChange={(val) => {
            setSelectedAssignedTo(val as InternalUser | null);
          }}
          label="Assigned to"
        />
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={3}
            label="Note"
            sx={{ marginBottom: 2 }}
          />
          <Stack direction="row" justifyContent="right">
            <Button variant="contained" size="small" onClick={save} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Box>
        <NotesTimeline notes={entity?.notes || []} />
      </DialogContent>
    </Dialog>
  );
}

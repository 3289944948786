import { Typography, Box, Divider, Skeleton, Stack, Link } from "@mui/material";
import { fCurrency } from "../utils/formatNumber";
import { LedgerTransaction } from "./types";

const LedgerSummary = ({
  ledgerTypeHeader,
  total,
  transactions,
  orderNumber,
  isRecording
}: {
  ledgerTypeHeader: string;
  total: number | null;
  transactions: LedgerTransaction[];
  orderNumber: string;
  isRecording?: boolean;
}) => {
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="subtitle2" marginBottom={1}>
        {ledgerTypeHeader}
      </Typography>
      <Box maxHeight={96} overflow="auto" width={200}>
        {transactions.map((transaction, index) => (
          <Box key={index} display="flex" justifyContent="space-between">
            <Link
              href={isRecording ? `/ledger/${orderNumber}-recording` : `/ledger/${orderNumber}-${transaction.name}`}
              target="_blank"
            >
              {transaction.name}:
            </Link>
            <Typography variant="body2" color="#637381">
              <Box component="span" textAlign="right">
                {fCurrency(transaction.amount)}
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />

      <Typography variant="body2" color="#637381" textAlign="right">
        Total: {fCurrency(total || 0)}
      </Typography>
    </Stack>
  );
};

export default LedgerSummary;

import { ContactProvider } from "./ContactContext";
import Contacts from "./Contacts";

export default function ContactsPage() {
  return (
    <ContactProvider>
      <Contacts />
    </ContactProvider>
  );
}

import { Box, Card, Grid, TextField } from "@mui/material";
import { useContacts } from "../../../contexts/ContactsContext";
import ClientLoaderButton from "./ClientLoaderButton";
import ProfileCard from "./ProfileCard";
import { useEffect, useState } from "react";
import ContactAddress from "./ContactAddress";
import validateEmail from "../../../utils/validateEmail";
import { MuiTelInput } from "mui-tel-input";
import styled from "@emotion/styled";

export default function PersonalInfo({ onNextClick }: { onNextClick: () => void }) {
  const { currentContact, editContact, newContact, isEditMode } = useContacts();
  const contact = isEditMode ? currentContact : newContact;
  const [isValid, setIsValid] = useState(true);

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: "grid",
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)"
              }
            }}
          >
            <TextField
              label="Personal Email"
              value={contact?.email || ""}
              error={!isValid}
              helperText={!isValid && "Invalid Email"}
              onFocus={() => setIsValid(true)}
              onBlur={(e) => {
                setIsValid(validateEmail(e.target.value.trim()));
              }}
              onChange={(e) => {
                setIsValid(true);
                editContact("email", e.target.value);
              }}
            />
            <IconStyle>
              <MuiTelInput
                preferredCountries={["US", "IL"]}
                defaultCountry="US"
                forceCallingCode
                inputProps={{ maxLength: 12 }}
                label="Personal Phone"
                value={contact?.phone || ""}
                onChange={(e) => editContact("phone", e)}
              />
            </IconStyle>
            <ContactAddress />
            <TextField
              label="Address Line 2"
              value={contact?.address2 || ""}
              onChange={(e) => editContact("address2", e.target.value.trim())}
            />
            <TextField
              label="City"
              value={contact?.city || ""}
              onChange={(e) => editContact("city", e.target.value.trim())}
            />
            <TextField
              label="State"
              value={contact?.state || ""}
              onChange={(e) => editContact("state", e.target.value.trim())}
            />
            <TextField label="Country" />
            <TextField
              label="Zip Code"
              value={contact?.zip || ""}
              onChange={(e) => editContact("zip", e.target.value.trim())}
            />
          </Box>
          <Box sx={{ justifyContent: "flex-end", display: "flex", mt: 4 }}>
            <ClientLoaderButton onNextClick={onNextClick} />
          </Box>
        </Card>
      </Grid>
      {contact && (
        <Grid item xs={4}>
          <ProfileCard />
        </Grid>
      )}
    </Grid>
  );
}
const IconStyle = styled.div`
  .MuiTelInput-IconButton {
    img {
      display: none;
    }
  }
`;

import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { InternalUser } from "../../../types/app";

interface IProps {
  clientCultivator: InternalUser | null;
  setClientCultivator: (internalUser: InternalUser | null) => void;
  label: string;
}

export default function ClientCultivatorsAutocomplete({ clientCultivator, setClientCultivator, label }: IProps) {
  const [clientCultivatorOptions, setClientCultivatorOptions] = useState<InternalUser[]>([]);
  //   const [selectedClientCultivator, setSelectedClientCultivator] = useState<InternalUser>(clientCultivator);

  useEffect(() => {
    const getClientCultivatorOptions = async () => {
      const { data } = await axios.get(`/api/clientphonebook/internalUsers/getClientCultivators`);
      setClientCultivatorOptions(data);
    };

    getClientCultivatorOptions();
  }, []);

  return (
    <Autocomplete
      onChange={(_, option) => setClientCultivator(option || null)}
      options={clientCultivatorOptions}
      value={clientCultivator}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password"
          }}
          fullWidth
          label={label}
          //   onChange={(e) => {
          //     setEditedRep(e.target.value);
          //   }}
        />
      )}
      getOptionLabel={(option) => (option?.firstName || "") + " " + (option?.lastName || "")}
    />
  );
}

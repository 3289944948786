import dayjs from "dayjs";

const today = dayjs();
const checksEndDate = today.subtract(10, "days");
const checksStartDate = dayjs("7/4/2023");

const policiesNotIssuedStartDate = today.subtract(90, "days");
const policiesNotIssuedEndDate = today;

export const reports: Report[] = [
  {
    title: "Uncleared Checks",
    to: "../checks",
    apiUrl: `/api/OutstandingChecks/GetChecks?from=${checksStartDate}&to=${checksEndDate}`,
    color: "rgb(0, 168, 168)",
    backgroundColor: "rgb(0, 168, 168, .05)",
    icon: "ic:baseline-money"
  },
  {
    title: "Searches Not Ordered",
    to: "../searches-not-ordered",
    apiUrl: "/api/outstandingchecks/searchesnotordered?username=",
    color: "rgb(24, 23, 168)",
    backgroundColor: "rgb(24, 23, 168, .05)",
    icon: "ic:sharp-search-off"
  },
  {
    title: "Outstanding Searches",
    to: "../outstanding-searches",
    apiUrl: "/api/outstandingchecks/outstandingSearches?username=",
    color: "rgb(168, 0, 255)",
    backgroundColor: "rgb(168, 0, 255, .05)",
    icon: "ic:twotone-youtube-searched-for"
  },
  {
    title: "Commitments Overdue",
    to: "../commitments-overdue",
    apiUrl: "/api/outstandingchecks/commitmentOverdue?username=",
    color: "rgb(0, 0, 255)",
    backgroundColor: "rgb(0, 0, 255, .05)",
    icon: "ic:outline-handshake"
  },
  {
    title: "Incoming Wires (Last 5 business days)",
    to: "https://wired.mcres.com",
    apiUrl: "/api/dashboard/getIncomingWires",
    color: "rgb(13, 0, 255)",
    backgroundColor: "rgb(13, 0, 255, .05)",
    icon: "ic:baseline-attach-money",
    isWire: true
  },
  {
    title: "Outgoing Wires (Last 5 business days)",
    to: "https://wired.mcres.com",
    apiUrl: "/api/dashboard/getOutgoingWires",
    color: "rgb(13, 0, 255)",
    backgroundColor: "rgb(13, 0, 255, .05)",
    icon: "ic:baseline-money-off",
    isWire: true
  },
  {
    title: "Policies Not Issued",
    to: "/policies-not-issued",
    apiUrl: `/api/outstandingchecks/policiesNotIssued?from=${policiesNotIssuedStartDate}&to=${policiesNotIssuedEndDate}`,
    color: "rgb(0 88 131)",
    backgroundColor: "rgb(0 88 131 / 5%)",
    icon: "material-symbols:policy-outline"
  },
  {
    title: "Escrow Overdue Orders",
    to: "/escrow-ledgers",
    apiUrl: "/api/ledgers/GetEscrowLedgers",
    color: "rgb(22, 0, 255)",
    backgroundColor: "rgb(22, 0, 255, .05)",
    icon: "mdi:account-cash-outline"
  },
  {
    title: "Non Zero Ledger Balances",
    to: "/non-zero-ledger-balances",
    apiUrl: "/api/outstandingchecks/GetNonZeroLedgerBalance",
    color: "rgb(0 88 131)",
    backgroundColor: "rgb(0 88 131 / 5%)",
    icon: "ic:baseline-attach-money"
  }
];

interface Report {
  title: string;
  to: string;
  apiUrl: string;
  color: string;
  backgroundColor: string;
  icon: string;
  isWire?: boolean;
}

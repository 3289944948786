import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import FileIcon from "./components/FileIcon";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import CorpDocHeader from "./CorpDocHeader";
import NewCorpDoc from "./NewCorpDoc";
import { useEffect, useState } from "react";
import { CorpDoc, CorpDocType } from "./types";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import CorpDocBreadcrumbs from "./CorpDocBreadcrumbs";
import CopDocFilters from "./CopDocFilters";
import VersionCount from "./VersionCount";
import VersionUpload from "./VersionUpload";
import UploadButton from "./UploadButton";
import OpenFileButton from "./OpenFileButton";
import CorpDocActions from "./CorpDocActions";
import OrderSidebar from "./OrderSidebar";

export default function CorpDocs() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");

  const [newCorpDocOpen, setNewCorpDocOpen] = useState<boolean>(false);
  const handleCloseNewCorpDoc = () => setNewCorpDocOpen(false);
  const handleOpenNewCorpDoc = () => setNewCorpDocOpen(true);
  const [openVersionUpload, setOpenVersionUpload] = useState<boolean>(false);

  const [corpDocTypes, setCorpDocTypes] = useState<CorpDocType[]>([]);
  const [corpDocs, setCorpDocs] = useState<CorpDoc[]>([]);
  const [selectedCorpDocType, setSelectedCorpDocType] = useState<CorpDocType | null>(null);
  const [selectedCorpDoc, setSelectedCorpDoc] = useState<CorpDoc | null>(null);

  const getAndSetCorpDocTypes = async () => {
    const { data } = await axios.get(`/api/corpdocstracking/corpdocs/getTypes`);
    setCorpDocTypes(data);
  };

  const getAndSetCorpDocs = async () => {
    const { data } = await axios.get(`/api/corpdocstracking/corpdocs/getByOrderNumber?orderNumber=${order}`);
    setCorpDocs(data);
  };

  const navigateToTasks = (id: number) => {
    navigate(`./tasks?order=${order}&corpdoc-id=${id}`);
  };

  const handleSelectedCorpDocAndOpenUpload = (r: CorpDoc) => {
    setSelectedCorpDoc(r);
    setOpenVersionUpload(true);
  };

  useEffect(() => {
    getAndSetCorpDocTypes();
    getAndSetCorpDocs();
  }, []);

  const handleOpenVersionOpload = (corpDoc: CorpDoc) => {
    setSelectedCorpDoc(corpDoc);
    setOpenVersionUpload(true);
  };

  const filteredCorpDocs = selectedCorpDocType ? corpDocs.filter((r) => r.type === selectedCorpDocType?.id) : corpDocs;

  return (
    <>
      <CorpDocHeader handleOpenNewCorpDoc={handleOpenNewCorpDoc} />
      <CorpDocBreadcrumbs />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TableContainer component={Card}>
            <CopDocFilters options={corpDocTypes} setSelectedCorpDocType={setSelectedCorpDocType} />
            <Table
              sx={{
                cursor: "pointer",
                "& .MuiTableRow-root:hover": {
                  backgroundColor: "background.neutral"
                }
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Corp Doc Document</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCorpDocs.map((r) => (
                  <TableRow>
                    <TableCell onClick={() => navigateToTasks(r.id)} width={"27%"}>
                      <Stack direction="row" spacing={2}>
                        <FileIcon />
                        <Box>
                          <Typography variant="subtitle2">
                            {corpDocTypes?.find((t) => t.id === r.type)?.name} {r.description && `- ${r.description}`}
                          </Typography>
                          <Tooltip disableFocusListener title="Add">
                            <VersionCount
                              versionCount={r.documents.length}
                              documents={r.documents}
                              openVersionUpload={() => handleOpenVersionOpload(r)}
                            />
                          </Tooltip>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell onClick={() => navigateToTasks(r.id)}>
                      <LinearProgressWithLabel
                        totalTasks={r.taskItems.filter((t) => t.status !== 3).length}
                        completedTasks={r.taskItems.filter((t) => t.status === 2).length}
                      />
                    </TableCell>
                    <TableCell width={"60%"}>
                      <Stack direction="row" spacing={3.5} alignItems="center">
                        <CorpDocActions
                          navigateToTasks={() => navigateToTasks(r.id)}
                          momentumDocumentId={r.documents[0].momentumDocumentId}
                          setOpenVersionUpload={() => handleSelectedCorpDocAndOpenUpload(r)}
                        />
                        <UploadButton setOpenVersionUpload={() => handleSelectedCorpDocAndOpenUpload(r)} />
                        <OpenFileButton
                          href={`https://internal.mcres.com/opendoc?id=${r.documents[0].momentumDocumentId}`}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {corpDocs.length === 0 && <Typography padding={2}>No corp docs available</Typography>}
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <OrderSidebar />
        </Grid>
      </Grid>
      {newCorpDocOpen && (
        <NewCorpDoc open={newCorpDocOpen} onClose={handleCloseNewCorpDoc} updateCorpDocData={getAndSetCorpDocs} />
      )}
      {openVersionUpload && (
        <VersionUpload
          open={openVersionUpload}
          onClose={() => setOpenVersionUpload(false)}
          updateData={getAndSetCorpDocs}
          corpDocId={selectedCorpDoc?.id}
          documentType={corpDocTypes.find((t) => t.id === selectedCorpDoc?.type)?.name}
          additionalDescription={selectedCorpDoc?.description}
          newVersionCount={selectedCorpDoc?.documents?.length ? selectedCorpDoc?.documents?.length + 1 : 0}
        />
      )}
    </>
  );
}

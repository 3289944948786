// ----------------------------------------------------------------------

import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import SendViaEmailButton from "./SendViaEmailButton";

export default function SentEmailAlert() {
  return (
    <Alert sx={{ marginBottom: 2 }} action={<SendViaEmailButton label="Re-send via Email" />}>
      Confirmation of Title Sent
    </Alert>
  );
}

// @mui
import { Card, Typography } from "@mui/material";
import { ReactElement } from "react";

// ----------------------------------------------------------------------

type props = {
  title: string;
  children: ReactElement<any, any>;
};

export default function ProofOfOrderPlacement({ title, children }: props) {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="overline" sx={{ mb: 3, display: "block", color: "text.secondary" }}>
        {title}
      </Typography>
      {children}
    </Card>
  );
}

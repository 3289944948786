import React, { useContext, useEffect } from "react";
import PhonebookContactAutocomplete from "../phonebook/phonebookAutocomplete/PhonebookContactAutocomplete";
import { Box, Button, Checkbox, FormControlLabel, IconButton, Stack, TextField } from "@mui/material";
import { OrderContext } from "../contexts/OrderDataContext";
import ContactCardNew from "./additionalContacts/ContactCardNew";
import Iconify from "../../minimals/components/iconify";
import { Contact } from "./types/Contact";
import { getContactFullName } from "./utils/helpers";

export default function Principal({
  principal,
  updatePrincipal,
  isBuyerPrincipal,
  addBuyerPrincipal,
  addSellerPrincipal,
  index,
  deleteContact
}: {
  principal: Contact;
  updatePrincipal: (updatedPrincipal: Contact) => void;
  isBuyerPrincipal: boolean;
  addBuyerPrincipal?: () => void;
  addSellerPrincipal?: () => void;
  index: number;
  deleteContact: (index: number) => void;
}) {
  const {
    selectedBuyerPrincipals,
    selectedSellerPrincipals,
    dealOwner,
    setDealOwner,
    searchForPatriotsAndBlackList,
    transactionType
  } = useContext(OrderContext);
  const handleCheckboxChange = () => {
    if (dealOwner === (isBuyerPrincipal ? "Buyer" : "Seller")) {
      setDealOwner(null);
    } else {
      setDealOwner(isBuyerPrincipal ? "Buyer" : "Seller");
    }
  };

  const getTextBoxLabel = () => {
    if (transactionType === "Refinance") {
      return "Borrower Principal";
    }
    return isBuyerPrincipal ? "Buyer's Principal" : "Seller's Principal";
  };

  const getAddActionLabel = () => {
    if (transactionType === "Refinance") {
      return "Add Additional Borrower Principal";
    }
    return isBuyerPrincipal ? "Add Additional Buyer Principal" : "Add Additional Seller Principal";
  };

  const emailsSplit = principal?.emailsSplit || [];

  return (
    <>
      <Box display="flex" alignItems="center" width="100%">
        <Box flexGrow={1} mr={2}>
          <PhonebookContactAutocomplete
            contact={principal}
            setContact={async (contact) => {
              const emailsSplit = contact?.emailsSplit || contact?.email?.split(";") || [];
              updatePrincipal({ ...contact, emailsSplit } as Contact);
              if (contact) {
                const fullName = getContactFullName(contact);
                await searchForPatriotsAndBlackList(
                  [
                    { name: fullName, isCompany: !contact?.isIndividual },
                    { name: contact.payeeName, isCompany: false },
                    { name: contact.company, isCompany: true }
                  ],
                  fullName
                );
              }
            }}
            textboxLabel={getTextBoxLabel()}
          />
        </Box>
        {principal?.id && index > 0 && (
          <IconButton
            onClick={() => {
              deleteContact(index);
            }}
          >
            <Iconify icon="eva:trash-fill" />
          </IconButton>
        )}
      </Box>

      {principal?.id && (
        <>
          <Box height={selectedBuyerPrincipals || selectedSellerPrincipals ? 449 : 0}>
            <ContactCardNew contact={principal} />
          </Box>
          <Stack direction="column" spacing={2} width="240px">
            {emailsSplit.length > 0 &&
              emailsSplit.slice(1)?.map((email, index) => (
                <Stack direction="row" spacing={0.5}>
                  <TextField
                    type="text"
                    size="small"
                    label={`CC ${index + 1}`}
                    value={email}
                    fullWidth
                    onChange={(e) => {
                      const emailsSplitCopy = [...emailsSplit];
                      emailsSplitCopy[index + 1] = e.target.value;
                      updatePrincipal({
                        ...principal,
                        emailsSplit: emailsSplitCopy
                      });
                    }}
                  />
                  <Box>
                    <IconButton
                      style={index === 0 ? { visibility: "hidden" } : {}}
                      aria-label="delete"
                      onClick={() => {
                        updatePrincipal({
                          ...principal,
                          emailsSplit: emailsSplit.filter((_, i) => i != index)
                        });
                      }}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                    </IconButton>
                  </Box>
                </Stack>
              ))}
            <Box>
              <Button
                size="small"
                onClick={() =>
                  updatePrincipal({
                    ...principal,
                    emailsSplit: [...emailsSplit, ""]
                  })
                }
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                variant="text"
              >
                Add a CC
              </Button>
            </Box>
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={dealOwner === (isBuyerPrincipal ? "Buyer" : "Seller")}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Is Deal Owner"
          />
          <Box>
            <Button
              color="primary"
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              onClick={isBuyerPrincipal ? addBuyerPrincipal : addSellerPrincipal}
            >
              {getAddActionLabel()}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

import { useEffect } from "react";

const useDebugShortcut = (onDebugShortcut: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === "D") {
        event.preventDefault();
        onDebugShortcut();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onDebugShortcut]);
};

export default useDebugShortcut;

import { Typography, Box, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import OpenInSelectButton from "../../searches/OpenInSelectButton";
import OpenInHomePageButton from "../../searches/OpenInHomePageButton";
import { useOrderInfo } from "./OrderInfoContext";

export default function OrderAndAddress() {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get("order");

  const orderInfo = useOrderInfo();

  const orderProperty = orderInfo?.properties[0];

  return (
    <Box marginBottom={2} minHeight={80}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4">{orderNumber}</Typography>
        <Box sx={{ position: "relative", top: 2 }}>
          <OpenInSelectButton identifier={orderInfo?.identifier || ""} />
        </Box>
        <OpenInHomePageButton orderNumber={orderNumber || ""} />
      </Stack>
      <Typography variant="subtitle2">
        {orderProperty?.address1} {orderProperty?.address2}
      </Typography>
      <Typography variant="subtitle2">
        {orderProperty?.city}
        {orderProperty?.city && ","} {orderProperty?.state} {orderProperty?.zip}
      </Typography>
    </Box>
  );
}

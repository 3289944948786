import React from "react";
import { Link } from "@mui/material";

interface CellProps {
  renderedCellValue: React.ReactNode;
  row: {
    original: {
      orderNumber: string;
    };
  };
}

export const OrderNumberCell = ({ renderedCellValue, row }: CellProps) => (
  <Link href={`https://internal.mcres.com/new-home/${row.original.orderNumber}`} target="_blank">
    {renderedCellValue}
  </Link>
);

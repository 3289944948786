import { Button } from "@mui/material";
import { MRT_Row } from "material-react-table";
import React, { useState } from "react";
import NoteModal from "./NoteModal";
import { DepositRefundApprovalStatus, DepositRefundRequest } from "./types";

interface ActionButtonsProps {
  row: MRT_Row<DepositRefundRequest>;
  approvalStatus: DepositRefundApprovalStatus;
  refreshData: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ row, approvalStatus, refreshData }) => {
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  return (
    <>
      {approvalStatus === DepositRefundApprovalStatus.NEW && (
        <Button
          onClick={() => setIsNoteModalOpen(true)}
          variant="contained"
          size="small"
          color="primary"
          aria-label="approve"
        >
          Close
        </Button>
      )}
      {isNoteModalOpen && (
        <NoteModal
          row={row}
          isOpen={isNoteModalOpen}
          setIsOpen={setIsNoteModalOpen}
          refreshData={refreshData}
          isClosing
        />
      )}
    </>
  );
};

export default ActionButtons;

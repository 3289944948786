import { EventDropArg } from "@fullcalendar/core";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { formatISO } from "date-fns";
import { useState } from "react";
import { useCalendar } from "../contexts/CalendarContext";
import { fMonthDayYear } from "../utils/formatTime";
import { updateClosingDate } from "./CalendarActions";

interface IProps {
  info: EventDropArg;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetDragEventData: Function;
}

export default function ConfirmDateDragModal({ info, isOpen, setIsOpen, resetDragEventData }: IProps) {
  const { getAndSetClosedOrders } = useCalendar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason == "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    closeModal();
  };

  const closeModal = () => {
    resetDragEventData();
    setIsSubmitting(false);
    setIsOpen(false);
  };

  const { event, oldEvent, revert } = info;
  console.log("extendedProps", event.extendedProps);

  const onCancel = () => {
    revert();
    closeModal();
  };

  const onConfirm = async () => {
    const updatePayload = {
      // status: event.extendedProps.orderStatus,
      orderNumber: event.extendedProps.orderNumber,
      newClosingDate: event.start ? formatISO(new Date(event.start)) : ""
    };

    try {
      setIsSubmitting(true);
      event.setExtendedProp("settlementDate", event.start);
      event.setProp("editable", false);
      await updateClosingDate(updatePayload);
      event.setProp("editable", true);
      closeModal();
    } catch (e) {
      // alert(`Something went wrong. ${oldEvent.title} was not moved from ${fMonthDayYear(oldEvent.startStr)}`);
      revert();
      // await getAndSetClosedOrders();
      closeModal();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to move closing <br />
            <br />
            <b>{oldEvent.title}</b> <br /> <br />
            From: {fMonthDayYear(oldEvent.startStr)} <br />
            To: {fMonthDayYear(event.startStr)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={onConfirm} disabled={isSubmitting} loading={isSubmitting}>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

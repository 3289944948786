import { Grid, TextField } from "@mui/material";

type Props = {
  notes?: string | null;
  onChange: (notes: string) => void;
};

export default function Notes({ notes, onChange }: Props) {
  return (
    <Grid container spacing={2} marginBottom={3}>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          rows={4}
          placeholder="Notes"
          onChange={(e) => onChange(e.target.value)}
          value={notes || ""}
          sx={{
            "& fieldset": {
              borderWidth: `1px !important`,
              borderColor: (theme) => `${theme.palette.grey[200]} !important`
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

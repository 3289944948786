import { useContext } from "react";
import { IFrameContext } from "../../contexts/IFrameContext";

interface IProps {
  inputText: string;
  contactType?: any;
  onNoOption?: (val: boolean) => void;
  text?: string;
  onClick?: () => void;
  onSave?: Function | null;
}

export default function OrderEntryCreateCompany(props: IProps) {
  const { openPhonebookIFrame } = useContext(IFrameContext);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          // props.onNoOption && props.onNoOption(true);
          // props.onClick && props.onClick();
          openPhonebookIFrame({
            defaultName: props.inputText,
            onSave: props.onSave,
            selectedLookupCode: "",
            pageView: "create-company"
          });
          // window.open(`../phonebook/create-contact`, "_blank")?.focus();
        }}
      >{`+ Add "${props.inputText}" to phonebook companies`}</div>
    </>
  );
}

import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import { ReactNode, createContext, useState } from "react";

// ----------------------------------------------------------------------
const ApiInterceptorContext = createContext({} as ApiData);

type ProviderProps = {
  children: ReactNode;
};

interface ApiData {
  errorMessage: string | null;
  errorMode: boolean;
  loading: boolean;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setErrorMode: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function ApiInterceptorProvider({ children }: ProviderProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<string | null>(null);
  const [errorMode, setErrorMode] = useState(false);
  const [loading, setLoading] = useState(false);

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (!Object.keys(error).length) {
        return;
      }
      if (error?.config?.url?.toLowerCase().includes("searchestated")) {
        return; //if API to Estated fails, don't show error message
      }
      const errorResponseData = error.response.data;
      const errorData = JSON.stringify(errorResponseData, null, 2);
      let text = errorResponseData.errorMessage || errorData;

      // Check if the error message contains the specific order locked error
      const orderLockRegex = /Order (\w+) is locked for editing by user '(.+?)'/;
      const match = orderLockRegex.exec(text);

      if (match && match.length >= 3) {
        const orderNumber = match[1];
        const lockedByUser = match[2];
        text = `Order ${orderNumber} is currently being edited by user '${lockedByUser}'. Please try again later or contact the user for assistance.`;
      } else {
        const titleRegex = /<title>(.*?)<\/title>/;
        const titleMatch = titleRegex.exec(text || "");
        if (titleMatch && titleMatch.length >= 2) {
          const titleContent = titleMatch[1];
          text = titleContent;
        }
      }

      setErrorMessage(text);
      setErrorData(errorData);
      console.error("Error", error.response.data);
      setErrorMode(false);
      setLoading(false);
      return Promise.reject(error);
    }
  );

  return (
    <ApiInterceptorContext.Provider
      value={{
        errorMessage,
        errorMode,
        loading,
        setErrorMessage,
        setErrorMode,
        setLoading
      }}
    >
      {children}
      {errorMessage && (
        <Snackbar
          open={errorMessage != null}
          onClose={() => setErrorMessage(null)}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: "100%" }}>
            <Typography variant="subtitle1" marginBottom={1}>
              There were some issues
            </Typography>
            <Box maxHeight={500} maxWidth={500} overflow="auto">
              <Typography variant="subtitle2" marginBottom={2}>
                <pre style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</pre>
              </Typography>
            </Box>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(errorData || "");
              }}
            >
              Copy Error
            </Button>
          </Alert>
        </Snackbar>
      )}
    </ApiInterceptorContext.Provider>
  );
}

export { ApiInterceptorProvider, ApiInterceptorContext };

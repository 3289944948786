import { useEffect, useState } from "react";
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CustomHeader from "./MergeOrdersProgressModalHeader";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";
import MergedOrderRow from "./MergeOrdersProgressModalRow";
import axios from "axios";
import { Company } from "../../types";
import MergeOrdersActions from "./MergeOrdersActions";
import { MergeCompanyStatus } from "../types";

interface IProps {
  orders: ContactOrder[];
  companyToKeep: Company;
  companiesToDeactivate: Company[];
  setOrdersSuccessStatus: (index: number, isSuccess: boolean) => void;
}

const MergeOrdersProgressModal = ({ orders, companyToKeep, companiesToDeactivate, setOrdersSuccessStatus }: IProps) => {
  const [indexToProcess, setIndexToProcess] = useState(0);
  const [failedAttemptCount, setFailedAttemptCount] = useState(0);
  const [mergeStatus, setMergeStatus] = useState<MergeCompanyStatus>(MergeCompanyStatus.MergingOrders);

  const resetMergeProcess = () => {
    setIndexToProcess(0);
    setMergeStatus(MergeCompanyStatus.MergingOrders);
  };

  const companyToKeepProfileUrl = `../phonebook/company/profile?id=${companyToKeep.id}`;
  const isAllOrdersSuccessful = orders.every((o) => o.isSuccessful);

  const mergeOrder = async (orderToMerge: ContactOrder) => {
    const { data } = await axios.post("/api/clientphonebook/companies/updateOrderLookupCode", {
      contactOrder: orderToMerge,
      oldLookupCode: orderToMerge.lookupCode,
      newLookupCode: companyToKeep.lookupCode
    });
    return data;
  };

  const deactivateCompanies = async (companyToKeep: Company, companiesToDeactivate: Company[]) => {
    if (companiesToDeactivate.length == 0 || !companyToKeep?.id) {
      setMergeStatus(MergeCompanyStatus.MergingCompanyFailed);
      return;
    }
    setMergeStatus(MergeCompanyStatus.DeactivatingCompanies);
    const { data: isMergeSuccessful } = await axios.post(
      "/api/clientphonebook/companies/deactivateCompaniesAfterMerge",
      {
        companyToKeep,
        companiesToDeactivate
      }
    );

    const mergeCompanyStatus = isMergeSuccessful
      ? MergeCompanyStatus.MergeOrdersAndCompanySuccessful
      : MergeCompanyStatus.MergingCompanyFailed;
    setMergeStatus(mergeCompanyStatus);
  };

  useEffect(() => {
    if (indexToProcess != orders.length) return;
    if (!isAllOrdersSuccessful) {
      setFailedAttemptCount((previousCount) => previousCount + 1);
      setMergeStatus(MergeCompanyStatus.MergingOrdersFailed);
      return;
    }

    deactivateCompanies(companyToKeep, companiesToDeactivate);
  }, [indexToProcess]);

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="md">
        <CustomHeader
          mergeStatus={mergeStatus}
          lookupCodeToKeep={companyToKeep.lookupCode}
          failedAttemptCount={failedAttemptCount}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell>Role in Order</TableCell>
                <TableCell>Closing Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => {
                return (
                  <MergedOrderRow
                    key={order.orderNumber}
                    order={order}
                    indexToProcess={indexToProcess}
                    nextIndex={() => setIndexToProcess((i) => i + 1)}
                    index={index}
                    mergeOrder={mergeOrder}
                    setOrdersSuccessStatus={setOrdersSuccessStatus}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MergeOrdersActions
          resetMergeProcess={resetMergeProcess}
          navigateToProfile={companyToKeepProfileUrl}
          mergeStatus={mergeStatus}
        />
      </Dialog>
    </>
  );
};

export default MergeOrdersProgressModal;

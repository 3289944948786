// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Card, Typography, CardHeader, Box, Button, Stack } from "@mui/material";
import Label from "../../../../minimals/components/label";
import { Company, CompanyBranch, Contact } from "../../types";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const NonActiveBox = styled("div")(({ theme }) => ({
  padding: "12px 24px",
  width: "100%"
}));

interface IProps {
  company: Company | undefined;
  activeBranchId?: number | undefined;
  amountOfBranchesToDisplay?: number;
  isDisplayMoreLocations?: boolean;
}

export default function Branches(props: IProps) {
  const theme = useTheme();
  const { company, activeBranchId, amountOfBranchesToDisplay = 10000, isDisplayMoreLocations } = props;

  const headquarters = company?.branches.filter((b) => b.isHeadquarters && b.address1);
  const activeBranch = company?.branches.find((b) => b.id === activeBranchId);

  const nonActiveBranchesToDisplay = company?.branches
    .filter((b) => !b.isHeadquarters && b.id != activeBranchId)
    .slice(0, amountOfBranchesToDisplay);

  const nonHeadquaterBranchesToDisplay = [activeBranch, ...(nonActiveBranchesToDisplay || [])].filter(
    (b) => b?.address1 && !b.isHeadquarters
  ) as CompanyBranch[];

  const branchesNotDisplayedCount = (company?.branches?.length || 0) - (headquarters?.length || 0) - 2;
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Company Branches</Typography>} />
      <Box paddingTop={3}>
        {headquarters &&
          headquarters.map((b) => (
            <NonActiveBox>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="subtitle2">{b.city} Headquarters</Typography>
                  <Typography variant="caption" color="text.disabled" display="block">
                    {b.address1}
                  </Typography>
                  <Typography variant="caption" color="text.disabled" display="block">
                    {b.city}
                    {b.city && b.state && ","} {b.state} {b.zip}
                  </Typography>
                </Box>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  {b.id === activeBranchId && (
                    <Label variant="soft" color="success">
                      Located at
                    </Label>
                  )}
                  {b.isHeadquarters && (
                    <Label variant="soft" color="warning">
                      HQ
                    </Label>
                  )}
                </Stack>
              </Stack>
            </NonActiveBox>
          ))}
        {nonHeadquaterBranchesToDisplay &&
          nonHeadquaterBranchesToDisplay.map((b) => (
            <Box
              sx={{
                backgroundColor: b.id === activeBranchId ? theme.palette.info.lighter : "inherit",
                padding: "12px 24px",
                width: "100%"
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="subtitle2">{b.city} Branch</Typography>
                  <Typography variant="caption" color="text.disabled" display="block">
                    {b.address1}
                  </Typography>
                  <Typography variant="caption" color="text.disabled" display="block">
                    {b.city}
                    {b.city && b.state && ","} {b.state} {b.zip}
                  </Typography>
                </Box>
                {b.id === activeBranchId && (
                  <Label variant="soft" color="success">
                    Located at
                  </Label>
                )}
              </Stack>
            </Box>
          ))}
      </Box>
      {isDisplayMoreLocations && (
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            paddingBottom: 3,
            marginTop: 1
          }}
        >
          <Button
            size="small"
            sx={{ textTransform: "unset" }}
            onClick={() => navigate(`../phonebook/company/profile?id=${company?.id}`)}
          >
            View {branchesNotDisplayedCount > 0 && `+${branchesNotDisplayedCount}`} locations in&nbsp;
            <span style={{ textDecoration: "underline" }}>company profile</span>
          </Button>
        </Box>
      )}
    </Card>
  );
}

import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useContext } from "react";
import Count from "./Count";
import ReassignButtonAndModal from "../ReassignButtonAndModal";
import { DashboardReportType } from "../../checks/types";
import { EscrowOverdueOrdersContext } from "./EscrowOverdueOrdersContext";

interface IProps {
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  count: number;
  hideAssignedToMeOptions: boolean;
  table: any;
  fetchTableData: Function;
  reportType: DashboardReportType;
  onRowsDeselected?: () => void;
}

export default function Actions({
  assignedToMe,
  setAssignedToMe,
  count,
  hideAssignedToMeOptions,
  reportType,
  table,
  fetchTableData,
  onRowsDeselected
}: IProps) {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Count count={count} />
      {!hideAssignedToMeOptions && (
        <FormControlLabel
          control={<Checkbox checked={assignedToMe} onChange={(_, value) => setAssignedToMe(value)} />}
          label="Assigned to me"
        />
      )}
      <ReassignButtonAndModal
        table={table}
        fetchTableData={fetchTableData}
        reportType={reportType}
        onRowsDeselected={onRowsDeselected}
      />
    </Stack>
  );
}

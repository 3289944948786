import { Box, Card, CardContent, CardHeader, Divider, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { InsuranceAuditOrderInfo } from "./types";

export default function InsuranceAuditOrderInfoCard() {
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");
  const [insuranceAuditOrderInfo, setInsuranceAuditOrderInfo] = useState<InsuranceAuditOrderInfo>();

  const getInsuranceOrderInfo = async () => {
    const { data } = await axios.get(`/api/insuranceaudit/getinsuranceorderinfo?orderNumber=${order}`);
    setInsuranceAuditOrderInfo(data);
  };

  useEffect(() => {
    if (!order) return;
    getInsuranceOrderInfo();
  }, [order]);

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={3}>
          {/* <Stack direction="column" spacing={1}> */}
          {insuranceAuditOrderInfo?.closer && (
            <Box>
              <Typography variant="caption">Closer Name:</Typography>
              <Typography>{insuranceAuditOrderInfo?.closer}</Typography>
            </Box>
          )}
          {insuranceAuditOrderInfo?.underwriter && (
            <Box>
              <Typography variant="caption">Underwriter Name:</Typography>
              <Typography>{insuranceAuditOrderInfo?.underwriter}</Typography>
            </Box>
          )}
          {insuranceAuditOrderInfo?.loanAmount && (
            <Box>
              <Typography variant="caption">Loan Amount:</Typography>
              <Typography>${insuranceAuditOrderInfo?.loanAmount.toLocaleString()}</Typography>
            </Box>
          )}
          {insuranceAuditOrderInfo?.closingDate && (
            <Box>
              <Typography variant="caption">Closing Date:</Typography>
              <Typography>{new Date(insuranceAuditOrderInfo.closingDate).toLocaleDateString()}</Typography>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

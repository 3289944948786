import { Tooltip } from "@mui/material";

type Prop = {
  identifier: string | undefined;
};

export default function OpenInSelectButton({ identifier }: Prop) {
  return (
    <Tooltip title="View in Select">
      <a href={`sp-select://./ordertracking/order?id=${identifier}`}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.688 3.07181C11.8863 2.97606 12.1137 2.97606 12.312 3.07181L22.5557 8.01749C23.2957 8.3748 23.0563 9.55353 22.2436 9.55353H21.8193V18C21.8193 18.5523 21.3716 19 20.8193 19H10.6606V17.4286C10.6606 17.2777 10.633 17.1333 10.5828 17H19.5532V14.5882H12V13H19.5532V10.5H4.44627V11.42H2.42857C2.34442 11.42 2.26154 11.4254 2.18023 11.4359V9.55353H1.75638C0.94373 9.55353 0.704272 8.3748 1.44433 8.01749L11.688 3.07181ZM4.44627 8.5L12 5L19.5532 8.5H4.44627Z"
            fill="#6088BC"
          ></path>
          <path
            d="M6 11.7143C6 11.3198 6.3198 11 6.71429 11H10.2857C10.6802 11 11 11.3198 11 11.7143V15.2857C11 15.6802 10.6802 16 10.2857 16C9.89122 16 9.57143 15.6802 9.57143 15.2857V13.4387L5.07651 17.9336C4.79756 18.2126 4.3453 18.2126 4.06635 17.9336C3.78741 17.6547 3.78741 17.2024 4.06635 16.9235L8.56128 12.4286H6.71429C6.3198 12.4286 6 12.1088 6 11.7143Z"
            fill="#6088BC"
          ></path>
          <path
            d="M1 13.8571C1 13.0682 1.63959 12.4286 2.42857 12.4286H4.57143C4.96592 12.4286 5.28571 12.7484 5.28571 13.1429C5.28571 13.5373 4.96592 13.8571 4.57143 13.8571L2.42857 13.8571V19.5714H8.14286V17.4286C8.14286 17.0341 8.46265 16.7143 8.85714 16.7143C9.25163 16.7143 9.57143 17.0341 9.57143 17.4286V19.5714C9.57143 20.3604 8.93184 21 8.14286 21H2.42857C1.63959 21 1 20.3604 1 19.5714V13.8571Z"
            fill="#6088BC"
          ></path>
        </svg>
      </a>
    </Tooltip>
  );
}

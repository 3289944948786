import { Box, Typography } from "@mui/material";

interface IProps {
  count: number;
}

export default function Count({ count }: IProps) {
  return (
    <Box>
      <Typography color="#637381" display="inline-block" variant="subtitle1" marginRight={1}>
        Count:
      </Typography>
      <Typography variant="body1" display="inline-block">
        {count}
      </Typography>
    </Box>
  );
}

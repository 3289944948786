import { InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Iconify from "../../../../../minimals/components/iconify";

export default function InvoicesFilter() {
  return (
    <>
      <DatePicker
        label="Start Date"
        onChange={(newValue) => null}
        value={null}
        slotProps={{ textField: { sx: { width: 160, marginRight: 2 } } }}
      />
      <DatePicker
        label="End Date"
        onChange={(newValue) => null}
        value={null}
        slotProps={{ textField: { sx: { width: 160, marginRight: 2 } } }}
      />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" />
            </InputAdornment>
          )
        }}
        placeholder="Search..."
        sx={{ width: 368 }}
      />
    </>
  );
}

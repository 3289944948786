export const getType = (id: number | undefined) => {
  switch (id) {
    case 0:
      return "Mortgage";
    case 1:
      return "Deed";
    default:
      return "";
  }
};

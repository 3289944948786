import { OrderRow } from "./types";

export const getOrderNumberDisplay = ({
  orderNumber,
  titleCompanyPrefix
}: {
  orderNumber: string;
  titleCompanyPrefix: string;
}) => {
  if (!orderNumber) return "";

  const startsWithNumber = /\d/.test(orderNumber![0]);
  if (!startsWithNumber || !titleCompanyPrefix) {
    return orderNumber;
  }

  return `${titleCompanyPrefix.toUpperCase()}-${orderNumber}`;
};

export const orderNumberFilterFn = (row: { original: OrderRow }, _: string, filterValue: any) => {
  const { titleCompanyPrefix, orderNumber } = row.original;
  const orderNumberWithPrefix = titleCompanyPrefix ? `${titleCompanyPrefix}-${orderNumber}` : orderNumber;
  return orderNumberWithPrefix.toLowerCase().includes(filterValue.toLowerCase());
};

import { Card, Typography, CardHeader, Stack, Box, Link, Grid, Tooltip } from "@mui/material";
import { useContext } from "react";
import { ContactContext } from "../ContactContext";
import Phone from "../../shared/phones/Phone";
import { StyledIcon } from "../../../components/StyledIcon";
import Label from "../../../../minimals/components/label";
import { Link as RouterLink } from "react-router-dom";
import { Phone as PhoneType } from "../../types";

// ----------------------------------------------------------------------

const sortPhoneNumbers = (phones: PhoneType[]): PhoneType[] => {
  const typePriority: { [key: string]: number } = {
    Business: 1,
    Mobile: 2,
    Fax: 3
  };

  return [...phones].sort((a, b) => {
    const aPriority = typePriority[a.type] || 4;
    const bPriority = typePriority[b.type] || 4;
    return aPriority - bPriority;
  });
};

export default function About() {
  const { contact } = useContext(ContactContext);
  const professionIsSameAsRole = contact?.profession?.name === contact?.contactRoles[0]?.role?.name;
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6">
            {contact?.firstName} {contact?.middleName} {contact?.lastName}{" "}
            <Typography variant="caption">{contact?.suffix}</Typography>
          </Typography>
        }
      />
      <Stack spacing={2} sx={{ p: 3 }}>
        {!professionIsSameAsRole && contact?.profession?.name && (
          <Stack direction="row">
            <Tooltip title="Profession" placement="top-start">
              <StyledIcon icon="ion:school" />
            </Tooltip>
            <Typography variant="body2">{contact?.profession?.name}</Typography>
          </Stack>
        )}
        {contact?.contactRoles[0].role?.name && (
          <Stack direction="row">
            <Tooltip title="Role" placement="top-start">
              <StyledIcon icon="eva:briefcase-fill" />
            </Tooltip>
            <Typography variant="body2">{contact?.contactRoles[0].role.name}</Typography>
          </Stack>
        )}
        {contact?.contactRoles.length === 0 && (
          <Stack direction="row">
            <Typography>No Information</Typography>
          </Stack>
        )}
        {contact?.contactRoles.map((r) => {
          const rolePhoneNumbers = r.phoneNumbers || [];
          const contactPhonesNumbers = contact.phoneNumbers || [];
          const allPhoneNumbers = [...rolePhoneNumbers, ...contactPhonesNumbers].filter((n) => n.number);
          const allPhoneNumbersSorted = sortPhoneNumbers(allPhoneNumbers);

          {
            r.phoneNumbers?.map((p) => <Phone phone={p} />);
          }
          {
            contact.phoneNumbers?.map((p) => {
              if (!p.number) {
                return;
              }
              return <Phone phone={p} />;
            });
          }

          return (
            <>
              {r.branch?.address1 && (
                <Stack direction="row">
                  <Tooltip title="Location" placement="top-start">
                    <StyledIcon icon="eva:pin-fill" />
                  </Tooltip>
                  <Box>
                    {contact.contactRoles[0]?.branch?.companyId && (
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography variant="subtitle2">
                            <RouterLink
                              to={`../phonebook/company/profile?id=${r.branch.company.id}`}
                              style={{
                                color: "#212B36"
                              }}
                            >
                              {r.branch.company.name}
                            </RouterLink>
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {r.branch.isHeadquarters && <Label color="warning">HQ</Label>}
                        </Grid>
                      </Grid>
                    )}
                    <Typography variant="body2">{r.branch?.address1}</Typography>
                    {r.branch.address2 && <Typography variant="body2">{r.branch?.address2}</Typography>}
                    <Typography variant="body2">
                      {r.branch?.city} {r.branch?.state} {r.branch?.zip}
                    </Typography>
                  </Box>
                </Stack>
              )}

              {r.businessEmail && (
                <Stack direction="row">
                  <Tooltip title="Email" placement="top-start">
                    <StyledIcon icon="eva:email-fill" />
                  </Tooltip>
                  <Typography variant="body2">
                    <Link color="initial" href={`mailto:${r.businessEmail}`}>
                      {r.businessEmail}
                    </Link>
                  </Typography>
                </Stack>
              )}
              {allPhoneNumbersSorted.map((p) => (
                <Phone phone={p} />
              ))}
            </>
          );
        })}
      </Stack>
    </Card>
  );
}

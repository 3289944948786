import { styled } from "@mui/material";

const StyledLoadingSpan = styled("span")({
  "@keyframes l": {
    to: {
      clipPath: "inset(0 -1ch 0 0)"
    }
  },
  fontWeight: "bold",
  display: "inline-block",
  fontFamily: "monospace",
  fontSize: "15px",
  clipPath: "inset(0 3ch 0 0)",
  animation: "l 1s steps(4) infinite"
});

const DotLoader = () => (
  <span>
    <StyledLoadingSpan>...</StyledLoadingSpan>
  </span>
);
export default DotLoader;

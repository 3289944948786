import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { ContactWithSuspectedMatch } from "../../phonebook/types";
import { DateRange } from "@mui/x-date-pickers-pro";
import axios from "axios";
import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { suspectedMatchesColums } from "./SuspectedMatchesColumns";
import SuspectedMatchesActions from "./SuspectedMatchesActions";
import Iconify from "../../../minimals/components/iconify";

export default function SuspectedMatches() {
  const today = dayjs();
  // const defaultEndDate = dayjs("08/12/2024").endOf("day");
  // const defaultStartDate = dayjs("08/07/2024").startOf("day");
  const defaultEndDate = today.endOf("day");
  const defaultStartDate = today.subtract(7, "days").startOf("day");

  const [loading, setLoading] = useState(true);
  const [suspectedMatch, setSuspectedMatch] = useState<ContactWithSuspectedMatch[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [enableOrdering, setEnableOrdering] = useState(false);

  const getSuspectedMatches = async () => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    const fromParam = (startDate?.startOf("day") || defaultStartDate).format("YYYY-MM-DD");
    const toParam = (endDate?.endOf("day") || defaultEndDate).format("YYYY-MM-DD");

    const url = `/api/clientphonebook/contacts/getSuspectedMatches?from=${fromParam}&to=${toParam}`;
    const { data } = await axios.get(url);
    setSuspectedMatch(data);
    setLoading(false);
  };

  useEffect(() => {
    getSuspectedMatches();
  }, [dateRange]);

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Suspected Matches
        </Typography>
        <MaterialReactTable
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              style: row.index % 2 === 0 ? {} : { backgroundColor: "#f4f4f4" }
            };
          }}
          columns={suspectedMatchesColums({ setSuspectedMatch, suspectedMatch })} // Pass state setter
          data={suspectedMatch}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility: {
              transactionType: false,
              voidedOn: false,
              status: false,
              extendedMemo: false
            }
          }}
          state={{
            showSkeletons: loading
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <SuspectedMatchesActions dateRange={dateRange} setDateRange={setDateRange} table={table} />
                <Tooltip title="Column ordering">
                  <IconButton
                    onClick={() => setEnableOrdering(!enableOrdering)}
                    aria-label="toggle column ordering"
                    style={{ position: "absolute", right: "200px" }}
                  >
                    <Iconify icon="mdi:reorder-vertical" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }}
        />
      </Container>
    </>
  );
}

import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "../../minimals/components/iconify";
import Label from "../../minimals/components/label";
import axios from "axios";
import { InternalUser } from "../types/app";
import { useQueryParam, StringParam } from "use-query-params";
import useDebounce from "../../hooks/useDebounce";
import ActiveStatusLabel from "../shared/ActiveStatusLabel";

export default function ManagersTable() {
  const [tableData, setTableData] = useState<InternalUser[]>([]);
  const [searchText, setSearchText] = useQueryParam("searchText", StringParam);
  const debouncedSearchText = useDebounce(searchText || "", 500);
  const navigate = useNavigate();

  const headers = ["User", "Email", "Role @ Department - Team", "Active", "Action"];

  const searchInternalUsersWithRoles = async (searchText: string) => {
    const { data } = await axios.get<InternalUser[]>(
      `/api/clientphonebook/internalusers/searchWithRoles?searchText=${searchText}`
    );
    setTableData(data);
  };

  const handleSearchChange = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (debouncedSearchText) {
      searchInternalUsersWithRoles(debouncedSearchText);
    } else {
      setTableData([]);
    }
  }, [debouncedSearchText]);

  const renderTableBody = () => {
    if (!debouncedSearchText) {
      return (
        <TableRow>
          <TableCell colSpan={headers.length} align="center">
            <Typography variant="subtitle2">Please type to search</Typography>
          </TableCell>
        </TableRow>
      );
    }
    if (!!debouncedSearchText && tableData.length == 0) {
      return (
        <TableRow>
          <TableCell colSpan={headers.length} align="center">
            <Typography variant="subtitle2">No search results found</Typography>
          </TableCell>
        </TableRow>
      );
    }
    return tableData.map((row) => {
      const { id, fullName, email, internalUserRoles, isActiveSelectUser } = row;
      return (
        <TableRow key={fullName}>
          <TableCell>{fullName}</TableCell>
          <TableCell>{email?.toLowerCase()}</TableCell>
          <TableCell>
            {internalUserRoles?.map((role, index) => (
              <Label key={index} marginRight={2} variant="soft" color="success">
                {role?.internalUserRoleType?.name} @ {role?.internalUserDepartment?.name} -{" "}
                {role?.internalUserTeam?.name}
              </Label>
            ))}
          </TableCell>
          <TableCell>
            <ActiveStatusLabel isActive={isActiveSelectUser} />
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                navigate(`/internal-user-roles/edit/${id}`);
              }}
            >
              <Iconify icon="mdi:lead-pencil" />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <Typography variant="h4" pb={2}>
        User Roles, Teams, and Departments
      </Typography>
      <Card>
        <Box sx={{ p: 2 }}>
          <TextField
            value={searchText}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                </InputAdornment>
              )
            }}
          ></TextField>
        </Box>

        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 720, textAlign: "left" }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <Box sx={{ p: 2, textAlign: "right", alignItems: "center" }}>
          <Switch /> Dense
        </Box>
      </Card>
    </>
  );
}

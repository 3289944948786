import { Dispatch, SetStateAction, useContext } from "react";
import { Company, CompanyBranch, Contact, ContactRep, Phone, Role } from "../../types";
import { AddEditContactContext } from "../addEditContact/AddEditContactContext";
import { Stack, Typography } from "@mui/material";
import DiscrepancyCardContent from "./DiscrepancyCardContent";
import PhoneNumberDiscrepancyCard from "./PhoneNumberDiscrepancyCard";
import { discrepancyKeyAndNameList } from "./discrepancyKeyAndNameList";
import { AccountRepDiscrepancy } from "./Discrepancies";
import { InternalUser } from "../../../types/app";

interface PhoneDiscrepancy {
  type: string;
  discrepancies: Phone[];
}
export default function DiscrepancyCards({
  setTheInput,
  keysDiscrepancies,
  emailDiscrepancies,
  phoneNumberDiscrepancies,
  roleDiscrepancies,
  locationDiscrepancies,
  accountRepDiscrepancies,
  professionDiscrepancies,
  salesRepDiscrepancies
}: {
  setTheInput: Dispatch<SetStateAction<string>>;
  keysDiscrepancies: (keyof Contact)[];
  emailDiscrepancies: string[];
  phoneNumberDiscrepancies: PhoneDiscrepancy[];
  roleDiscrepancies: (Role | null)[];
  locationDiscrepancies: CompanyBranch[];
  accountRepDiscrepancies: AccountRepDiscrepancy[];
  professionDiscrepancies: (Role | null)[];
  salesRepDiscrepancies: ContactRep[][];
}) {
  const {
    mergingContacts,
    handleContactChange,
    contact,
    handleRoleTypeChange,
    selectedCompanyBranch,
    getAndSetSelectedCompany,
    handleContactRoleChange,
    handleCompanyBranchChange,
    handleProfessionTypeChange,
    setContact,
    handleAccountRepChange
  } = useContext(AddEditContactContext);
  const firstContact = mergingContacts[0];
  const secondContact = mergingContacts[1];

  const firstContactCompanyAndBranchOnChange = () => {
    if (firstContact.contactRoles[0]?.branch?.companyId && firstContact.contactRoles[0]?.branch?.id) {
      getAndSetSelectedCompany(
        firstContact.contactRoles[0]?.branch?.companyId,
        firstContact.contactRoles[0]?.branch?.id
      );
    }
    if (firstContact.contactRoles[0]?.branch?.id && !firstContact.contactRoles[0]?.branch?.companyId) {
      handleCompanyBranchChange(firstContact.contactRoles[0]?.branch);
    }
  };

  const secondContactCompanyAndBranchOnChange = () => {
    if (secondContact.contactRoles[0]?.branch?.companyId && secondContact.contactRoles[0]?.branch?.id) {
      getAndSetSelectedCompany(
        secondContact.contactRoles[0]?.branch?.companyId,
        secondContact.contactRoles[0]?.branch?.id
      );
    }
    if (secondContact.contactRoles[0]?.branch?.id && !secondContact.contactRoles[0]?.branch?.companyId) {
      handleCompanyBranchChange(secondContact.contactRoles[0]?.branch);
    }
  };

  const personalSectionKeyAndNames = discrepancyKeyAndNameList.filter((kn) => kn.isPersonalSection);

  const nonPersonalSectionKeyAndNames = discrepancyKeyAndNameList.filter((kn) => !kn.isPersonalSection);
  const initialContactValueSalesReps = contact.contactReps.filter((rep) => rep.contactRepTypeId === 1);

  const handleSalesRepChange = (reps: ContactRep[]) => {
    if (!reps) {
      return;
    }
    setContact((draft) => {
      const tempContactReps = draft.contactReps.filter((sr) => sr.contactRepTypeId !== 1);
      //Update or Add rep
      for (let rep of reps) {
        tempContactReps.push(rep);
      }
      draft.contactReps = tempContactReps;
    });
  };
  return (
    <Stack>
      <Typography variant="h6">Discrepancies:</Typography>
      <Typography variant="body2" fontSize={15}>
        Choose which to keep
      </Typography>
      {nonPersonalSectionKeyAndNames.map((kn) => {
        var key = keysDiscrepancies.find((d) => kn.key === d);
        if (key !== undefined && key in contact) {
          const contactKey = key as keyof Contact;
          return (
            <DiscrepancyCardContent
              type={kn.friendlyName}
              onMouseOver={() => setTheInput(kn.friendlyName)}
              firstContactValue={firstContact[contactKey]}
              secondContactValue={secondContact[contactKey]}
              initialContact={contact[contactKey]}
              handleFirstContactChange={() => handleContactChange(contactKey, firstContact[contactKey])}
              handleSecondContactChange={() => handleContactChange(contactKey, secondContact[contactKey])}
            />
          );
        }
      })}
      {emailDiscrepancies.length > 0 && (
        <DiscrepancyCardContent
          type={"Business Email"}
          onMouseOver={() => setTheInput("Business Email")}
          firstContactValue={firstContact.contactRoles[0].businessEmail}
          secondContactValue={secondContact.contactRoles[0].businessEmail}
          initialContact={contact.contactRoles[0].businessEmail}
          handleFirstContactChange={() =>
            handleContactRoleChange("businessEmail", firstContact.contactRoles[0].businessEmail)
          }
          handleSecondContactChange={() =>
            handleContactRoleChange("businessEmail", secondContact.contactRoles[0].businessEmail)
          }
          firstContactCompanyName={firstContact.contactRoles[0].branch?.company?.name}
          secondContactCompanyName={secondContact.contactRoles[0].branch?.company?.name}
        />
      )}
      <PhoneNumberDiscrepancyCard setTheInput={setTheInput} phoneNumberDiscrepancies={phoneNumberDiscrepancies} />
      {salesRepDiscrepancies.length > 0 && (
        <DiscrepancyCardContent
          type={"Sales Rep"}
          onMouseOver={() => setTheInput("Sales Rep")}
          firstContactValue={salesRepDiscrepancies[0]
            .map((sr) => `${sr.internalUser?.firstName || ""} ${sr.internalUser?.lastName || ""} `)
            .join(", ")}
          secondContactValue={salesRepDiscrepancies[1]
            .map((sr) => `${sr.internalUser?.firstName || ""} ${sr.internalUser?.lastName || ""} `)
            .join(", ")}
          initialContact={initialContactValueSalesReps
            .map((sr) => `${sr.internalUser?.firstName} ${sr.internalUser?.lastName} `)
            .join(", ")}
          handleFirstContactChange={() => {
            handleSalesRepChange(salesRepDiscrepancies[0]);
          }}
          handleSecondContactChange={() => {
            handleSalesRepChange(salesRepDiscrepancies[1]);
          }}
        />
      )}
      {accountRepDiscrepancies.map((a) => {
        const initialContactValue = contact.contactReps.find((rep) => {
          return rep.contactRepTypeId === a.contactRepTypeId;
        })?.internalUser;
        return (
          <DiscrepancyCardContent
            type={a.header}
            onMouseOver={() => setTheInput("Account Rep")}
            firstContactValue={`${a.contactReps[0].internalUser?.firstName} ${a.contactReps[0].internalUser?.lastName}`}
            secondContactValue={`${a.contactReps[1].internalUser?.firstName} ${a.contactReps[1].internalUser?.lastName}`}
            initialContact={`${initialContactValue?.firstName} ${initialContactValue?.lastName}`}
            handleFirstContactChange={() => {
              handleAccountRepChange(a.contactReps[0].internalUser as InternalUser, a.contactRepTypeId);
            }}
            handleSecondContactChange={() => {
              handleAccountRepChange(a.contactReps[1].internalUser as InternalUser, a.contactRepTypeId);
            }}
          />
        );
      })}
      {roleDiscrepancies.length > 0 && (
        <DiscrepancyCardContent
          isRoleDiscrepancy
          type={"Role"}
          onMouseOver={() => setTheInput("Role")}
          firstContactValue={firstContact.contactRoles[0].roleId}
          secondContactValue={secondContact.contactRoles[0].roleId}
          initialContact={contact.contactRoles[0].roleId}
          handleFirstContactChange={() => handleRoleTypeChange(firstContact.contactRoles[0].role)}
          handleSecondContactChange={() => handleRoleTypeChange(secondContact.contactRoles[0].role)}
          firstContactRole={firstContact.contactRoles[0].role?.name}
          secondContactRole={secondContact.contactRoles[0].role?.name}
        />
      )}
      {professionDiscrepancies.length > 0 && (
        <DiscrepancyCardContent
          type={"Profession"}
          onMouseOver={() => setTheInput("Profession")}
          firstContactValue={firstContact?.profession?.name}
          secondContactValue={secondContact?.profession?.name}
          initialContact={contact?.profession?.name}
          handleFirstContactChange={() => handleProfessionTypeChange(firstContact?.profession)}
          handleSecondContactChange={() => handleProfessionTypeChange(secondContact?.profession)}
        />
      )}
      {locationDiscrepancies.length > 0 && (
        <DiscrepancyCardContent
          isLocationDiscrepancy
          type={"Company & Location"}
          onMouseOver={() => setTheInput("Location")}
          firstContactValue={firstContact.contactRoles[0].branch?.address1}
          secondContactValue={secondContact.contactRoles[0].branch?.address1}
          initialContact={selectedCompanyBranch?.address1}
          handleFirstContactChange={firstContactCompanyAndBranchOnChange}
          handleSecondContactChange={secondContactCompanyAndBranchOnChange}
          firstContactCompanyName={firstContact.contactRoles[0].branch?.company?.name}
          secondContactCompanyName={secondContact.contactRoles[0].branch?.company?.name}
        />
      )}
      {personalSectionKeyAndNames.map((kn) => {
        var key = keysDiscrepancies.find((d) => kn.key === d);
        if (key !== undefined && key in contact) {
          const contactKey = key as keyof Contact;
          return (
            <DiscrepancyCardContent
              type={kn.friendlyName}
              onMouseOver={() => setTheInput(kn.friendlyName)}
              firstContactValue={firstContact[contactKey]}
              secondContactValue={secondContact[contactKey]}
              initialContact={contact[contactKey]}
              handleFirstContactChange={() => handleContactChange(contactKey, firstContact[contactKey])}
              handleSecondContactChange={() => handleContactChange(contactKey, secondContact[contactKey])}
            />
          );
        }
      })}
    </Stack>
  );
}

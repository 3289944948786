import { Button, Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import HeaderTitle from "./HeaderTitle";
import axios from "axios";
import { CompanyContext } from "../../company/CompanyContext";

interface IProps {
  companyId: number;
  emailPolicyTo?: string;
}

export default function EmailPolicyToCard({ companyId, emailPolicyTo = "" }: IProps) {
  const { setCompany } = useContext(CompanyContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editValue, setEditValue] = useState(emailPolicyTo);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { data } = await axios.put(`/api/clientphonebook/companies/updateEmailPolicyTo`, {
      companyId,
      emailPolicyTo: editValue
    });

    if (data) {
      setCompany((draft) => {
        if (draft) {
          draft.emailPolicyTo = data.emailPolicyTo;
        }
      });
    }
    setIsSubmitting(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setEditValue(emailPolicyTo);
    setIsEditMode(false);
  };

  const viewModeCard = () => {
    return (
      <Card>
        <CardHeader title={<HeaderTitle title="Email Policy To" handleEditClick={() => setIsEditMode(true)} />} />
        <CardContent>
          <Stack spacing={1.5}>
            <Typography variant="body2" display="inline-block">
              {emailPolicyTo}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const editModeCard = () => {
    return (
      <Card>
        <CardHeader title={<HeaderTitle title="Email Policy To" />} />
        <CardContent>
          <Stack spacing={1.5}>
            <TextField
              fullWidth
              label="Email Policy To"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
            <Stack direction="row" justifyContent="right" spacing={1.5}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return isEditMode ? editModeCard() : viewModeCard();
}

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { UploadMultiFile } from "../components/upload";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { CorpDocType } from "./types";
import { useSearchParams } from "react-router-dom";
import { toBase64 } from "../utils/helpers";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";

interface IProps {
  open: boolean;
  onClose: () => void;
  updateCorpDocData: () => void;
}

export default function NewCorpDoc({ open, onClose, updateCorpDocData }: IProps) {
  const [searchParams] = useSearchParams();

  const order = searchParams.get("order");
  const [types, setTypes] = useState<CorpDocType[]>([]);
  const [selectedType, setSelectedType] = useState<CorpDocType | null>(null);
  const [additionalDescription, setAdditionalDescription] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [attemptedSave, setAttemptedSave] = useState<boolean>(false);

  const getAndSetTypes = async () => {
    const { data } = await axios.get<CorpDocType[]>("/api/corpdocstracking/corpdocs/getTypes");
    setTypes(data);
  };

  const handleFileDrop = useCallback(
    (acceptedFile: any) => {
      const file = Object.assign(acceptedFile[0], {
        preview: URL.createObjectURL(acceptedFile[0])
      });
      setFile(file);
    },
    [setFile]
  );

  const save = async () => {
    setAttemptedSave(true);
    if (!file || !order || !selectedType) {
      return;
    }
    setSubmitting(true);
    const base64 = stripDataUriPrefix(await toBase64(file));
    await axios.post("/api/corpdocstracking/corpdocs/add", {
      orderNumber: order,
      type: selectedType?.id,
      description: additionalDescription,
      documentBase64: base64,
      fileName: file.name
    });
    await updateCorpDocData();
    setSubmitting(false);
    onClose();
  };

  useEffect(() => {
    getAndSetTypes();
  }, []);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add a Corp Doc Document</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} paddingTop={1}>
          <Grid item xs={6}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={attemptedSave && !selectedType}
                  size="small"
                  fullWidth
                  label="Document Type"
                />
              )}
              options={types}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => setSelectedType(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Additional Description"
              onChange={(e) => setAdditionalDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadMultiFile
              error={attemptedSave && !file}
              files={file ? [file] : []}
              onDrop={handleFileDrop}
              onRemove={() => setFile(null)}
              onRemoveAll={() => setFile(null)}
              showPreview={false}
              showSelectedFiles={true}
              multiple={false}
            />
          </Grid>
        </Grid>
        <Typography marginTop={3}>
          Uploaded document will be saved to Closing {">"} Proofs {">"}
          <br />
          {selectedType?.name} {additionalDescription && ` - ${additionalDescription}`}{" "}
          {(selectedType?.name || additionalDescription) && "v1"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="soft" disabled={submitting} onClick={save}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { MRT_ColumnDef } from "material-react-table";
import { IAssignable } from "../checks/types";
import NoteCell from "./NoteCell";
import { getComponentColor } from "../checks/PriorityStatus";

export function NotesColumn<T extends IAssignable>(
  columns: any[],
  setSelectedOrder: (order: T) => void
): MRT_ColumnDef<T>[] {
  return columns.map((col) => {
    if (col.id === "notes") {
      return {
        ...col,
        Cell: ({ row }: { row: { original: T } }) => (
          <NoteCell
            row={row.original}
            onNoteClick={(order: T) => {
              setSelectedOrder(order);
            }}
            color={getComponentColor(row.original.priority || "")}
          />
        )
      };
    }
    return col;
  });
}

import { Helmet } from "react-helmet-async";
import Container from "@mui/material/Container";
import { useSettingsContext } from "../contexts/SettingsContext";
import Tasks from "./Tasks";

// ----------------------------------------------------------------------

export default function TasksPage() {
  const settings = useSettingsContext();
  return (
    <>
      <Helmet>
        <title>TitleQ Recordables</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <Tasks />
      </Container>
    </>
  );
}

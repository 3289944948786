import { Card, Stack, CardHeader } from "@mui/material";
import { OrderContext } from "../contexts/OrderDataContext";
import { useContext, useState, useEffect } from "react";
import ProfilePreviewCard from "./ProfilePreviewCard";
import { curry } from "lodash";
import { Contact } from "./types/Contact";

export default function ProfilePreview() {
  const {
    selectedClient,
    buyersAttorney,
    sellersAttorney,
    additionalContacts,
    selectedBuyerPrincipals,
    selectedSellerPrincipals,
    loans
  } = useContext(OrderContext);
  const [contacts, setContacts] = useState<(Contact | null)[]>([]);
  const [openIndexes, setOpenIndexes] = useState<Set<number>>(new Set());

  const setIndexes = (client: Contact | null, contacts: (Contact | null)[]) => {
    var index = contacts.indexOf(client);
    setOpenIndexes(new Set([index]));
  };

  useEffect(() => {
    const existingContactList = contacts;

    // Combine contacts from various sources into a single list
    const updatedContactList = [
      buyersAttorney,
      sellersAttorney,
      selectedClient,
      ...selectedBuyerPrincipals,
      ...selectedSellerPrincipals,
      ...loans.map((l) => l.lender).filter((l: any) => l?.name !== "TBD"),
      ...loans.map((l) => l.lendersAttorney),
      ...loans.map((l) => l.mortgageBroker),
      ...additionalContacts
    ]
      .filter((c) => c?.name) // Only include contacts with a valid name
      // Remove duplicate contacts
      .reduce((acc, current) => {
        const existingContact = acc.find((contact) => contact.name === current?.name);
        if (!existingContact && current) {
          acc.push(current);
        }
        return acc;
      }, [] as Contact[]);

    // Find the new contact added
    const newContact = updatedContactList.find(
      (contact) => !existingContactList.some((existing) => existing?.name === contact.name)
    );

    // Update the contacts state
    setContacts(updatedContactList);

    // Open the new contact if one is found
    if (newContact) {
      setIndexes(newContact, updatedContactList);
    }
  }, [
    sellersAttorney,
    buyersAttorney,
    selectedClient,
    additionalContacts,
    selectedBuyerPrincipals,
    selectedSellerPrincipals,
    loans
  ]);

  const onAccordianClick = (index: number) => {
    const newList = new Set(openIndexes);
    if (openIndexes.has(index)) {
      newList.delete(index);
    } else {
      newList.add(index);
    }
    setOpenIndexes(newList);
  };

  const updateContact = (index: number, contact: Contact) => {
    setContacts((contacts) => contacts.map((c, ind) => (index === ind ? contact : c)));
  };

  const updateCurrentContact = curry(updateContact);

  if (!contacts.length) return <></>;

  return (
    <>
      <Card
        sx={{
          mb: 3,
          position: "sticky",
          top: 62,
          maxHeight: "75vh",
          overflowY: "scroll"
        }}
      >
        <CardHeader title="Added Contacts" />
        <Stack spacing={2} sx={{ p: 3 }}>
          {contacts.map((c, i) => (
            <ProfilePreviewCard
              key={c?.id}
              contact={c}
              updateCurrentContact={updateCurrentContact(i)}
              open={openIndexes.has(i)}
              index={i}
              onAccordianClick={() => onAccordianClick(i)}
            />
          ))}
        </Stack>
      </Card>
    </>
  );
}

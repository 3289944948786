import { DocumentCategory, ConcatenatedDocumentTypes } from "../types";

export function generateFullPaths(data: DocumentCategory[]): ConcatenatedDocumentTypes[] {
  const result: ConcatenatedDocumentTypes[] = [];

  for (const documentType of data) {
    for (const subCategory of documentType.subCategories) {
      for (const description of subCategory.descriptions) {
        const fullPath = `${documentType.name} > ${subCategory.name} > ${description}`;
        result.push({ fullPath, subcategoryId: subCategory.id, description });
      }
    }
  }

  return result;
}

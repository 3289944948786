import { Box, Button, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { BasicTask, StatusCategory, FlipChecklistTaskInfo, TaskGroupEntity, EventType } from "./types";
import TasksTable from "./TasksTable";
import GenerateTasksModal from "./GenerateTasksModal";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../minimals/components/iconify";
import TaskHistory from "./TaskHistory";
import { useOrderInfo } from "../shared/orderInfo/OrderInfoContext";
import TaskOrderInfo from "./orderInfo/TaskOrderInfo";
import AddTaskModal from "./AddTaskModal";
import AlertSyncingWithSelectRefresh from "./AlertSyncingWithSelectRefresh";
import { isBuyerOrBorrower, isLender, isMTA, isSeller } from "./filterConditions";
import { Contact } from "../shared/orderInfo/types";
import Page from "../../minimals/components/page";

const applications = [
  { id: 1, name: "flips", friendlyName: "Flip" },
  { id: 2, name: "closingchecklist", friendlyName: "Closing Checklist" }
];

const excludedContactTypes = [
  "Abstractor",
  "Appraiser",
  "GeneralContractor",
  "Government",
  "HOA",
  "HomeInspector",
  "Surveyor",
  "Underwriter",
  "SettlementAgent"
];

const Tasks = () => {
  const orderInfo = useOrderInfo();
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get("order");
  const { taskType: applicationType } = useParams();
  let applicationTypeFriendlyName = applications.find((a) => a.name === applicationType)?.friendlyName || "";
  const [statusCategories, setStatusCategories] = useState<StatusCategory[]>([]);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [tasks, setTasks] = useState<BasicTask[] | null>(null);
  const [initiateIsPending, setInitiateIsPending] = useState<boolean>(false);
  const [flipChecklistTaskInfo, setFlipChecklistTaskInfo] = useState<FlipChecklistTaskInfo | null>(null);
  const [markAsCompletedIsPending, setMarkAsCompletedIsPending] = useState<boolean>(false);
  const [taskGroupEntity, setTaskGroupEntity] = useState<TaskGroupEntity | null>(null);
  const applicationId = applications.find((a) => a.name === applicationType)?.id || 1;
  const [selectedTaskHistory, setSelectedTaskHistory] = useState<BasicTask | null>(null);
  const [addTaskMode, setAddTaskMode] = useState<boolean>(false);
  const [alertSyncingWithSelectRefresh, setAlertSyncingWithSelectRefresh] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState("All");

  const contacts = orderInfo?.contacts.filter((c) => c.name && !excludedContactTypes.includes(c.type)) || [];

  const initiateTasks = async () => {
    setInitiateIsPending(true);

    if (applicationType === "flips") {
      await axios.post("/api/taskmanagement/flips/initiate", { orderNumber });
      await getAndSetFlipChecklistTask();
    } else if (applicationType === "closingchecklist") {
      await axios.post("/api/taskmanagement/closingchecklist/initiate", { orderNumber });
    }

    await getAndSetTasks();
    await getAndSetTaskGroupEntity();
    setInitiateIsPending(false);
  };

  const getAndSetTasks = async () => {
    const { data } = await axios.get(
      `/api/taskmanagement/tasks/getByOrderNumber?applicationId=${applicationId}&orderNumber=${orderNumber}`
    );
    setTasks(data);
  };

  const getStatusCategories = async () => {
    const { data } = await axios.get(`/api/taskmanagement/tasks/GetStatusCategories`);
    setStatusCategories(data);
  };

  const getEventTypes = async () => {
    const { data } = await axios.get(`/api/taskmanagement/tasks/getEventTypes`);
    setEventTypes(data);
  };

  const getAndSetFlipChecklistTask = async () => {
    const { data } = await axios.get(`/api/taskmanagement/flips/GetFlipChecklistTask?ordernumber=${orderNumber}`);
    setFlipChecklistTaskInfo(data);
  };

  const getAndSetTaskGroupEntity = async () => {
    const { data } = await axios.get(
      `/api/taskmanagement/TaskGroupEntity/getfororder?ordernumber=${orderNumber}&applicationId=${applicationId}`
    );
    setTaskGroupEntity(data);
  };

  const markTaskGroupEntityAsCompleted = async () => {
    setMarkAsCompletedIsPending(true);
    await axios.post(`/api/taskmanagement/taskgroupentity/MarkAsCompleted`, { orderNumber, applicationId });
    await getAndSetFlipChecklistTask();
    await getAndSetTaskGroupEntity();
    setMarkAsCompletedIsPending(false);
  };

  const syncChecklistWithSelect = async () => {
    const { data } = await axios.post(`/api/taskmanagement/closingchecklist/SyncWithSelect`, { orderNumber });
    if (data.hadChanges) {
      setAlertSyncingWithSelectRefresh(true);
    }
  };

  useEffect(() => {
    getStatusCategories();
    getEventTypes();
    getAndSetTasks();
    getAndSetTaskGroupEntity();
    if (applicationType === "flips") {
      getAndSetFlipChecklistTask();
    } else if (applicationType === "closingchecklist") {
      syncChecklistWithSelect();
    }
  }, []);

  if (!applicationType) {
    return <Navigate to="/orders" />;
  }

  const shouldShowFlipMarkAsCompletedButton =
    applicationType === "flips" && taskGroupEntity?.markedAsCompletedBy === null;

  const filteredTasks = useMemo(() => {
    if (applicationType !== "closingchecklist") {
      return tasks;
    }

    return tasks?.filter((t) => {
      const contact = contacts.find((c) => c.code === t.contactCode) as Contact;
      switch (globalFilter) {
        case "Buyer":
        case "Borrower":
          return isBuyerOrBorrower(t, contact);
        case "Seller":
          return isSeller(t, contact);
        case "Lender":
          return isLender(t, contact);
        case "MTA":
          return isMTA(t);
        default:
          return true;
      }
    });
  }, [applicationType, globalFilter, tasks, contacts]);

  return (
      <Page title={orderNumber || ""}>
        <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" color="primary">
              {orderInfo?.titleCode} {orderInfo?.number}
              {!orderInfo && <Skeleton width={200}/>}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            {applicationType === "flips" && flipChecklistTaskInfo && (
                <Stack direction="row" justifyContent="right" spacing={3} alignItems="center" marginBottom={1}>
                  {flipChecklistTaskInfo?.assignedTo && (
                      <Typography variant="subtitle2">
                        Marked as Flip by: <Typography
                          display="inline-block">{flipChecklistTaskInfo?.assignedTo}</Typography>
                      </Typography>
                  )}
                  {flipChecklistTaskInfo?.completedBy && (
                      <Typography variant="subtitle2">
                        Marked{" "}
                        <Box
                            sx={{color: flipChecklistTaskInfo.status === "Completed" ? "success.main" : "error.main"}}
                            display="inline-block"
                        >
                          {flipChecklistTaskInfo.status === "Completed" ? "Approved" : "Rejected"}
                        </Box>{" "}
                        by:{" "}
                        <Typography color="#000" display="inline-block">
                          {flipChecklistTaskInfo?.completedBy}
                        </Typography>
                      </Typography>
                  )}

                  {!flipChecklistTaskInfo?.completedBy && Boolean(taskGroupEntity?.markedAsCompletedBy) && (
                      <Typography variant="subtitle2" color="warning.main">
                        Pending Manager Approval
                      </Typography>
                  )}

                  {shouldShowFlipMarkAsCompletedButton && (
                      <LoadingButton
                          loading={markAsCompletedIsPending}
                          loadingPosition="end"
                          variant="soft"
                          size="medium"
                          startIcon={<Iconify icon="eva:checkmark-circle-fill"/>}
                          onClick={markTaskGroupEntityAsCompleted}
                          sx={{paddingLeft: 5, paddingRight: 5}}
                      >
                        {markAsCompletedIsPending ? "Submitting to Manager" : "Submit to Manager"}
                      </LoadingButton>
                  )}
                </Stack>
            )}
            <Box>
              <Button
                  variant="contained"
                  onClick={() => setAddTaskMode(true)}
                  startIcon={<Iconify icon="eva:plus-fill"/>}
              >
                Add Task
              </Button>
            </Box>
          </Stack>
        </Stack>
        <TaskOrderInfo taskGroupEntity={taskGroupEntity} refreshTaskGroupEntity={getAndSetTaskGroupEntity}/>
        {applicationType === "closingchecklist" && orderInfo && (
            <ToggleButtonGroup
                value={globalFilter}
                color="primary"
                size="small"
                exclusive
                sx={{marginBottom: 2}}
                onChange={(e, value) => setGlobalFilter(value)}
            >
              <ToggleButton value="All">All</ToggleButton>
              <ToggleButton value="MTA">MTA</ToggleButton>
              {orderInfo.transactionType === "Refinance" && <ToggleButton value="Borrower">Borrower</ToggleButton>}
              {orderInfo.transactionType === "Purchase" && <ToggleButton value="Buyer">Buyer</ToggleButton>}
              {orderInfo.loanAmounts && orderInfo?.loanAmounts.length > 0 && (
                  <ToggleButton value="Lender">Lender </ToggleButton>
              )}
              {orderInfo.transactionType === "Purchase" && <ToggleButton value="Seller">Seller</ToggleButton>}
            </ToggleButtonGroup>
        )}

        {filteredTasks && filteredTasks.length > 0 && (
            <TasksTable
                tasks={filteredTasks}
                setTasks={setTasks}
                statusCategories={statusCategories}
                eventTypes={eventTypes}
                refreshTasks={getAndSetTasks}
                orderNumber={orderNumber}
                setSelectedTaskHistory={setSelectedTaskHistory}
                contacts={contacts}
            />
        )}
        {tasks && tasks.length === 0 && (
            <GenerateTasksModal
                initiateTasks={initiateTasks}
                initiateIsPending={initiateIsPending}
                applicationType={applicationType}
                applicationTypeFriendlyName={applicationTypeFriendlyName}
                orderNumber={orderNumber}
            />
        )}
        {selectedTaskHistory && (
            <TaskHistory
                task={selectedTaskHistory}
                eventTypes={eventTypes}
                onClose={() => setSelectedTaskHistory(null)}
            />
        )}
        {addTaskMode && (
            <AddTaskModal
                open={addTaskMode}
                closeModal={() => setAddTaskMode(false)}
                applicationId={applicationId}
                orderNumber={orderNumber}
                refreshTasks={getAndSetTasks}
                tasks={tasks || []}
            />
        )}
        {alertSyncingWithSelectRefresh && <AlertSyncingWithSelectRefresh open={alertSyncingWithSelectRefresh}/>}
      </Page>
  );
};

export default Tasks;

import { Autocomplete, TextField, debounce } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import axios from "axios";
import { InternalUser } from "../../../types/app";

interface IProps {
  editedRep: InternalUser | undefined | null;
  setEditedRep: (internalUser: InternalUser | undefined) => void;
  label: string;
}

export default function AccountRepAutocomplete({ editedRep, setEditedRep, label }: IProps) {
  const [accountRepList, setAccountRepList] = useState<InternalUser[]>([]);

  const searchAccountReps = debounce(async (searchText: string) => {
    if (searchText) {
      const { data } = await axios.get(`/api/clientphonebook/internalUsers/searchAccountReps?searchText=${searchText}`);
      setAccountRepList(data);
    } else {
      setAccountRepList([]);
    }
  }, 200);

  return (
    <Autocomplete
      onChange={(_, option) => setEditedRep(option || undefined)}
      options={accountRepList}
      value={editedRep}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password"
          }}
          fullWidth
          label={label}
          onChange={(e) => {
            searchAccountReps(e.target.value);
          }}
        />
      )}
      getOptionLabel={(option) => (option?.firstName || "") + " " + (option?.lastName || "")}
    />
  );
}

import { Container, Typography, IconButton, Tooltip } from "@mui/material";
//import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { HistoryWithRedFlags } from "../../phonebook/types";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import { contactColumns } from "./ContactColumns";
import EditContactHistoryActions from "./EditContactHistoryActions";
import Iconify from "../../../minimals/components/iconify";
import { MaterialReactTable } from "material-react-table";

export default function EditContactHistory() {
  const today = dayjs();
  const defaultEndDate = today;
  const defaultStartDate = today.subtract(7, "days");
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<HistoryWithRedFlags[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [enableOrdering, setEnableOrdering] = useState(false);

  const getAndSetHistory = async () => {
    setLoading(true);
    const [startDate, endDate] = dateRange;
    const url = `/api/clientphonebook/redFlags/getHistoryWithRedFlags?from=${startDate || defaultStartDate}&to=${
      endDate || defaultEndDate
    }`;
    const { data } = await axios.get(url);
    setHistory(data);
    setLoading(false);
  };
  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  useEffect(() => {
    getAndSetHistory();
  }, [dateRange]);

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Edit History
        </Typography>
        <MaterialReactTable
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => ({
            style: row.index % 2 === 0 ? {} : { backgroundColor: "#f4f4f4" }
          })}
          columns={contactColumns}
          data={history}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility: {
              transactionType: false,
              voidedOn: false,
              status: false,
              extendedMemo: false
            }
          }}
          state={{
            showSkeletons: loading
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <EditContactHistoryActions dateRange={dateRange} setDateRange={setDateRange} table={table} />
              <Tooltip title="Column ordering">
                <IconButton
                  onClick={toggleColumnOrdering}
                  aria-label="toggle column ordering"
                  style={{ position: "absolute", right: "200px" }}
                >
                  <Iconify icon="mdi:reorder-vertical" />
                </IconButton>
              </Tooltip>
            </>
          )}
        />
      </Container>
    </>
  );
}

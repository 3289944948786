import { Box, Link, Stack, Tooltip, Typography, styled } from "@mui/material";
import { getPhoneIcon } from "../../../utils/getPhoneIcon";
import { Phone } from "../../types";
import { StyledIcon } from "../../../components/StyledIcon";
import { formatPhone } from "./formatPhone";

interface IProps {
  phone: Phone;
}

export default function ({ phone }: IProps) {
  return (
    <Stack direction="row">
      <Tooltip title={phone.type} placement="top-start">
        <StyledIcon icon={getPhoneIcon(phone.type)} />
      </Tooltip>
      <Typography variant="body2">
        <Tooltip title={phone.type} placement="top-start">
          <Link color="initial" href={`tel:+${phone.countryCode}${phone.number}`}>
            {phone.countryCode && phone.countryCode !== "1" && `+${phone.countryCode}`} {formatPhone(phone)}{" "}
          </Link>
        </Tooltip>
        <Box sx={{ display: "inline", color: "GrayText" }}>{phone.extension && `Ext. ${phone.extension}`}</Box>
      </Typography>
    </Stack>
  );
}

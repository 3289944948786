import { Card, CardContent, Typography } from "@mui/material";

export default function ComingSoon() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Coming Soon...</Typography>
      </CardContent>
    </Card>
  );
}

import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import Iconify from "../../../../../minimals/components/iconify";

export default function OrdersFilter({
  statuses,
  selectedStatus,
  onStatusChange
}: {
  statuses: string[];
  selectedStatus: string;
  onStatusChange: (status: string) => void;
}) {
  return (
    <>
      <Autocomplete
        sx={{ width: 160, marginRight: 2 }}
        defaultValue="All"
        value={selectedStatus}
        options={statuses}
        onChange={(e, v) => v && onStatusChange(v)}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />
      {/*<TextField*/}
      {/*  InputProps={{*/}
      {/*    startAdornment: (*/}
      {/*      <InputAdornment position="start">*/}
      {/*        <Iconify icon="eva:search-fill" />*/}
      {/*      </InputAdornment>*/}
      {/*    ),*/}
      {/*  }}*/}
      {/*  placeholder="Search..."*/}
      {/*  sx={{ width: 368 }}*/}
      {/*/>*/}
    </>
  );
}

import styled from "@emotion/styled";

export const AdditionalEmailsWrap = styled.div`
  background-color: rgb(248, 249, 250);
  border-radius: 12px;
  margin-top: 36px;
  padding: 20px;
  .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  .email-header {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    span {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      position: relative;
    }
  }
  .current_emails {
    margin-top: 24px;
    max-width: 310px;
  }
  .add-button {
    align-items: center;
    color: #4440db;
    cursor: pointer;
    display: flex;
    gap: 4px;
    margin-top: 24px;
    svg {
      color: #4440db;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
    :hover {
      color: #141368;
      svg {
        color: #141368;
      }
    }
  }
  .new-emails {
    .delete_icon {
      height: 20px;
      width: 20px;
    }
    .input_wrap {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: 26px;
      max-width: 355px;
      width: 100%;
      :first-of-type {
        margin-top: 14px;
        .df.aic {
          display: none;
        }
      }
    }
  }
`;

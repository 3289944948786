import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import Iconify from "../../minimals/components/iconify";
import { CorpDocType } from "./types";
import { Dispatch, SetStateAction, useState } from "react";

interface IProps {
  options: CorpDocType[];
  setSelectedCorpDocType: Dispatch<SetStateAction<CorpDocType | null>>;
}

export default function CopDocFilters({ options, setSelectedCorpDocType }: IProps) {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleShowFilters = () => setShowFilters(!showFilters);

  return (
    <Grid container spacing={2} padding={showFilters ? 3 : 1} alignItems="center">
      {showFilters && (
        <>
          <Grid item xs={9}>
            <TextField
              fullWidth
              sx={{ maxWidth: 705 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" />
                  </InputAdornment>
                )
              }}
              placeholder="Search Corp Docs..."
            />
          </Grid>
          <Grid item xs={2.5}>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Document Type" />}
              options={options}
              getOptionLabel={(o) => o.name}
              onChange={(_, value) => setSelectedCorpDocType(value)}
            />
          </Grid>
        </>
      )}
      <Grid item xs={showFilters ? 0.5 : 12} textAlign="right">
        <IconButton onClick={toggleShowFilters}>
          <Iconify icon={showFilters ? "ic:outline-filter-list" : "ic:outline-filter-list-off"} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

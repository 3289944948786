import { Breadcrumbs, Link } from "@mui/material";

export default function CorpDocBreadcrumbs() {
  return (
    <Breadcrumbs separator="•" aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
      <Link color="inherit">Orders</Link>
      <Link color="inherit">Corp Docs</Link>
      <Link color="text.primary">List</Link>
    </Breadcrumbs>
  );
}

import { Box, LinearProgress, LinearProgressProps, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { calculatePercentage } from "../../utils/calculatePercentage";

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { totalTasks: number; completedTasks: number }
) {
  const { totalTasks, completedTasks } = props;
  const percentage = calculatePercentage(completedTasks, totalTasks);

  const getColorBasedOnPercentage = () => {
    if (totalTasks === 0) {
      return "inherit";
    } else if (percentage === 100) {
      return "success";
    } else if (percentage > 0) {
      return "warning";
    } else if (percentage === 0) {
      return "error";
    }
  };

  const progressColor = useMemo(() => {
    return getColorBasedOnPercentage();
  }, [totalTasks, completedTasks]);

  return (
    <Box width={"244px"}>
      <Stack direction="row" justifyContent="space-between" marginBottom={1}>
        <Typography variant="subtitle2">Completed Tasks</Typography>
        <Stack direction="row" justifyContent="right" spacing={0.5}>
          <Typography variant="subtitle2">
            {completedTasks} of {totalTasks}
          </Typography>
        </Stack>
      </Stack>
      <LinearProgress variant="determinate" {...props} value={percentage} color={progressColor} />
    </Box>
  );
}

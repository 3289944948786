import { Box, IconButton, Link } from "@mui/material";
import Iconify from "../../minimals/components/iconify";

export default function OpenFileButton({ href }: { href: string }) {
  return (
    <Box>
      <Link href={href} underline="none" target="_blank">
        <IconButton>
          <Iconify icon="eva:external-link-fill" />
        </IconButton>
      </Link>
    </Box>
  );
}

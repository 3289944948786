export interface RecordableType {
  id: number;
  description: string;
}

export interface RecordableDocument {
  id: number;
  recordableId: number;
  momentumDocumentId: number;
  uploadedDate: Date;
  uploadedBy: string;
}

export interface RecordableHistory {
  id: number;
  recordableId: number;
  documentId: number | null;
  eventType: number;
  status: number;
  username: string;
  dateTime: Date;
  note: string;
  recordable: Recordable;
  eventTypeDescription: RecordableEventDescription;
  documentStatusDescription: RecordabledocumentStatusDescription;
  packageStatusDescription: RecordabledocumentStatusDescription;
}

export enum RecordableHistoryEventType {
  Added = 1,
  Deleted = 2,
  NewVersionUploaded = 3,
  RecordedVersionUploaded = 4,
  StatusChange = 5,
  NoteAdded = 6,
  PdfDownloaded = 7,
  SummaryCreated = 8,
  AssignedTo = 9
}

export interface TaskItem {
  id: number;
  recordableId: number;
  description: string;
  status: number;
  statusText: string;
  statusUpdateHistory: StatusUpdateHistory[];
  notes: Note[];
  assignedToName: string | null;
  assignedToEmail: string | null;
}

interface StatusUpdateHistory {
  id: number;
  taskItemId: number;
  status: number;
  username: string;
  documentId: number;
  dateTime: Date;
  note: string;
  eventType: number;
}

export interface Recordable {
  id: number;
  orderNumber: string;
  description: string;
  dateCreated: Date;
  createdBy: string;
  documents: RecordableDocument[];
  taskItems: TaskItem[];
  isDeleted: boolean;
  type: number;
  typeDescription: string;
  status: number;
  statusDescription: RecordabledocumentStatusDescription;
  history: RecordableHistory[];
  viewPackageUrl: string | null;
}

export type RecordabledocumentStatusDescription =
  | "Needs Review"
  | "Draft"
  | "Ready"
  | "Sent"
  | "Recorded"
  | "Rejected"
  | "Voided"
  | "Removed"
  | "Finalizing"
  | "Ready for Recording";

export type RecordableEventDescription =
  | "Added"
  | "Deleted"
  | "New Version Uploaded"
  | "Recorded Version Uploaded"
  | "Status Change"
  | "Note Added"
  | "Pdf Downloaded"
  | "Summary Created"
  | "Assigned To";

// ////These are dummy colors. Please replace with proper colors
export const RecordableStatusColorMap: Record<
  RecordabledocumentStatusDescription,
  "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
> = {
  "Needs Review": "warning",
  Draft: "warning",
  Ready: "primary",
  Sent: "primary",
  Recorded: "success",
  Rejected: "error",
  Voided: "default",
  Removed: "default",
  Finalizing: "primary",
  "Ready for Recording": "warning"
};

export const RecordableStatusColorHexMap: Record<RecordabledocumentStatusDescription, string> = {
  "Needs Review": "#FFC107",
  Draft: "#FFC107",
  Ready: "#3f51b5",
  Sent: "#3f51b5",
  Recorded: "#54D62C",
  Rejected: "#f44336",
  Voided: "#9e9e9e",
  Removed: "#9e9e9e",
  Finalizing: "#3f51b5",
  "Ready for Recording": "#FFC107"
};

export interface Status {
  id: number;
  description: string;
}

export interface EventType {
  id: number;
  description: string;
}

export interface RecordableTrackingOrderInfo {
  orderNumber: string;
  buyerBorrowerName: string;
  sellerName: string;
  purchasePrice?: number | null;
  loans: Array<{ amount: number; lender: string }>;
  properties: RecordableTrackingOrderProperty[];
}

export interface RecordableTrackingOrderProperty {
  id: number;
  legalDescription: string;
  block: string;
  lots: string[];
  parcelIds: string[];
  county: string;
}

export interface GeneralTask {
  id: number;
  description: string;
  recordableType: RecordableType;
}

export type StateTask = GeneralTask & { stateId: number };

export type CountyTask = GeneralTask & { countyId: number };

export enum TaskType {
  General,
  State,
  County
}

export enum ModalType {
  Add,
  Edit
}

export interface State {
  id: number;
  name: string;
  abbreviation: string;
}

export interface County {
  id: number;
  stateId: number;
  name: string;
}

export interface Note {
  id: number;
  taskItemId: number;
  text: string;
  dateCreated: Date;
  createdByUser: string;
  isUserNote: boolean;
}

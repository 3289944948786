import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
import { columns } from "./columns";
import { useContext, useEffect, useState } from "react";
import { PoliciesNotIssuedContext } from "./PoliciesNotIssuedContext";
import Actions from "./Actions";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import { StringParam, useQueryParam } from "use-query-params";
import { InternalUser } from "../../types/app";
import NotesModal from "../../checks/checkNotes/NotesModal";
import { DashboardReportType } from "../../checks/types";
import { PolicyNotIssued } from "./types";
import { NotesColumn } from "../NotesColumn";
import Iconify from "../../../minimals/components/iconify";
import { exportTableToCsv } from "../../taskUtils";

export default function PoliciesNotIssued() {
  const {
    policiesNotIssued,
    loading,
    setDateRange,
    dateRange,
    assignedToMe,
    setAssignedToMe,
    fetchPoliciesNotIssued,
    setPoliciesNotIssued,
    tableRef
  } = useContext(PoliciesNotIssuedContext);
  const [assignedToId] = useQueryParam("assignedtoid", StringParam);
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<PolicyNotIssued | null>(null);

  const { user } = useContext(UserContext);
  const isUnassignedOnly = Number(assignedToId) == -1;

  const [managerDepartments, setManagerDepartments] = useState<string[]>([]);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };

  const getAndSetManagerDepartmentsByEmail = async (emailAddress: string) => {
    const { data } = await axios.get<string[]>(
      `/api/outstandingchecks/getManagerDepartmentsByEmail?emailAddress=${emailAddress}`
    );
    setManagerDepartments(data);
  };

  let email = user?.email.toLowerCase();

  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  useEffect(() => {
    if (!email) return;
    getAndSetManagerDepartmentsByEmail(email);
  }, [user]);

  const filteredPoliciesNotIssued = assignedToMe
    ? policiesNotIssued.filter((p) => {
        if (isUnassignedOnly) {
          return !p.assignedToUser;
        }
        if (assignedToId) {
          return assignedToId === String(p.assignedToUser?.id || "");
        }

        return (user?.id && user.id == p.assignedToUser?.id) || managerDepartments.includes(p.department);
      })
    : policiesNotIssued;

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const columnsWithNotes = NotesColumn<PolicyNotIssued>(columns, setSelectedOrder);

  return (
    <>
      <Helmet>
        <title>Policies Not Issued | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Policies Not Issued {isUnassignedOnly && " - Unassigned"}
          {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
        </Typography>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredPoliciesNotIssued}
          enableGlobalFilter
          enableFacetedValues
          enableRowSelection
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 }
          }}
          state={{
            showSkeletons: loading
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            // Apply zebra striping styles to alternate rows
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {} // Change the background color as needed
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Actions
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      assignedToMe={assignedToMe}
                      setAssignedToMe={setAssignedToMe}
                      count={filteredPoliciesNotIssued.length}
                      hideAssignedToMeOptions={!!assignedToId}
                      onRowsDeselected={onRowsDeselected}
                      table={table}
                    />
                    <Box sx={{ position: "absolute", right: 0 }}>
                      <Tooltip title="Column ordering">
                        <IconButton onClick={toggleColumnOrdering}>
                          <Iconify icon="mdi:reorder-vertical" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Export to csv">
                        <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                          <Iconify icon="eva:download-fill" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </>
            );
          }}
        />
      </Container>
      {!!selectedOrder && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedOrder?.assignedToUser?.fullName || ""}
          emailBeforeUpdate={selectedOrder?.assignedToUser?.email || ""}
          entity={selectedOrder}
          setEntity={setSelectedOrder}
          setEntityTable={setPoliciesNotIssued}
          refetchData={() => fetchPoliciesNotIssued(false)}
          reportType={DashboardReportType.PoliciesNotIssued}
        />
      )}
    </>
  );
}

import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { CompanyBranch, Contact, ContactRep, Phone, Role } from "../../types";
import { AddEditContactContext } from "../addEditContact/AddEditContactContext";
import { uniq, uniqBy } from "lodash";
import _ from "lodash";
import DiscrepancyCards from "./DiscrepancyCards";

const accountRepTypes: { [key: string]: string } = {
  2: "NY Account Rep",
  3: "NJ Account Rep",
  4: "National Account Rep"
};
export interface AccountRepDiscrepancy {
  contactReps: ContactRep[];
  header: string;
  contactRepTypeId: number;
}
export default function Discrepancies({ setTheInput }: { setTheInput: Dispatch<SetStateAction<string>> }) {
  const {
    contact,
    mergingContacts,
    setContact,
    resetMergingContacts,
    getAndSetSelectedCompany,
    handleCompanyBranchChange
  } = useContext(AddEditContactContext);
  const [keysDiscrepancies, setKeysDiscrepancies] = useState<(keyof Contact)[]>([]);

  const [phoneNumberDiscrepancies, setPhoneNumberDiscrepancies] = useState<PhoneDiscrepancy[]>([]);
  const [roleDiscrepancies, setRoleDiscrepancies] = useState<(Role | null)[]>([]);
  const [professionDiscrepancies, setProfessionDiscrepancies] = useState<(Role | null)[]>([]);
  const [locationDiscrepancies, setLocationDiscrepancies] = useState<CompanyBranch[]>([]);
  const [emailDiscrepancies, setEmailDiscrepancies] = useState<string[]>([]);
  const [accountRepDiscrepancies, setAccountRepDiscrepancies] = useState<AccountRepDiscrepancy[]>([]);
  const [salesRepDiscrepancies, setSalesRepDiscrepancies] = useState<ContactRep[][]>([]);
  const firstContact = mergingContacts[0];
  const secondContact = mergingContacts[1];

  interface PhoneDiscrepancy {
    type: string;
    discrepancies: Phone[];
  }

  const phoneDiscrepancies = () => {
    const phoneNumbers = firstContact.contactRoles[0].phoneNumbers
      .filter((p) => p.number)
      .concat(secondContact.contactRoles[0].phoneNumbers.filter((p) => p.number));
    const keys = uniq(phoneNumbers.map((p) => p.type));
    const phoneDiscreps = keys.map((key) => {
      return {
        type: key,
        discrepancies: uniqBy(
          phoneNumbers.filter((p) => p.type === key),
          (p) => `${p.number} ${p.extension}`
        )
      };
    });
    setPhoneNumberDiscrepancies(phoneDiscreps);
    setContact((draft) => {
      draft.contactRoles[0].phoneNumbers = [
        {
          type: "",
          number: "",
          extension: "",
          countryCode: "",
          key: ""
        }
      ];
    });
  };

  const getRoleDiscrepancyResults = () => {
    const firstContactRole = firstContact.contactRoles[0].role;
    const secondContactRole = secondContact.contactRoles[0].role;
    if (!firstContactRole || !secondContactRole) {
      setContact((draft) => {
        draft.contactRoles[0].role = firstContactRole || secondContactRole;
      });
    } else {
      const roleDiscreps =
        firstContactRole?.name !== secondContactRole?.name ? [firstContactRole, secondContactRole] : [];
      setRoleDiscrepancies(roleDiscreps);

      if (roleDiscreps?.length > 0) {
        setContact((draft) => {
          draft.contactRoles[0].roleId = null;
          draft.contactRoles[0].role = null;
        });
      } else if (roleDiscreps.length === 0)
        setContact((draft) => {
          draft.contactRoles[0].roleId = firstContactRole?.id || secondContactRole?.id;
          draft.contactRoles[0].role = firstContactRole || secondContactRole;
        });
    }
  };

  const getProfessionDiscrepancyResults = () => {
    const firstContactProfession = firstContact.profession;
    const secondContactProfession = secondContact.profession;

    if (!firstContactProfession || !secondContactProfession) {
      setContact((draft) => {
        draft.profession = firstContactProfession || secondContactProfession;
      });
    } else {
      const profession: (Role | null)[] =
        firstContactProfession?.id !== secondContactProfession?.id
          ? [firstContactProfession || null, secondContactProfession || null]
          : [];

      setProfessionDiscrepancies(profession);

      if (profession.length > 0) {
        setContact((draft) => {
          draft.professionId = null;
          draft.profession = null;
        });
      } else if (profession.length === 0) {
        setContact((draft) => {
          draft.profession = firstContactProfession || secondContactProfession;
        });
      }
    }
  };

  const getLocationsDiscrepancies = () => {
    const firstContactBranch = firstContact.contactRoles[0].branch;
    const secondContactBranch = secondContact.contactRoles[0].branch;
    if (!firstContactBranch || !secondContactBranch) {
      if (firstContactBranch && firstContactBranch.companyId) {
        getAndSetSelectedCompany(firstContactBranch.companyId, firstContactBranch.id);
      } else if (firstContactBranch && !firstContactBranch.companyId) {
        handleCompanyBranchChange(firstContactBranch);
      }
      if (secondContactBranch) {
        getAndSetSelectedCompany(secondContactBranch.companyId, secondContactBranch.id);
        handleCompanyBranchChange(secondContactBranch);
      }
    } else {
      const locationDiscreps =
        firstContactBranch?.id !== secondContactBranch?.id ||
        firstContactBranch.companyId !== secondContactBranch.companyId
          ? [firstContactBranch, secondContactBranch]
          : [];
      setLocationDiscrepancies(locationDiscreps);

      if (locationDiscreps.length > 0) {
        setContact((draft) => {
          draft.contactRoles[0].branch = null;
        });
      } else if (locationDiscreps.length === 0) {
        setContact((draft) => {
          draft.contactRoles[0].branch = firstContactBranch || secondContactBranch;
        });
      }
    }
  };

  const getAccountRepDiscrepancies = (contactRepTypeId: number) => {
    const firstContactAccountRep = firstContact.contactReps.find((rep) => rep.contactRepTypeId === contactRepTypeId);
    const secondContactAccountRep = secondContact.contactReps.find((rep) => rep.contactRepTypeId === contactRepTypeId);
    if (
      !firstContactAccountRep ||
      !secondContactAccountRep ||
      firstContactAccountRep?.internalUser?.id === secondContactAccountRep?.internalUser?.id
    ) {
      return null;
    } else {
      return {
        contactReps: [firstContactAccountRep, secondContactAccountRep],
        header: accountRepTypes[contactRepTypeId.toString()],
        contactRepTypeId
      };
    }
  };

  const setUpAccountRepDiscreps: () => ContactRep[] = () => {
    const discreps = [
      getAccountRepDiscrepancies(2),
      getAccountRepDiscrepancies(3),
      getAccountRepDiscrepancies(4)
    ].filter((f) => f) as any[];
    setAccountRepDiscrepancies(discreps);
    const discrepsContactType = discreps.map((c) => c.contactRepTypeId);
    return [2, 3, 4]
      .map((i) => {
        const firstContactAccountRep = firstContact.contactReps.find((rep) => rep.contactRepTypeId === i);
        const secondContactAccountRep = secondContact.contactReps.find((rep) => rep.contactRepTypeId === i);

        if (discrepsContactType.includes(i)) {
          return {
            internalUserId: null,
            internalUser: null,
            contactRepTypeId: i,
            contactId: contact.id
          } as any as ContactRep;
        }
        return firstContactAccountRep || secondContactAccountRep;
      })
      .filter((v) => v) as ContactRep[];
  };

  const getSalesRepDiscrepancies: () => ContactRep[] = () => {
    const firstContactSalesRep = firstContact.contactReps.filter(
      (rep) => rep.contactRepTypeId === 1 && rep.internalUserId
    );
    const secondContactSalesRep = secondContact.contactReps.filter(
      (rep) => rep.contactRepTypeId === 1 && rep.internalUserId
    );

    if (firstContactSalesRep.length === 0 || secondContactSalesRep.length === 0) {
      return firstContactSalesRep.concat(secondContactSalesRep);
    }
    const differences = _.differenceWith(firstContactSalesRep, secondContactSalesRep, _.isEqual);
    if (firstContactSalesRep.length === secondContactSalesRep.length && differences.length === 0) {
      return firstContactSalesRep;
    }
    const discreps = [firstContactSalesRep, secondContactSalesRep];
    setSalesRepDiscrepancies(discreps);

    return [];
  };

  const handleAccountSalesReps = () => {
    var accountReps = setUpAccountRepDiscreps();
    var salesReps = getSalesRepDiscrepancies();
    setContact((draft) => {
      const newAccontReps = (accountReps || []).concat(salesReps || []);
      draft.contactReps = newAccontReps;
    });
  };

  const getEmailDiscrepancies = () => {
    const firstContactEmail = firstContact.contactRoles[0].businessEmail;
    const secondContactEmail = secondContact.contactRoles[0].businessEmail;
    if (!firstContactEmail || !secondContactEmail) {
      setContact((draft) => {
        draft.contactRoles[0].businessEmail = firstContactEmail || secondContactEmail;
      });
    } else {
      const emailDiscreps = firstContactEmail !== secondContactEmail ? [firstContactEmail, secondContactEmail] : [];

      setEmailDiscrepancies(emailDiscreps);

      if (emailDiscreps.length > 0) {
        setContact((draft) => {
          draft.contactRoles[0].businessEmail = null;
        });
      } else if (emailDiscreps.length === 0) {
        setContact((draft) => {
          draft.contactRoles[0].businessEmail = firstContactEmail || secondContactEmail;
        });
      }
    }
  };

  const setUpDiscrepancies = () => {
    if (!mergingContacts[0] || !mergingContacts[1]) {
      return;
    }
    const calculatedKeysDiscrepancies: (keyof Contact)[] = Object.keys(mergingContacts[0]).filter((key) => {
      const fieldOne: any = (mergingContacts[0] as any)[key];
      const fieldTwo: any = (mergingContacts[1] as any)[key];
      return key !== "lookupCode" && key !== "dynamicsGuid" && typeof fieldOne === "string" && fieldOne !== fieldTwo;
    }) as any;

    setKeysDiscrepancies(
      calculatedKeysDiscrepancies.filter((key) => {
        const fieldOne: any = (mergingContacts[0] as any)[key];
        const fieldTwo: any = (mergingContacts[1] as any)[key];
        return ((fieldOne && !fieldTwo) || (!fieldOne && fieldTwo)) === false;
      })
    );

    const contact = { ...mergingContacts[0] };
    for (let key of calculatedKeysDiscrepancies) {
      (contact as any)[key] = firstContact[key] && secondContact[key] ? null : firstContact[key] || secondContact[key];
    }
    setContact(contact);
    getEmailDiscrepancies();
    phoneDiscrepancies();
    getRoleDiscrepancyResults();
    getProfessionDiscrepancyResults();
    getLocationsDiscrepancies();
    handleAccountSalesReps();
  };

  useEffect(() => {
    return resetMergingContacts;
  }, []);

  useEffect(() => {
    setUpDiscrepancies();
  }, [mergingContacts]);

  return (
    <DiscrepancyCards
      setTheInput={setTheInput}
      keysDiscrepancies={keysDiscrepancies}
      emailDiscrepancies={emailDiscrepancies}
      phoneNumberDiscrepancies={phoneNumberDiscrepancies}
      roleDiscrepancies={roleDiscrepancies}
      professionDiscrepancies={professionDiscrepancies}
      locationDiscrepancies={locationDiscrepancies}
      accountRepDiscrepancies={accountRepDiscrepancies}
      salesRepDiscrepancies={salesRepDiscrepancies}
    />
  );
}

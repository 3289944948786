export const countAndMapAssignedTasks = (tasks: any[], taskType: any) => {
  const assignedToCountsMap = new Map<string, number>();
  const unassignedCountsMap = new Map<string, number>();

  tasks.forEach((task) => {
    const { assignedToUser } = task;

    if (!assignedToUser) {
      const unassignedCount = unassignedCountsMap.get(taskType) || 0;
      unassignedCountsMap.set(taskType, unassignedCount + 1);
      return;
    }

    const key = `${assignedToUser?.fullName || ""}-${assignedToUser?.email || ""}-${
      assignedToUser?.id || ""
    }-${taskType}`;

    const count = assignedToCountsMap.get(key) || 0;
    assignedToCountsMap.set(key, count + 1);
  });

  const uniqueAssignedToList = Array.from(assignedToCountsMap.entries()).map(([key, count]) => {
    const [assignedToName, assignedToEmail, assignedToId, reportType] = key.split("-");
    return {
      assignedToName,
      assignedToEmail,
      assignedToId: Number(assignedToId),
      reportType,
      count
    };
  });

  const uniqueUnassignedList = Array.from(unassignedCountsMap.entries()).map(([key, count]) => {
    return {
      assignedToName: "Unassigned",
      assignedToEmail: "",
      assignedToId: -1,
      reportType: key,
      count
    };
  });

  var uniqueAssignedToListSorted = uniqueAssignedToList.sort((a, b) => {
    const aLastname = a.assignedToName.split(" ").pop() || "";
    const bLastname = b.assignedToName.split(" ").pop() || "";
    return aLastname.localeCompare(bLastname);
  });

  return [...uniqueAssignedToListSorted, ...uniqueUnassignedList];
};

import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable, MRT_TableInstance } from "material-react-table";
import { columns } from "./columns";
import { useContext, useEffect, useMemo, useState } from "react";
import { EscrowLedgersContext } from "./EscrowLedgersContext";
import Actions from "./Actions";
import { UserContext } from "../../contexts/UserContext";
import { StringParam, useQueryParam } from "use-query-params";
import { InternalUser } from "../../types/app";
import axios from "axios";
import { DashboardReportType } from "../../checks/types";
import NotesModal from "../../checks/checkNotes/NotesModal";
import { EscrowLedger } from "./types";
import { NotesColumn } from "../NotesColumn";
import Iconify from "../../../minimals/components/iconify";
import { exportTableToCsv } from "../../taskUtils";
import ClosingChecklistEscrow from "../../taskManagement/ClosingChecklistEscrow";
import { saveEscrowLedgerAssignTo } from "./saveAssignTo";
import { saveEscrowLedgerNote } from "./saveNote";
import dayjs, { Dayjs } from "dayjs";

const today = dayjs();
const defaultEndDate = today.add(5, "day");
const defaultStartDate = null;

export default function EscrowLedgers() {
  const { escrowLedgers, loading, assignedToMe, setAssignedToMe, fetchEscrowLedgers, setEscrowLedgers, tableRef } =
    useContext(EscrowLedgersContext);
  const { user } = useContext(UserContext);
  const [assignedToId] = useQueryParam("assignedtoid", StringParam);
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<EscrowLedger | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [openClosingChecklistEscrow, setOpenClosingChecklistEscrow] = useState<boolean>(false);
  const [showZeroAmount, setShowZeroAmount] = useState<boolean>(false);

  const isUnassignedOnly = Number(assignedToId) == -1;

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };
  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  const filteredEscrowLedgers = useMemo(() => {
    var ledgers = showZeroAmount ? escrowLedgers : escrowLedgers.filter((l) => l.amount);
    if (assignedToMe) {
      return ledgers.filter((o) => user?.email?.toLowerCase() === o.assignedToEmail?.toLowerCase());
    } else if (isUnassignedOnly) {
      return ledgers.filter((o) => !o.assignedTo);
    } else if (assignedToId) {
      return ledgers.filter((o) => assignedToUser?.fullName?.toLowerCase() === o.assignedTo?.toLowerCase());
    } else return ledgers;
  }, [escrowLedgers, assignedToMe, isUnassignedOnly, showZeroAmount]);

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const getTotal = (table: MRT_TableInstance<EscrowLedger>) => {
    return loading ? 0 : table.getExpandedRowModel().rows.reduce((sum, row) => sum + (row.original?.amount || 0), 0);
  };

  const columnsWithNotes = NotesColumn<EscrowLedger>(columns, setSelectedOrder);

  return (
    <>
      <Helmet>
        <title>Escrow Ledgers | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h4" marginBottom={2}>
            Escrow Ledgers
            {isUnassignedOnly && " - Unassigned"}
            {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenClosingChecklistEscrow(true)}
            size="small"
            sx={{ marginBottom: 1 }}
          >
            Transfer Escrow Funds
          </Button>
        </Stack>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredEscrowLedgers}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            showColumnFilters: true,
            columnFilters: [
              {
                id: "dueDate",
                value: [defaultStartDate, defaultEndDate]
              }
            ]
          }}
          state={{
            showSkeletons: loading
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            // Apply zebra striping styles to alternate rows
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {} // Change the background color as needed
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Actions
                      assignedToMe={assignedToMe}
                      setAssignedToMe={setAssignedToMe}
                      count={loading ? null : table.getRowCount()}
                      total={getTotal(table)}
                      hideTotal={false}
                      hideAssignedToMeOptions={!!assignedToId}
                      hideReasignAction={true}
                      table={table}
                      fetchTableData={() => fetchEscrowLedgers(true)}
                      reportType={DashboardReportType.EscrowOverdue}
                      onRowsDeselected={onRowsDeselected}
                      setShowZeroAmount={setShowZeroAmount}
                    />
                    <Box sx={{ position: "absolute", right: 0 }}>
                      <Tooltip title="Column ordering">
                        <IconButton onClick={toggleColumnOrdering}>
                          <Iconify icon="mdi:reorder-vertical" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Export to csv">
                        <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                          <Iconify icon="eva:download-fill" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </>
            );
          }}
        />
      </Container>
      {!!selectedOrder && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedOrder?.assignedTo || ""}
          emailBeforeUpdate={selectedOrder?.assignedToEmail || ""}
          entity={selectedOrder}
          setEntity={setSelectedOrder}
          setEntityTable={setEscrowLedgers}
          reportType={DashboardReportType.EscrowOverdue}
          saveEscrowLedgerAssignTo={async (userName) => {
            await saveEscrowLedgerAssignTo(selectedOrder.name, userName);
          }}
          saveEscrowLedgerNote={async (text) => {
            await saveEscrowLedgerNote(selectedOrder.name, text, user?.name as string);
          }}
        />
      )}
      {openClosingChecklistEscrow && (
        <ClosingChecklistEscrow
          onClose={() => setOpenClosingChecklistEscrow(false)}
          refresh={() => fetchEscrowLedgers(true)}
        />
      )}
    </>
  );
}

import { TableContainer, Paper, Table, TableRow, TableCell, TableBody, Skeleton } from "@mui/material";

export default function LoadingSkeletonTable() {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {Array.from({ length: 10 }).map((_, i) => (
            <TableRow key={i}>
              <TableCell width={300}>
                <Skeleton variant="rectangular" width={300} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width={200} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width={200} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width={200} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width={200} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" width={200} height={40} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

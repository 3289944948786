import { SearchContextProvider } from "../contexts/SearchesContext";
import OrderSearches from "./OrderSearches";

// ----------------------------------------------------------------------

export default function PageSearchOrder() {
  return (
    <SearchContextProvider>
      <OrderSearches />
    </SearchContextProvider>
  );
}

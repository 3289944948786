export function stripDataUriPrefix(dataUri: string) {
  const base64Prefix = ";base64,";
  const index = dataUri.indexOf(base64Prefix);

  if (index === -1) {
    throw new Error("Invalid data URI format.");
  }

  return dataUri.substring(index + base64Prefix.length);
}

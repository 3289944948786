import { Box, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function GenerateTasksModal({
  initiateTasks,
  initiateIsPending,
  applicationType,
  applicationTypeFriendlyName,
  orderNumber
}: {
  initiateTasks: () => Promise<void>;
  initiateIsPending: boolean;
  applicationType: string;
  applicationTypeFriendlyName: string;
  orderNumber: string | null;
}) {
  const closeModal = () => {};

  const getInitiatePendingText = () => {
    return applicationType === "flips"
      ? `Adding " 01a. Flip Approved" to Select's Checklist Task and generating checklist. Please stand by.`
      : `Generating ${applicationTypeFriendlyName} tasks`;
  };

  return (
    <Dialog open={true} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        {applicationTypeFriendlyName} for #{" "}
        <Typography display="inline-block" color={(theme) => theme.palette.grey[500]}>
          {orderNumber}
        </Typography>{" "}
        has not been initiated yet
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack direction="row" justifyContent="center">
          <Box>
            <LoadingButton
              variant="contained"
              onClick={initiateTasks}
              loading={initiateIsPending}
              loadingPosition="end"
              sx={{ paddingRight: 5, paddingLeft: 5 }}
            >
              {initiateIsPending ? getInitiatePendingText() : `Initiate ${applicationTypeFriendlyName}`}
            </LoadingButton>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

import {
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material";
import { useContext, useState } from "react";
import { FullContact, Company } from "../../types";
import { UserContext } from "../../../contexts/UserContext";

interface IProps {
  entityType: string;
  entity: FullContact | Company | undefined;
  updateActiveStatus: (id: number, isActive: boolean) => void;
}

export default function DeactivateDialog({ entityType, entity, updateActiveStatus }: IProps) {
  const { user } = useContext(UserContext);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const deactivators = [
    "agoldstein@madisoncres.com",
    "sdavidowitz@madisoncres.com",
    "bfreylicher@madisoncres.com",
    "afriedman@madisoncres.com",
    "sbagdadi@madisontitle.com",
    "ykeller@madisontitle.com",
    "srubin@madisontitle.com"
    // "swaldman@madisoncres.com",
    // "cherzka@madisoncres.com"
  ];

  const isUserDeactivator = deactivators.includes(user?.email.toLowerCase() || "");

  const closeModal = () => setIsOpenConfirmation(false);

  const handleToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!entity?.id || !isUserDeactivator) return;
    const isSetToDeactivate = !event.target.checked;
    if (isSetToDeactivate) {
      return setIsOpenConfirmation(true);
    }
    await updateActiveStatus(entity.id, true);
  };

  const handleConfirmDeactivate = async () => {
    if (!entity?.id || !isUserDeactivator) return;
    await updateActiveStatus(entity.id, false);
    closeModal();
  };

  // Render
  if (!entity || !isUserDeactivator) {
    return <></>;
  }

  return (
    <div>
      <FormGroup>
        <FormControlLabel control={<Switch checked={entity?.isActive} onChange={handleToggle} />} label="Active" />
      </FormGroup>
      <Dialog
        open={isOpenConfirmation}
        onClose={closeModal}
        aria-labelledby="deactivate-dialog-title"
        aria-describedby="deactivate-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Deactivate ${entityType}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`You are about to deactivate this ${entityType.toLowerCase()}. Are you sure you want to proceed?`}
            {entityType == "Company" && `Please make sure to remove all contacts from this company`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>No</Button>
          <Button onClick={handleConfirmDeactivate} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

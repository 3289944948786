import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar
} from "@mui/material";
import { useCalendar } from "../contexts/CalendarContext";
import { logEvent } from "../utils/analyticsLogger";
import { getClosedOrder } from "./CalendarActions";
import DebouncedOrderSearch from "../components/DebouncedOrderSearch";
import { ICalendarEvent, IClosedOrder } from "../types/calendar";
import { useQueryParam, StringParam } from "use-query-params";
interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewClosingModal = ({ isOpen, setIsOpen }: Props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayErrorToast, setIsDisplayErrorToast] = useState(false);
  const { openViewOrderModal } = useCalendar();
  const [orderNumberUrlParam, setOrderNumberUrlParam] = useQueryParam("ordernumber", StringParam);

  const handleCloseModal = () => {
    setIsLoading(false);
    setIsOpen(false);
  };

  const handleCloseToast = () => setIsDisplayErrorToast(false);

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <DialogTitle>New Closing</DialogTitle>
        <DialogContent sx={{ marginTop: "35px" }}>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <DebouncedOrderSearch
              value={selectedValue}
              textboxMargin="dense"
              onSelect={async (e: React.SyntheticEvent<Element, Event>, option: string | null, reason: string) => {
                setSelectedValue(option || "");
                if (option) {
                  setIsLoading(true);
                  const { data }: { data: IClosedOrder } = await getClosedOrder(option);
                  if (!data) {
                    setIsLoading(false);
                    setIsDisplayErrorToast(true);
                    return;
                  }
                  logEvent("Closing Calendar", "New Closing", "");
                  openViewOrderModal({
                    order: data as ICalendarEvent,
                    mode: "edit"
                  });
                  setOrderNumberUrlParam(data.orderNumber);
                  handleCloseModal();
                }
              }}
            />
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={isDisplayErrorToast}
            autoHideDuration={5000}
            onClose={handleCloseToast}
          >
            <Alert onClose={handleCloseToast} severity="error" sx={{ width: "100%" }}>
              There is an issue with this order and it cannot be edited
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} disabled={isLoading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewClosingModal;

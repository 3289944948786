import { Grid, Stack, TextField, Autocomplete, Card } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { PropertyInfo } from "../orderEntry/types/Property";
import AddressSearch from "./AddressSearch";

interface Props {
  propertyInfo: any;
  setPropertyInfo: (value: any) => void;
}
export default function NewPropertyInfo(props: Props) {
  const [states, setStates] = useState<any[]>([]);
  const [counties, setCounties] = useState<any[]>([]);
  
  const unitInputRef = useRef<HTMLInputElement | null>(null);

  const getStates = async () => {
    const { data } = await axios.get("/api/blacklist/getstates");
    setStates(data);
  };
  const handleStateChange = (selectedState: any) => {
    if (selectedState) {
      setCounties(selectedState.counties ||  []); 
      props.setPropertyInfo( { ...props.propertyInfo, state: selectedState })
    } else {
      setCounties([]);
      props.setPropertyInfo( { ...props.propertyInfo, state: "" })
      props.setPropertyInfo( { ...props.propertyInfo, county: "" })
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Card sx={{ p: 3, maxWidth: 760, marginTop: 4 }}>
      <Stack direction="row" justifyContent="space-between" marginTop="40px" spacing={2}>
        <Grid item md={10} xs={12}>
          <AddressSearch propertyInfo={props.propertyInfo} setPropertyInfo={props.setPropertyInfo} states={states} counties={counties} setCounties={setCounties}/>
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            label="Unit/Suite/Apt #"
            value={props.propertyInfo?.aptNo || ""}
            onChange={(e) => {
              props.setPropertyInfo({ ...props.propertyInfo, aptNo: e.target.value });
            }}
            inputRef={unitInputRef}
          />
        </Grid>
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginTop="24px" spacing={3}>
        <Grid item md={10} xs={12}>
          <TextField
            label="City"
            value={props.propertyInfo?.city || ""}
            onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, city: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={states || []}
            renderInput={(params) => <TextField {...params} label="State" />}
            getOptionLabel={(option) => option.name || props.propertyInfo?.state}
            onChange={(e, value) => handleStateChange(value)}
            //onChange={(e, value) => props.setPropertyInfo({ ...props.propertyInfo, state: value })}
            value={props.propertyInfo?.state || null}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            label="Zip Code"
            value={props.propertyInfo?.zipCode || ""}
            onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, zipCode: e.target.value })}
          />
        </Grid>
      </Stack>
      <Stack direction="row" marginTop="30px" justifyContent="space-between" spacing={4}>
        <TextField
          label="Section/Borough"
          value={props.propertyInfo?.section || props.propertyInfo?.borough}
          onChange={(e) =>props.setPropertyInfo({ ...props.propertyInfo, section: e.target.value })}
        />
        <TextField
          label="Block"
          value={props.propertyInfo?.block}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, block: e.target.value })}
        />
        <TextField
          label="Lot"
          value={props.propertyInfo?.lots}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, lots: [e.target.value] })}
        />
         <Autocomplete
          options={counties || []}
          renderInput={(params) => <TextField {...params} label="County" fullWidth />}
          getOptionLabel={(option) => option.name || props.propertyInfo?.county}
          onChange={(e, val) => props.setPropertyInfo({ ...props.propertyInfo, county: val})}
          value={props.propertyInfo?.county || null}
          fullWidth
        />
      </Stack>
      <Stack direction="row" marginTop="30px" justifyContent="space-between" spacing={2}>
        <TextField
          label="Parcel ID"
          value={props.propertyInfo?.parcelIds}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, parcelIds: [e.target.value] })}
          fullWidth
        />
        <TextField
          label="Subdivision/Condo/PUD/Addition"
          value={props.propertyInfo?.condoNumber}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, condoNumber: e.target.value })}
          fullWidth
        />
      </Stack>
    </Card>
  );
}

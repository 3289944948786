export const discrepancyKeyAndNameList = [
  { key: "firstName", friendlyName: "First Name", isPersonalSection: false },
  { key: "middleName", friendlyName: "Middle Name", isPersonalSection: false },
  { key: "lastName", friendlyName: "Last Name", isPersonalSection: false },
  { key: "suffix", friendlyName: "Suffix", isPersonalSection: false },
  { key: "preferredName", friendlyName: "Preferred Name", isPersonalSection: true },
  { key: "personalEmail", friendlyName: "Personal Email", isPersonalSection: true },
  { key: "address1", friendlyName: "Address 1", isPersonalSection: true },
  { key: "address2", friendlyName: "Address 2", isPersonalSection: true },
  { key: "city", friendlyName: "City", isPersonalSection: true },
  { key: "state", friendlyName: "State", isPersonalSection: true },
  { key: "zip", friendlyName: "Zip", isPersonalSection: true },
  { key: "country", friendlyName: "Country", isPersonalSection: true }
];

export default function () {
  return (
    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="#">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.24433 0H18.6227L29.96 11.8168V34.7986C29.96 37.6637 27.6236 40 24.7586 40H5.24433C2.367 40 0.0429688 37.6637 0.0429688 34.7986V5.20136C0.0429688 2.32403 2.367 0 5.24433 0V0Z"
          fill="#FFD666"
        />
        <path
          id="Vector_2"
          opacity="0.4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.6094 0V11.7184H29.9589L18.6094 0Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M20.271 26.0808H9.73301C9.24116 26.0808 8.84766 26.4743 8.84766 26.9661C8.84766 27.4457 9.24116 27.8392 9.73301 27.8392H20.271C20.7628 27.8392 21.1563 27.4457 21.1563 26.9661C21.1563 26.4742 20.7628 26.0808 20.271 26.0808ZM20.271 29.5975H9.73301C9.24116 29.5975 8.84766 29.991 8.84766 30.4829C8.84766 30.9625 9.24116 31.3559 9.73301 31.3559H20.271C20.7628 31.3559 21.1563 30.9624 21.1563 30.4829C21.1563 29.991 20.7628 29.5975 20.271 29.5975ZM14.9098 19.0596H9.73301C9.24116 19.0596 8.84766 19.4407 8.84766 19.9326C8.84766 20.4245 9.24116 20.8056 9.73301 20.8056H14.9098C15.4016 20.8056 15.7951 20.4245 15.7951 19.9326C15.7951 19.4407 15.4016 19.0596 14.9098 19.0596ZM20.271 22.564H9.73301C9.24116 22.564 8.84766 22.9575 8.84766 23.4494C8.84766 23.9289 9.24116 24.3224 9.73301 24.3224H20.271C20.7628 24.3224 21.1563 23.9289 21.1563 23.4494C21.1563 22.9575 20.7628 22.564 20.271 22.564Z"
          fill="#B76E00"
        />
      </g>
    </svg>
  );
}

import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
// import {csv} from "papaparse";
import { columns } from "./columns";
import NotesModal from "./checkNotes/NotesModal";
import { ChecksContext } from "./ChecksContext";
import { useRef, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import CheckActions from "./CheckActions";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { InternalUser } from "../types/app";
import { fCurrency } from "../utils/formatNumber";
import { getOrderNumberDisplay } from "./getOrderNumberDisplay";
import { Check, DashboardReportType } from "./types";
import { NotesColumn } from "../reports/NotesColumn";
import Iconify from "../../minimals/components/iconify";
import { exportTableToCsv } from "../taskUtils";

export default function Checks() {
  const { checks, loading, setDateRange, dateRange, assignedToMe, setAssignedToMe, fetchChecks, setChecks, tableRef } =
    useContext(ChecksContext);
  const { user } = useContext(UserContext);
  const [managerDepartments, setManagerDepartments] = useState<string[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assignedToId = queryParams.get("assignedtoid");
  const isUnassignedOnly = Number(assignedToId) == -1;
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [assignToModalOpen, setAssignToModalOpen] = useState(false);
  const getAndSetManagerDepartmentsByEmail = async (emailAddress: string) => {
    const { data } = await axios.get<string[]>(
      `/api/outstandingchecks/getManagerDepartmentsByEmail?emailAddress=${emailAddress}`
    );
    setManagerDepartments(data);
  };

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };

  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  const filteredChecks = useMemo(() => {
    if (isUnassignedOnly) {
      return checks.filter((c) => !c.assignedToUser);
    }
    if (!assignedToMe) return checks;

    if (assignedToId) {
      return checks.filter((c) => assignedToId == String(c.assignedToUser?.id || ""));
    }
    return checks.filter(
      (c) => (user?.id && c.assignedToUser?.id == user.id) || managerDepartments.some((d) => d === c.department)
    );
  }, [assignedToMe, checks, assignedToId, user?.id, managerDepartments]);

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  useEffect(() => {
    if (user?.email) {
      getAndSetManagerDepartmentsByEmail(user.email);
    }
  }, [user]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const columnsWithNotes = NotesColumn<Check>(columns, setSelectedCheck);
  return (
    <>
      <Helmet>
        <title> Checks | TitleQ</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Outstanding Checks
          {isUnassignedOnly && " - Unassigned"}
          {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
        </Typography>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredChecks}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          enableRowSelection
          enableRowActions
          autoResetPageIndex={false}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility: {
              transactionType: false,
              voidedOn: false,
              status: false,
              extendedMemo: false
            }
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {}
            };
          }}
          state={{ showSkeletons: loading }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box sx={{ flexGrow: 1, position: "relative" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <CheckActions
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    assignedToMe={assignedToMe}
                    setAssignedToMe={setAssignedToMe}
                    table={table}
                    hideAssignedToMeOptions={!!assignedToId}
                    onRowsDeselected={onRowsDeselected}
                  />
                  <Box sx={{ position: "absolute", right: 0 }}>
                    <Tooltip title="Column ordering">
                      <IconButton onClick={toggleColumnOrdering}>
                        <Iconify icon="mdi:reorder-vertical" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export to csv">
                      <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                        <Iconify icon="eva:download-fill" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Box>
            );
          }}
        />
      </Container>
      {!!selectedCheck && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedCheck?.assignedToName || ""}
          emailBeforeUpdate={selectedCheck?.assignedToEmail || ""}
          entity={selectedCheck}
          setEntity={setSelectedCheck}
          setEntityTable={setChecks}
          orderNumberDisplay={getOrderNumberDisplay(selectedCheck)}
          amount={fCurrency(selectedCheck?.amount || "")}
          priority={selectedCheck?.priority}
          refetchData={() => fetchChecks({ useLoader: false, resetPagination: false })}
          reportType={DashboardReportType.OutstandingChecks}
        />
      )}
    </>
  );
}

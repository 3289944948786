import {
  format,
  getTime,
  formatDistanceToNow,
  isValid,
  differenceInDays,
  differenceInHours,
  differenceInMinutes
} from "date-fns";

// ----------------------------------------------------------------------
export function fDate(date: Date | string | number) {
  return format(new Date(date), "dd MMMM yyyy");
}
export function fDateAbbreviatedMonth(date: Date | string | number) {
  return format(new Date(date), "dd MMM yyyy");
}
export function fDateMonthYear(date: Date | string | number) {
  return format(new Date(date), "MMMM yyyy");
}
export function fDateMonthDayYear(date: Date | string | number) {
  return format(new Date(date), "MMMM dd, yyyy");
}
export function fMonth(date: Date | string | number) {
  return format(new Date(date), "MM");
}
export function fYear(date: Date | string | number) {
  return format(new Date(date), "yyyy");
}
export function fMonthDayYear(date: Date | string | number) {
  return format(new Date(date), "MM-dd-yyyy");
}

export function fMonthDayYearDotNotation(date: Date | string | number) {
  return format(new Date(date), "MM.dd.yyyy");
}

export function fMonthDayYearSlashNotation(date: Date | string | number) {
  return format(new Date(date), "MM/dd/yyyy");
}

export function fShortMonthDayYearSlashNotation(date: Date | string | number) {
  return format(new Date(date), "MM/dd/yy");
}

export function fMonthDayYearTimeDotNotation(date: Date | string | number) {
  return format(new Date(date), "MM.dd.yyyy p");
}
export function fMonthDayYearTimeSlashNotation(date: Date | string | number) {
  return format(new Date(date), "MM/dd/yyyy p");
}
export function fDateTime(date: Date | string | number) {
  return format(new Date(date), "dd MMM yyyy p");
}

export function fTime(date: Date | string | number) {
  return format(new Date(date), "p");
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

///Using an any here since its a validation function and that is the type that date-fns takes for this
export function fIsValidDate(date: any) {
  return isValid(new Date(date));
}

export function formatTimeElapsed(requestedDate: Date) {
  const currentTime = new Date();
  const timeElapsedMs = currentTime.getTime() - requestedDate.getTime();

  const minutes = Math.floor((timeElapsedMs / (1000 * 60)) % 60);
  const hours = Math.floor((timeElapsedMs / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeElapsedMs / (1000 * 60 * 60 * 24));
  
  return `${days > 0 ? `${days}d ` : ''}${hours}h ${minutes}m`;
}

export function isTimeExceeded(requestedDate: Date, thresholdHours: number) {
  const currentTime = new Date();
  const timeElapsedMs = currentTime.getTime() - requestedDate.getTime();
  const thresholdMs = thresholdHours * 60 * 60 * 1000;
  return timeElapsedMs > thresholdMs;
}
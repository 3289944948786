import { Card, CardContent, CardHeader, Stack, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HeaderTitle from "./HeaderTitle";
import axios from "axios";
import { useState } from "react";

const DebugMenu = ({ contactId }: { contactId: number }) => {
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [isSendToDynamicsLoading, setIsSendToDynamicsLoading] = useState(false);

  const onAddToLookupTableClick = async () => {
    setIsAddLoading(true);
    await axios.post("/api/clientphonebook/debugmenu/AddToLookupTable", { contactId });
    setIsAddLoading(false);
    alert("Initiated add to lookup table. This operation is queued - give it a minute till it shows up in Select");
  };

  const onSyncWithLookupTableClick = async () => {
    setIsSyncLoading(true);
    await axios.post("/api/clientphonebook/debugmenu/SyncWithLookupTable", { contactId });
    setIsSyncLoading(false);
    alert("Initiated sync with lookup table. This operation is queued - give it a minute till it shows up in Select");
  };

  const onSendToDynamicsClick = async () => {
    setIsSendToDynamicsLoading(true);
    await axios.post("/api/clientphonebook/debugmenu/SendToDynamicsOnly", { contactId });
    setIsSendToDynamicsLoading(false);
    alert("Successfully sent to Dynamics");
  };

  return (
    <Card>
      <CardHeader title={<HeaderTitle title="Debug Menu" />} />
      <CardContent>
        <Stack direction="column" alignItems="center" spacing={2}>
          <LoadingButton
            loading={isAddLoading}
            variant="contained"
            color="info"
            sx={{ width: "100%" }}
            onClick={onAddToLookupTableClick}
          >
            Add to Lookup Table (will send to dynamics too)
          </LoadingButton>
          <LoadingButton
            loading={isSyncLoading}
            variant="contained"
            color="info"
            onClick={onSyncWithLookupTableClick}
            sx={{ width: "100%" }}
          >
            Sync with Lookup Table (will send to dynamics too)
          </LoadingButton>
          <LoadingButton
            loading={isSendToDynamicsLoading}
            variant="contained"
            color="info"
            onClick={onSendToDynamicsClick}
            sx={{ width: "100%" }}
          >
            Send to Dynamics (only)
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DebugMenu;

import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { WritableDraft } from "immer/dist/internal";
import { debounce } from "lodash";
import { useCallback, useContext, useState } from "react";
import { OrderContext } from "../../../contexts/OrderDataContext";
import { CityData, Property, PropertyInfo } from "../../types/Property";

interface IProps {
  property: Property;
  propertyIndex: number;
  setProperty: (newProperty: PropertyInfo, index: number) => void;
  setPropertyProp: (index: number, cb: (property: WritableDraft<Property>) => void) => void;
  isDisabled: boolean;
  searchFullAddressDetails: (propertyInfo: PropertyInfo, index: number) => Promise<void>;
}

export default function City(props: IProps) {
  const { errorMode } = useContext(OrderContext);
  const { property, propertyIndex, setProperty, setPropertyProp, isDisabled, searchFullAddressDetails } = props;
  const { propertyInfo } = property;
  const [selectedCity, setSelectedCity] = useState<CityData>({
    city: "",
    state: "",
    county: "",
    zipCode: ""
  });
  const [options, setOptions] = useState<CityData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputEdited, setIsInputEdited] = useState(false);

  const onCitySearchTextChanged = async (text: string) => {
    if (!text || text.length < 3) {
      setOptions([]);
      setIsLoading(false);
      return;
    }
    const { data } = await axios.get(`/api/property/searchCityData?city=${encodeURIComponent(text)}`);
    setOptions(data || []);
    setIsLoading(false);
  };

  const handleInputChange = async (event: any, newInputValue: string, reason: any) => {
    if (reason === "reset") {
      return;
    }
    setIsInputEdited(true);
    setPropertyProp(propertyIndex, (property) => (property.propertyInfo.city = newInputValue));

    if (newInputValue.trim() === "") {
      setPropertyProp(propertyIndex, (property) => (property.propertyInfo.city = ""));
      setOptions([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setOptions([]);
    setPropertyProp(propertyIndex, (property) => (property.propertyInfo.city = newInputValue));
    if (reason == "input") await debouncedOnCityTextChanged(newInputValue);
    setIsLoading(false);
  };

  const debouncedOnCityTextChanged = useCallback(debounce(onCitySearchTextChanged, 400), []);

  return (
    <Autocomplete
      disableClearable
      disabled={isDisabled}
      value={selectedCity}
      inputValue={propertyInfo.city ?? ""}
      freeSolo
      options={options || []}
      getOptionLabel={(option: any) => option?.city || ""}
      renderOption={(props, option) => (
        <li {...props} key={`${option?.zipCode}${option?.county}${option?.city}${option?.state}`}>
          <div style={{ width: "100%" }}>
            <div>
              <Typography variant="subtitle2">{option.city}</Typography>
              <Typography variant="subtitle2" color="text.grey">
                County of {option.county}, {option.state}
              </Typography>
            </div>
          </div>
        </li>
      )}
      onChange={(e, value, reason) => {
        if (typeof value === "object") {
          setSelectedCity(value);
          setProperty(
            {
              ...property.propertyInfo,
              ...value,
              zipCode: propertyInfo.zipCode
            },
            propertyIndex
          );
        }
      }}
      onInputChange={handleInputChange}
      onBlur={async (e) => {
        const target = e.target as HTMLInputElement;
        if (!isInputEdited) return;
        if (propertyInfo.isFreeFormEntry && propertyInfo.city && propertyInfo.state) {
          searchFullAddressDetails(propertyInfo, propertyIndex);
          setIsInputEdited(false);
          return;
        }
        setIsInputEdited(false);
      }}
      renderInput={(params) => (
        <TextField
          required
          error={errorMode && !property.propertyInfo.city}
          {...params}
          label="City"
          // InputProps={{ ...params.InputProps, ...InputProps }}
          inputProps={{
            ...params.inputProps,
            name: "password",
            autoComplete: "off",
            type: "text"
          }}
        />
      )}
    />
  );
}

import { IconButton, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { RecordableDocument } from "./types";
import Iconify from "../../minimals/components/iconify";

interface CustomMouseEvent extends React.MouseEvent<HTMLLIElement> {
  stopPropagation: () => void;
}

export default function VersionCount({
  versionCount,
  documents,
  openVersionUpload
}: {
  versionCount: number;
  documents: RecordableDocument[];
  openVersionUpload: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: CustomMouseEvent) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <>
      <Typography variant="body2" color="primary" onClick={handleClick} sx={{ cursor: "pointer" }}>
        {versionCount > 1
          ? `${versionCount - 1} Previous Version${versionCount > 2 ? "s" : ""}`
          : "View Version History"}
      </Typography>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link
            width="100%"
            href={`https://internal.mcres.com/opendoc?id=${documents[0].momentumDocumentId}`}
            underline="none"
            target="_blank"
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} width="100%">
              <Typography>Current Version</Typography>
              <IconButton>
                <Iconify icon="eva:external-link-fill" />
              </IconButton>
            </Stack>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            width="100%"
            onClick={openVersionUpload}
          >
            <Typography>Upload New Version</Typography>
            <IconButton>
              <Iconify icon="eva:cloud-upload-fill" />
            </IconButton>
          </Stack>
        </MenuItem>
        {documents?.slice(1).map((d, i) => (
          <MenuItem onClick={handleClose}>
            <Link
              width="100%"
              href={`https://internal.mcres.com/opendoc?id=${d.momentumDocumentId}`}
              underline="none"
              target="_blank"
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} width="100%">
                <Typography>Version {documents.length - i - 1}</Typography>
                <IconButton>
                  <Iconify icon="eva:external-link-fill" />
                </IconButton>
              </Stack>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

import { countAndMapAssignedTasks } from "./commonUtils";
import { NonZeroLedgerBalanceInfo } from "../../nonZeroLedgerBalance/types";
import { MemberTaskCount } from "../../types";
import axios from "axios";

export const getNonZeroLedgerBalancesUniqueAssignedToList = (
  nonZeroLedgerBalances: NonZeroLedgerBalanceInfo[]
): MemberTaskCount[] => countAndMapAssignedTasks(nonZeroLedgerBalances, "Non Zero Ledger Balances");

export const searchNonZeroLedgerBalances = async (): Promise<NonZeroLedgerBalanceInfo[]> => {
  const { data } = await axios.get<NonZeroLedgerBalanceInfo[]>(`/api/outstandingchecks/getNonZeroLedgerBalance`);
  return data;
};

import React, { Component, ErrorInfo, ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { Alert, Box, Typography } from "@mui/material";
// hooks
import useResponsive from "../../../../minimals/hooks/useResponsive";
// components
import { useSettingsContext } from "../../../../minimals/components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import NavHorizontal from "./nav/NavHorizontal";
import axios from "axios";

// ----------------------------------------------------------------------

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    await axios.post("/api/ErrorLogger/log", {
      header: "Frontend Error",
      message: window.location.href + "\r\n" + error.stack
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          <Typography variant="h4">Something went wrong.</Typography>
          <Typography variant="body1">{this.state.error?.stack}</Typography>
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default function DashboardLayout() {
  const { themeLayout, isAppInIFrame } = useSettingsContext();

  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: !isNavHorizontal ? "flex" : null },
          minHeight: { lg: !isNavHorizontal ? 1 : null }
        }}
      >
        {isNavHorizontal && isDesktop && <NavHorizontal />}
        {!isNavHorizontal && isNavMini && isDesktop ? <NavMini /> : !isAppInIFrame && renderNavVertical}

        <Main>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Main>
      </Box>
    </>
  );
}

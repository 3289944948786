import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ContactContext } from "../ContactContext";
import { AddEditContactContext } from "../addEditContact/AddEditContactContext";

export default function NoCompanyAssociated() {
  const { contact } = useContext(ContactContext);
  const { setAddCompanyNameMode, setFocusOnCompanyName } = useContext(AddEditContactContext);
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader title="No Company Associated" />
      <CardContent>
        <Stack direction="row" justifyContent="center">
          <Button
            onClick={() => {
              setFocusOnCompanyName(true);
              setAddCompanyNameMode(true);
              navigate(`../phonebook/edit-contact?id=${contact?.id}`);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
            variant="contained"
            disabled={!contact?.isActive}
          >
            Add a Company Name
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

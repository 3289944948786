import { Alert, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import NewPropertyInfo from "./NewPropertyInfo";
import { useState } from "react";
import { useNavigate } from "react-router";
import { BlacklistPropertyInfo, BulletinInformation, UploadInfo } from "./types";
import produce from "immer";
import BulletinInfo from "./BulletinInfo";
import axios from "axios";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";
import { toBase64 } from "../utils/helpers";
import { PropertyInfo } from "../orderEntry/types/Property";
import { id } from "date-fns/locale";

export default function AddBlacklistProperty() {
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const baseBulletin = {
    state: { value: null, label: "" },
    county: { value: null, label: "" },
    uploads: [],
    note: ""
  };

  const [property, setProperty] = useState<PropertyInfo>({} as PropertyInfo);
  const [bulletinInfo, setBulletinInfo] = useState<BulletinInformation>(baseBulletin);
  const [uploads, setUploads] = useState<UploadInfo[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const addOrEditUpload = (newUpload: UploadInfo, index: number | null = null) => {
    if (index === null) {
      setUploads((prevUploads) => [...prevUploads, newUpload]);
      return;
    }
    setUploads((prevUploads) => {
      return prevUploads.map((pu, i) => {
        return i === index ? newUpload : pu;
      });
    });
  };

  const changeBulletinInfo = (label: keyof BulletinInformation, value: any) => {
    const newBulletinInfo = produce(bulletinInfo, (draft: any) => {
      (draft[label] as keyof BulletinInformation) = value;
    });
    setBulletinInfo(newBulletinInfo);
  };
  const handleRemoveUpload = (index: number) => {
    setUploads((prevUploads) => prevUploads.filter((_, i) => i !== index));
  };
  
  const resetFunction = () => {
    setSuccessMessage(true);
    setBulletinInfo(baseBulletin);
    setProperty({} as PropertyInfo);
    setUploads([]);
    setSuccessMessage(false);
    navigate("/blacklistsearch");
  }

  const onSubmit = async () => {
    setSubmitting(true);
    const blacklistProperty = {
      property: {
        ...property,
        unit: property?.aptNo,
        zip: property?.zipCode,
        lot: property?.lots.toString(),
        parcelId: property?.parcelIds.toString(),
        subdivision: property?.condoNumber,
         //@ts-ignore
        countyId: property?.county?.id,
        //@ts-ignore
        stateId: property?.state?.id,
        latitude: 0,
        longitude: 0,
        geocodeAccuracy: ""
      },
      //@ts-ignore
      countyId: bulletinInfo.county.id,
      bulletinsWithBase64: await Promise.all(
        uploads.map(async (u) => {
          const base64File = stripDataUriPrefix(await toBase64(u.file));
          return {
            bulletin: {
              number: u.number,
              date: u.date,
              //@ts-ignore
              underwriterId: u.underwriter.id
            },
            base64File
          };
        })
      )
    };
    console.log(blacklistProperty)
    setSubmitting(true);
    await axios.post("/api/blacklist/addPropertyRecord", blacklistProperty);
    resetFunction();
  };
  const onCancel = () => {
    navigate("/blacklistsearch");
  };

  return (
    <Page title="Blacklist" width="100%">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom={2}>
                Add Property to Blacklist
              </Typography>
            </Stack>
            <Typography variant="h5">Address</Typography>
            <NewPropertyInfo propertyInfo={property} setPropertyInfo={setProperty} />
            <Typography variant="h5" marginTop="40px">
              Bulletin Information
            </Typography>
            <BulletinInfo
              bulletinInfo={bulletinInfo}
              setBulletinInfo={changeBulletinInfo}
              uploads={uploads}
              addUpload={addOrEditUpload}
              handleRemoveUpload={handleRemoveUpload}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="right" spacing={1.5} width={784} marginTop={8}>
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={submitting}>
            {submitting ? "Saving..." : "Save"}
          </Button>
          {successMessage && (
            <Alert sx={{ marginTop: 2 }} severity="success">
              Successfully Saved
            </Alert>
          )}
        </Stack>
      </Container>
    </Page>
  );
}

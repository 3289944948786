import { Chip, Link } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { fMonthDayYearDotNotation } from "../../utils/formatTime";
import { OrderNumberCell } from "../OrderNumberCell";
import { getOrderNumberDisplay, orderNumberFilterFn } from "../ReportsUtils";
import { PolicyNotIssued } from "./types";
import SingleDateRangeFilter from "./SingleDateRangeFilter";

export const columns: MRT_ColumnDef<PolicyNotIssued>[] = [
  {
    id: "notes",
    header: "",
    enableColumnFilter: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,
    size: 50,
    enableHiding: false
  },
  {
    header: "Order Number",
    accessorKey: "orderNumber",
    id: "orderNumber",
    accessorFn: getOrderNumberDisplay,
    filterFn: orderNumberFilterFn,
    Cell: OrderNumberCell,
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Settlement Date",
    id: "settlementDate",
    accessorFn: (row) => (row.settlementDate ? new Date(row.settlementDate) : null),
    sortingFn: "datetime",
    filterFn: (row, id, filterValue) => {
      if (filterValue && filterValue[0] && filterValue[1] && !row.original.settlementDate) {
        return false;
      } else if (filterValue && filterValue[0] && filterValue[1] && row.original.settlementDate) {
        const settlementDate = new Date(row.original.settlementDate).getTime();
        const startDate = new Date(filterValue[0]).getTime();
        const endDate = new Date(filterValue[1]).getTime();

        return settlementDate >= startDate && settlementDate <= endDate;
      } else {
        return true;
      }
    },
    Filter: SingleDateRangeFilter,
    Cell: ({ row }) => <Chip label={fMonthDayYearDotNotation(row.original.settlementDate)} />
  },
  {
    header: "Closer",
    id: "closerUser",
    accessorFn: (row) => row.closerUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.closerUser?.email) {
        return <Link href={`mailto:${row.original.closerUser.email}`}>{renderedCellValue || ""}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Funder",
    id: "funderUser",
    accessorFn: (row) => row.funderUser?.fullName || "",
    filterVariant: "select",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.funderUser?.email) {
        <Link href={`mailto:${row.original.funderUser.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Post Closer",
    id: "postCloser",
    accessorFn: (row) => row.postCloserUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.postCloserUser?.email) {
        return <Link href={`mailto:${row.original.postCloserUser?.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Assigned To",
    id: "assignedTo",
    accessorFn: (row) => row.assignedToUser?.fullName || "",
    Cell: ({ renderedCellValue = "", row }) => {
      if (row?.original?.assignedToUser?.email) {
        return <Link href={`mailto:${row.original.assignedToUser.email}`}>{renderedCellValue}</Link>;
      }
      return renderedCellValue;
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];

import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";

interface IProps {
  to: string;
  openInNewTab?: boolean; // Optional prop to control opening in a new tab
}

const NavigationIconButton = ({ to, openInNewTab, ...props }: IProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (openInNewTab) {
      window.open(to, "_blank", "noopener,noreferrer");
    } else {
      navigate(to);
    }
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      <Iconify icon="lucide:merge" />
    </IconButton>
  );
};

export default NavigationIconButton;

import { Card, Typography, CardHeader, Stack, Box, Link } from "@mui/material";
import { useContext } from "react";
import { ContactContext } from "../ContactContext";
import Phone from "../../shared/phones/Phone";
import { StyledIcon } from "../../../components/StyledIcon";

// ----------------------------------------------------------------------

export default function About() {
  const { contact } = useContext(ContactContext);
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">{contact?.contactRoles[0]?.branch?.company?.name}</Typography>} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {contact?.contactRoles[0]?.role?.name && (
          <Stack direction="row">
            <StyledIcon icon="eva:person-fill" />
            <Box>
              <Typography variant="subtitle2">{contact?.contactRoles[0]?.role?.name}</Typography>
            </Box>
          </Stack>
        )}

        {contact?.contactRoles[0]?.branch?.email && (
          <Stack direction="row">
            <StyledIcon icon="eva:email-fill" />
            <Typography variant="body2">
              <Link color="initial" href={`mailto:${contact?.contactRoles[0]?.branch?.email}`}>
                {contact?.contactRoles[0]?.branch?.email}
              </Link>
            </Typography>
          </Stack>
        )}

        {contact?.contactRoles[0]?.phoneNumbers?.map((p) => (
          <Phone phone={p} />
        ))}

        {contact?.contactRoles[0]?.branch?.company?.website && (
          <Stack direction="row">
            <StyledIcon icon="eva:globe-3-fill" color="#0000EE" />

            <Typography variant="body2">
              <Link
                color="#0000EE"
                target="blank"
                href={`https://${contact?.contactRoles[0]?.branch?.company?.website}`}
              >
                {contact?.contactRoles[0]?.branch?.company?.website}{" "}
              </Link>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}

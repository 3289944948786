import { statusColorMap } from "./CalendarUtils";
import { useCalendar } from "../contexts/CalendarContext";
import { logEvent } from "../utils/analyticsLogger";
import { CircularProgress } from "@mui/material";
import { EventContentArg } from "@fullcalendar/core";
import { ICalendarEvent } from "../types/calendar";
import { StringParam, useQueryParam } from "use-query-params";

interface Props {
  eventInfo: EventContentArg;
  isLoading: boolean;
  view: string;
}

const CalendarEventContent = ({ eventInfo, isLoading, view }: Props) => {
  const { viewMode, setModalOrder } = useCalendar();
  const [orderNumberUrlParam, setOrderNumberUrlParam] = useQueryParam("ordernumber", StringParam);
  const calendarEvent = (eventInfo?.event?.extendedProps || {}) as ICalendarEvent;
  const { status = "default", accountRep = "", funder = "", clientName = "", titleCloser = "" } = calendarEvent;
  const color = statusColorMap[status] || statusColorMap["default"];

  const handleClick = () => {
    logEvent("Closing Calendar", "View Closing", "");
    setOrderNumberUrlParam(calendarEvent.orderNumber);
    setModalOrder(calendarEvent);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <span style={{ color, fontSize: "12px", fontWeight: 700 }} onClick={handleClick}>
        {" "}
        {eventInfo.event.title}
      </span>
      {viewMode === "rep" && (
        <span style={{ paddingLeft: "7px", fontSize: "11px" }}>
          {accountRep || "..."} / {funder || "..."}
        </span>
      )}
      {viewMode === "client" && <span style={{ paddingLeft: "7px", fontSize: "11px" }}>{clientName}</span>}
      {viewMode === "titlecloser" && <span style={{ paddingLeft: "7px", fontSize: "11`px" }}>{titleCloser}</span>}
      {!eventInfo.isDraggable && view == "dayGridMonth" && <CircularProgress size="12px" sx={{ marginLeft: "5px" }} />}
    </>
  );
};
export default CalendarEventContent;

import { Button, Card, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

type Prop = {
  section?: string;
  navigationUrlBuilder?: (orderNumber: string) => string;
};

export default function OrderNumberPrompt(prop: Prop) {
  const { section, navigationUrlBuilder } = prop;
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState<string>();
  const [submissionAttempted, setSubmissionAttempted] = useState<boolean>(false);
  const [invalidOrderNumber, setInvalidOrderNumber] = useState<boolean>(false);

  const testOrderNumber = async () => {
    setSubmissionAttempted(true);
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/GetOrderInfo?orderNumber=${orderNumber}`
    });
    setInvalidOrderNumber(data === null);
  };

  useEffect(() => {
    if (submissionAttempted && !invalidOrderNumber) {
      if (!section && !navigationUrlBuilder) {
        return;
      }
      if (!orderNumber) {
        return;
      }
      const url = section ? `/${section}/${orderNumber}` : navigationUrlBuilder!(orderNumber);
      navigate(url);
    }
  }, [invalidOrderNumber, submissionAttempted]);

  const handleOrderNumberChange = (orderNumber: string) => {
    setSubmissionAttempted(false);
    setOrderNumber(orderNumber);
  };

  return (
    <Container>
      <Card sx={{ width: 600 }}>
        <Grid container padding={4} spacing={4}>
          <Grid item xs={12}>
            <Typography>Please enter an order number to continue</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <TextField
                error={submissionAttempted && invalidOrderNumber}
                helperText={submissionAttempted && invalidOrderNumber && "Invalid order number"}
                label="Order Number"
                value={orderNumber}
                onChange={(e) => handleOrderNumberChange(e.target.value)}
              />
              <Button
                type="submit"
                disabled={submissionAttempted}
                variant="contained"
                onClick={() => testOrderNumber()}
              >
                {submissionAttempted ? "Submitting..." : "Continue"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

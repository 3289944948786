import { MSGExtractFileWithType } from "../shared/types/FileWithType";

export const getUniqueListByKey = (arr: any[], key: string) => [
  ...new Map(arr.map((item) => [item[key], item])).values()
];

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const msgAttachmentToBase64 = async (file: MSGExtractFileWithType) => {
  if (!file.data.length) {
    return "";
  }
  const base64url = await new Promise<string>((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result as string);
    reader.readAsDataURL(new Blob([file.data]));
  });

  return base64url.split(",", 2)[1];
};

export const stripBase64LeadingChars = (str: string) => {
  const indexOfComma = str.indexOf(",");
  return str.substr(indexOfComma + 1);
};

import { Grid, Stack } from "@mui/material";
import { useContext } from "react";
import { OrderContext } from "../contexts/OrderDataContext";
import Principal from "./Principal";
import { Contact } from "./types/Contact";

export default function BuyerSellerPrincipal() {
  const {
    selectedBuyerPrincipals,
    setSelectedBuyerPrincipals,
    selectedSellerPrincipals,
    setSelectedSellerPrincipals,
    transactionType
  } = useContext(OrderContext);

  const addBuyerPrincipal = () => {
    const newPrincipal = {} as Contact;
    setSelectedBuyerPrincipals([...selectedBuyerPrincipals, newPrincipal]);
  };

  const addSellerPrincipal = () => {
    const newPrincipal = {} as Contact;
    setSelectedSellerPrincipals([...selectedSellerPrincipals, newPrincipal]);
  };

  const setBuyerPrincipal = (index: number, updatedPrincipal: Contact) => {
    const updatedPrincipals = selectedBuyerPrincipals.map((p, i) => (i === index ? updatedPrincipal : p));
    setSelectedBuyerPrincipals(updatedPrincipals);
  };

  const setSellerPrincipal = (index: number, updatedPrincipal: Contact) => {
    const updatedPrincipals = selectedSellerPrincipals.map((p, i) => (i === index ? updatedPrincipal : p));
    setSelectedSellerPrincipals(updatedPrincipals);
  };

  const handleDeleteBuyerPrincipal = (index: number) => {
    const updatedPrincipals = selectedBuyerPrincipals.splice(index, 1);
    setSelectedBuyerPrincipals(updatedPrincipals);
  };

  const handleDeleteSellerPrincipal = (index: number) => {
    const updatedPrincipals = selectedSellerPrincipals.splice(index, 1);
    setSelectedSellerPrincipals(updatedPrincipals);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Stack direction="column" spacing={2}>
          {selectedBuyerPrincipals?.map((bp, i) => (
            <Principal
              principal={bp}
              updatePrincipal={(principal: Contact) => setBuyerPrincipal(i, principal)}
              isBuyerPrincipal={true}
              addBuyerPrincipal={addBuyerPrincipal}
              index={i}
              deleteContact={handleDeleteBuyerPrincipal}
            />
          ))}
        </Stack>
      </Grid>
      {transactionType !== "Refinance" && (
        <Grid item md={6} xs={12}>
          <Stack direction="column" spacing={2} alignItems="flex-end">
            {selectedSellerPrincipals?.map((sp, i) => (
              <Principal
                principal={sp}
                updatePrincipal={(principal: Contact) => setSellerPrincipal(i, principal)}
                isBuyerPrincipal={false}
                addSellerPrincipal={addSellerPrincipal}
                index={i}
                deleteContact={handleDeleteSellerPrincipal}
              />
            ))}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

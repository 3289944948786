import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import AddressAutocomplete from "./AddressAutocomplete";
import { CompanyBranch, SmartyAddress } from "../../types";
import { useImmer } from "use-immer";
import axios from "axios";
import { MuiTelInput } from "mui-tel-input";
import { usStateList } from "../../../utils/usStateList";
import { countryList } from "../../../utils/countryList";

export default function AddNewBranchToCompany() {
  const { selectedCompany, setNewBranch, setSelectedCompanyBranch, handleCompanyBranchChange } =
    useContext(AddEditContactContext);
  const existingHeadquarterBranch = selectedCompany?.branches.find((b) => b.isHeadquarters);
  const isExistingEmptyHeadquarterBranch = existingHeadquarterBranch && !existingHeadquarterBranch.address1;

  const companyHasHeadquarters = useMemo(() => selectedCompany?.branches.some((b) => b.isHeadquarters), []);

  const defaultBranch = {
    companyId: selectedCompany?.id,
    isHeadquarters: !companyHasHeadquarters
  } as CompanyBranch;

  const [branch, setBranch] = useImmer<CompanyBranch>(
    isExistingEmptyHeadquarterBranch ? existingHeadquarterBranch : defaultBranch
  );

  const handleBranchChange = (key: keyof CompanyBranch, value: any) => {
    setBranch((draft) => {
      (draft[key] as any) = value;
    });
  };

  const handleOptionChange = (option: SmartyAddress | null) => {
    if (option) {
      setBranch((draft) => {
        draft.address1 = option?.formattedStreet_line;
        draft.address2 = option.secondary;
        draft.city = option.city;
        draft.state = option.state;
        draft.zip = option.zipcode;
        draft.country = "United States";
      });
    }
  };

  const [submitting, setSubmitting] = useState(false);

  const getBranchById = async (id: number) => {
    const { data } = await axios.get(`/api/clientphonebook/companybranch/${id}`);
    return data;
  };

  const submit = async () => {
    setSubmitting(true);

    const updateMode = !!branch.id;

    if (updateMode) {
      await axios.put("/api/clientphonebook/companybranch/update", branch);
      setSelectedCompanyBranch(branch);
      handleCompanyBranchChange(branch);
    } else {
      const { data } = await axios.post("/api/clientphonebook/companybranch/add", branch);
      const returnedBranch = await getBranchById(data);
      setSelectedCompanyBranch(returnedBranch);
      handleCompanyBranchChange(returnedBranch);
    }
    setNewBranch(false);
    setSubmitting(false);
  };

  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Card
          sx={{
            backgroundColor: "rgba(24, 23, 168, 0.08)"
          }}
        >
          <CardContent>
            <Stack alignItems="center" marginBottom={2.5}>
              <Typography variant="overline" color={theme.palette.text.secondary}>
                {`ADD A NEW BRANCH TO ${selectedCompany?.name}`}
              </Typography>
            </Stack>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={branch.isHeadquarters}
                      onChange={(_, checked) => handleBranchChange("isHeadquarters", checked)}
                      name="Headquarters"
                    />
                  }
                  label="Headquarters"
                />
                {companyHasHeadquarters && !isExistingEmptyHeadquarterBranch && (
                  <Typography variant="caption" color={theme.palette.error.main} display="block">
                    This toggle will move company headquarters to this location
                  </Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <AddressAutocomplete
                  setAddressOption={handleOptionChange}
                  setAddress1={(value) => handleBranchChange("address1", value)}
                  inputValue={branch.address1 || ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Address 2"
                  value={branch.address2 || ""}
                  onChange={(e) => handleBranchChange("address2", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="City"
                  value={branch.city || ""}
                  onChange={(e) => handleBranchChange("city", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={
                    branch.country === "United States" || branch.country === "US" || !branch.country ? usStateList : []
                  }
                  value={branch.state || ""}
                  freeSolo
                  onChange={(_, value) => handleBranchChange("state", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password"
                      }}
                      fullWidth
                      label="State"
                      onChange={(e) => handleBranchChange("state", e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Zip"
                  value={branch.zip || ""}
                  onChange={(e) => handleBranchChange("zip", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={branch?.country || ""}
                  options={countryList}
                  onChange={(_, value) => handleBranchChange("country", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password"
                      }}
                      fullWidth
                      label="Country"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiTelInput
                  value={branch.phone}
                  onChange={(value) => handleBranchChange("phone", value)}
                  preferredCountries={["US", "IL"]}
                  defaultCountry="US"
                  forceCallingCode
                  inputProps={{ maxLength: 12 }}
                  label="Company Phone"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Company Email"
                  value={branch.email}
                  onChange={(e) => handleBranchChange("email", e.target.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack direction="row" justifyContent="flex-end" marginTop={3} columnGap={2}>
          <Button variant="contained" color="inherit" onClick={() => setNewBranch(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submit} disabled={submitting}>
            {submitting ? "Saving..." : "Save Location"}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

import { Button, Grid } from "@mui/material";
import { useContext } from "react";
import { NotesContext } from "../contexts/NotesContext";
import Iconify from "../../minimals/components/iconify";
import { convertToFileDownload } from "../utils/convertToFileDownload";
import { logEvent } from "../utils/analyticsLogger";
import { UserContext } from "../contexts/UserContext";

export default function DownloadButton() {
  const { order, notesPdf } = useContext(NotesContext);
  const { user } = useContext(UserContext);

  const content = {
    user: user?.name,
    order: order?.orderNumber,
    notesPdf: notesPdf
  };
  const handleDownload = () => {
    if (notesPdf) {
      const fileName = order?.orderNumber + "-notes.pdf";
      convertToFileDownload(notesPdf, fileName);
      logEvent("Add Notes", "Add Notes", JSON.stringify({ content }, null, 2));
    }
  };
  return (
    <Grid container marginTop={2} marginBottom={2}>
      <Grid item xs={10}></Grid>
      <Grid item xs={2}>
        <Button color="primary" variant="outlined" disabled={!notesPdf} fullWidth onClick={handleDownload}>
          Download <Iconify sx={{ marginLeft: 1 }} icon={"eva:download-fill"} />
        </Button>
      </Grid>
    </Grid>
  );
}

import { Card, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { SearchesContext } from "../../contexts/SearchesContext";
import OrderAttachments from "../../shared/OrderAttachments";

export default function SearchAttachments() {
  const { orderFiles, setOrderFiles, selectedAttachments, setSelectedAttachments, orderNumber } =
    useContext(SearchesContext);
  return (
    <>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5" marginBottom={2}>
          Attachments
        </Typography>
        {/* <Attachments orderFiles={orderFiles} setOrderFiles={setOrderFiles} errorMode={false} fullWidth /> */}
        <OrderAttachments
          selectedAttachments={selectedAttachments}
          setSelectedAttachments={setSelectedAttachments}
          orderNumber={orderNumber}
        />
      </Card>
    </>
  );
}

import { Box, Button, Card, CardContent, CardHeader, Divider, Paper, Skeleton, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RecordableTrackingOrderInfo } from "./types";
import { fCurrency } from "../utils/formatNumber";
import Iconify from "../../minimals/components/iconify";
import OpenInHomePageButton from "../searches/OpenInHomePageButton";

export default function OrderSidebar() {
  const [searchParams] = useSearchParams();
  const order = searchParams.get("order");
  const [orderInfo, setOrderInfo] = useState<RecordableTrackingOrderInfo>();
  const [ledgerBalance, setLedgerBalance] = useState("");
  const [isLedgerBalanceLoading, setIsLedgerBalanceLoading] = useState(true);
  const [ledgerExists, setLedgerExists] = useState<boolean | null>(null);

  const getAndSetOrderInfo = async () => {
    const { data } = await axios.get(`/api/recordablestracking/orderInfo/getinfo?orderNumber=${order}`);
    setOrderInfo(data);
    await getLedgerBalance(data.orderNumber);
  };

  const getLedgerBalance = async (orderNumber: string) => {
    const ledgerExists = await checkIfLedgerExists(orderNumber);
    if (ledgerExists) {
      const { data } = await axios.get(
        `/proxy/api/ledgers/getOrderAndLedgerBalance?orderNumber=${orderNumber}-RECORDING`
      );
      setLedgerBalance(data.ledgerBalance);
    }
    setIsLedgerBalanceLoading(false);
  };

  const checkIfLedgerExists = async (orderNumber: string): Promise<boolean> => {
    const { data } = await axios.get(`/proxy/api/ledgers/ledgerexists?ordernumber=${orderNumber}-RECORDING`);
    setLedgerExists(data.exists);
    return data.exists;
  };

  useEffect(() => {
    if (!order) return;
    getAndSetOrderInfo();
  }, [order]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="column" spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {ledgerExists === false && <Typography variant="body2">Ledger does not exist</Typography>}
              {ledgerExists !== false && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2" display="inline-block">
                    Ledger Balance{" "}
                  </Typography>
                  {!isLedgerBalanceLoading && (
                    <Typography display="inline-block" variant="body2">
                      {fCurrency(ledgerBalance)}
                    </Typography>
                  )}
                  {isLedgerBalanceLoading && <Skeleton sx={{ display: "inline-block" }} width={75} />}
                </Stack>
              )}
              <Button
                variant="outlined"
                component="a"
                size="small"
                href={`/ledger/${orderInfo?.orderNumber}-RECORDING`}
                target="_blank"
                disabled={!ledgerExists}
              >
                Ledger
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography variant="body1">Order Information</Typography>
                <Typography variant="subtitle1">{orderInfo?.orderNumber}</Typography>
              </Box>
              <Box>
                <OpenInHomePageButton orderNumber={orderInfo?.orderNumber || ""} />
              </Box>
            </Stack>
          </Stack>
        }
      ></CardHeader>
      <CardContent>
        <Stack direction="column" spacing={2}>
          {!!orderInfo?.buyerBorrowerName && (
            <Box>
              <Typography variant="caption">Buyer/Borrower Name:</Typography>
              <Typography>{orderInfo?.buyerBorrowerName}</Typography>
            </Box>
          )}
          {orderInfo?.sellerName && (
            <Box>
              <Typography variant="caption">Seller Name</Typography>
              <Typography>{orderInfo?.sellerName}</Typography>
            </Box>
          )}
          {!!orderInfo?.purchasePrice && (
            <Box>
              <Typography variant="caption">Purchase Price</Typography>
              <Typography>{fCurrency(orderInfo?.purchasePrice)}</Typography>
            </Box>
          )}
          {orderInfo?.loans.map((la, index) => (
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Iconify icon="mdi:bank-outline" color="primary" />
                  <Typography variant="subtitle2">Loan {orderInfo?.loans.length > 1 && ` #${index + 1}`}</Typography>
                </Stack>
                <Box>
                  <Typography variant="caption">Lender:</Typography>
                  <Typography>{la.lender}</Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Amount:</Typography>
                  <Typography>{fCurrency(la.amount)}</Typography>
                </Box>
              </Stack>
            </Paper>
          ))}
          <Divider />
          {orderInfo?.properties.map((p, index) => (
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Iconify icon="eva:home-outline" color="primary" />
                  <Typography variant="subtitle2">
                    Property {orderInfo?.properties.length > 1 && ` #${index + 1}`}
                  </Typography>
                </Stack>
                {p.county && (
                  <Box>
                    <Typography variant="caption">County:</Typography>
                    <Typography>{p.county}</Typography>
                  </Box>
                )}
                {p.lots.length > 0 && (
                  <Box>
                    <Typography variant="caption">Lots:</Typography>
                    <Typography>{p.lots.join(", ")}</Typography>
                  </Box>
                )}
                {p.parcelIds.length > 0 && (
                  <Box>
                    <Typography variant="caption">Parcel Id(s):</Typography>
                    <Typography>{p.parcelIds.join(", ")}</Typography>
                  </Box>
                )}
                {!p.legalDescription && !p.county && p.lots.length === 0 && p.parcelIds.length === 0 && (
                  <Typography>No Information</Typography>
                )}
                {p.legalDescription && (
                  <Box>
                    <Typography variant="caption">Legal Description:</Typography>
                    <Typography>{p.legalDescription}</Typography>
                  </Box>
                )}
              </Stack>
            </Paper>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

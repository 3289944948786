import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import DashboardCard from "./DashboardCard";
import { reports } from "./reports";

export default function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={2} rowSpacing={5}>
          {reports.map((r) => (
            <Grid item md={2.6} xs={12}>
              <DashboardCard
                title={r.title}
                to={r.to}
                apiUrl={r.apiUrl}
                color={r.color}
                backgroundColor={r.backgroundColor}
                icon={r.icon}
                isWire={r.isWire}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

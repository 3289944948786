import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ProgressLoader from "../components/ProgressLoader";
import SendViaEmailButton from "./SendViaEmailButton";

import { useContext } from "react";
import { SearchesContext } from "../contexts/SearchesContext";

export default function Preview() {
  const { preview, searchSections, onPreivewSendViaEmailClick, closePreview, orderNumber, order } =
    useContext(SearchesContext);
  const { base64Document, index } = preview!;
  const vendor = searchSections[index].vendor;
  const searchType = searchSections[index].searchRequests[0].searchType;
  const isDocumentLoaded = !!base64Document;

  const title = `${order?.titleCode ? order?.titleCode + "-" : ""}${orderNumber} / ${searchType}`;

  return (
    <Dialog maxWidth="lg" fullWidth={true} open={true} onClose={closePreview}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          height={1000}
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}
        >
          {!isDocumentLoaded && <ProgressLoader size={80} />}
          {isDocumentLoaded && (
            <iframe
              allowFullScreen={true}
              title={title}
              src={`data:application/pdf;base64,${base64Document}#toolbar=0`}
              height="100%"
              width="100%"
              style={{ border: "none" }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <SendViaEmailButton disabled={!isDocumentLoaded} onClick={onPreivewSendViaEmailClick} />
        <Button variant="outlined" size="small" onClick={closePreview}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

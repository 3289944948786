import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
import { useContext } from "react";
import { columns } from "./columns";
import { SearchesNotOrderedContext } from "./SearchesNotOrderedContext";
import SearchesNotOrderedFilters from "./SearchesNotOrderedFilters";

export default function SearchesNotOrdered() {
  const { tasks, loading, assignedToMe, setAssignedToMe } = useContext(SearchesNotOrderedContext);

  return (
    <>
      <Helmet>
        <title>Searches Not Ordered | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Searches Not Ordered
        </Typography>
        <MaterialReactTable
          columns={columns}
          data={tasks}
          enableGlobalFilter={true}
          globalFilterFn="contains"
          enableFacetedValues={true}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 }
          }}
          state={{
            showSkeletons: loading,
            columnVisibility: {
              requestedBy: !!!assignedToMe
            }
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            // Apply zebra striping styles to alternate rows
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {} // Change the background color as needed
            };
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <SearchesNotOrderedFilters assignedToMe={assignedToMe} setAssignedToMe={setAssignedToMe} />
          )}
        />
      </Container>
    </>
  );
}

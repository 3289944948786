import { Autocomplete, TextField, Typography } from "@mui/material";
import axios from "axios";
import { WritableDraft } from "immer/dist/internal";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { ZipMask } from "../../../components/TextMaskCustom";
import { validateZipcode } from "../../../utils/validation";
import { CityData, Property, PropertyInfo } from "../../types/Property";
interface IProps {
  property: Property;
  propertyIndex: number;
  setProperty: (newProperty: PropertyInfo, index: number) => void;
  setPropertyProp: (index: number, cb: (property: WritableDraft<Property>) => void) => void;
  isDisabled: boolean;
  searchFullAddressDetails: (propertyInfo: PropertyInfo, index: number) => Promise<void>;
}

export default function Zip(props: IProps) {
  const { property, propertyIndex, setProperty, setPropertyProp, isDisabled, searchFullAddressDetails } = props;
  const [zipCodeError, setZipCodeError] = useState("");
  const { propertyInfo } = property;

  const [selectedZip, setSelectedZip] = useState<CityData>({
    city: "",
    state: "",
    county: "",
    zipCode: ""
  });
  const [options, setOptions] = useState<CityData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputEdited, setIsInputEdited] = useState(false);

  const onZipSearchTextChanged = async (text: string) => {
    if (!text || text.length < 3) {
      setOptions([]);
      setIsLoading(false);
      return;
    }
    const { data } = await axios.get<CityData[]>(`/api/property/searchZipData?zipCode=${encodeURIComponent(text)}`);
    setOptions(data || []);
    setIsLoading(false);
  };

  const handleInputChange = async (event: any, newInputValue: string, reason: any) => {
    if (reason === "reset") {
      return;
    }
    setIsInputEdited(true);
    setZipCodeError("");
    if (validateZipcode(newInputValue)) {
      setPropertyProp(propertyIndex, (property) => (property.propertyInfo.zipCode = newInputValue.replace("-", "")));
    }
    if (newInputValue.trim() === "") {
      setPropertyProp(propertyIndex, (property) => (property.propertyInfo.zipCode = ""));
      setOptions([]);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setOptions([]);
    setPropertyProp(propertyIndex, (property) => (property.propertyInfo.zipCode = newInputValue));
    ///We only want to search the options if the user entered the option and not selected a the autocomplete of a partially typed option
    if (reason == "input") await debouncedOnZipTextChanged(newInputValue);
    setIsLoading(false);
  };

  const debouncedOnZipTextChanged = useCallback(debounce(onZipSearchTextChanged, 400), []);

  return (
    <>
      {/*  */}
      <Autocomplete
        disableClearable
        disabled={isDisabled}
        freeSolo
        value={selectedZip}
        inputValue={property.propertyInfo.zipCode ?? ""}
        onChange={async (e, value) => {
          if (typeof value === "object") {
            setSelectedZip(value);
            const updatedPropertyInfo = { ...property.propertyInfo, ...value };
            setProperty(updatedPropertyInfo, propertyIndex);
            searchFullAddressDetails(updatedPropertyInfo, propertyIndex);
          }
        }}
        onInputChange={handleInputChange}
        options={options}
        loadingText="Loading..."
        loading={isLoading}
        getOptionLabel={(option: any) => option?.zipCode || ""}
        renderOption={(props, option) => (
          <li {...props} key={`${option?.zipCode}${option?.county}${option?.city}${option?.state}`}>
            <div style={{ width: "100%" }}>
              <div>
                <Typography variant="subtitle2">{option.zipCode}</Typography>
                <Typography variant="subtitle2">{option.city}</Typography>
                <Typography variant="subtitle2" color="text.grey">
                  County of {option.county}, {option.state}
                </Typography>
              </div>
            </div>
          </li>
        )}
        onBlur={async (e) => {
          const target = e.target as HTMLInputElement;
          if (!validateZipcode(target.value)) {
            setZipCodeError("Invalid zip code");
            setIsInputEdited(false);
            return;
          }
          if (!isInputEdited) return;
          if (propertyInfo.isFreeFormEntry && propertyInfo.city && propertyInfo.state) {
            searchFullAddressDetails(propertyInfo, propertyIndex);
            setIsInputEdited(false);
            return;
          }
          setIsInputEdited(false);
        }}
        renderInput={(params) => (
          <TextField
            margin="none"
            {...params}
            InputProps={{
              ...params.InputProps,
              ...{ inputComponent: ZipMask }
            }}
            inputProps={{
              ...params.inputProps,
              name: "password",
              autoComplete: "off",
              type: "text"
            }}
            label="Zip Code"
          />
        )}
      />
      {zipCodeError && (
        <Typography variant="caption" color="error">
          {zipCodeError}
        </Typography>
      )}
    </>
  );
}

import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';

import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

import { columns } from './Columns';
import { Commission } from './types';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Iconify from '../../minimals/components/iconify/Iconify';

export default function CommissionTool() {
  const [loading, setLoading] = useState(false);
  const [commissionOrders, setCommissionOrders] = useState<Commission[]>([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const today = dayjs();
  const defaultEndDate = today;
  const defaultStartDate = today.subtract(1, 'months');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    defaultStartDate,
    defaultEndDate,
  ]);

  const getAllOrdersForCommissions = async () => {
    const [startDate, endDate] = dateRange;
    const { data } = await axios.get<Commission[]>(
      `/api/commissionTool/GetOrderForCommissionTool?from=${
        startDate || defaultStartDate
      }&to=${endDate || defaultEndDate}`
    );
    setCommissionOrders(data);
  };
//   const searchOrderNumber = async (e: any) => {
//     setOrderNumber(e);
//     const { data } = await axios.get(
//       `/api/commissionTool/GetOrderForCommissionToolByOrderNumber?orderNumber=${e}`
//     );
//     setCommissionOrders(data);
//   };

  const filteredData = commissionOrders.filter((row) => {
    return Object.values(row)
      .join(' ')
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    getAllOrdersForCommissions();
  }, [dateRange]);

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h4' marginBottom={2}>
          Commissions
        </Typography>
        <Stack direction='row' spacing={2}>
          <TextField
            label='Order Number'
            size='small'
            helperText="Note: This search field disregards the selected date range."
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' />
                </InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={dateRange}
              localeText={{
                start: 'Start-Date',
                end: 'End-Date',
              }}
              onAccept={(newValue) => {
                setDateRange(newValue);
              }}
              format='MM/DD/YY'
              calendars={3}
              slotProps={{ textField: { size: 'small', label: 'Date Range' } }}
              slots={{ field: SingleInputDateRangeField }}
              sx={{ width: 220 }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        initialState={{
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 30 },
        }}
        muiTableBodyProps={{
          sx: {
            '& .MuiTableRow-root:hover .MuiTableCell-root': {
              backgroundColor: 'inherit !important',
            },
          },
        }}
        state={{ showSkeletons: loading }}
      />
    </>
  );
}

// i18n
import "./minimals/locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// theme
import ThemeProvider from "./minimals/theme";
// locales
import ThemeLocalization from "./minimals/locales";
// components
import SnackbarProvider from "./minimals/components/snackbar";
import { ThemeSettings, SettingsProvider } from "./minimals/components/settings";

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import EnsureAuthenticated from "./EnsureAuthenticated";
import { UserProvider } from "./app/contexts/UserContext";
import { IFrameProvider } from "./app/contexts/IFrameContext";
import { ContactsProvider } from "./app/contexts/ContactsContext";
import { InternalUsersProvider } from "./app/contexts/InternalUserContext";
import { ApiInterceptorProvider } from "./app/contexts/ApiInterceptorContext";
import Router from "./app/routes";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LocalizationProvider as LocalizationProviderPro } from "@mui/x-date-pickers-pro";
import { AddEditContactProvider } from "./app/phonebook/contact/addEditContact/AddEditContactContext";
import { AddEditCompanyProvider } from "./app/phonebook/company/addEditCompany/AddEditCompanyContext";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

// ----------------------------------------------------------------------

// mui pro license
LicenseInfo.setLicenseKey(
  "4d8ce44c13a2afa98e59996a5c1b615fTz05NTkwMyxFPTE3NTQ4NDQxNTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

export default function App() {
  return (
    <EnsureAuthenticated>
      <LocalizationProviderPro dateAdapter={AdapterDayjs}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApiInterceptorProvider>
            <UserProvider>
              <ContactsProvider>
                <InternalUsersProvider>
                  <HelmetProvider>
                    <IFrameProvider>
                      <SettingsProvider>
                        <BrowserRouter>
                          <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <ThemeProvider>
                              <ThemeSettings>
                                <ThemeLocalization>
                                  <SnackbarProvider>
                                    <AddEditContactProvider>
                                      <AddEditCompanyProvider>
                                        <Router />
                                      </AddEditCompanyProvider>
                                    </AddEditContactProvider>
                                  </SnackbarProvider>
                                </ThemeLocalization>
                              </ThemeSettings>
                            </ThemeProvider>
                          </QueryParamProvider>
                        </BrowserRouter>
                      </SettingsProvider>
                    </IFrameProvider>
                  </HelmetProvider>
                </InternalUsersProvider>
              </ContactsProvider>
            </UserProvider>
          </ApiInterceptorProvider>
        </LocalizationProvider>
      </LocalizationProviderPro>
    </EnsureAuthenticated>
  );
}

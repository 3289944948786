import { Grid, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { fCurrency } from "../../utils/formatNumber";
import { OrderContext } from "../../contexts/OrderDataContext";
import { PriorPolicy, PriorPolicyDocument } from "./MatchedOrder";
import axios from "axios";
import { setDate } from "date-fns/esm";
import Iconify from "../../../minimals/components/iconify";

type Prop = {
  priorPolicy: PriorPolicy;
  orderNumber: string;
};

export default function MatchedPriorPolicy({ priorPolicy, orderNumber }: Prop) {
  const {
    setPriorPolicyAmount,
    setPriorPolicyDate,
    setSelectedPriorPolicyUnderwriter,
    priorPolicyAmount,
    priorPolicyDate,
    selectedPriorPolicyUnderwriter,
    priorPolicyFiles,
    setPriorPolicyFiles
  } = useContext(OrderContext);

  const setMatchedPriorPolicy = () => {
    setMatchedPriorPolicyDocument();
    setPriorPolicyAmount(priorPolicy.amount);
    setPriorPolicyDate(priorPolicy.date || null);
    setSelectedPriorPolicyUnderwriter({
      suffix: priorPolicy.underwriterLookupCode,
      name: priorPolicy.underwriterLookupCode
    });
    const relatedPriorPolicyElement = document.getElementById("priorPolicy");
    relatedPriorPolicyElement?.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    setIsHovered(false);
  };

  const setMatchedPriorPolicyDocument = async () => {
    const { data } = await axios.get(`/api/orderdocuments/GetPriorPolicy?ordernumber=${orderNumber}`);
    const url = `data:application/pdf;base64,${data.documentBase64}`;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName, {
          type: "application/pdf"
        });
        setPriorPolicyFiles([...priorPolicyFiles, file]);
      });
  };
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const isImported =
    priorPolicyAmount === priorPolicy.amount &&
    priorPolicyAmount === priorPolicy.amount &&
    priorPolicyDate === priorPolicy.date &&
    selectedPriorPolicyUnderwriter?.name === priorPolicy.underwriterLookupCode;
  return (
    <Grid container marginTop={3}>
      <Grid item xs={2}>
        {!isImported && (
          <IconButton
            color={isHovered ? "primary" : "primary"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={setMatchedPriorPolicy}
          >
            {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
            {!isHovered && <Iconify icon={"eva:shield-outline"} />}
          </IconButton>
        )}
        {isImported && (
          <IconButton color="success">
            <Iconify icon={"eva:checkmark-fill"} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Prior Loan Policy of {priorPolicy.amount && fCurrency(priorPolicy.amount.toString())}
            </Typography>
            <Typography>Issued on {priorPolicy.date && new Date(priorPolicy.date).toLocaleDateString()}</Typography>
            <Typography>with {priorPolicy.underwriterLookupCode}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

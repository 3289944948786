import { ConfirmationContextProvider } from "../contexts/ConfirmationContext";
import Confirmation from "./Confirmation";

// ----------------------------------------------------------------------

export default function PageConfirmation() {
  return (
    <ConfirmationContextProvider>
      <Confirmation />
    </ConfirmationContextProvider>
  );
}

// @mui
import { useState } from "react";
import { Grid, Stack } from "@mui/material";
import ProfileAbout from "./About";
import NoteCard from "../../shared/profile/NoteCard";
import NoteInput from "../../shared/profile/NoteInput";
import ProfileAccountReps from "../../shared/profile/AccountReps";
import { useContext } from "react";
import { CompanyContext } from "../CompanyContext";
import { Company, Note } from "../../types";
import axios from "axios";
import DeactivateDialog from "../../shared/preferences/DeactivateDialog";
import { ContactContext } from "../../contact/ContactContext";
// ----------------------------------------------------------------------

export default function Profile() {
  const { company, setCompany } = useContext(CompanyContext);
  const { contact, setContact } = useContext(ContactContext);
  const handleActiveStatusUpdate = async (contactId: number, isActive: boolean) => {
    const { data } = await axios.post<Company>(`/api/clientphonebook/companies/updateActiveStatus`, {
      id: contactId,
      isActive
    });
    if (data) {
      setCompany((draft) => {
        if (draft) {
          draft.isActive = data.isActive;
        }
      });
    }
  };

  const updateNotes = (note: Note) => {
    setCompany((draft) => {
      if (draft) {
        draft.notes.unshift(note);
      }
    });
  };

  const saveNote = async (note: string) => {
    const { data } = await axios.post("/api/clientphonebook/companyNotes/add", {
      text: note,
      companyId: company?.id
    });
    updateNotes(data);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <ProfileAbout company={company} />
          {/* <ProfileAccountReps /> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={3} alignItems="flex-end">
          <DeactivateDialog entityType="Company" entity={company} updateActiveStatus={handleActiveStatusUpdate} />
        </Stack>

        <Stack spacing={3}>
          <NoteInput showClientInstructionsHeader={false} saveNote={saveNote} updateNotes={updateNotes} />
          {company?.notes?.map((note) => (
            <NoteCard
              showClientInstructionsHeader={false}
              key={note.id}
              note={note}
              companyId={company.id}
              contactId={contact?.id}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}

import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { ContactContext } from "../../contact/ContactContext";
import { useContext, useState } from "react";
import AccountRepAutocomplete from "./AccountRepAutocomplete";
import { refreshContact } from "./helpers/refreshContact";
import axios from "axios";
import { InternalUser } from "../../../types/app";

interface IProps {
  setEditMode: () => void;
}

export default function EditAccountReps({ setEditMode }: IProps) {
  const { contact, setContact } = useContext(ContactContext);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const originalNyRep = contact?.contactReps.find((r) => r.contactRepTypeId === 2);
  const originalNjRep = contact?.contactReps.find((r) => r.contactRepTypeId === 3);
  const originalNationalRep = contact?.contactReps.find((r) => r.contactRepTypeId === 4);

  const [editedNyRep, setEditedNyRep] = useState<InternalUser | undefined | null>(originalNyRep?.internalUser);
  const [editedNjRep, setEditedNjRep] = useState<InternalUser | undefined | null>(originalNjRep?.internalUser);
  const [editedNationalRep, setEditedNationalRep] = useState<InternalUser | undefined | null>(
    originalNationalRep?.internalUser
  );

  const submit = async () => {
    setSubmitting(true);

    const payload = [
      {
        contactId: contact?.id,
        internalUserId: editedNyRep?.id,
        contactRepTypeId: 2
      },
      {
        contactId: contact?.id,
        internalUserId: editedNjRep?.id,
        contactRepTypeId: 3
      },
      {
        contactId: contact?.id,
        internalUserId: editedNationalRep?.id,
        contactRepTypeId: 4
      }
    ].filter((r) => r.internalUserId);

    await axios.post(`/api/clientphonebook/contactreps/update/${contact?.id}`, payload);
    await refreshContact({ contact, setContact });
    setEditMode();
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Account Reps</Typography>}></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          <AccountRepAutocomplete editedRep={editedNyRep} setEditedRep={setEditedNyRep} label="NY Account Rep" />
          <AccountRepAutocomplete
            editedRep={editedNationalRep}
            setEditedRep={setEditedNationalRep}
            label="National Account Rep"
          />
          <AccountRepAutocomplete editedRep={editedNjRep} setEditedRep={setEditedNjRep} label="NJ Account Rep" />
          <Stack direction="row" justifyContent="right" spacing={1.5}>
            <Button variant="outlined" onClick={setEditMode}>
              Cancel
            </Button>
            <Button variant="contained" onClick={submit} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

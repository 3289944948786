import { useContext, useEffect } from "react";
import { produce } from "immer";
import { WritableDraft } from "immer/dist/internal";
// @mui
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Stack,
  Autocomplete
} from "@mui/material";
// components
import { OrderContext } from "../contexts/OrderDataContext";
import { Loan } from "./types/Loan";
import { Contact } from "./types/Contact";
import { NumberFormatCustom } from "../components/NumberFormat";
import PrefilledIndicator from "../components/PrefilledIndicator";
import Iconify from "../../minimals/components/iconify";
import axios from "axios";
import { CompanyBranch } from "../phonebook/types";
import PhonebookContactAutocomplete from "../phonebook/phonebookAutocomplete/PhonebookContactAutocomplete";

// ----------------------------------------------------------------------

const TransactionRootStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  marginTop: 24,
  marginBottom: 24,
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 2.5,
  backgroundColor: theme.palette.grey[200]
}));

const getEmptyLoan = (): Loan => {
  return {
    loanAmount: null,
    lender: { firstName: "TBD", isIndividual: true } as Contact,
    lendersAttorney: null,
    mortgageBroker: null,
    lendersAttorneyList: [],
    lendersList: [],
    loanNumber: "",
    isConstructionLoan: false,
    lendersBranch: null,
    lenderBranchesList: []
  };
};

export default function Transaction() {
  const {
    loans,
    setLoans,
    purchasePrice,
    setPurchasePrice,
    transactionType,
    settlementStatementType,
    setSettlementStatementType,
    propertyUse,
    autoFilled,
    setAutoFilled,
    getFullNameForBlacklistAndPatriotSearching,
    searchForPatriotsAndBlackList
  } = useContext(OrderContext);

  const setLoanProp = (index: number, cb: (loan: WritableDraft<Loan>) => void) => {
    setLoans((draft) => {
      const loan = draft[index];
      cb(loan);
    });
  };

  const getAndSetLenderBranches = async (
    index: number,
    isIndividual: boolean,
    companyId?: string | undefined,
    branchId?: number | null | undefined
  ) => {
    if ((isIndividual && !branchId) || (!isIndividual && !companyId)) {
      setLoanProp(index, (loan) => {
        loan.lenderBranchesList = [];
      });
      return;
    }

    let endpoint = isIndividual
      ? `/api/clientphonebook/companyBranch/${branchId}`
      : `/api/clientphonebook/companies/getBranches/${companyId}`;

    const { data } = await axios.get<CompanyBranch[] | CompanyBranch>(endpoint);

    let companyBranches: CompanyBranch[];

    if (Array.isArray(data)) {
      companyBranches = data.filter((d) => d.address1 || d.address2);
    } else {
      companyBranches = data.address1 || data.address2 ? [data] : []; // Wrap individual data in an array
    }

    const defaultBranch = companyBranches.find((d) => d.isHeadquarters) || companyBranches[0];

    setLoanProp(index, (loan) => {
      loan.lenderBranchesList = companyBranches;
      loan.lendersBranch = defaultBranch;
    });
  };

  const setLender = (lender: Contact | null, index: number) => {
    setLoanProp(index, (loan) => {
      loan.lender = lender;
      loan.lendersBranch = null;
    });

    getAndSetLenderBranches(index, lender?.isIndividual === true, lender?.id || undefined, lender?.branchId);
  };

  const setLenderBranch = (companyBranch: CompanyBranch | null, index: number) => {
    setLoanProp(index, (loan) => {
      loan.lendersBranch = companyBranch;
    });
  };

  const setLenderAttorney = (lendersAttorney: Contact | null, index: number) => {
    setLoanProp(index, (loan) => (loan.lendersAttorney = lendersAttorney));
  };

  const setMortgageBroker = (mortgageBroker: Contact | null, index: number) => {
    setLoanProp(index, (loan) => (loan.mortgageBroker = mortgageBroker));
  };

  const setLoanNumber = (loanNumber: string, index: number) =>
    setLoanProp(index, (loan) => (loan.loanNumber = loanNumber));

  const setLoanAmount = (loanAmount: number | null, index: number) =>
    setLoanProp(index, (loan) => (loan.loanAmount = loanAmount));

  const setIsConstructionLoan = (isConstructionLoan: boolean, index: number) =>
    setLoanProp(index, (loan) => (loan.isConstructionLoan = isConstructionLoan));

  const removeLoan = (index: number) => {
    var loansCopy = [...loans];
    loansCopy.splice(index, 1);
    setLoans(loansCopy);
  };

  useEffect(() => {
    loans.length > 0 &&
      (transactionType === "Purchase" || transactionType === "Refinance") &&
      setSettlementStatementType("HUD");
  }, [transactionType]);

  const handleLenderChange = async (value: any, i: number) => {
    setLender(value, i);
    setAutoFilled(
      produce(autoFilled, (draft) => {
        draft.lender.autoFilled = false;
      })
    );
    if (!value) return;
    const { firstName = "", middleName = "", lastName = "", company } = value;
    const fullName = getFullNameForBlacklistAndPatriotSearching({ firstName, middleName, lastName });
    searchForPatriotsAndBlackList(
      [
        { name: fullName, isCompany: false },
        { name: company, isCompany: true }
      ],
      fullName
    );
  };

  const handleLenderAttorneyChange = async (value: any, i: number) => {
    if (typeof value !== "object") return;
    setLenderAttorney(value || null, i);
    if (!value) return;
    const { firstName = "", middleName = "", lastName = "", company } = value;
    const fullName = getFullNameForBlacklistAndPatriotSearching({ firstName, middleName, lastName });
    searchForPatriotsAndBlackList(
      [
        { name: fullName, isCompany: false },
        { name: company, isCompany: true }
      ],
      fullName
    );
  };

  const handleMortgageBrokerChange = async (value: any, i: number) => {
    if (typeof value !== "object") return;
    setMortgageBroker(value || null, i);
    if (!value) return;
    const { firstName = "", middleName = "", lastName = "", company } = value;
    const fullName = getFullNameForBlacklistAndPatriotSearching({ firstName, middleName, lastName });
    searchForPatriotsAndBlackList(
      [
        { name: fullName, isCompany: false },
        { name: company, isCompany: true }
      ],
      fullName
    );
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" marginBottom={3}>
        <Grid item xs={6}>
          <TextField
            label="Purchase Amount"
            value={purchasePrice || ""}
            onChange={(e) => setPurchasePrice(Number(e.target.value) || null)}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
            fullWidth
            sx={{ opacity: transactionType != "Purchase" ? 0.3 : 1 }}
            disabled={transactionType != "Purchase"}
          />
        </Grid>
        {propertyUse === "Residential" && (transactionType === "Purchase" || transactionType === "Refinance") && (
          <Grid item xs={6}>
            <Stack alignItems="flex-end">
              <>
                <ToggleButtonGroup size="small" value={settlementStatementType} color="primary">
                  <ToggleButton value="CDF" onClick={() => setSettlementStatementType("CDF")}>
                    CDF
                  </ToggleButton>
                  <ToggleButton value="HUD" onClick={() => setSettlementStatementType("HUD")}>
                    HUD
                  </ToggleButton>
                </ToggleButtonGroup>
              </>
            </Stack>
          </Grid>
        )}
      </Grid>
      {transactionType != "LastOwnerSearch" &&
        loans.map((loan, i) => (
          <TransactionRootStyle key={i}>
            <Typography variant="subtitle1" marginBottom={2}>
              Loan Details
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={loan.isConstructionLoan}
                      onChange={() => setIsConstructionLoan(!loan.isConstructionLoan, i)}
                    />
                  }
                  label="Construction Loan"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Amount"
                  value={loan.loanAmount || ""}
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom as any
                  }}
                  onChange={(e) => setLoanAmount(Number(e.target.value) || null, i)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Loan #"
                  fullWidth
                  value={loan.loanNumber || ""}
                  onChange={(e) => setLoanNumber(e.target.value, i)}
                />
              </Grid>
              <Grid item md={6} xs={12} position="relative">
                <PhonebookContactAutocomplete
                  contact={loan.lender}
                  setContact={(value: any) => handleLenderChange(value, i)}
                  apiOptions={{ companyType: "Lender", roleType: "Banker" }}
                  textboxLabel={"Lender"}
                  textboxPlaceholder={"Start typing..."}
                  isCompany={true}
                />
                {autoFilled.lender.autoFilled && <PrefilledIndicator title={autoFilled.lender.reason} />}
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  value={loan.lendersBranch}
                  disabled={loan.lenderBranchesList.length === 0}
                  renderInput={(params) => <TextField {...params} label="Lender Branch" />}
                  getOptionLabel={(option) =>
                    `${option.address1} ${option.isHeadquarters ? " (Headquarters)" : ""}` || ""
                  }
                  options={loan.lenderBranchesList}
                  onChange={(_, value) => setLenderBranch(value, i)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhonebookContactAutocomplete
                  contact={loan.lendersAttorney}
                  setContact={(value: any) => handleLenderAttorneyChange(value, i)}
                  textboxLabel={"Lender's Attorney"}
                  textboxPlaceholder={"Start typing..."}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhonebookContactAutocomplete
                  contact={loan.mortgageBroker}
                  setContact={(value: any) => handleMortgageBrokerChange(value, i)}
                  textboxLabel={"Mortgage Broker"}
                  textboxPlaceholder={"Start typing..."}
                />
              </Grid>
              {(transactionType != "Refinance" || i > 0 || loans.length > 1) && (
                <Grid item xs={12}>
                  <Button
                    color="error"
                    size="small"
                    startIcon={<Iconify icon={"eva:trash-2-outline"} />}
                    onClick={() => removeLoan(i)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </TransactionRootStyle>
        ))}
      {transactionType != "LastOwnerSearch" && (
        <Grid item xs={12}>
          <Button
            size="small"
            startIcon={<Iconify icon={"eva:plus-fill"} />}
            onClick={() => setLoans([...loans, getEmptyLoan()])}
          >
            Add a loan
          </Button>
        </Grid>
      )}
    </>
  );
}

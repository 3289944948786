import { useContext, useState, createContext, ReactNode } from "react";
import { IToastProps } from "../@types/app";

interface ClosingsContextType {
  toastProps: IToastProps;
  showToastMessage: (data: IToastProps) => void;
}

const ClosingsContext = createContext<ClosingsContextType>({} as ClosingsContextType);
function ClosingsProvider({ children }: { children: ReactNode }) {
  const initialToastState: IToastProps = {
    open: false,
    onClose: () => setToastProps(initialToastState), //() => setToastProps((props: IToastProps) => ({ ...props, open: false })),
    message: "",
    autoHideDuration: 5000,
    severity: "warning",
    anchorOrigin: { vertical: "top", horizontal: "center" }
  };
  const [toastProps, setToastProps] = useState<IToastProps>(initialToastState);

  const showToastMessage = ({ message = "", severity, autoHideDuration = 5000 }: IToastProps) => {
    setToastProps((toastProps) => ({
      ...toastProps,
      open: true,
      message,
      severity,
      autoHideDuration
    }));
  };

  return (
    <ClosingsContext.Provider
      value={{
        toastProps,
        showToastMessage
      }}
    >
      {children}
    </ClosingsContext.Provider>
  );
}

const useClosings = () => useContext(ClosingsContext);

export { ClosingsProvider, useClosings };

import { Card, CardContent, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import HeaderTitle from "./HeaderTitle";
import { useContext } from "react";
import { ContactContext } from "../../contact/ContactContext";

interface IProps {
  setEditMode: () => void;
}

export default function ClientCultivators({ setEditMode }: IProps) {
  const theme = useTheme();
  const { contact } = useContext(ContactContext);
  const clientCultivator = contact?.clientCultivator;
  return (
    <Card>
      <CardHeader
        title={<HeaderTitle title="Client Cultivators" handleEditClick={setEditMode} isDisabled={!contact?.isActive} />}
      />
      <CardContent>
        <Stack spacing={1.5}>
          {clientCultivator && <Typography variant="body2">{clientCultivator.fullName}</Typography>}
          {!clientCultivator && (
            <Typography
              variant="body2"
              display="inline-block"
              color={theme.palette.text.disabled}
              sx={{ fontStyle: "italic" }}
            >
              no rep selected
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

import { useState, useEffect } from "react";
import axios from "axios";
import { CompanyType } from "../types";

const useCompanyTypeList = () => {
  const [companyTypeList, setCompanyTypeList] = useState<CompanyType[]>([]);

  useEffect(() => {
    const getCompanyTypeList = async () => {
      try {
        const { data } = await axios.get("/api/clientphonebook/companies/getAllTypes");
        setCompanyTypeList(data);
      } catch (error) {
        console.error("Error fetching company types:", error);
      }
    };
    getCompanyTypeList();
  }, []);

  return companyTypeList;
};

export default useCompanyTypeList;

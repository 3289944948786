import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import Iconify from "../../../minimals/components/iconify";
import PrefilledIndicator from "../../components/PrefilledIndicator";
import { OrderContext } from "../../contexts/OrderDataContext";
import {
  getBlock,
  getDefaultPropertyInfoObj,
  getLot,
  getParsedName,
  getSection,
  isNJorNY,
  searchCountyByCityAndZip,
  searchEstated,
  toTitleCase
} from "../../utils/property";
import { MatchedOrderRequest } from "../matchedOrders/MatchedOrder";
import { CityData, Property, PropertyInfo } from "../types/Property";
import Address from "./Components/Address";
import City from "./Components/City";
import Lot from "./Components/Lot";
import ParcelId from "./Components/ParcelId";
import PropertyUseComponent from "./Components/PropertyUseComponent";
import Zip from "./Components/Zip";
import { Party } from "../types/Party";
import { ParsedName } from "./types";
import { usStateList } from "../../utils/usStateList";

export default function Properties() {
  const {
    properties,
    setProperties,
    autoFilled,
    setAutoFilled,
    getAndSetMatchedOrders,
    errorMode,
    transactionType,
    setPropertyUse,
    setSellers,
    sellers,
    buyers,
    setBuyers
  } = useContext(OrderContext);

  const [propertyTypeList, setPropertyTypeList] = useState<string[]>([]);
  const [showClearIcon, setShowClearIcon] = useState(-1);
  const [isCurrentInputEdited, setIsCurrentInputEdited] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const unitInputRef = useRef<HTMLInputElement | null>(null);
  const focusUnitInput = () => unitInputRef?.current?.focus();

  const removeProperty = (index: number) => {
    var propertiesCopy = [...properties];
    propertiesCopy.splice(index, 1);
    setProperties(propertiesCopy);

    var autofilledPropertiesCopy = [...autoFilled.properties];
    autofilledPropertiesCopy.splice(index, 1);
    setAutoFilled({ ...autoFilled, properties: autofilledPropertiesCopy });
  };

  const setPropertyProp = (index: number, cb: (property: WritableDraft<Property>) => void) => {
    const newProperties = produce(properties, (draft) => {
      const property = draft[index];
      cb(property);
    });

    setProperties(newProperties);
  };

  const setProperty = (propertyInfo: PropertyInfo, index: number, isCommercial?: boolean) => {
    const isCommercialNJ = propertyInfo.state?.toLocaleUpperCase() === "NJ" && isCommercial;
    let parcelIds = isCommercialNJ ? [""] : propertyInfo.parcelIds;
    let block = isCommercialNJ ? "" : propertyInfo.block;
    let lots = isCommercialNJ ? [""] : propertyInfo.lots;
    let section = isCommercialNJ ? "" : propertyInfo.section;

    var data = {
      city: propertyInfo.city || "",
      state: propertyInfo.state || "",
      county: propertyInfo.county || "",
      zipCode: propertyInfo.zipCode || ""
    };
    setPropertyProp(
      index,
      (property) => (
        (property.cityData = data),
        (property.zipData = data),
        (property.propertyInfo = {
          ...propertyInfo,
          parcelIds,
          block,
          lots,
          section
        })
      )
    );
  };

  const getPropertyType = async (land: string) => {
    const { data } = await axios.get(`/api/property/getPropertyType?land=${land}`);
    return data;
  };

  const getAndSetPropertyTypeList = async () => {
    const { data } = await axios.get("/proxy/api/orders/getPropertyTypeValues");
    setPropertyTypeList(data);
  };

  const getMatchedOrderForProperyInfo = (propertyInfo: PropertyInfo) => {
    getAndSetMatchedOrders({
      address: propertyInfo.formattedAddress,
      state: propertyInfo.state,
      block: propertyInfo.block,
      lot: propertyInfo.lots[0],
      parcelId: propertyInfo.parcelIds[0],
      section: propertyInfo.section
    } as MatchedOrderRequest);
  };

  const setNameProps = async (
    isLLC: boolean,
    isTrust: boolean,
    isJoint: boolean,
    parties: Party[],
    setParties: (value: React.SetStateAction<Party[]>) => void,
    buyerSeller: "buyer" | "seller",
    parsedName1: ParsedName | null,
    parsedName2?: ParsedName | null,
    owner?: string
  ) => {
    if (!isLLC && !isTrust) {
      var updatedParties = parties.map((b, i) =>
        i === 0
          ? ({
              ...b,
              firstName1: parsedName1?.firstName || "Certify as Found",
              lastName1: parsedName1?.lastName || "Certify as Found",
              type: "Individual",
              gender1: parsedName1?.gender,
              isJoint: isJoint,
              firstName2: isJoint ? parsedName2?.firstName || "Certify as Found" : "",
              lastName2: isJoint ? parsedName2?.lastName || "Certify as Found" : ""
            } as Party)
          : b
      );
      setParties(updatedParties);
      if (parsedName1) {
        setAutoFilled((autoFilled) => ({
          ...autoFilled,
          [buyerSeller]: {
            ...autoFilled.buyer,
            firstName1: {
              autoFilled: true,
              reason: "Autofilled from public record"
            },
            lastName1: {
              autoFilled: true,
              reason: "Autofilled from public record"
            },
            firstName2: isJoint
              ? { autoFilled: true, reason: "Autofilled from public record" }
              : { autoFilled: false, reason: "" },
            lastName2: isJoint
              ? { autoFilled: true, reason: "Autofilled from public record" }
              : { autoFilled: false, reason: "" }
          }
        }));
      }
    } else {
      var updatedParties = parties.map((b, i) =>
        i === 0
          ? ({
              ...b,
              organizationName: owner || "Certify as Found",
              organizationType: isLLC ? "Limited Liability Company" : "Trust",
              vesting: isTrust ? "Trust" : null,
              type: "Organization",
              isJoint: false
            } as Party)
          : b
      );
      setParties(updatedParties);
      setAutoFilled((autoFilled) => ({
        ...autoFilled,
        seller: {
          ...autoFilled.seller,
          organizationName: {
            autoFilled: true,
            reason: "Autofilled from public record"
          },
          organizationType: {
            autoFilled: true,
            reason: isLLC ? "Autofilled because LLC found in name" : "Autofilled because Trust found in name"
          }
        }
      }));
    }
  };

  const searchFullAddressDetails = async (propertyInfo: PropertyInfo, index: number) => {
    //I don't see why this should be needed but it was here so I will leave it
    if (propertyInfo == null || typeof propertyInfo !== "object") return;

    const estatedResponseData = await searchEstated(propertyInfo);

    var propertyUseAndType = estatedResponseData?.parcel?.standardized_land_use_type
      ? await getPropertyType(estatedResponseData?.parcel?.standardized_land_use_type)
      : null;

    setPropertyUse(
      propertyUseAndType?.propertyUse === "Residential" || propertyUseAndType?.propertyUse === "Commercial"
        ? propertyUseAndType.propertyUse
        : null
    );
    const isCommercial = propertyUseAndType?.propertyUse === "Commercial";

    if (!estatedResponseData) {
      if (propertyInfo.city && propertyInfo.zipCode) {
        var county = await searchCountyByCityAndZip(propertyInfo.city, propertyInfo.zipCode);
        if (county) {
          setProperty({ ...propertyInfo, county }, index, isCommercial);
        }
      }

      if (propertyInfo.formattedAddress && propertyInfo.state) {
        getAndSetMatchedOrders({
          address: propertyInfo.formattedAddress,
          parcelId: propertyInfo.parcelIds[0],
          state: propertyInfo.state,
          city: propertyInfo.city,
          block: propertyInfo.block,
          lot: propertyInfo.lots[0],
          section: propertyInfo.section
        });
      }

      return;
    }

    const { address, parcel, owner } = estatedResponseData;
    const { state, formatted_street_address } = address;
    const { county_name, apn_original } = parcel;
    const stateLowerCase = state?.toLowerCase();
    const countyLowerCase = county_name?.toLowerCase();

    const city = toTitleCase(address.city.toLowerCase());
    const block = getBlock(stateLowerCase, countyLowerCase, apn_original);
    const lot = getLot(stateLowerCase, countyLowerCase, apn_original);
    const section = getSection(stateLowerCase, countyLowerCase, apn_original);
    ///Prefer to use the formatted address which came from Smarty because its formatted without abbreviations i.e. Road instead of Rd. and getAndSetMatchOrders doesn't work with abbreviations
    // if (propertyInfo.formattedAddress && state && ((block && lot) || apn_original)) {
    if (propertyInfo.formattedAddress && state) {
      getAndSetMatchedOrders({
        address: propertyInfo.formattedAddress || formatted_street_address,
        parcelId: apn_original,
        state,
        city,
        block,
        lot,
        section
      });
    }

    if (index === 0) {
      const ownerName = toTitleCase(owner.name) || "";
      const ownerNameLowerCase = ownerName.toLowerCase();
      const isLLC = ownerNameLowerCase.includes("llc");
      const isTrust = ownerNameLowerCase.includes("trust");

      ////This splits the name by the word and (case insenstive), the charachter ; or the charachter &
      const splitNames = ownerName.split(/\s*(?:and|&|;)\s*/i);
      const joint1 = await getParsedName(splitNames[0] || estatedResponseData.owner.name);
      const joint2 = await getParsedName(splitNames[1]);
      if (transactionType === "Refinance" || transactionType === "LastOwnerSearch") {
        setNameProps(isLLC, isTrust, splitNames.length > 1, buyers, setBuyers, "buyer", joint1, joint2, ownerName);
        return;
      }
      setNameProps(isLLC, isTrust, splitNames.length > 1, sellers, setSellers, "seller", joint1, joint2, ownerName);
    }

    var propertyUseAndType = await getPropertyType(estatedResponseData.parcel.standardized_land_use_type);

    setPropertyUse(
      propertyUseAndType.propertyUse === "Residential" || propertyUseAndType.propertyUse === "Commercial"
        ? propertyUseAndType.propertyUse
        : null
    );

    setProperty(
      {
        ...propertyInfo,
        city,
        county: estatedResponseData.parcel.county_name
          .split(" ")
          .map((part: string) => toTitleCase(part.toLowerCase()))
          .join(" "),
        block,
        section: getSection(
          estatedResponseData.address.state?.toLowerCase(),
          estatedResponseData.parcel.county_name?.toLowerCase(),
          estatedResponseData.parcel.apn_original
        ),
        propertyType: propertyUseAndType?.propertyType,
        parcelIds: propertyInfo.parcelIds.map((value, i) =>
          i === 0 ? estatedResponseData!.parcel.apn_original : value
        ),
        lots: propertyInfo.lots.map((value, i) => lot || value)
      },
      index,
      isCommercial
    );
    setAutoFilled((autoFilled) => ({
      ...autoFilled,
      properties: propertyUseAndType?.propertyType
        ? autoFilled.properties.map((p, i) =>
            i === index
              ? {
                  propertyType: {
                    autoFilled: true,
                    reason: "Autofilled from public record"
                  }
                }
              : p
          )
        : autoFilled.properties,
      propertyUse: propertyUseAndType?.propertyUse
        ? { autoFilled: true, reason: "Autofilled from public record" }
        : autoFilled.propertyUse
    }));
  };

  useEffect(() => {
    getAndSetPropertyTypeList();
  }, []);

  return (
    <>
      {properties.map((property, i) => {
        const { propertyInfo } = property;
        return (
          <Paper
            id={`property${i}`}
            sx={{
              alignItems: "center",
              marginTop: 3,
              marginBottom: 3,
              padding: properties.length > 1 ? 3 : 0,
              borderRadius: 2,
              backgroundColor: properties.length > 1 ? "background.neutral" : "transparent"
            }}
            key={i}
          >
            <Grid container spacing={3} alignItems="top">
              {properties.length > 1 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Property #{i + 1}</Typography>
                </Grid>
              )}
              <Grid item md={8} xs={12}>
                <Address
                  i={i}
                  property={property}
                  setPropertyProp={setPropertyProp}
                  focusUnitInput={focusUnitInput}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  searchFullAddressDetails={searchFullAddressDetails}
                  setProperty={setProperty}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  label="Unit/Suite/Apt #"
                  value={propertyInfo?.aptNo || ""}
                  onChange={(e) => setProperty({ ...propertyInfo, aptNo: e.target.value }, i)}
                  fullWidth
                  inputRef={unitInputRef}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <City
                  property={property}
                  propertyIndex={i}
                  setProperty={setProperty}
                  setPropertyProp={setPropertyProp}
                  isDisabled={isDisabled}
                  searchFullAddressDetails={searchFullAddressDetails}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  disabled={isDisabled}
                  options={usStateList}
                  value={propertyInfo?.state || ""}
                  onChange={(_, value) => {
                    setIsCurrentInputEdited(true);
                    setProperty({ ...propertyInfo, state: value }, i);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errorMode && !propertyInfo.state}
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password"
                      }}
                      fullWidth
                      label="State"
                    />
                  )}
                  onBlur={async () => {
                    if (!isCurrentInputEdited) return;
                    if (propertyInfo.isFreeFormEntry && propertyInfo.city) {
                      searchFullAddressDetails(propertyInfo, i);
                      setIsCurrentInputEdited(false);
                      return;
                    }
                    //parcelIds defaults to empty string so we are filtering out empty sting values
                    if (
                      propertyInfo.formattedAddress ||
                      propertyInfo.parcelIds.some((p) => p) ||
                      (propertyInfo.lots.some((l) => l) && propertyInfo.block)
                    ) {
                      getMatchedOrderForProperyInfo(propertyInfo);
                    }
                    setIsCurrentInputEdited(false);
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Zip
                  property={property}
                  propertyIndex={i}
                  setProperty={setProperty}
                  setPropertyProp={setPropertyProp}
                  isDisabled={isDisabled}
                  searchFullAddressDetails={searchFullAddressDetails}
                />
              </Grid>
              <Grid item md={6} xs={12} alignItems="top">
                <TextField
                  disabled={isDisabled}
                  required
                  error={errorMode && !propertyInfo.county}
                  label="County"
                  value={propertyInfo?.county || ""}
                  onChange={(e) => setProperty({ ...propertyInfo, county: e.target.value }, i)}
                  fullWidth
                />
              </Grid>{" "}
              <Grid item md={5} xs={12}>
                {propertyInfo.parcelIds.map((id, parcelIdsIndex) => (
                  <ParcelId
                    id={id}
                    parcelIdsIndex={parcelIdsIndex}
                    property={property}
                    propertyIndex={i}
                    setProperty={setProperty}
                    isDisabled={isDisabled}
                  />
                ))}
              </Grid>
              {isNJorNY(propertyInfo.state?.toLowerCase()) && (
                <>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={isDisabled}
                      label="Section"
                      value={propertyInfo?.section || ""}
                      onChange={(e) => {
                        setIsCurrentInputEdited(true);
                        setProperty({ ...propertyInfo, section: e.target.value }, i);
                      }}
                      fullWidth
                      onBlur={() => {
                        if (!isCurrentInputEdited) return;
                        if (propertyInfo.section && propertyInfo.block && propertyInfo.lots.some((l) => l))
                          getMatchedOrderForProperyInfo(propertyInfo);
                        setIsCurrentInputEdited(false);
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled={isDisabled}
                      label="Block"
                      value={propertyInfo?.block || ""}
                      onChange={(e) => {
                        setIsCurrentInputEdited(true);
                        setProperty({ ...propertyInfo, block: e.target.value }, i);
                      }}
                      fullWidth
                      onBlur={() => {
                        if (!isCurrentInputEdited) return;
                        if (propertyInfo.block && propertyInfo.lots.some((l) => l)) {
                          getMatchedOrderForProperyInfo(propertyInfo);
                        }
                        setIsCurrentInputEdited(false);
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {propertyInfo.lots.map((lot, lotsIndex) => (
                      <Lot
                        lot={lot}
                        lotsIndex={lotsIndex}
                        property={property}
                        propertyIndex={i}
                        setProperty={setProperty}
                        isDisabled={isDisabled}
                      />
                    ))}
                  </Grid>
                </>
              )}
              {properties.length === 1 && (
                <Grid item md={4} xs={12} position="relative">
                  <PropertyUseComponent />
                </Grid>
              )}
              <Grid item md={8} xs={12} position="relative">
                <Autocomplete
                  value={propertyInfo?.propertyType || ""}
                  options={propertyTypeList}
                  getOptionLabel={(option) => option || ""}
                  onChange={(e, value) => {
                    setProperty({ ...propertyInfo, propertyType: value }, i);
                    setAutoFilled((autoFilled) => ({
                      ...autoFilled,
                      properties: autoFilled.properties.map((p, index) =>
                        index === i ? { propertyType: { autoFilled: false, reason: "" } } : p
                      )
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Property Type" placeholder="Select a Property Type" />
                  )}
                />
                {autoFilled.properties[i]?.propertyType?.autoFilled && (
                  <PrefilledIndicator title={autoFilled.properties[i].propertyType.reason} />
                )}
              </Grid>
              {propertyInfo?.legalDescription?.description && (
                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}
                    onMouseOver={() => setShowClearIcon(i)}
                    onMouseLeave={() => setShowClearIcon(-1)}
                  >
                    <Typography variant="overline" sx={{ color: "primary.main" }}>
                      Legal Description Imported
                    </Typography>
                    {showClearIcon === i && (
                      <Iconify
                        onClick={() => {
                          setProperty(
                            {
                              ...propertyInfo,
                              legalDescription: {
                                description: null,
                                lookupCode: null
                              }
                            },
                            i
                          );
                        }}
                        style={{ cursor: "pointer" }}
                        color="error.main"
                        icon={"eva:close-circle-outline"}
                      />
                    )}
                  </Box>
                </Grid>
              )}
              {properties.length > 1 && (
                <Grid item xs={12}>
                  <Button
                    color="error"
                    size="small"
                    startIcon={<Iconify icon={"eva:trash-2-outline"} />}
                    onClick={() => removeProperty(i)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        );
      })}
      {properties.length > 1 && (
        <Grid item md={6} xs={12} marginBottom={2} position="relative">
          <PropertyUseComponent />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          size="small"
          startIcon={<Iconify icon={"eva:plus-fill"} />}
          onClick={() => {
            setProperties([
              ...properties,
              {
                propertyInfo: getDefaultPropertyInfoObj(),
                cityData: {} as CityData,
                zipData: {} as CityData
              }
            ]);
            setAutoFilled((autoFilled) => ({
              ...autoFilled,
              properties: [...autoFilled.properties, { propertyType: { autoFilled: false, reason: "" } }]
            }));
          }}
        >
          Add a Property
        </Button>
      </Grid>
    </>
  );
}

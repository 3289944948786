import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderInfo } from "./types";

const OrderInfoContext = createContext<OrderInfo | null>(null);

export function useOrderInfo() {
  return useContext(OrderInfoContext);
}

export const OrderInfoProvider = ({ children }: { children: any }) => {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get("order");
  const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);

  const getAndSetOrderInfo = async () => {
    try {
      const { data } = await axios.get(`/proxy/api/orders/getOrderInfo?orderNumber=${orderNumber}`);
      setOrderInfo(data);
    } catch (error) {
      console.error("Failed to fetch order info:", error);
    }
  };

  useEffect(() => {
    if (orderNumber) {
      getAndSetOrderInfo();
    }
  }, [orderNumber]);

  return <OrderInfoContext.Provider value={orderInfo}>{children}</OrderInfoContext.Provider>;
};

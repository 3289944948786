import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
// @mui
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Box,
  Autocomplete,
  Alert,
  Dialog,
  TextFieldProps, Checkbox, FormControlLabel
} from "@mui/material";
// components
import { OrderContext } from "../contexts/OrderDataContext";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { WritableDraft } from "immer/dist/internal";
import produce from "immer";
import { RelatedOrder } from "./types/RelatedOrder";
import Iconify from "../../minimals/components/iconify";
import DebouncedAutoComplete from "../components/DebouncedAutoComplete";
import { UploadMultiFile } from "../components/upload";
import { NumberFormatCustom } from "../components/NumberFormat";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DotLoader from "../components/DotLoader";
// ----------------------------------------------------------------------

export default function PriorPolicy() {
  const {
    priorPolicyUnderwriterList,
    selectedPriorPolicyUnderwriter,
    setSelectedPriorPolicyUnderwriter,
    priorPolicyFiles,
    orderFiles,
    setOrderFiles,
    setPriorPolicyFiles,
    priorPolicyAmount,
    setPriorPolicyAmount,
    priorPolicyDate,
    setPriorPolicyDate,
    relatedOrders,
    setRelatedOrders,
    getAndSetMatchedOrdersByOrderNumber,
    totalRelatedOrdersCharLength,
    totalCharCount,
    openAsSubfile,
    setOpenAsSubfile,
    subfileSuffix,
    setSubfileSuffix
  } = useContext(OrderContext);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePriorPolicyDropMultiFile = useCallback(
    (acceptedFiles: any[]) => {
      setIsLoading(true);
      setPriorPolicyFiles((existingFiles) =>
        existingFiles.concat(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        )
      );
      setIsLoading(false);
    },
    [setPriorPolicyFiles]
  );
  const handlePriorPolicyRemoveAll = () => {
    setPriorPolicyFiles([]);
  };
  const deletePriorPolicyFileFromList = () => {
    setOrderFiles(orderFiles?.filter((file) => file.type !== "Prior Policy"));
  };

  const handlePriorPolicyRemove = (file: any) => {
    const filteredItems = priorPolicyFiles?.filter((_file) => _file !== file);
    if (orderFiles.some((orderFile) => orderFile.file === file)) {
      setDeleteConfirmationPopup(true);
    } else {
      setPriorPolicyFiles(filteredItems);
    }
  };

  const onRelatedOrderSearchTextChanged = async (text: string, index: number) => {
    if (!text) {
      setFileNumberList([], index);
      return;
    }
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/FindRelatedOrdersByNumber?searchText=${text}`
    });
    setFileNumberList(data, index);
  };

  const setRelatedOrderProp = (index: number, cb: (relatedOrder: WritableDraft<RelatedOrder>) => void) => {
    const newRelatedOrders = produce(relatedOrders, (draft) => {
      const order = draft[index];
      cb(order);
    });

    setRelatedOrders(newRelatedOrders);
  };

  const setFileNumber = (fileNumber: string | null, index: number) => {
    setRelatedOrderProp(index, (relatedOrder) => {
      relatedOrder.fileNumber = fileNumber;
    });
  };

  const setNotes = (notes: string | null, index: number) => {
    setRelatedOrderProp(index, (relatedOrder) => {
      relatedOrder.notes = notes;
    });
  };

  const setFileNumberList = (fileNumberList: string[], index: number) => {
    setRelatedOrderProp(index, (relatedOrder) => (relatedOrder.fileNumberList = fileNumberList));
  };

  const removeRelatedOrder = (index: number) => {
    var relatedOrdersCopy = [...relatedOrders];
    relatedOrdersCopy.splice(index, 1);
    setRelatedOrders(relatedOrdersCopy);
  };

  const handleClose = () => {
    setDeleteConfirmationPopup(false);
  };

  return (
    <>
      {totalRelatedOrdersCharLength > totalCharCount && (
        <Alert className="Mui-error" severity="error" style={{ maxWidth: 712, fontWeight: "bold", marginBottom: 20 }}>
          Too many characters - Please add only the most essential related orders and notes.
        </Alert>
      )}
      <Dialog
        open={deleteConfirmationPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="subtitle1" sx={{ padding: " 20px 40px" }}>
          Should uploaded document also be removed from uploaded documents in section 2 above?
        </Typography>
        <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => {
              deletePriorPolicyFileFromList();
              handleClose();
            }}
            sx={{ width: "100px", margin: "10px" }}
          >
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "100px", margin: "10px" }}>
            NO
          </Button>
        </div>
      </Dialog>
      <Grid container spacing={3} alignItems="top" marginBottom={3} id="relatedOrders">
        {relatedOrders.map((f, i) => (
          <Fragment key={f.fileNumber || i}>
            <Grid item xs={6}>
              <Box sx={{ position: "relative" }}>
                <DebouncedAutoComplete
                  value={f.fileNumber}
                  options={f.fileNumberList}
                  getOptionsLabel={(option) => option || ""}
                  onSelectedOptionChanged={(_e, fileNumber) => {
                    setFileNumber(fileNumber, i);
                    if (fileNumber) {
                      getAndSetMatchedOrdersByOrderNumber(fileNumber);
                    }
                  }}
                  textboxLabel={`Related Order #${i + 1}`}
                  textboxPlaceholder="Start typing..."
                  onDebouncedTextChanged={(text) => onRelatedOrderSearchTextChanged(text, i)}
                />
                {relatedOrders.length > 1 && (
                  <IconButton
                    aria-label="delete"
                    sx={{ position: "absolute", top: 10, right: 2 }}
                    onClick={() => removeRelatedOrder(i)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField label="Notes" onChange={(e) => setNotes(e.target.value, i)} fullWidth />
            </Grid>
            {i === 0 && relatedOrders.length > 0 && relatedOrders[0].fileNumber && <>
              <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox checked={openAsSubfile} onChange={(e, v) => setOpenAsSubfile(v)} />}
                    label={`Open as subfile of this Order # ${relatedOrders[0].fileNumber || ''}`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Suffix" value={subfileSuffix} onChange={(e) => setSubfileSuffix(e.target.value)} fullWidth />
              </Grid>
            </>
            }
          </Fragment>
        ))}
        <Grid item xs={12}>
          <Button
            size="small"
            startIcon={<Iconify icon={"eva:plus-fill"} />}
            onClick={() => setRelatedOrders([...relatedOrders, {} as RelatedOrder])}
          >
            Add Related Order
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" sx={{ display: "block", color: "text.secondary" }} id="priorPolicy">
            3B. Prior Policy
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <UploadMultiFile
            showPreview={false}
            files={priorPolicyFiles}
            onDrop={handlePriorPolicyDropMultiFile}
            onRemove={handlePriorPolicyRemove}
            onRemoveAll={handlePriorPolicyRemoveAll}
            label="Upload copy of prior policy"
            showSelectedFiles={true}
          />
          {isLoading && <DotLoader />}
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={selectedPriorPolicyUnderwriter}
            options={priorPolicyUnderwriterList}
            getOptionLabel={(option) => option.name || ""}
            onChange={(e, value) => setSelectedPriorPolicyUnderwriter(value)}
            renderInput={(params) => (
              <TextField required {...params} label="Prior Policy Underwriter" placeholder="Start typing..." />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Policy Amount"
            value={priorPolicyAmount || ""}
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
            onChange={(e) => setPriorPolicyAmount(Number(e.target.value) || null)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DesktopDatePicker
            label="Policy Date"
            format="MM/dd/yyyy"
            value={priorPolicyDate}
            onChange={setPriorPolicyDate}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
      </Grid>
    </>
  );
}

import { Box, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";
import { useOrderInfo } from "../../shared/orderInfo/OrderInfoContext";
import { useState, useEffect } from "react";
import AddEditSpreadModal from "./AddEditSpreadModal";
import { FlipSpreadInfo, TaskGroupEntity } from "../types";
import axios from "axios";
import { fCurrency } from "../../utils/formatNumber";

const SpreadAmount = ({ taskGroupEntity }: { taskGroupEntity: TaskGroupEntity }) => {
  const orderInfo = useOrderInfo();
  const [addEditSpreadMode, setAddEditSpreadMode] = useState<boolean>(false);
  const [spreadInfo, setSpreadInfo] = useState<FlipSpreadInfo | null>(null);

  const loadSpreadInfo = async () => {
    const { data } = await axios.get(`/api/taskmanagement/flips/GetSpread?taskGroupEntityId=${taskGroupEntity.id}`);
    setSpreadInfo(data);
  };

  useEffect(() => {
    loadSpreadInfo();
  }, []);

  const onCloseModal = async () => {
    setAddEditSpreadMode(false);
    await loadSpreadInfo();
  };

  return (
    <>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={4}>
          <Box>
            <Typography variant="caption">Intermediate Price</Typography>
            {orderInfo && (
              <Typography variant="subtitle1">
                {spreadInfo?.intermediatePrice ? fCurrency(spreadInfo?.intermediatePrice) : "--"}
              </Typography>
            )}
            {!orderInfo && <Skeleton />}
          </Box>
          <Box>
            <Typography variant="caption">End-Buyer Price</Typography>
            {orderInfo && (
              <Typography variant="subtitle1">
                {spreadInfo?.endBuyerPrice ? fCurrency(spreadInfo?.endBuyerPrice) : "--"}
              </Typography>
            )}
            {!orderInfo && <Skeleton />}
          </Box>
          <Box>
            <IconButton onClick={() => setAddEditSpreadMode(true)}>
              <Iconify icon="eva:edit-fill" />
            </IconButton>
          </Box>
        </Stack>
        <Box paddingBottom={0.5} paddingTop={0.5}>
          <Divider />
        </Box>
        <Box textAlign="right" display="flex" justifyItems="center" justifyContent="left">
          {/* calculation of percentage should be endprice divided by intermediate price with a percentage shown as well 1.28 should show as 28% */}
          <Typography variant="body2" display="inline-block" marginRight={0.5}>
            Spread Amount =
          </Typography>
          {orderInfo && (
            <>
              <Typography variant="subtitle1" display="inline-block" marginRight={0.5}>
                {spreadInfo?.intermediatePrice && spreadInfo?.endBuyerPrice
                  ? fCurrency(spreadInfo?.endBuyerPrice - spreadInfo?.intermediatePrice)
                  : "--"}
              </Typography>
              <Typography variant="subtitle1" display="inline-block">
                {spreadInfo?.percentage ? `(${Math.round(spreadInfo?.percentage)}%)` : ""}
              </Typography>
            </>
          )}
          {!orderInfo && <Skeleton sx={{ display: "inline-block", width: 140 }} />}
        </Box>
      </Stack>
      {addEditSpreadMode && (
        <AddEditSpreadModal
          taskGroupEntity={taskGroupEntity}
          open={addEditSpreadMode}
          closeModal={onCloseModal}
          flipSpreadInfo={spreadInfo}
        />
      )}
    </>
  );
};

export default SpreadAmount;

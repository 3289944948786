import React, { useContext, useEffect, useState } from "react";
import { Box, Card, Container, FormGroup, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { Helmet } from "react-helmet-async";
import Iconify from "../../minimals/components/iconify";
import { useSettingsContext } from "../contexts/SettingsContext";
import { User, UserContext } from "../contexts/UserContext";
import InternalUserAutocomplete from "../shared/InternalUserAutocomplete";
import { InternalUser } from "../types/app";
import Dashboard from "./Dashboard";
import { tmeOrderColumns } from "./tmeOrderColumns";
import { TMEOrderDetails } from "./types";
import { orderColumnsByList } from "../utils/materialReactTableUtils";
import { getConfiguration } from "./configurations";
import useLocalStorage from "../../minimals/hooks/useLocalStorage";
import useAxiosCancel from "../utils/useAxiosCancel";
import { StyledSwitch } from "./StyledSwitch";
import axios from "axios";

export default function TMEOrders() {
  const { user } = useContext(UserContext);
  const { fetchData, isLoading } = useAxiosCancel();

  const config = getConfiguration(user?.roles);
  const orderedColumns = orderColumnsByList(tmeOrderColumns, config.columnOrderOptions);

  const settings = useSettingsContext();
  const [internalUsers, setInternalUsers] = useState<InternalUser[]>([]);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [orders, setOrders] = useState<TMEOrderDetails[]>([]);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [isShowAssignedToMyTeam, setIsShowAssignedToMyTeam] = useLocalStorage<boolean>("isShowAssignedToMyTeam", true);
  const [isUserManagerOrTME, setIsUserManagerOrTME] = useState(false);

  const removeDuplicates = (users: InternalUser[]) => {
    const seenIds = new Set<number>();
    return users.filter((user) => {
      if (seenIds.has(user.id)) {
        return false;
      } else {
        seenIds.add(user.id);
        return true;
      }
    });
  };

  const handleShowTeamToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowAssignedToMyTeam(event.target.checked);
    if (user) {
      getAndSetInternalUsers(user, event.target.checked);
    }
  };

  const setUsersAndGetOrders = (internalUsers: InternalUser[], isShowAssignedToMyTeam: boolean) => {
    setInternalUsers(internalUsers);
    getAndSetOrders(internalUsers, isShowAssignedToMyTeam);
  };

  const getAndSetInternalUsers = async (user: User, isShowAssignedToMyTeam: boolean) => {
    const id = user?.id;
    if (!id) return;

    const url = isShowAssignedToMyTeam
      ? `/api/clientphonebook/internalusers/getInternalUserAndTeamMembers?id=${id}`
      : `/api/clientphonebook/internalusers/getInternalUserById?id=${id}`;

    const { data } = await axios.get<InternalUser[] | InternalUser | null>(url);

    if (data) {
      const usersArray = Array.isArray(data) ? data : [data];
      const updatedUsers = isShowAssignedToMyTeam ? removeDuplicates(usersArray) : usersArray;
      setInternalUsers(updatedUsers);
      await getAndSetOrders(updatedUsers, isShowAssignedToMyTeam);
    }
  };

  const getIfUserIsManagerOrTME = async (id: number) => {
    if (!id) return;
    const { data } = await axios.get<boolean>(
      `/api/clientphonebook/internalusers/getIsInternalUserManagerOrTME?id=${id}`
    );
    setIsUserManagerOrTME(data);
  };

  const getAndSetOrders = async (internalUsers: InternalUser[], isShowAssignedToMyTeam: boolean) => {
    if (internalUsers.length === 0) {
      setOrders([]);
      return;
    }
    const baseUrl = `/api/orders/getOrderDetails?isShowUnassigned=${isShowAssignedToMyTeam}`;
    const queryParams = internalUsers.length > 0 ? internalUsers.map((u) => `internalUserIds=${u.id}`).join("&") : "";
    const url = queryParams ? `${baseUrl}&${queryParams}` : baseUrl;
    const data: TMEOrderDetails[] = await fetchData(url);
    if (data) {
      data.sort((a, b) => +a.number - +b.number);
      setOrders(data);
    }
  };

  useEffect(() => {
    if (!user?.id) return;
    getIfUserIsManagerOrTME(user.id);
    getAndSetInternalUsers(user, isShowAssignedToMyTeam);
  }, [user]);

  return (
    <>
      <Helmet>
        <title>TitleQ Orders</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : "xl"} sx={{ borderRadius: 8 }}>
        <Stack
          direction="row"
          spacing={isUserManagerOrTME ? 1 : 2}
          marginBottom={isUserManagerOrTME ? 1 : 3}
          alignItems="center"
          visibility={isFullScreen ? "hidden" : "visible"}
        >
          <Typography variant="h4">Orders</Typography>
          {isUserManagerOrTME && (
            <InternalUserAutocomplete
              multiple
              value={internalUsers}
              onChange={(newValue) => {
                setIsShowAssignedToMyTeam(false);
                if (newValue === null) {
                  setUsersAndGetOrders([], false);
                } else if (Array.isArray(newValue)) {
                  setUsersAndGetOrders(newValue, false);
                }
              }}
              sx={{ width: 300 }}
            />
          )}
          <Dashboard internalUsers={internalUsers} />
        </Stack>
        {isUserManagerOrTME && (
          <FormGroup sx={{ marginBottom: 4 }}>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">Mine</Typography>
              <StyledSwitch
                checked={isShowAssignedToMyTeam}
                size="small"
                onChange={handleShowTeamToggleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body2">My Team's</Typography>
            </Stack>
          </FormGroup>
        )}
        <Card
          sx={{
            paddingRight: 4,
            paddingTop: 2,
            "& .MuiPaper-root, & .MuiCard-root": {
              boxShadow: "none !important"
            }
          }}
        >
          {user && (
            <MaterialReactTable
              onIsFullScreenChange={(value) => {
                setIsFullScreen(value);
              }}
              columns={orderedColumns}
              data={orders}
              enableGlobalFilter
              enableFacetedValues
              enableColumnOrdering={enableOrdering}
              enableMultiSort
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 },
                columnFilters: config.filterOptions,
                sorting: config.sortingOptions
              }}
              state={{
                showSkeletons: isLoading,
                showColumnFilters: true,
                isFullScreen
              }}
              muiTableProps={{
                sx: {
                  paddingLeft: 4
                }
              }}
              muiTableBodyProps={{
                sx: {
                  boxShadow: "none",
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              renderTopToolbarCustomActions={() => (
                <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
                  <Tooltip title="Column ordering">
                    <IconButton onClick={() => setEnableOrdering(!enableOrdering)} aria-label="toggle column ordering">
                      <Iconify icon="mdi:reorder-vertical" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          )}
        </Card>
      </Container>
    </>
  );
}

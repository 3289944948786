import { Autocomplete, Grid, InputAdornment, TextField } from "@mui/material";
import Iconify from "../../minimals/components/iconify";
import { Status } from "./types";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  options: Status[];
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSelectedTaskType: Dispatch<SetStateAction<number | undefined>>;
}

export default function TaskFilters({ options, setSearchTerm, setSelectedTaskType }: IProps) {
  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={10}>
        <TextField
          fullWidth
          sx={{ maxWidth: 705 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" />
              </InputAdornment>
            )
          }}
          placeholder="Search tasks..."
          onChange={(e) => handleSearchTermChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          renderInput={(params) => <TextField {...params} label="Status" />}
          getOptionLabel={(o) => o.description}
          options={options}
          onChange={(_, value) => setSelectedTaskType(value?.id)}
        />
      </Grid>
    </Grid>
  );
}

import axios from "axios";

const logEvent = (eventFunction: string, description: string, additionalInfo: string | undefined = undefined) => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/logevent", {
        function: eventFunction,
        url: window.location.href,
        description,
        additionalInfo
      });
    } catch {}
  })();
};

const logPageView = () => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/logpageview", { url: window.location.href });
    } catch {}
  })();
};

export { logEvent, logPageView };

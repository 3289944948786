import { Button, Grid, IconButton, TextField } from "@mui/material";
import { useContext } from "react";
import Iconify from "../../../../minimals/components/iconify";
import { OrderContext } from "../../../contexts/OrderDataContext";
import { MatchedOrderRequest } from "../../matchedOrders/MatchedOrder";
import { Property, PropertyInfo } from "../../types/Property";

interface IProps {
  lot: string;
  lotsIndex: number;
  property: Property;
  propertyIndex: number;
  setProperty: (newProperty: PropertyInfo, index: number) => void;
  isDisabled: boolean;
}

export default function Lot(props: IProps) {
  const { getAndSetMatchedOrders } = useContext(OrderContext);

  const { lot, lotsIndex, property, propertyIndex, setProperty, isDisabled } = props;
  return (
    <>
      <Grid container alignItems="center" marginBottom={1}>
        <Grid item xs={11}>
          <TextField
            disabled={isDisabled}
            InputProps={{
              endAdornment: property.propertyInfo.lots.length > 1 && (
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setProperty(
                      {
                        ...property.propertyInfo,
                        lots: property.propertyInfo.lots.filter((value, index) => index != lotsIndex)
                      },
                      propertyIndex
                    );
                  }}
                >
                  <Iconify icon={"eva:trash-2-outline"} />
                </IconButton>
              )
            }}
            label="Lot"
            value={lot || ""}
            onChange={(e) =>
              setProperty(
                {
                  ...property.propertyInfo,
                  lots: property.propertyInfo.lots.map((l, index) => (index === lotsIndex ? e.target.value : l))
                },
                propertyIndex
              )
            }
            fullWidth
            onBlur={() => {
              property.propertyInfo.block &&
                property.propertyInfo.lots[0] &&
                getAndSetMatchedOrders({
                  address: property.propertyInfo.formattedAddress,
                  state: property.propertyInfo.state,
                  block: property.propertyInfo.block,
                  lot: property.propertyInfo.lots[lotsIndex],
                  section: property.propertyInfo.section,
                  parcelId: property.propertyInfo.parcelIds[0]
                } as MatchedOrderRequest);
            }}
          />
        </Grid>
        {lotsIndex === property.propertyInfo.lots.length - 1 && (
          <Grid item xs={1}>
            <Button
              sx={{ marginLeft: 1 }}
              size="small"
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              onClick={() =>
                setProperty(
                  {
                    ...property.propertyInfo,
                    lots: [...property.propertyInfo.lots, ""]
                  },
                  propertyIndex
                )
              }
            >
              Lot
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}

import { Alert, Box, Card, CardContent, Checkbox, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AddEditContactContext } from "../addEditContact/AddEditContactContext";
import Iconify from "../../../../minimals/components/iconify";
import { Phone } from "../../types";

interface PhoneDiscrepancy {
  type: string;
  discrepancies: Phone[];
}
export default function PhoneNumberDiscrepancyCard({
  phoneNumberDiscrepancies,
  setTheInput
}: {
  phoneNumberDiscrepancies: PhoneDiscrepancy[];
  setTheInput: Dispatch<SetStateAction<string>>;
}) {
  const { contact, setContact } = useContext(AddEditContactContext);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleDescrepancyPhoneChange = (phone: Phone) => {
    setContact((draft) => {
      const phoneNumbers = draft.contactRoles[0].phoneNumbers;
      const emptyPhoneIndex = phoneNumbers.findIndex((phone) => !phone.type && !phone.number);

      if (emptyPhoneIndex !== -1) {
        phoneNumbers[emptyPhoneIndex] = phone;
      } else {
        phoneNumbers.push(phone);
      }
    });
  };
  return (
    <>
      {phoneNumberDiscrepancies.map((pd) => (
        <Card
          sx={{
            p: 2,
            mb: 3,
            top: 62,
            maxHeight: "100vh",
            overflowY: "scroll"
          }}
        >
          <Stack spacing={2}>
            <CardContent onMouseOver={() => setTheInput("Phone")}>
              <Typography variant="h6" marginBottom={3}>
                {pd.type} Number
              </Typography>
              {pd.discrepancies.map((n) => (
                <Stack direction="row" marginBottom={2} spacing={1} alignItems="center">
                  <Box>
                    {contact.contactRoles[0].phoneNumbers.filter(
                      (p) => `${p.number} ${p.extension}` === `${n.number} ${n.extension}`
                    ).length > 0 ? (
                      <Checkbox checked={true} />
                    ) : (
                      <IconButton
                        color="primary"
                        sx={{ borderRadius: "4px" }}
                        onClick={(e) => {
                          handleDescrepancyPhoneChange(n);
                        }}
                      >
                        <Iconify icon="eva:plus-fill" />
                      </IconButton>
                    )}
                  </Box>
                  <Typography variant="body2" fontSize={13} color="primary">
                    {n.number} {n.extension ? `Ext: ${n.extension}` : ""}
                  </Typography>

                  <Box>
                    <IconButton color="primary" onClick={() => copyToClipboard(n.number)}>
                      <Iconify icon="eva:copy-outline" />
                    </IconButton>
                  </Box>
                </Stack>
              ))}
            </CardContent>
          </Stack>
        </Card>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="success" sx={{ width: 200 }}>
          Copied!
        </Alert>
      </Snackbar>
    </>
  );
}

import Label from "../../../../../minimals/components/label";

export const generateLabel = (status: string, label?: string | JSX.Element | number) => {
  switch (status) {
    case "Paid":
      return (
        <Label sx={{ cursor: "pointer" }} variant="soft" color="success">
          {label || "Paid"}
        </Label>
      );
    case "Unpaid":
      return (
        <Label sx={{ cursor: "pointer" }} variant="soft" color="warning">
          {label || "Unpaid"}
        </Label>
      );
    case "Overdue":
      return (
        <Label sx={{ cursor: "pointer" }} variant="soft" color="error">
          {label || "Overdue"}
        </Label>
      );
    case "Draft":
      return (
        <Label sx={{ cursor: "pointer" }} variant="soft" color="default">
          {label || "Draft"}
        </Label>
      );
    case "All":
      return (
        <Label sx={{ cursor: "pointer" }} variant="soft" color="info">
          {label || "All"}
        </Label>
      );
  }
};

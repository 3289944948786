import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Order } from "../orderEntry/types/Order";
import { NotesData } from "../notes/NotesTypes";

// ----------------------------------------------------------------------

const NotesContext = createContext<NotesData>({} as NotesData);

function NotesContextProvider({ children }: { children: JSX.Element }) {
  const { orderNumber } = useParams();
  const [notesPdf, setNotesPdf] = useState<string>();
  const [order, setOrder] = useState<Order>();

  const getandSetOrderInfo = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/orders/GetOrderInfo?orderNumber=${orderNumber}`
    });
    setOrder({
      orderNumber: data.number,
      identifier: data.identifier,
      titleCode: data.titleCode,
      propertyUse: data.propertyUse,
      state: data.properties[0].state,
      productType: data.productType,
      contacts: data.contacts,
      properties: data.properties
    });
  };

  const getandSetNotesPdf = async () => {
    const { data } = await axios({
      method: "get",
      url: `/proxy/api/documentgenerator/generate?orderNumber=${orderNumber}&documentType=Notes`
    });
    setNotesPdf(data.base64Document);
  };

  useEffect(() => {
    getandSetNotesPdf();
    getandSetOrderInfo();
  }, [orderNumber]);

  return (
    <NotesContext.Provider
      value={{
        order,
        notesPdf
      }}
    >
      {children}
    </NotesContext.Provider>
  );
}

export { NotesContextProvider, NotesContext };

export interface CorpDocType {
  id: number;
  name: string;
}

export interface CorpDocDocument {
  id: number;
  corpDocId: number;
  momentumDocumentId: number;
  uploadedDate: Date;
  uploadedBy: string;
}

export interface TaskItem {
  id: number;
  corpDocId: number;
  description: string;
  status: number;
  statusUpdateHistory: StatusUpdateHistory[];
  notes: Note[];
  assignedToName: string | null;
  assignedToEmail: string | null;
}

interface StatusUpdateHistory {
  id: number;
  taskItemId: number;
  status: number;
  username: string;
  documentId: number;
  dateTime: Date;
  note: string;
  eventType: number;
}

export interface CorpDoc {
  id: number;
  orderNumber: string;
  type: number;
  description: string;
  dateCreated: Date;
  createdBy: string;
  documents: CorpDocDocument[];
  taskItems: TaskItem[];
}

export interface Status {
  id: number;
  description: string;
}

export interface EventType {
  id: number;
  description: string;
}

export interface CorpDocTrackingOrderInfo {
  orderNumber: string;
  buyerBorrowerName: string;
  sellerName: string;
  purchasePrice?: number | null;
  loans: Array<{ amount: number; lender: string }>;
  properties: CorpDocTrackingOrderProperty[];
}

export interface CorpDocTrackingOrderProperty {
  id: number;
  legalDescription: string;
  block: string;
  lots: string[];
  parcelIds: string[];
  county: string;
}

export interface GeneralTask {
  id: number;
  description: string;
  corpDocType: CorpDocType;
}

export type StateTask = GeneralTask & { stateId: number };

export type CountyTask = GeneralTask & { countyId: number };

export enum TaskType {
  General,
  State,
  County
}

export enum ModalType {
  Add,
  Edit
}

export interface State {
  id: number;
  name: string;
  abbreviation: string;
}

export interface County {
  id: number;
  stateId: number;
  name: string;
}

export interface Note {
  id: number;
  taskItemId: number;
  text: string;
  dateCreated: Date;
  createdByUser: string;
  isUserNote: boolean;
}

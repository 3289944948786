import { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { isAfter, isValid } from "date-fns";
import MUIDataTable from "mui-datatables";
import useQuery from "../../hooks/useQuery";
import { customColumns } from "./ClosingUtils";
import { getClosedOrdersByDateRange } from "../calendar/closings/actions/closings";
import { IClosedOrder } from "../types/calendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fMonthDayYear } from "../utils/formatTime";

const ClosingTable = () => {
  const [query, setQuery] = useQuery();
  // const { closedOrders, getClosedOrdersByDateRange } = useClosings();
  const [isLoading, setIsLoading] = useState(false);
  const [closings, setClosings] = useState<IClosedOrder[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const earliestValidDate = useRef(new Date(2020, 1, 1));

  const getOrdersWithLoader = async ({ start, end }: { start: Date | null; end: Date | null }) => {
    if (!start || !end) {
      return;
    }
    setIsLoading(true);
    const data = await getClosedOrdersByDateRange({ start, end });
    setClosings(data);
    setIsLoading(false);
  };

  useEffect(() => {
    ////Replacing these params because of JS Date bug with -
    // const startDateParam = (query.get('startDate') || '').replace('/-/g', '/');
    // const endDateParam = (query.get('endDate') || '').replace('/-/g', '/')l
    const startDateParam = (query.get("startDate") || "").replaceAll("-", "/");
    const endDateParam = (query.get("endDate") || "").replaceAll("-", "/");

    const defaultStartDate = startDateParam ? new Date(startDateParam) : new Date();
    const defaultEndDate = endDateParam ? new Date(endDateParam) : new Date();

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);

    (async () => {
      await getOrdersWithLoader({
        start: new Date(startDateParam || defaultStartDate),
        end: new Date(endDateParam || defaultEndDate)
      });
    })();
  }, []);

  const title = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          marginTop: "25px"
        }}
      >
        <h2>Closings</h2>
        <DatePicker
          label="Settlement Date From"
          format="MM/dd/yyyy"
          value={startDate}
          onChange={(date) => {
            if (!date) {
              return;
            }
            const isValidDate = isValid(date);
            const isAfterEarliestDate = isAfter(date, earliestValidDate.current);
            setStartDate(date);
            if (isValidDate && isAfterEarliestDate) {
              setQuery("startDate", fMonthDayYear(date));
              getOrdersWithLoader({ start: date, end: endDate });
            }
          }}
          slotProps={{ textField: { sx: { maxWidth: 250, marginRight: "24px" } } }}
        />
        <DatePicker
          label="Settlement Date To"
          format="MM/dd/yyyy"
          value={endDate}
          onChange={async (date) => {
            if (!date) {
              return;
            }
            const isValidDate = isValid(date);
            const isAfterEarliestDate = isAfter(date, earliestValidDate.current);
            setEndDate(date);
            if (isValidDate && isAfterEarliestDate) {
              setQuery("endDate", fMonthDayYear(date));
              getOrdersWithLoader({ start: startDate, end: date });
            }
          }}
          slotProps={{ textField: { sx: { maxWidth: 250, marginRight: "24px" } } }}
        />
        {isLoading && <CircularProgress />}
      </Box>
    </>
  );

  const options = {
    // filterType: 'custom',
    rowsPerPage: 100,
    selectableRowsHideCheckboxes: true,
    fixedHeader: true,
    print: false,
    setRowProps: (row: any[], index: number) => {
      return {
        style: {
          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
          borderTop: ".5px solid grey"
        }
      };
    }
  };
  return (
    <>
      <MUIDataTable title={title} data={closings} columns={customColumns} options={options as any} />
    </>
  );
};

export default ClosingTable;

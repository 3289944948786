import { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
// @mui
import { Typography, Button, TextField, Autocomplete, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// components
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import { OrderContext } from "../contexts/OrderDataContext";
import { MuiTelInput } from "mui-tel-input";
import PrefilledIndicator from "../components/PrefilledIndicator";
import Iconify from "../../minimals/components/iconify";
import styled from "@emotion/styled";
import { InternalUser } from "../types/app";
import PhonebookContactAutocomplete from "../phonebook/phonebookAutocomplete/PhonebookContactAutocomplete";
import { Contact } from "./types/Contact";

// ----------------------------------------------------------------------
export default function PointOfContact() {
  const {
    selectedClient,
    selectedMarketingRep1,
    selectedMarketingRep2,
    selectedMarketingRep3,
    selectedAccountRep,
    selectedClientPointOfContactType,
    selectedTeam,
    salesSuffix,
    setSelectedTeam,
    setSalesSuffix,
    setSelectedClient,
    setSelectedMarketingRep1,
    setSelectedMarketingRep2,
    setSelectedMarketingRep3,
    setSelectedAccountRep,
    setSelectedClientPointOfContactType,
    errorMode,
    errors,
    autoFilled,
    setAutoFilled,
    properties,
    selectedFunder,
    setSelectedFunder,
    transactionType
  } = useContext(OrderContext);

  const clientTypeOptionsList =
    transactionType === "Refinance"
      ? [
          { apiName: "Attorney", name: "Borrower's Attorney", enum: "buyersAttorney", hasOther: false },
          { apiName: "Principal-Buyer", name: "Principal-Borrower", enum: "principalBuyer", hasOther: true },
          { apiName: "Lender", name: "Lender", enum: "lender", hasOther: true },
          { apiName: "Lender's Attorney", name: "Lender's Attorney", enum: "lendersAttorney", hasOther: true },
          { apiName: "Mortgage Broker", name: "Mortgage Broker", enum: "mortgageBroker", hasOther: false },
          { apiName: "Other", name: "Other", enum: "other", hasOther: false }
        ]
      : [
          { apiName: "Attorney", name: "Buyers Attorney", enum: "buyersAttorney", hasOther: false },
          { apiName: "Principal-Buyer", name: "Principal-Buyer", enum: "principalBuyer", hasOther: true },
          { apiName: "Principal-Seller", name: "Principal-Seller", enum: "principalSeller", hasOther: true },
          { apiName: "Attorney", name: "Sellers Attorney", enum: "sellersAttorney", hasOther: false },
          { apiName: "Lender", name: "Lender", enum: "lender", hasOther: true },
          { apiName: "Lender's Attorney", name: "Lender's Attorney", enum: "lendersAttorney", hasOther: true },
          { apiName: "Mortgage Broker", name: "Mortgage Broker", enum: "mortgageBroker", hasOther: false },
          { apiName: "Other", name: "Other", enum: "other", hasOther: false }
        ];

  const [accountRepList, setAccountRepList] = useState<InternalUser[]>([]);
  const [marketingRepList1, setMarketingRepList1] = useState<InternalUser[]>([]);
  const [marketingRepList2, setMarketingRepList2] = useState<InternalUser[]>([]);
  const [marketingRepList3, setMarketingRepList3] = useState<InternalUser[]>([]);

  const [funderList, setFunderList] = useState<InternalUser[]>([]);
  const [searchedFunder, setSearchedFunder] = useState("");
  const debouncedSearchedFunder = useDebounce(searchedFunder, 300);
  const [teamList, setTeamList] = useState<string[]>([]);

  const [searchSalesRepText1, setSearchSalesRepText1] = useState("");
  const [searchSalesRepText2, setSearchSalesRepText2] = useState("");
  const [searchSalesRepText3, setSearchSalesRepText3] = useState("");
  const debouncedSearchSaleReps1Text = useDebounce(searchSalesRepText1, 300);
  const debouncedSearchSaleReps2Text = useDebounce(searchSalesRepText2, 300);
  const debouncedSearchSaleReps3Text = useDebounce(searchSalesRepText3, 300);

  const [showSaleRep2, setShowSaleRep2] = useState(false);
  const [showSaleRep3, setShowSaleRep3] = useState(false);
  const isSameAsAttorney =
    selectedClientPointOfContactType?.name === "Buyers Attorney" ||
    selectedClientPointOfContactType?.name === "Sellers Attorney";

  const searchSalesReps = async (searchText: string) => {
    const { data } = await axios.get<InternalUser[]>(
      `/api/clientphonebook/internalUsers/searchSalesReps?searchText=${searchText}`
    );
    return data;
  };

  const searchFunders = async (searchText: string) => {
    const { data } = await axios.get<InternalUser[]>(
      `/api/clientphonebook/internalUsers/searchFunders?searchText=${searchText}`
    );
    return data;
  };

  const getAndSetAccountReps = async () => {
    const { data } = await axios.get<InternalUser[]>(`/api/clientphonebook/internalUsers/getAllAccountReps`);
    setAccountRepList(data);
  };

  const useSearchSalesReps = (
    debouncedSearchText: string,
    setMarketingRepList: Dispatch<SetStateAction<InternalUser[]>>
  ) => {
    useEffect(() => {
      if (debouncedSearchText) {
        searchSalesReps(debouncedSearchText).then((response) => {
          setMarketingRepList(response);
        });
      } else {
        setMarketingRepList([]);
      }
    }, [debouncedSearchText, setMarketingRepList]);
  };

  useSearchSalesReps(debouncedSearchSaleReps1Text, setMarketingRepList1);
  useSearchSalesReps(debouncedSearchSaleReps2Text, setMarketingRepList2);
  useSearchSalesReps(debouncedSearchSaleReps3Text, setMarketingRepList3);

  useEffect(() => {
    axios.get("/proxy/api/contacts/getteams").then((response) => {
      setTeamList(response.data.map((d: any) => d.name).sort());
    });

    getAndSetAccountReps();
  }, []);

  useEffect(() => {
    if (!debouncedSearchedFunder) {
      setFunderList([]);
      return;
    }
    searchFunders(debouncedSearchedFunder).then((response) => {
      setFunderList(response);
    });
  }, [debouncedSearchedFunder]);

  useEffect(() => {
    if (selectedClient?.salesRep) {
      const [rep1, rep2, rep3] = selectedClient.salesRep;
      if (rep1) {
        setMarketingRepList1([rep1]);
        setSelectedMarketingRep1(rep1);
      }
      if (rep2) {
        setMarketingRepList2([rep2]);
        setSelectedMarketingRep2(rep2);
        setShowSaleRep2(true);
      }

      if (rep3) {
        setMarketingRepList3([rep3]);
        setSelectedMarketingRep3(rep3);
        setShowSaleRep3(true);
      }
      setAutoFilled((prevAutoFilled) => ({
        ...prevAutoFilled,
        pointOfContact: {
          ...prevAutoFilled.pointOfContact,
          salesRep: {
            ...prevAutoFilled.pointOfContact.salesRep,
            autoFilled: true
          }
        }
      }));
    }
    setAccountRep(selectedClient);
  }, [selectedClient]);

  const setAutoFilledAccountRep = (client: Contact) => {
    setAutoFilled({
      ...autoFilled,
      selectedClient: {
        ...autoFilled.selectedClient,
        internalDriver: {
          ...autoFilled.selectedClient.internalDriver,
          autoFilled: client?.internalDriverName != ""
        }
      }
    });
  };

  const setAccountRep = (client: Contact | null) => {
    if (!client) return;
    let accountRep: InternalUser | null;
    if (properties.some((p) => p.propertyInfo.state === "NY")) {
      accountRep = client.nyAccountRep;
    } else if (properties.some((p) => p.propertyInfo.state === "NJ")) {
      accountRep = client.njAccountRep;
    } else {
      accountRep = client.nationalAccountRep;
    }
    if (accountRep) {
      setSelectedAccountRep(accountRep);
      setAutoFilledAccountRep(client);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} xs={12} position="relative">
          <Autocomplete
            id="principal"
            value={selectedClientPointOfContactType}
            options={clientTypeOptionsList}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => {
              setSelectedClientPointOfContactType(value);
              setAutoFilled({
                ...autoFilled,
                pointOfContact: {
                  ...autoFilled.pointOfContact,
                  clientType: {
                    ...autoFilled.pointOfContact.clientType,
                    autoFilled: false
                  }
                }
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={errorMode && errors.pointOfContact.pointOfContactType}
                label="Client Type"
                placeholder="Start typing..."
              />
            )}
          />
          {autoFilled.pointOfContact.clientType.autoFilled && (
            <PrefilledIndicator title={autoFilled.pointOfContact.clientType.reason} sx={{ top: 24 }} />
          )}
        </Grid>
        {selectedClientPointOfContactType?.name != "Other" && (
          <Grid item md={6} xs={12}>
            <PhonebookContactAutocomplete
              contact={selectedClient}
              setContact={(value) => {
                setSelectedClient(typeof value === "object" && value ? value : null);
              }}
              textboxLabel="Point of Contact"
              error={!isSameAsAttorney && errorMode && errors.pointOfContact.name}
              textboxPlaceholder={""}
              disabled
            />
          </Grid>
        )}
        {selectedClientPointOfContactType?.name === "Other" && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                value={selectedClient?.name}
                required
                error={errorMode && errors.pointOfContact.name}
                fullWidth
                label="Name"
                onChange={(e) =>
                  setSelectedClient({
                    ...selectedClient,
                    name: e.target.value
                  } as Contact)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={selectedClient?.email}
                fullWidth
                label="Email"
                onChange={(e) =>
                  setSelectedClient({
                    ...selectedClient,
                    email: e.target.value
                  } as Contact)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <IconStyle>
                <MuiTelInput
                  preferredCountries={["US", "IL"]}
                  defaultCountry="US"
                  forceCallingCode
                  inputProps={{ maxLength: 12 }}
                  value={selectedClient?.phone || undefined}
                  fullWidth
                  label="Phone"
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      phone: e
                    } as Contact)
                  }
                />
              </IconStyle>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="overline" sx={{ mb: 1, mt: 1, display: "block", color: "text.secondary" }}>
            Reps
          </Typography>
        </Grid>

        <Grid item md={6} xs={12} position="relative">
          <Autocomplete
            value={selectedMarketingRep1}
            options={marketingRepList1}
            getOptionLabel={(option) => `${option.firstName || ""} ${option.lastName || ""}`}
            onChange={(e, value) => {
              setSelectedMarketingRep1(value);
              setAutoFilled({
                ...autoFilled,
                pointOfContact: {
                  ...autoFilled.pointOfContact,
                  salesRep: {
                    ...autoFilled.pointOfContact.salesRep,
                    autoFilled: false
                  }
                }
              });
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.firstName || ""} ${option.lastName || ""}`}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sales Rep"
                placeholder="Start typing..."
                onChange={(e) => setSearchSalesRepText1(e.target.value)}
              />
            )}
          />
          {autoFilled.pointOfContact.salesRep.autoFilled && (
            <PrefilledIndicator title={autoFilled.pointOfContact.salesRep.reason} sx={{ top: 24 }} />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={selectedTeam}
            options={teamList}
            getOptionLabel={(option) => option || ""}
            onChange={(e, value) => setSelectedTeam(value)}
            renderInput={(params) => <TextField {...params} label="Team" placeholder="Start typing..." />}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={salesSuffix}
            options={["BA", "NYO", "ST", "SO"]}
            getOptionLabel={(option) => option || ""}
            onChange={(e, value) => setSalesSuffix(value!)}
            renderInput={(params) => <TextField {...params} label="Sales Suffix" placeholder="Start typing..." />}
          />
        </Grid>
        <Grid item md={6} xs={12} position="relative">
          <Autocomplete
            value={selectedAccountRep}
            options={accountRepList}
            getOptionLabel={(option) => `${option.firstName || ""} ${option.lastName || ""}`}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {`${item.firstName || ""} ${item.lastName || ""}`}
              </li>
            )}
            onChange={(e, value) => {
              setSelectedAccountRep(value);
              setAutoFilled({
                ...autoFilled,
                selectedClient: {
                  ...autoFilled.selectedClient,
                  internalDriver: {
                    ...autoFilled.selectedClient.internalDriver,
                    autoFilled: false
                  }
                }
              });
            }}
            renderInput={(params) => <TextField {...params} label="Account Rep" />}
          />
          {autoFilled.selectedClient.internalDriver.autoFilled && (
            <PrefilledIndicator sx={{ top: 24 }} title={autoFilled.selectedClient.internalDriver.reason} />
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            value={selectedFunder}
            options={funderList}
            getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}`.trim() : "")}
            onChange={(e, value) => setSelectedFunder(value)}
            onInputChange={(e, value) => setSearchedFunder(value)}
            renderInput={(params) => <TextField {...params} label="Funder" placeholder="Start typing..." />}
          />
        </Grid>

        {showSaleRep2 && (
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={selectedMarketingRep2}
              options={marketingRepList2}
              getOptionLabel={(option) => `${option.firstName || ""} ${option.lastName || ""}`}
              onChange={(e, value) => setSelectedMarketingRep2(value)}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Sales Rep 2"
                    placeholder="Start typing..."
                    onChange={(e) => setSearchSalesRepText2(e.target.value)}
                  />
                  <IconButton
                    aria-label="delete"
                    sx={{ position: "absolute", right: 2, top: 10 }}
                    onClick={() => {
                      if (showSaleRep3) {
                        setShowSaleRep3(false);
                        setSearchSalesRepText2(searchSalesRepText3);
                        setSelectedMarketingRep2(selectedMarketingRep3);
                        setSelectedMarketingRep3(null);
                        setSearchSalesRepText3("");
                      } else {
                        setShowSaleRep2(false);
                        setSearchSalesRepText2("");
                      }
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </>
              )}
              sx={{ position: "relative" }}
            />
          </Grid>
        )}
        {showSaleRep3 && (
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={selectedMarketingRep3}
              options={marketingRepList3}
              getOptionLabel={(option) => `${option.firstName || ""} ${option.lastName || ""}`}
              onChange={(e, value) => setSelectedMarketingRep3(value)}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label={`Sales Rep ${showSaleRep2 && showSaleRep3 ? "3" : "2"}`}
                    placeholder="Start typing..."
                    onChange={(e) => setSearchSalesRepText3(e.target.value)}
                  />

                  <IconButton
                    aria-label="delete"
                    sx={{ position: "absolute", right: 2, top: 10 }}
                    onClick={() => {
                      setShowSaleRep3(false);
                      setSearchSalesRepText3("");
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </>
              )}
              sx={{ position: "relative" }}
            />
          </Grid>
        )}
        {!showSaleRep3 && (
          <Grid item xs={12}>
            <Button
              size="small"
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              onClick={() => {
                if (showSaleRep2) {
                  setShowSaleRep3(true);
                } else {
                  setShowSaleRep2(true);
                }
              }}
            >
              Add Sales Rep
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
const IconStyle = styled.div`
  .MuiTelInput-IconButton {
    img {
      display: none;
    }
  }
`;

import { Alert, Button, Stack } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { SearchesContext } from "../contexts/SearchesContext";
import SendViaEmailButton from "./SendViaEmailButton";

export default function SentSearchEmailAlert() {
  const { orderNumber, onPreivewSendViaEmailClick } = useContext(SearchesContext);
  const navigate = useNavigate();
  return (
    <Alert
      sx={{ marginTop: 2 }}
      action={
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => window.open(`https://internal.mcres.com/new-home/${orderNumber}`, "_blank")?.focus()}
          >
            View
          </Button>
          <SendViaEmailButton onClick={onPreivewSendViaEmailClick} size="medium" label="Re-send via Email" />
          <Button variant="contained" onClick={() => navigate(`/confirmation/${orderNumber}`)}>
            Next
          </Button>
        </Stack>
      }
    >
      Search Order Sent
    </Alert>
  );
}

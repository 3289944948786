import { Box, Typography } from "@mui/material";
import { OrderInfo } from "../../shared/orderInfo/types";
import dayjs from "dayjs";

const getStatusStyles = (status: string) => {
  switch (status) {
    case "Canceled":
      return { color: "#ca0000", background: "rgba(199, 0, 0, 0.12)" };
    case "Hold" || "Duplicate":
      return { color: "#ffa500", background: "rgba(255, 165, 0, 0.12)" };
    case "Pending":
      return { color: "#0066ff", background: "rgba(139, 196, 248, 0.8)" };
    case "Closed":
      return { color: "#ffde00", background: "rgb(254, 255, 175)" };
  }
};

const StatusAndDate = ({ order }: { order: OrderInfo }) => {
  const formattedDate = order.settlementDate ? dayjs(order.settlementDate).format("MM/DD/YYYY") : "--";

  switch (order.status) {
    case "Canceled":
    case "Hold":
    case "Transferred":
    case "Duplicate":
      return (
        <Box
          sx={{
            backgroundColor: "rgb(57, 181, 74, 0.12)",
            borderRadius: "4px",
            color: "rgb(57, 181, 74)",
            textTransform: "uppercase",
            textAlign: "center",
            paddingRight: 1,
            paddingLeft: 1,
            ...getStatusStyles(order.status)
          }}
        >
          <Typography variant="subtitle2">{order.status}</Typography>
        </Box>
      );
    case "Closed":
    case "Completed":
      return (
        <>
          <Typography variant="caption">{`${order.status} on`}</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
    case "In process":
      return (
        <>
          <Typography variant="caption">Est. closing date</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
    case "Firm":
      return (
        <>
          <Typography variant="caption">Scheduled to close</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
    case "Adjourned":
      return (
        <>
          <Typography variant="caption">Adjourned from</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
    case "Tentative":
      return (
        <>
          <Typography variant="caption">Tentative Closing date</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
    default:
      return (
        <>
          <Typography variant="caption">Settlement date</Typography>
          <Typography variant="subtitle1">{formattedDate}</Typography>
        </>
      );
  }
};

export default StatusAndDate;

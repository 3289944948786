export const debounce = (func: any, delay: number) => {
  let timerId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args);
    }, delay);
  };
};

import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const RequiredDocAlertModal = ({ open, closeModal }: { open: boolean; closeModal: () => void }) => {
  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>Please upload a copy of the document prior to marking this completed/received.</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={closeModal}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequiredDocAlertModal;

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface IProps {
  handleClose: () => void;
  handleNo: () => void;
}

export default function MissingCompanyBranchAlert({ handleClose, handleNo }: IProps) {
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>A company address/branch is required.</DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={2}>
          Please try to visit the company website or do some googling. Otherwise this individual will be assigned an
          address at company headquarters.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleNo}>
          That's fine
        </Button>
        <Button variant="contained" onClick={handleClose}>
          I'll google it
        </Button>
      </DialogActions>
    </Dialog>
  );
}

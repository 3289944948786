import { Box, Button, Card, CardContent, IconButton, Skeleton, Stack } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";
import { useEffect, useState } from "react";
import { StyledBadge } from "../../shared/StyledBadge";
import OrderNotesModal from "../notes/OrderNotesModal";
import { LedgerTransaction, TaskGroupEntity } from "../types";
import SpreadAmount from "./SpreadAmount";
import Properties from "./Properties";
import Order from "./Order";
import ClosingChecklistLedgers from "../ClosingChecklistLedgers";
import ClosingChecklistEscrow from "../ClosingChecklistEscrow";
import { EscrowLedger } from "../../reports/escrowLedgers/types";
import axios from "axios";
import LedgerSummary from "../LedgerSummary";
import { processLedgerData } from "../../reports/escrowLedgers/processLedgerData";
import { useQueryParam } from "use-query-params";
import LedgerLoader from "./LedgerLoader";

const TaskOrderInfo = ({
  taskGroupEntity,
  refreshTaskGroupEntity
}: {
  taskGroupEntity: TaskGroupEntity | null;
  refreshTaskGroupEntity: () => Promise<void>;
}) => {
  const [orderNumber] = useQueryParam<string>("order");
  const [addOrderNoteMode, setAddOrderNoteMode] = useState<boolean>(false);
  const [openClosingChecklistEscrow, setOpenClosingChecklistEscrow] = useState<boolean>(false);
  const [escrowLedgers, setEscrowLedgers] = useState<LedgerTransaction[]>([]);
  const [escrowLedgerTotal, setEscrowLedgerTotal] = useState<number | null>(null);
  const [isLedgersLoading, setIsLedgersLoading] = useState<boolean>(true);

  const isFlips = taskGroupEntity?.applicationId === 1;
  const isClosingChecklist = taskGroupEntity?.applicationId === 2;

  const fetchEscrowLedgers = async () => {
    setIsLedgersLoading(true);
    if (!orderNumber) {
      return;
    }
    const { data } = await axios.get<EscrowLedger[]>(`/proxy/api/ledgers/getescrowledgers`);
    const ledgers = processLedgerData(data);
    var transactions = ledgers
      .filter((d) => d.name.startsWith(orderNumber))
      .map((d) => {
        var splitName = d.name.split("-");
        return {
          name: splitName[splitName.length - 1],
          amount: d.amount
        };
      })
      .sort((a, b) => {
        const numA = parseInt(a.name.replace(/\D/g, ""), 10);
        const numB = parseInt(b.name.replace(/\D/g, ""), 10);
        return numA - numB;
      }) as LedgerTransaction[];
    setEscrowLedgers(transactions);
    var escrowTotal = transactions.reduce((total, transaction) => {
      return total + transaction.amount;
    }, 0);
    setEscrowLedgerTotal(escrowTotal);
    setIsLedgersLoading(false);
  };

  useEffect(() => {
    fetchEscrowLedgers();
  }, [orderNumber]);

  return (
    <>
      <Stack direction="row" marginBottom={3}>
        <Card sx={{ width: "100%", padding: 0 }}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={14}>
                <Order />
                <Properties />
                {isFlips && <SpreadAmount taskGroupEntity={taskGroupEntity} />}
                <Stack direction="row" spacing={2}>
                  {isClosingChecklist && (
                    <>
                      <ClosingChecklistLedgers />
                      <Box marginBottom={1}>
                        <Button
                          variant="outlined"
                          onClick={() => setOpenClosingChecklistEscrow(true)}
                          size="small"
                          sx={{ marginBottom: 1 }}
                        >
                          Transfer Escrow Funds
                        </Button>
                        {openClosingChecklistEscrow && (
                          <ClosingChecklistEscrow
                            onClose={() => setOpenClosingChecklistEscrow(false)}
                            refresh={fetchEscrowLedgers}
                          />
                        )}
                        {!isLedgersLoading && escrowLedgers.length > 0 && (
                          <LedgerSummary
                            ledgerTypeHeader={"Escrow Ledgers"}
                            total={escrowLedgerTotal}
                            transactions={escrowLedgers}
                            orderNumber={orderNumber}
                          />
                        )}
                        {isLedgersLoading && <LedgerLoader />}
                      </Box>
                    </>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                {taskGroupEntity && (
                  <Box>
                    <IconButton onClick={() => setAddOrderNoteMode(true)}>
                      <StyledBadge badgeContent={taskGroupEntity?.notes?.length} color="error">
                        <Iconify icon="eva:file-add-outline" />
                      </StyledBadge>
                    </IconButton>
                  </Box>
                )}
                {!taskGroupEntity && <Skeleton variant="circular" width={36} height={36} />}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      {addOrderNoteMode && (
        <OrderNotesModal
          taskGroupEntity={taskGroupEntity}
          closeModal={() => setAddOrderNoteMode(false)}
          refresh={refreshTaskGroupEntity}
        />
      )}
    </>
  );
};

export default TaskOrderInfo;

import { useContext } from "react";
import { Box, Card, CardContent, CardHeader, LinearProgress, Typography } from "@mui/material";
import { OrderAccordian } from "./OrderAccordian";
import { OrderContext } from "../../contexts/OrderDataContext";
import { MatchedOldOrder } from "./MatchedOldOrder";
import { MatchedOrder } from "./MatchedOrder";

export default function MatchedOrders() {
  const { matchedOrders, matchedOldOrders, matchedOrdersIsLoading, relatedOrders } = useContext(OrderContext);

  const isImportedOrder = (orderNumber: string) => relatedOrders.some((o) => o.fileNumber === orderNumber);
  const sortedMatchedOldOrders = [...matchedOldOrders].filter(o => Boolean(o)).sort((a, b) => a.orderNumber.localeCompare(b.orderNumber));
  const sortedMatchedOrders = [...matchedOrders].filter(o => Boolean(o)).sort((a, b) => a.number.localeCompare(b.number));
  const relatedOrdersElement = document.getElementById("relatedOrders");

  const renderOldOrders = (orders: MatchedOldOrder[]) =>
    orders.map((order, index) => {
      const imported = isImportedOrder(order.orderNumber);
      const expanded = index === orders.length - 1;

      return (
        <OrderAccordian
          matchedOldOrder={order}
          imported={imported}
          relatedOrdersElement={relatedOrdersElement}
          isOldOrder={true}
          expanded={expanded}
        />
      );
    });

  const renderNewOrders = (orders: MatchedOrder[]) =>
    orders.map((order, index) => {
      const imported = isImportedOrder(order.number);
      const expanded = index === orders.length - 1;

      return (
        <OrderAccordian
          order={order}
          imported={imported}
          relatedOrdersElement={relatedOrdersElement}
          isOldOrder={false}
          expanded={expanded}
        />
      );
    });

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader title="Matched Orders" />
        <CardContent>
          {renderOldOrders(sortedMatchedOldOrders)}
          {renderNewOrders(sortedMatchedOrders)}
          {!sortedMatchedOrders.length && !sortedMatchedOldOrders.length && <Typography>No matched orders</Typography>}
        </CardContent>
        {matchedOrdersIsLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Card>
    </>
  );
}

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BlackListResult } from './types';

interface IProps {
  data: BlackListResult[];
}
export default function ResultList({ data }: IProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Information</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Underwriter</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Bulletin Date</TableCell>
            <TableCell>Bulletin Number</TableCell>
            <TableCell>Bulletin Location 1</TableCell>
            <TableCell>Bulletin Location 2</TableCell>
            <TableCell>Bulletin Location 3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.fieldName}</TableCell>
              <TableCell>{row.information}</TableCell>
              <TableCell>{row.score}</TableCell>
              <TableCell>{row.underwriter}</TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{row.notes}</TableCell>
              <TableCell>{row.bulletinDate}</TableCell>
              <TableCell>{row.bulletinNum}</TableCell>
              <TableCell>
                <a target='_blank' referrerPolicy='no-referrer' href={`${row.bulletinLocation}`}>
                  {row.bulletinLocation}
                </a>
              </TableCell>
              <TableCell>
                <a target='_blank' referrerPolicy='no-referrer' href={`${row.bulletinLocation2}`}>
                  {row.bulletinLocation2}
                </a>
              </TableCell>
              <TableCell>
                <a target='_blank' referrerPolicy='no-referrer' href={`${row.bulletinLocation3}`}>
                  {row.bulletinLocation3}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { ReactNode, createContext, useEffect, useState } from "react";
import axios from "axios";
import { Company } from "../types";
import { Updater, useImmer } from "use-immer";

// ----------------------------------------------------------------------

interface ICompanyContext {
  company: Company | undefined;
  setCompany: Updater<Company | undefined>;
  isFetching: boolean;
}

const CompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

type CompanyProviderProps = {
  children: ReactNode;
};

function CompanyProvider({ children }: CompanyProviderProps) {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const lookupcode = searchParams.get("lookupcode");
  const [company, setCompany] = useImmer<Company | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(true);

  const getAndSetCompany = () => {
    setIsFetching(true);
    axios.get(`/api/clientphonebook/companies/get?id=${id || ""}&lookupcode=${lookupcode || ""}`).then((response) => {
      setCompany(response.data);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (id || lookupcode) {
      getAndSetCompany();
    }
  }, [id, lookupcode]);

  return <CompanyContext.Provider value={{ company, setCompany, isFetching }}>{children}</CompanyContext.Provider>;
}

export { CompanyProvider, CompanyContext };

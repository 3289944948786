import { Autocomplete, TextField, debounce } from "@mui/material";
import { useCallback, useState } from "react";
import axios from "axios";
import { Updater } from "use-immer";
import { InternalUser } from "../../../types/app";

interface IProps {
  index: number;
  editedRep: InternalUser;
  editedReps: InternalUser[];
  setEditedReps: Updater<InternalUser[]>;
  handleRepChange: (rep: InternalUser, index: number) => void;
}

export default function SalesRepAutocomplete({ index, editedRep, editedReps, handleRepChange, setEditedReps }: IProps) {
  const [saleRepList, setSaleRepList] = useState<InternalUser[]>([]);
  const [value, setValue] = useState<InternalUser | null>(editedRep || null);

  const searchSaleReps = async (searchText: string) => {
    if (searchText) {
      const { data } = await axios.get(`/api/clientphonebook/internalUsers/searchSalesReps?searchText=${searchText}`);
      setSaleRepList(data);
    } else {
      setSaleRepList([]);
    }
  };
  const debouncedOnSearchSalesRepChange = useCallback(debounce(searchSaleReps, 200), []);

  const clearRep = () => {
    setEditedReps(editedReps.filter((d) => d.id !== editedRep.id));
    setValue(null);
  };

  return (
    <Autocomplete
      fullWidth
      value={value}
      options={saleRepList}
      onChange={(_, option) => {
        setValue(option);
        option ? handleRepChange(option, index) : clearRep();
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.fullName}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password"
          }}
          fullWidth
          label={`Sales Rep #${index + 1}`}
          onChange={async (e) => {
            if (!e.target.value) {
              clearRep();
            }
            await debouncedOnSearchSalesRepChange(e.target.value);
          }}
        />
      )}
      getOptionLabel={(option) => (option?.firstName || "") + " " + (option?.lastName || "")}
    />
  );
}

import { Phone } from "../../types";
import getCountryCodeForRegion, { parsePhoneNumber } from "libphonenumber-js";

export const formatPhone = (phone: Phone) => {
  const cleanedPhoneNumber = phone.number.replace(/\D/g, "");
  try {
    const countryCode = getCountryCodeForRegion(phone.countryCode);
    const phoneNumber = parsePhoneNumber(cleanedPhoneNumber, countryCode?.country || "US");
    return phoneNumber.formatNational();
  } catch (error) {
    return phone.number;
  }
};

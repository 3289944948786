import { TypeSpecimenRounded } from "@mui/icons-material";
import { Alert, Box, Card, CardContent, Checkbox, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import { Note, Phone, Role } from "../../types";
import { useState } from "react";
export default function DiscrepancyCardContent({
  onMouseOver,
  type,
  firstContactValue,
  secondContactValue,
  initialContact,
  handleFirstContactChange,
  handleSecondContactChange,
  firstContactCompanyName,
  secondContactCompanyName,
  isLocationDiscrepancy,
  isRoleDiscrepancy,
  firstContactRole,
  secondContactRole
}: {
  onMouseOver?: () => void;
  type: any;
  firstContactValue: any;
  secondContactValue: any;
  initialContact: any;
  handleFirstContactChange: any;
  handleSecondContactChange: any;
  firstContactCompanyName?: any;
  secondContactCompanyName?: any;
  isLocationDiscrepancy?: boolean;
  isRoleDiscrepancy?: boolean;
  firstContactRole?: string;
  secondContactRole?: string;
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };
  const getSecondContactTextToCopy = () => {
    if (isLocationDiscrepancy) {
      return secondContactCompanyName;
    } else if (isRoleDiscrepancy) {
      return secondContactRole;
    }
    return secondContactValue;
  };

  const getFirstContactTextToCopy = () => {
    if (isLocationDiscrepancy) {
      return firstContactCompanyName;
    } else if (isRoleDiscrepancy) {
      return firstContactRole;
    }
    return firstContactValue;
  };
  return (
    <>
      <Card
        sx={{
          p: 2,
          mb: 3,
          top: 58,
          maxHeight: "100vh",
          overflowY: "scroll"
        }}
      >
        <Stack spacing={2}>
          <CardContent onMouseOver={onMouseOver}>
            <Typography variant="h6" marginBottom={3}>
              {type}
            </Typography>

            <Stack direction="row" marginBottom={2} spacing={1} alignItems="center">
              <Box>
                {initialContact === firstContactValue ? (
                  <Checkbox checked={true} />
                ) : (
                  <IconButton
                    color="primary"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                      handleFirstContactChange();
                    }}
                  >
                    <Iconify icon="eva:plus-fill" />
                  </IconButton>
                )}
              </Box>
              {!isLocationDiscrepancy && !isRoleDiscrepancy && (
                <Typography variant="body2" fontSize={13} color="primary">
                  {firstContactValue as string}
                </Typography>
              )}
              {isLocationDiscrepancy && (
                <Box>
                  <Typography variant="subtitle2" color="primary">
                    {firstContactCompanyName}
                  </Typography>
                  <Typography variant="body2" fontSize={13} color="primary">
                    {firstContactValue as string}
                  </Typography>
                </Box>
              )}
              {isRoleDiscrepancy && (
                <Typography variant="body2" fontSize={13} color="primary">
                  {firstContactRole as string}
                </Typography>
              )}
              <Box>
                <IconButton color="primary" onClick={() => copyToClipboard(getFirstContactTextToCopy())}>
                  <Iconify icon="eva:copy-outline" />
                </IconButton>
              </Box>
            </Stack>

            <Stack direction="row" marginBottom={2} spacing={1} alignItems="center">
              <Box>
                {initialContact === secondContactValue ? (
                  <Checkbox checked={true} />
                ) : (
                  <IconButton
                    color="primary"
                    sx={{ borderRadius: "4px" }}
                    onClick={(e) => {
                      handleSecondContactChange();
                    }}
                  >
                    <Iconify icon="eva:plus-fill" />
                  </IconButton>
                )}
              </Box>
              {!isLocationDiscrepancy && !isRoleDiscrepancy && (
                <Typography variant="body2" fontSize={13} color="primary">
                  {secondContactValue as string}
                </Typography>
              )}
              {isLocationDiscrepancy && (
                <Box>
                  <Typography variant="subtitle2" color="primary">
                    {secondContactCompanyName}
                  </Typography>
                  <Typography variant="body2" fontSize={13} color="primary">
                    {secondContactValue as string}
                  </Typography>
                </Box>
              )}
              {isRoleDiscrepancy && (
                <Typography variant="body2" fontSize={13} color="primary">
                  {secondContactRole as string}
                </Typography>
              )}
              <Box>
                <IconButton color="primary" onClick={() => copyToClipboard(getSecondContactTextToCopy())}>
                  <Iconify icon="eva:copy-outline" />
                </IconButton>
              </Box>
            </Stack>
          </CardContent>
        </Stack>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="success" sx={{ width: 200 }}>
          Copied!
        </Alert>
      </Snackbar>
    </>
  );
}

import { Breadcrumbs, Link } from "@mui/material";

interface IProps {
  corpDocType: string;
}

export default function TaskBreadcrumbs({ corpDocType }: IProps) {
  return (
    <Breadcrumbs separator="•" aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
      <Link color="inherit">Orders</Link>
      <Link color="inherit">Corp Docs</Link>
      <Link color="text.primary">{corpDocType}</Link>
    </Breadcrumbs>
  );
}

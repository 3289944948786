import { useContext, useState } from "react";
import AddEditCompanyToPhoneBook from "../contact/addEditContact/AddEditCompanyToPhoneBook";
import { Company } from "../types";
import axios from "axios";
import { AddEditCompanyContext } from "./addEditCompany/AddEditCompanyContext";
import DuplicateAlert from "../shared/create/DuplicateAlert";
import { Box, Grid, Stack } from "@mui/material";

export default function AddCompanyPage() {
  const { company } = useContext(AddEditCompanyContext);
  const [duplicateCompanyList, setDuplicateCompanyList] = useState<Company[]>([]);

  const searchCompanyDuplicates = async () => {
    const { data } = await axios.post("/api/clientphonebook/companies/findMatching", {
      id: company.id,
      name: company.name || "",
      website: company.website || "",
      phone: company.branches[0]?.phone || "",
      email: company.branches[0]?.email || "",
      fax: company.branches[0]?.fax || "",
      addresses: [
        {
          address1: company.branches[0]?.address1 || "",
          state: company.branches[0]?.state || ""
        }
      ]
    });
    const duplicateCompanies =
      data?.map(
        (d: any) =>
          ({
            ...d.company,
            branches: [d]
          } as Company)
      ) || [];
    setDuplicateCompanyList(duplicateCompanies);
  };
  return (
    <Stack direction="row" justifyContent="center">
      <Box maxWidth={900}>
        <AddEditCompanyToPhoneBook searchCompanyDuplicates={searchCompanyDuplicates} isPartOfOwnForm />
        {duplicateCompanyList?.length > 0 && (
          <Grid item xs={12} marginBottom={5}>
            <DuplicateAlert handleDismiss={() => {}} />
          </Grid>
        )}
      </Box>
    </Stack>
  );
}

import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Stack,
  Autocomplete,
  IconButton,
  InputAdornment,
} from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState, useContext } from 'react';
import { LedgerTransfer, TransactionHistory } from './types';
import { historyColumns } from './HistoryColumns';
import { CloseIcon } from '../../../minimals/theme/overrides/CustomIcons';
import produce from 'immer';
import { NumberFormatCustom } from '../NumberFormat';
import useDebounce from '../../../hooks/useDebounce';
import { debounce } from 'lodash';
import {UserContext} from "../../contexts/UserContext";

const LedgerTransferModal = ({
  open,
  closeModal,
  orderNumber,
  handleRefreshList,
  handleRefreshBalance,
  ledgerBalance,   
}: {
  open: boolean;
  closeModal: () => void;
  orderNumber: any;
  handleRefreshList: () => void;
  handleRefreshBalance: () => void;
  ledgerBalance: number
}) => {
  const [ledgerTransfer, setLedgerTransfer] = useState<LedgerTransfer>({
    sourceLedger: orderNumber,
    destinationLedger: '',
    payeeContactCode: '',
    payeeContactName: '',
    memo: '',
    amount: '',
  });
  const [orderContacts, setOrderContacts] = useState([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const {user} = useContext(UserContext);

  
  
  const setLedgerTransferInfo = (name: keyof LedgerTransfer, value: any) => {
    const newInfo = produce(ledgerTransfer, (draft: any) => {
      draft[name] = value;
    });
    setLedgerTransfer(newInfo);
  };
  const handleTransfer = async () => {
    setSubmitting(true);
    try {
      await axios.post(
        '/proxy/api/fundstransfer/CreateOutgoingLedgerTransfer',
          {...ledgerTransfer, username: user?.username}
      );
      closeModal();
      handleRefreshBalance();
      handleRefreshList();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const getOrderContacts = async () => {
    const { data } = await axios.get(
      `/proxy/api/orders/GetOrderContacts?orderNumber=${orderNumber}`
    );
    setOrderContacts(data);
  };
  const getDestinationLedgers = async (value: string) => {
    setLoading(true)
    const { data } = await axios.get(
      `/proxy/api/ledgers/SearchLedgersInSameTrustAccount?ordernumber=${orderNumber}&searchtext=${value}`
    );
    setOptions(data);
    setLoading(false)
  };
  const debouncedGetDestinationLedgers = useMemo(
    () => debounce((value) => getDestinationLedgers(value), 500),
    []
  );

  useEffect(() => {
    getOrderContacts();
  }, []);

  const isValidTransferAmount = (() => {

    function isValidNumber(str: string) {
      const num = parseFloat(str);
      return !isNaN(num) && isFinite(num) && str.trim() !== "";
    }
    const {amount} = ledgerTransfer;
    if(amount === '') {
      return true;
    }
    if(!isValidNumber(amount)) {
      return false;
    }
    return Number(amount) <= ledgerBalance;

  })();

  
  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Typography variant='h4'>Outgoing Ledger Transfer (-)</Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <IconButton
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box paddingTop={1}>
          <Stack direction='row' spacing={2} justifyContent='space-between'>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Destination Ledger'
                  size='small'
                  fullWidth
                  onChange={(e) =>
                    debouncedGetDestinationLedgers(e.target.value)
                  }
                />
              )}
              options={options}
              getOptionLabel={(option: any) => option}
              onChange={(_, value) =>
                setLedgerTransferInfo('destinationLedger', value)
              }
              fullWidth
              loading={loading}
            />
            <TextField
              label='Amount'
              size='small'
              value={ledgerTransfer?.amount}
              onChange={(e) => {
                setLedgerTransferInfo('amount', e.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </Stack>
          <Stack direction='column' spacing={2} marginTop={2}>
            <Autocomplete
              renderInput={(params) => (
                <TextField {...params} size='small' fullWidth label='Payee' />
              )}
              options={orderContacts}
              getOptionLabel={(option: any) => `${option.name} (${option.type})`}
              onChange={(_, value) => {
                setLedgerTransferInfo('payeeContactCode', value?.id);
                setLedgerTransferInfo('payeeContactName', value?.name);
              }
              } 
              freeSolo
            />
            <TextField
              label='Memo'
              size='small'
              fullWidth
              value={ledgerTransfer?.memo}
              onChange={(e) => {
                setLedgerTransferInfo('memo', e.target.value);
              }}
            />
          </Stack>
        </Box>
        <Typography marginTop={3} marginBottom={3}>
          All ledger transfers are “unapplied.”{' '}
        </Typography>

        {!isValidTransferAmount && <Typography
            marginTop={3}
            marginBottom={3}
            sx={{ color: 'error.main' }}
        >
          There are insufficient funds for this transaction.
        </Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={closeModal} color='inherit'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={handleTransfer}
          disabled={submitting || !isValidTransferAmount}
        >
          {submitting ? 'Transferring....' : 'Transfer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LedgerTransferModal;

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Typography, DialogContent } from "@mui/material";

const AlertSyncingWithSelectRefresh = ({ open }: { open: boolean }) => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (countdown <= 0) {
      window.location.reload();
    }

    const timer = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Dialog open={open} onClose={() => null} fullWidth maxWidth="md">
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="subtitle1" marginBottom={3}>
          Important Update Required!
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3, textAlign: "center" }}>
        <Typography>We synced new select changes.</Typography>
        <Typography>
          To ensure you have the latest information, this application will refresh in
          <Typography variant="subtitle1" display="inline-block" marginLeft={1}>
            {countdown} seconds.
          </Typography>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AlertSyncingWithSelectRefresh;

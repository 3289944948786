import { Dialog, Container, IconButton } from "@mui/material";
import EditContact from "../components/EditContact";
import { CloseIcon } from "../../minimals/theme/overrides/CustomIcons";
import { Contact } from "./types/Contact";

interface iProps {
  contact: Contact | null;
  editProfileModal: boolean;
  onClose: () => void;
}

export default function EditProfilePreviewCard(props: iProps) {
  const { contact, onClose } = props;

  return (
    <Dialog
      open={props.editProfileModal}
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": { maxWidth: 1000, width: "100%" },
        ".MuiContainer-root": { maxHeight: "90vh" }
      }}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Container sx={{ width: "100%" }}>
        <EditContact lookupCode={contact?.lookupCode} />
      </Container>
    </Dialog>
  );
}

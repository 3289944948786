export const getPhoneIcon = (type: string) => {
  switch (type) {
    case "Business":
      return "entypo:old-phone";
    case "Mobile":
      return "ic:outline-phone-iphone";
    case "Fax":
      return "fa:fax";
    default:
      return "eva:phone-fill";
  }
};

import { Button, Grid, Stack, Switch } from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import AccountReps from "./AccountReps";
import AdditionalPreferences from "./AdditionalPreferences";
import NotificationPreferences from "./NotificationPreferences";
import PaymentPreferences from "./PaymentPreferences";
import TitleAgent from "./TitleAgent";
import Underwriters from "./Underwriters";
import { useContext, useState } from "react";
import EditAccountReps from "../editPreferences/EditAccountReps";
import EditPaymentPreferences from "../editPreferences/EditPaymentPreferences";
import EditUnderwriters from "../editPreferences/EditUnderwriters";
import EditTitleAgents from "../editPreferences/EditTitleAgents";
import EditNotificationPreferences from "../editPreferences/EditNotificationPreferences";
import EditAdditionalPreferences from "../editPreferences/EditAdditionalPreferences";
import SalesReps from "./SalesReps";
import EditSalesReps from "../editPreferences/EditSalesReps";
import { ContactContext } from "../../contact/ContactContext";
import NoteCard from "../profile/NoteCard";
import LookupCodeCard from "./LookupCodeCard";
import DebugMenu from "./DebugMenu";
import DeactivateDialog from "./DeactivateDialog";
import { Contact, Note } from "../../types";
import axios from "axios";
import useDebugShortcut from "../../../utils/useDebugShortcut";
import { CompanyContext } from "../../company/CompanyContext";
import ProfilePostInput from "../../shared/profile/NoteInput";
import EditClientCultivator from "../editPreferences/EditClientCultivator";
import ClientCultivators from "./ClientCultivators";
import { UserContext } from "../../../contexts/UserContext";

export default function Preferences() {
  const { contact, setContact } = useContext(ContactContext);
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);

  const [editSalesRepsMode, setEditSalesRepsMode] = useState<boolean>(false);
  const [editAccountRepsMode, setEditAccountRepsMode] = useState<boolean>(false);
  const [editClientCultivator, setEditClientCultivator] = useState<boolean>(false);
  const [editPaymentPreferencesMode, setEditPaymentPreferencesMode] = useState<boolean>(false);
  const [editUnderwritersMode, setEditUnderwritersMode] = useState<boolean>(false);
  const [editNotificationPreferencesMode, setEditNotificationPreferencesMode] = useState<boolean>(false);
  const [editTitleAgentMode, setEditTitleAgentMode] = useState<boolean>(false);
  const [editAdditionalPreferencesMode, setEditAdditionalPreferencesMode] = useState<boolean>(false);
  const [isInDebugMode, setIsInDebugMode] = useState(false);

  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const clientCultivatorSetters = [
    "agoldstein@madisoncres.com",
    "sdavidowitz@madisoncres.com",
    "clipschutz@madisoncres.com",
    "snierenberg@madisontitle.com",
    "jshapiro@madisontitle.com",
    "afriedman@madisoncres.com",
    "bfreylicher@madisoncres.com",
    "lbensoussan@madisoncres.com"
  ];
  const isClientCultivatorSetter = clientCultivatorSetters.includes(user?.email.toLowerCase() || "");

  useDebugShortcut(() => {
    setIsInDebugMode(true);
  });

  const handleActiveStatusUpdate = async (contactId: number, isActive: boolean) => {
    const { data } = await axios.post<Contact>(`/api/clientphonebook/contacts/updateActiveStatus`, {
      id: contactId,
      isActive
    });
    if (data) {
      setContact((draft) => {
        if (draft) {
          draft.isActive = data.isActive;
        }
      });
    }
  };
  const updateNotes = (note: Note) => {
    setContact((draft) => {
      if (draft) {
        draft.notes.unshift(note);
      }
    });
  };

  const saveNote = async (note: string) => {
    const { data } = await axios.post("/api/clientphonebook/contactNotes/add", {
      text: note,
      contactId: contact?.id,
      isClientInstructions: true
    });
    updateNotes(data);
  };

  const clientInstructions = contact?.notes.filter((n) => n.isClientInstructions) || [];

  const displayClientCultivatorInfo = () => {
    if (!isClientCultivatorSetter) return;

    if (editClientCultivator) {
      return <EditClientCultivator setEditMode={() => setEditClientCultivator((prevState) => !prevState)} />;
    }
    return <ClientCultivators setEditMode={() => setEditClientCultivator((prevState) => !prevState)} />;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {editAccountRepsMode ? (
            <EditAccountReps setEditMode={() => setEditAccountRepsMode((prevState) => !prevState)} />
          ) : (
            <AccountReps setEditMode={() => setEditAccountRepsMode((prevState) => !prevState)} />
          )}
          {editSalesRepsMode ? (
            <EditSalesReps setEditMode={() => setEditSalesRepsMode((prevState) => !prevState)} />
          ) : (
            <SalesReps setEditMode={() => setEditSalesRepsMode((prevState) => !prevState)} />
          )}

          {displayClientCultivatorInfo()}

          <LookupCodeCard lookupCode={contact?.lookupCode || ""} />
          {isInDebugMode && contact && <DebugMenu contactId={contact.id} />}
          {/* {editUnderwritersMode ? (
            <EditUnderwriters
              setEditMode={() =>
                setEditUnderwritersMode((prevState) => !prevState)
              }
            />
          ) : (
            <Underwriters
              setEditMode={() =>
                setEditUnderwritersMode((prevState) => !prevState)
              }
            />
          )}
          {editTitleAgentMode ? (
            <EditTitleAgents
              setEditMode={() =>
                setEditTitleAgentMode((prevState) => !prevState)
              }
            />
          ) : (
            <TitleAgent
              setEditMode={() =>
                setEditTitleAgentMode((prevState) => !prevState)
              }
            />
          )}
          <Button
            startIcon={<Iconify icon="eva:plus-fill" />}
            variant="contained"
            color="error"
            sx={{ width: 165, alignSelf: "center" }}
          >
            Add to blacklist
          </Button> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {/* ------------- Client Instructions ------------- */}
          {clientInstructions.map((n) => (
            <NoteCard showClientInstructionsHeader={true} note={n} companyId={company?.id} contactId={contact?.id} />
          ))}
          {clientInstructions.length === 0 && (
            <ProfilePostInput showClientInstructionsHeader={true} saveNote={saveNote} updateNotes={updateNotes} />
          )}
          {/* {editPaymentPreferencesMode ? (
            <EditPaymentPreferences
              setEditMode={() =>
                setEditPaymentPreferencesMode((prevState) => !prevState)
              }
            />
          ) : (
            <PaymentPreferences
              setEditMode={() =>
                setEditPaymentPreferencesMode((prevState) => !prevState)
              }
            />
          )}
          {editNotificationPreferencesMode ? (
            <EditNotificationPreferences
              setEditMode={() =>
                setEditNotificationPreferencesMode((prevState) => !prevState)
              }
            />
          ) : (
            <NotificationPreferences
              setEditMode={() =>
                setEditNotificationPreferencesMode((prevState) => !prevState)
              }
            />
          )}
          {editAdditionalPreferencesMode ? (
            <EditAdditionalPreferences
              setEditMode={() =>
                setEditAdditionalPreferencesMode((prevState) => !prevState)
              }
            />
          ) : (
            <AdditionalPreferences
              setEditMode={() =>
                setEditAdditionalPreferencesMode((prevState) => !prevState)
              }
            />
          )} */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack spacing={3} alignItems="flex-end">
          <DeactivateDialog entityType="Contact" entity={contact} updateActiveStatus={handleActiveStatusUpdate} />
        </Stack>
      </Grid>
    </Grid>
  );
}

import { Autocomplete, Button, Grid, IconButton, Stack, TextField, debounce } from "@mui/material";
import { useContext, useState } from "react";
import { ContactRep } from "../../types";
import { AddEditContactContext } from "./AddEditContactContext";
import Iconify from "../../../../minimals/components/iconify";
import axios from "axios";
import { InternalUser } from "../../../types/app";

interface IProps {
  keyProp: string;
  index: number;
  indexOfList: number;
}

export default function RepForm(props: IProps) {
  const { index, keyProp, indexOfList } = props;
  const { contact, setContact, mergingContacts } = useContext(AddEditContactContext);
  const [saleRepList, setSaleRepList] = useState<InternalUser[]>([]);

  const searchSaleReps = debounce(async (searchText: string) => {
    if (searchText) {
      const { data } = await axios.get(`/api/clientphonebook/internalUsers/searchSalesReps?searchText=${searchText}`);
      setSaleRepList(data);
    } else {
      setSaleRepList([]);
    }
  }, 200);

  const handleAddRep = () => {
    setContact((draft) => {
      draft.contactReps.push({
        contactRepTypeId: 1,
        key: crypto.randomUUID()
      } as unknown as ContactRep);
    });
  };

  const removeRep = () => {
    setContact((draft) => {
      draft.contactReps = draft.contactReps.filter((_, i) => i != indexOfList);
    });
  };

  const handleSalesRepChange = (contactRep: InternalUser) => {
    setContact((draft) => {
      draft.contactReps[indexOfList].internalUserId = contactRep.id;
      draft.contactReps[indexOfList].internalUser = contactRep;
    });
  };

  const showAddButton =
    contact.contactReps.filter((cr) => cr.contactRepTypeId === 1).length < 3 &&
    contact.contactReps.filter((cr) => cr.contactRepTypeId === 1).length - 1 === index;

  const showDeleteButton = contact.contactReps.length > 1;
  const salesRepValue = contact.contactReps[indexOfList]?.internalUser;
  // .filter((cr) => cr.contactRepTypeId === 1)
  // .find(
  //   (rep, index) => rep.contactRepTypeId === 1 && index === props.index
  // )?.internalUser;

  return (
    <Grid container spacing={2} alignItems="center" key={keyProp}>
      <Grid item xs={5}>
        <Autocomplete
          value={salesRepValue}
          onChange={(_, option) => (option ? handleSalesRepChange(option) : null)}
          options={saleRepList}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password"
              }}
              fullWidth
              label={`Sales Rep #${index + 1}`}
              onChange={(e) => searchSaleReps(e.target.value)}
            />
          )}
          getOptionLabel={(option) => (option.firstName || "") + " " + (option.lastName || "")}
        />
      </Grid>
      <Grid item>
        <Stack direction="row" justifyContent="flex-start">
          {showDeleteButton && (
            <IconButton color="error" onClick={() => removeRep()}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          )}
          {showAddButton && <Button onClick={handleAddRep}>+ Sales Rep</Button>}
        </Stack>
      </Grid>
    </Grid>
  );
}

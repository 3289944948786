import axios from "axios";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { IOption } from "../@types/app";
import { InternalUser } from "../types/app";

interface InternalUserContextType {
  internalUsers: InternalUser[];
  internalUserOptions: IOption[];
  accountRepOptions: IOption[];
  salesRepOptions: IOption[];
}

const InternalUserContext = createContext<InternalUserContextType>({} as InternalUserContextType);
function InternalUsersProvider({ children }: { children: ReactNode }) {
  const [internalUsers, setInternalUsers] = useState<InternalUser[]>([]);
  const [internalUserOptions, setInternalUserOptions] = useState<IOption[]>([]);
  const [salesRepOptions, setSalesRepOptions] = useState<IOption[]>([]);
  const [accountRepOptions, setAccountRepOptions] = useState<IOption[]>([]);

  useEffect(() => {
    const fetchInternalUsers = async () => {
      const { data } = await axios.get<InternalUser[]>("/api/clientphonebook/internalUsers/getAll");
      setInternalUsers(data);

      const formatUserOption = (user: InternalUser) => ({ label: user.fullName, value: user.selectId });

      const internalUserOptions = data.map(formatUserOption);
      setInternalUserOptions(internalUserOptions);

      const accountReps = data.filter((user) => user.isActiveSelectUser && user.username).map(formatUserOption);
      setAccountRepOptions(accountReps);

      const salesReps = data
        .filter((user) => user.isActiveSalesRep && user.lookupCode)
        .map((user) => ({
          label: user.fullName,
          value: user.id.toString()
        }));
      setSalesRepOptions(salesReps);
    };

    const getInitialData = async () => {
      try {
        fetchInternalUsers();
      } catch (error) {
        console.error("Failed to fetch initial data:", error);
      }
    };

    getInitialData();
  }, []);

  return (
    <InternalUserContext.Provider
      value={{
        internalUsers,
        internalUserOptions,
        accountRepOptions,
        salesRepOptions
      }}
    >
      {children}
    </InternalUserContext.Provider>
  );
}

const useInternalUsers = () => useContext(InternalUserContext);

export { InternalUsersProvider, useInternalUsers };

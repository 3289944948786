import { Grid, Typography } from "@mui/material";
import { Property } from "./types/Property";

export default function Address({
  address1,
  address2,
  city,
  state,
  county,
  zip,
  propertyUse,
  section,
  block,
  lots,
  parcelIds
}: Property) {
  return (
    <>
      <Typography>{address1}</Typography>
      <Typography>{address2}</Typography>
      <Typography>
        {city}
        {city && state && ","} {state} {zip}
      </Typography>
      <Grid container marginTop={1}>
        {county && (
          <Grid item xs={12}>
            <Typography variant="caption">County: </Typography>
            <Typography>{county}</Typography>
          </Grid>
        )}
        {propertyUse && (
          <Grid item xs={12}>
            <Typography variant="caption">Property Use: </Typography>
            <Typography>{propertyUse}</Typography>
          </Grid>
        )}
        {section && (
          <Grid item xs={4}>
            <Typography variant="caption">Section: </Typography>
            <Typography>{section}</Typography>
          </Grid>
        )}
        {block && (
          <Grid item xs={4}>
            <Typography variant="caption">Block: </Typography>
            <Typography>{block}</Typography>
          </Grid>
        )}
        {lots.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="caption">Lot(s): </Typography>
            <Typography>{lots.join(", ")}</Typography>
          </Grid>
        )}
        {parcelIds.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="caption">Parcel Id(s): </Typography>
            <Typography>{parcelIds.join(", ")}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { generateLabel } from "./generateLabel";
import { TABS } from "./TABS";

export default function () {
  const [selectedFilter, setSelectedFilter] = useState("All");
  return (
    <Box sx={{ paddingLeft: 2 }}>
      <Tabs value={selectedFilter} onChange={(e, value) => setSelectedFilter(value)}>
        {TABS.map((t) => (
          <Tab
            value={t.value}
            label={
              <>
                <Typography variant="subtitle2" paddingRight={1}>
                  {t.value}
                </Typography>
                {generateLabel(t.value, t.count)}
              </>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}

import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  InputAdornment,
  Stack
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { FlipSpreadInfo, TaskGroupEntity } from "../types";
import { useOrderInfo } from "../../shared/orderInfo/OrderInfoContext";
import { fCurrency } from "../../utils/formatNumber";

const AddEditSpreadModal = ({
  open,
  closeModal,
  taskGroupEntity,
  flipSpreadInfo
}: {
  open: boolean;
  closeModal: () => void;
  taskGroupEntity: TaskGroupEntity;
  flipSpreadInfo: FlipSpreadInfo | null;
}) => {
  const orderInfo = useOrderInfo();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [intermediatePrice, setIntermediatePrice] = useState<number | undefined>(
    flipSpreadInfo?.intermediatePrice || orderInfo?.salesPrice
  );
  const [endBuyerPrice, setEndBuyerPrice] = useState<number | undefined>(flipSpreadInfo?.endBuyerPrice);

  const percentage =
    intermediatePrice && endBuyerPrice ? ((endBuyerPrice - intermediatePrice) / intermediatePrice) * 100 : undefined;

  const save = async () => {
    setSubmitting(true);
    await axios.post("/api/taskmanagement/flips/setSpread", {
      taskGroupEntityId: taskGroupEntity?.id,
      intermediatePrice,
      endBuyerPrice
    });
    closeModal();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3}>
          Set Spread Amount
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box paddingTop={1}>
          <Stack direction="column" spacing={3}>
            <TextField
              type="number"
              fullWidth
              label="Intermediate Price"
              value={intermediatePrice}
              onChange={(e) => setIntermediatePrice(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
            <TextField
              type="number"
              fullWidth
              label="End-Buyer Price"
              value={endBuyerPrice}
              onChange={(e) => setEndBuyerPrice(parseFloat(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
            <Box textAlign="right" display="flex" justifyItems="center" justifyContent="right">
              {/* calculation of percentage should be endprice divided by intermediate price with a percentage shown as well (1.28 should show as 28%) */}
              <Typography variant="body2" display="inline-block" marginRight={0.5}>
                Spread Amount =
              </Typography>
              <>
                <Typography variant="subtitle1" display="inline-block" marginRight={0.5}>
                  {intermediatePrice && endBuyerPrice ? fCurrency(endBuyerPrice - intermediatePrice) : "--"}
                </Typography>
                <Typography variant="subtitle1" display="inline-block">
                  ({percentage ? `${Math.round(percentage)}%` : "--"})
                </Typography>
              </>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} color="inherit">
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={save}>
          {submitting ? "Saving...." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditSpreadModal;

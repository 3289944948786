export const orders = [
  {
    id: 1,
    created: "11.02.2022",
    due: "01.26.2023",
    amount: "$779.58",
    status: "Unpaid"
  },
  {
    id: 2,
    created: "11.02.2022",
    due: "01.26.2023",
    amount: "$779.58",
    status: "Overdue"
  },
  {
    id: 3,
    created: "11.02.2022",
    due: "01.26.2023",
    amount: "$779.58",
    status: "Draft"
  },
  {
    id: 4,
    created: "11.02.2022",
    due: "01.26.2023",
    amount: "$779.58",
    status: "Paid"
  },
  {
    id: 5,
    created: "11.02.2022",
    due: "01.26.2023",
    amount: "$779.58",
    status: "Draft"
  }
];

import { useState } from "react";
import axios from "axios";
import { Delete } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, ListItemSecondaryAction } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IOption } from "../@types/app";
import { IClosedOrder } from "../types/calendar";
import { useCalendar } from "../contexts/CalendarContext";
import { logEvent } from "../utils/analyticsLogger";
import { getStatusColor, propertyTypeOptions, stateOptions, statusOptions } from "./CalendarUtils";
import FiltersPresetModal from "./FiltersPresetModal";
import DebouncedOrderSearch from "../components/DebouncedOrderSearch";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { useInternalUsers } from "../contexts/InternalUserContext";

interface Props {
  onGoToDate: (date: string) => void;
}

export default function CalendarFilters({ onGoToDate }: Props) {
  const {
    selectedPropertyType,
    setSelectedPropertyType,
    selectedStatuses,
    setSelectedStatuses,
    selectedStates,
    setSelectedStates,
    selectedAccountReps,
    setSelectedAccountReps,
    selectedSalesReps,
    setSelectedSalesReps,
    openViewOrderModal,
    showToastMessage,
    removeFromSavedFilterList,
    restoreSavedFilters,
    savedFilterList,
    selectedSavedFilterPreset,
    setSelectedSavedFilterPreset,
    clearSelectedPresetFilter
  } = useCalendar();

  const { salesRepOptions, internalUserOptions } = useInternalUsers();

  const [isNewPresetModalOpen, setIsNewPresetModalOpen] = useState(false);
  const [searchOrderValue, setSearchOrderValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleClick = ({
    selectedOption,
    selectedOptions,
    setSelectedOptions,
    label = ""
  }: {
    selectedOption: IOption;
    selectedOptions: IOption[];
    setSelectedOptions: (value: React.SetStateAction<any>) => void;
    label?: string;
  }) => {
    clearSelectedPresetFilter();
    if (selectedOptions.find((option) => option.value === selectedOption.value)) {
      setSelectedOptions(selectedOptions.filter((option) => option.value !== selectedOption.value));
      return;
    }
    const updatedSelectedOptions = [...selectedOptions, selectedOption];
    setSelectedOptions(updatedSelectedOptions);
    logEvent("Closing Calendar", "Filter 1", label || "");
  };

  const handleRepClick = async ({ selectedOption }: { selectedOption: IOption }) => {
    const { value } = selectedOption;
    clearSelectedPresetFilter();
    if (selectedAccountReps.find((option) => option.value === value)) {
      const updatedFilter = selectedAccountReps.filter((option) => option.value !== value);
      setSelectedAccountReps(updatedFilter);
      logEvent("Closing Calendar", "Filter 1", "Assigned To");
      return;
    }
    if (value == "anyfunders" || value == "noclosersassigned" || value == "nofundersassigned") {
      const updatedRepFilters = [...selectedAccountReps, { ...selectedOption, email: "N/A" }];
      setSelectedAccountReps(updatedRepFilters);
      logEvent("Closing Calendar", "Filter 1", "Assigned To");
      return;
    }

    const { data } = await axios.get(`proxy/api/contacts/GetSelectUserEmail?userId=${selectedOption.value}`);
    const updatedRepFilters = [...selectedAccountReps, { ...selectedOption, email: data?.email || "" }];
    setSelectedAccountReps(updatedRepFilters);
    logEvent("Closing Calendar", "Filter 1", "Assigned To");
  };

  const handleSalesRepClick = ({ selectedOption }: { selectedOption: IOption }) => {
    clearSelectedPresetFilter();
    const { value } = selectedOption;
    let updatedSalesRepsFilters = [];
    if (value) {
      updatedSalesRepsFilters = [...selectedSalesReps, selectedOption];
    } else {
      updatedSalesRepsFilters = selectedSalesReps.filter((r) => r.value !== value);
    }
    setSelectedSalesReps(updatedSalesRepsFilters);
    logEvent("Closing Calendar", "Filter 1", "Sales Reps");
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={2} position="absolute" right={220} width={"241px"} top={-82} zIndex={10000}>
          <FiltersPresetModal isOpen={isNewPresetModalOpen} handleClose={() => setIsNewPresetModalOpen(false)} />
          <DebouncedOrderSearch
            size="small"
            disabled={isSearching}
            value={isSearching ? "Searching..." : searchOrderValue} // I hate the way I did this too, but this component drills down to a highly used reusable component that would have been a hassle to change around
            onSelect={async (e: React.SyntheticEvent<Element, Event>, option: string | null, reason: string) => {
              setSearchOrderValue(option || "");
              if (option) {
                setIsSearching(true);
                const { data }: { data: IClosedOrder } = await axios.get(
                  `/api/calendar/getclosedorder?orderNumber=${option}`
                );
                setIsSearching(false);
                if (!data) {
                  showToastMessage({
                    message: "Something went wrong. No data found for this order",
                    severity: "error"
                  });
                  return;
                }
                if (!data.settlementDate) {
                  showToastMessage({
                    message: "This order has no settlement date",
                    severity: "error"
                  });
                  return;
                }

                onGoToDate(data.settlementDate || "");
                showToastMessage({
                  message: "Order may not be visible due to filters settings",
                  severity: "warning"
                });

                ////When they come back and see they want to see the order uncomment below
                // openViewOrderModal({ order: data as ICalendarEvent, mode: 'view' });
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <FormControl size="small" fullWidth>
          <InputLabel>Saved Filter Presets</InputLabel>
          <Select
            size="small"
            fullWidth
            label="Saved Filter Presets"
            value={""}
            // value={selectedSavedFilterPreset}
            // renderValue={() => selectedSavedFilterPreset}
          >
            <MenuItem
              value=""
              onClick={() => {
                setIsNewPresetModalOpen(true);
              }}
            >
              <em>-Add New Filter Preset-</em>
            </MenuItem>
            {Object.keys(savedFilterList).map((key) => (
              <MenuItem
                key={key}
                value={key}
                onClick={() => {
                  restoreSavedFilters(key);
                  setSelectedSavedFilterPreset(key);
                }}
              >
                <div
                  style={{
                    maxWidth: 200,
                    marginRight: "40px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {key}
                </div>
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFromSavedFilterList(key);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl size="small" fullWidth>
          <InputLabel>Prop. Type</InputLabel>
          <Select
            value={selectedPropertyType}
            label="Property Type"
            onChange={(event: SelectChangeEvent) => {
              const value = event.target.value;
              clearSelectedPresetFilter();
              setSelectedPropertyType(value);
              logEvent("Closing Calendar", "Filter 1", "Propery Type");
            }}
          >
            <MenuItem value="">
              <em>-All-</em>
            </MenuItem>
            {propertyTypeOptions.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <CustomAutocomplete
          options={stateOptions}
          selectedOptions={selectedStates}
          setSelectedOptions={setSelectedStates}
          id="states"
          label="States"
          onlyShowOptionsOnTyping
          handleSelect={handleClick}
          multiple
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutocomplete
          options={statusOptions}
          selectedOptions={selectedStatuses}
          setSelectedOptions={setSelectedStatuses}
          label="Status"
          getMenuItemStyles={getStatusColor}
          id="statuses"
          handleSelect={handleClick}
          multiple
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutocomplete
          options={salesRepOptions}
          selectedOptions={selectedSalesReps}
          setSelectedOptions={setSelectedSalesReps}
          handleSelect={handleSalesRepClick}
          id="sales-reps"
          label="Sales Reps"
          onlyShowOptionsOnTyping
          multiple
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutocomplete
          options={internalUserOptions}
          selectedOptions={
            selectedAccountReps
          } /* Selected Account Reps is terrible name for this as it also represents selected funders*/
          setSelectedOptions={setSelectedAccountReps}
          handleSelect={handleRepClick}
          id="account-reps"
          label="Assigned to"
          onlyShowOptionsOnTyping
          defaultOptions={[
            { label: "Any Funders", value: "anyfunders" },
            { label: "No Closer Assigned", value: "noclosersassigned" },
            { label: "No Funder Assigned", value: "nofundersassigned" }
          ]}
          multiple
        />
      </Grid>
    </Grid>
  );
}

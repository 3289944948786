import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../../minimals/theme/overrides/CustomIcons";
import axios from "axios";
import { BasicTask } from "../types";
import InternalUserAutocomplete from "../../shared/InternalUserAutocomplete";
import { InternalUser } from "../../types/app";

export default function AssignmentModal({
  selectedTask,
  setSelectedTask,
  updateTasks
}: {
  selectedTask: BasicTask | null;
  setSelectedTask: (value: any) => void;
  updateTasks: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<InternalUser | null>({
    email: selectedTask?.assignedToEmail,
    fullName: selectedTask?.assignedToName
  } as InternalUser);

  const saveAssignTo = async (user: InternalUser | null) => {
    if (!user || user.email?.toLowerCase() == selectedTask?.assignedToEmail?.toLowerCase()) return;
    const payload = {
      taskId: selectedTask?.id,
      name: user.fullName,
      email: user.email.toLowerCase()
    };
    //revise route to be dynamic
    await axios.post("/api/taskmanagement/tasks/assignTo", payload);
  };

  const save = async () => {
    if (selectedAssignedTo && selectedTask) {
      setSubmitting(true);
      await saveAssignTo(selectedAssignedTo);
      updateTasks();
      closeModal();
      setSubmitting(false);
    }
  };

  const closeModal = () => {
    setSelectedTask(null);
  };

  const open = !!selectedTask;

  useEffect(() => {
    const fetchAssignedToUser = async () => {
      if (selectedTask?.assignedToEmail) {
        const { data } = await axios.get(
          `/api/clientphonebook/internalusers/getInternalUserByEmail?email=${selectedTask.assignedToEmail}`
        );
        if (data) {
          setSelectedAssignedTo(data);
        }
      }
    };
    fetchAssignedToUser();
  }, []);

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3}>
          {selectedTask?.description}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <InternalUserAutocomplete
            value={selectedAssignedTo}
            onChange={(val) => {
              setSelectedAssignedTo(val as InternalUser | null);
            }}
            label="Assigned to"
            size="medium"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} color="inherit">
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={save}>
          {submitting ? "Saving...." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

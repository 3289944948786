import { Badge, BadgeProps, Box, Button, IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import Iconify from "../../minimals/components/iconify";

export default function TaskActions({
  updateStatus,
  viewTaskHistory,
  addNoteAction,
  badgeCount
}: {
  updateStatus: (statusId: number) => void;
  viewTaskHistory: () => void;
  addNoteAction: () => void;
  badgeCount: number;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (statusId: number) => {
    handleClose();
    updateStatus(statusId);
  };

  const handleViewTaskHistory = () => {
    viewTaskHistory();
    handleClose();
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px"
    }
  }));

  return (
    <Stack direction="row" spacing={1}>
      <Box>
        <Button
          variant="contained"
          color="inherit"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem disableRipple onClick={() => handleStatusChange(2)}>
            Confirmed
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleStatusChange(1)}>
            Missing
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleStatusChange(0)}>
            Needs Confirmation
          </MenuItem>
          <MenuItem disableRipple onClick={() => handleStatusChange(3)}>
            N/A
          </MenuItem>
          <MenuItem disableRipple onClick={handleViewTaskHistory}>
            View Activity History
          </MenuItem>
          <MenuItem disableRipple onClick={addNoteAction}>
            Add a Note
          </MenuItem>
        </Menu>
      </Box>
      <IconButton onClick={addNoteAction}>
        <StyledBadge badgeContent={badgeCount} color="error">
          <Iconify icon="eva:file-add-outline" />
        </StyledBadge>
      </IconButton>
    </Stack>
  );
}

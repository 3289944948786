import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import { Box, Button, Grid, TextField, Typography, styled } from "@mui/material";
import { Contact } from "../types/Contact";
import { ContactType } from "../types/ContactType";
import ContactCardNew from "./ContactCardNew";
import ContactTypeDropdown from "./ContactTypeDropdown";
import OtherContact from "./OtherContact";
import PhonebookContact from "./PhonebookContact";
import { getContactFullName } from "../utils/helpers";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.grey[200]
}));

export default function AddEditContact(prop: { cancel: () => void; editContactIndex: number; isEditMode: boolean }) {
  const { searchForPatriotsAndBlackList, setAdditionalContacts, additionalContacts, contactTypeList } =
    useContext(OrderContext);
  const [contact, setContact] = useState<Contact>(
    prop.isEditMode ? additionalContacts[prop.editContactIndex] : ({} as Contact)
  );
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [errorMode, setErrorMode] = useState(false);
  const [contactType, setContactType] = useState<ContactType | null>(null);
  const [isContactLocked, setIsContactLocked] = useState(false);
  const [searchedContact, setSearchedContact] = useState<string | null>(null);
  const isAttorney = contactType?.name?.toString().toLowerCase().includes("attorney");

  const saveContact = async (isEdit: boolean) => {
    const fullName = getContactFullName(contact);
    const namesToSearch = getBlackNamesToSearch(contact);

    if (isEdit) {
      const updatedContacts = additionalContacts.map((c, i) => (i === editContactIndex ? contact : c));
      setAdditionalContacts(updatedContacts as Contact[]);
    } else {
      setAdditionalContacts([
        ...additionalContacts,
        { ...contact, payeeName: contact.payeeName || contact.company || contact.name }
      ]);
    }

    await searchForPatriotsAndBlackList(namesToSearch, fullName);
  };

  const getBlackNamesToSearch = (contact: Contact) => {
    const isContactFromPhonebookApi = Boolean(contact?.lookupCode);
    const fullName = getContactFullName(contact);
    const isNameAndPayeeMatch = !contact?.payeeName || fullName == contact.payeeName;
    return [
      { name: fullName, isCompany: isContactFromPhonebookApi ? !contact.isIndividual : isNameAndPayeeMatch },
      { name: contact?.company, isCompany: true },
      { name: contact?.payeeName, isCompany: true }
    ].filter((n) => Boolean(n.name));
  };

  const validateAndSubmit = () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;
    saveContact(prop.isEditMode);
    cancel();
  };

  const validateForm = () => {
    const hasErrors = (contact && !contactType) || !contact.name;
    if (hasErrors) {
      setErrorMode(true);
      return false;
    }
    return true;
  };

  const { cancel, editContactIndex, isEditMode } = prop;

  useEffect(() => {
    if (isEditMode) {
      setContactType(contactTypeList.find((c) => c.name === additionalContacts[editContactIndex]?.type) || null);
    }
  }, [editContactIndex, isEditMode]);

  const resetSearchedContact = () => {
    setContactList([]);
    setSearchedContact("");
  };

  return (
    <>
      <Grid item xs={12}>
        <RootStyle>
          <Typography variant="h6" marginBottom={3}>
            Contact Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={!isAttorney ? 12 : 6}>
              {(contactType?.isPhonebookContact || !contactType) && (
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <Box width={!isAttorney ? "50%" : "100%"}>
                    <ContactTypeDropdown
                      setContactType={setContactType}
                      contactType={contactType}
                      setContact={setContact}
                      contact={contact}
                      errorMode={errorMode}
                      resetSearchedContact={resetSearchedContact}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            {!contactType && (
              <>
                <Grid item xs={5}>
                  <ContactCardNew contact={contact} />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    size="small"
                    placeholder="Search the phonebook/Lookup..."
                    style={{ width: "100%" }}
                    disabled={true}
                  />
                </Grid>
              </>
            )}
            {contactType?.isPhonebookContact && (
              <PhonebookContact
                setContactList={setContactList}
                contactList={contactList}
                isAttorney={isAttorney}
                contact={contact}
                setContact={setContact}
                setContactType={setContactType}
                contactType={contactType}
                setSearchedContact={setSearchedContact}
                searchedContact={searchedContact}
              />
            )}
            {!contactType?.isPhonebookContact && contactType?.name && (
              <OtherContact
                setContactList={setContactList}
                contactList={contactList}
                contact={contact}
                setContact={setContact}
                setContactType={setContactType}
                contactType={contactType}
                resetSearchedContact={resetSearchedContact}
                isContactLocked={isContactLocked}
                setIsContactLocked={setIsContactLocked}
              />
            )}

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button color="inherit" variant="contained" onClick={cancel} size="small">
                  Cancel
                </Button>
                <Box style={{ marginLeft: "8px" }}> </Box>
                <Button color="primary" variant="contained" size="small" onClick={validateAndSubmit}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </RootStyle>
      </Grid>
    </>
  );
}
const IconStyle = styled("div")`
  .MuiTelInput-IconButton {
    img {
      display: none;
    }
  }
`;

import { Card, CardContent, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import HeaderTitle from "./HeaderTitle";
import { useContext } from "react";
import { ContactContext } from "../../contact/ContactContext";

interface IProps {
  setEditMode: () => void;
}

export default function SalesReps({ setEditMode }: IProps) {
  const theme = useTheme();
  const { contact } = useContext(ContactContext);
  const saleReps = contact?.contactReps.filter((r) => r.contactRepTypeId === 1);
  return (
    <Card>
      <CardHeader
        title={<HeaderTitle title="Sales Reps" handleEditClick={setEditMode} isDisabled={!contact?.isActive} />}
      />
      <CardContent>
        <Stack spacing={1.5}>
          {saleReps?.map((r) => (
            <Typography key={r.internalUser!.id} variant="body2">
              {r?.internalUser?.firstName} {r?.internalUser?.middleName}
              {r?.internalUser?.lastName}
            </Typography>
          ))}
          {saleReps?.length === 0 && (
            <Typography
              variant="body2"
              display="inline-block"
              color={theme.palette.text.disabled}
              sx={{ fontStyle: "italic" }}
            >
              no account rep selected
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

import { Alert, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import ContactInfo from "./ContactInfo";
import BulletinInfo from "./BulletinInfo";
import { useState } from "react";
import produce from "immer";
import { BlacklistNameEntity, BlacklistNameIndividual, BulletinInformation, UploadInfo } from "./types";
import { useNavigate } from "react-router";
import axios from "axios";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";
import { toBase64 } from "../utils/helpers";

export default function AddBlacklistName() {
  const { themeStretch } = useSettingsContext();
  const baseIndivual = {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    isAttorney: false,
    taxID: "",
    isNotary: false,
    yearAdmittedToBar: ""
  };

  const baseEntity = {
    name: "",
    companyTypeId: { value: null, label: "" },
    taxID: ""
  };
  const baseBulletin = {
    state: { value: null, label: "" },
    county: { value: null, label: "" },
    note: ""
  };
  const [individualInfo, setIndividualInfo] = useState<BlacklistNameIndividual>(baseIndivual);
  const [entityInfo, setEntityInfo] = useState<BlacklistNameEntity>(baseEntity);
  const [bulletinInfo, setBulletinInfo] = useState<BulletinInformation>(baseBulletin);
  const [submitting, setSubmitting] = useState(false);
  const [uploads, setUploads] = useState<UploadInfo[]>([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);

  const addOrEditUpload = (newUpload: UploadInfo, index: number | null = null) => {
    if (index === null) {
      setUploads((prevUploads) => [...prevUploads, newUpload]);
      return;
    }
    setUploads((prevUploads) => {
      return prevUploads.map((pu, i) => {
        return i === index ? newUpload : pu;
      });
    });
  };
  const navigate = useNavigate();

  const changeIndividualInfo = (label: keyof BlacklistNameIndividual, value: any) => {
    const newIndividualInfo = produce(individualInfo, (draft: any) => {
      (draft[label] as keyof BlacklistNameIndividual) = value;
    });
    setIndividualInfo(newIndividualInfo);
  };

  const changeEntityInfo = (label: keyof BlacklistNameEntity, value: any) => {
    const newEntityInfo = produce(entityInfo, (draft: any) => {
      (draft[label] as keyof BlacklistNameEntity) = value;
    });
    setEntityInfo(newEntityInfo);
  };
  const changeBulletinInfo = (label: keyof BulletinInformation, value: any) => {
    const newBulletinInfo = produce(bulletinInfo, (draft: any) => {
      (draft[label] as keyof BulletinInformation) = value;
    });
    setBulletinInfo(newBulletinInfo);
  };

  const handleRemoveUpload = (index: number) => {
    setUploads((prevUploads) => prevUploads.filter((_, i) => i !== index));
  };
  const resetFunction = () => {
    setSuccessMessage(true);
    setBulletinInfo(baseBulletin);
    setIndividualInfo(baseIndivual);
    setEntityInfo(baseEntity);
    setUploads([]);
    setSuccessMessage(false);
    navigate("/blacklistsearch");
  }

  const onSubmit = async () => {
    if (isIndividual) {
      const blacklistName = {
        person: individualInfo,
        //@ts-ignore
        countyId: bulletinInfo.county.id,
        bulletinsWithBase64: await Promise.all(
          uploads.map(async (u) => {
            const base64File = stripDataUriPrefix(await toBase64(u.file));
            return {
              bulletin: {
                number: u.number,
                date: u.date,
                //@ts-ignore
                underwriterId: u.underwriter.id
              },
              base64File
            };
          })
        )
      };
      setSubmitting(true);
      await axios.post("/api/blacklist/addPersonRecord", blacklistName);
      resetFunction();
      return;
    }
      const blacklistNameEntity = {
        company:{ 
          ...entityInfo,
          //@ts-ignore
          companyTypeId: entityInfo?.companyTypeId?.id
        },
        //@ts-ignore
        countyId: bulletinInfo.county.id,
        bulletinsWithBase64: await Promise.all(
          uploads.map(async (u) => {
            const base64File = stripDataUriPrefix(await toBase64(u.file));
            return {
              bulletin: {
                number: u.number,
                date: u.date,
                //@ts-ignore
                underwriterId: u.underwriter.id
              },
              base64File
            };
          })
        )
      };
      setSubmitting(true);
      await axios.post("/api/blacklist/addCompanyRecord", blacklistNameEntity);
      resetFunction();
  };
  const onCancel = () => {
    navigate("/blacklistsearch");
  };

  return (
    <Page title="Blacklist" width="100%">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom={2}>
                Add Name to Blacklist
              </Typography>
            </Stack>
            <Typography variant="h5">Name</Typography>
            <ContactInfo
              individualInfo={individualInfo}
              setIndividualInfo={changeIndividualInfo}
              entityInfo={entityInfo}
              changeEntityInfo={changeEntityInfo}
              isIndividual={isIndividual}
              setIsIndividual={setIsIndividual}
            />
            <Typography variant="h5" marginTop="40px">
              Bulletin Information
            </Typography>
            <BulletinInfo
              bulletinInfo={bulletinInfo}
              setBulletinInfo={changeBulletinInfo}
              addUpload={addOrEditUpload}
              uploads={uploads}
              handleRemoveUpload={handleRemoveUpload}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="right" spacing={1.5} width={784} marginTop={8}>
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={submitting}>
            {submitting ? "Saving..." : "Save"}
          </Button>
          {successMessage && (
            <Alert sx={{ marginTop: 2 }} severity="success">
              Successfully Saved
            </Alert>
          )}
        </Stack>
      </Container>
    </Page>
  );
}

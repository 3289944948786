import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Container } from "@mui/material";
import { ClosingsProvider } from "../contexts/ClosingsContext";
import Page from "../../minimals/components/page";
import Main from ".";

export default function ClosingsPage() {
  return (
    <ClosingsProvider>
      <Page title="Closings">
        <Container maxWidth={false}>
          <Main />
        </Container>
      </Page>
    </ClosingsProvider>
  );
}

import axios from "axios";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Check, DashboardReportType, assignedToResponse } from "../checks/types";
import InternalUserAutocomplete from "../shared/InternalUserAutocomplete";
import { InternalUser } from "../types/app";

export default function AssignToModal({
  selectedRows,
  onClose,
  open,
  reportType,
  fetchTableData,
  onRowsDeselected
}: {
  selectedRows: Check[];
  onClose: () => void;
  open: boolean;
  reportType: DashboardReportType;
  fetchTableData: Function;
  onRowsDeselected?: () => void;
}) {
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<InternalUser | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const selectedRowCount = selectedRows.length;
  const save = async () => {
    if (!selectedAssignedTo) return;
    setSubmitting(true);

    const payload = selectedRows.map((r) => ({
      selectId: r.selectId,
      orderNumber: r.orderNumber,
      name: selectedAssignedTo.fullName,
      email: selectedAssignedTo.email,
      reportType
    }));

    try {
      const { data } = await axios.post<assignedToResponse[]>("/api/outstandingchecks/assignTo", payload);
      setSubmitting(false);
      fetchTableData({ resetPagination: false });
      if (onRowsDeselected) {
        onRowsDeselected(); // Deselect the rows after saving
      }
      onClose();
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Please choose assignee{" "}
        <Typography>
          {`${selectedRowCount} rows will be reassigned`} {selectedAssignedTo && `to ${selectedAssignedTo.fullName}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <InternalUserAutocomplete
          sx={{ width: 220, marginTop: 0.7 }}
          value={selectedAssignedTo}
          onChange={(val) => {
            setSelectedAssignedTo(val as InternalUser | null);
          }}
          label="Assigned to"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={save} disabled={submitting}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

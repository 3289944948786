import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
import { insuranceAuditResultsColumns } from "./insuranceAuditResultsColumns";
import { StringParam, useQueryParam } from "use-query-params";
import { useContext, useEffect, useState } from "react";
import { Audit, ThreeWayMatchedOutgoingWires, InsuranceAuditData } from "./types";
import axios from "axios";
import { threeWayMatchedOutgoingWiresColumns, unmatchedItemWithUrl } from "./threeWayMatchedOutgoingWiresColumns";
import OrderNumberPrompt from "../orderEntry/OrderNumberPrompt";
import OrderAndAddress from "../shared/orderInfo/OrderAndAddress";
import InsuranceAuditOrderInfoCard from "./InsuranceAuditOrderInfoCard";
import Iconify from "../../minimals/components/iconify";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../minimals/auth/useAuthContext";
import { UserContext } from "../contexts/UserContext";
import { ModalType } from "../recordables/types";

type ChecklistTaskItem = {
  status: string;
  completedBy?: string;
};

type AuditTaskNotesModalProps = {
  onCloseClick: () => void;
  onSaveClick: (text: string) => void;
};

const AuditTaskNotesModal = ({ onCloseClick, onSaveClick }: AuditTaskNotesModalProps) => {
  const [noteText, setNoteText] = useState("");
  return (
    <Dialog open={true} fullWidth>
      <DialogContent sx={{ padding: 4 }}>
        <Typography variant="h6" marginBottom={3}>
          Mark as Completed
        </Typography>
        <Typography variant="subtitle2">Would you like to include a note? Enter it below and click Save.</Typography>
        <Stack spacing={2}>
          <TextField
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            placeholder="Note Text"
            rows={2}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onCloseClick}>
          Close
        </Button>
        <Button variant="contained" onClick={() => onSaveClick(noteText)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Main = () => {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [orderNumber, setOrderNumber] = useQueryParam("order", StringParam);
  const [insuranceAuditData, setInsuranceAuditData] = useState<InsuranceAuditData>({
    threeWayMatchedOutgoingWires: [],
    insuranceAuditResults: [],
    missingInvoices: [],
    unmatchedInvoices: [],
    unmatchedRegisterTransactions: [],
    unmatchedWireConfirmations: [],
    outgoingChecksInRegister: [],
    outgoingCheckDocuments: []
  });
  const [auditTask, setAuditTask] = useState<ChecklistTaskItem | undefined>();
  const [isAuditTaskLoading, setIsAuditTaskLoading] = useState(true);
  const [isAuditTaskMarkAsCompletedLoading, setIsAuditTaskMarkAsCompletedLoading] = useState(false);
  const [showTaskNotesModal, setShowTaskNotesModal] = useState(false);
  const hasOrderNumber = !!orderNumber;

  const getAndSetAuditData = async () => {
    setLoading(true);
    const { data } = await axios.get(`/api/insuranceaudit/getlist?ordernumber=${orderNumber}`);
    setInsuranceAuditData(data);
    setLoading(false);
  };

  const loadAuditTask = async () => {
    setIsAuditTaskLoading(true);
    const { data } = await axios.get(`/api/insuranceaudit/GetInsuranceAuditTaskStatus?ordernumber=${orderNumber}`);
    setAuditTask(data);
    setIsAuditTaskLoading(false);
  };

  const setAuditTaskAsCompleted = async (note: string | null = null) => {
    setShowTaskNotesModal(false);
    setIsAuditTaskMarkAsCompletedLoading(true);
    await axios.post("/proxy/api/checklisttasks/SetInternalAuditTaskToCompleted", {
      username: user?.username,
      orderNumber,
      note
    });
    await loadAuditTask();
    setIsAuditTaskMarkAsCompletedLoading(false);
  };

  useEffect(() => {
    if (!hasOrderNumber) {
      return;
    }
    getAndSetAuditData();
    loadAuditTask();
  }, [orderNumber]);

  const getAuditTaskButton = () => {
    if (isAuditTaskLoading && !isAuditTaskMarkAsCompletedLoading) {
      return <></>;
    }

    if (!auditTask || auditTask.status !== "Completed") {
      return (
        <LoadingButton
          loading={isAuditTaskMarkAsCompletedLoading}
          loadingPosition="end"
          variant="soft"
          size="medium"
          startIcon={<Iconify icon="eva:checkmark-circle-fill" />}
          onClick={() => setShowTaskNotesModal(true)}
        >
          Mark as Completed
        </LoadingButton>
      );
    }

    return (
      <Typography variant="subtitle2">
        {"Marked Completed by: "}
        <Typography color="#000" display="inline-block">
          {auditTask.completedBy}
        </Typography>
      </Typography>
    );
  };

  return (
    <>
      {hasOrderNumber && (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="space-between">
              <OrderAndAddress />
              <Stack direction="row" alignItems="center" spacing={4}>
                <InsuranceAuditOrderInfoCard />
                <Box>{getAuditTaskButton()}</Box>
              </Stack>
            </Stack>
            <Typography variant="h4" marginBottom={2}>
              Insurance Audit
            </Typography>
            <MaterialReactTable
              columns={insuranceAuditResultsColumns}
              data={insuranceAuditData.insuranceAuditResults}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Three Way Matched Outgoing Wires
            </Typography>
            <MaterialReactTable
              columns={threeWayMatchedOutgoingWiresColumns}
              data={insuranceAuditData.threeWayMatchedOutgoingWires}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Remaining Wires matched with Register, unmatched to invoices
            </Typography>
            <MaterialReactTable
              columns={threeWayMatchedOutgoingWiresColumns}
              data={insuranceAuditData.missingInvoices}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Unmatched Wire Confirmations
            </Typography>
            <MaterialReactTable
              columns={unmatchedItemWithUrl}
              data={insuranceAuditData.unmatchedWireConfirmations}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Unmatched Register Transactions
            </Typography>
            <MaterialReactTable
              columns={[
                {
                  header: "Unmatched",
                  accessorKey: "value"
                }
              ]}
              data={insuranceAuditData.unmatchedRegisterTransactions.map((s, idx) => ({ id: idx, value: s }))}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Unmatched Invoices
            </Typography>
            <MaterialReactTable
              columns={unmatchedItemWithUrl}
              data={insuranceAuditData.unmatchedInvoices}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Outgoing Checks in Register
            </Typography>
            <MaterialReactTable
              columns={[
                {
                  header: "Checks",
                  accessorKey: "value"
                }
              ]}
              data={insuranceAuditData.outgoingChecksInRegister.map((s, idx) => ({ id: idx, value: s }))}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>

          <Container maxWidth="xl">
            <Typography variant="h4" marginTop={4}>
              Scanned Outgoing Checks
            </Typography>
            <MaterialReactTable
              columns={unmatchedItemWithUrl.map((u) => ({
                ...u,
                header: "Documents"
              }))}
              data={insuranceAuditData.outgoingCheckDocuments}
              enableGlobalFilter
              enableFacetedValues
              initialState={{
                density: "compact",
                pagination: { pageIndex: 0, pageSize: 30 }
              }}
              state={{
                showSkeletons: loading
              }}
              muiTableBodyProps={{
                sx: {
                  "& .MuiTableRow-root:hover .MuiTableCell-root": {
                    backgroundColor: "inherit !important"
                  }
                }
              }}
              muiTableBodyRowProps={({ row }) => {
                // Apply zebra striping styles to alternate rows
                return {
                  sx:
                    row.index % 2 === 0
                      ? {
                          backgroundColor: "#f4f4f4"
                        }
                      : {} // Change the background color as needed
                };
              }}
            />
          </Container>
        </>
      )}
      {!hasOrderNumber && (
        <>
          <OrderNumberPrompt navigationUrlBuilder={(number) => `/insurance-audit?order=${number}`} />
        </>
      )}
      {showTaskNotesModal && (
        <AuditTaskNotesModal
          onCloseClick={() => setAuditTaskAsCompleted()}
          onSaveClick={(note) => setAuditTaskAsCompleted(note)}
        />
      )}
    </>
  );
};

export default Main;

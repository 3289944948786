import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { ContactContext } from "../../contact/ContactContext";
import { useContext, useState } from "react";
import { useImmer } from "use-immer";
import SalesRepAutocomplete from "./SalesRepAutocomplete";
import axios from "axios";
import { refreshContact } from "./helpers/refreshContact";
import { InternalUser } from "../../../types/app";

interface IProps {
  setEditMode: () => void;
}

export default function EditSalesReps({ setEditMode }: IProps) {
  const { contact, setContact } = useContext(ContactContext);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const salesReps = (contact?.contactReps
    .filter((r) => r.contactRepTypeId === 1 && r.internalUser)
    .map((r) => r.internalUser) || []) as InternalUser[];

  const [editedReps, setEditedReps] = useImmer<InternalUser[]>(salesReps);

  const handleSalesRepChange = (rep: InternalUser, index: number) => {
    setEditedReps((draft) => {
      draft[index] = rep;
    });
  };

  const submit = async () => {
    setSubmitting(true);

    const payload = editedReps.map((r) => ({
      contactId: contact?.id,
      internalUserId: r.id,
      contactRepTypeId: 1
    }));

    await axios.post(`/api/clientphonebook/contactreps/salesreps/update/${contact?.id}`, payload);

    await refreshContact({ contact, setContact });
    setEditMode();
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Sales Reps</Typography>}></CardHeader>
      <CardContent>
        <Stack spacing={2}>
          {[0, 1, 2].map((index) => (
            <SalesRepAutocomplete
              key={index} // Add the key prop here
              index={index}
              editedRep={editedReps[index]}
              handleRepChange={handleSalesRepChange}
              setEditedReps={setEditedReps}
              editedReps={editedReps}
            />
          ))}
          <Stack direction="row" justifyContent="right" spacing={1.5}>
            <Button variant="outlined" onClick={setEditMode}>
              Cancel
            </Button>
            <Button variant="contained" onClick={submit} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

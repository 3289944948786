import { useState } from "react";
import axios from "axios";
import DebouncedAutoComplete from "./DebouncedAutoComplete";

interface Props {
  value: string;
  onSelect: (e: React.SyntheticEvent<Element, Event>, option: string | null, reason: string) => void;
  size?: "small" | "medium";
  textboxMargin?: "dense" | "none" | "normal";
  disabled?: boolean;
}

const DebouncedOrderSearch = ({
  value,
  onSelect,
  size = "medium",
  textboxMargin = "none",
  disabled = false
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const onInputChange = async (text: string) => {
    if (!text) {
      setOptions([]);
      return;
    }
    const { data } = await axios.get(`/proxy/api/orders/FindRelatedOrdersByNumber?searchText=${text}`);
    setOptions(data || []);
  };

  return (
    <DebouncedAutoComplete
      autoHighlight
      disabled={disabled}
      value={value}
      options={options}
      size={size}
      getOptionsLabel={(option) => option || ""}
      onSelectedOptionChanged={onSelect}
      textboxMargin={textboxMargin}
      textboxLabel={`Search by Order #`}
      textboxPlaceholder="Start typing..."
      onDebouncedTextChanged={(text) => onInputChange(text)}
    />
  );
};

export default DebouncedOrderSearch;

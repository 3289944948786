import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  SxProps,
  Theme,
  FormHelperText
} from "@mui/material";
import { InternalUserDepartment } from "../types/app";

interface IProps {
  sx?: SxProps<Theme> | undefined;
  value: number;
  options: InternalUserDepartment[];
  onChange: (e: SelectChangeEvent<number>) => void;
  label: string;
  error?: boolean;
}

const RoleOptionsDropdown = ({ value, options, onChange, label, sx, error }: IProps) => {
  return (
    <FormControl sx={sx} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} label={label}>
        {value == 0 && <MenuItem value={0} />}
        {options.map((o: any) => (
          <MenuItem key={o.id} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{`${label} is required`}</FormHelperText>}
    </FormControl>
  );
};

export default RoleOptionsDropdown;

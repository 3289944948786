import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { CustomAvatar } from "../../components/custom-avatar";
import { CloseIcon } from "../../../minimals/theme/overrides/CustomIcons";
import axios from "axios";
import { OrderNote, TaskGroupEntity } from "../types";
import { useOrderInfo } from "../../shared/orderInfo/OrderInfoContext";

export default function OrderNotesModal({
  taskGroupEntity,
  closeModal,
  refresh
}: {
  taskGroupEntity: TaskGroupEntity | null;
  closeModal: () => void;
  refresh: () => Promise<void>;
}) {
  const orderInfo = useOrderInfo();
  const [submitting, setSubmitting] = useState(false);
  const [text, setText] = useState<string>("");
  const [isCritical, setIsCritical] = useState<boolean>(false);

  const saveNote = async () => {
    if (!taskGroupEntity || !text) return;
    const payload = {
      taskGroupEntityId: taskGroupEntity?.id,
      text,
      isCritical
    };
    await axios.post("/api/taskmanagement/taskgroupentity/addNote", payload);
  };

  const save = async () => {
    if (text) {
      setSubmitting(true);
      await saveNote();
      await refresh();
      setText("");
      setSubmitting(false);
    }
  };

  const sortedNotes = useMemo(() => {
    return taskGroupEntity?.notes.sort(
      (a: OrderNote, b: OrderNote) => new Date(b.dateCreated || "").getTime() - new Date(a.dateCreated || "").getTime()
    );
  }, [taskGroupEntity]);

  return (
    <Dialog open={true} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3} color="primary">
          {orderInfo?.titleCode} {orderInfo?.number}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack direction="column" paddingTop={1} rowGap={1}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            multiline
            rows={3}
            label="Note"
            sx={{ marginBottom: 2, color: isCritical ? "error" : "info" }}
          />
          <Stack direction="row" justifyContent="right">
            <Button variant="contained" size="small" onClick={save} disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </Stack>
        {sortedNotes && sortedNotes?.length > 0 && (
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1">History</Typography>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.4
                }
              }}
            >
              {sortedNotes?.map((n) => {
                return (
                  <TimelineItem key={n.id}>
                    <TimelineOppositeContent color="textSecondary" width={300}>
                      <Stack direction="column">
                        <Typography variant="caption">{n.createdByUser}</Typography>
                        <Typography variant="caption">
                          {new Date(n.dateCreated).toLocaleDateString()}{" "}
                          {new Date(n.dateCreated).toLocaleTimeString(undefined, {
                            hour: "numeric",
                            minute: "numeric"
                          })}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined">
                        <CustomAvatar
                          alt={n.createdByUser}
                          name={n.createdByUser}
                          sx={{ width: 22, height: 22, fontSize: 12 }}
                        />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                        {n.text}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

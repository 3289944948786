import { LoadingButton } from "@mui/lab";
import { Badge } from "@mui/material";

export default function DashboardBadge({
  badgeContent,
  href,
  disabled,
  color,
  hoverColor,
  hoverBackgroundColor,
  label,
  loading
}: {
  badgeContent: string | undefined;
  href: string;
  disabled: boolean;
  color: string;
  hoverColor: string;
  hoverBackgroundColor: string;
  label: string;
  loading?: boolean;
}) {
  return (
    <Badge
      badgeContent={loading ? undefined : badgeContent}
      color={badgeContent === "0" ? undefined : "error"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: badgeContent === "0" ? "#c1c4c8" : undefined,
          color: badgeContent === "0" ? "white" : undefined
        }
      }}
    >
      <a href={href} target="_blank">
        <LoadingButton
          size="small"
          variant="outlined"
          disabled={disabled}
          loading={loading}
          loadingPosition="end"
          sx={{
            color,
            borderColor: color,
            userSelect: "none",
            paddingRight: 4,
            paddingLeft: 4,
            "&:hover": {
              backgroundColor: hoverBackgroundColor,
              color: hoverColor,
              borderColor: color
            }
          }}
        >
          {label}
        </LoadingButton>
      </a>
    </Badge>
  );
}

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
  useState,
  useEffect,
  useContext
} from "react";
import Iconify from "../../../../minimals/components/iconify";
import palette from "../../../../minimals/theme/palette";
import { FullContact } from "../../types";
import axios from "axios";
import { ContactContext } from "../ContactContext";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getLogo } from "../../../utils/getLogo";
import { CustomAvatar } from "../../../components/custom-avatar";

const CustomCell = (props: {
  paddingTop?: number;
  paddingBottom?: number;
  children?:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}) => (
  <TableCell
    sx={{
      backgroundColor: "inherit"
    }}
  >
    {props.children}
  </TableCell>
);

export default function DirectColleagues() {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const themeColor = palette("light");
  const [colleagues, setColleagues] = useState<FullContact[]>([]);

  const getAndSetColleagues = async () => {
    const { data } = await axios.get(`/api/clientphonebook/contacts/getColleagues?contactId=${id}`);
    setColleagues(data);
  };

  useEffect(() => {
    getAndSetColleagues();
  }, []);

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Direct Colleagues</Typography>} />
      <CardContent sx={{ paddingLeft: 1 }}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <CustomCell>Name</CustomCell>
              <CustomCell>Role</CustomCell>
              <CustomCell>Email</CustomCell>
              <CustomCell>Phone #</CustomCell>
              <CustomCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {colleagues.map((colleague) => (
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CustomAvatar
                        alt={`${colleague.firstName} ${colleague.lastName}`}
                        name={`${colleague.firstName} ${colleague.lastName}`}
                        src={getLogo(colleague.contactRoles[0]?.businessEmail?.split("@")[1])}
                      />
                      <Box>
                        <Typography variant="subtitle2">
                          <Link
                            to={`../phonebook/contact/profile?id=${colleague.id}`}
                            style={{
                              textDecoration: "none",
                              color: "#212B36"
                            }}
                          >
                            {colleague.firstName} {colleague.lastName}
                          </Link>
                        </Typography>
                      </Box>
                    </Stack>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={themeColor.text.secondary}>
                    {colleague.contactRoles[0]?.role?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={themeColor.text.secondary}>
                    {colleague.contactRoles[0]?.businessEmail}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={themeColor.text.secondary}>
                    {colleague.contactRoles[0]?.phoneNumbers && colleague.contactRoles[0]?.phoneNumbers[0]?.number}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box
      height="100%"
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative"
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

type LoaderProp = {
  size?: number;
};

export default function ProgressLoader({ size }: LoaderProp) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 5500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box>
      <CircularProgressWithLabel value={progress} size={size} />
      {progress === 100 && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          Something is taking too long. You can wait another minute, but then please click close button and try again.
        </Alert>
      )}
    </Box>
  );
}

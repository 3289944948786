import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import MergedOrderRow from "./MergedOrderRow";
import MergedOrdersActions from "./MergedOrdersActions";
import MergedOrdersHeaderText from "./MergedOrdersHeaderText";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";
import { NavigateFunction } from "react-router";

interface IProps {
  mergedOrderResult: ContactOrder[];
  getOrdersForLookupCode: (lookupCode: string) => Promise<ContactOrder[]>;
  updateOrders: (lookupCode: string) => Promise<void>;
  mergeOrder: (orderToMerge: ContactOrder) => Promise<any>;
  deleteingLookupCodes: string[];
  deactivateLookupCode: () => Promise<void>;
  navigateToProfile: string;
}
export default function MergedOrders({
  mergedOrderResult,
  getOrdersForLookupCode,
  updateOrders,
  mergeOrder,
  deleteingLookupCodes,
  deactivateLookupCode,
  navigateToProfile
}: IProps) {
  const { mergingContacts } = useContext(AddEditContactContext);
  const [isAllOrdersSuccessful, setIsAllOrdersSuccessful] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [indexToProcess, setIndexToProcess] = useState<number>(0);

  const verifyAllOrdersSuccess = async () => {
    const allSuccessful = (await getOrdersForLookupCode(mergingContacts[1]?.lookupCode)).length === 0;
    setIsAllOrdersSuccessful(allSuccessful);
    if (allSuccessful) {
      deactivateLookupCode();
    }
    return allSuccessful;
  };

  const checkSuccessfulAndGetOrders = async () => {
    var allOrdersSuccess = await verifyAllOrdersSuccess();
    if (!allOrdersSuccess) {
      updateOrders(mergingContacts[1]?.lookupCode);
    }
  };

  useEffect(() => {
    if (indexToProcess >= mergedOrderResult.length) {
      verifyAllOrdersSuccess();
    }
  }, [indexToProcess, mergedOrderResult.length]);

  useEffect(() => {
    checkSuccessfulAndGetOrders();
  }, [mergingContacts[1]?.lookupCode, step]);

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="md">
        <MergedOrdersHeaderText
          isAllOrdersSuccessful={isAllOrdersSuccessful}
          step={step}
          indexToProcess={indexToProcess}
          mergedOrderResult={mergedOrderResult}
          deleteingLookupCodes={deleteingLookupCodes}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell>Role in Order</TableCell>
                <TableCell>Closing Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedOrderResult.map((order, index) => {
                return (
                  <MergedOrderRow
                    key={order.orderNumber}
                    order={order}
                    indexToProcess={indexToProcess}
                    setIndexToProcess={setIndexToProcess}
                    index={index}
                    mergeOrder={mergeOrder}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MergedOrdersActions
          mergedOrderResult={mergedOrderResult}
          setIndexToProcess={setIndexToProcess}
          isAllOrdersSuccessful={isAllOrdersSuccessful}
          step={step}
          setStep={setStep}
          indexToProcess={indexToProcess}
          navigateToProfile={navigateToProfile}
        />
      </Dialog>
    </>
  );
}

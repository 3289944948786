import { Container, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import GoBackButton from "./GoBackButton";

export default function NewIndividual() {
  const { themeStretch } = useSettingsContext();
  return (
    <>
      <Helmet>
        <title> New Contact | TitleQ</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={4}>
          <Typography variant="h4">Create New</Typography>
          <GoBackButton />
        </Stack>
      </Container>
    </>
  );
}

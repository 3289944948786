import { Grid, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import Iconify from "../../../minimals/components/iconify";
import { Attorney } from "./MatchedOrder";

type Prop = {
  contact: Attorney;
};

export default function MatchedAttorney({ contact }: Prop) {
  const { buyersAttorney, setBuyersAttorney, sellersAttorney, setSellersAttorney, transactionType, getContact } =
    useContext(OrderContext);

  const setMatchedAttorney = (phonebookContact: Attorney) => {
    if (transactionType === "Purchase") {
      setSellersAttorney(phonebookContact);
      const relatedOrdersElement = document.getElementById("sellersAttorney");
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    } else {
      setBuyersAttorney(phonebookContact);
      const relatedOrdersElement = document.getElementById("buyersAttorney");
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
    setIsHovered(false);
  };

  const handleMatchedAttorneyContact = async () => {
    const phonebookContact = await getContact(contact?.lookupCode || "");
    setMatchedAttorney(phonebookContact);
  };
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const isImported =
    transactionType === "Purchase" ? contact.name === sellersAttorney?.name : contact.name === buyersAttorney?.name;
  return (
    <Grid container marginTop={3}>
      <Grid item xs={2}>
        {!isImported && (
          <IconButton
            color={isHovered ? "primary" : "primary"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMatchedAttorneyContact()}
          >
            {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
            {!isHovered && <Iconify icon={"eva:person-outline"} />}
          </IconButton>
        )}
        {isImported && (
          <IconButton color="success">
            <Iconify icon={"eva:checkmark-fill"} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">Owner's Attorney</Typography>
            <Typography>{contact.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { IAssignedToOption, IClosedOrder } from "../types/calendar";

export const checkIsPassAssignedToFilter = ({
  order,
  selectedAccountReps,
  selectedAccountRepsEmails,
  isAnyFundersSelected,
  isNoCloserSelected,
  isNoFunderSelected
}: {
  order: IClosedOrder;
  selectedAccountReps: IAssignedToOption[];
  selectedAccountRepsEmails: string[];
  isAnyFundersSelected: boolean;
  isNoCloserSelected: boolean;
  isNoFunderSelected: boolean;
}): boolean => {
  if (selectedAccountReps.length == 0) return true;
  if (isAnyFundersSelected && order.funder) return true;
  if (isNoCloserSelected && !order.accountRep && !order.titleCloser) return true;
  if (isNoFunderSelected && !order.funder) return true;

  const someRepEmailMatch = selectedAccountRepsEmails.some((email) => {
    const lowerCaseEmail = email.toLowerCase();
    const { accountRepEmail, funderEmail, titleCloserEmail } = order;
    return (
      lowerCaseEmail == accountRepEmail?.toLowerCase() ||
      lowerCaseEmail == funderEmail?.toLowerCase() ||
      lowerCaseEmail == titleCloserEmail?.toLowerCase()
    );
  });
  return someRepEmailMatch;
};

export const validateZipcode = (input: string) => {
  const pattern = /^$|^\d{5}$|^\d{5}-\d{4}$/;
  return pattern.test(input);
};

import { Box, Skeleton } from "@mui/material";

export default function LedgerLoader() {
  return (
    <Box width={172}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Box>
  );
}

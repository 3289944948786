import { Autocomplete, TextField } from "@mui/material";

const CustomStatusFilter = ({ column }: { column: any }) => {
  const filterValue = column.getFilterValue() || []; // Get current filter value
  const setFilterValue = column.setFilterValue; // Method to update filter value

  return (
    <Autocomplete
      sx={{ width: 250 }}
      fullWidth
      multiple
      options={column.columnDef.filterSelectOptions.map((option: any) => option.text)}
      value={filterValue}
      onChange={(event, newValue) => {
        setFilterValue(newValue);
      }}
      getOptionLabel={(option) => option}
      renderInput={(params) => <TextField {...params} placeholder="Filter by Status" variant="outlined" />}
      limitTags={1}
      size="small"
    />
  );
};

export default CustomStatusFilter;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { UploadMultiFile } from "../components/upload";
import { useCallback, useState } from "react";
import axios from "axios";
import { toBase64 } from "../utils/helpers";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";

interface IProps {
  open: boolean;
  onClose: () => void;
  updateData: () => void;
  recordableId: string | number | null | undefined;
  documentType: string | undefined;
  additionalDescription: string | undefined;
  newVersionCount: number;
}

//removes for example v2
const removePreviousVersion = (inputString: string) => {
  var resultString = inputString.replace(/^\d+\s*|\bv\b/g, "");
  return resultString;
};

export default function NewVersionUpload({
  open,
  onClose,
  updateData,
  recordableId,
  documentType,
  additionalDescription,
  newVersionCount
}: IProps) {
  const [file, setFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [attemptedSave, setAttemptedSave] = useState<boolean>(false);

  const handleFileDrop = useCallback(
    (acceptedFile: any) => {
      const file = Object.assign(acceptedFile[0], {
        preview: URL.createObjectURL(acceptedFile[0])
      });
      setFile(file);
    },
    [setFile]
  );

  const save = async () => {
    setAttemptedSave(true);
    if (!file) {
      return;
    }
    setSubmitting(true);
    const base64 = stripDataUriPrefix(await toBase64(file));
    await axios.post("/api/recordablestracking/recordables/uploadNewDocumentVersion", {
      recordableId: recordableId,
      documentBase64: base64,
      fileName: file.name
    });
    await updateData();
    setSubmitting(false);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Version Upload</DialogTitle>
      <DialogContent>
        <UploadMultiFile
          error={attemptedSave && !file}
          files={file ? [file] : []}
          onDrop={handleFileDrop}
          onRemove={() => setFile(null)}
          onRemoveAll={() => setFile(null)}
          showPreview={false}
          showSelectedFiles={true}
          multiple={false}
        />
        <Typography marginTop={3}>
          Uploaded document will be saved to Post Closing {">"} Recordings {">"} <br />
          {documentType} {additionalDescription && "-"}{" "}
          {additionalDescription && `${removePreviousVersion(additionalDescription)} v${newVersionCount}`}
          {!additionalDescription && `v${newVersionCount}`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="soft" disabled={submitting} onClick={save}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

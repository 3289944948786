import { useSearchParams } from "react-router-dom";
import { EventType, CorpDoc, Status, TaskItem } from "./types";
import { useEffect, useState } from "react";
import axios from "axios";
import OrderAndAddress from "./OrderAndAddress";
import TaskBreadcrumbs from "./TaskBreadcrumbs";
import { getType } from "./getType";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import OrderInfo from "./OrderInfo";
import TaskFilters from "./TaskFilters";
import Fuse from "fuse.js";
import getStatusColor from "./getStatusColor";
import TaskHistory from "./TaskHistory";
import VersionUpload from "./VersionUpload";
import TaskActions from "./TaskActions";
import OrderSidebar from "./OrderSidebar";
import { CustomAvatar } from "../components/custom-avatar";
import NotesModal from "./notes/NotesModal";
import { fMonthDayYearDotNotation } from "../utils/formatTime";

export default function Tasks() {
  const [searchParams] = useSearchParams();
  const corpDocId = searchParams.get("corpdoc-id");

  const [corpDoc, setCorpDoc] = useState<CorpDoc | null>(null);
  const [tasks, setTasks] = useState<TaskItem[]>([]);
  const [selectedTaskItem, setSelectedTaskItem] = useState<TaskItem | null>(null);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTaskType, setSelectedTaskType] = useState<number>();
  const [selectedTaskHistory, setSelectedTaskHistory] = useState<TaskItem>();
  const [openVersionUpload, setOpenVersionUpload] = useState<boolean>(false);
  const [showTaskFilters, setShowTaskFilters] = useState<boolean>(false);

  const getAndSetStatuses = async () => {
    const { data } = await axios.get(`/api/corpdocstracking/taskItems/getStatusTypes`);
    setStatuses(data);
  };

  const getAndSetEventTypes = async () => {
    const { data } = await axios.get(`/api/corpdocstracking/taskItems/getEventTypes`);
    setEventTypes(data);
  };

  const getAndSetCorpDoc = async () => {
    const { data } = await axios.get(`/api/corpdocstracking/corpdocs/getById?id=${corpDocId}`);
    setCorpDoc(data);
  };

  const updateStatus = async (taskId: number, statusId: number) => {
    await axios.post(`/api/corpdocstracking/taskItems/updateStatus`, {
      id: taskId,
      status: statusId
    });
    updateData();
  };

  useEffect(() => {
    if (corpDocId) {
      getAndSetStatuses();
      getAndSetEventTypes();
      getAndSetCorpDoc();
    }
  }, [corpDocId]);

  const toggleShowTaskFilters = () => setShowTaskFilters(!showTaskFilters);

  const filterTasks = () => {
    const fuseOptions = {
      keys: ["description"], // Specify the properties to search on
      threshold: 0.5 // Set the threshold for fuzzy matching (0.0 to 1.0)
    };
    const filteredTasksByStatus =
      selectedTaskType !== undefined
        ? corpDoc?.taskItems.filter((t) => t.status === selectedTaskType) || []
        : corpDoc?.taskItems || [];
    const fuse = new Fuse(filteredTasksByStatus, fuseOptions);
    return searchTerm === "" ? filteredTasksByStatus : fuse.search(searchTerm).map((f) => f.item);
  };

  const filteredTasks = filterTasks();

  const corpDocType = getType(corpDoc?.type);

  const updateData = () => {
    getAndSetCorpDoc();
  };

  const getLatestEvent = (task: TaskItem) => {
    const sortedEventByDate = task.statusUpdateHistory.sort((a, b) => {
      const dateA: Date = new Date(a.dateTime);
      const dateB: Date = new Date(b.dateTime);
      return dateB.getTime() - dateA.getTime();
    });

    const latestEvent = sortedEventByDate[0];
    return latestEvent;
  };

  return (
    <>
      <OrderAndAddress />
      <TaskBreadcrumbs corpDocType={corpDocType} />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TableContainer component={Card}>
            {corpDoc && (
              <OrderInfo
                corpDoc={corpDoc}
                tasks={tasks}
                setOpenVersionUpload={setOpenVersionUpload}
                showTaskFilters={showTaskFilters}
                toggleShowTaskFilters={toggleShowTaskFilters}
              />
            )}
            <Divider />
            {showTaskFilters && (
              <TaskFilters options={statuses} setSearchTerm={setSearchTerm} setSelectedTaskType={setSelectedTaskType} />
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Tasks To Do</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Latest Activity</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTasks.map((t) => {
                  var latestEvent = getLatestEvent(t);
                  var userNotesCount = t.notes.filter((n) => n.isUserNote).length;
                  return (
                    <TableRow key={t.id}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell width={500}>
                        <Typography variant="subtitle2">{t.description}</Typography>
                      </TableCell>
                      <TableCell width={500}>
                        {t.assignedToName && (
                          <Stack direction="row" spacing={1} alignItems="center">
                            <CustomAvatar name={t.assignedToName || ""} />
                            <Box>
                              <Typography typography="subtitle2">{t.assignedToName}</Typography>
                              <Typography typography="body2">{t.assignedToEmail}</Typography>
                            </Box>
                          </Stack>
                        )}
                      </TableCell>
                      <TableCell width={500}>
                        {latestEvent && (
                          <>
                            <Typography variant="body2" color="GrayText" noWrap>
                              <Typography
                                variant="body2"
                                display="inline-block"
                                color="info.main"
                                onClick={() => setSelectedTaskHistory(t)}
                                sx={{ cursor: "pointer" }}
                              >
                                {eventTypes.find((t) => t.id === latestEvent?.eventType)?.description}
                              </Typography>{" "}
                              on {latestEvent?.dateTime && fMonthDayYearDotNotation(latestEvent?.dateTime)}
                            </Typography>
                            <Typography variant="body2" color="GrayText">
                              by&nbsp;{latestEvent?.username}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" color={getStatusColor(t.status)} noWrap>
                          {statuses.find((s) => s.id === t.status)?.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TaskActions
                          updateStatus={(statusId: number) => updateStatus(t.id, statusId)}
                          viewTaskHistory={() => setSelectedTaskHistory(t)}
                          addNoteAction={() => setSelectedTaskItem(t)}
                          badgeCount={userNotesCount}
                        />
                      </TableCell>
                      <TableCell width={400} />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <OrderSidebar />
        </Grid>
      </Grid>
      {selectedTaskHistory && (
        <TaskHistory
          task={selectedTaskHistory}
          corpDocType={corpDocType}
          corpDocDescription={corpDoc?.description}
          documents={corpDoc?.documents || []}
          statuses={statuses}
          eventTypes={eventTypes}
          onClose={() => setSelectedTaskHistory(undefined)}
        />
      )}
      {openVersionUpload && (
        <VersionUpload
          open={openVersionUpload}
          onClose={() => setOpenVersionUpload(false)}
          updateData={updateData}
          corpDocId={corpDocId}
          documentType={getType(corpDoc?.type)}
          additionalDescription={corpDoc?.description}
          newVersionCount={corpDoc?.documents?.length ? corpDoc?.documents?.length + 1 : 0}
        />
      )}
      {selectedTaskItem && (
        <NotesModal
          selectedTaskItem={selectedTaskItem}
          setSelectedTaskItem={setSelectedTaskItem}
          updateTasks={getAndSetCorpDoc}
        />
      )}
    </>
  );
}

import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import Iconify from "../../../../minimals/components/iconify";
import { Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useState } from "react";
import { Company, CompanyBranch } from "../../types";
import axios from "axios";
import { debounce } from "../../../utils/debounce";
import { AddEditContactContext } from "./AddEditContactContext";
import RoleAndProfession from "./RoleAndProfession";

interface IProps {
  setDuplicateCompanyList: Dispatch<SetStateAction<Company[]>>;
  refProp: MutableRefObject<null | HTMLInputElement>;
  theInput: string;
}

export default function CompanyInformation({ setDuplicateCompanyList, refProp, theInput }: IProps) {
  const {
    selectedCompany,
    setSelectedCompany,
    selectedCompanyBranch,
    setSelectedCompanyBranch,
    handleCompanyBranchChange,
    newCompany,
    setNewCompany,
    handleSelectedCompanyChange,
    setCompany,
    setContact,
    setNewBranch,
    formIsInvalid,
    submissionAttempted,
    contact,
    focusOnCompanyName,
    setNoCompanyMode
  } = useContext(AddEditContactContext);

  const newCompanyEntryOptions = [{ id: 0, isNewCompanyEntry: true, name: "" } as Company];

  const newCompanyBranchEntryOptions = [
    {
      isNewBranchEntry: true
    } as CompanyBranch as CompanyBranch
  ] as CompanyBranch[];

  const searchParams = new URLSearchParams(location.search);
  const companyIdFromUrl = searchParams.get("companyId");

  const [companyList, setCompanyList] = useState<Company[]>([]);

  const [locationList, setLocationList] = useState<CompanyBranch[]>(
    selectedCompany?.branches
      ? [...newCompanyBranchEntryOptions, ...selectedCompany?.branches]
      : newCompanyBranchEntryOptions
  );

  const [branchLocations, setBranchLocations] = useState<CompanyBranch[]>([]);

  const [searchedLocation, setSearchedLocation] = useState<string | null>(null);

  const [searchedCompanyName, setSearchedCompanyName] = useState<string | null>(null);

  const searchCompanies = debounce(async (searchText: string) => {
    let { data } = await axios.get(`/api/clientphonebook/companies/search?searchText=${searchText}`);
    setCompanyList([...newCompanyEntryOptions, ...data]);
  }, 200);

  const searchLocations = debounce(async (searchText: string) => {
    let { data } = await axios.get<CompanyBranch[]>(
      `/api/clientphonebook/companybranch/search?searchText=${searchText}`
    );
    data = data.filter((d) => d.companyId);
    setLocationList([...newCompanyBranchEntryOptions, ...data]);
  }, 200);

  const getAndSetSelectedCompany = async (companyId: number, branchId: number) => {
    let { data } = await axios.get<Company>(`/api/clientphonebook/companies/get/${companyId}`);
    const selectedBranch = data.branches.find((b) => b.id === branchId) || null;
    setSelectedCompany(data);
    setSelectedCompanyBranch(selectedBranch);
    handleCompanyBranchChange(selectedBranch);
  };

  const clearCompaniesList = (reason: AutocompleteInputChangeReason) => {
    if (reason === "clear") {
      setCompanyList(newCompanyEntryOptions);
      setSearchedCompanyName(null);
      setContact((draft) => {
        draft.contactRoles[0].branch = {} as CompanyBranch;
      });
      clearLocationsList("clear");
      setSelectedCompanyBranch(null);
      handleCompanyBranchChange(null);
    }
  };

  const clearLocationsList = (reason: AutocompleteInputChangeReason) => {
    if (reason === "clear") {
      setLocationList(newCompanyBranchEntryOptions);
      setContact((draft) => {
        draft.contactRoles[0].branch = {} as CompanyBranch;
      });
      setSelectedCompanyBranch(null);
    }
  };

  const handleCreateNewCompany = async () => {
    setCompany((draft) => {
      draft.name = searchedCompanyName || "";
    });
    setNoCompanyMode(false);
    setNewCompany(true);

    const { data } = await axios.post("/api/clientphonebook/companies/findMatching", {
      name: searchedCompanyName,
      website: "",
      phone: "",
      email: "",
      fax: "",
      addresses: []
    });

    const duplicateCompanies = data?.map((d: any) => ({ ...d.company, branches: [d] } as Company)) || [];
    setDuplicateCompanyList(duplicateCompanies);
  };

  useEffect(() => {
    const newEntryAndBranches = [...newCompanyBranchEntryOptions, ...(selectedCompany?.branches || [])];
    setBranchLocations(newEntryAndBranches);
  }, [selectedCompany]);

  useEffect(() => {
    (async () => {
      if (companyIdFromUrl) {
        const { data } = await axios.get(`/api/clientphonebook/companies/search?companyId=${companyIdFromUrl}`);
        if (data.length) {
          handleSelectedCompanyChange(data[0]);
        }
      }
    })();
  }, [companyIdFromUrl]);

  return (
    <Card>
      <CardHeader title="Company Information" />
      <CardContent>
        {newCompany && <h1>New Company</h1>}
        <RoleAndProfession theInput={theInput} refProp={refProp} />
        {!selectedCompany && (
          <Grid container rowGap={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                onInputChange={(_, __, reason) => clearCompaniesList(reason)}
                onChange={(_, value) => (value?.id ? handleSelectedCompanyChange(value) : null)}
                renderInput={(params) => (
                  <TextField
                    autoFocus={focusOnCompanyName}
                    error={submissionAttempted && formIsInvalid}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                    label="Search for a company"
                    placeholder="Start typing Company name..."
                    onChange={(e) => {
                      searchCompanies(e.target.value);
                      setSearchedCompanyName(e.target.value);
                    }}
                  />
                )}
                getOptionLabel={(option) => option.name}
                options={companyList}
                renderOption={(props, option) => {
                  return (
                    searchedCompanyName && (
                      <li {...props} key={option.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        {option.isNewCompanyEntry && searchedCompanyName && (
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            onClick={handleCreateNewCompany}
                            sx={{ width: "100%" }}
                          >
                            + Create New Company
                          </Typography>
                        )}
                        {!option.isNewCompanyEntry && <Typography variant="body1">{option.name}</Typography>}
                      </li>
                    )
                  );
                }}
                filterOptions={(o) => o}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" marginBottom={2}>
                Location
              </Typography>
              <Autocomplete
                value={selectedCompanyBranch}
                onInputChange={(_, __, reason) => clearLocationsList(reason)}
                onChange={(_, value) => (value?.id ? getAndSetSelectedCompany(value.companyId, value.id) : null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={submissionAttempted && formIsInvalid}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" />
                        </InputAdornment>
                      )
                    }}
                    inputRef={theInput === "Location" ? refProp : null}
                    fullWidth
                    label="Company Address / Location"
                    placeholder="Start typing..."
                    onChange={(e) => {
                      searchLocations(e.target.value);
                      setSearchedLocation(e.target.value);
                    }}
                  />
                )}
                filterOptions={(option) => option}
                getOptionLabel={(option) => option?.address1 || ""}
                options={locationList}
                renderOption={(props, option) => {
                  if (!searchedLocation || searchedLocation?.length === 0) {
                    return;
                  }
                  return (
                    <li {...props} key={option.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
                      {option.isNewBranchEntry && (
                        <Typography
                          color="primary"
                          onClick={() => {
                            setCompany((draft) => {
                              draft.branches[0].address1 = searchedLocation;
                            });
                            setNewCompany(true);
                            setSearchedLocation("");
                          }}
                        >
                          {locationList.length > 2
                            ? `Results don't match... Use address "${searchedLocation}"?`
                            : `No results found... Use address "${searchedLocation}"?`}
                        </Typography>
                      )}
                      {!option.isNewBranchEntry && (
                        <Typography>
                          {option.address1} {option.address2}{" "}
                          <Typography variant="caption">{option.company?.name}</Typography>
                        </Typography>
                      )}
                    </li>
                  );
                }}
              />
            </Grid>
          </Grid>
        )}
        {selectedCompany && (
          <>
            <Grid container columnSpacing={2} rowSpacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  onInputChange={(_, __, reason) => clearCompaniesList(reason)}
                  value={selectedCompany}
                  onChange={(_, value) => handleSelectedCompanyChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                      }}
                      fullWidth
                      label="Company"
                      placeholder="Start typing..."
                      onChange={(e) => searchCompanies(e.target.value)}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  options={companyList}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={selectedCompanyBranch}
                  onChange={(_, value) => handleCompanyBranchChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={theInput === "Location" ? refProp : null}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                        // Conditionally set the ref here
                      }}
                      fullWidth
                      label="Branch / Location"
                      placeholder="Start typing..."
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option?.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        {option?.isNewBranchEntry && (
                          <Typography variant="subtitle1" color="primary" onClick={() => setNewBranch(true)}>
                            + Add a new branch/location
                          </Typography>
                        )}
                        {!option?.isNewBranchEntry && (
                          <Typography>
                            {option?.address1} {option?.address2}{" "}
                          </Typography>
                        )}
                      </li>
                    );
                  }}
                  filterOptions={(o) => o}
                  options={branchLocations}
                  getOptionLabel={(option) => option?.address1 || ""}
                />
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

import { Autocomplete, Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { MutableRefObject, useContext } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import { Phone } from "../../types";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { phoneTypeList } from "../../shared/phoneTypeList";
import isNumber from "../../../utils/isNumber";

interface IProps {
  showAddButton: boolean;
  showDeleteButton: boolean;
  phone: Phone;
  index: number;
  searchClientDuplicates: () => Promise<void>;
  theInput: string;
  refProp: MutableRefObject<null | HTMLInputElement>;
}

export default function PhoneForm(props: IProps) {
  const { showAddButton, showDeleteButton, phone, index, searchClientDuplicates, theInput, refProp } = props;
  const { addPhone, removePhone, handlePhoneChange, submissionAttempted, formIsInvalid } =
    useContext(AddEditContactContext);

  const handlePhoneFields = (index: number, info: MuiTelInputInfo) => {
    handlePhoneChange(index, "number", info.nationalNumber);
    handlePhoneChange(index, "countryCode", info.countryCallingCode);
  };

  const getPhoneType = () => {
    if (phone.type === "Business") {
      return "Phone";
    } else return phone.type;
  };

  return (
    <Grid container spacing={2} alignItems="center" ref={theInput === "Phone" ? refProp : null}>
      <Grid item xs={3.8}>
        <Autocomplete
          value={getPhoneType()}
          onChange={(e, value) => handlePhoneChange(index, "type", value)}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password"
              }}
              label="Phone Type"
            />
          )}
          options={phoneTypeList}
          fullWidth
        />
      </Grid>
      <Grid item xs={3.8}>
        <MuiTelInput
          error={index === 0 && submissionAttempted && !phone.number && formIsInvalid}
          value={`+${isNumber(phone.countryCode) ? phone.countryCode : 1} ${phone.number}`}
          onChange={(value, info) => handlePhoneFields(index, info)}
          preferredCountries={["US", "IL"]}
          defaultCountry={"US"}
          forceCallingCode
          label="Phone"
          fullWidth
          onBlur={searchClientDuplicates}
          inputRef={props.theInput === "Profession" ? props.refProp : null}
        />
      </Grid>
      <Grid item xs={2.3}>
        <TextField
          value={phone.extension || ""}
          label="Ext."
          fullWidth
          onChange={(e) => handlePhoneChange(index, "extension", e.target.value.trim())}
          disabled={phone.type === "Mobile"}
          sx={{ opacity: phone.type === "Mobile" ? 0.5 : 1 }}
        />
      </Grid>
      <Grid item xs={2.1}>
        <Stack direction="row" justifyContent="flex-start">
          {showDeleteButton && (
            <IconButton color="error" onClick={() => removePhone(index)}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          )}
          {showAddButton && <Button onClick={addPhone}>+ Phone</Button>}
        </Stack>
      </Grid>
    </Grid>
  );
}

import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import HeaderTitle from "./HeaderTitle";

interface IProps {
  lookupCode: string;
}

export default function LookupCodeCard({ lookupCode = "" }: IProps) {
  return (
    <Card>
      <CardHeader title={<HeaderTitle title="Lookup Code" />} />
      <CardContent>
        <Stack spacing={1.5}>
          <Typography variant="body2" display="inline-block">
            {lookupCode}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

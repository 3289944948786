import { Chip } from "@mui/material";
import palette from "../../minimals/theme/palette";

const themeColor = palette("light");

export const getComponentColor = (priority: string) => {
  switch (priority) {
    case "Urgent":
      return "error";
    case "Important":
      return "warning";
    default:
      return "secondary";
  }
};

export const getLabelColor = (priority: string) => {
  switch (priority) {
    case "Urgent":
      return themeColor.error.main;
    case "Important":
      return themeColor.warning.main;
    default:
      return themeColor.secondary.light;
  }
};

interface IProps {
  priority: string;
}

export function PriorityChip({ priority }: IProps) {
  return (
    <Chip
      label={priority}
      size="small"
      color={
        getComponentColor(priority) as "default" | "error" | "primary" | "secondary" | "info" | "success" | "warning"
      }
      variant="soft"
    />
  );
}

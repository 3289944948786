import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="155" height="38" viewBox="0 0 155 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8125 19C37.8125 29.4417 29.3481 37.9063 18.9063 37.9063C8.46459 37.9063 0 29.4417 0 19C0 8.55812 8.46459 0.09375 18.9063 0.09375C29.3481 0.09375 37.8125 8.55812 37.8125 19Z"
          fill={PRIMARY_MAIN}
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.875 10.3654H27.9369V8.68359H9.875V10.3654Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8613 29.3162H15.5432V17.0938H13.8613V29.3162Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0645 29.3162H19.7463V17.0938H18.0645V29.3162Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2695 29.3162H23.9516V17.0938H22.2695V29.3162Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8085 14.5688H12.005C11.5402 14.5688 11.1641 14.1922 11.1641 13.7279C11.1641 13.2633 11.5402 12.8867 12.005 12.8867H25.8085C26.2732 12.8867 26.6494 13.2633 26.6494 13.7279C26.6494 14.1922 26.2732 14.5688 25.8085 14.5688Z"
          fill="white"
        />
        <path
          d="M78.9863 17.704H75.3063L75.3382 26.52C75.3382 26.9253 75.3863 27.2293 75.4823 27.432C75.5889 27.624 75.7489 27.752 75.9622 27.816C76.1862 27.8693 76.4689 27.896 76.8102 27.896H79.0342V30.776C78.8209 30.872 78.4849 30.9627 78.0263 31.048C77.5783 31.1333 76.9489 31.176 76.1383 31.176C74.6876 31.176 73.5623 30.9893 72.7623 30.616C71.9729 30.2427 71.4236 29.72 71.1143 29.048C70.8049 28.376 70.6503 27.5973 70.6503 26.712V17.704H67.9622V14.456H70.8102L71.9782 9.464H75.3063V14.44H78.9863V17.704Z"
          fill="#637381"
        />
        <path d="M87.1415 14.456V31H82.6775V14.456H87.1415ZM87.1895 7.88V12.088H82.6455V7.88H87.1895Z" fill="#637381" />
        <path
          d="M101.455 17.704H97.775L97.807 26.52C97.807 26.9253 97.855 27.2293 97.951 27.432C98.0577 27.624 98.2177 27.752 98.431 27.816C98.655 27.8693 98.9377 27.896 99.279 27.896H101.503V30.776C101.29 30.872 100.954 30.9627 100.495 31.048C100.047 31.1333 99.4177 31.176 98.607 31.176C97.1563 31.176 96.031 30.9893 95.231 30.616C94.4417 30.2427 93.8923 29.72 93.583 29.048C93.2737 28.376 93.119 27.5973 93.119 26.712V17.704H90.431V14.456H93.279L94.447 9.464H97.775V14.44H101.455V17.704Z"
          fill="#637381"
        />
        <path
          d="M110.186 31.288C108.97 31.288 108.005 31.144 107.29 30.856C106.576 30.568 106.048 30.1893 105.706 29.72C105.365 29.24 105.146 28.712 105.05 28.136C104.954 27.5493 104.906 26.9573 104.906 26.36V7.224H109.482V25.784C109.482 26.392 109.61 26.888 109.866 27.272C110.133 27.6453 110.629 27.8747 111.354 27.96L112.138 28.008V30.888C111.818 30.9947 111.493 31.0853 111.162 31.16C110.832 31.2453 110.506 31.288 110.186 31.288Z"
          fill="#637381"
        />
        <path
          d="M118.965 23.8C118.954 24.6427 119.088 25.3787 119.365 26.008C119.642 26.6267 120.042 27.1067 120.565 27.448C121.098 27.7787 121.738 27.944 122.485 27.944C123.285 27.944 123.984 27.7733 124.581 27.432C125.189 27.0907 125.61 26.5307 125.845 25.752H130.053C129.872 26.9253 129.402 27.928 128.645 28.76C127.898 29.592 126.992 30.2267 125.925 30.664C124.858 31.1013 123.749 31.32 122.597 31.32C120.965 31.32 119.525 30.9787 118.277 30.296C117.04 29.6027 116.074 28.6267 115.381 27.368C114.688 26.1093 114.341 24.6267 114.341 22.92C114.341 21.2667 114.656 19.7787 115.285 18.456C115.914 17.1227 116.826 16.072 118.021 15.304C119.226 14.5253 120.672 14.136 122.357 14.136C124.042 14.136 125.456 14.4933 126.597 15.208C127.749 15.912 128.613 16.8987 129.189 18.168C129.776 19.4267 130.069 20.8827 130.069 22.536V23.8H118.965ZM118.949 21.016H125.701C125.701 20.3547 125.578 19.7467 125.333 19.192C125.088 18.6373 124.714 18.2 124.213 17.88C123.722 17.56 123.104 17.4 122.357 17.4C121.632 17.4 121.013 17.576 120.501 17.928C120 18.2693 119.616 18.7173 119.349 19.272C119.082 19.8267 118.949 20.408 118.949 21.016Z"
          fill="#637381"
        />
        <path
          d="M132.848 19.48C132.848 17.0053 133.286 14.8773 134.161 13.096C135.046 11.3147 136.283 9.944 137.872 8.984C139.472 8.024 141.35 7.544 143.505 7.544C145.67 7.544 147.547 8.024 149.137 8.984C150.726 9.944 151.958 11.3147 152.833 13.096C153.707 14.8773 154.145 17.0053 154.145 19.48C154.145 21.592 153.825 23.448 153.185 25.048C152.555 26.648 151.59 27.9867 150.288 29.064C150.544 29.704 151.019 30.216 151.712 30.6C152.416 30.984 153.195 31.1813 154.049 31.192V34.936C153.046 34.936 152.091 34.8507 151.185 34.68C150.289 34.5093 149.424 34.1467 148.592 33.592C147.76 33.048 146.945 32.216 146.145 31.096C145.611 31.1707 145.142 31.224 144.736 31.256C144.342 31.2987 143.931 31.32 143.505 31.32C141.339 31.32 139.457 30.8507 137.857 29.912C136.267 28.9627 135.035 27.608 134.161 25.848C133.286 24.0773 132.848 21.9547 132.848 19.48ZM137.984 19.544C137.984 22.3707 138.47 24.4027 139.441 25.64C140.411 26.8667 141.766 27.48 143.505 27.48C145.254 27.48 146.614 26.8667 147.585 25.64C148.555 24.4027 149.04 22.3653 149.04 19.528C149.04 16.6587 148.555 14.584 147.585 13.304C146.614 12.024 145.254 11.384 143.505 11.384C141.766 11.384 140.411 12.0293 139.441 13.32C138.47 14.6 137.984 16.6747 137.984 19.544Z"
          fill="#637381"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

export default Logo;

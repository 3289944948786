import { DialogContentText, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import Iconify from "../../minimals/components/iconify";

interface Property {
  address: string | null;
  block: string | null;
  city: string | null;
  county: string | null;
  id: number;
  lots: string[] | null;
  parcel: string | null;
  section: string | null;
  state: string | null;
  zip: string | null;
}

const PropertyAddress = ({ properties = [] }: { properties: Property[] }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!properties || !properties.length) {
    return <>No Address Info Available</>;
  }

  const { address, city, state, zip } = properties[selectedIndex];
  return (
    <>
      <DialogContentText variant="body2">{address}</DialogContentText>
      <DialogContentText variant="body2">
        {city && `${city}, `} {state} {zip}
      </DialogContentText>
      {properties.length > 1 && (
        <Stack direction="row" alignItems="center" spacing={0} sx={{ paddingTop: "5px" }}>
          <IconButton size={"small"} disabled={selectedIndex == 0} onClick={() => setSelectedIndex(selectedIndex - 1)}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>
          <span style={{ fontSize: "11px" }}>
            Property {selectedIndex + 1} of {properties.length}
          </span>
          <IconButton
            size="small"
            disabled={selectedIndex == properties.length - 1}
            onClick={() => setSelectedIndex(selectedIndex + 1)}
          >
            <Iconify icon="eva:arrow-ios-forward-fill" />
          </IconButton>
        </Stack>
      )}
    </>
  );
};

export default PropertyAddress;

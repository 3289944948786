import axios from "axios";
import { BasicTask, TaskNote } from "../types";

export const getNotes = async (task: BasicTask) => {
  if (task?.id) {
    let { data } = await axios.get(`/api/taskmanagement/tasks/getnotes?taskId=${task?.id}`);
    data = data.sort(
      (a: TaskNote, b: TaskNote) => new Date(b.dateCreated || "").getTime() - new Date(a.dateCreated || "").getTime()
    );
    return data;
  }
};

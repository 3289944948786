import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import axios from "axios";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import { useContext, useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { countryList } from "../../../utils/countryList";
import { closeIFrame, postMessageToParentWindow } from "../../../utils/iframeUtils";
import isNumber from "../../../utils/isNumber";
import { usStateList } from "../../../utils/usStateList";
import StateAutocomplete from "../../shared/StateAutocomplete";
import { Company, CompanyBranch, CompanyType, SmartyAddress } from "../../types";
import { AddEditContactContext } from "./AddEditContactContext";
import AddressAutocomplete from "./AddressAutocomplete";
import NoCompanyAlert from "./NoCompanyAlert";
import RoleAndProfession from "./RoleAndProfession";
import { types } from "util";
import validateEmail from "../../../utils/validateEmail";

interface IProps {
  searchCompanyDuplicates: () => Promise<void>;
  isPartOfOwnForm?: boolean;
}

export default function AddEditCompanyToPhoneBook({ searchCompanyDuplicates, isPartOfOwnForm = false }: IProps) {
  const {
    company,
    setCompany,
    setNewCompany,
    setSelectedCompany,
    setSelectedCompanyBranch,
    handleCompanyBranchChange,
    newAddressWithoutCompanyInfoMode,
    setNewAddressWithoutCompanyInfoMode,
    noCompanyMode,
    setNoCompanyMode,
    submitBranchWithoutCompany,
    submissionAttempted,
    formIsInvalid,
    focusOnCompanyName,
    editMode,
    isBranchWithoutCompany,
    setIsBranchWithoutCompany,
    contact
  } = useContext(AddEditContactContext);

  const [defaultName] = useQueryParam("defaultName", StringParam);
  const [defaultCompanyType] = useQueryParam("defaultCompanyType", StringParam);

  const { isAppInIFrame } = useSettingsContext();

  const [submitting, setSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [showConfirmNoCompanyAlert, setShowConfirmNoCompanyAlert] = useState(false);
  const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const theme = useTheme();

  const getAndSetCompanyTypes = async () => {
    const { data } = await axios.get("/api/clientphonebook/companies/getAllTypes");
    setCompanyTypes(data);
  };

  const handleCompanyChange = (key: keyof Company, value: any) => {
    setCompany((draft) => {
      (draft[key] as any) = value;
    });
  };

  const handleBranchChange = (key: keyof CompanyBranch, value: any) => {
    setCompany((draft) => {
      (draft.branches[0][key] as any) = value;
    });
  };

  const handleOptionChange = (option: SmartyAddress | null) => {
    setCompany((draft) => {
      const branch = draft.branches[0];
      branch.address1 = option?.formattedStreet_line || null;
      if (option) {
        branch.address2 = option.secondary;
        branch.city = option.city;
        branch.state = option.state;
        branch.zip = option.zipcode;
        branch.country = "United States";
      }
    });
  };

  const cancelNewCompany = () => {
    setNewCompany(false);
    setNewAddressWithoutCompanyInfoMode(false);
    setCompany({ branches: [contact.contactRoles[0].branch] } as Company);
  };

  const getCompanyById = async (id: number) => {
    const { data } = await axios.get<Company>(`/api/clientphonebook/companies/get/${id}`);
    return data;
  };

  const branch = company.branches[0];

  const handleNoCompany = (value: boolean) => {
    setNoCompanyMode(value);
    setShowConfirmNoCompanyAlert(value);
    setIsBranchWithoutCompany(value);
  };

  const submitCompanyAndBranch = async () => {
    if (!company.name) return;

    setSubmitting(true);
    const { data } = await axios.post("/api/clientphonebook/companies/add", company);
    const returnedCompany = await getCompanyById(data);
    setSelectedCompany(returnedCompany);
    setSelectedCompanyBranch(returnedCompany.branches[0]);
    handleCompanyBranchChange(returnedCompany.branches[0]);
    setNewCompany(false);
    setSubmitting(false);
    if (isPartOfOwnForm) {
      postMessageToParentWindow({ eventType: "addCompany", phonebookId: data });
    }
  };

  const submitBranch = async () => {
    setSubmitting(true);
    const returnedBranch = await submitBranchWithoutCompany(company);
    setSelectedCompanyBranch(returnedBranch);
    handleCompanyBranchChange(returnedBranch);
    setIsBranchWithoutCompany(false);
    setNewCompany(false);
    setSubmitting(false);
    setNewCompany(false);
    setNewAddressWithoutCompanyInfoMode(false);
  };

  const submit = () => {
    if (!isValidEmail && company.email) {
      return;
    }
    if (newAddressWithoutCompanyInfoMode || isBranchWithoutCompany) {
      submitBranch();
    } else {
      submitCompanyAndBranch();
    }
  };

  const handleYes = () => {
    setShowConfirmNoCompanyAlert(false);
  };

  const handleNo = () => {
    setTouched(true);
    setNoCompanyMode(false);
    setShowConfirmNoCompanyAlert(false);
  };

  const handlePhoneFields = (info: MuiTelInputInfo) => {
    handleCompanyChange("phone", info.nationalNumber);
    handleCompanyChange("phoneCountryCode", info.countryCallingCode);
  };

  const handleFaxFields = (info: MuiTelInputInfo) => {
    handleCompanyChange("fax", info.nationalNumber);
    handleCompanyChange("faxCountryCode", info.countryCallingCode);
  };

  const isOrphanedBranch = branch?.id && !branch.companyId;

  useEffect(() => {
    getAndSetCompanyTypes();
  }, []);

  useEffect(() => {
    if (defaultName) {
      setTimeout(() => {
        handleCompanyChange("name", defaultName);
      }, 0);
    }
    if (defaultCompanyType) {
      const defaultType = companyTypes.find((type) => type.name === defaultCompanyType);
      if (defaultType) {
        setTimeout(() => {
          handleCompanyChange("companyTypeId", defaultType?.id);
        });
      }
    }
  }, [companyTypes]);

  let stateListOpened = branch?.state != null && !usStateList.includes(branch?.state);

  const renderSubmitButtonText = () => {
    if (submitting) {
      return "Saving...";
    }
    if (isAppInIFrame && isPartOfOwnForm) {
      return "Save & Add to Order";
    }
    return "Save";
  };

  !company;
  return (
    <Card>
      <CardContent>
        {!isPartOfOwnForm && <RoleAndProfession />}
        <Card
          sx={{
            backgroundColor: newAddressWithoutCompanyInfoMode ? "inherit" : "rgba(24, 23, 168, 0.08)"
          }}
        >
          <CardContent>
            <Stack alignItems="center" marginBottom={2.5}>
              <Typography variant="overline" color={theme.palette.text.secondary}>
                {newAddressWithoutCompanyInfoMode || noCompanyMode || editMode
                  ? "ADDRESS"
                  : "ADD A NEW COMPANY TO PHONE BOOK"}
              </Typography>
            </Stack>
            <Grid container rowSpacing={3} columnSpacing={2}>
              {(focusOnCompanyName || !newAddressWithoutCompanyInfoMode) && (
                <>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={0.5}>
                      <TextField
                        autoFocus={focusOnCompanyName}
                        error={!noCompanyMode && touched && !company.name}
                        helperText={!noCompanyMode && touched && !company.name ? "Company Name is required" : undefined}
                        fullWidth
                        label={
                          noCompanyMode ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Iconify icon="fe:disabled" />
                              <Typography alignContent="center">Company unknown</Typography>
                            </Stack>
                          ) : (
                            "Company Name"
                          )
                        }
                        value={company.name}
                        onChange={(e) => handleCompanyChange("name", e.target.value)}
                        disabled={noCompanyMode}
                        sx={{ opacity: noCompanyMode ? 0.5 : 1 }}
                        onBlur={() => {
                          setTouched(true);
                          searchCompanyDuplicates();
                        }}
                      />
                      <FormControlLabel
                        control={<Switch checked={noCompanyMode} onChange={(_, value) => handleNoCompany(value)} />}
                        label="I don’t have company information"
                        sx={{ color: theme.palette.primary.main }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    {!noCompanyMode && (
                      <Autocomplete
                        defaultValue={{ name: defaultCompanyType } as CompanyType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Type"
                            placeholder="Start typing..."
                            onFocus={() => setTouched(true)}
                          />
                        )}
                        onChange={(_, value) => handleCompanyChange("companyTypeId", value?.id)}
                        options={companyTypes}
                        getOptionLabel={(option) => option.name || ""}
                        //value={companyTypes.find(type => type.id.toString() === defaultCompanyType) || null}
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={8}>
                <AddressAutocomplete
                  setAddressOption={handleOptionChange}
                  setAddress1={(value) => handleBranchChange("address1", value)}
                  inputValue={branch?.address1 || ""}
                  onFocus={() => setTouched(true)}
                  error={submissionAttempted && formIsInvalid && !company.branches[0].address1}
                  onBlur={searchCompanyDuplicates}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Address 2"
                  value={branch?.address2 || ""}
                  onChange={(e) => handleBranchChange("address2", e.target.value.trim())}
                  onFocus={() => setTouched(true)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="City"
                  value={branch?.city || ""}
                  onChange={(e) => handleBranchChange("city", e.target.value.trim())}
                  onFocus={() => setTouched(true)}
                />
              </Grid>
              <Grid item xs={6}>
                <StateAutocomplete
                  usMode={branch.country === "United States" || branch.country === "US" || !branch.country}
                  state={branch?.state}
                  handleChange={(value: any) => handleBranchChange("state", value.trim())}
                  onBlur={searchCompanyDuplicates}
                  onFocus={() => setTouched(true)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Zip"
                  value={branch?.zip || ""}
                  onChange={(e) => handleBranchChange("zip", e.target.value.trim())}
                  onFocus={() => setTouched(true)}
                  error={submissionAttempted && formIsInvalid && !company.branches[0].zip}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={branch?.country || ""}
                  options={countryList}
                  onChange={(_, value) => handleBranchChange("country", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autocomplete: "new-password"
                      }}
                      fullWidth
                      label="Country"
                      onFocus={() => setTouched(true)}
                    />
                  )}
                />
              </Grid>
              {!newAddressWithoutCompanyInfoMode && (
                <>
                  <Grid item xs={6}>
                    <MuiTelInput
                      value={`+${isNumber(company.phoneCountryCode) ? company.phoneCountryCode : 1} ${company.phone}`}
                      onChange={(value, info) => handlePhoneFields(info)}
                      preferredCountries={["US", "IL"]}
                      defaultCountry={"US"}
                      forceCallingCode
                      label="Company Phone"
                      fullWidth
                      onFocus={() => setTouched(true)}
                      onBlur={searchCompanyDuplicates}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MuiTelInput
                      value={`+${isNumber(company.faxCountryCode) ? company.faxCountryCode : 1} ${company.fax}`}
                      onChange={(value, info) => handleFaxFields(info)}
                      preferredCountries={["US", "IL"]}
                      defaultCountry={"US"}
                      forceCallingCode
                      label="Company Fax"
                      fullWidth
                      onFocus={() => setTouched(true)}
                      onBlur={searchCompanyDuplicates}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Company Email"
                      value={company?.email}
                      onChange={(e) => handleCompanyChange("email", e.target.value?.trim())}
                      onFocus={() => setTouched(true)}
                      onBlur={(e) => {
                        setEmailTouched(true);
                        setIsValidEmail(validateEmail(e.target.value))
                        searchCompanyDuplicates();
                      }}
                      error={emailTouched && !isValidEmail}
                      helperText={emailTouched && !isValidEmail && company?.email !== "" ? "Invalid email address" : ""}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Website"
                  value={company?.website || ""}
                  onChange={(e) => handleCompanyChange("website", e.target.value)}
                  onFocus={() => setTouched(true)}
                  onBlur={searchCompanyDuplicates}
                />
              </Grid>
            </Grid>
          </CardContent>
          {showConfirmNoCompanyAlert && (
            <NoCompanyAlert open={showConfirmNoCompanyAlert} handleNo={handleNo} handleYes={handleYes} />
          )}
        </Card>
        {!noCompanyMode && (
          <Stack direction="row" justifyContent="flex-end" marginTop={3} columnGap={2}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => (isPartOfOwnForm ? closeIFrame() : cancelNewCompany())}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={submit} disabled={submitting}>
              {renderSubmitButtonText()}
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

import axios from "axios";
import { FullContact } from "../../../types";
import { Updater } from "use-immer";

export const refreshContact = async ({
  contact,
  setContact
}: {
  contact: FullContact | undefined;
  setContact: Updater<FullContact | undefined>;
}) => {
  if (!contact?.id) return;
  const { data } = await axios.get(`/api/clientphonebook/contacts/get/${contact.id}`);
  setContact((draft) => (draft = data));
};

import { Box, Link, Card, Typography, CardHeader, IconButton, TextField, Button, Stack } from "@mui/material";
import { CustomAvatar } from "../../../components/custom-avatar";
import { fDate } from "../../../utils/formatTime";
import Iconify from "../../../../minimals/components/iconify";
import { Note } from "../../types";
import { useContext, useState } from "react";
import axios from "axios";
import { ContactContext } from "../../contact/ContactContext";
import { CompanyContext } from "../../company/CompanyContext";

// ----------------------------------------------------------------------

interface Props {
  note: Note;
  companyId: number | undefined;
  contactId: number | undefined;
  showClientInstructionsHeader: boolean;
}

export default function NoteCard({ note, companyId, contactId, showClientInstructionsHeader }: Props) {
  const [updatedNote, setUpdatedNote] = useState<Note>(note);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editText, setEditText] = useState(note.text);
  const { contact, setContact } = useContext(ContactContext);
  const { company, setCompany } = useContext(CompanyContext);

  const onSaveEditCompanyNote = async (note: Note) => {
    const { data } = await axios.put("/api/clientphonebook/companyNotes/update", {
      id: note.id,
      text: editText,
      companyId
    });
    setUpdatedNote({ ...note, text: data.text });
    updateCompanyNoteList(data);
    setEditMode(false);
  };

  const onSaveEditContactNote = async (note: Note) => {
    const { data } = await axios.put("/api/clientphonebook/contactNotes/update", {
      id: note.id,
      text: editText,
      contactId
    });
    setUpdatedNote({ ...note, text: data.text });
    updateContactNoteList(data);
    setEditMode(false);
  };

  const updateContactNoteList = (editedNote: Note) => {
    const updatedContactNotes = contact?.notes.map((n) => {
      if (n.id === editedNote.id) {
        return editedNote;
      }
      return n;
    });
    setContact((contact) => ({ ...contact, notes: updatedContactNotes }));
  };

  const updateCompanyNoteList = (editedNote: Note) => {
    const updatedCompanyNotes = company?.notes.map((n) => {
      if (n.id === editedNote.id) {
        return editedNote;
      }
      return n;
    });
    setCompany((company) => ({ ...company, notes: updatedCompanyNotes }));
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Box>
            {showClientInstructionsHeader && (
              <Typography variant="h6" marginBottom={2}>
                Client Instructions
              </Typography>
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomAvatar alt={note.authorName} name={note.authorName} />
              <Box>
                <Link color="inherit" variant="subtitle2">
                  {note.updatedAt && Date.parse(note.updatedAt) > Date.parse(note.createdAt)
                    ? `edited by ${note.authorName}`
                    : note.authorName}
                </Link>
                <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>
                  {fDate(note.createdAt)}
                </Typography>
              </Box>
            </Stack>
          </Box>
        }
        action={
          <Box>
            <IconButton onClick={() => setEditMode(true)} color="primary">
              <Iconify icon="eva:edit-fill" />
            </IconButton>
          </Box>
        }
      />
      {editMode ? (
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => {
              companyId ? onSaveEditCompanyNote(note) : onSaveEditContactNote(note);
            }}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Typography
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
            whiteSpace: "pre-wrap"
          }}
        >
          {updatedNote.text}
        </Typography>
      )}
    </Card>
  );
}

import { useCallback, useState } from "react";
import { Msg, PidTagAttachLongFilename } from "msg-parser";
// @mui
import { Autocomplete, Card, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
// components
import { FileWithType } from "./types/FileWithType";
import Iconify from "../../minimals/components/iconify";
import { UploadMultiFile } from "../components/upload";
import DotLoader from "../components/DotLoader";
// ----------------------------------------------------------------------

const extractAttachments = async (msgFile: File): Promise<FileWithType[]> => {
  const buffer = await msgFile.arrayBuffer();
  const byteArray = new Uint8Array(buffer);
  const msg = Msg.fromUint8Array(byteArray);
  return msg.attachments().map((attachment) => {
    return {
      file: {
        name: attachment.getProperty(PidTagAttachLongFilename),
        data: new Uint8Array() //don't extract the data - let's leave that for the backend
      },
      type: null as any,
      isOther: false
    };
  });
};

interface AttachmentProps {
  orderFiles: FileWithType[];
  setOrderFiles: React.Dispatch<React.SetStateAction<FileWithType[]>>;
  errorMode: boolean;
  priorPolicyFiles?: File[];
  setPriorPolicyFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  fullWidth?: boolean;
}
export default function Attachments({
  orderFiles,
  setOrderFiles,
  errorMode,
  priorPolicyFiles,
  setPriorPolicyFiles,
  fullWidth
}: AttachmentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDropMultiFile = useCallback(
    // @ts-ignore
    async (acceptedFiles) => {
      setIsLoading(true);
      if (acceptedFiles[0].name.endsWith(".msg")) {
        const msgFile = acceptedFiles[0];
        const attachments = await extractAttachments(msgFile);
        setOrderFiles([...orderFiles, { file: msgFile, type: null as any, isOther: false }, ...attachments]);
      } else {
        setOrderFiles([
          ...orderFiles,
          ...acceptedFiles.map((file: any) => ({
            file: Object.assign(file, {
              preview: URL.createObjectURL(file)
            }),
            type: null
          }))
        ]);
      }
      setIsLoading(false);
    },
    [setOrderFiles, orderFiles]
  );

  const handleRemoveAll = () => {
    setOrderFiles([]);
  };

  const documentTypeList = [
    "Application/Title Order",
    "Checklist",
    "Contract of Sale/PSA",
    "Amendment to contract of sale",
    "Prior Survey",
    "Prior Policy",
    "Prior Commitment",
    "Letter from Seller’s Attorney"
  ];

  const checkIsOther = (str: string) => {
    return documentTypeList.some((documentType) => documentType === str) === false;
  };

  const getHelperText = (str: string | null) => {
    if (!str) {
      return "For “other” custom descriptions, freetype the “additional description.”";
    } else if (checkIsOther(str)) {
      return `Will be saved to Order/Miscellaneous > Miscellaneous > Other > ${str}`;
    } else {
      if (str === "Prior Survey") {
        return "Will be saved to Post Closing > Searches > Old Survey";
      } else {
        return `Will be saved to Order/Miscellaneous > Correspondence > ${str}`;
      }
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={fullWidth ? 12 : 6}>
        <UploadMultiFile
          showPreview={false}
          files={orderFiles.map((f) => f.file) as File[]}
          onDrop={handleDropMultiFile}
          onRemove={() => null}
          onRemoveAll={handleRemoveAll}
          label="Upload documents"
        />
        {isLoading && <DotLoader />}
      </Grid>
      {orderFiles.map((f, i) => (
        <Grid item xs={12} md={fullWidth ? 12 : 6} key={i}>
          <Card sx={{ padding: 1.5 }}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={11}>
                <Grid container>
                  <Grid item>
                    <Iconify fontSize={24} marginRight={1} icon={"eva:file-text-fill"} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" sx={{ display: "inline-block", wordBreak: "break-all" }}>
                      {f.file.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    size="small"
                    onClick={() => setOrderFiles(orderFiles.filter((_files) => _files.file != f.file))}
                  >
                    <Iconify icon={"eva:close-outline"} />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} marginTop={2}>
                <Autocomplete
                  value={f.type}
                  options={documentTypeList}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => {
                    if (setPriorPolicyFiles && priorPolicyFiles && value === "Prior Policy") {
                      setPriorPolicyFiles([...priorPolicyFiles, f.file as File]);
                    }

                    setOrderFiles(
                      orderFiles.map((_file) =>
                        _file.file === f.file
                          ? ({
                              ..._file,
                              type: value,
                              isOther: value ? checkIsOther(value) : false
                            } as FileWithType)
                          : _file
                      )
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      helperText={getHelperText(f.type)}
                      error={errorMode && f.type === null}
                      {...params}
                      label="Document Type"
                      placeholder="Select document type"
                      onChange={(e) => {
                        setOrderFiles(
                          orderFiles.map((_file) =>
                            _file.file === f.file
                              ? ({
                                  ..._file,
                                  type: e.target.value,
                                  isOther: checkIsOther(e.target.value)
                                } as FileWithType)
                              : _file
                          )
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

// @mui
import { Button, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import AddEditContact from "./AddEditContact";
import Iconify from "../../../minimals/components/iconify";
import ContactCardNew from "./ContactCardNew";

// ----------------------------------------------------------------------

export default function Contacts() {
  const { additionalContacts, setAdditionalContacts } = useContext(OrderContext);

  const removeContact = (index: number) => {
    var contactsCopy = [...additionalContacts];
    contactsCopy.splice(index, 1);
    setAdditionalContacts(contactsCopy);
  };

  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editContactIndex, setEditContactIndex] = useState<number>(0);

  if (isEditMode || isAddMode) {
    return (
      <Grid container spacing={1} alignItems="center">
        {(isEditMode || isAddMode) && (
          <AddEditContact
            cancel={() => {
              setIsAddMode(false);
              setIsEditMode(false);
            }}
            isEditMode={isEditMode}
            editContactIndex={editContactIndex}
          />
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} alignItems="center">
      {additionalContacts.map((c, i) => {
        return (
          <Grid item>
            <ContactCardNew
              contact={c}
              index={i}
              removeContact={removeContact}
              setEditContactIndex={setEditContactIndex}
              setIsEditMode={() => setIsEditMode(true)}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button
          id="addcontact"
          size="small"
          startIcon={<Iconify icon={"eva:plus-fill"} />}
          onClick={() => setIsAddMode(true)}
        >
          Add a Contact
        </Button>
      </Grid>
    </Grid>
  );
}

export function formatPhoneNumber(input: string) {
  // Extracting country code, number, and extension using regular expressions
  if (input == null) return input;
  const countryCodeMatch = input.match(/CountryCode:(\d+);/);
  const numberMatch = input.match(/Number:(\d+);/);
  const extensionMatch = input.match(/Extension:(\d+);/);

  // Checking if matches are found
  if (!countryCodeMatch && !numberMatch && !extensionMatch) {
    return "";
  }

  // Extracting values from regex matches
  const countryCode = countryCodeMatch ? countryCodeMatch[1] : "";
  const number = numberMatch ? numberMatch[1] : "";
  const extension = extensionMatch ? extensionMatch[1] : ""; // Extension is not used in the final format

  // Formatting the phone number
  return `+${countryCode} - ${number}`;
}

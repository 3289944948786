import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export const useQuery = (): [URLSearchParams, (name: string, val: string) => void] => {
  const navigate = useNavigate();
  const { search } = useLocation();

  // Creates a URLSearchParams object given any existing query string that is on the url
  const params = new URLSearchParams(location.search);

  // Function that gets called by any component that wants to update the URL parameters
  const setParams = (name: string, val: string) => {
    // Sets new parameters onto our URLSearchParams object
    params.set(name, val);

    // Pushes new parameters onto the current URL using URLSearchParams object toString method
    navigate({
      pathname: location.pathname,
      search: params.toString()
    });
  };
  return [useMemo(() => new URLSearchParams(search), [search]), setParams];
};

export default useQuery;

import { TableContainer, Table, TableRow, TableCell, Skeleton, TableHead, TableBody } from "@mui/material";

export default function ClientOrdersLoadingSkeleton() {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell>Transaction</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Closed</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Deal Owner</TableCell>
            <TableCell>Sales Person</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Power BI</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 50 }).map((row) => (
            <TableRow>
              <TableCell width={120}>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell width={480}>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell width={50}>
                <Skeleton />
              </TableCell>
              <TableCell align="right" width={100}>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

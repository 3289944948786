import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../../minimals/components/iconify";
import { useContext } from "react";
import { AddEditContactContext } from "../../contact/addEditContact/AddEditContactContext";

export default function GoBackButton() {
  const { reset, setContact, resetMergingContacts } = useContext(AddEditContactContext);
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      startIcon={<Iconify icon="eva:arrow-back-fill" />}
      onClick={() => {
        navigate(-1);
        reset();
        resetMergingContacts();
      }}
    >
      Go Back
    </Button>
  );
}

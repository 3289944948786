import OrderHeader from "../orderEntry/OrderHeader";
import { useContext } from "react";
import { NotesContext } from "../contexts/NotesContext";
import NotesPreview from "./NotesPreview";
import { Container, Typography } from "@mui/material";
import DownloadButton from "./DownloadButton";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
// ----------------------------------------------------------------------

export default function Notes() {
  const { order } = useContext(NotesContext);
  const { themeStretch } = useSettingsContext();
  return (
    <Page title="Notes" width="100%">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h5">Notes</Typography>
        <OrderHeader order={order} />
        <DownloadButton />
        <NotesPreview />
        <DownloadButton />
      </Container>
    </Page>
  );
}

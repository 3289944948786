import { Autocomplete, Button, Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Iconify from "../../../../minimals/components/iconify";
import { MutableRefObject, useContext, useEffect, useState } from "react";
import { AddEditContactContext } from "./AddEditContactContext";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import isNumber from "../../../utils/isNumber";
import { Phone, SmartyAddress } from "../../types";
import AddressAutocomplete from "./AddressAutocomplete";
import { usStateList } from "../../../utils/usStateList";
import { countryList } from "../../../utils/countryList";

interface IProps {
  refProp: MutableRefObject<null | HTMLInputElement>;
  theInput: string;
}
export default function PersonalInformation({ refProp, theInput }: IProps) {
  const { handleContactChange, contact, homePhone, setHomePhone, setContact } = useContext(AddEditContactContext);

  const [expandPersonalInfo, setExpandPersonalInfo] = useState<boolean>(false);

  useEffect(() => {
    const expanded =
      !!contact.preferredName ||
      !!homePhone?.number ||
      !!contact.birthday ||
      !!contact.address1 ||
      !!contact.address2 ||
      !!contact.city ||
      !!contact.state ||
      !!contact.zip;
    setExpandPersonalInfo(expanded);
  }, [contact.id]);

  useEffect(() => {
    if (contact.id) {
      const currentHomePhone = contact.phoneNumbers.find((p) => p.type === "Home") || ({} as Phone);
      setHomePhone(currentHomePhone);
    }
  }, [contact.id]);

  const handlePhoneFields = (info: MuiTelInputInfo) => {
    setHomePhone({
      ...homePhone,
      number: info.nationalNumber || "",
      countryCode: info.countryCallingCode || "",
      type: "Home"
    } as Phone);
  };

  const handleOptionChange = (option: SmartyAddress | null) => {
    setContact((draft) => {
      draft.address1 = option?.formattedStreet_line || "";
      if (option) {
        draft.address2 = option.secondary;
        draft.city = option.city;
        draft.state = option.state;
        draft.zip = option.zipcode;
        draft.country = "United States";
      }
    });
  };

  return (
    <Card>
      <CardHeader title="Personal Information" />
      <CardContent>
        {!expandPersonalInfo && (
          <Button
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setExpandPersonalInfo(!expandPersonalInfo)}
          >
            Add Personal Information
          </Button>
        )}
        {expandPersonalInfo && (
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={6}>
              <TextField
                value={contact.preferredName || ""}
                label="Preffered Name"
                fullWidth
                onChange={(e) => handleContactChange("preferredName", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                onChange={(value) => handleContactChange("birthday", value)}
                value={contact.birthday || null}
                slotProps={{ textField: { label: "Birthday" } }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={contact.personalEmail || ""}
                inputRef={theInput === "Personal Email" ? refProp : null}
                label="Personal Email"
                onChange={(e) => {
                  handleContactChange("personalEmail", e.target.value);
                }}
                fullWidth
                inputProps={{
                  autoComplete: "new-password"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiTelInput
                value={`+${isNumber(homePhone?.countryCode) ? homePhone?.countryCode : 1} ${homePhone?.number}`}
                onChange={(value, info) => handlePhoneFields(info)}
                preferredCountries={["US", "IL"]}
                defaultCountry={"US"}
                forceCallingCode
                label="Home Phone"
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <AddressAutocomplete
                setAddressOption={handleOptionChange}
                setAddress1={(value) => handleContactChange("address1", value)}
                inputValue={contact?.address1 || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Address 2"
                value={contact?.address2 || ""}
                onChange={(e) => handleContactChange("address2", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                value={contact?.city || ""}
                onChange={(e) => handleContactChange("city", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={
                  contact.country === "United States" || contact.country === "US" || !contact.country ? usStateList : []
                }
                value={contact.state || ""}
                freeSolo
                onChange={(_, value) => handleContactChange("state", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autocomplete: "new-password"
                    }}
                    fullWidth
                    label="State"
                    onChange={(e) => handleContactChange("state", e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Zip"
                value={contact?.zip || ""}
                onChange={(e) => handleContactChange("zip", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={contact?.country || ""}
                options={countryList}
                onChange={(_, value) => handleContactChange("country", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autocomplete: "new-password"
                    }}
                    fullWidth
                    label="Country"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TaskFilters from "./TaskFilters";
import TaskRow from "./TaskRow";
import AssignmentModal from "./assignment/AssignmentModal";
import DocumentUploadModal from "./documents/DocumentUploadModal";
import ViewAssociatedDocumentsModal from "./documents/ViewAssociatedDocumentsModal";
import { useSortedTasks } from "./hooks/useSortedTasks";
import { useTaskFilters } from "./hooks/useTaskFilters";
import NotesModal from "./notes/TaskNotesModal";
import { BasicTask, EventType, SortConfig, StatusCategory, SelectedTasks } from "./types";
import { useSnackbar } from "notistack";
import Iconify from "../../minimals/components/iconify";
import { copyTasksToClipboard } from "../taskUtils";
import { Contact } from "../shared/orderInfo/types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";

const TasksTable = ({
  tasks,
  setTasks,
  statusCategories,
  eventTypes,
  refreshTasks,
  orderNumber,
  setSelectedTaskHistory,
  contacts
}: {
  tasks: BasicTask[];
  setTasks: Dispatch<SetStateAction<BasicTask[] | null>>;
  statusCategories: StatusCategory[];
  eventTypes: EventType[];
  refreshTasks: () => void;
  orderNumber: string | null;
  setSelectedTaskHistory: Dispatch<SetStateAction<BasicTask | null>>;
  contacts: Contact[];
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTaskNotes, setSelectedTaskNotes] = useState<BasicTask | null>(null);
  const [selectedTaskAssignment, setSelectedTaskAssignment] = useState<BasicTask | null>(null);
  const [selectedTaskUploadDocument, setSelectedTaskUploadDocument] = useState<BasicTask | null>(null);
  const [selectedTaskViewDocuments, setSelectedTaskViewDocuments] = useState<BasicTask | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [selectedTasks, setSelectedTasks] = useState<SelectedTasks>({});
  const { sortedTasks, columns } = useSortedTasks(tasks, sortConfig);

  const {
    statusFilters,
    setStatusFilters,
    activityFilters,
    setActivityFilters,
    assignedToFilters,
    setAssignedToFilters,
    associationFilters,
    setAssociationFilters,
    taskFilter,
    setTaskFilter,
    taskQuickNoteFilter,
    setTaskQuickNoteFilter,
    associationFilterOptions,
    assignedToFilterOptions,
    activityFilterOptions,
    filtersVisible,
    setFiltersVisible,
    filteredSortedTasks,
    hideCompleted,
    setHideCompleted
  } = useTaskFilters(sortedTasks, contacts, eventTypes);

  const getAllTasksAndSubtasksFlattened = (tasks: BasicTask[]): BasicTask[] => {
    const collectSubtasksByTask = (task: BasicTask): BasicTask[] => {
      const subTasks = task.subTasks.flatMap(collectSubtasksByTask);
      return [task, ...subTasks];
    };

    return tasks.flatMap(collectSubtasksByTask);
  };

  const handleSelectTask = (taskId: number, isChecked: boolean): void => {
    setSelectedTasks((prevSelectedTasks) => {
      if (isChecked) {
        return { ...prevSelectedTasks, [taskId]: true };
      }
      const { [taskId]: _, ...remainingIds } = prevSelectedTasks;
      return remainingIds;
    });
  };

  const handleSelectAll = (tasks: BasicTask[], isChecked: boolean): void => {
    if (isChecked) {
      const allTasksSelectedObj = getAllTasksAndSubtasksFlattened(tasks).reduce((builder, task) => {
        return { ...builder, [task.id]: true };
      }, {});
      setSelectedTasks(allTasksSelectedObj);
      return;
    }
    setSelectedTasks({});
  };

  const openUploadDocument = (task: BasicTask) => {
    setSelectedTaskViewDocuments(null);
    setSelectedTaskUploadDocument(task);
  };

  const handleCopy = async (tasks: BasicTask[]) => {
    var hasSelectedTasks = Object.keys(selectedTasks).length > 0;
    const allSelectedTasksId = hasSelectedTasks
      ? Object.keys(selectedTasks).filter((key) => selectedTasks[key])
      : tasks.map((t) => t.id.toString());
    const allTaskAndSubtasks = getAllTasksAndSubtasksFlattened(tasks);
    const tasksToCopy = allTaskAndSubtasks.filter((t) => allSelectedTasksId.includes(String(t.id)));

    if (tasksToCopy.length === 0) {
      enqueueSnackbar("No tasks were selected. Please select tasks and try again", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
      return;
    }

    const success = await copyTasksToClipboard(tasksToCopy);

    if (success) {
      enqueueSnackbar("Selected tasks were copied to clipboard. You can now paste it into an email.", {
        variant: "success",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    } else {
      enqueueSnackbar("There was a problem copying the data. Please try again", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
    }
  };

  const isFlips = tasks[0].applicationId === 1;

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedTasks = [...filteredSortedTasks];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    reorderedTasks.forEach((r, i) => (r.sortIndex = i));

    try {
      setTasks(reorderedTasks);
      const sortIndexes = reorderedTasks.map((r) => ({
        id: r.id,
        sortIndex: r.sortIndex
      }));
      await axios.post("/api/taskmanagement/tasks/updateSortIndexes", { sortIndexes });
      enqueueSnackbar("Task order updated successfully.", { variant: "success" });
      refreshTasks();
    } catch (error) {
      enqueueSnackbar("Failed to update task order. Please try again.", { variant: "error" });
    }
  };

  // This useEffect ensures that the Autocomplete dropdowns close when clicking outside of them.
  // It listens for mousedown events on the document and triggers a blur on any open Autocomplete input.
  // The issue is caused by the drag and drop functionality, which interferes with the natural DOM blur events.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const autocompleteElements = document.querySelectorAll(".MuiAutocomplete-popper");
      autocompleteElements.forEach((autocompleteElement) => {
        if (!autocompleteElement.contains(event.target as Node)) {
          const openAutocomplete = document.querySelector(".MuiAutocomplete-root input:focus") as HTMLInputElement;
          if (openAutocomplete) {
            openAutocomplete.blur();
          }
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <TableContainer component={Card}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable" type="TASK">
            {(provided) => (
              <Table ref={provided.innerRef} {...provided.droppableProps}>
                <TableHead>
                  <TableRow>
                    {!isFlips && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={getAllTasksAndSubtasksFlattened(filteredSortedTasks).every(
                            (task) => selectedTasks[task.id]
                          )}
                          onChange={(event) => handleSelectAll(filteredSortedTasks, event.target.checked)}
                          size="small"
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell key={column.key} style={{ cursor: "pointer", whiteSpace: "nowrap" }}>
                        <TableSortLabel
                          active={sortConfig?.key === column.key}
                          direction={sortConfig?.key === column.key ? sortConfig.direction : "asc"}
                          onClick={() => {
                            const isAsc = sortConfig?.key === column.key && sortConfig.direction === "asc";
                            setSortConfig({
                              key: column.key,
                              direction: isAsc ? "desc" : "asc",
                              accessor: column.accessor
                            });
                          }}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <FormControlLabel
                          control={<Switch checked={hideCompleted} onChange={(_, value) => setHideCompleted(value)} />}
                          label="Hide Completed"
                        />
                        <IconButton onClick={() => setFiltersVisible(!filtersVisible)}>
                          <FilterListIcon />
                        </IconButton>
                        <IconButton onClick={() => handleCopy(filteredSortedTasks)}>
                          <Iconify icon="codicon:combine" />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TaskFilters
                    chosenStatusCategories={statusFilters}
                    setChosenStatusCategories={setStatusFilters}
                    statusCategories={statusCategories}
                    activityFilters={activityFilters}
                    setActivityFilters={setActivityFilters}
                    assignedToFilters={assignedToFilters}
                    setAssignedToFilters={setAssignedToFilters}
                    associationFilters={associationFilters}
                    setAssociationFilters={setAssociationFilters}
                    taskFilter={taskFilter}
                    setTaskFilter={setTaskFilter}
                    taskQuickNoteFilter={taskQuickNoteFilter}
                    setTaskQuickNoteFilter={setTaskQuickNoteFilter}
                    associationFilterOptions={associationFilterOptions}
                    assignedToFilterOptions={assignedToFilterOptions}
                    activityFilterOptions={activityFilterOptions}
                    filtersVisible={filtersVisible}
                    setFiltersVisible={setFiltersVisible}
                    columns={columns}
                    eventTypes={eventTypes}
                    applicationId={tasks[0]?.applicationId}
                  />
                </TableHead>
                <TableBody>
                  {filteredSortedTasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <TaskRow
                          t={task}
                          eventTypes={eventTypes}
                          refreshTasks={refreshTasks}
                          selectedTaskNotes={selectedTaskNotes}
                          selectedTaskAssignment={selectedTaskAssignment}
                          setSelectedAssignment={setSelectedTaskAssignment}
                          setSelectedTaskNotes={setSelectedTaskNotes}
                          selectedTaskUploadDocument={selectedTaskUploadDocument}
                          setSelectedTaskUploadDocument={setSelectedTaskUploadDocument}
                          selectedTaskViewDocuments={selectedTaskViewDocuments}
                          setSelectedTaskViewDocuments={setSelectedTaskViewDocuments}
                          setSelectedTaskHistory={setSelectedTaskHistory}
                          contacts={contacts}
                          handleSelectTask={handleSelectTask}
                          isSelected={Boolean(selectedTasks[task.id])}
                          selectedTasks={selectedTasks}
                          index={index}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
      {selectedTaskNotes && (
        <NotesModal
          selectedTask={selectedTaskNotes}
          setSelectedTask={setSelectedTaskNotes}
          updateTasks={refreshTasks}
        />
      )}
      {selectedTaskAssignment && (
        <AssignmentModal
          selectedTask={selectedTaskAssignment}
          setSelectedTask={setSelectedTaskAssignment}
          updateTasks={refreshTasks}
        />
      )}
      {selectedTaskUploadDocument && (
        <DocumentUploadModal
          selectedTask={selectedTaskUploadDocument}
          setSelectedTask={setSelectedTaskUploadDocument}
          updateTasks={refreshTasks}
          orderNumber={orderNumber}
        />
      )}
      {selectedTaskViewDocuments && (
        <ViewAssociatedDocumentsModal
          selectedTask={selectedTaskViewDocuments}
          setSelectedTask={setSelectedTaskViewDocuments}
          orderNumber={orderNumber}
          uploadDocument={openUploadDocument}
        />
      )}
    </>
  );
};

export default TasksTable;

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../../minimals/theme/overrides/CustomIcons";
import { BasicTask, Document } from "../types";
import Iconify from "../../../minimals/components/iconify";
import axios from "axios";
import { fMonthDayYearDotNotation, fTime } from "../../utils/formatTime";

export default function ViewAssociatedDocumentsModal({
  selectedTask,
  setSelectedTask,
  uploadDocument
}: {
  selectedTask: BasicTask | null;
  setSelectedTask: (value: any) => void;
  orderNumber: string | null;
  uploadDocument: (task: BasicTask) => void;
}) {
  const [documents, setDocuments] = useState<Document[]>([]);

  const closeModal = () => {
    setSelectedTask(null);
  };

  const open = !!selectedTask;

  const getAndSetDocuments = async () => {
    const { data } = await axios.get(`/api/taskmanagement/documents/getfortask?taskid=${selectedTask?.id}`);
    setDocuments(data);
  };

  useEffect(() => {
    getAndSetDocuments();
  }, []);

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" width="93%">
          <Stack direction="column">
            <Typography variant="subtitle1">Associated Documents</Typography>
            <Typography variant="body2">{selectedTask?.description}</Typography>
          </Stack>
          <Box>
            <IconButton onClick={() => uploadDocument(selectedTask as BasicTask)}>
              <Iconify icon="eva:upload-fill" />
            </IconButton>
          </Box>
        </Stack>
      </DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Document</TableCell>
                <TableCell>Uploaded by</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((d) => (
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell>
                    <Typography variant="subtitle2">{fMonthDayYearDotNotation(d.uploadedDate)}</Typography>
                    <Typography variant="body2" color={(theme) => theme.palette.grey[600]}>
                      {fTime(d.uploadedDate)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      sx={{ cursor: "pointer" }}
                      target="_blank"
                      href={`https://internal.mcres.com/opendoc?id=${d.momentumDocumentId}`}
                    >
                      {d.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">{d.uploadedBy}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Card, CardContent, CardHeader, Stack, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { useState } from "react";

const DebugMenu = ({ companyId }: { companyId: number }) => {
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isSendToDynamicsLoading, setIsSendToDynamicsLoading] = useState(false);

  const onAddToLookupTableClick = async () => {
    setIsAddLoading(true);
    await axios.post("/api/clientphonebook/debugmenu/AddCompanyToLookupTable", { companyId });
    setIsAddLoading(false);
    alert("Initiated add to lookup table. This operation is queued - give it a minute till it shows up in Select");
  };

  const onSendToDynamicsClick = async () => {
    setIsSendToDynamicsLoading(true);
    await axios.post("/api/clientphonebook/debugmenu/SyncCompanyWithDynamics", { companyId });
    setIsSendToDynamicsLoading(false);
    alert("Done sending company to dynamics");
  };

  return (
    <Card>
      <CardHeader title={"Debug Menu"} />
      <CardContent>
        <Stack direction="column" alignItems="center" spacing={2}>
          <LoadingButton
            loading={isAddLoading}
            variant="contained"
            color="info"
            sx={{ width: "100%" }}
            onClick={onAddToLookupTableClick}
          >
            Add to Lookup Table
          </LoadingButton>
          <LoadingButton
            loading={isSendToDynamicsLoading}
            variant="contained"
            color="info"
            sx={{ width: "100%" }}
            onClick={onSendToDynamicsClick}
          >
            Sync with Dynamics
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DebugMenu;

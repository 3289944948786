import { Card } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { ConfirmationContext } from "../contexts/ConfirmationContext";
import ProgressLoader from "../components/ProgressLoader";
// ----------------------------------------------------------------------

export default function ConfirmationPreview() {
  const { confirmationPdf } = useContext(ConfirmationContext);
  return (
    <Card>
      <Box height={1000}>
        {confirmationPdf && (
          <iframe
            allowFullScreen={true}
            title="confirmation"
            src={`data:application/pdf;base64,${confirmationPdf}#toolbar=0`}
            height="100%"
            width="100%"
            allowTransparency={true}
            style={{
              border: "none",
              backgroundColor: "#fff",
              padding: 0,
              position: "relative",
              left: -5,
              top: -5
            }}
            frameBorder="0"
            scrolling="no"
          />
        )}
        {!confirmationPdf && <ProgressLoader size={80} />}
      </Box>
    </Card>
  );
}

import axios from "axios";
import { fMonthDayYear, fMonthDayYearDotNotation, fMonthDayYearTimeDotNotation } from "../utils/formatTime";
import { isPast } from "date-fns";
import { ICalendarEvent, IClosedOrder } from "../types/calendar";

export const mapClosedOrdersToCalenderEvents = (closedOrders: IClosedOrder[] = []): ICalendarEvent[] => {
  return closedOrders?.map((d) => ({
    ...d,
    title: d.fullOrderNumber || "",
    start: d.settlementDate,
    end: d.settlementDate,
    status: d.orderStatus || "",
    statusColor: statusColorMap[d.orderStatus]
  }));
};

export const fetchClosedOrders = async (start: Date, end: Date) => {
  const startDateFormatted = fMonthDayYear(start);
  const endDateFormatted = fMonthDayYear(end);
  const { data }: { data: IClosedOrder[] } = await axios.get(
    `/api/calendar/getclosedorders?settlementDateFrom=${startDateFormatted}&settlementDateTo=${endDateFormatted}`
  );
  return data;
};

export const statusColorMap: { [key: string]: string } = {
  "In process": "#3b49d4",
  Transferred: "#3b49d4",
  Tentative: "#252f85",
  Hold: "#7e60ab",
  Pending: "#552899",
  Firm: "#47a842",
  Completed: "#2a8a25",
  Closed: "#1a5417",
  Adjourned: "#E0a800",
  Canceled: "#FF2929",
  Duplicate: "#AC0000",
  Default: "#000000",
  Dormant: "#78909C"
};

export const getStatusColor = (statusType: string) => {
  const color = statusColorMap[statusType];
  return { color };
};

export const statusOptions = [
  { value: "In process", label: "In process" },
  { value: "Transferred", label: "Transferred" },
  { value: "Tentative", label: "Tentative" },
  { value: "Hold", label: "Hold" },
  { value: "Pending", label: "Pending" },
  { value: "Firm", label: "Firm" },
  { value: "Completed", label: "Completed" },
  { value: "Closed", label: "Closed" },
  { value: "Adjourned", label: "Adjourned" },
  { value: "Canceled", label: "Canceled" },
  { value: "Duplicate", label: "Duplicate" },
  { value: "Dormant", label: "Dormant" }
];

export const getStatusTitle = ({ status, date }: { status: string; date: string | undefined | null }): string => {
  if (!status) return "";
  const fomattedDateAndTime = !date ? "" : fMonthDayYearTimeDotNotation(date);
  // const fomattedDate = !date ? '' : fMonthDayYearDotNotation(date)
  const statusObj: { [key: string]: string } = {
    Canceled: "Canceled",
    Closed: `Closed on ${fomattedDateAndTime}`,
    Completed: `Completed on ${fomattedDateAndTime}`,
    "In process": `Est. closing date ${fomattedDateAndTime}`,
    Transferred: `Est. closing date ${fomattedDateAndTime}`,
    Firm: `Scheduled to close on ${fomattedDateAndTime}`,
    Hold: `Hold`,
    Pending: `Pending`,
    Adjourned:
      date && isPast(new Date(date)) ? `Adjourned from ${fomattedDateAndTime}` : `Adjourned to ${fomattedDateAndTime}`,
    Tentative: `Tentative closing date ${fomattedDateAndTime}`,
    Duplicate: "Duplicate"
  };

  return statusObj[status] || "";
};

export const propertyTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Residential", label: "Residential" }
];

export const stateOptions = [
  { label: "NATIONAL", value: "NATIONAL" },
  { label: "AL", value: "AL" },
  { label: "AK", value: "AK" },
  { label: "AZ", value: "AZ" },
  { label: "AR", value: "AR" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "IA", value: "IA" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "ME", value: "ME" },
  { label: "MD", value: "MD" },
  { label: "MA", value: "MA" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MS", value: "MS" },
  { label: "MO", value: "MO" },
  { label: "MT", value: "MT" },
  { label: "NE", value: "NE" },
  { label: "NV", value: "NV" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NY", value: "NY" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VT", value: "VT" },
  { label: "VA", value: "VA" },
  { label: "WA", value: "WA" },
  { label: "WV", value: "WV" },
  { label: "WI", value: "WI" },
  { label: "WY", value: "WY" },
  { label: "DC", value: "DC" },
  { label: "ON", value: "ON" },
  { label: "NF", value: "NF" }
  // { value: "NO STATE", label: 'None' }
];

// export const stateOptions = [
//     { value: "NATIONAL", label: "National" },
//     { value: "AL", label: "Alabama" },
//     { value: "AK", label: "Alaska" },
//     { value: "AZ", label: "Arizona" },
//     { value: "AR", label: "Arkansas" },
//     { value: "CA", label: "California" },
//     { value: "CO", label: "Colorado" },
//     { value: "CT", label: "Connecticut" },
//     { value: "DE", label: "Delaware" },
//     { value: "FL", label: "Florida" },
//     { value: "GA", label: "Georgia" },
//     { value: "HI", label: "Hawaii" },
//     { value: "ID", label: "Idaho" },
//     { value: "IL", label: "Illinois" },
//     { value: "IN", label: "Indiana" },
//     { value: "IA", label: "Iowa" },
//     { value: "KS", label: "Kansas" },
//     { value: "KY", label: "Kentucky" },
//     { value: "LA", label: "Louisiana" },
//     { value: "ME", label: "Maine" },
//     { value: "MD", label: "Maryland" },
//     { value: "MA", label: "Massachusetts" },
//     { value: "MI", label: "Michigan" },
//     { value: "MN", label: "Minnesota" },
//     { value: "MS", label: "Mississippi" },
//     { value: "MO", label: "Missouri" },
//     { value: "MT", label: "Montana" },
//     { value: "NE", label: "Nebraska" },
//     { value: "NV", label: "Nevada" },
//     { value: "NH", label: "New Hampshire" },
//     { value: "NJ", label: "New Jersey" },
//     { value: "NM", label: "New Mexico" },
//     { value: "NY", label: "New York" },
//     { value: "NC", label: "North Carolina" },
//     { value: "ND", label: "North Dakota" },
//     { value: "OH", label: "Ohio" },
//     { value: "OK", label: "Oklahoma" },
//     { value: "OR", label: "Oregon" },
//     { value: "PA", label: "Pennsylvania" },
//     { value: "PR", label: "Puerto Rico" },
//     { value: "RI", label: "Rhode Island" },
//     { value: "SC", label: "South Carolina" },
//     { value: "SD", label: "South Dakota" },
//     { value: "TN", label: "Tennessee" },
//     { value: "TX", label: "Texas" },
//     { value: "UT", label: "Utah" },
//     { value: "VT", label: "Vermont" },
//     { value: "VA", label: "Virginia" },
//     { value: "WA", label: "Washington" },
//     { value: "WV", label: "West Virginia" },
//     { value: "WI", label: "Wisconsin" },
//     { value: "WY", label: "Wyoming" },
//     { value: "DC", label: "District of Columbia" },
//     { value: "ON", label: "Ontario, CA" },
//     { value: "NF", label: "New Foundland, CA" },
//     { value: "NO STATE", label: 'None' }
// ];

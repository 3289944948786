import { IconButton, Stack, Tooltip } from "@mui/material";
import Iconify from "../../minimals/components/iconify";

type Prop = {
  orderNumber: string | undefined;
};

export default function OpenInHomePageButton({ orderNumber }: Prop) {
  return (
    <Tooltip title="View in Momentum">
      <IconButton
        onClick={() => window.open(`https://internal.mcres.com/new-home/${orderNumber}`, "_blank")?.focus()}
        size="small"
      >
        <Iconify icon={"eva:external-link-fill"} />
      </IconButton>
    </Tooltip>
  );
}

import OrderHeader from "../orderEntry/OrderHeader";
import { useContext } from "react";
import { ConfirmationContext } from "../contexts/ConfirmationContext";
import ConfirmationPreview from "./ConfirmationPreview";
import EmailOptions from "./EmailOptions";
import SentEmailAlert from "./SentEmailAlert";
import OrderPageContainer from "../orderEntry/OrderPageContainer";
// ----------------------------------------------------------------------

export default function Confirmation() {
  const { order, sentEmail } = useContext(ConfirmationContext);
  return (
    <OrderPageContainer title="Confirmation" activeStep={2}>
      <>
        <OrderHeader order={order} />
        {sentEmail && <SentEmailAlert />}
        <ConfirmationPreview />
        <EmailOptions />
      </>
    </OrderPageContainer>
  );
}

// @mui
import { Card, Typography, CardHeader, Stack, Box } from "@mui/material";
import { useContext } from "react";
import { ContactContext } from "../../contact/ContactContext";

// ----------------------------------------------------------------------

export default function AccountReps() {
  const { contact } = useContext(ContactContext);

  const nyAccountReps = contact?.contactReps.filter((r) => r.contactRepTypeId === 2).map((r) => r.internalUser);

  const njAccountReps = contact?.contactReps.filter((r) => r.contactRepTypeId === 3).map((r) => r.internalUser);

  const nationalAccountReps = contact?.contactReps.filter((r) => r.contactRepTypeId === 4).map((r) => r.internalUser);

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Account Reps</Typography>} />
      <Stack spacing={2} sx={{ p: 3 }}>
        {contact?.contactReps?.length === 0 && (
          <Stack direction="row" spacing={1}>
            <Typography>No Information</Typography>
          </Stack>
        )}

        {nyAccountReps?.map((r) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={700}>
              NY
            </Typography>
            <Typography variant="body2">
              {r?.firstName} {r?.lastName}
            </Typography>
          </Stack>
        ))}

        {nationalAccountReps?.map((r) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={700}>
              National
            </Typography>
            <Typography variant="body2">
              {r?.firstName} {r?.lastName}
            </Typography>
          </Stack>
        ))}

        {njAccountReps?.map((r) => (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={700}>
              NJ
            </Typography>
            <Typography variant="body2">
              {r?.firstName} {r?.lastName}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

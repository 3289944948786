import { countAndMapAssignedTasks } from "./commonUtils";
import { PolicyNotIssued } from "../../policiesNotIssued/types";
import { MemberTaskCount } from "../../types";
import axios from "axios";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/lab";

export const getPoliciesNotIssuedUniqueAssignedToList = (policiesNotIssued: PolicyNotIssued[]): MemberTaskCount[] =>
  countAndMapAssignedTasks(policiesNotIssued, "Policies Not Issued");

export const searchPoliciesNotIssued = async (dateRange?: DateRange<Dayjs>): Promise<PolicyNotIssued[]> => {
  const url = dateRange
    ? `/api/outstandingchecks/policiesNotIssued?from=${dateRange[0]}&to=${dateRange[1]}`
    : `/api/outstandingchecks/policiesNotIssued`;

  const { data } = await axios.get<PolicyNotIssued[]>(url);
  return data;
};
